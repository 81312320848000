import React, { useState } from 'react';
import PropTypes, { func, string, arrayOf } from 'prop-types';
import Modal from 'components/modal';
import { Button } from 'components/button';
import DropzoneCSV from 'components/upload-csv';
import CloseIcon from 'assets/images/close-icon.svg';
import Papa from 'papaparse';

const CsvUpload = ({ open, onClose, handleClick, whitelistedEmails }) => {
   const [csvFile, setCsvFile] = useState(null);

   const handleFileDrop = (file) => {
      setCsvFile(file);
   };

   const handleRemoveFile = () => {
      setCsvFile(null);
   };

   const handleFileConversion = () => {
      const file = csvFile;
      if (file) {
         Papa.parse(file, {
            header: true,
            skipEmptyLines: true,
            complete(results) {
               const headers = results.meta.fields;
               const propertyKey = headers[0];
               const values = results.data.map((obj) => obj[propertyKey]);
               const mergedData = [
                  ...whitelistedEmails,
                  ...values.filter((email) => !whitelistedEmails.includes(email)),
               ];
               handleClick(mergedData);
            },
         });
      }
      onClose();
   };

   return (
      <Modal open={open} onClose={onClose} center width="500px">
         <div>
            <h1 className="mb-4">Upload CSV</h1>
            <DropzoneCSV onFileDrop={handleFileDrop} />
            {csvFile && (
               <div
                  className="d-flex align-items-center mt-5 mx-4"
                  style={{
                     borderBottom: '1px solid #000',
                     marginLeft: 'auto',
                  }}
               >
                  <p className="mr-3">
                     <b>{csvFile.name}</b>
                  </p>
                  <div style={{ flexGrow: 1 }} />
                  <img
                     src={CloseIcon}
                     alt="Remove File"
                     onClick={handleRemoveFile}
                     style={{ cursor: 'pointer' }}
                  />
               </div>
            )}
            {csvFile && (
               <div className="col mt-4 text-center">
                  <Button variant="primary" onClick={handleFileConversion}>
                     Done
                  </Button>
               </div>
            )}
         </div>
      </Modal>
   );
};

CsvUpload.propTypes = {
   open: PropTypes.bool.isRequired,
   onClose: PropTypes.func.isRequired,
   handleClick: func.isRequired,
   whitelistedEmails: arrayOf(string).isRequired,
};

export default CsvUpload;
