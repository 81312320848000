import { useState } from 'react';
import { Button } from 'components/button';
import { confirm } from 'components/confirm-box';
import { getPendingLoanStream } from 'services/loans';
import { func } from 'prop-types';
import { toast } from 'react-hot-toast';
import { MenuComponent } from 'components/menu';
import moment from 'moment';

function Index({ setModalVisibility, setWorkerDateRange }) {
   const [workAnchorEl, setWorkAnchorEl] = useState(null);
   // const [dateRange, setDateRange] = useState({});
   // const [businessFeed, setBusinessFeed] = useState();
   // const [bizAccount, setBizAccount] = useState('');
   const [today] = useState(moment().format('YYYY-MM-DD'));

   const openStatusMenu = Boolean(workAnchorEl);

   const handleStatusButtonClick = (event) => {
      setWorkAnchorEl(event.currentTarget);
   };

   const handleCloseStatusMenu = () => {
      setWorkAnchorEl(null);
   };

   const handleStartWork = (start, end, msg) => {
      setWorkAnchorEl(null);
      confirm({
         confirmText: `Are you sure you want to start work on accounts created in the last ${msg}?`,
         isLoading: false,
         onConfirm: async () => {
            const payload = {
               start_date: start,
               end_date: end,
            };
            setWorkerDateRange(payload);
            // setDateRange(payload);
            try {
               const result = await getPendingLoanStream(payload);
               if (!result.data)
                  return toast.error('No loan application is currently in this time range stream');
               //    setBizAccount(result.data.biz_account_id);
               // setNoCloseIcon(true);
               setModalVisibility(result.data, true);
               //    setActiveTab('overview');
            } catch ({ message }) {
               toast.error(message);
            }
         },
      });
   };

   return (
      <>
         <Button onClick={handleStatusButtonClick} className="ms-3" disabled variant="primary">
            Start Work
         </Button>
         <MenuComponent
            anchorEl={workAnchorEl}
            handleClose={handleCloseStatusMenu}
            open={openStatusMenu}
            // options={['Last 24 hours', 'Last 7 days', 'Last 30 days', 'More than 30 days']}
            options={['Last 24 hours', 'Last 7 days']}
            optionsFns={[
               () =>
                  handleStartWork(
                     moment().subtract(1, 'days').format('YYYY-MM-DD'),
                     today,
                     '24 hours',
                  ),
               () =>
                  handleStartWork(
                     moment().subtract(8, 'days').format('YYYY-MM-DD'),
                     moment().subtract(2, 'days').format('YYYY-MM-DD'),
                     '7 days',
                  ),
               //    () =>
               //       handleStartWork(
               //          moment().subtract(39, 'days').format('YYYY-MM-DD'),
               //          moment().subtract(9, 'days').format('YYYY-MM-DD'),
               //          '30 days',
               //       ),
               //    () =>
               //       handleStartWork(
               //          '2019-01-01',
               //          moment().subtract(40, 'days').format('YYYY-MM-DD'),
               //          'more than 30 days',
               //       ),
            ]}
         />
      </>
   );
}

Index.propTypes = {
   setModalVisibility: func.isRequired,
   setWorkerDateRange: func.isRequired,
   // setNoCloseIcon: func,
};

Index.defaultProps = {
   // setNoCloseIcon: false,
};
export default Index;
