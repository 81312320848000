import {
   can_read_business_data,
   can_read_marketing_data,
   can_read_promo_code,
} from 'utils/user_actions';
import { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useAuth } from 'hooks/auth';
import { hasPermission } from 'utils/user_access';
import TabMenu from 'components/TabMenu';
import { TabWrapper } from 'pages/pending-documents/style';
import LoggedInDashboard from 'components/logged-in-dashboard';
import PromoCodeDashboard from './feature/promo-code-dashboard';
import MarketingData from './feature/marketing-data';
import BusinessData from './feature/business-data';

export const Marketing = () => {
   const { pathname } = useLocation();
   const [activeTab, setActiveTab] = useState('');
   const history = useHistory();
   const { currentUser } = useAuth();
   const { permissions } = currentUser?.access || {};

   const tabs = [
      {
         label: 'Promo Code',
         value: 'promo',
         permission: hasPermission(permissions, can_read_promo_code),
         component: PromoCodeDashboard,
      },
      {
         label: 'Marketing data',
         value: 'marketing-data',
         permission: hasPermission(permissions, can_read_marketing_data),
         component: MarketingData,
      },
      {
         label: 'Business data',
         value: 'business-data',
         permission: hasPermission(permissions, can_read_business_data),
         component: BusinessData,
      },
   ];

   useEffect(() => {
      const defaultTab = tabs.find((tab) => tab.permission && tab.value === activeTab);
      if (!defaultTab) {
         const firstTab = tabs.find((tab) => tab.permission);
         if (firstTab) {
            setActiveTab(firstTab.value);
         }
      }
   }, [activeTab, tabs]);

   const handleActiveTab = (value) => {
      history.push(pathname);
      setActiveTab(value);
   };

   return (
      <>
         <LoggedInDashboard pageTitle="Marketing">
            <TabWrapper>
               <ul className="m-0 d-flex align-items-center">
                  {tabs
                     .filter((tab) => tab.permission)
                     .map(({ label, value }) => (
                        <TabMenu
                           key={value}
                           text={label}
                           active={activeTab === value ? 'active' : ''}
                           setActiveTab={() => handleActiveTab(value)}
                        />
                     ))}
               </ul>
            </TabWrapper>
            {tabs
               .filter((tab) => tab.permission && tab.value === activeTab)
               .map(({ value, component: Component }) => (
                  <Component key={value} />
               ))}
         </LoggedInDashboard>
      </>
   );
};
export default Marketing;
