import { useState } from 'react';
import { node, string } from 'prop-types';
import Modal from 'components/modal';
import Viewer from 'react-viewer';
import { logActivity } from 'services/activity';
import { toast } from 'react-hot-toast';
import { PDFViewerWrapper } from './style';

function DocumentPreviewer({ children, url, title, biz_account_id }) {
   const [showModal, setShowModal] = useState(false);
   const [visible, setVisible] = useState(false);

   async function logPreview() {
      try {
         await logActivity(`${title} Document Previewed`, 'reg', biz_account_id);
      } catch (e) {
         toast.error(e.message);
      }
   }

   if (!url || url === '') return <p>Kindly provide a valid document url!</p>;
   return (
      <>
         {url.includes('.pdf') ? (
            <div>
               <span
                  onClick={() => {
                     logPreview();
                     setShowModal(true);
                  }}
                  style={{ cursor: 'pointer' }}
               >
                  {children}
               </span>

               <Modal title={title} open={showModal} onClose={() => setShowModal(false)}>
                  <PDFViewerWrapper>
                     <embed src={url} type="application/pdf" width="100%" height="100%" />
                  </PDFViewerWrapper>
               </Modal>
            </div>
         ) : (
            <div>
               <span
                  onClick={() => {
                     setVisible(true);
                     logPreview();
                  }}
                  style={{ cursor: 'pointer' }}
               >
                  {children}
               </span>
               <Viewer
                  visible={visible}
                  onClose={() => {
                     setVisible(false);
                  }}
                  images={[{ src: url, alt: '' }]}
               />
            </div>
         )}
      </>
   );
}
DocumentPreviewer.propTypes = {
   children: node.isRequired,
   url: string.isRequired,
   title: string,
   biz_account_id: string.isRequired,
};

DocumentPreviewer.defaultProps = {
   title: 'Document',
};

export default DocumentPreviewer;
