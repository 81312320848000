/* eslint-disable react/prop-types */
/* eslint-disable no-use-before-define */
import { useAuth } from 'hooks/auth';
import { IconButton } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { MenuComponent } from 'components/menu';
import { StatsCards } from 'components/stats-cards';
import { TableComponent } from 'components/table';
import { TooltipComponent } from 'components/tooltip';
import { useBizDetailsModal } from 'hooks/bizDetailsModal';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { buildPartnerAssignedBusiness, getApprovedAccounts } from 'services/business';
import {
   getBuildPartnerMonthlyDeposit,
   getBuildPartnerWeeklyDeposit,
} from 'services/build-partner';
import { useDebouncedCallback } from 'use-debounce';
import { TableContainer, TableContent } from 'styles/table';
import _ from 'underscore';
import { numberWithCommas } from 'utils/others';
import { Input } from 'components/input';
import BusinessDetails from 'containers/business-details';
import Graph from 'components/graph';
import { GraphContainer } from './business-account.style';

const ApprovedAccounts = () => {
   const [buildBusinessData, setBuildBusinessData] = useState({});
   const [isLoadingStats, setIsLoadingStats] = useState(false);
   const [isLoadingApprovedData, setIsLoadingAprrovedData] = useState(false);
   const [paginationData, setPaginationData] = useState({});
   const [currentPage, setCurrentPage] = useState(1);
   const [approvedAccountData, setApprovedAccountData] = useState([]);
   const [modalVisibility, setModalVisibility] = useState(false);
   const [anchorEl, setAnchorEl] = useState(null);
   const open = Boolean(anchorEl);
   const [bizAccount, setBizAccount] = useState('');
   const [searchTerm, setSearchTerm] = useState('');
   const { setActiveTab } = useBizDetailsModal();
   const debounce = useDebouncedCallback(handleSearch, 600);
   const [selectedYear, setSelectedYear] = useState(`${moment().format('YYYY')}`);
   const [years, setYears] = useState([]);
   const [feedsFilter, setFeedsFilter] = useState('weekly');
   const [buildPartnerFeedData, setBuildPartnerFeedData] = useState([]);
   const { currentUser } = useAuth();

   function handleSearch({ target }) {
      setSearchTerm(target.value);
   }

   function handleOpenModal() {
      setActiveTab('overview');
      setModalVisibility(true);
   }

   function sendMessageModalSection() {
      setActiveTab('message');
      setModalVisibility(true);
   }

   function callNumber(phone) {
      const link = document.createElement('a');
      const linkText = document.createTextNode('call us');
      link.appendChild(linkText);
      link.href = `tel:${phone}`;
      link.click();
   }

   const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
   };

   const handleClose = () => {
      setAnchorEl(null);
   };

   const options = ['view Details', 'Call', 'Send Message'];

   const columns = [
      {
         field: 'biz_name',
         headerName: 'Business Name',
         renderCell: ({ row }) => (
            <TooltipComponent title={row.biz_name || row.biz_friendly_name}>
               <span
                  style={{
                     cursor: 'pointer',
                  }}
                  className="text-primary"
                  onClick={() => handleOpenModal(row.biz_account_id)}
               >
                  {row.biz_name || row.biz_friendly_name || 'N/A'}
               </span>
            </TooltipComponent>
         ),
      },

      {
         field: 'owner',
         headerName: 'Owner',
         renderCell: ({ row }) => (
            <span style={{ textTransform: 'capitalize' }}>
               {row?.account_holder_details?.first_name.toLowerCase()}{' '}
               {row?.account_holder_details?.last_name.toLowerCase()}
            </span>
         ),
      },
      {
         field: 'biz_turnover',
         headerName: 'Turn over',
         renderCell: ({ row }) => <span>{row.biz_turnover || 'N/A'}</span>,
      },
      {
         field: 'deposit_value',
         headerName: 'Deposit',
         renderCell: ({ row }) => (
            <TooltipComponent title={numberWithCommas(row.deposit_value)}>
               ₦{numberWithCommas(row.deposit_value)}
            </TooltipComponent>
         ),
      },
      {
         field: 'account_holder_details.phone',
         headerName: 'Phone',
         renderCell: ({ row }) => <span>{row?.account_holder_details?.phone || 'N/A'}</span>,
      },
      {
         field: 'assigned_manager_details',
         headerName: 'Manager',
         renderCell: ({ row }) => (
            <>
               {!row.assigned_manager_details ? (
                  'N/A'
               ) : (
                  <span>
                     {row.assigned_manager_details.first_name}
                     {row.assigned_manager_details.first_name}
                  </span>
               )}
            </>
         ),
      },
      {
         field: 'pub_date',
         headerName: 'Date Onboarded',
         renderCell: ({ row }) => (
            <span>
               {moment(row?.pub_date).format('MMM DD, YYYY')} |{' '}
               {moment(row?.pub_date).format('h:mm a')}
            </span>
         ),
      },
      {
         field: '',
         width: 0,
         renderCell: ({ row }) => (
            <div>
               <IconButton
                  aria-label="more"
                  aria-controls="long-menu"
                  aria-haspopup="true"
                  onClick={handleClick}
               >
                  <MoreVertIcon />
               </IconButton>
               <MenuComponent
                  anchorEl={anchorEl}
                  handleClose={handleClose}
                  open={open}
                  options={options}
                  optionsFns={[
                     // place the callbacks in position corresponding to the options index
                     () => handleOpenModal(),
                     () => callNumber(row?.account_holder_details?.phone),
                     () => sendMessageModalSection(),
                  ]}
               />
            </div>
         ),
      },
   ];

   function onRowClick({ row }) {
      setBizAccount(row.biz_account_id);
   }

   useEffect(() => {
      (async () => {
         if (currentUser.prospa_user_id) {
            try {
               setIsLoadingStats(true);
               const result = await buildPartnerAssignedBusiness(currentUser.prospa_user_id);
               const obj = _.pick(
                  result,
                  'refer_code',
                  'total_deposit_value',
                  'total_assigned_biz',
                  'total_refer_code_usage',
               );
               obj.total_deposit_value = `₦${numberWithCommas(obj.total_deposit_value)}`;
               setBuildBusinessData(obj);
            } catch (err) {
               return err;
            } finally {
               setIsLoadingStats(false);
            }
         }
      })();
   }, [currentUser]);

   useEffect(() => {
      (async () => {
         if (currentUser.prospa_user_id) {
            const currentYear = new Date().getFullYear();
            const range = (start, stop, step) =>
               Array.from({ length: (stop - start) / step + 1 }, (pick, i) => start + i * step);
            setYears(range(currentYear, currentYear - 2, -1));

            if (feedsFilter === 'weekly') {
               const { weekly_deposit } = await getBuildPartnerWeeklyDeposit(
                  {
                     start_date: `${selectedYear}-01-01`,
                     end_date: `${selectedYear}-12-31`,
                  },
                  currentUser.prospa_user_id,
               );
               setBuildPartnerFeedData(weekly_deposit);
            } else {
               const { monthly_deposit } = await getBuildPartnerMonthlyDeposit({
                  start_date: `${selectedYear}-01-01`,
                  end_date: `${selectedYear}-12-31`,
                  build_partner_manager_id: currentUser.prospa_user_id,
               });
               setBuildPartnerFeedData(monthly_deposit);
            }
         }
      })();
   }, [selectedYear, feedsFilter, currentUser]);

   function onPageChange(e, pageNumber) {
      setCurrentPage(pageNumber);
   }

   useEffect(() => {
      (async () => {
         if (currentUser.prospa_user_id) {
            try {
               setIsLoadingAprrovedData(true);
               setApprovedAccountData([]);
               setPaginationData({});
               const result = await getApprovedAccounts(
                  {
                     page: currentPage,
                  },
                  {
                     start_date: '2019-01-01',
                     end_date: moment().format('YYYY-MM-DD'),
                     target_biz_name: searchTerm,
                     build_partner_manager_id: currentUser.prospa_user_id,
                  },
               );
               setApprovedAccountData(result.data);
               setPaginationData(result);
            } catch (err) {
               return err;
            } finally {
               setIsLoadingAprrovedData(false);
            }
         }
      })();
   }, [currentPage, searchTerm, currentUser]);

   return (
      <>
         <StatsCards data={buildBusinessData} isLoading={isLoadingStats} />

         <GraphContainer>
            <div className="d-flex justify-content-between px-4 align-items-center">
               <div className="">
                  <h3 className="deposit-title">Deposits Feed</h3>

                  <p className="mb-0 moment">As of {moment().format('DD MMM YYYY, HH:mm A')}</p>
               </div>
               <div>
                  <div className="d-flex align-items-center date-picker mb-3">
                     <div
                        className={`weekly-feeds ${
                           feedsFilter === 'weekly' ? 'active' : ''
                        } feeds-btn me-3`}
                        onClick={() => {
                           setSelectedYear(`${moment().format('YYYY')}`);
                           setFeedsFilter('weekly');
                        }}
                     >
                        <p>Weekly</p>
                     </div>

                     <div
                        className={`monthly-feeds ${
                           feedsFilter === 'monthly' ? 'active' : ''
                        } feeds-btn`}
                        onClick={() => setFeedsFilter('monthly')}
                     >
                        <p>Monthly</p>
                     </div>
                  </div>
                  {feedsFilter === 'monthly' && (
                     <div className=" date-picker-group d-flex justify-content-between align-items-center">
                        <span className="">Filter by year:</span>
                        <select
                           value={selectedYear}
                           onChange={({ target }) => setSelectedYear(target.value)}
                        >
                           {years.map((item) => (
                              <option key={item} value={item}>
                                 {item}
                              </option>
                           ))}
                        </select>
                     </div>
                  )}
               </div>
            </div>
            <Graph
               data={buildPartnerFeedData.map((item) => +item.inflow)}
               categories={buildPartnerFeedData.map((item) => item.month || item.day)}
            />
         </GraphContainer>
         <TableContainer id="table">
            <TableContent>
               {isLoadingApprovedData ? (
                  <Skeleton width="30%" height="20" />
               ) : (
                  <div>
                     <h1>Assigned Accounts</h1>
                     <span className="value">{paginationData?.count || 'N/A'}</span>
                  </div>
               )}
               <div className="d-flex justify-content-between">
                  <div>
                     <Input style={{ width: '300px' }} placeholder="Search" onChange={debounce} />
                  </div>
               </div>
            </TableContent>
            <TableComponent
               columns={columns}
               rows={approvedAccountData}
               isLoading={isLoadingApprovedData}
               onPageChange={onPageChange}
               count={Math.round(paginationData.count / 15)}
               page={currentPage}
               onRowClick={onRowClick}
            />
         </TableContainer>
         <BusinessDetails
            businessAccountId={bizAccount}
            open={modalVisibility}
            onClose={() => setModalVisibility(false)}
         />
      </>
   );
};

export { ApprovedAccounts };
