import { useState } from 'react';
import { Toaster } from 'react-hot-toast';
import LoggedInDashboard from 'components/logged-in-dashboard';
import TabMenu from 'components/TabMenu';
import { useAuth } from 'hooks/auth';
import { hasPermission } from 'utils/user_access';
import { TabWrapper } from 'pages/pending-documents/style';
import {
   can_operate_settings,
   can_view_safe_interest_earnings,
   can_view_fixed_deposits,
   can_view_fixed_deposits_terms,
   can_access_withdrawal,
} from 'utils/user_actions';
import SavingTable from './Feature/savings-table';
import SavingsTerms from './Feature/savings-term';
import InterestLog from './Feature/interest-log';
import FixedDepositsTable from './Feature/fixed-deposits-table';
import FixedDepositsTerm from './Feature/fixed-deposit-terms';
import WithdrawalTab from './Feature/withdrawal-table';

function Savings() {
   const [activeTab, setActiveTab] = useState('flexi-accounts');
   const { currentUser } = useAuth();
   const { permissions } = currentUser?.access || {};

   const tabs = [
      {
         label: 'Flexi Accounts',
         value: 'flexi-accounts',
         permission: true,
      },
      {
         label: 'Fixed Accounts',
         value: 'fixed-accounts',
         permission: hasPermission(permissions, can_view_fixed_deposits),
      },
      {
         label: 'Interest Log',
         value: 'interest-log',
         permission: hasPermission(permissions, can_view_safe_interest_earnings),
      },
      {
         label: 'Flexi Rates',
         value: 'flexi-rates',
         permission: hasPermission(permissions, can_operate_settings),
      },
      {
         label: 'Fixed Rates',
         value: 'fixed-rates',
         permission: hasPermission(permissions, can_view_fixed_deposits_terms),
      },
      {
         label: 'Withdrawal ',
         value: 'withdrawal',
         permission: hasPermission(permissions, can_access_withdrawal),
      },
   ];

   return (
      <LoggedInDashboard pageTitle="Savings">
         <Toaster />
         <TabWrapper>
            <ul className="m-0 d-flex align-items-center">
               {tabs?.map(({ label, value, permission }) => {
                  if (permission) {
                     return (
                        <TabMenu
                           key={value}
                           text={label}
                           active={activeTab === value ? 'active' : ''}
                           setActiveTab={() => {
                              setActiveTab(value);
                           }}
                        />
                     );
                  }
                  return null;
               })}
            </ul>
         </TabWrapper>
         {activeTab === 'flexi-accounts' && <SavingTable />}
         {activeTab === 'fixed-accounts' && <FixedDepositsTable />}
         {activeTab === 'flexi-rates' && <SavingsTerms />}
         {activeTab === 'interest-log' && <InterestLog />}
         {activeTab === 'fixed-rates' && <FixedDepositsTerm />}
         {activeTab === 'withdrawal' && <WithdrawalTab />}
      </LoggedInDashboard>
   );
}

export default Savings;
