import React, { forwardRef } from 'react';
import Pagination from '@material-ui/lab/Pagination';
import { makeStyles } from '@material-ui/styles';
import { DataGrid } from '@material-ui/data-grid';
import { useHistory, useLocation } from 'react-router-dom';
import { string, arrayOf, bool, oneOfType, number, func } from 'prop-types';
import { Button } from 'components/button';

const useStyles = makeStyles({
   root: {
      position: 'relative',

      '& .super-app-theme--header': {
         backgroundColor: '#f8f9fa',
         fontSize: '1.4rem',
         fontFamily: "'Mark OT', sans-serif",
         top: '0',
         boxShadow: '0 2px 2px -1px rgba(0, 0, 0, 0.4)',
      },

      '& .MuiDataGrid-iconSeparator': {
         display: 'none',
      },

      '& .MuiDataGrid-row': {
         fontSize: '1.35rem',
         fontFamily: "'Mark OT', sans-serif",
         backgroundColor: '#fff',
         margin: '0 auto',
         fontWeight: 400,
         maxHeight: '20px',
         letterSpacing: '-0.1px',
      },

      '& .MuiDataGrid-root .MuiDataGrid-cell': {
         justifyContent: 'flex-start',
         whiteSpace: 'nowrap',
         overflow: 'hidden',
         textOverflow: 'ellipsis',
      },

      '& .MuiDataGrid-footerContainer': {
         display: 'none',
      },
      '& .highlighted-row': {
         backgroundColor: '#d5edf5',
      },
   },
});

export const usePaginationStyles = makeStyles({
   root: {
      '& .MuiPaginationItem-root': {
         fontSize: '1.4rem',
         fontFamily: "'Mark OT', sans-serif",
         margin: '3px 0 30px 0',
         marginLeft: 'auto',
         fontWeight: 400,
      },
   },
   ul: {
      fontSize: '1.7rem',
      fontFamily: "'Mark OT', sans-serif",
      justifyContent: 'flex-end',
   },
});

export const TableComponent = forwardRef(
   (
      {
         columns,
         rows,
         isLoading,
         checkboxSelection,
         count,
         page,
         filter,
         onPageChange,
         onSelectionModelChange,
         // selectionModel,
         pageSize,
         onRowClick,
         highlight,
         preventUrl,
      },
      ref,
   ) => {
      const classes = useStyles();
      const paginationClasses = usePaginationStyles();
      const { search } = useLocation();
      const pageUrl = !preventUrl ? new URLSearchParams(search).get('page') : page;
      const [pageInput, setPageInput] = React.useState('');
      const cols = React.useMemo(
         () =>
            columns.map((item) => ({
               width: 200,
               headerClassName: 'super-app-theme--header',
               sortable: false,
               disableClickEventBubbling: true,
               ...item,
            })),
         [columns],
      );

      const data = React.useMemo(
         () =>
            rows?.map((item) => ({
               ...item,
               id: item?.id || JSON.stringify(item),
               isBizTurnoverGreaterThan5M: [
                  'above_5m',
                  '5m-10m',
                  '10m-20m',
                  '10m-50m',
                  '20m_above',
                  '50m_above',
               ].includes(item.biz_turnover),
            })),
         [rows],
      );

      const history = useHistory();

      const handlePageInputKeyDown = (e) => {
         const pageValue = parseInt(pageInput, 10);
         if (!Number.isNaN(pageValue) && pageValue > 0 && pageValue <= count) {
            onPageChange(e, pageValue);
            setPageInput('');
            history.push(
               `${history.location.pathname}?page=${pageValue}${filter ? `&filter=${filter}` : ''}`,
            );
         }
      };

      return (
         <div className={classes.root}>
            <DataGrid
               rows={data}
               columns={cols}
               pageSize={pageSize || 15}
               disableColumnMenu
               sortingMode="server"
               hideFooterSelectedRowCount
               hideFooterPagination
               checkboxSelection={checkboxSelection}
               disableSelectionOnClick
               loading={isLoading}
               ref={ref}
               autoHeight
               headerHeight={45}
               rowHeight={45}
               onRowClick={onRowClick}
               onSelectionModelChange={(value) => onSelectionModelChange(value)}
               getRowClassName={(params) =>
                  params.row.isBizTurnoverGreaterThan5M && highlight ? 'highlighted-row' : ''
               }
            />
            {onPageChange && (
               <div className="d-flex justify-content-end mt-2">
                  <Pagination
                     classes={paginationClasses}
                     count={count}
                     page={parseInt(pageUrl, 10) || parseInt(page, 10) || 1}
                     onChange={(e, num) => {
                        const pageValue = pageInput !== '' ? parseInt(pageInput, 10) : num;
                        onPageChange(e, pageValue);
                        setPageInput('');
                        if (!preventUrl)
                           history.push(
                              `${history.location.pathname}?page=${pageValue}${
                                 filter ? `&filter=${filter}` : ''
                              }`,
                           );
                     }}
                     boundaryCount={4}
                     disabled={isLoading}
                     className="mr-3"
                  />
                  <input
                     style={{ height: '35px', width: '130px', marginRight: '4px' }}
                     placeholder="go to page..."
                     type="number"
                     value={pageInput}
                     onChange={(e) => setPageInput(e.target.value)}
                  />
                  <Button
                     size="small"
                     variant="secondary"
                     onClick={(e) => handlePageInputKeyDown(e)}
                  >
                     go
                  </Button>
               </div>
            )}
         </div>
      );
   },
);

TableComponent.propTypes = {
   columns: arrayOf(string.isRequired).isRequired,
   rows: arrayOf(oneOfType([string, number])).isRequired,
   isLoading: bool,
   checkboxSelection: bool,
   count: number.isRequired,
   page: number.isRequired,
   onPageChange: func.isRequired,
   onSelectionModelChange: func.isRequired,
   filter: string,
   onRowClick: func.isRequired,
   pageSize: number,
   highlight: bool,
   preventUrl: bool,
};

TableComponent.defaultProps = {
   isLoading: false,
   checkboxSelection: false,
   filter: null,
   pageSize: 15,
   highlight: false,
   preventUrl: false,
};
