import styled from 'styled-components/macro';

export const MessageForm = styled.form`
   border: 1px solid rgba(0, 0, 0, 0.2);
   border-radius: 4px;

   .message-input {
      input {
         border: 1px solid #bdbdbdbd;
      }
   }

   .subject-container {
      align-self: flex-end;
   }

   .message-checkbox {
      height: 16px;
      width: 16px;
      margin-left: 1rem;
      cursor: pointer;
   }

   .checkbox-label {
      cursor: pointer;
   }

   .whatsapp-btn {
      background-color: #128c7e;
   }
`;

export const MessageContainer = styled.div`
   padding: 16px;
`;

export const InputGroup = styled.div`
   display: flex;
   justify-content: space-between;
   margin-bottom: 16px;

   @media (max-width: 700px) {
      display: initial;
   }
`;

export const InputContainer = styled.div`
   width: 48%;
   margin-bottom: 1rem;

   @media (max-width: 700px) {
      width: 100%;
      margin-bottom: 20px;
   }
`;

export const CheckboxContainer = styled.div`
   margin-top: 16px;
`;

export const MessageTextArea = styled.textarea`
   border: 1px solid #bdbdbdbd;
   width: 100%;
   border-radius: 4px;
   min-height: 250px;
   outline: none;
   padding: 1rem;
`;

export const ButtonWrapper = styled.div`
   display: flex;
   justify-content: flex-end;
   border-top: 1px solid rgba(0, 0, 0, 0.2);
   padding: 1rem;
`;
