import React from 'react';
import styled from 'styled-components';
import { string, bool, func } from 'prop-types';
import NumberFormat from 'react-number-format';
import PlusIcon from 'assets/images/plus.svg';
import SubtractIcon from 'assets/images/subtract.svg';

const ExtendedAmountInputWrapper = styled.div`
   label {
      font-size: 15px;
      line-height: 12px;
      color: #8397ab;
      margin-bottom: 22px;
   }

   .extended-amount-input {
      width: 100%;
      margin-bottom: 40px;
      display: flex;
      justify-content: space-between;

      img {
         cursor: pointer;
      }

      input {
         border: none;
         outline: none;
         text-align: center;
         font-size: 20px;
         line-height: 18px;
         color: #04093f;
      }
   }
`;

export default function ExtendedAmountInput({
   handleControls,
   name,
   required,
   error,
   label,
   onChange,
   value,
   prefix,
}) {
   return (
      <ExtendedAmountInputWrapper className="withdraw-amount">
         <label htmlFor="amount">{label}</label>
         <div className="extended-amount-input">
            <img src={SubtractIcon} alt="minus" onClick={() => handleControls('decrement')} />
            <NumberFormat
               id="amount"
               value={value}
               name={name}
               required={required}
               aria-invalid={!!error}
               placeholder={label}
               thousandSeparator
               prefix={prefix || '₦'}
               className="number-format"
               inputMode="numeric"
               onValueChange={onChange}
            />
            <img src={PlusIcon} alt="plus" onClick={() => handleControls('increment')} />
         </div>
      </ExtendedAmountInputWrapper>
   );
}

ExtendedAmountInput.propTypes = {
   name: string,
   label: string,
   onChange: func.isRequired,
   value: string.isRequired,
   error: string,
   required: bool,
   prefix: string,
   handleControls: func.isRequired,
};

ExtendedAmountInput.defaultProps = {
   required: false,
   name: '',
   label: '',
   error: '',
   prefix: '',
};
