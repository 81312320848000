import styled from 'styled-components/macro';

export const Wrapper = styled.div`
   color: ${({ theme }) => theme.colors?.black01};
   .note-input {
      margin-right: 1.5rem;
   }

   .note-time {
      color: #81909d;
   }
`;

export const NotesContainer = styled.div`
   height: 60vh;
   overflow-y: scroll;
   border-bottom: 1px solid #f1f2f6;
   margin-bottom: 3.5rem;
   padding-right: 2rem;
`;

export const NoteForm = styled.form`
   display: flex;
   align-items: flex-start;
`;

export const NoteTimestamp = styled.div`
   font-size: 14px;
`;

export const NoteWrapper = styled.div`
   font-size: 14px;
   border: 1px solid #f4f5f6;
`;

export const NoteHeading = styled.div`
   background-color: #f4f5f6;
`;

export const NoteContent = styled.div`
   font-size: 14px;
   min-height: 50px;
`;
