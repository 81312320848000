import { Http } from 'services/http';
import { paramsObjectToQueryString, trimObject } from 'utils/others';
import { logActivity } from 'services/activity';
import { getDocumentType } from 'utils/business';
import axiosCustom from 'utils/axios';

export const getBusinessDetails = (businessAccountId) => {
   if (businessAccountId) return Http(`/backmen/prospa_mybiz_details/${businessAccountId}/`);
};

export const getBlockedUserAccounts = (page) => {
   return Http(`/compliance/flagged_user_accounts${paramsObjectToQueryString(page)}`);
};
export const getBlockedUserAccountsFilter = (page) => {
   return Http(
      `/compliance/flagged_user_accounts?flag_status=flagged${paramsObjectToQueryString(page)}`,
   );
};

export const getBusinessPcr = (businessAccountId) => {
   if (businessAccountId) return Http(`/backmen/admin_biz_crs_check/${businessAccountId}/`);
};

export const getUnregBusinessDetails = (businessAccountId) =>
   Http(`/backmen/just_unregistered_biz_details/${businessAccountId}/`);

export const getAdminLogs = (payload, page) =>
   Http(`/backmen/psp_activity_manager_log/${paramsObjectToQueryString(page)}`, {
      body: payload,
      method: 'POST',
      action: 'read_business_logs',
   });

export const getResetLogs = (params) =>
   Http(`/backmen/reset_accounts${paramsObjectToQueryString(params)}`, {
      action: 'can_reset_pin_or_password',
   });

export const getNotes = (businessAccountId, page) =>
   Http(`/backmen/prospa_mybiz_note/${businessAccountId}/${paramsObjectToQueryString(page)}`, {
      action: 'read_or_write_notes',
   });

export const addNote = async (payload) => {
   try {
      const response = await Http('/backmen/submit_message_biz/', {
         body: payload,
         method: 'POST',
         action: 'read_or_write_notes',
      });

      let msg = '';
      if (payload.drop_message.length > 20) {
         msg = `${payload.drop_message.substring(0, 20)}...`;
      } else {
         msg = payload.drop_message;
      }
      await logActivity(`New note added - ${msg}`, 'reg', payload.biz_account_id);
      return response;
   } catch (e) {
      throw new Error(e.message);
   }
};

export const updateBizDetails = async (payload) => {
   try {
      const response = await Http('/backmen/upper_self_biz_update/', {
         body: payload,
         method: 'POST',
         action: 'update_business_details',
      });
      await logActivity(`Updated business details`, 'reg', payload.biz_account_id);
      return response;
   } catch (e) {
      throw new Error(e.message);
   }
};

export const updatePersonalDetails = async (payload, biz_account_id) => {
   try {
      const response = await Http('/backmen/upper_self_personal_update/', {
         body: payload,
         method: 'POST',
         action: 'update_owner_details',
      });

      if (biz_account_id) {
         await logActivity(`Updated business owner details`, 'reg', biz_account_id);
      } else {
         await logActivity(
            `Updated business owner details - ${
               payload.rep_email ? `Email:${payload.rep_email}` : ''
            }`,
         );
      }
      return response;
   } catch (e) {
      throw new Error(e.message);
   }
};
export const getBusinessAccountTransactions = (page, biz_account_id) =>
   Http(`/backmen/by_biz_transactions/${biz_account_id}/${paramsObjectToQueryString(page)}`, {
      action: 'read_business_transactions',
   });

export const updatePersonalDocs = async (payload, business_id) => {
   try {
      const response = await axiosCustom({
         action: 'update_documents',
      }).post('/backmen/upper_self_personal_update/', payload);

      logActivity(`Updated Indentity File`, 'reg', business_id);

      return response;
   } catch (e) {
      throw new Error(e.response?.data?.message || e.message);
   }
};

export const uploadBusinessDocs = async (payload, type) => {
   try {
      const response = await axiosCustom({
         action: 'update_documents',
      }).post('/backmen/add_extra_kyc_doc/', payload);
      await logActivity(
         `Uploaded ${type} document for business`,
         'reg',
         payload.get('biz_account_id'),
      );
      return response;
   } catch (e) {
      throw new Error(e.response?.data?.message || e.message);
   }
};
export const sendEmail = async (payload, business_id, type) => {
   try {
      const response = await Http('/backmen/direct_send_customer_mail/', {
         body: payload,
         method: 'POST',
         action: 'send_messages',
      });
      await logActivity(`Sent email to business`, type || 'reg', business_id);
      return response;
   } catch (e) {
      throw new Error(e.message);
   }
};
export const sendSMS = async (payload, business_id, type) => {
   try {
      const response = await Http('/backmen/admin_send_sms/', {
         body: payload,
         method: 'POST',
         action: 'send_messages',
      });
      await logActivity(`Sent SMS to business`, type || 'reg', business_id);
      return response;
   } catch (e) {
      throw new Error(e.message);
   }
};
export const verifyDocument = async (payload, type, action) => {
   try {
      const response = await Http('/backmen/biz_kyc_verify/', {
         body: payload,
         method: 'POST',
         action: 'accept_or_reject_documents',
      });
      await logActivity(
         `${action.charAt(0).toUpperCase() + action.slice(1)} Document - ${getDocumentType(type)}`,
         'reg',
         payload.biz_account_id,
      );
      return response;
   } catch (e) {
      throw new Error(e.message);
   }
};

export const uploadDocument = (payload) =>
   Http('/backmen/add_extra_kyc_doc/', { body: payload, method: 'POST' });

export const triggerTinMail = async (payload) => {
   try {
      const response = await Http('/backmen/trigger_tin_mail/', { body: payload, method: 'POST' });
      await logActivity(`Sent TIN for processing.`, 'reg', payload.biz_account_id);
      return response;
   } catch (e) {
      throw new Error(e.message);
   }
};

export const approveBusiness = async (payload) => {
   try {
      const response = await Http('/backmen/prospa_approve_biz/', {
         body: payload,
         method: 'POST',
      });
      await logActivity(`Approved business`, 'reg', payload.biz_account_id);
      return response;
   } catch (e) {
      throw new Error(e.message);
   }
};
export const preapproveBusiness = async (payload) => {
   try {
      const response = await Http('/backmen/pre_approve_direct_biz/', {
         body: payload,
         method: 'POST',
      });
      await logActivity(`Preapproved business`, 'reg', payload.biz_account_id);
      return response;
   } catch (e) {
      throw new Error(e.message);
   }
};
export const verifySecurityQuestion = async (payload) => {
   try {
      const response = await Http('/backmen/moment_of_truth/', {
         body: payload,
         method: 'POST',
      });
      return response;
   } catch (e) {
      throw new Error(e.message);
   }
};
export const updateBusinessNameWithCac = async (payload) => {
   const { businessId, ...rest } = payload;
   try {
      const response = await Http('/backmen/update_biz_name/', {
         body: rest,
         method: 'POST',
         action: 'update_business_name',
      });
      await logActivity(
         `Business name was updated with the RC Number: ${payload.rc_number}`,
         'reg',
         businessId,
         'update_biz_name',
      );
      return response;
   } catch (e) {
      throw new Error(e.message);
   }
};

export const extractUtilityTokenWithApi = async (payload) => {
   const { businessId, ...rest } = payload;
   try {
      const response = await Http('/backmen/utility_token/', {
         body: rest,
         method: 'POST',
         action: 'extract_utility_token',
      });
      await logActivity(
         `Transaction (ref - ${payload.transaction_ref}) token was extracted.`,
         'reg',
         businessId,
         'extract_utility_token',
      );
      return response;
   } catch (e) {
      throw new Error(e.message);
   }
};

export const deActivate2faApi = async (payload) => {
   const { businessId, ...rest } = payload;
   try {
      const response = await Http('/backmen/deactivate_2fa/', {
         body: rest,
         method: 'PUT',
         action: 'update_business_details',
      });
      await logActivity(`2FA deactivated`, 'reg', businessId);
      return response;
   } catch (e) {
      throw new Error(e.message);
   }
};

export const reActivateMemberAccountApi = async (payload = {}) => {
   const { businessId, ...rest } = payload;
   try {
      const response = await Http('/backmen/reactivate_user/', {
         body: trimObject(rest),
         method: 'PUT',
         action: 'user_reactivation',
      });
      await logActivity(`Reactivated Member Account`, 'reg', businessId);
      return response;
   } catch (e) {
      throw new Error(e.message);
   }
};
export const resendOTPApi = async (payload) => {
   try {
      const response = await Http('/backmen/send_member_otp', {
         body: payload,
         method: 'POST',
         action: 'update_business_details',
      });
      await logActivity(`Resent OTP on user id: ${payload.user_id}`, 'reg', payload.biz_account_id);
      return response;
   } catch (e) {
      throw new Error(e.message);
   }
};

export const getBizWeeklyInflow = (param) => {
   return Http(`/backmen/admin_biz_weekly_inflow${paramsObjectToQueryString(param)}`, {
      action: 'can_view_dashboard_stats',
   });
};

export const getBizMonthlyInflow = (param) => {
   return Http(`/backmen/admin_biz_monthly_inflow${paramsObjectToQueryString(param)}`, {
      action: 'can_view_dashboard_stats',
   });
};
export const getQualifyingInformation = (businessId) => {
   return Http(`/backmen/biz_qualifying_questions/${businessId}/`);
};

export const updateQualifyingInformation = async (businessId, payload) => {
   try {
      const response = await Http(`/backmen/biz_qualifying_questions/${businessId}/`, {
         body: payload,
         method: 'PATCH',
      });
      return response;
   } catch (e) {
      throw new Error(e.message);
   }
};

export const updatePhoneVerificationStatus = async (userId, bizId, payload) => {
   try {
      const response = await Http(`/backmen/call_request_verification/${userId}/`, {
         body: payload,
         method: 'PATCH',
         action: 'can_verify_phone',
      });
      await logActivity(
         `Changed phone verification status to ${payload.phone_verification_status}`,
         'reg',
         bizId,
      );
      return response;
   } catch (e) {
      throw new Error(e.message);
   }
};
