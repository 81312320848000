/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import moment from 'moment';
import { Skeleton } from '@material-ui/lab';
import LoggedInDashboard from 'components/logged-in-dashboard';
import { TableContainer, TableContent } from 'styles/table';
import { TableComponent } from 'components/table';
import { TooltipComponent } from 'components/tooltip';
import { Button } from 'components/button';
import { MoreHoriz } from '@material-ui/icons';
import { Status } from 'styles/utils';
import { MenuComponent } from 'components/menu';
import { IconButton } from '@material-ui/core';
import { getAllPromotions } from 'services/campaigns';
import PromotionsDetails from './features/campaign-details';
import AddCampaign from './features/add-campaigns';

export const Campaigns = () => {
   const [tableData, setTableData] = useState([]);
   const [newCampaign, setNewCampaign] = useState(false);
   const [campaignId, setCampaignId] = useState(null);
   const [editCampaign, setEditCampaign] = useState(false);
   const [editDefault, setEditDefault] = useState({});
   const [modalVisibilityDetails, setModalVisibilityDetails] = useState(false);
   const [isLoading, setIsLoading] = useState(false);
   const [paginationData, setPaginationData] = useState({});
   const [currentPage, setCurrentPage] = useState(1);

   const options = ['Edit'];

   const [anchorEl, setAnchorEl] = useState(null);
   const open = Boolean(anchorEl);

   const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
   };

   const handleClose = () => {
      setAnchorEl(null);
   };

   const handleEdit = () => {
      setEditCampaign(true);
   };

   function handleOpenDetailsModal() {
      setModalVisibilityDetails(true);
   }

   function onRowClick({ row }) {
      setCampaignId(row.campaign_id);
      setEditDefault(row);
   }

   const fetchCampaigns = async (pageNumber) => {
      setIsLoading(true);
      Promise.all([getAllPromotions({ page: pageNumber || currentPage })])
         .then(([promotionData]) => {
            setTableData(promotionData?.data);
            setPaginationData(promotionData);
         })
         .finally(() => {
            setIsLoading(false);
         });
   };

   useEffect(() => {
      fetchCampaigns();
   }, [currentPage]);

   function onPageChange(e, pageNumber) {
      setCurrentPage(pageNumber);
   }

   const columns = [
      {
         field: '',
         width: 0,
         renderCell: ({ row }) => (
            <div>
               <IconButton
                  aria-label="more"
                  aria-controls="long-menu"
                  aria-haspopup="true"
                  onClick={handleClick}
               >
                  <MoreHoriz />
               </IconButton>
               <MenuComponent
                  anchorEl={anchorEl}
                  handleClose={handleClose}
                  open={open}
                  options={options}
                  optionsFns={[() => handleEdit(row.campaign_id)]}
               />
            </div>
         ),
      },
      {
         field: 'cap_title',
         headerName: 'Title',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.cap_title}>
               <span
                  style={{ cursor: 'pointer' }}
                  className="text-primary"
                  onClick={() => handleOpenDetailsModal()}
               >
                  {row?.cap_title}
               </span>
            </TooltipComponent>
         ),
      },
      {
         field: 'cap_content',
         width: 350,
         headerName: 'Description',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.cap_content}>{row?.cap_content}</TooltipComponent>
         ),
      },
      {
         field: 'cap_status',
         width: 150,
         headerName: 'Status',
         renderCell: ({ row }) => <Status status={row?.cap_status}>{row?.cap_status}</Status>,
      },
      {
         field: 'pub_date',
         headerName: 'Date Created',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.pub_date}>
               {moment(row?.pub_date).format('DD MMM, YYYY')} |{' '}
               <span style={{ opacity: '.5' }}>{moment(row?.pub_date).format('HH:mm A')}</span>
            </TooltipComponent>
         ),
      },
   ];

   return (
      <>
         <LoggedInDashboard pageTitle="Campaigns">
            <TableContainer>
               <TableContent>
                  {isLoading ? (
                     <Skeleton width="30%" height="20" />
                  ) : (
                     <div>
                        <h1> All Campaigns</h1>
                        <span className="value">{paginationData?.count || 'N/A'}</span>
                     </div>
                  )}
                  <div className="d-flex justify-content-between" />

                  <div style={{ marginLeft: '10px' }}>
                     <Button onClick={() => setNewCampaign(true)}>Create New Campaign</Button>
                  </div>
               </TableContent>
               <TableComponent
                  columns={columns}
                  rows={tableData}
                  isLoading={isLoading}
                  onPageChange={onPageChange}
                  count={Math.ceil(paginationData.count / 15)}
                  onRowClick={onRowClick}
               />
               {modalVisibilityDetails && (
                  <PromotionsDetails
                     open={modalVisibilityDetails}
                     campaigns={tableData}
                     campaignId={campaignId}
                     fetchCampaigns={fetchCampaigns}
                     onClose={() => setModalVisibilityDetails(false)}
                     setCurrentPage={setCurrentPage}
                  />
               )}
               {newCampaign && (
                  <AddCampaign
                     open={newCampaign}
                     editDefault={{}}
                     fetchCampaigns={fetchCampaigns}
                     onClose={() => setNewCampaign(false)}
                  />
               )}
               {editCampaign && (
                  <AddCampaign
                     isEdit
                     open={editCampaign}
                     editDefault={editDefault}
                     fetchCampaigns={fetchCampaigns}
                     onClose={() => setEditCampaign(false)}
                  />
               )}
            </TableContainer>
         </LoggedInDashboard>
      </>
   );
};
export default Campaigns;
