import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { useAuth } from 'hooks/auth';
import useFetchManagers from 'hooks/fetchManagers';
import { InputWrapper } from 'styles/form';
import { prospabuildpartner } from 'utils/user_types';
import { hasValue } from 'utils/validations';
import { hasPermission } from 'utils/user_access';
import {
   can_access_cards_settings,
   can_set_transaction_limit,
   can_update_global_payment_settings,
   can_view_transaction_limit,
} from 'utils/user_actions';
import { Spinner } from 'components/spinner';
import { Button } from 'components/button';
import { SelectInput } from 'components/select';
import { confirm } from 'components/confirm-box';
import { getBusinessDetails } from 'services/businessDetails';
import { reAssignActivationManager } from 'services/business';
import { getFixedDepositsAccount } from 'services/fixed-deposits';
import { assignBuildParter } from 'services/build-partner';
import TransferLimits from './transfer-limits';
import FixedDeposits from './fixed-deposits';
import { BillingRates } from './billing';
import InternationalPaymentToggle from './international-payment-toggle';
import CardToggle from './card-toggle';
import { BusinessInboundPaymentFees } from './inbound-payment-fees';

export default function Settings({ businessAccountId }) {
   const { currentUser } = useAuth();
   const { permissions } = currentUser?.access || {};
   const { managers } = useFetchManagers();
   const [buildPartner, setBuildPartner] = useState('');
   const [fdDetails, setFdDetails] = useState({});
   const [activationManager, setActivationManager] = useState('');
   const [assigningManagers, setAssigningManagers] = useState(false);
   const [bizDetails, setBizDetails] = useState({});
   const [loading, setLoading] = useState(false);

   const fetchBusinessDetails = async () => {
      if (businessAccountId) {
         setLoading(true);
         try {
            const result = await getBusinessDetails(businessAccountId);
            setBizDetails(result.data);
         } catch (err) {
            toast.error(err?.message);
         }
         setLoading(false);
      }
   };
   const fetchFixedDepositsAccount = async () => {
      try {
         const response = await getFixedDepositsAccount({
            biz_account_id: businessAccountId,
            status: 'pending&status=on_going',
         });
         setFdDetails(response.results[0]);
      } catch (err) {
         toast.error(err?.message);
      }
   };

   useEffect(() => {
      fetchBusinessDetails();
      fetchFixedDepositsAccount();
   }, [businessAccountId]);

   useEffect(() => {
      if (managers) {
         const { assigned_manager_details, build_manager_details } = bizDetails;
         if (assigned_manager_details) {
            const activation_manager_id = assigned_manager_details.manager_id;
            setActivationManager(managers.find((item) => item.value === activation_manager_id));
         }

         if (build_manager_details) {
            const build_partner_id = build_manager_details.manager_id;
            setBuildPartner(managers.find((item) => item.value === build_partner_id));
         }
      }
   }, [bizDetails, managers]);

   const { assigned_manager_details, build_manager_details } = bizDetails;
   const activationsChanged = activationManager?.value !== assigned_manager_details?.manager_id;
   const buildPartnerChanged = buildPartner?.value !== build_manager_details?.manager_id;

   const changed = activationsChanged || buildPartnerChanged;

   const assignManagers = async () => {
      confirm({
         confirmText: 'Do you want to proceed to assign these managers?',
         isLoading: false,
         onConfirm: async () => {
            setAssigningManagers(true);
            try {
               const buildPartnerPayload = {
                  prospa_manager_id: buildPartner?.value,
                  build_biz: [businessAccountId],
               };

               if (hasValue(buildPartner) && buildPartnerChanged) {
                  await assignBuildParter(buildPartnerPayload);
               }

               if (hasValue(activationManager && activationsChanged)) {
                  await reAssignActivationManager(businessAccountId, {
                     assign_partner: activationManager.value,
                  });
               }
               toast.success('Managers have been successfully assigned');
               fetchBusinessDetails();
            } catch ({ message }) {
               toast.error(message);
            }
            setAssigningManagers(false);
         },
      });
   };

   return loading ? (
      <Spinner />
   ) : (
      <div>
         <form className="pb-5">
            <h3>Managers</h3>
            <InputWrapper className="grid-2-2">
               <SelectInput
                  onChange={(val) => setBuildPartner(val)}
                  label="Build Partner"
                  options={managers?.filter((manager) => manager.user_type === prospabuildpartner)}
                  value={managers.find((item) => item.value === buildPartner?.value)}
               />

               <SelectInput
                  onChange={(val) => setActivationManager(val)}
                  label="Activation Manager"
                  options={managers}
                  value={managers.find((item) => item.value === activationManager?.value)}
               />
            </InputWrapper>
            <div className="d-flex justify-content-end">
               <Button
                  type="button"
                  isLoading={assigningManagers}
                  onClick={assignManagers}
                  disabled={!changed}
               >
                  Assign Managers
               </Button>
            </div>
         </form>
         {hasPermission(permissions, can_update_global_payment_settings) && (
            <InternationalPaymentToggle bizDetails={bizDetails} />
         )}
         {hasPermission(permissions, can_access_cards_settings) && (
            <CardToggle businessAccountId={businessAccountId} />
         )}
         {hasPermission(permissions, can_view_transaction_limit) && (
            <TransferLimits
               limits={{}}
               bizDetails={bizDetails}
               canEdit={hasPermission(permissions, can_set_transaction_limit)}
            />
         )}
         <FixedDeposits
            businessAccountId={businessAccountId}
            bizDetails={bizDetails}
            fdDetails={fdDetails}
            fetchFixedDepositsAccount={fetchFixedDepositsAccount}
         />
         <BillingRates businessAccountId={businessAccountId} />

         <BusinessInboundPaymentFees businessAccountId={businessAccountId} />
      </div>
   );
}

Settings.propTypes = {
   businessAccountId: PropTypes.number.isRequired,
};
