/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import toast from 'react-hot-toast';
import { IconButton } from '@material-ui/core';
import { MoreHoriz } from '@material-ui/icons';
import { TableContainer, TableContent } from 'styles/table';
import { dealWithUserApi } from 'services/business';
import { getComplianceBlockedAccounts } from 'services/transactions';
import { TooltipComponent } from 'components/tooltip';
import { confirm } from 'components/confirm-box/confirm-box';
import { MenuComponent } from 'components/menu';
import { Skeleton } from '@material-ui/lab';
import { TableComponent } from 'components/table';
import { numberWithCommas } from 'utils/others';
import { useBizDetailsModal } from 'hooks/bizDetailsModal';
import TranasactionModal from 'components/TransactionModal';
import BusinessDetails from 'containers/business-details';
import TransactionsFilterModal from 'containers/filter-modal/nibss-transactions-filter';
import { hasValue } from 'utils/validations';

const intialFilter = {
   created_after: moment().format('YYYY-MM-01'),
   created_before: moment().format('YYYY-MM-DD'),
};

function BlockedAccounts() {
   const { setActiveTab } = useBizDetailsModal();
   const { search } = useLocation();
   const page = new URLSearchParams(search).get('page');
   const [tableData, setTableData] = useState([]);
   const [isLoading, setIsLoading] = useState(false);
   const [paginationData, setPaginationData] = useState({});
   const [currentPage, setCurrentPage] = useState(page);
   const [transactionDetail] = useState('');
   const [transactionModal, setTransactionModal] = useState(false);
   const [modalVisibilityFilter, setModalVisibilityFilter] = useState(false);
   const [filterData, setFilterData] = useState(intialFilter);
   const [filterPayload, setFilterPayload] = useState({});
   const [modalVisibility, setModalVisibility] = useState(false);
   const [bizAccount, setBizAccount] = useState('');

   const [anchorEl, setAnchorEl] = useState(null);
   const open = Boolean(anchorEl);

   const handleClose = () => {
      setAnchorEl(null);
   };

   const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
   };

   const options = ['View', 'Unblock Account'];

   function handleOpenModal() {
      setActiveTab('overview');
      setModalVisibility(true);
   }

   function onRowClick({ row }) {
      setBizAccount(row.business_accounts?.biz_account_id);
   }

   // const onTransactionClick = (data) => {
   //    setTransactionModal(true);
   //    setTransactionDetail(data);
   // };

   const fetchBlockedAccounts = async (pageNumber) => {
      setIsLoading(true);
      setTableData([]);
      setPaginationData([]);
      Promise.all([
         getComplianceBlockedAccounts({ page: pageNumber || currentPage, ...filterPayload }),
      ])
         .then(([businessAccountsData]) => {
            setTableData(businessAccountsData?.results);
            setPaginationData(businessAccountsData);
         })
         .finally(() => {
            setIsLoading(false);
         });
   };

   function unblockAccount() {
      confirm({
         confirmText: 'Are you sure you want to unblock this account?',
         isLoading: false,
         onConfirm: async () => {
            try {
               const selected = tableData.find(
                  (item) => item.business_accounts?.biz_account_id === bizAccount,
               );
               const response = await dealWithUserApi(
                  {
                     prospa_user_id: selected.user,
                     action_word: 'unblock_with_rule',
                     biz_account_id: bizAccount,
                     message: 'Business account block for compliance lifted',
                  },
                  true,
               );
               toast.success(response?.message || 'Operation Successful');
               await fetchBlockedAccounts();
            } catch (err) {
               toast.error(err.message || err.custom_message);
            }
         },
      });
   }

   // const exportTransaction = async () => {
   //    if (isExporting) return null;

   //    setisExporting(true);
   //    try {
   //       const response = await exportNibssTransactions(filterPayload);
   //       toast.success(
   //          response?.message ||
   //             'Export is currently processing and will available for download shortly',
   //       );
   //    } catch (e) {
   //       toast.error(e.message);
   //    }
   //    setisExporting(false);
   // };

   const columns = [
      {
         field: '',
         width: 0,
         renderCell: ({ row }) => (
            <div>
               <IconButton
                  aria-label="more"
                  aria-controls="long-menu"
                  aria-haspopup="true"
                  onClick={handleClick}
               >
                  <MoreHoriz />
               </IconButton>
               <MenuComponent
                  anchorEl={anchorEl}
                  handleClose={handleClose}
                  open={open}
                  options={options}
                  optionsFns={[
                     // place the callbacks in position corresponding to the options index
                     () => handleOpenModal(row.business_accounts?.biz_account_id),
                     () => unblockAccount(row.business_accounts?.biz_account_id),
                  ]}
               />
            </div>
         ),
      },
      {
         field: 'business_accounts.biz_friendly_name',
         headerName: 'Business Name',
         renderCell: ({ row }) => (
            <TooltipComponent title={row.business_accounts?.biz_friendly_name}>
               <span
                  style={{ cursor: 'pointer' }}
                  className="text-primary"
                  // onClick={() => onTransactionClick(row)}
                  onClick={() => handleOpenModal(row.biz_account_id)}
               >
                  {row.business_accounts?.biz_friendly_name}
               </span>
            </TooltipComponent>
         ),
      },
      {
         field: 'user_name',
         headerName: 'Owner Name',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.user_name}>{row?.user_name}</TooltipComponent>
         ),
      },
      {
         field: 'description',
         headerName: 'Reason',
         width: 350,
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.description}>
               {hasValue(row?.description) ? row?.description : 'N/A'}
            </TooltipComponent>
         ),
      },
      {
         field: 'business_accounts.biz_type',
         headerName: 'Business Type',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.business_accounts?.biz_type}>
               {row?.business_accounts?.biz_type}
            </TooltipComponent>
         ),
      },
      {
         field: 'transaction_details.tx_ref',
         headerName: 'Transaction Ref',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.transaction_details?.tx_ref}>
               {row?.transaction_details?.tx_ref}
            </TooltipComponent>
         ),
      },
      {
         field: 'transaction_details.nibss_session_id',
         headerName: 'Session ID',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.transaction_details?.nibss_session_id || 'N/A'}>
               {row?.transaction_details?.nibss_session_id || 'N/A'}
            </TooltipComponent>
         ),
      },
      {
         field: 'transaction_details.transfer_details.amount',
         headerName: 'Amount',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.transaction_details?.transfer_details?.amount}>
               ₦{numberWithCommas(row?.transaction_details?.transfer_details?.amount)}
            </TooltipComponent>
         ),
      },
   ];

   useEffect(() => {
      fetchBlockedAccounts();
   }, [currentPage, filterPayload]);

   function onPageChange(e, pageNumber) {
      setCurrentPage(pageNumber);
   }

   // function handleSearch({ target }) {
   //    setSearchTerm(target.value);
   // }

   return (
      <>
         <TableContainer>
            <TableContent>
               <div className="d-flex justify-content-between w-100 align-items-center">
                  {isLoading ? (
                     <Skeleton width="30%" height="20" />
                  ) : (
                     <div>
                        <h1>Accounts</h1>
                        <span className="value">{paginationData?.count || 0}</span>
                     </div>
                  )}
                  {/* <div className="d-flex align-items-center">
                     <FilterContainer className="me-3" onClick={exportTransaction}>
                        <>
                           {isExporting ? 'Exporting...' : 'Export Data'}{' '}
                           <ImportExportIcon style={{ marginLeft: '10px' }} />
                        </>
                     </FilterContainer>
                     <FilterContainer onClick={() => setModalVisibilityFilter(true)}>
                        Filter By <FilterListIcon style={{ marginLeft: '10px' }} />
                     </FilterContainer>
                  </div> */}
               </div>
            </TableContent>
            <TableComponent
               columns={columns}
               rows={tableData}
               isLoading={isLoading}
               onPageChange={onPageChange}
               count={Math.ceil(paginationData.count / 15)}
               onRowClick={onRowClick}
            />
         </TableContainer>
         <TranasactionModal
            open={transactionModal}
            onClose={() => setTransactionModal(false)}
            data={transactionDetail}
         />
         {modalVisibilityFilter && (
            <TransactionsFilterModal
               open={modalVisibilityFilter}
               onClose={() => setModalVisibilityFilter(false)}
               setCurrentPage={setCurrentPage}
               filterData={filterData}
               setFilterData={setFilterData}
               setFilterPayload={setFilterPayload}
            />
         )}

         {modalVisibility && (
            <BusinessDetails
               businessAccountId={bizAccount}
               open={modalVisibility}
               onClose={() => setModalVisibility(false)}
            />
         )}
      </>
   );
}

export default BlockedAccounts;
