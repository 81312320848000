import { useEffect, useState } from 'react';
import PropTypes, { func, objectOf, bool, string } from 'prop-types';
import Modal from 'components/modal';
import { SelectInput } from 'components/select';
import { Input } from 'components/input';
import { toast } from 'react-hot-toast';
import { confirm } from 'components/confirm-box';
import { InputWrapper } from 'styles/form';
import { Button } from 'components/button';
import { createNewChildCampaign, updateChildCampaignDetails } from 'services/campaigns';

function AddChildCampaign({
   isEdit = false,
   campaignDetails,
   childCampaignDetails,
   fetchCampaigns,
   onClose,
   open,
}) {
   const [updatingData, setUpdatingData] = useState(false);
   const [loading, setLoading] = useState(false);
   const [imageSelected, setImageSelected] = useState([]);
   const [smallImageSelected, setSmallImageSelected] = useState([]);
   const [newData, setNewData] = useState({});
   const [allowButton, setAllowButton] = useState(false);

   const statusOptions = [
      {
         value: 'active',
         label: 'Active',
      },
      {
         value: 'expired',
         label: 'Expired',
      },
   ];

   const typeOptions = [
      {
         value: 'internal',
         label: 'Internal',
      },
      {
         value: 'external',
         label: 'External',
      },
   ];

   const { child_status, child_location, child_button, child_type } = childCampaignDetails || {};

   const isValid = newData.child_status;

   const isValid2 = imageSelected.length !== 0;

   const detailsUnchanged = () =>
      newData.child_location === child_location &&
      newData.child_button === child_button &&
      newData.child_status === child_status &&
      newData.child_type === child_type &&
      imageSelected.length === 0 &&
      smallImageSelected.length === 0;

   function onChange(name, data) {
      setNewData({
         ...newData,
         [name]: data.value,
      });
   }

   const addNewChildCampaign = async (e) => {
      e.preventDefault();
      confirm({
         confirmText: 'Are you sure you want to perform this action?',
         isLoading: false,
         onConfirm: async () => {
            setLoading(true);
            const formData = new FormData();
            formData.append('campaign_id', campaignDetails?.campaign_id);
            if (imageSelected) formData.append('child_image', imageSelected);
            if (allowButton) formData.append('child_location', newData?.child_location);
            if (allowButton) formData.append('child_button', newData?.child_button);
            if (allowButton) formData.append('child_type', newData?.child_type);
            if (!allowButton) formData.append('child_location', 'n/a');
            if (smallImageSelected) formData.append('child_small_image', smallImageSelected);
            try {
               const result = await createNewChildCampaign(formData);
               if (result.status === 201) {
                  fetchCampaigns();
                  toast.success(addNewChildCampaign?.message || 'Created Successfully', {
                     duration: 3000,
                  });
               }
               setUpdatingData(false);
               onClose();
            } catch (err) {
               toast.error(err.message || 'An error has occurred');
               setUpdatingData(false);
            }
            setLoading(false);
         },
      });
   };

   const editCampaignDetails = async (e) => {
      e.preventDefault();
      confirm({
         confirmText: 'Are you sure you want to perform this action?',
         isLoading: false,
         onConfirm: async () => {
            const formData = new FormData();
            formData.append('child_location', newData?.child_location);
            formData.append('child_button', newData?.child_button);
            formData.append('child_status', newData?.child_status);
            formData.append('child_type', newData?.child_type);
            formData.append('child_campaign_id', childCampaignDetails?.child_campaign_id);
            if (imageSelected) formData.append('child_image', imageSelected);
            if (smallImageSelected) formData.append('child_small_image', smallImageSelected);
            setUpdatingData(true);
            try {
               const result = await updateChildCampaignDetails(formData);
               if (result.status === 200) {
                  fetchCampaigns();
                  toast.success(editCampaignDetails?.message || 'Details Updated Successfully', {
                     duration: 3000,
                  });
                  onClose();
               }
               setUpdatingData(false);
            } catch (err) {
               toast.error(err.message || err.custom_message);
               setUpdatingData(false);
            }
         },
      });
   };

   useEffect(() => {
      setNewData(childCampaignDetails);
   }, [childCampaignDetails]);

   return (
      <Modal
         open={open}
         onClose={onClose}
         title={isEdit ? 'Edit Child Campaign Details' : 'Create New Child Campaign'}
      >
         <form onSubmit={isEdit ? editCampaignDetails : addNewChildCampaign}>
            {isEdit && (
               <InputWrapper className="grid-2-2">
                  <SelectInput
                     label="Campaign status"
                     options={statusOptions}
                     value={statusOptions.filter((item) => item.value === newData.child_status)}
                     onChange={(target) => onChange('child_status', target)}
                  />
               </InputWrapper>
            )}

            <InputWrapper className="grid-2-2">
               <div>
                  <h2>Upload Image</h2>
                  <div className="input-group mt-4 doc-file-input-group align-items-center">
                     <input
                        type="file"
                        accept="image\*"
                        className="form-control file-input"
                        aria-label="Upload"
                        onChange={(e) => setImageSelected(e.target.files[0])}
                     />
                  </div>
               </div>
            </InputWrapper>

            {!isEdit && (
               <InputWrapper className="grid-2-2">
                  <label htmlFor="for-button-text">
                     <input
                        id="for-button-text"
                        type="checkbox"
                        checked={allowButton}
                        style={{ cursor: 'pointer' }}
                        onChange={() => setAllowButton(!allowButton)}
                     />
                     <span className="ms-3">Add Button & Location</span>
                  </label>
               </InputWrapper>
            )}

            {(allowButton || isEdit) && (
               <>
                  <InputWrapper className="grid-2-2">
                     <div>
                        <Input
                           label="Button Text"
                           name="child_button"
                           value={newData.child_button}
                           className={Input}
                           onChange={(e) => {
                              onChange('child_button', e.target);
                           }}
                        />
                     </div>
                     <div>
                        <SelectInput
                           label="Campaign Type"
                           options={typeOptions}
                           value={typeOptions.filter((item) => item.value === newData.child_type)}
                           onChange={(target) => onChange('child_type', target)}
                        />
                     </div>
                  </InputWrapper>
                  <InputWrapper className="grid-2-2">
                     <div>
                        <Input
                           label="Location"
                           name="child_location"
                           value={newData.child_location}
                           className={Input}
                           onChange={(e) => {
                              onChange('child_location', e.target);
                           }}
                        />
                     </div>
                     <div>
                        <h2>Upload Small Image</h2>
                        <div className="input-group mt-4 doc-file-input-group align-items-center">
                           <input
                              type="file"
                              accept="image\*"
                              className="form-control file-input"
                              aria-label="Upload"
                              onChange={(e) => setSmallImageSelected(e.target.files[0])}
                           />
                        </div>
                     </div>
                  </InputWrapper>
               </>
            )}

            <div className="d-flex justify-content-end pt-5">
               {isEdit ? (
                  <Button
                     type="submit"
                     isLoading={updatingData}
                     disabled={!isValid || detailsUnchanged() || updatingData || loading}
                  >
                     Update Details
                  </Button>
               ) : (
                  <Button
                     type="submit"
                     isLoading={loading}
                     disabled={!isValid2 || updatingData || loading}
                  >
                     Add New Data
                  </Button>
               )}
            </div>
         </form>
      </Modal>
   );
}

AddChildCampaign.propTypes = {
   isEdit: bool.isRequired,
   onClose: func.isRequired,
   open: func.isRequired,
   campaignDetails: PropTypes.objectOf(PropTypes.string).isRequired,
   childCampaignDetails: objectOf(string).isRequired,
   fetchCampaigns: func.isRequired,
};

export default AddChildCampaign;
