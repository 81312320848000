/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import clsx from 'classnames';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Button } from 'components/button';
import BackArrowIcon from 'assets/images/back-arrow.svg';
import TickIcon from 'assets/images/tick.svg';
import BankIcon from 'assets/images/bank.svg';

const SelectBankAccountWrapper = styled.div`
   .modal-title {
      font-size: 18px;
      line-height: 18px;
      color: #04093f;
   }

   .modal-sub {
      font-size: 12px;
      line-height: 18px;
      color: #82849f;
      margin-bottom: 20px;
   }
   .subaccount-list {
   }

   .subaccount-item {
      border: 1px solid #d9dae2;
      padding: 15px;
      border-radius: 4px;
      cursor: pointer;
      margin-bottom: 20px;

      :hover {
         border: 1px solid #3cba54;
      }
   }

   .subaccount-item.active {
      .status-flag {
         background: #3cba54;
         display: flex;
         align-items: center;
         justify-content: center;
      }
   }

   .back {
      margin-bottom: 35px;
      display: block;
      cursor: pointer;
   }

   .bank-icon {
      background: #f4f8fb;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      margin-right: 15px;

      img {
         width: 16px;
      }
   }

   .text-content {
      h6 {
         font-size: 15px;
         line-height: 12px;
         color: #1e2442;
         margin-bottom: 8px;
         text-transform: Capitalize;
      }
      p {
         font-size: 12px;
         line-height: 10px;
         margin-bottom: 8px;
         color: #8397ab;
         margin-bottom: 0;
      }
   }

   .status-flag {
      background: #ffffff;
      border: 1px solid #e6ebee;
      width: 24px;
      height: 24px;
      border-radius: 50%;
   }
`;

export default function SelectBankAccount({ loading, bizWallets, onSubmit, onBack }) {
   const [activeAccount, setActiveAccount] = useState(null);

   const handleNext = () => {
      onSubmit({ biz_wallet_id: activeAccount?.biz_wallet_id });
   };

   useEffect(() => {
      if (bizWallets) {
         setActiveAccount(bizWallets[0]);
      }
   }, [bizWallets]);

   const valid = activeAccount;

   return (
      <div>
         <SelectBankAccountWrapper>
            <img src={BackArrowIcon} alt="back" className="back" onClick={onBack} />

            <div>
               <h3 className="modal-title">Prospa Accounts</h3>
               <p className="modal-sub">Select the Prospa Accounts you want to withdraw funds to</p>
            </div>
            <div className="subaccount-list">
               {bizWallets?.map((wallet) => {
                  const {
                     biz_wallet_id,
                     preferred_name,
                     biz_account_number,
                     goodnews_account_number,
                     goodnews_full_bank_name,
                     partner_bank_name,
                     available_balance,
                  } = wallet;

                  return (
                     <div
                        className={clsx('subaccount-item d-flex align-items-center', {
                           active: activeAccount?.biz_wallet_id === biz_wallet_id,
                        })}
                        onClick={() => setActiveAccount(wallet)}
                        key={biz_wallet_id}
                     >
                        <div className="bank-icon">
                           <img src={BankIcon} alt="back" />
                        </div>
                        <div className="d-flex justify-content-between w-100 align-items-center">
                           <div className="text-content">
                              <h6>{preferred_name}</h6>
                              <p>
                                 {goodnews_account_number
                                    ? `${goodnews_full_bank_name.slice(0, 20)}`
                                    : `${partner_bank_name.slice(0, 20)} - ${biz_account_number}`}
                                 -<span style={{ color: '#32CD32' }}> ₦{available_balance}</span>
                              </p>
                           </div>
                           <div className="status-flag active">
                              <img src={TickIcon} alt="tick" />
                           </div>
                        </div>
                     </div>
                  );
               })}
            </div>

            <Button disabled={!valid || loading} fullWidth onClick={handleNext}>
               Continue
            </Button>
         </SelectBankAccountWrapper>
      </div>
   );
}

SelectBankAccount.propTypes = {
   loading: PropTypes.bool.isRequired,
   onSubmit: PropTypes.func.isRequired,
   bizWallets: PropTypes.objectOf(PropTypes.any).isRequired,
   onBack: PropTypes.func.isRequired,
};
