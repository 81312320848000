import { useEffect, useCallback, useState } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessage } from '@hookform/error-message';
import useTransactionsSettings from 'hooks/transactionsSettings';
import { NumberInput } from 'components/input';
import { confirm } from 'components/confirm-box';
import { Button } from 'components/button';
import { updateTransactionSettings } from 'services/transactions';
import classNames from 'classnames';

const defaultValues = {
   no_of_days: 0,
};

const errMessage = 'Please enter a valid value';

export default function Settings() {
   const { fetchAllSettings, systemSettings } = useTransactionsSettings();
   const [loading, setLoading] = useState(false);

   useEffect(() => {
      fetchAllSettings();
   }, []);

   const settingCodes = ['OTHER__REFER_SETTINGS'];
   const OTHER__REFER_SETTINGS = systemSettings.find((item) => settingCodes.includes(item.name));

   const schema = yup.object().shape({
      no_of_days: yup.number(errMessage).positive(errMessage).required(errMessage),
   });

   const {
      register,
      handleSubmit,
      setValue,
      trigger,
      formState: { isValid },
      watch,
      formState: { errors },
   } = useForm({
      defaultValues,
      mode: 'onChange',
      resolver: yupResolver(schema),
      shouldFocusError: true,
   });

   useEffect(() => {
      register('no_of_days');
   }, []);

   useEffect(() => {
      if (OTHER__REFER_SETTINGS) {
         setValue('no_of_days', OTHER__REFER_SETTINGS.value?.no_of_days);
      }
   }, [OTHER__REFER_SETTINGS]);

   const no_of_days = watch('no_of_days');

   const renderErrorMessage = (name) => (
      <ErrorMessage
         errors={errors}
         name={name}
         render={({ message }) => <p className="input-error-message text-danger">{message}</p>}
      />
   );

   const sharedOnChange = useCallback(async ({ name, value }) => {
      setValue(name, value);
      await trigger(name);
   }, []);

   const onSubmit = async (data) => {
      confirm({
         proceedBtnText: 'Proceed',
         cancelBtnText: 'Cancel',
         confirmText: `Only confirm if you are sure you want to take this action`,
         onConfirm: async () => {
            setLoading(true);
            try {
               const payload = {
                  ...OTHER__REFER_SETTINGS,
                  value: {
                     no_of_days: data.no_of_days,
                  },
               };
               const response = await updateTransactionSettings(
                  OTHER__REFER_SETTINGS.id,
                  payload,
                  'Refer and Earn days updated',
               );
               confirm({
                  proceedBtnText: 'Close',
                  hide: { cancel: true },
                  confirmText: response?.message || `Action successful!`,
                  onConfirm: fetchAllSettings,
               });
            } catch (e) {
               confirm({
                  cancelBtnColor: '#0E55CF',
                  cancelBtnText: 'Close',
                  hide: { proceed: true },
                  confirmText: e?.message || `Action failed!`,
               });
            }
            setLoading(false);
         },
      });
   };

   return (
      <div className="row pt-4">
         <form className={classNames('col-lg-4 mb-5')} onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-4">
               <h3 className="mb-3">Refer And Earn</h3>

               <NumberInput
                  label="Number of Days"
                  className="wrap-label-input"
                  placeholder="Number of Days"
                  prefix="hidden"
                  name="no_of_days"
                  value={no_of_days}
                  onChange={({ floatValue }) =>
                     sharedOnChange({ name: 'no_of_days', value: floatValue || 0 })
                  }
               />
               {renderErrorMessage('no_of_days')}
            </div>
            <Button
               className="w-100"
               type="submit"
               isLoading={loading}
               disabled={!isValid || loading}
            >
               Update
            </Button>
         </form>
      </div>
   );
}
