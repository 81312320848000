import LoggedInDashboard from 'components/logged-in-dashboard';
import { useState } from 'react';
import { ApprovedAccounts } from 'containers/business-account';
import { TabWrapper } from 'pages/build-partner/style';
import { Target } from 'containers/business-account/target';

const BusinessPartner = () => {
   const [activeTab, setActiveTab] = useState('active-customers');

   function handleView() {
      let content;
      switch (activeTab) {
         case 'active-customers':
            content = <ApprovedAccounts />;
            break;
         default:
         case 'target':
            content = <Target />;
      }

      return content;
   }

   return (
      <LoggedInDashboard pageTitle="Business Partner">
         <TabWrapper>
            <ul className="m-0 d-flex align-items-center">
               <li
                  className={`${
                     activeTab === 'active-customers' ? 'active' : ''
                  } pending-doc-overview pending-tab-action`}
                  onClick={() => setActiveTab('active-customers')}
               >
                  Active Customers
               </li>
               <li
                  className={`${
                     activeTab === 'target' ? 'active' : ''
                  } pending-doc-overview pending-tab-action`}
                  onClick={() => setActiveTab('target')}
               >
                  Target
               </li>
            </ul>
         </TabWrapper>
         {handleView()}
      </LoggedInDashboard>
   );
};

export default BusinessPartner;
