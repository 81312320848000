import { Alert } from '@material-ui/lab';
import { AnimatePresence } from 'framer-motion';
import { oneOf, string } from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AlertMessageContainer } from './alert-message.style';

const useStyles = makeStyles({
   root: {
      fontSize: '1.4rem',
      fontFamily: "'Mark OT', sans-serif",
   },
});

const AlertMessage = ({ message, variant }) => {
   const classes = useStyles();
   if (!message) {
      return null;
   }
   return (
      <AnimatePresence initial key={message}>
         <AlertMessageContainer
            initial="pageInitial"
            animate="pageAnimate"
            exit="pageExit"
            key={message}
            variants={{
               pageInitial: {
                  opacity: 1,
                  x: 0,
                  y: 5,
               },
               pageAnimate: {
                  opacity: 1,
                  x: 0,
                  y: 0,
               },
               pageExit: {
                  opacity: 0,
                  y: -5,
               },
            }}
         >
            <Alert severity={variant} className={classes.root}>
               {message}
            </Alert>
         </AlertMessageContainer>
      </AnimatePresence>
   );
};

AlertMessage.propTypes = {
   message: string,
   variant: oneOf(['error', 'warning', 'success', 'info']).isRequired,
};

AlertMessage.defaultProps = {
   message: '',
};

export { AlertMessage };
