import styled from 'styled-components';

export const Wrapper = styled.div`
   border-radius: 6px;
   background: #fdf2e7;
   border: 1px dashed #ff980099;
   .warning-icon {
      width: 50px;
      align-self: center;
   }
   ul {
      li {
         font-size: 14px;
      }
   }
`;
