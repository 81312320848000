/* eslint-disable eqeqeq */
// import Skeleton from '@material-ui/lab/Skeleton';
import { Skeleton } from '@material-ui/lab';
import { string, objectOf, bool } from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { numberWithCommas } from 'utils/others';
import { SelectInput } from 'components/select';
import { CardsContainer, Card } from './stats-card.style';

const useStyles = makeStyles({
   root: {
      borderRadius: '10px',
      background: '#f8f9fa',
   },
});

export function StatsCards({ data, isLoading, filters }) {
   const classes = useStyles();

   function handleValue(element) {
      let content;
      if (element[1] == 0) {
         content = '0';
      } else if (element[1]) {
         content = numberWithCommas(element[1]);
      } else {
         content = 'N/A';
      }
      return content;
   }

   return (
      <CardsContainer gridNumber={Object.entries(data).length || 3} className="fle">
         {isLoading
            ? Array(3)
                 .fill({})
                 .map(() => (
                    <Skeleton
                       classes={classes}
                       variant="rect"
                       width="100%"
                       height={118}
                       animation="wave"
                    />
                 ))
            : Object.entries(data).map((element) => {
                 const title = element[0].replaceAll('_', ' ');
                 const filter = filters ? filters[element[0]] : null;
                 return (
                    <Card>
                       <div className="d-flex justify-content-between align-items-center">
                          <div className="text">{title}</div>
                          {filter && (
                             <div className="stat-filter">
                                <SelectInput
                                   styles={{
                                      option: (provided) => ({
                                         ...provided,
                                         fontSize: 8,
                                      }),
                                   }}
                                   isSearchable={false}
                                   options={filter.options}
                                   value={filter.value}
                                   onChange={filter.onChange}
                                />
                             </div>
                          )}
                       </div>
                       <div className="value">{handleValue(element)}</div>
                    </Card>
                 );
              })}
      </CardsContainer>
   );
}

StatsCards.propTypes = {
   data: objectOf(string).isRequired,
   isLoading: bool.isRequired,
   filters: objectOf(string).isRequired,
};
