import { useEffect, useState } from 'react';
import { bool, func, number, string } from 'prop-types';
import { Navigation, NavigationTab } from 'styles/layout';
import { Spinner } from 'components/spinner';
import Modal from 'components/modal';
import Notes from 'containers/business-details/notes';
import { getSingleCard } from 'services/cards';
import { getBusinessDetails } from 'services/businessDetails';
import PhysicalCardOverview from './physical-card-overview';
import VirtualCardOverview from './virtual-card-overview';
import CardTransactions from '../card-transactions';
import CardSettings from '../card-settings';

const CardDetails = ({ open, cardId, onClose, noCloseIcon, activeFilter, type }) => {
   const [activeTab, setActiveTab] = useState('overview');
   const [isLoading, setIsLoading] = useState(false);
   const [cardDetails, setCardDetails] = useState();
   const [bizDetails, setBizDetails] = useState({});

   async function fetchCard() {
      setIsLoading(true);
      try {
         const card = await getSingleCard(cardId);
         const { business_id } = card;
         setCardDetails(card);
         if (business_id) {
            const result = await getBusinessDetails(business_id);
            setBizDetails(result.data);
         }
      } catch (err) {
         return err;
      } finally {
         setIsLoading(false);
      }
   }

   useEffect(() => {
      fetchCard();
   }, [cardId]);

   const activePage = () => {
      switch (activeTab) {
         case '':
         case 'overview':
            return type === 'physical' ? (
               <PhysicalCardOverview
                  bizDetails={bizDetails}
                  cardDetails={cardDetails}
                  fetchCard={fetchCard}
                  activeFilter={activeFilter}
               />
            ) : (
               <VirtualCardOverview
                  bizDetails={bizDetails}
                  cardDetails={cardDetails}
                  fetchCard={fetchCard}
                  activeFilter={activeFilter}
               />
            );
         case 'transactions':
            return <CardTransactions cardId={cardId} />;
         case 'notes':
            return <Notes businessAccountId={bizDetails.biz_account_id} />;
         case 'settings':
            return <CardSettings cardDetails={cardDetails} fetchCard={fetchCard} cardId={cardId} />;
         default:
            return type === 'physical' ? (
               <PhysicalCardOverview bizDetails={bizDetails} cardDetails={cardDetails} />
            ) : (
               <VirtualCardOverview bizDetails={bizDetails} cardDetails={cardDetails} />
            );
      }
   };

   return (
      <Modal
         open={open}
         onClose={() => {
            setActiveTab(null);
            onClose();
         }}
         closeOnOverlayClick={false}
         noCloseIcon={noCloseIcon}
         title="Card Details"
         className="biz-details-modal"
      >
         <div className="d-flex justify-content-between">
            <Navigation>
               <NavigationTab
                  className={activeTab === 'overview' ? 'active' : ''}
                  onClick={() => setActiveTab('overview')}
               >
                  Overview
               </NavigationTab>
               <NavigationTab
                  className={activeTab === 'transactions' ? 'active' : ''}
                  onClick={() => setActiveTab('transactions')}
               >
                  Transactions
               </NavigationTab>
               <NavigationTab
                  className={activeTab === 'settings' ? 'active' : ''}
                  onClick={() => setActiveTab('settings')}
               >
                  Settings
               </NavigationTab>
               <NavigationTab
                  className={activeTab === 'notes' ? 'active' : ''}
                  onClick={() => setActiveTab('notes')}
               >
                  Notes
               </NavigationTab>
            </Navigation>
         </div>
         <div className="pt-5">{!isLoading ? activePage() : <Spinner />}</div>
      </Modal>
   );
};

CardDetails.propTypes = {
   open: bool.isRequired,
   cardId: number.isRequired,
   onClose: func.isRequired,
   noCloseIcon: bool,
   activeFilter: string.isRequired,
   type: string.isRequired,
};
CardDetails.defaultProps = {
   noCloseIcon: false,
};
export default CardDetails;
