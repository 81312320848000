/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import PropTypes from 'prop-types';
import Modal from 'components/modal';
import { TooltipComponent } from 'components/tooltip';
import { TableComponent } from 'components/table';
import { renderTransactionStatus } from 'utils/business';
import { numberWithCommas } from 'utils/others';
import { getChildTransactions } from 'services/transactions';
import { MultipleTransactionsWrapper } from './multiple-transactions-details.style';

export default function MultipleTransactionsModal({ open, onClose, transaction, noCloseIcon }) {
   const [tableData, setTableData] = useState([]);
   const [isLoading, setIsLoading] = useState(false);
   const [currentPage, setCurrentPage] = useState(1);
   const [paginationData, setPaginationData] = useState({});

   const fetchChildTransactions = async () => {
      setIsLoading(true);
      try {
         const response = await getChildTransactions({
            id: transaction.transaction_id,
            page: currentPage,
         });
         setTableData(response?.data);
         setPaginationData(response);
      } catch (e) {
         toast.error(e?.message);
      }
      setIsLoading(false);
   };

   useEffect(() => {
      fetchChildTransactions();
   }, []);
   const columns = [
      {
         field: 'tx_ref',
         headerName: 'Transaction ref',
         renderCell: ({ row }) => (
            <TooltipComponent title={row.tx_ref}>
               <span style={{ cursor: 'pointer' }} className="text-primary">
                  {row.tx_ref}
               </span>
            </TooltipComponent>
         ),
      },
      {
         field: 'transaction_id',
         headerName: 'Transaction ID',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.transaction_id}>{row?.transaction_id}</TooltipComponent>
         ),
      },
      {
         field: 'session_id',
         width: 300,
         headerName: 'Session ID',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.session_id}>{row?.session_id}</TooltipComponent>
         ),
      },
      {
         field: 'trans_amount',
         headerName: 'Amount',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.trans_amount}>
               ₦{numberWithCommas(row?.trans_amount)}
            </TooltipComponent>
         ),
      },
      {
         field: 'transfer_details.transfer_status',
         headerName: 'Status',
         renderCell: ({ row }) => renderTransactionStatus(row),
      },
   ];

   function onPageChange(e, pageNumber) {
      setCurrentPage(pageNumber);
   }

   return (
      <Modal open={open} onClose={onClose} center maxWidth="712px" noCloseIcon={noCloseIcon}>
         <MultipleTransactionsWrapper>
            <div className="d-flex justify-content-between transaction-details">
               <div className="transaction-details-item">
                  <h3 className="title">Total Value</h3>
                  <p className="value">N{numberWithCommas(transaction?.trans_amount)}</p>
               </div>

               <div className="transaction-details-item">
                  <h1 className="title">Total Amount</h1>
                  <p className="value">{numberWithCommas(paginationData?.count)}</p>
               </div>
            </div>

            <div>
               <TableComponent
                  columns={columns}
                  rows={tableData}
                  isLoading={isLoading}
                  onPageChange={onPageChange}
                  count={Math.ceil(paginationData.count / 15)}
               />
            </div>
         </MultipleTransactionsWrapper>
      </Modal>
   );
}

MultipleTransactionsModal.propTypes = {
   open: PropTypes.bool.isRequired,
   onClose: PropTypes.func.isRequired,
   noCloseIcon: PropTypes.bool.isRequired,
   transaction: PropTypes.objectOf().isRequired,
};
