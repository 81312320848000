import styled from 'styled-components';

export const MultipleTransactionsWrapper = styled.div`
   padding: 36px;
   .transaction-details {
      margin-bottom: 60px;

      .transaction-details-item {
         .title {
            font-weight: 500;
            font-size: 20px;
            line-height: 25px;
            margin-bottom: 29px;
         }
         .value {
            font-weight: 700;
            font-size: 29px;
            line-height: 36px;
         }
      }
   }
`;
