import { useState } from 'react';
import { PropTypes } from 'prop-types';
import InputFundAmount from 'components/input-amount';
import Modal from 'components/modal';
import SelectBankAccount from 'components/select-bank-account';
import { withdrawFromSavings } from 'services/savings';
import toast from 'react-hot-toast';
import Success from 'components/success';

export default function AddFundsModal({
   open,
   onClose,
   bizDetails,
   fdDetails,
   fetchFixedDepositsAccount,
}) {
   const [loading, setLoading] = useState(false);
   const [step, setStep] = useState('input-amount');
   const [successModal, setSuccessModal] = useState(false);
   const [payload, setPayload] = useState({});

   const { safe } = fdDetails;
   const { safe_wallet_details } = safe || {};

   const { biz_account_wallet_details } = bizDetails;

   const onClick = (data) => {
      setPayload((prevPayload) => ({ ...prevPayload, ...data }));
      setStep('select-account');
   };

   const handleDeposit = async (data) => {
      const { biz_wallet_id } = data;
      const { amount } = payload;

      const params = {
         from_wallet_id: biz_wallet_id,
         to_wallet_id: safe_wallet_details?.biz_wallet_id,
         amount,
      };
      setLoading(true);
      try {
         await withdrawFromSavings(params);
         setSuccessModal(true);
      } catch (e) {
         toast.error(e.message);
      }

      setLoading(false);
   };

   const handleAfterSuccess = () => {
      onClose();
      setSuccessModal(false);
      fetchFixedDepositsAccount();
   };

   return (
      <Modal
         open={open}
         onClose={onClose}
         center
         noCloseIcon
         maxWidth="400px"
         modalStyle={{ maxHeight: '450px' }}
      >
         {step === 'input-amount' && (
            <InputFundAmount onBack={onClose} onClick={onClick} title="Add Funds" />
         )}
         {step === 'select-account' && (
            <SelectBankAccount
               onBack={() => setStep('input-amount')}
               bizWallets={biz_account_wallet_details?.filter(
                  (item) => item.biz_wallet_type !== 'fixed_deposits',
               )}
               onSubmit={handleDeposit}
               loading={loading}
            />
         )}
         {successModal && (
            <Success
               open={successModal}
               onClose={handleAfterSuccess}
               title={`₦${parseFloat(
                  payload?.amount,
               ).toLocaleString()} has been added to the fixed deposit account`}
            />
         )}
      </Modal>
   );
}
AddFundsModal.propTypes = {
   open: PropTypes.bool.isRequired,
   onClose: PropTypes.func.isRequired,
   bizDetails: PropTypes.objectOf().isRequired,
   fdDetails: PropTypes.objectOf().isRequired,
   fetchFixedDepositsAccount: PropTypes.func.isRequired,
};
