/* eslint-disable no-restricted-syntax */
/* eslint-disable no-undef */
/* eslint-disable array-callback-return */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import LoggedInDashboard from 'components/logged-in-dashboard';
import { confirm } from 'components/confirm-box/confirm-box';
import Graph from 'components/graph';
import { TableContainer, TableContent } from 'styles/table';
import BusinessDetails from 'containers/business-details';
import { useDebouncedCallback } from 'use-debounce';
import toast, { Toaster } from 'react-hot-toast';
import moment from 'moment';
import {
   getBuildPartnerApprovedAccounts,
   getBuildPartnerAssignedStats,
   getBuildPartnerBusinessDeposit,
   updateContactStatus,
} from 'services/build-partner';
import { TooltipComponent } from 'components/tooltip';
import { MoreHoriz } from '@material-ui/icons';
import { numberWithCommas } from 'utils/others';
import { Skeleton } from '@material-ui/lab';
import { TableComponent } from 'components/table';
import { TabWrapper } from 'pages/pending-documents/style';
import IconButton from '@material-ui/core/IconButton';
import { Input } from 'components/input';
import TabMenu from 'components/TabMenu';
import Calendar from 'components/calendar';
import { useAuth } from 'hooks/auth';
import FilterListIcon from '@material-ui/icons/FilterList';
import { MenuComponent } from 'components/menu';
import { useBizDetailsModal } from 'hooks/bizDetailsModal';
import { BusinessAccountFilterModal } from 'containers/business-account-filter-modal';
import { Status } from 'styles/utils';
import { Spinner } from 'components/spinner';
import { StatsCards } from 'components/stats-cards';
import { hasPermission } from 'utils/user_access';
import PerformanceOverview from './features/performance-overview';
import { DepositFeedWrapper, GraphWrapper, FilterContainer } from './style';

const intialFilter = {
   start_date: moment().subtract(30, 'days').format('YYYY-MM-DD'),
   end_date: moment().format('YYYY-MM-DD'),
};

function FollowUp() {
   const history = useHistory();
   const { currentUser } = useAuth();
   const { permissions } = currentUser?.access || {};
   const [tableData, setTableData] = useState([]);
   const [isLoading, setIsLoading] = useState(false);
   const [depositFeed, setDepositFeed] = useState([]);
   const [isChangeStatusLoading] = useState(false);
   const [anchorEl, setAnchorEl] = useState(null);
   const [paginationData, setPaginationData] = useState({});
   const [currentPage, setCurrentPage] = useState(1);
   const [modalVisibility, setModalVisibility] = useState(false);
   const [statsData, setStatsData] = useState({});
   const [searchTerm, setSearchTerm] = useState('');
   const [activeTab, setPageActiveTab] = useState('overview');
   const [selectedYear, setSelectedYear] = useState(`${moment().format('YYYY')}`);
   const [years, setYears] = useState([]);
   const [feedsFilter] = useState('monthly');
   // const [graphFilter, setGraphFilter] = useState({});
   const [loadingStats, setLoadingStats] = useState(false);
   const [modalVisibilityFilter, setModalVisibilityFilter] = useState(false);
   const [bizAccount, setBizAccount] = useState('');
   const { setActiveTab } = useBizDetailsModal();
   const open = Boolean(anchorEl);
   const tableRef = useRef();
   const [filterData, setfilterData] = useState(intialFilter);
   const [filterPayload, setFilterPayload] = useState(intialFilter);

   // const build_partner_manager_id = currentUser.prospa_user_id;

   const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
   };

   const handleClose = () => {
      setAnchorEl(null);
   };

   const isAdmin = hasPermission(permissions, 'build_partner_admin');

   // async function buildPartnerDeposit() {
   //    setIsLoading(true);
   //    try {
   //       if (feedsFilter === 'weekly') {
   //          const { weekly_deposit } = await getBuildPartnerWeeklyDeposit(
   //             {
   //                start_date: `${selectedYear}-01-01`,
   //                end_date: `${selectedYear}-12-31`,
   //             },
   //             '117',
   //          );
   //          setDepositFeed(weekly_deposit);
   //       } else {
   //          const { monthly_deposit } = await getBuildPartnerMonthlyDeposit({
   //             start_date: `${selectedYear}-01-01`,
   //             end_date: `${selectedYear}-12-31`,
   //             build_partner_manager_id: '117',
   //          });
   //          setDepositFeed(monthly_deposit);
   //       }
   //    } catch ({ message }) {
   //       toast.error(message);
   //    }
   //    setIsLoading(false);
   // }

   // useEffect(() => {
   //    buildPartnerDeposit();
   // }, [selectedYear, feedsFilter]);

   async function handleChangeStatus({ target }, bizId) {
      confirm({
         confirmText: 'Are you sure you want to perform this action?',
         isLoading: isChangeStatusLoading,
         onConfirm: async () => {
            const params = {
               biz_account_id: bizId,
               biz_contact_status: target.value,
            };
            try {
               const result = await updateContactStatus(params, bizId);
               toast.success(result.message);
            } catch (e) {
               toast.error(e.message);
            }
         },
      });
   }

   function handleOpenModal() {
      setActiveTab('overview');
      setModalVisibility(true);
      // document.body.style.overflow = "hidden";
   }

   function sendMessageModalSection() {
      setActiveTab('message');
      setModalVisibility(true);
   }

   function callNumber(phone) {
      const link = document.createElement('a');
      const linkText = document.createTextNode('call us');
      link.appendChild(linkText);
      link.href = `tel:${phone}`;
      link.click();
   }

   const options = ['view Details', 'Call', 'Send Message'];

   const columns = [
      {
         field: '',
         width: 0,
         renderCell: ({ row }) => (
            <div>
               <IconButton
                  aria-label="more"
                  aria-controls="long-menu"
                  aria-haspopup="true"
                  onClick={handleClick}
               >
                  <MoreHoriz />
               </IconButton>
               <MenuComponent
                  anchorEl={anchorEl}
                  handleClose={handleClose}
                  open={open}
                  options={options}
                  optionsFns={[
                     // place the callbacks in position corresponding to the options index
                     () => handleOpenModal(row.id),
                     () => callNumber(row?.account_holder_details?.phone),
                     () => sendMessageModalSection(row.id),
                  ]}
               />
            </div>
         ),
      },
      {
         field: 'biz_friendly_name',
         headerName: 'Business Name',
         renderCell: ({ row }) => (
            <TooltipComponent title={row.biz_friendly_name || row.biz_name}>
               <span
                  style={{
                     cursor: 'pointer',
                  }}
                  className="text-primary"
                  onClick={() => handleOpenModal(row.id)}
               >
                  {row.biz_friendly_name || row.biz_name || 'N/A'}
               </span>
            </TooltipComponent>
         ),
      },
      {
         field: 'biz_owner',
         headerName: 'Owner',
         renderCell: ({ row }) => (
            <TooltipComponent>{row?.biz_owner?.toLowerCase()} </TooltipComponent>
         ),
      },
      {
         field: 'biz_type',
         headerName: 'Type',
         renderCell: ({ row }) => (
            <TooltipComponent title={row.biz_type}>{row.biz_type}</TooltipComponent>
         ),
      },
      {
         field: 'biz_category',
         headerName: 'Category',
         renderCell: ({ row }) => (
            <TooltipComponent title={row.biz_category || 'N/A'}>
               {row.biz_category || 'N/A'}
            </TooltipComponent>
         ),
      },
      {
         field: 'biz_state',
         headerName: 'State',
         renderCell: ({ row }) => (
            <TooltipComponent title={row.biz_state || 'N/A'}>
               {row.biz_state || 'N/A'}
            </TooltipComponent>
         ),
      },
      {
         field: 'activity_status',
         headerName: 'Account Status',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.activity_status}>
               <div className="d-flex gap-2">
                  {row?.activity_status?.map((status) => (
                     <Status status={status}>{status?.replace('_', ' ')}</Status>
                  ))}
               </div>
            </TooltipComponent>
         ),
      },
      {
         field: 'biz_turnover',
         headerName: 'Turnover',
         renderCell: ({ row }) => (
            <TooltipComponent title={row.biz_turnover?.replaceAll('_', ' ')}>
               {row.biz_turnover?.replaceAll('_', ' ') || 'N/A'}
            </TooltipComponent>
         ),
      },
      {
         field: 'deposit_value',
         headerName: 'Deposit Value',
         renderCell: ({ row }) => (
            <TooltipComponent title={numberWithCommas(row.deposit_volume)}>
               ₦{numberWithCommas(row.deposit_volume)}
            </TooltipComponent>
         ),
      },
      {
         field: 'build_partner_name',
         headerName: 'Build Partner',
         renderCell: ({ row }) => <span>{row?.build_partner_name || 'N/A'}</span>,
      },
      {
         field: 'biz_phone',
         headerName: 'Phone',
         renderCell: ({ row }) => <span>{row?.biz_phone || 'N/A'}</span>,
      },
      {
         field: 'biz_contact_status',
         headerName: 'Contact Status',
         renderCell: ({ row }) => (
            <select
               style={{ width: '100%', border: 'none', padding: '10px' }}
               onChange={(e) => handleChangeStatus(e, row.id)}
               name="status"
               defaultValue={row.biz_contact_status}
            >
               <option value="yes">Yes</option>
               <option value="no">No</option>
            </select>
         ),
      },
      {
         field: 'approved_time',
         headerName: 'Approval Date ',
         renderCell: ({ row }) => (
            <span>
               {row.approved_time
                  ? ` ${moment(row?.approved_time).format('MMM DD, YYYY')} | ${moment(
                       row?.approved_time,
                    ).format('h:mm a')}`
                  : 'NA'}
            </span>
         ),
      },
   ];

   const {
      start_date: date_after,
      end_date: date_before,
      assigned_manager_id: build_partner,
      ...remainingFilter
   } = filterPayload;

   const payload = {
      date_after,
      date_before,
      build_partner,
      ...remainingFilter,
   };

   const fetchBuildPartnerBusinesses = async () => {
      setIsLoading(true);

      try {
         const businessAccountsData = await getBuildPartnerApprovedAccounts({
            page: currentPage,
            ...payload,
            ...(searchTerm !== '' && { biz_friendly_name: searchTerm }),
         });
         setTableData(businessAccountsData.results);
         setPaginationData(businessAccountsData);
      } catch (e) {
         toast.error(e?.message);
      }

      setIsLoading(false);
   };

   const fetchBuildPartnerStats = async () => {
      try {
         setLoadingStats(true);
         const stat = await getBuildPartnerAssignedStats(payload);
         const { deposit_volume, total_active_customers, total_assigned_build_partners } = stat;

         setStatsData({
            deposit_volume: `₦${numberWithCommas(deposit_volume)}`,
            businsses_assigned_build_partners: `${numberWithCommas(total_assigned_build_partners)}`,
            total_active_customers: `${numberWithCommas(total_active_customers)}`,
         });
      } catch (err) {
         return err;
      } finally {
         setLoadingStats(false);
      }
   };

   const fetchBusinessDeposit = async () => {
      try {
         const { assigned_manager_id: build_partnerValue } = filterPayload;
         const response = await getBuildPartnerBusinessDeposit({
            ...(Object.keys(selectedYear)?.length > 1 && {
               date_after: `${selectedYear}-01-01`,
               date_before: `${selectedYear}-12-31`,
            }),
            build_partner: build_partnerValue,
         });
         setDepositFeed(response?.data);
      } catch (e) {
         toast.error(e?.message);
      }
   };

   useEffect(() => {
      fetchBusinessDeposit();
   }, [selectedYear]);

   useEffect(() => {
      (async function buildPartnerStats() {
         const currentYear = new Date().getFullYear();
         const range = (start, stop, step) =>
            Array.from({ length: (stop - start) / step + 1 }, (pick, i) => start + i * step);
         setYears(range(currentYear, currentYear - 2, -1));
         // 117
      })();
   }, []);

   useEffect(() => {
      fetchBuildPartnerStats();
   }, [filterPayload]);

   useEffect(() => {
      if (permissions) {
         fetchBuildPartnerBusinesses();
      }
   }, [currentPage, searchTerm, permissions, filterPayload]);

   useEffect(() => {
      if (activeTab === 'overview') {
         setCurrentPage(1);
         history.push(`${history.location.pathname}?page=1`);
         // setFilterPayload({});
         setSearchTerm({});
      }
   }, [activeTab]);

   function onPageChange(e, pageNumber) {
      setCurrentPage(pageNumber);
   }

   function handleSearch({ target }) {
      setCurrentPage(1);
      history.push(`${history.location.pathname}?page=1`);
      setSearchTerm(target.value);
   }

   function onRowClick({ row }) {
      setBizAccount(row.id);
   }

   const debounce = useDebouncedCallback(handleSearch, 600);

   const tabs = [
      {
         value: 'overview',
         label: 'Overview',
      },
      isAdmin && {
         value: 'performance',
         label: 'Performance Overview',
      },
   ];

   return (
      <LoggedInDashboard pageTitle="Build Partners">
         <div>
            <div>
               <TabWrapper>
                  <ul className="m-0 d-flex align-items-center">
                     {tabs.map(({ label, value }) => (
                        <TabMenu
                           key={value}
                           text={label}
                           active={activeTab === value}
                           setActiveTab={() => setPageActiveTab(value)}
                        />
                     ))}
                  </ul>
               </TabWrapper>
            </div>
         </div>

         <StatsCards data={statsData} isLoading={loadingStats} />
         <Toaster />
         {!isLoading ? (
            <GraphWrapper className="mt-4">
               <DepositFeedWrapper className="d-flex justify-content-between px-4 align-items-center">
                  <div className="">
                     <h3 className="deposit-title">Deposits Feed</h3>
                     <p className="mb-0 moment">As of {moment().format('DD MMM YYYY, HH:mm A')}</p>
                  </div>
                  <div>
                     {/* <div className="d-flex align-items-center date-picker mb-3">
                        <div
                           className={`weekly-feeds ${
                              feedsFilter === 'weekly' ? 'active' : ''
                           } feeds-btn me-3`}
                           onClick={() => {
                              setSelectedYear(`${moment().format('YYYY')}`);
                              setFeedsFilter('weekly');
                           }}
                        >
                           <p>Weekly</p>
                        </div>

                        <div
                           className={`monthly-feeds ${
                              feedsFilter === 'monthly' ? 'active' : ''
                           } feeds-btn`}
                           onClick={() => setFeedsFilter('monthly')}
                        >
                           <p>Monthly</p>
                        </div>
                     </div> */}
                     {feedsFilter === 'monthly' && (
                        <div className=" date-picker-group d-flex justify-content-between align-items-center">
                           <span className="me-3">Filter by year:</span>
                           <select
                              value={selectedYear}
                              onChange={({ target }) => setSelectedYear(target.value)}
                           >
                              {years.map((item) => (
                                 <option key={item} value={item}>
                                    {item}
                                 </option>
                              ))}
                           </select>
                        </div>
                     )}

                     <div className=" date-picker-group d-flex justify-content-between align-items-center">
                        {/* <div className=" date-picker-group d-flex justify-content-between align-items-center me-4">
                           <span className="me-2">Start:</span>
                           <Input
                              placeholder="Start Date"
                              name="start_date"
                              type="date"
                              value={graphFilter?.date_after}
                              onChange={({ target }) =>
                                 setGraphFilter({
                                    ...graphFilter,
                                    date_after: target.value,
                                 })
                              }
                           />
                        </div> */}

                        {/* <div className=" date-picker-group d-flex justify-content-between align-items-center">
                           <span className="me-2">End:</span>
                           <Input
                              placeholder="End Date"
                              name="end_date"
                              type="date"
                              value={graphFilter?.date_before}
                              onChange={({ target }) =>
                                 setGraphFilter({
                                    ...graphFilter,
                                    date_before: target.value,
                                 })
                              }
                           />
                        </div> */}

                        {/* <select
                           value={selectedYear}
                           onChange={({ target }) => setSelectedYear(target.value)}
                        >
                           {years.map((item) => (
                              <option key={item} value={item}>
                                 {item}
                              </option>
                           ))}
                        </select> */}
                     </div>
                  </div>
               </DepositFeedWrapper>
               <div
                  className=""
                  style={{
                     height: '300px',
                  }}
               >
                  <Graph
                     data={depositFeed.map((item) => parseInt(item.inflow, 10))}
                     categories={depositFeed.map((item) => item.month || item.day)}
                  />
               </div>
            </GraphWrapper>
         ) : (
            <Spinner />
         )}

         {activeTab === 'overview' && (
            <TableContainer>
               <TableContent>
                  {isLoading ? (
                     <Skeleton width="30%" height="20" />
                  ) : (
                     <div>
                        <h1>Accounts</h1>
                        <span className="value">{paginationData?.count || 0}</span>
                     </div>
                  )}
                  <div className="d-flex justify-content-between">
                     <div>
                        <Input
                           style={{ width: '300px' }}
                           placeholder="Search"
                           onChange={debounce}
                        />
                     </div>
                     <FilterContainer onClick={() => setModalVisibilityFilter(true)}>
                        Filter By <FilterListIcon style={{ marginLeft: '10px' }} />
                     </FilterContainer>
                     <Calendar />
                  </div>
               </TableContent>
               <TableComponent
                  columns={columns}
                  rows={tableData}
                  isLoading={isLoading}
                  onPageChange={onPageChange}
                  page={currentPage}
                  ref={tableRef}
                  count={Math.ceil(paginationData.count / 15)}
                  onRowClick={onRowClick}
               />
            </TableContainer>
         )}

         {activeTab === 'performance' && <PerformanceOverview filters={filterPayload} />}

         <BusinessAccountFilterModal
            open={modalVisibilityFilter}
            onClose={() => setModalVisibilityFilter(false)}
            setCurrentPage={setCurrentPage}
            setfilterData={setfilterData}
            filterData={filterData}
            setFilterPayload={setFilterPayload}
            setBusinessInfo={setTableData}
            setPaginationData={setPaginationData}
            currentPage={currentPage}
            filterFunction={(page, values) =>
               getBuildPartnerApprovedAccounts(page, {
                  ...values,
                  build_partner_manager_id: '117',
               })
            }
            active={[
               'date',
               'turn_over',
               'status',
               'manager',
               'activity_status',
               'biz_state',
               'biz_category',
               'deposit_volume',
            ]}
         />

         {modalVisibility && (
            <BusinessDetails
               businessAccountId={bizAccount}
               open={modalVisibility}
               onClose={() => {
                  setModalVisibility(false);
                  fetchBuildPartnerBusinesses();
                  fetchBuildPartnerStats();
               }}
               type="build-partner"
            />
         )}
      </LoggedInDashboard>
   );
}

export default FollowUp;
