/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { getActivationPerformance } from 'services/build-partner';
import { TableComponent } from 'components/table';
import { TableContainer } from 'styles/table';
import { TooltipComponent } from 'components/tooltip';
import { useAuth } from 'hooks/auth';
import { numberWithCommas } from 'utils/others';

const PerformanceOverview = ({ filter }) => {
   const { currentUser } = useAuth();
   const { search } = useLocation();
   const page = new URLSearchParams(search).get('page');
   const [searchTerm] = useState('');
   const [tableData, setTableData] = useState([]);
   const [isLoadingMangers, setIsLoadingMangers] = useState(false);
   const [paginationData, setPaginationData] = useState({});
   const [currentPage, setCurrentPage] = useState(1);

   const fetchPerformance = async () => {
      setIsLoadingMangers(true);
      try {
         setTableData([]);
         setPaginationData({});
         const response = await getActivationPerformance({
            page,
            ...filter,
            date_before: moment(filter.date_before).add(1, 'day').format('YYYY-MM-DD'),
         });
         setTableData(response.results);
         setPaginationData(response);
      } catch (err) {
         return err;
      } finally {
         setIsLoadingMangers(false);
      }
   };

   useEffect(() => {
      if (currentUser?.prospa_user_id) {
         fetchPerformance();
      }
   }, [searchTerm, page, currentUser]);

   function onPageChange(e, pageNumber) {
      setCurrentPage(pageNumber);
   }

   const columns = [
      {
         field: 'partner_name',
         headerName: 'Name of Agent',
         renderCell: ({ row }) => (
            <TooltipComponent className="m-0" title={`${row.partner_name}`}>
               {row.partner_name}
            </TooltipComponent>
         ),
      },
      {
         field: 'accounts_assigned',
         headerName: 'Accounts Assigned',
         renderCell: ({ row }) => <span>{row?.accounts_assigned}</span>,
      },
      {
         field: 'deposit_volume',
         headerName: 'Deposit Value',
         width: 300,
         renderCell: ({ row }) => <span>₦ {numberWithCommas(row?.deposit_volume)}</span>,
      },
      {
         field: 'active_accounts',
         headerName: 'Active Accounts',
         renderCell: ({ row }) => (
            <TooltipComponent title={row.active_accounts}>
               {numberWithCommas(row.active_accounts)}
            </TooltipComponent>
         ),
      },
      {
         field: 'inactive_accounts',
         headerName: 'Inactive Accounts',
         renderCell: ({ row }) => (
            <TooltipComponent title={row.inactive_accounts}>
               {numberWithCommas(row.inactive_accounts)}
            </TooltipComponent>
         ),
      },
      {
         field: 'dormant_accounts',
         headerName: 'Dormant Accounts',
         renderCell: ({ row }) => (
            <TooltipComponent title={row.dormant_accounts}>
               {numberWithCommas(row.dormant_accounts)}
            </TooltipComponent>
         ),
      },
      {
         field: 'at_risk_accounts',
         headerName: 'At Risk Accounts',
         renderCell: ({ row }) => (
            <TooltipComponent title={row.at_risk_accounts}>
               {numberWithCommas(row.at_risk_accounts)}
            </TooltipComponent>
         ),
      },
      {
         field: 'churned_accounts',
         headerName: 'Churned Accounts',
         renderCell: ({ row }) => (
            <TooltipComponent title={row.churned_accounts}>
               {numberWithCommas(row.churned_accounts)}
            </TooltipComponent>
         ),
      },
   ];

   return (
      <div>
         <TableContainer id="table">
            <TableComponent
               columns={columns}
               rows={tableData}
               isLoading={isLoadingMangers}
               onPageChange={onPageChange}
               count={Math.round(paginationData.count / 15)}
               page={currentPage}
               pageSize={20}
            />
         </TableContainer>
      </div>
   );
};

PerformanceOverview.propTypes = {
   filter: PropTypes.objectOf(PropTypes.string).isRequired,
};
export default PerformanceOverview;
