import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { useAuth } from 'hooks/auth';
import { Button } from 'components/button';
import { Input } from 'components/input';
import { InputWrapper } from 'styles/form';
import { confirm } from 'components/confirm-box';
import { hasValue, validateEmail } from 'utils/validations';
import { sendPasswordResetApi } from 'services/user';

export default function PasswordReset() {
   const { currentUser } = useAuth();
   const [email, setEmail] = useState('');
   const [loading, setLoading] = useState(false);
   const [businessId, setBusinessId] = useState('');

   const isValid = validateEmail(email) && hasValue(businessId);

   const sendResetLink = async () => {
      setLoading(true);

      const payload = {
         email,
         otp_approver: currentUser?.prospa_user_id,
         businessId,
      };

      try {
         const response = await sendPasswordResetApi(payload);
         toast.success(response.message || `Reset link succesfully sent to member's email`);
         setEmail('');
         setBusinessId('');
      } catch (e) {
         toast.error(e?.message);
      }
      setLoading(false);
   };

   const confirmAction = () =>
      confirm({
         confirmText: 'Are you sure you want to continue ?',
         onConfirm: sendResetLink,
      });

   return (
      <div className="row align-items-end">
         <InputWrapper className="col-lg-4">
            <Input
               placeholder="Enter email address"
               label="Email Address"
               value={email}
               onChange={(e) => setEmail(e.target.value)}
            />
         </InputWrapper>
         <InputWrapper className="col-lg-4">
            <Input
               placeholder="Enter Business ID"
               label="Business ID"
               value={businessId}
               onChange={(e) => setBusinessId(e.target.value)}
            />
         </InputWrapper>
         <InputWrapper className="col-lg-4">
            <Button
               variant="primary"
               onClick={confirmAction}
               isLoading={loading}
               disabled={!isValid}
            >
               Send Reset Mail
            </Button>
         </InputWrapper>
      </div>
   );
}
