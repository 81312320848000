import { useState, useEffect } from 'react';
import clsx from 'classnames';
import toast from 'react-hot-toast';
import { bool, func, number } from 'prop-types';
import { Spinner } from 'components/spinner';
import Modal from 'components/modal';
import { getBusinessDetails } from 'services/businessDetails';
import { getSingleTicket, getTicketMessages, updateSingleTicket } from 'services/ticketing';
import { useAuth } from 'hooks/auth';
import { hasPermission } from 'utils/user_access';
import { Navigation, NavigationTab } from 'styles/layout';
import Notes from 'containers/business-details/notes';
import RequestedLoans from 'containers/loan-details/requested-loans';
import Transactions from 'containers/business-details/transactions';
import Accounts from 'containers/business-details/accounts';
import BusinessOverview from 'containers/business-details/overview';
import TicketMessage from './message';
import Overview from './overview';

const TicketDetails = ({ open, ticketId, onClose, fetchAllTickets, noCloseIcon }) => {
   const { currentUser } = useAuth();
   const [activeTab, setActiveTab] = useState('overview');
   const [fetchingTicket, setFetchingTicket] = useState(false);
   const [ticketDetails, setTicketDetails] = useState();
   const [bizDetails, setBizDetails] = useState({});
   const [messages, setMessages] = useState([]);
   const { permissions } = currentUser?.access || {};
   const canAccessMessages = hasPermission(permissions, 'can_read_all_ticket_messages');
   const admin = hasPermission(permissions, 'ticket_admin');
   const ticketer = hasPermission(permissions, 'can_read_all_tickets');

   async function fetchSingleTicket() {
      setFetchingTicket(true);
      try {
         const ticket = await getSingleTicket(ticketId);
         const { assigned_to, issue_status } = ticket;
         const { hbizaccount } = ticket;
         if (hbizaccount) {
            const result = await getBusinessDetails(hbizaccount);
            setBizDetails(result.data);
         }

         // AUTO ASSIGN TICKET TO REGULAR TICKET MANAGER ONLY (NOT ADMIN)
         if ((!assigned_to && ticketer && !admin) || (assigned_to && issue_status === 'new')) {
            await updateSingleTicket(ticketId, {
               assigned_to: currentUser?.prospa_user_id,
               issue_status: 'open',
            });
            ticket.assigned_to = currentUser?.prospa_user_id;
            ticket.issue_status = 'open';
         }
         setTicketDetails(ticket);
         fetchAllTickets();
      } catch (e) {
         return e;
      } finally {
         setFetchingTicket(false);
      }
   }

   const fetchTicketMessages = async () => {
      try {
         const response = await getTicketMessages({
            ticket: ticketId,
         });

         setMessages(response?.data?.sort((a, b) => (a.id < b.id ? -1 : 1)));
      } catch (e) {
         toast.error(e?.message);
      }
   };

   const afterTicketUpdate = () => {
      fetchSingleTicket();
      fetchAllTickets();
   };

   useEffect(() => {
      if (ticketId) {
         fetchSingleTicket();
         if (canAccessMessages) {
            fetchTicketMessages();
         }
      }
   }, [ticketId]);

   useEffect(() => {
      const interval = setInterval(() => {
         fetchTicketMessages();
      }, 10000);
      return () => clearInterval(interval);
   }, []);

   const activePage = () => {
      switch (activeTab) {
         case 'messages':
            return (
               <TicketMessage
                  ticketId={ticketId}
                  bizDetails={bizDetails}
                  messages={messages}
                  fetchTicketMessages={fetchTicketMessages}
                  ticketDetails={ticketDetails}
               />
            );

         case 'notes':
            return <Notes businessAccountId={bizDetails?.biz_account_id} />;

         case 'business':
            return <BusinessOverview businessAccountId={bizDetails?.biz_account_id} />;

         case 'accounts':
            return <Accounts businessAccountId={bizDetails?.biz_account_id} />;

         case 'loans':
            return <RequestedLoans businessAccountId={bizDetails?.biz_account_id} />;

         case 'transactions':
            return <Transactions businessAccountId={bizDetails?.biz_account_id} />;

         case '':
         case 'overview':
         default:
            return (
               <Overview
                  bizDetails={bizDetails}
                  ticketDetails={ticketDetails}
                  afterTicketUpdate={afterTicketUpdate}
               />
            );
      }
   };

   const tabs = [
      {
         label: 'Overview',
         value: 'overview',
         active: true,
      },
      {
         label: 'Messages',
         value: 'messages',
         active: canAccessMessages,
      },
      {
         label: 'Business Details',
         value: 'business',
         active: true,
      },
      {
         label: 'Notes',
         value: 'notes',
         active: true,
      },
      {
         label: 'Transactions',
         value: 'transactions',
         active: true,
      },
      {
         label: 'Accounts',
         value: 'accounts',
         active: true,
      },
      {
         label: 'Requested Loans',
         value: 'loans',
         active: true,
      },
   ];

   return (
      <Modal
         open={open}
         onClose={() => {
            setActiveTab(null);
            onClose();
         }}
         closeOnOverlayClick={false}
         noCloseIcon={noCloseIcon}
         title="Ticket Information"
         className={clsx('modal-full biz-details-modal', {
            'modal-h100': activeTab === 'messages',
         })}
      >
         <div className="d-flex justify-content-between">
            <Navigation>
               {tabs
                  ?.filter((tab) => tab.active)
                  .map(({ label, value }) => (
                     <NavigationTab
                        className={activeTab === value ? 'active' : ''}
                        onClick={() => setActiveTab(value)}
                     >
                        {label}
                     </NavigationTab>
                  ))}
            </Navigation>
         </div>
         <div className="pt-5">{!fetchingTicket ? activePage() : <Spinner />}</div>
      </Modal>
   );
};

TicketDetails.propTypes = {
   open: bool.isRequired,
   ticketId: number.isRequired,
   onClose: func.isRequired,
   noCloseIcon: bool,
   fetchAllTickets: func.isRequired,
};
TicketDetails.defaultProps = {
   noCloseIcon: false,
};

export default TicketDetails;
