import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import moment from 'moment';
import { useAuth } from 'hooks/auth';
import getSymbolFromCurrency from 'currency-symbol-map';
import { formatTag } from 'utils/tags';
import { hasPermission } from 'utils/user_access';
import { can_view_international_transactions } from 'utils/user_actions';
import { Skeleton } from '@material-ui/lab';
import FilterListIcon from '@material-ui/icons/FilterList';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import { TableContainer, TableContent } from 'styles/table';
import { TooltipComponent } from 'components/tooltip';
import { TableComponent } from 'components/table';
import InternationalTransactionModal from 'components/TransactionModal/global-payments';
import InternationalTransactionsFilters from 'containers/filter-modal/international-transactions-filter';
import { FilterContainer } from 'pages/follow-up/follow-up.style';
import { getInternationalTransactions } from 'services/international-transactions';

const initialFilter = {
   modified_at_after: moment().format('YYYY-MM-01'),
   modified_at_before: moment().format('YYYY-MM-DD'),
};

function TransactionsDashboard() {
   const { currentUser } = useAuth();
   const { permissions } = currentUser?.access || {};
   const [tableData, setTableData] = useState([]);
   const [isLoading, setIsLoading] = useState(false);
   const [paginationData, setPaginationData] = useState({});
   const [currentPage, setCurrentPage] = useState(1);
   const [transactionDetail, setTransactionDetail] = useState('');
   const [transactionModal, setTransactionModal] = useState(false);
   const [modalVisibilityFilter, setModalVisibilityFilter] = useState(false);
   const [filterData, setFilterData] = useState(initialFilter);
   const [filterPayload, setFilterPayload] = useState({});

   const onTransactionClick = (data) => {
      setTransactionModal(true);
      setTransactionDetail(data);
   };

   const columns = [
      {
         field: 'payout_internal_ref',
         width: 280,
         headerName: 'Transaction reference',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.payout_internal_ref || 'N/A'}>
               <span
                  style={{ cursor: 'pointer' }}
                  className="text-primary"
                  onClick={() => onTransactionClick(row)}
               >
                  {row?.payout_internal_ref || 'N/A'}
               </span>
            </TooltipComponent>
         ),
      },
      {
         field: 'created_by?.first_name',
         headerName: 'Initiated by',
         renderCell: ({ row }) => (
            <span style={{ textTransform: 'capitalize' }} title={row?.created_by?.first_name}>
               {row?.created_by?.first_name || 'N/A'} {row?.created_by?.last_name || 'N/A'}
            </span>
         ),
      },
      {
         field: 'source_amount',
         headerName: 'Amount Sent',
         renderCell: ({ row }) => (
            <span style={{ textTransform: 'capitalize' }}>
               {`${getSymbolFromCurrency(row?.source_currency) || 'N/A'}${parseFloat(
                  row?.source_amount || 'N/A',
               ).toLocaleString()}`}
            </span>
         ),
      },
      {
         field: 'amount',
         headerName: 'Receiver Gets',
         renderCell: ({ row }) => (
            <span style={{ textTransform: 'capitalize' }}>
               {`${getSymbolFromCurrency(row?.beneficiary?.currency) || 'N/A'}${parseFloat(
                  row?.amount || 'N/A',
               ).toLocaleString()}`}
            </span>
         ),
      },
      {
         field: 'beneficiary?.first_name',
         headerName: 'Beneficiary',
         renderCell: ({ row }) => (
            <span style={{ textTransform: 'capitalize' }}>
               {row?.revo_transfer_type === 'individual'
                  ? `${row?.beneficiary?.first_name || 'N/A'} ${
                       row?.beneficiary?.last_name || 'N/A'
                    }`
                  : `${row?.beneficiary?.name || 'N/A'}`}
            </span>
         ),
      },
      {
         field: 'payout_status',
         headerName: 'Status',
         renderCell: ({ row }) => {
            return (
               <TooltipComponent title={row?.payout_status}>
                  {formatTag(row?.payout_status, row?.payout_status)}
               </TooltipComponent>
            );
         },
      },
      {
         field: 'created_at',
         headerName: 'Date',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.created_at}>
               {moment(row?.created_at).format('DD/MM/YYYY')} |{' '}
               <span style={{ opacity: '.5' }}>{moment(row?.created_at).format('HH:mm A')}</span>
            </TooltipComponent>
         ),
      },
   ];

   const fetchTransactions = async (pageNumber) => {
      setIsLoading(true);
      try {
         const res = await getInternationalTransactions({
            page: pageNumber || currentPage,
            ...filterPayload,
         });
         setIsLoading(false);
         setTableData(res?.results);
         setPaginationData(res);
      } catch (e) {
         toast.error(e?.message || 'Something went wrong!');
         setIsLoading(false);
      }
   };

   useEffect(() => {
      fetchTransactions();
   }, [currentPage, filterPayload]);

   function onPageChange(e, pageNumber) {
      setCurrentPage(pageNumber);
   }
   return (
      <>
         {hasPermission(permissions, can_view_international_transactions) ? (
            <div>
               <TableContainer>
                  <TableContent>
                     {isLoading ? (
                        <Skeleton width="30%" height="20" />
                     ) : (
                        <div>
                           <h1> All Transactions</h1>
                           <span className="value">{paginationData?.count || 'N/A'}</span>
                        </div>
                     )}
                     <div className="d-flex align-items-center">
                        <FilterContainer className="me-3">
                           Export Data
                           <ImportExportIcon style={{ marginLeft: '10px' }} />
                        </FilterContainer>
                        <FilterContainer onClick={() => setModalVisibilityFilter(true)}>
                           Filter By <FilterListIcon style={{ marginLeft: '10px' }} />
                        </FilterContainer>
                     </div>
                  </TableContent>
                  <TableComponent
                     columns={columns}
                     rows={tableData}
                     isLoading={isLoading}
                     onPageChange={onPageChange}
                     count={Math.ceil(paginationData.count / 15)}
                  />
               </TableContainer>
               {transactionModal && (
                  <InternationalTransactionModal
                     open={transactionModal}
                     onClose={() => setTransactionModal(false)}
                     transactionDetail={transactionDetail}
                     fetchTransactions={fetchTransactions}
                  />
               )}
               {modalVisibilityFilter && (
                  <InternationalTransactionsFilters
                     open={modalVisibilityFilter}
                     onClose={() => setModalVisibilityFilter(false)}
                     setCurrentPage={setCurrentPage}
                     filterData={filterData}
                     setFilterData={setFilterData}
                     setFilterPayload={setFilterPayload}
                     type="outbound"
                  />
               )}
            </div>
         ) : (
            <div>
               <p>You do not have required permission : can_view_international_transactions </p>
            </div>
         )}
      </>
   );
}

export default TransactionsDashboard;
