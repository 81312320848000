import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { updateBizDetails } from 'services/businessDetails';
import { Button } from 'components/button';
import { Input } from 'components/input';
import { confirm } from 'components/confirm-box';
import { InputWrapper } from 'styles/form';
import { hasValue, validateEmail } from 'utils/validations';

export default function UpdateBusinessEmail() {
   const [bizEmail, setBizEmail] = useState('');
   const [loading, setLoading] = useState(false);
   const [businessId, setBusinessId] = useState('');

   const isValid = validateEmail(bizEmail) && hasValue(businessId);

   const updateBizEmail = async () => {
      setLoading(true);
      const payload = {
         biz_email: bizEmail,
         biz_account_id: businessId,
      };

      try {
         const response = await updateBizDetails(payload);
         toast.success(response.message);
         setBizEmail('');
         setBusinessId('');
      } catch (e) {
         toast.error(e.message);
      }
      setLoading(false);
   };

   const confirmAction = () =>
      confirm({
         confirmText: 'Are you sure you want to continue ?',
         onConfirm: updateBizEmail,
      });

   return (
      <div className="row align-items-end">
         <InputWrapper className="col-lg-4">
            <Input
               placeholder="Enter Business Email"
               label="Business Email"
               value={bizEmail}
               onChange={(e) => setBizEmail(e.target.value)}
            />
         </InputWrapper>
         <InputWrapper className="col-lg-4">
            <Input
               placeholder="Enter Business ID"
               label="Business ID"
               value={businessId}
               onChange={(e) => setBusinessId(e.target.value)}
            />
         </InputWrapper>
         <InputWrapper className="col-lg-4">
            <Button
               variant="primary"
               onClick={confirmAction}
               isLoading={loading}
               disabled={!isValid}
            >
               Update
            </Button>
         </InputWrapper>
      </div>
   );
}
