import { Http } from 'services/http';
import axiosCustom from 'utils/axios';
import { paramsObjectToQueryString } from 'utils/others';

export const createTicket = (payload) =>
   Http(`/tickets/tickets/`, {
      method: 'POST',
      body: payload,
      action: 'create_ticket',
   });

export const getAllTickets = (page) =>
   Http(`/tickets/tickets/${paramsObjectToQueryString(page)}`, {
      action: 'can_read_all_tickets',
   });

export const getSingleTicket = (id) => Http(`/tickets/tickets/${id}/`);

export const updateSingleTicket = (id, payload) =>
   Http(`/tickets/tickets/${id}/`, {
      method: 'PATCH',
      body: payload,
      action: 'can_update_status',
   });

export const getTicketCategories = () => Http(`/tickets/category`);

export const getSingleTicketCategory = (id) => Http(`/tickets/category/${id}`);

export const addTicketCategory = (payload) =>
   Http(`/tickets/category`, {
      method: 'POST',
      body: payload,
      action: 'add_ticket_category',
   });

export const updateTicketCategoryName = (id, payload) =>
   Http(`/tickets/category/${id}`, {
      method: 'PUT',
      body: payload,
      action: 'update_delete_ticket_category',
   });

export const deleteTicketCategory = (id) =>
   Http(`/tickets/category/${id}`, {
      method: 'DELETE',
      action: 'update_delete_ticket_category',
   });

export const addTicketSubCategory = (payload) =>
   Http(`/tickets/sub_category`, {
      method: 'POST',
      body: payload,
      action: 'add_ticket_category',
   });

export const updateTicketSubCategory = (id, payload) =>
   Http(`/tickets/sub_category/${id}/`, {
      method: 'PATCH',
      body: payload,
      action: 'update_delete_ticket_category',
   });

export const deleteTicketSubCategory = (id, payload) =>
   Http(`/tickets/sub_category/${id}`, {
      method: 'DELETE',
      body: payload,
      action: 'update_delete_ticket_category',
   });

export const getTicketFaq = (params) =>
   Http(`/tickets/prospafaqs${paramsObjectToQueryString(params)}`);

export const getSingleTicketFaq = (id) => Http(`/tickets/prospafaqs/${id}`);

export const addTicketFaq = (payload) =>
   Http(`/tickets/prospafaqs`, {
      method: 'POST',
      body: payload,
      action: 'add_ticket_category',
   });

export const updateTicketFaq = (id, payload) =>
   Http(`/tickets/prospafaqs/${id}`, {
      method: 'PUT',
      body: payload,
      action: 'update_delete_ticket_category',
   });

export const deleteTicketFaq = (id) =>
   Http(`/tickets/prospafaqs/${id}`, {
      method: 'DELETE',
      action: 'update_delete_ticket_category',
   });

export const getTicketMessages = (params) =>
   Http(`/tickets/messages/${paramsObjectToQueryString(params)}`, {
      action: 'can_read_all_ticket_messages',
   });

export const replyTicketMessage = async (payload) => {
   try {
      const response = await axiosCustom().post('/tickets/messages/', payload);
      return response;
   } catch (e) {
      throw new Error(e.response?.data?.message || e.message);
   }
};

export const getTicketStats = (params) =>
   Http(`/backmen/ticket_stats${paramsObjectToQueryString(params)}`);

export const getTicketStatsFilter = (params) =>
   Http(`/backmen/ticket_stats_filter${paramsObjectToQueryString(params)}`);
