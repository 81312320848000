import { Http } from 'services/http';
import { logActivity } from 'services/activity';

export const getActivePlan = (bizId) => {
   return Http(`/backmen/billing_plan/${bizId}`);
};

export const changeBillingPlan = async (payload, bizId) => {
   try {
      const response = await Http(`/backmen/billing_plan/${bizId}`, {
         body: payload,
         method: 'PATCH',
      });
      await logActivity(
         `Billing plan changed to ${payload.plan_name}`,
         'reg',
         payload.business_id,
         'account_upgrade',
      );
      return response;
   } catch (e) {
      throw new Error(e.message);
   }
};

export const getBillingPlans = async () => Http('/bill/plans/');

export const getBillingCycles = async () => Http('/bill/cycles/');
