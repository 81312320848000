/* eslint-disable react/prefer-stateless-function */
/* eslint-disable react/prop-types */
/* eslint-disable no-restricted-globals */
import { motion, AnimatePresence } from 'framer-motion';
import { Switch, useLocation } from 'react-router-dom';

export const MountTransition = ({ children, slide = 0, slideUp = 0 }) => (
   <motion.div
      exit={{ opacity: 0, x: slide, y: slideUp }}
      initial={{ opacity: 0, x: slide, y: slideUp }}
      animate={{
         opacity: 1,
         x: 0,
         y: 0,
         transition: { type: 'tween', duration: 0.2 },
      }}
   >
      {children}
   </motion.div>
);

export const OpenIn = ({ children, isActive = false }) => (
   <AnimatePresence>
      {isActive && (
         <motion.div
            exit={{ opacity: 0, height: 0, overflow: 'hidden' }}
            initial={{ opacity: 0, height: 0, overflow: 'hidden' }}
            animate={{ opacity: 1, height: 'auto', overflow: 'visible' }}
         >
            {children}
         </motion.div>
      )}
   </AnimatePresence>
);

export const RouteTransition = ({ children, slide = 0, slideUp = 5 }) => (
   <div>
      <MountTransition slide={slide} slideUp={slideUp}>
         {children}
      </MountTransition>
   </div>
);

export const AnimatedRoutes = ({ children, exitBeforeEnter = true, initial = false }) => {
   const location = useLocation();

   return (
      <AnimatePresence exitBeforeEnter={exitBeforeEnter} initial={initial}>
         <Switch location={location} key={location.pathname}>
            {children}
         </Switch>
      </AnimatePresence>
   );
};

export const SlideIn = ({ children, isActive = false, slide = 30, key = 'auniquestring' }) => (
   <AnimatePresence>
      {isActive && (
         <motion.div
            key={key}
            exit={{ opacity: 0, x: slide }}
            initial={{ opacity: 0, x: slide }}
            animate={{ opacity: 1, x: 0 }}
         >
            {children}
         </motion.div>
      )}
   </AnimatePresence>
);
