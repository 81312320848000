import { useState } from 'react';
import { Button } from 'components/button';
import { confirm } from 'components/confirm-box';
import { getFollowUpFeed, getActivationFeed } from 'services/business';
import { func, string } from 'prop-types';
import { toast } from 'react-hot-toast';
import { MenuComponent } from 'components/menu';
import moment from 'moment';

function Index({
   setModalVisibility,
   setBizAccount,
   setNoCloseIcon,
   setActiveTab,
   streamType,
   setDateRange,
}) {
   const [workAnchorEl, setWorkAnchorEl] = useState(null);
   // const [dateRange, setDateRange] = useState({});
   // const [businessFeed, setBusinessFeed] = useState();
   // const [bizAccount, setBizAccount] = useState('');
   const [today] = useState(moment().format('YYYY-MM-DD'));

   const openStatusMenu = Boolean(workAnchorEl);

   const handleStatusButtonClick = (event) => {
      setWorkAnchorEl(event.currentTarget);
   };

   const handleCloseStatusMenu = () => {
      setWorkAnchorEl(null);
   };

   const handleStartWork = (start, end, msg) => {
      setWorkAnchorEl(null);
      confirm({
         confirmText: `Are you sure you want to start work on accounts created in the last ${msg}?`,
         isLoading: false,
         onConfirm: async () => {
            const payload = {
               ...(start && end && { start_date: start }),
               ...(start && end && { end_date: end }),
               ...(!start && !end && { pending_time: 10 }),
            };
            setDateRange(payload);
            try {
               let result = '';
               if (streamType === 'work_followup') {
                  result = await getFollowUpFeed(payload, 'start');
               } else if (streamType === 'work_activation') {
                  result = await getActivationFeed(payload, 'start');
               }
               if (!result.data)
                  return toast.error('No business is currently in this time range stream');
               setBizAccount(result.data.biz_account_id);
               setNoCloseIcon(true);
               setModalVisibility(true);
               setActiveTab('overview');
            } catch ({ message }) {
               toast.error(message);
            }
         },
      });
   };

   return (
      <>
         <Button onClick={handleStatusButtonClick} className="ms-3" variant="primary">
            Start Work
         </Button>
         <MenuComponent
            anchorEl={workAnchorEl}
            handleClose={handleCloseStatusMenu}
            open={openStatusMenu}
            options={[
               'Last 10 mins',
               'Last 24 hours',
               'Last 7 days',
               'Last 30 days',
               'More than 30 days',
            ]}
            optionsFns={[
               () => handleStartWork(null, null, 'Last 10 mins'),
               () =>
                  handleStartWork(
                     moment().subtract(1, 'days').format('YYYY-MM-DD'),
                     today,
                     '24 hours',
                  ),
               () =>
                  handleStartWork(
                     moment().subtract(8, 'days').format('YYYY-MM-DD'),
                     moment().subtract(2, 'days').format('YYYY-MM-DD'),
                     '7 days',
                  ),
               () =>
                  handleStartWork(
                     moment().subtract(39, 'days').format('YYYY-MM-DD'),
                     moment().subtract(9, 'days').format('YYYY-MM-DD'),
                     // '2019-01-01',
                     // '2021-09-04',
                     '30 days',
                  ),
               () =>
                  handleStartWork(
                     '2019-01-01',
                     moment().subtract(40, 'days').format('YYYY-MM-DD'),
                     'more than 30 days',
                  ),
            ]}
         />
      </>
   );
}

Index.propTypes = {
   setModalVisibility: func.isRequired,
   setBizAccount: func.isRequired,
   setNoCloseIcon: func,
   setActiveTab: func.isRequired,
   streamType: string.isRequired,
   setDateRange: func.isRequired,
};

Index.defaultProps = {
   setNoCloseIcon: false,
};
export default Index;
