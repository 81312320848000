import { useEffect, useState, useCallback } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessage } from '@hookform/error-message';
import { func, objectOf, bool } from 'prop-types';
import Modal from 'components/modal';
import { NumberInput } from 'components/input';
import { toast } from 'react-hot-toast';
import { Button } from 'components/button';
import { confirm } from 'components/confirm-box';
import { createSavingsTerm, updateSavingsTerm } from 'services/fixed-deposits';

function AddSavingsTerm({ isEdit = false, onClose, open, term }) {
   const [loading, setLoading] = useState(false);
   const [changed, setChanged] = useState(true);

   const defaultValues = {
      interest_rate: 0,
      period: 0,
   };

   const schema = yup.object().shape({
      interest_rate: yup
         .number()
         .positive()
         .required()
         .min(0)
         .max(100, 'Minimum value allowed is 100'),
      period: yup.number().positive().required().min(0).max(15, 'Minimum value allowed is 15'),
   });

   const {
      register,
      handleSubmit,
      setValue,
      trigger,
      watch,
      formState: { isValid, errors },
   } = useForm({
      defaultValues,
      mode: 'onChange',
      resolver: yupResolver(schema),
      shouldFocusError: true,
   });

   useEffect(() => {
      register('interest_rate');
      register('period');
   }, []);

   const interest_rate = watch('interest_rate');
   const period = watch('period');

   useEffect(() => {
      if (isEdit) {
         setValue('interest_rate', term?.interest_rate);
         setValue('period', term?.period);

         trigger();
      }
   }, [isEdit]);

   const sharedOnChange = useCallback(async ({ name, value }, cb) => {
      setValue(name, value);
      await trigger(name);
      if (cb) {
         cb();
      }
   }, []);

   const getChanged = () => ({
      ...(interest_rate !== term.interest_rate && { interest_rate }),
      ...(period !== term.period && { period }),
   });

   useEffect(() => {
      if (isEdit) {
         const changedCheck = getChanged();
         setChanged(!!Object.keys(changedCheck).length);
      }
   }, [interest_rate, period]);

   const onSubmit = (data) => {
      confirm({
         confirmText: 'Are you sure you want to continue?',
         onConfirm: async () => {
            setLoading(true);
            try {
               if (!isEdit) {
                  await createSavingsTerm({ ...data });
                  toast.success('New term was created successfully');
               } else {
                  const payload = getChanged();
                  await updateSavingsTerm({ ...payload, id: term.id });
                  toast.success('Term was successful updated');
               }
               onClose();
            } catch (e) {
               toast.error(e.message);
            }
            setLoading(false);
         },
      });
   };

   const renderErrorMessage = (name) => (
      <ErrorMessage
         errors={errors}
         name={name}
         render={({ message }) => <p className="input-error-message text-danger">{message}</p>}
      />
   );

   return (
      <Modal open={open} onClose={onClose} title={isEdit ? 'Edit Term' : 'Add New Term'}>
         <form onSubmit={handleSubmit(onSubmit)} className="row">
            <div className="col-md-6 mb-4">
               <NumberInput
                  label="Interest Rate"
                  name="interest_rate"
                  value={interest_rate}
                  placeholder="Enter Interest Rate"
                  prefix="hidden"
                  suffix="%"
                  onChange={({ floatValue }) =>
                     sharedOnChange({ name: 'interest_rate', value: floatValue || 0 })
                  }
               />
               {renderErrorMessage('interest_rate')}
            </div>

            <div className="col-md-6 mb-4">
               <NumberInput
                  label="Period"
                  name="period"
                  prefix="hidden"
                  value={period}
                  placeholder="Enter Period"
                  onChange={({ floatValue }) =>
                     sharedOnChange({ name: 'period', value: floatValue || 0 })
                  }
               />
               {renderErrorMessage('period')}
            </div>

            <div className="d-flex justify-content-end pt-5">
               <Button type="submit" isLoading={loading} disabled={!isValid || !changed}>
                  {isEdit ? 'Update Term' : 'Add New Term'}
               </Button>
            </div>
         </form>
      </Modal>
   );
}

AddSavingsTerm.propTypes = {
   isEdit: bool.isRequired,
   onClose: func.isRequired,
   open: func.isRequired,
   term: objectOf().isRequired,
};

export default AddSavingsTerm;
