/* eslint-disable no-use-before-define */
import { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { Skeleton } from '@material-ui/lab';
import { Input } from 'components/input';
import LoggedInDashboard from 'components/logged-in-dashboard';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import { CloseOutlined, MenuOutlined } from '@material-ui/icons';
import { TableComponent } from 'components/table';
import ExportDataModal from 'components/export-data';
import { FilterContainer } from 'pages/follow-up/follow-up.style';
import { BusinessWorkerBtn } from 'components/start-work-btn';
import { StatsCards } from 'components/stats-cards';
import ExportedData from 'components/exported-data';
import {
   addBizAccountToPool,
   createPool,
   deletePool,
   getActivationAccounts,
   getActivationStats,
   listBizAccountPerPools,
   listPools,
   removeBusinessAccountFromPool,
} from 'services/business';
import { IconButton } from '@material-ui/core';
import { exportDataDump } from 'services/loans';
import { TableContainer, TableContent } from 'styles/table';
import { HeadingOne } from 'styles/typography';
import { numberWithCommas } from 'utils/others';
import { useDebouncedCallback } from 'use-debounce';
import { Button } from 'components/button';
import { TabWrapper } from 'pages/pending-documents/style';
import toast from 'react-hot-toast';
import FilterListIcon from '@material-ui/icons/FilterList';
import { useBizDetailsModal } from 'hooks/bizDetailsModal';
import { hasPermission } from 'utils/user_access';
import { formatPayloadFromFilter } from 'utils/formatter';
import { useAuth } from 'hooks/auth';
import { MenuComponent } from 'components/menu';
import BusinessDetails from 'containers/business-details';
import { ApprovedBusinessFilter } from 'containers/business-account-filter-modal';
import { downloadFile } from 'utils/file';
import { getTabs, getColumns, exportTypes } from './utils';
import PerformanceOverview from './features/performance-overview';
import ActivatedZeroTransactions from './features/activated-zero-transactions';
import { BulkAssignManagers } from './features/bulk-assign';

const initialFilter = {
   date_after: moment().subtract(30, 'days').format('YYYY-MM-DD'),
   date_before: moment().format('YYYY-MM-DD'),
};

const Activation = () => {
   const { setActiveTab } = useBizDetailsModal();
   const { currentUser } = useAuth();
   const { permissions } = currentUser?.access || {};
   const history = useHistory();
   const [currentPage, setCurrentPage] = useState(1);
   const debounce = useDebouncedCallback(handleSearch, 600);
   const [searchTerm, setSearchTerm] = useState('');
   const [approvedAccountData, setApprovedAccountData] = useState([]);
   const [stats, setStats] = useState([]);
   const [isLoadingApprovedData, setIsLoadingApprovedData] = useState(false);
   const [loadingStats, setLoadingStats] = useState(false);
   const [paginationData, setPaginationData] = useState({});
   const [poolYouthId, setPoolYouthId] = useState('');
   const [modalVisibilityFilter, setModalVisibilityFilter] = useState(false);
   const [modalVisibility, setModalVisibility] = useState(false);
   const [activeFilter, setActiveFilter] = useState('all');
   const [customColumnActive, setCustomColumnActive] = useState(false);
   const [bulkAssignActive, setBulkAssignActive] = useState(false);
   const [bulkAssignType, setBulkAssignType] = useState(null);
   const [assignManagersModalVisibility, setAssignManagersModalVisibility] = useState(false);
   const [assignType, setAssignType] = useState('');
   const [pool, setPool] = useState([]);
   const [selectionModel, setSelectionModel] = useState([]);
   const [columnName, setColumnName] = useState('');
   const [selectedCustomColumn, setSelectedCustomColumn] = useState(false);
   const [bizAccount, setBizAccount] = useState('');
   const [bizApprovedAccountPerPool, setBizApprovedAccountPerPool] = useState([]);
   const [streamType] = useState('work_activation');
   const [noCloseIcon, setNoCloseIcon] = useState(false);
   const [anchorEl, setAnchorEl] = useState(null);
   const open = Boolean(anchorEl);
   const [poolAnchorEl, setPoolAnchorEl] = useState(null);
   const openPoolMenu = Boolean(poolAnchorEl);
   const [actionAnchorEl, setActionsAnchorEl] = useState(null);
   const openActionsMenu = Boolean(actionAnchorEl);
   const [dateRange, setDateRange] = useState({});
   const [filterData, setFilterData] = useState(initialFilter);
   const [filterPayload, setFilterPayload] = useState(initialFilter);
   const [showExport, setShowExport] = useState(false);
   const [exportState, setExportState] = useState({
      start_date: moment().format('YYYY-MM-01'),
      end_date: moment().format('YYYY-MM-DD'),
      allowRange: false,
   });

   const actionOptions = ['Custom Column', 'Bulk Assign Accounts', 'Bulk Assign Categories'];

   const options = ['View Details', 'Call', 'Send Message', selectedCustomColumn && 'Delete'];
   const isAdmin = hasPermission(permissions, 'activation_admin');

   const tabs = getTabs(permissions);

   useEffect(() => {
      history.push(`${history.location.pathname}?page=1`);
   }, []);

   const fetchApprovedAccounts = async () => {
      try {
         setIsLoadingApprovedData(true);
         setApprovedAccountData([]);
         setPaginationData({});
         const defaultPayload = {
            ...(searchTerm
               ? {
                    biz_friendly_name: searchTerm,
                 }
               : {
                    ...filterPayload,
                    date_before: moment(filterPayload.date_before)
                       .add(1, 'day')
                       .format('YYYY-MM-DD'),
                 }),
         };
         const accounts = await getActivationAccounts({
            page: currentPage,
            ...defaultPayload,
         });
         setApprovedAccountData(accounts.results);
         setPaginationData(accounts);
      } catch (err) {
         return err;
      } finally {
         setIsLoadingApprovedData(false);
      }
   };

   const fetchActivationStats = async () => {
      try {
         setLoadingStats(true);
         const stat = await getActivationStats({
            ...filterPayload,
            date_before: moment(filterPayload.date_before).add(1, 'day').format('YYYY-MM-DD'),
         });
         const { ok, deposit_value, ...rest } = stat;
         setStats({ ...rest, deposit_value: `₦ ${numberWithCommas(deposit_value)}` });
      } catch (err) {
         return err;
      } finally {
         setLoadingStats(false);
      }
   };

   const fetchBizAccountPerPool = async () => {
      try {
         setIsLoadingApprovedData(true);
         setBizApprovedAccountPerPool([]);
         setPaginationData({});
         const result = await listBizAccountPerPools(
            {
               page: currentPage,
            },
            {
               pool_grand_id: activeFilter,
            },
         );
         setBizApprovedAccountPerPool(result.data);
         setPaginationData({ count: result.data[0]?.pool_youth_details?.length });
      } catch (err) {
         return err;
      } finally {
         setIsLoadingApprovedData(false);
      }
   };

   const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
   };

   const handleActionClick = (event) => {
      setActionsAnchorEl(event.currentTarget);
   };

   const handleExistingPoolClick = (event) => {
      setPoolAnchorEl(event.currentTarget);
   };

   const handleCloseExistingPoolClick = () => {
      setPoolAnchorEl(null);
   };

   const handleCloseActions = () => {
      setActionsAnchorEl(null);
   };

   const handleClose = () => {
      setAnchorEl(null);
   };

   function handleOpenModal() {
      setModalVisibility(true);
      setActiveTab('overview');
      setNoCloseIcon(false);
   }

   function sendMessageModalSection() {
      setActiveTab('message');
      setModalVisibility(true);
   }

   function callNumber(phone) {
      const link = document.createElement('a');
      const linkText = document.createTextNode('call us');
      link.appendChild(linkText);
      link.href = `tel:${phone}`;
      link.click();
   }

   function handleSearch({ target }) {
      setSearchTerm(target.value);
   }

   const handleModalOpen = (type) => {
      setAssignType(type);
      setAssignManagersModalVisibility(true);
   };

   const columns = getColumns({
      handleOpenModal,
      handleClick,
      anchorEl,
      handleClose,
      open,
      options,
      callNumber,
      sendMessageModalSection,
      selectedCustomColumn,
      deleteBusinessAccountFromPool,
   });

   async function deleteBusinessAccountFromPool() {
      try {
         await removeBusinessAccountFromPool({
            pool_youth_id: poolYouthId,
         });
         await fetchBizAccountPerPool();
      } catch (err) {
         return err;
      }
   }

   useEffect(() => {
      fetchPools();
      const tabKeys = tabs.map((item) => item.key);
      if (activeFilter === 'all') {
         setBizApprovedAccountPerPool([]);
         fetchApprovedAccounts();
         setSelectedCustomColumn(false);
      } else if (!tabKeys.includes(activeFilter)) {
         history.push(`${history.location.pathname}?page=${1}`);
         setApprovedAccountData([]);
         fetchBizAccountPerPool();
      }
   }, [currentPage, searchTerm, activeFilter, filterPayload]);

   useEffect(() => {
      return fetchActivationStats();
   }, [filterPayload]);

   function handleCustomColumn() {
      setCustomColumnActive(!customColumnActive);
   }

   function handleBulkAssign(value) {
      setBulkAssignType(value);
      setBulkAssignActive(!bulkAssignActive);
   }

   function textOnChange({ target }) {
      setColumnName(target.value);
   }

   async function handlePoolCreation() {
      try {
         const ids = selectionModel.map((item) => {
            return JSON.parse(item);
         });
         await createPool({
            pool_name: columnName,
            pool_biz_tag: ids,
         });
         setCustomColumnActive(false);
         setColumnName('');
         setSelectionModel([]);
         toast.success('Pool created successfully');
         fetchPools();
      } catch (err) {
         return err;
      }
   }

   async function fetchPools() {
      try {
         const result = await listPools();
         setPool(result.data);
      } catch (err) {
         return err;
      }
   }

   function onRowClick({ row }) {
      setBizAccount(row.id);
      setPoolYouthId(row.pool_youth_id);
   }

   async function handlePoolDelete(id) {
      try {
         await deletePool({
            pool_grand_id: id,
         });
         toast.success('Deleted Successfully');
         setPool(pool.filter((p) => p.pool_grand_id !== id));
         setActiveFilter('all');
         // setSelectedCustomColumn(false);
         history.push(`${history.location.pathname}`);
      } catch (err) {
         return err;
      }
   }

   async function addToExistingPool(existingPoolId) {
      try {
         const ids = selectionModel.map((item) => {
            return JSON.parse(item).id;
         });

         const result = await addBizAccountToPool({
            pool_grand_id: existingPoolId,
            pool_biz_tag: ids,
         });
         toast.success(result?.message, { duration: 3000 });
         setCustomColumnActive(false);
      } catch (err) {
         toast.error(err.message || err.custom_message);
         return err;
      }
   }

   const poolData = useMemo(
      () =>
         bizApprovedAccountPerPool.reduce((acc, item) => {
            item.pool_youth_details.forEach((elem) =>
               acc.push({ ...elem.pool_biz_details, pool_youth_id: elem.pool_youth_id }),
            );
            return acc;
         }, []),
      [bizApprovedAccountPerPool],
   );

   function onPageChange(e, pageNumber) {
      setCurrentPage(pageNumber);
   }

   const handleTabSwitch = (key) => {
      setActiveFilter(key);
      // if (key === 'performance') {
      setSelectedCustomColumn(false);
      history.push(`${history.location.pathname}?page=${1}`);
      // }
   };

   async function handleOnSubmit(data) {
      const selected = exportTypes.find((item) => item.value === data.download_type);
      const payload = formatPayloadFromFilter(selected.filters, data);
      try {
         const result = await exportDataDump(payload);
         toast.success(result?.message || 'File has been downloaded successfully to your machine');
         if (payload.download_type === 'no_biz_fund_report') {
            downloadFile(result.data, 'Business_on_zero_all_time');
         }
         setShowExport(false);
      } catch (err) {
         toast.error(err.message || err.custom_message);
      }
   }

   const tableData = !selectedCustomColumn ? approvedAccountData : poolData;

   return (
      <LoggedInDashboard>
         <div className="d-flex justify-content-between align-items-center mb-4">
            <HeadingOne> Activation</HeadingOne>
            <BusinessWorkerBtn
               setModalVisibility={(value) => setModalVisibility(value)}
               setBizAccount={(value) => setBizAccount(value)}
               setNoCloseIcon={(value) => setNoCloseIcon(value)}
               setActiveTab={() => setActiveTab('overview')}
               streamType={streamType}
               setDateRange={setDateRange}
            />
         </div>

         {hasPermission(permissions, 'can_view_activations_stats') && (
            <StatsCards data={stats} isLoading={loadingStats} />
         )}

         <TableContainer id="table">
            <TabWrapper>
               <ul className="m-0 d-flex align-items-center">
                  {tabs?.map(({ label, key }) => (
                     <li
                        key={key}
                        className={`${
                           activeFilter === key ? 'active' : ''
                        } pending-doc-overview pending-tab-action`}
                        onClick={() => handleTabSwitch(key)}
                     >
                        {label}
                     </li>
                  ))}

                  {pool.map((p) => (
                     <li
                        className={`${
                           activeFilter === p.pool_grand_id ? 'active' : ''
                        } pending-doc-overview pending-tab-action`}
                        onClick={() => {
                           setActiveFilter(p.pool_grand_id);
                           setSelectedCustomColumn(true);
                           setCustomColumnActive(false);
                        }}
                     >
                        {p.pool_name}
                        <span className="close-icon">
                           <CloseOutlined
                              style={{ fontSize: '1.2rem' }}
                              onClick={() => handlePoolDelete(p.pool_grand_id)}
                           />
                        </span>
                     </li>
                  ))}
               </ul>
            </TabWrapper>
            {customColumnActive && (
               <>
                  <div className="d-flex justify-content-start my-3 w-75">
                     <Input placeholder="Column name" onChange={textOnChange} />

                     <div className="ms-4">
                        <Button variant="secondary" onClick={() => setCustomColumnActive(false)}>
                           Cancel
                        </Button>
                     </div>
                     <div className="ms-4">
                        <Button
                           onClick={handlePoolCreation}
                           variant="primary"
                           disabled={!columnName || selectionModel.length < 1}
                        >
                           Add
                        </Button>
                     </div>
                     {pool?.length ? (
                        <div className="ms-3">
                           <Button
                              onClick={handleExistingPoolClick}
                              className="bg-secondary"
                              disabled={!selectionModel?.length}
                           >
                              Add to existing pool
                           </Button>
                           <MenuComponent
                              anchorEl={poolAnchorEl}
                              handleClose={handleCloseExistingPoolClick}
                              open={openPoolMenu}
                              options={pool?.map((p) => p.pool_name)}
                              optionsFns={pool?.map(
                                 (p) => () => addToExistingPool(p.pool_grand_id),
                              )}
                           />
                        </div>
                     ) : (
                        <span />
                     )}
                  </div>
               </>
            )}

            <div className="d-flex gap-3 mb-3 justify-content-end">
               {bulkAssignActive && selectionModel.length === 0 && (
                  <Button onClick={() => handleModalOpen('all-accounts')}>
                     Bulk Assign All Accounts
                  </Button>
               )}
               {bulkAssignActive && selectionModel.length > 0 && (
                  <Button onClick={() => handleModalOpen('selected-accounts')}>Bulk Assign</Button>
               )}
            </div>

            {(activeFilter === 'all' || selectedCustomColumn) && (
               <>
                  <TableContent>
                     {isLoadingApprovedData ? (
                        <Skeleton width="30%" height="20" />
                     ) : (
                        <div>
                           <h1> Business Accounts</h1>
                           <span className="value">{paginationData?.count || 0}</span>
                        </div>
                     )}
                     <div className="d-flex">
                        {!selectedCustomColumn && (
                           <div>
                              <Input
                                 style={{ width: '300px' }}
                                 placeholder="Search"
                                 onChange={debounce}
                              />
                           </div>
                        )}

                        {hasPermission(permissions, 'admin_can_view_exported_data') && (
                           <div>
                              <FilterContainer className="ms-3" onClick={() => setShowExport(true)}>
                                 Export Data
                                 <ImportExportIcon style={{ marginLeft: '10px' }} />
                              </FilterContainer>
                           </div>
                        )}
                        <div>
                           <FilterContainer
                              style={{
                                 margin: !customColumnActive ? '0 10px' : '0px 0px 0px 10px',
                              }}
                              onClick={() => setModalVisibilityFilter(true)}
                           >
                              Filter By <FilterListIcon style={{ marginLeft: '10px' }} />
                           </FilterContainer>
                        </div>
                        <div className="w-100">
                           <div>
                              <IconButton
                                 aria-label="more"
                                 aria-controls="long-menu"
                                 aria-haspopup="true"
                                 onClick={handleActionClick}
                              >
                                 <MenuOutlined />
                              </IconButton>
                              <MenuComponent
                                 anchorEl={actionAnchorEl}
                                 handleClose={handleCloseActions}
                                 open={openActionsMenu}
                                 options={actionOptions}
                                 optionsFns={[
                                    () => handleCustomColumn(),
                                    () => handleBulkAssign('accounts'),
                                    () => handleBulkAssign('categories'),
                                    // place the callbacks in position corresponding to the options index
                                 ]}
                              />
                           </div>
                        </div>
                     </div>
                  </TableContent>
                  <TableComponent
                     columns={columns}
                     rows={tableData}
                     isLoading={isLoadingApprovedData}
                     onPageChange={onPageChange}
                     count={Math.ceil(paginationData.count / (!selectedCustomColumn ? 15 : 20))}
                     page={!selectedCustomColumn ? currentPage : ''}
                     checkboxSelection={customColumnActive || bulkAssignActive}
                     onSelectionModelChange={setSelectionModel}
                     // selectionModel={allPools}
                     {...(selectedCustomColumn && { pageSize: 20 })}
                     onRowClick={onRowClick}
                     highlight
                  />
               </>
            )}
            {activeFilter === 'performance' && <PerformanceOverview filter={filterPayload} />}
            {activeFilter === 'activated_zero_transactions' && <ActivatedZeroTransactions />}
         </TableContainer>

         <ApprovedBusinessFilter
            open={modalVisibilityFilter}
            onClose={() => setModalVisibilityFilter(false)}
            filterData={filterData}
            setFilterData={setFilterData}
            setFilterPayload={setFilterPayload}
            isAdmin={isAdmin}
            initialFilter={initialFilter}
         />

         <BusinessDetails
            businessAccountId={bizAccount}
            open={modalVisibility}
            onClose={() => setModalVisibility(false)}
            noCloseIcon={noCloseIcon}
            streamType={streamType}
            dateRange={dateRange}
         />

         {assignManagersModalVisibility && (
            <BulkAssignManagers
               open={assignManagersModalVisibility}
               onClose={() => setAssignManagersModalVisibility(false)}
               assignType={assignType}
               bulkAssignType={bulkAssignType}
               selectionModel={selectionModel}
               filterData={filterData}
               setBulkAssignActive={setBulkAssignActive}
               setSelectionModel={setSelectionModel}
               fetchApprovedAccounts={fetchApprovedAccounts}
            />
         )}

         {showExport && (
            <ExportDataModal
               open={showExport}
               onClose={() => setShowExport(false)}
               exportOptions={exportTypes}
               exportState={exportState}
               setExportState={setExportState}
               onSubmit={handleOnSubmit}
            />
         )}
         {activeFilter === 'exports' && (
            <ExportedData
               exportTypes={exportTypes.filter((item) => item.value !== 'no_biz_fund_report')}
            />
         )}
      </LoggedInDashboard>
   );
};

export default Activation;
