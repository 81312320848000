import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import { useDebouncedCallback } from 'use-debounce';
import { Skeleton } from '@material-ui/lab';
import FilterListIcon from '@material-ui/icons/FilterList';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import { openNewWindow } from 'utils/others';
import { Input } from 'components/input';
import { Button } from 'components/button';
import { confirm } from 'components/confirm-box';
import { TableComponent } from 'components/table';
import { StatsCards } from 'components/stats-cards';
import { TooltipComponent } from 'components/tooltip';
import { Status } from 'styles/utils';
import { TableContainer, TableContent } from 'styles/table';
import { FilterContainer } from 'pages/follow-up/follow-up.style';
import CardFilterModal from 'pages/prospa-cards/features/card-filter-modal';
import { exportCardsData, getCards, getCardStats } from 'services/cards';
import CardDetails from '../features/card-details';
import { CardDesignModal } from '../features/card-designs';

const initialFilter = {
   payment_date__start: moment().format('YYYY-MM-01'),
   payment_date__end: moment().format('YYYY-MM-DD'),
};

export default function PhysicalCardsDashboard() {
   const { search } = useLocation();
   const page = new URLSearchParams(search).get('page');
   const [stats, setStats] = useState([]);
   const [currentPage, setCurrentPage] = useState(page || 1);
   const [searchTerm, setSearchTerm] = useState('');
   const [tableData, setTableData] = useState([]);
   const [modalVisibilityFilter, setModalVisibilityFilter] = useState(false);
   const [modalVisibilityDetails, setModalVisibilityDetails] = useState(false);
   const [modalVisibilityDesigns, setModalVisibilityDesigns] = useState(false);
   const [paginationData, setPaginationData] = useState({});
   const [isLoadingStats, setIsLoadingStats] = useState(false);
   const [isLoadingData, setIsLoading] = useState(false);
   const [filterData, setFilterData] = useState(initialFilter);
   const [filterPayload, setFilterPayload] = useState({});
   const [exportActive, setExportActive] = useState(false);
   const [selectionModel, setSelectionModel] = useState([]);

   const fetchCardDetails = async () => {
      setIsLoading(true);
      try {
         const result = await getCards({
            page: currentPage,
            ...filterPayload,
            account_name: searchTerm,
            card_type: 'physical',
         });
         setTableData(result.results);
         setPaginationData(result);
      } catch (err) {
         return err;
      } finally {
         setIsLoading(false);
      }
   };

   const handleExportData = () => {
      confirm({
         confirmText: 'Are you sure want to export the data?',
         onConfirm: async () => {
            setIsLoading(true);
            try {
               const payload = {
                  card_ids: selectionModel,
               };
               const result = await exportCardsData({ ...filterPayload, payload });
               toast.success(result.message || 'Action Successful!');

               const { accounts, card_accounts, cards } = result;

               if (accounts) {
                  openNewWindow(accounts);
               }

               if (card_accounts) {
                  openNewWindow(card_accounts);
               }

               if (cards) {
                  openNewWindow(cards);
               }

               setIsLoading(false);
               setExportActive(false);
            } catch (e) {
               toast.error(e?.message || 'Something went wrong!');
               setIsLoading(false);
            }
         },
      });
   };

   const fetchCardStats = async () => {
      setIsLoadingStats(true);
      try {
         const res = await getCardStats();
         const { ok, ...remData } = res;
         setStats(remData);
         setIsLoadingStats(false);
      } catch (e) {
         toast.error(e?.message);
         setIsLoadingStats(false);
      }
   };

   useEffect(() => {
      fetchCardStats();
   }, []);

   function handleSearch({ target }) {
      setSearchTerm(target.value);
   }

   const debounce = useDebouncedCallback(handleSearch, 600);

   function handleOpenModal(id) {
      setModalVisibilityDetails(id);
   }

   useEffect(() => {
      fetchCardDetails();
   }, [searchTerm, currentPage, filterPayload]);

   function onPageChange(e, pageNumber) {
      setCurrentPage(pageNumber);
   }

   const columns = [
      {
         field: 'business_name',
         headerName: 'Business Name',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.business_name}>
               <span
                  style={{
                     cursor: 'pointer',
                  }}
                  className="text-primary"
                  onClick={() => handleOpenModal(row.id)}
               >
                  {row?.business_name || 'N/A'}
               </span>
            </TooltipComponent>
         ),
      },
      {
         field: 'name',
         headerName: 'Owner',
         renderCell: ({ row }) => (
            <span style={{ textTransform: 'capitalize' }}>
               {row?.delivery_address?.name.toLowerCase() || 'N/A'}{' '}
            </span>
         ),
      },
      {
         field: 'order_id',
         headerName: 'Order ID',
         renderCell: ({ row }) => <span>#{row?.order_id}</span>,
      },
      {
         field: 'delivery_option',
         headerName: 'Priority ',
         renderCell: () => <span>Standard</span>,
      },
      {
         field: 'design?.name',
         headerName: 'Type ',
         renderCell: ({ row }) => <span>{row?.design?.name.toUpperCase()}</span>,
      },
      {
         field: 'status',
         headerName: 'Card Status ',
         renderCell: ({ row }) => <Status status={row?.status}>{row?.status}</Status>,
      },
      {
         field: 'delivery_status',
         headerName: 'Delivery Status',
         renderCell: ({ row }) => (
            <Status status={row?.delivery_status}>{row?.delivery_status}</Status>
         ),
      },
      {
         field: 'created',
         headerName: 'Date Submitted',
         renderCell: ({ row }) => (
            <span>
               {moment(row?.created).format('MMM DD, YYYY')} |{' '}
               {moment(row?.created).format('h:mm a')}
            </span>
         ),
      },
      {
         field: 'delivery_date',
         headerName: 'Delivery Date',
         renderCell: ({ row }) =>
            !row?.delivery_date ? (
               <span>N/A</span>
            ) : (
               <span>
                  {moment(row?.delivery_date).format('MMM DD, YYYY')} |{' '}
                  {moment(row?.delivery_date).format('h:mm a')}
               </span>
            ),
      },
   ];

   return (
      <>
         <StatsCards data={stats} isLoading={isLoadingStats} />

         <div className="d-flex justify-content-end">
            <Button onClick={() => setModalVisibilityDesigns(true)}>View Cards</Button>
         </div>
         <TableContainer id="table">
            <TableContainer id="table">
               <TableContent>
                  {isLoadingData ? (
                     <Skeleton width="30%" height="20" />
                  ) : (
                     <div>
                        <h1>Physical Cards</h1>
                        <span className="value">{paginationData?.count || 'N/A'}</span>
                     </div>
                  )}
                  {exportActive ? (
                     <div className="d-flex justify-content-end me-5">
                        <Button fullWidth onClick={handleExportData}>
                           Export
                        </Button>
                     </div>
                  ) : (
                     <div className="d-flex justify-content-between">
                        <div>
                           <Input
                              style={{ width: '300px', height: '48px' }}
                              placeholder="Search for Business"
                              onChange={debounce}
                           />
                        </div>

                        <FilterContainer
                           style={{ margin: '0px 0px 0px 20px' }}
                           onClick={() => setModalVisibilityFilter(true)}
                        >
                           Filter By <FilterListIcon style={{ marginLeft: '10px' }} />
                        </FilterContainer>
                        <div style={{ margin: '0px 0px 0px 20px' }}>
                           <FilterContainer className="me-3" onClick={() => setExportActive(true)}>
                              Bulk Export
                              <ImportExportIcon style={{ marginLeft: '10px' }} />
                           </FilterContainer>
                        </div>
                     </div>
                  )}
               </TableContent>
               <TableComponent
                  columns={columns}
                  rows={tableData}
                  isLoading={isLoadingData}
                  onPageChange={onPageChange}
                  count={Math.round(paginationData.count / 15)}
                  checkboxSelection={exportActive}
                  onSelectionModelChange={setSelectionModel}
               />
               {modalVisibilityFilter && (
                  <CardFilterModal
                     open={modalVisibilityFilter}
                     onClose={() => setModalVisibilityFilter(false)}
                     setCurrentPage={setCurrentPage}
                     filterData={filterData}
                     setFilterData={setFilterData}
                     setFilterPayload={setFilterPayload}
                  />
               )}
               {modalVisibilityDetails && (
                  <CardDetails
                     type="physical"
                     open={modalVisibilityDetails}
                     cardId={modalVisibilityDetails}
                     onClose={() => setModalVisibilityDetails(false)}
                  />
               )}
               {modalVisibilityDesigns && (
                  <CardDesignModal
                     open={modalVisibilityDesigns}
                     onClose={() => setModalVisibilityDesigns(false)}
                     fetchCardDetails={fetchCardDetails}
                  />
               )}
            </TableContainer>
         </TableContainer>
      </>
   );
}
