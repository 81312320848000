import { Http } from 'services/http';
import { logActivity } from './activity';

export const sendPinResetApi = async (payload) => {
   const { businessId } = payload;
   try {
      const response = await Http('/backmen/invoke_grand_approval/', {
         method: 'POST',
         body: payload,
         action: 'pin_reset',
      });
      await logActivity(
         `Pin was reset for user email:${payload.rep_email}`,
         'reg',
         businessId,
         'pin_reset',
      );
      return response;
   } catch (e) {
      throw new Error(e.message);
   }
};

export const sendPasswordResetApi = async (payload) => {
   const { businessId, ...rest } = payload;
   try {
      const response = await Http('/account/reset_password_service/', {
         body: rest,
         method: 'POST',
         action: 'can_approve_otp',
      });
      await logActivity(
         `Password was reset for user email:${payload.email}`,
         'reg',
         businessId,
         'can_approve_otp',
      );
      return response;
   } catch (e) {
      throw new Error(e.message);
   }
};
