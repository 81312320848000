import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import moment from 'moment';
import { Skeleton } from '@material-ui/lab';
import { TableContainer, TableContent } from 'styles/table';
import { confirm } from 'components/confirm-box';
import { numberWithCommas } from 'utils/others';
import { Button } from 'components/button';
import { StatsCards } from 'components/stats-cards';
import { renderTransactionStatus } from 'utils/business';
import { TooltipComponent } from 'components/tooltip';
import { TableComponent } from 'components/table';
import TransactionModal from 'components/TransactionModal';
import {
   getPendingTransferStats,
   getPendingTransfers,
   approvePendingTransactions,
} from 'services/transactions';

const initialFilter = {
   start_date: '2020-01-01',
   end_date: moment().format('YYYY-MM-DD'),
};

function PendingTransactions() {
   const [tableData, setTableData] = useState([]);
   const [isLoading, setIsLoading] = useState(false);
   const [paginationData, setPaginationData] = useState({});
   const [currentPage, setCurrentPage] = useState(1);
   const [transactionDetail, setTransactionDetail] = useState('');
   const [transactionModal, setTransactionModal] = useState(false);
   const [selectionModel, setSelectionModel] = useState([]);
   const [customColumnActive, setCustomColumnActive] = useState(false);
   const [loading, setLoading] = useState(false);
   const [filterPayload] = useState(initialFilter);
   const [stats, setStats] = useState({});

   const onTransactionClick = (data) => {
      setTransactionModal(true);
      setTransactionDetail(data);
   };

   const fetchTransactionsStats = async () => {
      setIsLoading(true);
      try {
         const { pending_transactions_count, total_amount } = await getPendingTransferStats({});
         setStats({
            'All pending transactions': `${numberWithCommas(pending_transactions_count)}`,
            'Total Value': `₦${numberWithCommas(total_amount)}`,
         });
         setIsLoading(false);
      } catch (error) {
         toast.error(error?.message || 'Something went wrong');
         setIsLoading(false);
      }
   };

   useEffect(() => {
      fetchTransactionsStats();
   }, []);

   const columns = [
      {
         field: 'txn_ref',
         width: 280,
         headerName: 'Transaction Ref',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.txn_ref}>
               <span
                  style={{ cursor: 'pointer' }}
                  className="text-primary"
                  onClick={() => onTransactionClick(row)}
               >
                  {row?.txn_ref}
               </span>
            </TooltipComponent>
         ),
      },
      {
         field: 'transaction_id',
         headerName: 'Transaction ID',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.txn_id}>{row?.txn_id}</TooltipComponent>
         ),
      },
      {
         field: 'recipient',
         width: 350,
         headerName: 'Recipient',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.recipient}>
               {row?.recipient?.replace('_', ' ')}
            </TooltipComponent>
         ),
      },
      {
         field: 'amount',
         headerName: 'Amount',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.amount}>
               ₦{numberWithCommas(row?.amount)}
            </TooltipComponent>
         ),
      },
      {
         field: 'initiated_by',
         width: 250,
         headerName: 'Initiated By',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.initiated_by || 'N/A'}>
               {row?.initiated_by || 'N/A'}
            </TooltipComponent>
         ),
      },
      {
         field: 'status',
         headerName: 'Status',
         renderCell: ({ row }) =>
            renderTransactionStatus({
               transfer_details: {
                  transfer_status: row?.status,
               },
            }),
      },

      {
         field: 'business_name',
         width: 350,
         headerName: 'Business Name',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.business_name}>{row?.business_name}</TooltipComponent>
         ),
      },
      {
         field: 'txn_date',
         headerName: 'Date',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.txn_date}>
               {moment(row?.txn_date).format('DD MMM, YYYY')} |{' '}
               <span style={{ opacity: '.5' }}>{moment(row?.txn_date).format('HH:mm A')}</span>
            </TooltipComponent>
         ),
      },
   ];

   const fetchTransactions = async (pageNumber) => {
      setIsLoading(true);
      try {
         const transactions = await getPendingTransfers({
            page: pageNumber || currentPage,
            ...filterPayload,
            status: 'pending',
         });
         setTableData(transactions?.result);
         setPaginationData(transactions);
      } catch (err) {
         toast.error(err.message || err.custom_message);
      }

      setIsLoading(false);
   };

   useEffect(() => {
      fetchTransactions();
   }, [currentPage, filterPayload]);

   function onPageChange(e, pageNumber) {
      setCurrentPage(pageNumber);
   }

   const onApprove = async (type) => {
      const isSelection = type === 'selected';
      confirm({
         proceedBtnText: 'Proceed',
         cancelBtnText: 'Cancel',
         confirmText: `Are you sure you want to approve ${type} transactions?`,
         onConfirm: async () => {
            setLoading(true);
            try {
               const txn_refs = selectionModel.map((item) => {
                  return JSON.parse(item)?.txn_ref;
               });
               const response = await approvePendingTransactions({
                  ...(isSelection && { txn_refs }),
                  approve: true,
               });
               setSelectionModel([]);
               setCustomColumnActive(false);
               confirm({
                  proceedBtnText: 'Close',
                  hide: { cancel: true },
                  confirmText: response?.message || 'Action Successful!',
                  onConfirm: () => {
                     setTableData([]);
                     fetchTransactions();
                  },
               });
            } catch (e) {
               toast.error(e?.message);
            }
            setLoading(false);
         },
      });
   };

   return (
      <>
         <TableContainer>
            {isLoading ? (
               <Skeleton width="30%" height="20" />
            ) : (
               <div className="mb-5">
                  <StatsCards data={stats} isLoading={isLoading} />
               </div>
            )}
            <TableContent>
               {isLoading ? (
                  <Skeleton width="30%" height="20" />
               ) : (
                  <div>
                     <h1> Pending Transactions</h1>
                     <span className="value">{paginationData?.count || 'N/A'}</span>
                  </div>
               )}
               <div className="d-flex align-items-center">
                  {customColumnActive ? (
                     <Button
                        variant="primary"
                        onClick={() => onApprove('selected')}
                        disabled={!selectionModel?.length}
                        isLoading={loading}
                     >
                        Approve selected transactions
                     </Button>
                  ) : (
                     <>
                        <Button
                           variant="secondary"
                           style={{ marginRight: '10px' }}
                           onClick={() => setCustomColumnActive(true)}
                        >
                           Select transactions
                        </Button>

                        <Button
                           variant="primary"
                           onClick={() => onApprove('all')}
                           isLoading={loading}
                        >
                           Approve all
                        </Button>
                     </>
                  )}
               </div>
            </TableContent>
            <TableComponent
               columns={columns}
               rows={tableData}
               isLoading={isLoading}
               onPageChange={onPageChange}
               count={Math.ceil(paginationData.count / 15)}
               checkboxSelection={customColumnActive}
               onSelectionModelChange={setSelectionModel}
            />
         </TableContainer>
         <TransactionModal
            open={transactionModal}
            onClose={() => setTransactionModal(false)}
            data={transactionDetail}
         />
      </>
   );
}

export default PendingTransactions;

PendingTransactions.propTypes = {};
