import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Skeleton } from '@material-ui/lab';
import moment from 'moment';
import { Input } from 'components/input';
import { TableComponent } from 'components/table';
import { FilterContainer } from 'pages/follow-up/follow-up.style';
import { getBizActivatedZeroTransaction } from 'services/business';
import { TableContainer, TableContent } from 'styles/table';
import { useDebouncedCallback } from 'use-debounce';
import FilterListIcon from '@material-ui/icons/FilterList';
import { useBizDetailsModal } from 'hooks/bizDetailsModal';
import BusinessDetails from 'containers/business-details';
import { BusinessFilterModal } from 'containers/business-account-filter-modal/';
import { hasValue } from 'utils/validations';
import { formatPayloadFromFilter } from 'utils/formatter';
import { getColumns, bizActivatedFilter } from '../../utils';

const ActivatedZeroTransactions = () => {
   const { setActiveTab } = useBizDetailsModal();
   const { search } = useLocation();
   const page = new URLSearchParams(search).get('page');
   const [searchTerm, setSearchTerm] = useState('');
   const [tableData, setTableData] = useState([]);
   const [isLoadingApprovedData, setIsLoadingAprrovedData] = useState(false);
   const [paginationData, setPaginationData] = useState({});
   const [currentPage, setCurrentPage] = useState(1);
   const [modalVisibilityFilter, setModalVisibilityFilter] = useState(false);
   const [modalVisibility, setModalVisibility] = useState(false);
   const [bizAccount, setBizAccount] = useState('');
   const [noCloseIcon, setNoCloseIcon] = useState(false);
   const [filterData, setfilterData] = useState({
      start_date: '2019-01-01',
      end_date: moment().format('YYYY-MM-DD'),
   });

   const [anchorEl, setAnchorEl] = useState(null);
   const open = Boolean(anchorEl);

   const options = ['View Details', 'Call', 'Send Message'];

   function handleSearch({ target }) {
      setSearchTerm(target.value);
   }
   const debounce = useDebouncedCallback(handleSearch, 600);

   const fetchBizActivatedZeroTransaction = async () => {
      try {
         setIsLoadingAprrovedData(true);
         setTableData([]);
         setPaginationData({});
         const isSearch = hasValue(searchTerm);
         const payload = formatPayloadFromFilter(bizActivatedFilter, filterData);
         const { start_date, end_date, ...remainingPayload } = payload;

         const result = await getBizActivatedZeroTransaction(
            {
               page: isSearch ? 1 : page,
            },
            {
               start_date,
               end_date,
               ...(isSearch ? { biz_friendly_name: searchTerm } : remainingPayload),
            },
         );

         setTableData(result.data);
         setPaginationData(result);
      } catch (err) {
         return err;
      } finally {
         setIsLoadingAprrovedData(false);
      }
   };

   useEffect(() => {
      fetchBizActivatedZeroTransaction();
   }, [searchTerm, page, filterData]);

   const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
   };

   const handleClose = () => {
      setAnchorEl(null);
   };

   function handleOpenModal() {
      setActiveTab('overview');
      setModalVisibility(true);
      setNoCloseIcon(false);
   }

   function sendMessageModalSection() {
      setActiveTab('message');
      setModalVisibility(true);
   }

   function callNumber(phone) {
      const link = document.createElement('a');
      const linkText = document.createTextNode('call us');
      link.appendChild(linkText);
      link.href = `tel:${phone}`;
      link.click();
   }
   function onPageChange(e, pageNumber) {
      setCurrentPage(pageNumber);
   }

   const columns = getColumns({
      handleOpenModal,
      handleClick,
      anchorEl,
      handleClose,
      open,
      options,
      callNumber,
      sendMessageModalSection,
   });

   function onRowClick({ row }) {
      setBizAccount(row.biz_account_id);
   }

   return (
      <>
         <TableContainer id="table">
            <TableContent>
               {isLoadingApprovedData ? (
                  <Skeleton width="30%" height="20" />
               ) : (
                  <div>
                     <h1> Business Accounts</h1>
                     <span className="value">{paginationData?.count || 'N/A'}</span>
                  </div>
               )}
               <div className="d-flex justify-content-between">
                  <div className="me-3">
                     <Input style={{ width: '300px' }} placeholder="Search" onChange={debounce} />
                  </div>

                  <FilterContainer onClick={() => setModalVisibilityFilter(true)}>
                     Filter By <FilterListIcon style={{ marginLeft: '10px' }} />
                  </FilterContainer>
               </div>
            </TableContent>
            <TableComponent
               columns={columns}
               rows={tableData}
               isLoading={isLoadingApprovedData}
               onPageChange={onPageChange}
               count={Math.round(paginationData.count / 15)}
               page={currentPage}
               pageSize={15}
               onRowClick={onRowClick}
            />
         </TableContainer>

         {modalVisibility && (
            <BusinessDetails
               businessAccountId={bizAccount}
               open={modalVisibility}
               onClose={() => setModalVisibility(false)}
               noCloseIcon={noCloseIcon}
            />
         )}

         <BusinessFilterModal
            open={modalVisibilityFilter}
            onClose={() => setModalVisibilityFilter(false)}
            filterData={filterData}
            filters={bizActivatedFilter}
            handleSubmit={setfilterData}
         />
      </>
   );
};

export default ActivatedZeroTransactions;
