/* eslint-disable no-restricted-globals */
/* eslint-disable no-else-return */
import { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import { getPendingBusiness, getLatestPendingBusiness } from 'services/business';
import LoggedInDashboard from 'components/logged-in-dashboard';
import ApproverStats from 'containers/approver-stats';
import moment from 'moment';
import PendingDocument from 'components/pending-document';
import Notes from 'containers/business-details/notes';
import { HeadingOne } from 'styles/typography';
import { useHistory, useLocation } from 'react-router-dom';
import { Button } from 'components/button';
import { Spinner } from 'components/spinner';
import { TabWrapper } from './style';

export default function Index() {
   const getDates = (value) => {
      if (value === 'last-24') {
         return {
            start_date: moment().subtract(1, 'days').format('YYYY-MM-DD'),
            end_date: moment().format('YYYY-MM-DD'),
         };
      } else if (value === 'last-7') {
         return {
            start_date: moment().subtract(9, 'days').format('YYYY-MM-DD'),
            end_date: moment().subtract(2, 'days').format('YYYY-MM-DD'),
         };
      }
      return {
         start_date: '2019-01-01',
         end_date: moment().format('YYYY-MM-DD'),
      };
   };

   const { search } = useLocation();
   const activeTabInUrl = new URLSearchParams(search).get('active');
   const [activeTab, setActiveTab] = useState('overview');
   const [business, setBusiness] = useState(null);
   const [filter, setFilter] = useState(activeTabInUrl || 'all');
   const [startDate, setStartDate] = useState(
      activeTabInUrl ? getDates(activeTabInUrl).start_date : '2019-01-01',
   );
   const [loading, setLoading] = useState(false);
   const [endDate, setEndDate] = useState(
      activeTabInUrl ? getDates(activeTabInUrl).end_date : moment().format('YYYY-MM-DD'),
   );
   const history = useHistory();

   const initializeBusiness = async () => {
      try {
         let response = '';
         if (filter === 'all') {
            const { data } = await getPendingBusiness();
            response = data;
            setBusiness(data);
         } else {
            const { data } = await getLatestPendingBusiness({
               ...(filter !== 'last-30' && { start_date: startDate }),
               ...(filter !== 'last-30' && { end_date: endDate }),
               ...(filter === 'last-30' && { pending_time: 30 }),
            });
            response = data;
            setBusiness(data);
         }
         return response;
      } catch (e) {
         toast.error(e.message);
      }
   };

   const renderActiveTab = () => {
      switch (activeTab) {
         case 'overview':
            return <PendingDocument business={business} initializeBusiness={initializeBusiness} />;

         case 'stats':
            return (
               <div>
                  <ApproverStats />
               </div>
            );

         case 'notes':
            return <Notes businessAccountId={business?.biz_account_id} />;

         default:
            return <PendingDocument />;
      }
   };

   useEffect(() => {
      (async function intialize() {
         setLoading(true);
         try {
            window.scroll(0, 0);
            if (activeTab === 'overview') {
               initializeBusiness();
            }
         } catch (e) {
            toast.error(e.message);
         }
         setLoading(false);
      })();
   }, [filter]);

   return (
      <LoggedInDashboard pageTitle="">
         <div className="d-flex justify-content-between mb-5 px-4 pb-md-5 align-items-center">
            <HeadingOne> Pending Documents</HeadingOne>
            <div>
               <Button
                  variant={filter === 'all' ? 'primary' : 'secondary'}
                  className="me-2"
                  onClick={() => {
                     setStartDate('2019-01-01');
                     setEndDate(moment().format('YYYY-MM-DD'));
                     setFilter('all');
                     history.push(`${location.pathname}?active=all`);
                  }}
               >
                  All
               </Button>

               <Button
                  variant={filter === 'last-30' ? 'primary' : 'secondary'}
                  onClick={() => {
                     setFilter('last-30');
                     history.push(`${location.pathname}?active=last-30`);
                  }}
                  className="me-2"
               >
                  Last 30 mins
               </Button>

               <Button
                  variant={filter === 'last-24' ? 'primary' : 'secondary'}
                  onClick={() => {
                     setStartDate(moment().subtract(1, 'days').format('YYYY-MM-DD'));
                     setEndDate(moment().format('YYYY-MM-DD'));
                     setFilter('last-24');
                     history.push(`${location.pathname}?active=last-24`);
                  }}
                  className="me-2"
               >
                  Last 24 hours
               </Button>

               <Button
                  variant={filter === 'last-7' ? 'primary' : 'secondary'}
                  onClick={() => {
                     setFilter('last-7');
                     setStartDate(moment().subtract(9, 'days').format('YYYY-MM-DD'));
                     setEndDate(moment().subtract(2, 'days').format('YYYY-MM-DD'));
                     history.push(`${location.pathname}?active=last-7`);
                  }}
               >
                  Last 7 days
               </Button>
            </div>
         </div>

         <TabWrapper>
            <ul className="m-0 d-flex align-items-center">
               <li
                  className={`${
                     activeTab === 'overview' ? 'active' : ''
                  } pending-doc-overview pending-tab-action`}
                  onClick={() => setActiveTab('overview')}
               >
                  Overview
               </li>
               <li
                  className={`${
                     activeTab === 'notes' ? 'active' : ''
                  } pending-doc-overview pending-tab-action`}
                  onClick={() => setActiveTab('notes')}
               >
                  Notes
               </li>

               <li
                  className={`${
                     activeTab === 'stats' ? 'active' : ''
                  } pending-doc-overview pending-tab-action`}
                  onClick={() => setActiveTab('stats')}
               >
                  Stats
               </li>
            </ul>
         </TabWrapper>
         {!loading ? renderActiveTab() : <Spinner />}
      </LoggedInDashboard>
   );
}
