import { toast } from 'react-hot-toast';
import { func, bool, string } from 'prop-types';
import { confirm } from 'components/confirm-box/confirm-box';
import { editCardDetails } from 'services/cards';
import Modal from 'components/modal';
import { Input } from 'components/input';
import { useState } from 'react';
import { Button } from 'components/button';
import { InputWrapper } from 'styles/form';

const ReasonModal = ({ open, onClose, noCloseIcon, cardId, fetchCards }) => {
   const [isTerminatingCard, setIsTerminatingCard] = useState(false);
   const [reasonInput, setReasonInput] = useState('');

   const handleTerminateCard = async () => {
      confirm({
         confirmText: 'Are you sure want to terminate this card?',
         isLoading: isTerminatingCard,
         onConfirm: async () => {
            setIsTerminatingCard(true);
            const payload = { status: 'blocked', is_active: true, terminate_reason: reasonInput };
            try {
               const result = await editCardDetails(payload, cardId);
               if (result.ok) {
                  toast.success(result?.message || 'Card successfully Freezed', { duration: 3000 });
                  fetchCards();
               }
               setIsTerminatingCard(false);
            } catch (err) {
               toast.error(err.message || err.custom_message);
               setIsTerminatingCard(false);
            }
         },
      });
      onClose();
   };

   return (
      <Modal
         open={open}
         onClose={onClose}
         closeOnOverlayClick={false}
         noCloseIcon={noCloseIcon}
         title="Reason"
         center
      >
         <div className="d-flex justify-content-between">
            <Input
               label="Reason"
               placeholder="Enter the Reason"
               name="reasonInput"
               value={reasonInput}
               className={Input}
               inputmode="text"
               onChange={(e) => setReasonInput(e.target.value)}
            />
         </div>
         <InputWrapper
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
         >
            <div style={{ marginRight: '20px' }}>
               <Button variant="primary" onClick={handleTerminateCard}>
                  Terminate
               </Button>
            </div>
         </InputWrapper>
      </Modal>
   );
};
ReasonModal.propTypes = {
   open: bool.isRequired,
   onClose: func.isRequired,
   noCloseIcon: bool,
   cardId: string.isRequired,
   fetchCards: func.isRequired,
};
ReasonModal.defaultProps = {
   noCloseIcon: false,
};

export default ReasonModal;
