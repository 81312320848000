// import { useEffect, useState } from 'react';
import { useAuth } from 'hooks/auth';
import { string, bool } from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
// import { Spinner } from 'components/spinner';

export const ProtectedRoute = ({ path, notProtected, ...rest }) => {
   const { isAuthenticated } = useAuth();
   // const [isCheckingAuth, setIsCheckingAuth] = useState(true);

   // useEffect(() => {
   //    setIsCheckingAuth(false);
   // }, [isAuthenticated]);

   // if (isCheckingAuth) {
   //    return <Spinner />;
   // }

   if (notProtected && isAuthenticated) {
      return <Redirect to="/" />;
   }

   if (!isAuthenticated && !notProtected) {
      return (
         <Redirect
            to={{
               pathname: '/login',
               state: {
                  prevLocation: path,
                  error: 'You need to login first!',
               },
            }}
            exact
         />
      );
   }

   return <Route {...rest} />;
};

ProtectedRoute.propTypes = {
   notProtected: bool,
   path: string.isRequired,
};

ProtectedRoute.defaultProps = {
   notProtected: false,
};
