import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Grid, Switch } from '@material-ui/core';
import { toggleInternationalPayment } from 'services/international-transactions';
import { confirm } from 'components/confirm-box';

export default function InternationalPaymentToggle({ bizDetails }) {
   const [toggle, setToggle] = useState(null);

   useEffect(() => {
      if (bizDetails?.international_transfer) setToggle(bizDetails?.international_transfer);
   }, []);

   const handleInternationalPaymentToggle = async (type) => {
      if (type === 'on') {
         setToggle(true);
      }

      if (type === 'off') {
         setToggle(false);
      }

      const payload =
         type === 'on'
            ? {
                 hbizaccount_id: bizDetails?.biz_account_id,
                 is_enabled: true,
              }
            : {
                 hbizaccount_id: bizDetails?.biz_account_id,
                 is_enabled: false,
              };
      try {
         const res = await toggleInternationalPayment(payload);
         toast.success(res?.message || 'Successful');
      } catch (e) {
         toast.error(e?.message);
      }
   };

   const confirmToggleChange = (type) => {
      confirm({
         confirmText: 'Are you sure want to proceed?',
         onConfirm: () => handleInternationalPaymentToggle(type),
      });
   };

   return (
      <div className="row pt-5 border-top">
         <div className="mb-5">
            <b>International Transfers</b>
            <Grid component="label" container alignItems="center" marginLeft="" spacing={1}>
               <Grid item>
                  <b>Off</b>
               </Grid>
               <Grid item>
                  <Switch
                     checked={toggle}
                     onClick={
                        toggle ? () => confirmToggleChange('off') : () => confirmToggleChange('on')
                     }
                  />
               </Grid>
               <Grid item>
                  <b>On</b>
               </Grid>
            </Grid>
         </div>
      </div>
   );
}
InternationalPaymentToggle.propTypes = {
   bizDetails: PropTypes.objectOf().isRequired,
};
