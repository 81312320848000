import React from 'react';
import PropTypes from 'prop-types';

export default function TabMenu({ text, active, setActiveTab }) {
   return (
      <li
         className={`${active ? 'active' : ''} pending-doc-overview pending-tab-action`}
         onClick={setActiveTab}
      >
         {text}
      </li>
   );
}

TabMenu.propTypes = {
   text: PropTypes.string.isRequired,
   active: PropTypes.bool.isRequired,
   setActiveTab: PropTypes.func.isRequired,
};
