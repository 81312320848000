import { useEffect, useState, useCallback } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessage } from '@hookform/error-message';
import { func, objectOf, bool } from 'prop-types';
import Modal from 'components/modal';
import { NumberInput } from 'components/input';
import { toast } from 'react-hot-toast';
import { confirm } from 'components/confirm-box';
import { Button } from 'components/button';
import { createLoanTenure, updateLoanTenure } from 'services/loans';

function AddLoanTenure({ isEdit = false, onClose, open, tenure }) {
   const [loading, setLoading] = useState(false);
   const [changed, setChanged] = useState(true);

   const defaultValues = {
      min_tenure: 0,
      max_tenure: 0,
      min_loan_amount: 0,
      max_loan_amount: 0,
   };

   const schema = yup.object().shape({
      min_tenure: yup.number().positive().required().min(1, 'Minimum value must be at least 1'),
      max_tenure: yup
         .number()
         .positive()
         .required()
         .when('min_tenure', (min_tenure) => {
            if (min_tenure) {
               return yup
                  .number()
                  .required()
                  .min(parseInt(min_tenure, 10) + 1, 'Max tenure must be greater than min tenure');
            }
         }),
      min_loan_amount: yup
         .number()
         .positive()
         .required()
         .min(1, 'Minimum value must be at least 1'),
      max_loan_amount: yup
         .number()
         .positive()
         .required()
         .when('min_loan_amount', (min_loan_amount) => {
            if (min_loan_amount) {
               return yup
                  .number()
                  .required()
                  .min(
                     parseInt(min_loan_amount, 10) + 1,
                     'Max amount must be greater than min amount',
                  );
            }
         }),
   });

   const {
      register,
      handleSubmit,
      setValue,
      trigger,
      watch,
      formState: { isValid, errors },
   } = useForm({
      defaultValues,
      mode: 'onChange',
      resolver: yupResolver(schema),
      shouldFocusError: true,
   });

   useEffect(() => {
      register('min_tenure');
      register('max_tenure');
      register('min_loan_amount');
      register('max_loan_amount');
   }, []);

   const min_tenure = watch('min_tenure');
   const max_tenure = watch('max_tenure');
   const min_loan_amount = watch('min_loan_amount');
   const max_loan_amount = watch('max_loan_amount');

   useEffect(() => {
      if (isEdit) {
         setValue('min_tenure', tenure?.min_tenure);
         setValue('max_tenure', tenure?.max_tenure);
         setValue('min_loan_amount', tenure?.min_loan_amount);
         setValue('max_loan_amount', tenure?.max_loan_amount);
      }
   }, [isEdit]);

   const sharedOnChange = useCallback(async ({ name, value }, cb) => {
      setValue(name, value);
      await trigger(name);
      if (cb) {
         cb();
      }
   }, []);

   const getChanged = () => ({
      ...(min_tenure !== tenure.min_tenure && { min_tenure }),
      ...(max_tenure !== tenure.max_tenure && { max_tenure }),
      ...(min_loan_amount !== tenure.min_loan_amount && { min_loan_amount }),
      ...(max_loan_amount !== tenure.max_loan_amount && { max_loan_amount }),
   });

   useEffect(() => {
      if (isEdit) {
         const changedCheck = getChanged();
         setChanged(Object.keys(changedCheck).length);
      }
   }, [min_tenure, max_tenure, min_loan_amount, max_loan_amount]);

   const onSubmit = (data) => {
      confirm({
         confirmText: 'Are you sure you want to continue?',
         onConfirm: async () => {
            setLoading(true);
            try {
               if (!isEdit) {
                  await createLoanTenure(data);
                  toast.success('New tenure was created successfully');
               } else {
                  const payload = getChanged();
                  await updateLoanTenure({ ...payload, id: tenure.id });
                  toast.success('Tenure was successful');
               }
               onClose();
            } catch (e) {
               toast.error(e.message);
            }
            setLoading(false);
         },
      });
   };

   const renderErrorMessage = (name) => (
      <ErrorMessage
         errors={errors}
         name={name}
         render={({ message }) => <p className="input-error-message text-danger">{message}</p>}
      />
   );

   return (
      <Modal open={open} onClose={onClose} title={isEdit ? 'Edit Tenure' : 'Add New Tenure'}>
         <form onSubmit={handleSubmit(onSubmit)} className="row">
            <div className="col-md-6 mb-4">
               <NumberInput
                  label="Minimum Tenure (days)"
                  name="min_tenure"
                  value={min_tenure}
                  placeholder="e.g 30"
                  prefix="hidden"
                  onChange={({ floatValue }) =>
                     sharedOnChange({ name: 'min_tenure', value: floatValue || 0 }, async () => {
                        await trigger('max_tenure');
                     })
                  }
               />
               {renderErrorMessage('min_tenure')}
            </div>
            <div className="col-md-6 mb-4">
               <NumberInput
                  label="Maximum Tenure (days)"
                  name="max_tenure"
                  value={max_tenure}
                  placeholder="e.g 60"
                  prefix="hidden"
                  onChange={({ floatValue }) =>
                     sharedOnChange({ name: 'max_tenure', value: floatValue || 0 })
                  }
               />
               {renderErrorMessage('max_tenure')}
            </div>

            <div className="col-md-6 mb-4">
               <NumberInput
                  label="Minimum Loan Amount"
                  name="min_loan_amount"
                  value={min_loan_amount}
                  placeholder="Enter Minimum Loan Amount"
                  onChange={({ floatValue }) =>
                     sharedOnChange(
                        { name: 'min_loan_amount', value: floatValue || 0 },
                        async () => {
                           await trigger('max_loan_amount');
                        },
                     )
                  }
               />
               {renderErrorMessage('min_loan_amount')}
            </div>
            <div className="col-md-6 mb-4">
               <NumberInput
                  label="Maximum Loan Amount"
                  name="max_loan_amount"
                  value={max_loan_amount}
                  placeholder="Enter Maximum Loan Amount"
                  onChange={({ floatValue }) =>
                     sharedOnChange({ name: 'max_loan_amount', value: floatValue || 0 })
                  }
               />
               {renderErrorMessage('max_loan_amount')}
            </div>

            <div className="d-flex justify-content-end pt-5">
               <Button type="submit" isLoading={loading} disabled={!isValid || !changed}>
                  {isEdit ? 'Update Tenure' : 'Add New Tenure'}
               </Button>
            </div>
         </form>
      </Modal>
   );
}

AddLoanTenure.propTypes = {
   isEdit: bool.isRequired,
   onClose: func.isRequired,
   open: func.isRequired,
   tenure: objectOf().isRequired,
};

export default AddLoanTenure;
