/* eslint-disable react/forbid-prop-types */
// import { useEffect, useState } from 'react';
import { numberWithCommas } from 'utils/others';
import moment from 'moment';
import { Status } from 'styles/utils';
import { InfoGroup, Label, OverviewSection, SectionTitle } from 'styles/layout';
import { Spinner } from 'components/spinner';
import { getPCRLabel } from 'utils/loans';
import { object } from 'prop-types';
// import './style';

export default function LoanOverview({ loanDetails }) {
   // const [loan, setLoan] = useState("")
   // useEffect(() =>{
   //    (async function(){
   //       try{
   //          const result = await fetchLoanRepaymentHistory(loan_id)
   //          console.log(result.data[0].loan_details)
   //          setLoan(result.data[0].loan_details)
   //          console.log(businessAccountId)
   //       }
   //       catch({message}){
   //          toast.error(message)
   //       }
   //    })()
   // },[])
   // return <h1>J</h1>

   return loanDetails?.biz_name ? (
      <div>
         <SectionTitle>Loan Request</SectionTitle>
         <OverviewSection>
            <InfoGroup>
               <Label>Business Name</Label>
               {loanDetails?.biz_name}
            </InfoGroup>

            <InfoGroup>
               <Label>Loan Purpose</Label>
               {loanDetails?.loan_purpose || 'N/A'}
            </InfoGroup>

            <InfoGroup>
               <Label>Requested Amount</Label>
               {loanDetails && `₦ ${numberWithCommas(loanDetails?.loan_amount)}`}
            </InfoGroup>

            <InfoGroup>
               <Label>Loan PCR Score</Label>
               {loanDetails?.loan_crs_score ? (
                  <>
                     {getPCRLabel(loanDetails?.loan_crs_score)}
                     <span style={{ fontSize: '12px' }}> - {loanDetails?.loan_crs_score}</span>
                  </>
               ) : (
                  <>
                     {getPCRLabel('N/A')} <span style={{ fontSize: '12px' }}> - N/A</span>
                  </>
               )}
            </InfoGroup>

            <InfoGroup>
               <Label>Loan PCR Max Amount</Label>
               {(loanDetails && `₦ ${numberWithCommas(loanDetails?.loan_crs_max_amount)}`) || 'N/A'}
            </InfoGroup>

            <InfoGroup>
               <Label>Loan Interest Rate</Label>
               {loanDetails?.loan_interest_rate}%
            </InfoGroup>

            <InfoGroup>
               <Label>Loan Interest</Label>
               {loanDetails && `₦ ${numberWithCommas(loanDetails?.loan_interest)}`}
            </InfoGroup>

            <InfoGroup>
               <Label>Loan Admin Fee</Label>
               {(loanDetails && `₦ ${numberWithCommas(loanDetails?.loan_admin_fee)}`) || 'N/A'}
            </InfoGroup>

            <InfoGroup>
               <Label>Loan Status</Label>
               <Status status={loanDetails?.loan_status}>
                  {loanDetails?.loan_status || 'N/A'}
               </Status>
            </InfoGroup>

            <InfoGroup>
               <Label>Requested Date</Label>
               {moment(loanDetails?.pub_date).format('DD MMM, YYYY')} |{' '}
               <span style={{ opacity: '.5' }}>
                  {moment(loanDetails?.pub_date).format('HH:mm A')}
               </span>
            </InfoGroup>
         </OverviewSection>

         <SectionTitle>Loan Repayment</SectionTitle>
         <OverviewSection>
            <InfoGroup>
               <Label>Loan Repayment Amount</Label>
               {loanDetails && `₦ ${numberWithCommas(loanDetails?.loan_repayment_amount)}`}
            </InfoGroup>

            <InfoGroup>
               <Label>Loan Repayment Balance</Label>
               {loanDetails && `₦ ${numberWithCommas(loanDetails?.loan_repayment_balance)}`}
            </InfoGroup>

            <InfoGroup>
               <Label>Loan Repayment Status</Label>
               <Status status={loanDetails?.loan_repayment_status}>
                  {loanDetails?.loan_repayment_status || 'N/A'}
               </Status>
            </InfoGroup>

            <InfoGroup>
               <Label>Auto-repayment</Label>
               <Status status={loanDetails?.loan_rule_status}>
                  {loanDetails?.loan_rule_status || 'N/A'}
               </Status>
            </InfoGroup>

            <InfoGroup>
               <Label>Auto-repayment % on inflow</Label>
               {loanDetails?.loan_rule_allocation || 'N/A'} %
            </InfoGroup>

            <InfoGroup>
               <Label>Loan Tenure</Label>
               {loanDetails?.loan_tenure} days
            </InfoGroup>
         </OverviewSection>

         <SectionTitle>Loan Disbursement Status</SectionTitle>
         <OverviewSection>
            <InfoGroup>
               <Label>Loan Disbursement Status</Label>
               <Status status={loanDetails?.loan_disbursement_status}>
                  {loanDetails?.loan_disbursement_status || 'N/A'}
               </Status>
            </InfoGroup>

            <InfoGroup>
               <Label>Loan Officer Details</Label>
               {loanDetails?.loan_officer_details
                  ? `${loanDetails?.loan_officer_details?.first_name} ${loanDetails?.loan_officer_details?.last_name}`
                  : 'N/A'}
            </InfoGroup>

            <InfoGroup>
               <Label>Loan Officer Time</Label>
               {loanDetails?.loan_officer_time ? (
                  <span>
                     {moment(loanDetails?.loan_officer_time).format('DD MMM, YYYY')} |
                     <span style={{ opacity: '.5' }}>
                        {moment(loanDetails?.loan_officer_time).format('HH:mm A')}
                     </span>
                  </span>
               ) : (
                  'N/A'
               )}
            </InfoGroup>

            <InfoGroup>
               <Label>Loan Approved By</Label>
               {loanDetails?.loan_approved_by_details
                  ? `${loanDetails?.loan_approved_by_details?.first_name} ${loanDetails?.loan_approved_by_details?.last_name}`
                  : 'N/A'}
            </InfoGroup>

            <InfoGroup>
               <Label>Loan Disbursed Time</Label>
               {loanDetails?.loan_approved_time
                  ? moment(loanDetails?.loan_approved_time).format('DD MMM, YYYY')
                  : 'N/A'}
            </InfoGroup>

            <InfoGroup>
               <Label>Loan Repayment Start Date</Label>
               {loanDetails?.loan_repay_start_date
                  ? moment(loanDetails?.loan_repay_start_date).format('DD MMM, YYYY')
                  : 'N/A'}
            </InfoGroup>

            <InfoGroup>
               <Label>Loan Repayment End Date</Label>
               {loanDetails?.loan_repay_end_date
                  ? moment(loanDetails?.loan_repay_end_date).format('DD MMM, YYYY')
                  : 'N/A'}
            </InfoGroup>

            <InfoGroup>
               <Label>Loan Type</Label>
               {loanDetails?.loan_type || 'N/A'}
            </InfoGroup>

            {/* <InfoGroup>
               <Label>Loan Ref</Label>
               {loanDetails?.loan_ref}
            </InfoGroup> */}
         </OverviewSection>
      </div>
   ) : (
      <Spinner />
   );
}

LoanOverview.propTypes = {
   loanDetails: object.isRequired,
};
