import PropTypes from 'prop-types';
import { Grid, Switch } from '@material-ui/core';
import { useEffect, useState } from 'react';

export const TransferCharges = ({ settings, onChange }) => {
   const [toggle, setToggle] = useState(null);

   useEffect(() => {
      setToggle(settings?.value?.transfer_charge);
   }, [settings]);

   const handleSettingChange = (type) => {
      const payload =
         type === 'on'
            ? {
                 id: settings?.id,
                 name: 'BANKING__EXTERNAL_TRANSFER_CHARGES',
                 category: settings?.category,
                 value: { transfer_charge: true },
              }
            : {
                 id: settings?.id,
                 name: 'BANKING__EXTERNAL_TRANSFER_CHARGES',
                 category: settings?.BANKING__EXTERNAL_TRANSFER_CHARGES?.category,
                 value: { transfer_charge: false },
              };
      onChange(payload);
   };

   return (
      <div className="row border-top pt-5 pb-4">
         <b>Transfer Charges</b>
         <div className="pb-4">
            <Grid component="label" container alignItems="center" marginLeft="" spacing={1}>
               <Grid item>
                  <b>Off</b>
               </Grid>
               <Grid item>
                  <Switch
                     checked={toggle || false}
                     onClick={() =>
                        handleSettingChange(settings?.value?.transfer_charge ? 'off' : 'on')
                     }
                  />
               </Grid>
               <Grid item>
                  <b>On</b>
               </Grid>
            </Grid>
         </div>
      </div>
   );
};
TransferCharges.propTypes = {
   settings: PropTypes.objectOf().isRequired,
   onChange: PropTypes.func.isRequired,
};
