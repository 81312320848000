/* eslint-disable react/prop-types */
import { useState, useEffect } from 'react';
import moment from 'moment';
import toast from 'react-hot-toast';
import { bool, func, objectOf } from 'prop-types';
import { TableContainer } from 'styles/table';
import { TableComponent } from 'components/table';
import { Status } from 'styles/utils';
import { TooltipComponent } from 'components/tooltip';
import { SectionTitle, InfoGroup, Label, Value, OverviewSection } from 'styles/layout';
import { promoCodeUsage } from 'services/promo-code';
import Modal from 'components/modal';
import { Spinner } from 'components/spinner';
import BusinessDetails from 'containers/business-details';

const PromoDetails = ({ open, onClose, promo }) => {
   const [codeUsage, setCodeUsage] = useState({});
   const [start_date] = useState('2020-01-01');
   const [end_date] = useState(moment().format('YYYY-MM-DD'));
   const [loading, setLoading] = useState(false);
   const [modalVisibility, setModalVisibility] = useState(false);
   const [currentPage, setCurrentPage] = useState(1);
   const [bizAccount, setBizAccount] = useState('');

   const getPromoCodeUsage = async () => {
      setLoading(true);
      try {
         const response = await promoCodeUsage({
            start_date,
            end_date,
            promo_code: promo?.prospa_code,
            page: currentPage,
         });
         setCodeUsage(response);
      } catch (e) {
         toast.error(e?.message);
      }
      setLoading(false);
   };

   function onRowClick({ row }) {
      setBizAccount(row);
   }

   function handleOpenModal() {
      // setBizAccount(id);
      setModalVisibility('details');
   }

   useEffect(() => {
      getPromoCodeUsage();
   }, [currentPage]);

   function onPageChange(e, pageNumber) {
      setCurrentPage(pageNumber);
   }

   const columns = [
      {
         field: 'biz_name',
         headerName: 'Business Name',
         renderCell: ({ row }) => (
            <TooltipComponent title={row.biz_name}>
               <span
                  style={{
                     cursor: 'pointer',
                  }}
                  className="text-primary"
                  onClick={() => handleOpenModal(row.biz_account_id)}
               >
                  {row.biz_name || 'N/A'}
               </span>
            </TooltipComponent>
         ),
      },
      {
         field: 'biz_account_id',
         width: 350,
         headerName: 'Business ID',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.biz_account_id}>{row?.biz_account_id}</TooltipComponent>
         ),
      },
      {
         field: 'pub_date',
         headerName: 'Date Used',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.pub_date}>
               {moment(row?.pub_date).format('DD MMM, YYYY')} |{' '}
               <span style={{ opacity: '.5' }}>{moment(row?.pub_date).format('HH:mm A')}</span>
            </TooltipComponent>
         ),
      },
   ];

   return (
      <Modal
         open={open}
         onClose={() => {
            onClose();
         }}
         closeOnOverlayClick={false}
         title="Promo Code Details"
         className="biz-details-modal"
      >
         {loading ? (
            <Spinner />
         ) : (
            <div>
               <SectionTitle>Code Information</SectionTitle>
               <OverviewSection>
                  <InfoGroup>
                     <Label>Promo Code</Label>
                     <Value>{promo?.prospa_code}</Value>
                  </InfoGroup>

                  {promo?.promo_id && (
                     <InfoGroup>
                        <Label>Promo ID</Label>
                        <Value>{promo?.promo_id}</Value>
                     </InfoGroup>
                  )}

                  <InfoGroup>
                     <Label>Total Code Usage</Label>
                     <Value>{codeUsage?.count}</Value>
                  </InfoGroup>

                  {promo?.promo_status && (
                     <InfoGroup>
                        <Label>Promo Code Status</Label>
                        <Value>
                           <Status status={promo?.promo_status}>{promo?.promo_status}</Status>
                        </Value>
                     </InfoGroup>
                  )}

                  <InfoGroup>
                     <Label>Date Created</Label>
                     <Value>{moment(promo?.pub_date).format('DD MMM, YYYY')}</Value>
                  </InfoGroup>
               </OverviewSection>

               {codeUsage?.data?.length > 0 && (
                  <div>
                     <SectionTitle>Promo Code Businesses (Used)</SectionTitle>
                     <TableContainer>
                        <TableComponent
                           preventUrl
                           columns={columns}
                           rows={codeUsage?.data}
                           isLoading={loading}
                           page={currentPage}
                           onPageChange={onPageChange}
                           count={Math.ceil(codeUsage?.count / 15)}
                           onRowClick={onRowClick}
                        />
                     </TableContainer>
                  </div>
               )}
            </div>
         )}
         {modalVisibility && (
            <BusinessDetails
               businessAccountId={bizAccount.biz_account_id}
               open={modalVisibility}
               onClose={() => setModalVisibility(false)}
            />
         )}
      </Modal>
   );
};

PromoDetails.propTypes = {
   open: bool.isRequired,
   promo: objectOf().isRequired,
   onClose: func.isRequired,
};

export default PromoDetails;
