/* eslint-disable react/forbid-prop-types */
import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { formatTag } from 'utils/tags';
import { numberWithCommas } from 'utils/others';
import { Button } from 'components/button';
import WalletIcon from 'assets/images/wallet.png';
import SafeIcon from 'assets/images/safe-box.png';
import { MenuComponent } from 'components/menu';
import { BankAccountWrapper } from './style';

export default function SubAccountCard({
   account,
   toggle,
   setModalVisibility,
   reactivatingAccount,
   reactivateTerminatedAccount,
   filterSingleSafeSettings,
}) {
   const {
      biz_wallet_type,
      partner_bank_name,
      goodnews_full_bank_name,
      biz_wallet_id,
      wallet_final_status,
      biz_account_number,
      goodnews_account_number,
      preferred_name,
      available_balance,
      total_balance,
   } = account || {};

   const isSafe = biz_wallet_type === 'safe';
   const isFixedDeposit = biz_wallet_type === 'fixed_deposits';

   const [anchorEl, setAnchorEl] = useState(null);
   const open = Boolean(anchorEl);
   const safeOptions = ['Withdraw Fund', 'Transactions'];
   const fixedOption = ['Transactions'];

   const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
   };

   const handleClose = () => {
      setAnchorEl(null);
   };

   const getAccountType = useCallback((type) => {
      if (type === 'safe') return 'Flexi Savings';
      if (type === 'current') return 'Current';
      if (type === 'fixed_deposits') return 'Fixed Savings';
      return 'Sub Account';
   }, []);

   function setBackgroundColor() {
      let color;
      if (isSafe) {
         color = '#fa4a842e';
      } else if (isFixedDeposit) {
         color = '#D8BFD8';
      } else {
         color = '#e3f0fd';
      }
      return color;
   }

   function setAccountIcon() {
      let icon;
      if (isSafe) {
         icon = SafeIcon;
      } else if (isFixedDeposit) {
         icon = SafeIcon;
      } else {
         icon = WalletIcon;
      }
      return icon;
   }

   return (
      <BankAccountWrapper
         style={{
            background: setBackgroundColor(),
         }}
      >
         <div className="d-flex justify-content-between">
            <div className="d-flex align-items-center mb-4">
               <img src={setAccountIcon()} alt="" className="wallet-icon" />
               <div className="">
                  <h3 className="ms-4 me-3 mb-0 account-type">
                     {getAccountType(biz_wallet_type)}
                     {!toggle ? (
                        <>
                           {partner_bank_name ? (
                              <span className="preferred_name mb-0 d-flex align-items-center mb-0">
                                 {' '}
                                 {partner_bank_name} (Partner)
                              </span>
                           ) : (
                              <span className="preferred_name mb-0 d-flex align-items-center mb-0 text-danger">
                                 (No Partner Name)
                              </span>
                           )}
                        </>
                     ) : (
                        <>
                           {goodnews_full_bank_name ? (
                              <span className="preferred_name mb-0 d-flex align-items-center mb-0">
                                 {' '}
                                 {goodnews_full_bank_name} (Goodnews)
                              </span>
                           ) : (
                              <span className="preferred_name mb-0 d-flex align-items-center mb-0 text-danger">
                                 (No GoodNews Name)
                              </span>
                           )}
                        </>
                     )}

                     <p
                        className="font-weight-normal"
                        style={{ fontSize: '12px', fontWeight: '400' }}
                     >
                        Wallet ID: {biz_wallet_id}
                     </p>
                  </h3>
               </div>
            </div>
            <div>{formatTag(wallet_final_status, wallet_final_status)}</div>
         </div>
         <div>
            {!toggle ? (
               <>
                  <div className="account_number d-flex mb-2">
                     <h3 className="mb-0">Account Number: </h3> {'    '}
                     {biz_account_number ? (
                        <span className="ms-2"> {biz_account_number} (Partner)</span>
                     ) : (
                        <span className="ms-2 text-danger">N/A</span>
                     )}
                  </div>
               </>
            ) : (
               <div className="account_number d-flex mb-2">
                  <h3 className="mb-0">Account Number: </h3> {'    '}
                  {goodnews_account_number ? (
                     <span className="ms-2"> {goodnews_account_number} (Goodnews)</span>
                  ) : (
                     <span className="ms-2 text-danger">N/A</span>
                  )}
               </div>
            )}

            <div className="account_number d-flex mb-2">
               <h3 className="mb-0">
                  Account Name:
                  {preferred_name ? (
                     <span className="ms-2">{preferred_name?.replace('_', ' ')}</span>
                  ) : (
                     <span className="ms-2 text-danger">{' N/A'}</span>
                  )}
               </h3>
            </div>

            <h3 className="mb-2">
               Avail Bal: <span>₦{numberWithCommas(available_balance)} </span>
            </h3>
            <h3 className="mb-2">
               Total Bal: <span> ₦{numberWithCommas(total_balance)} </span>
            </h3>

            {(isSafe || isFixedDeposit) && (
               <>
                  <div className="text-capitalize mb-3 d-flex align-items-center">
                     <h3 className="me-2">Active Settings: </h3>
                     <span> [{filterSingleSafeSettings(account)}]</span>
                  </div>
                  <div>
                     <Button
                        variant="secondary"
                        onClick={handleClick}
                        className="safe-actions-trigger"
                     >
                        Show Actions
                     </Button>
                     {isSafe ? (
                        <MenuComponent
                           anchorEl={anchorEl}
                           handleClose={handleClose}
                           open={open}
                           options={safeOptions}
                           optionsFns={[
                              // place the callbacks in position corresponding to the options index
                              () =>
                                 setModalVisibility({
                                    type: 'withdraw',
                                    data: account,
                                 }),
                              () =>
                                 setModalVisibility({
                                    type: 'transactions',
                                    data: account,
                                 }),
                           ]}
                        />
                     ) : (
                        <MenuComponent
                           anchorEl={anchorEl}
                           handleClose={handleClose}
                           open={open}
                           options={fixedOption}
                           optionsFns={[
                              // place the callbacks in position corresponding to the options index
                              () =>
                                 setModalVisibility({
                                    type: 'fixed-transactions',
                                    data: account,
                                 }),
                           ]}
                        />
                     )}
                  </div>
               </>
            )}
         </div>

         {account.wallet_final_status === 'terminated' && (
            <Button
               className="mt-3"
               onClick={() => reactivateTerminatedAccount(account.biz_wallet_id)}
               disabled={reactivatingAccount === account.biz_wallet_id}
               isLoading={reactivatingAccount === account.biz_wallet_id}
               style={{
                  visibility: account.wallet_final_status === 'terminated' ? '' : 'hidden',
               }}
            >
               Reactivate Account
            </Button>
         )}
      </BankAccountWrapper>
   );
}

SubAccountCard.propTypes = {
   // eslint-disable-next-line react/no-unused-prop-types
   account: PropTypes.objectOf(PropTypes.any).isRequired,
   toggle: PropTypes.bool.isRequired,
   setModalVisibility: PropTypes.func.isRequired,
   reactivatingAccount: PropTypes.bool.isRequired,
   reactivateTerminatedAccount: PropTypes.func.isRequired,
   filterSingleSafeSettings: PropTypes.func.isRequired,
};
