import { Input, NumberInput } from 'components/input';
import Modal from 'components/modal';
import useBusinessMeta from 'hooks/businessMeta';
import { useHistory } from 'react-router-dom';
import { func, bool, string, number, objectOf } from 'prop-types';
import { InputWrapper } from 'styles/form';
import { Button } from 'components/button';
import moment from 'moment';
import { businessType, turnover, accountStatus, states } from 'utils/business-constants';
import { SelectInput } from 'components/select';
import { getBizCategory, getUnApprovedAccounts } from 'services/business';
import useFetchManagers from 'hooks/fetchManagers';
import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';

const status = [
   {
      value: 'pending',
      label: 'Uploaded',
   },
   {
      value: 'accepted',
      label: 'Accepted',
   },
   {
      value: 'rejected',
      label: 'Rejected',
   },
];

const bvn_options = [
   {
      value: true,
      label: 'True',
   },
   {
      value: false,
      label: 'False',
   },
];

const payment = [
   {
      value: 'has_paid',
      label: 'Paid',
   },
   {
      value: 'not_paid',
      label: 'Unpaid',
   },
];

const decision = [
   {
      value: 'approved',
      label: 'Approved',
   },
   {
      value: 'pending',
      label: 'Pending',
   },
];

const BusinessAccountFilterModal = ({
   open,
   onClose,
   setBusinessInfo,
   currentPage,
   setPaginationData,
   setCurrentPage,
   filterFunction,
   active,
   filterData,
   setfilterData,
   setFilterPayload,
}) => {
   const history = useHistory();
   const [isLoading, setIsLoading] = useState(false);
   const [bizCurrentStatus, setBizCurrentStatus] = useState([]);
   const [bizCategory, setBizCategory] = useState([]);
   const { businessCurrentStatus } = useBusinessMeta();
   const { managers } = useFetchManagers();
   function onChange(name, data) {
      setfilterData({
         ...filterData,
         [name]: data.value,
      });
   }

   const fetchBizCategories = async () => {
      try {
         const res = await getBizCategory();
         setBizCategory(res.data);
      } catch (e) {
         toast.error(e?.message);
      }
   };

   useEffect(() => {
      fetchBizCategories();
   }, []);

   const categoriesOptions = bizCategory?.map(({ category_name, category_slug }) => ({
      label: category_slug,
      value: category_name,
   }));

   async function onSubmit() {
      try {
         // setFilterPayload
         setCurrentPage(() => 1);
         history.push(`${history.location.pathname}?page=1`);
         setIsLoading(true);
         if (setFilterPayload) {
            setFilterPayload(filterData);
         } else {
            const payload = {
               ...filterData,
               ...(!filterData.start_date && { start_date: '2019-01-01' }),
               ...(!filterData.end_date && { end_date: moment().format('YYYY-MM-DD') }),
            };
            const result = filterFunction
               ? await filterFunction({ page: 1 }, payload)
               : await getUnApprovedAccounts({ page: currentPage }, payload);
            setBusinessInfo(result.data);
            setPaginationData(result);
         }
         onClose();
      } catch (err) {
         return err;
      } finally {
         setIsLoading(false);
      }
   }

   async function clearFilter() {
      try {
         setCurrentPage(1);
         if (setFilterPayload) {
            setFilterPayload({});
         } else {
            const result = await getUnApprovedAccounts({ page: currentPage });
            setBusinessInfo(result.data);
            setPaginationData(result);
         }
         setfilterData({});
         onClose();
      } catch (err) {
         return err;
      }
   }

   useEffect(() => {
      if (businessCurrentStatus) {
         setBizCurrentStatus(
            businessCurrentStatus.map(({ current_status_tag }) => ({
               value: current_status_tag,
               label:
                  current_status_tag.charAt(0).toUpperCase() +
                  current_status_tag.slice(1).replaceAll('_', ' '),
            })),
         );
      }
   }, [businessCurrentStatus]);

   return (
      <Modal
         open={open}
         onClose={onClose}
         title="Filters"
         className="biz-details-modal"
         center
         width="750px"
      >
         <form className="row">
            {active.includes('date') && (
               <>
                  <div className="col-md-6 mb-4">
                     <Input
                        placeholder="Start Date"
                        label="Start Date"
                        name="start_date"
                        type="date"
                        value={filterData?.start_date}
                        onChange={({ target }) => onChange('start_date', target)}
                     />
                  </div>
                  <div className="col-md-6 mb-4">
                     <Input
                        placeholder="End Date"
                        label="End Date"
                        name="end_date"
                        type="date"
                        value={filterData?.end_date}
                        onChange={({ target }) => onChange('end_date', target)}
                     />
                  </div>
               </>
            )}
            {active.includes('manager') && (
               <div className="col-md-6 mb-4">
                  <SelectInput
                     onChange={(val) => onChange('assigned_manager_id', val)}
                     name="assigned_manager_id"
                     label="Manager"
                     options={managers}
                     value={managers.filter(
                        (item) => item.value === filterData?.assigned_manager_id,
                     )}
                  />
               </div>
            )}

            {active.includes('biz_type') && (
               <div className="col-md-6 mb-4">
                  <SelectInput
                     name="biz_type"
                     label="Business Type"
                     options={businessType}
                     onChange={(val) => onChange('biz_type', val)}
                     value={businessType.filter((item) => item.value === filterData?.biz_type)}
                  />
               </div>
            )}

            {active.includes('identity') && (
               <div className="col-md-6 mb-4">
                  <SelectInput
                     label="ID status"
                     options={status}
                     onChange={(val) => onChange('identity_file_status', val)}
                     value={status.filter(
                        (item) => item.value === filterData?.identity_file_status,
                     )}
                  />
               </div>
            )}

            {active.includes('bvn') && (
               <div className="col-md-6 mb-4">
                  <SelectInput
                     label="BVN"
                     options={bvn_options}
                     onChange={(val) => onChange('bvn_number', val)}
                     value={bvn_options.filter((item) => item.value === filterData?.bvn_number)}
                  />
               </div>
            )}

            {active.includes('cac') && (
               <div className="col-md-6 mb-4">
                  <SelectInput
                     onChange={(val) => onChange('cac_cert_photo_status', val)}
                     label="CAC Cert Status"
                     options={status}
                     value={status.filter(
                        (item) => item.value === filterData?.cac_cert_photo_status,
                     )}
                  />
               </div>
            )}

            {active.includes('poa') && (
               <div className="col-md-6 mb-4">
                  <SelectInput
                     onChange={(val) => onChange('has_address_verified', val)}
                     name="has_address_verified"
                     label="Proof of Address"
                     options={status}
                     value={status.filter(
                        (item) => item.value === filterData?.has_address_verified,
                     )}
                  />
               </div>
            )}

            {active.includes('payment') && (
               <div className="col-md-6 mb-4">
                  <SelectInput
                     label="Payment Status"
                     onChange={(val) => onChange('payment_status', val)}
                     options={payment}
                     value={payment.filter((item) => item.value === filterData?.payment_status)}
                  />
               </div>
            )}

            {active.includes('decision') && (
               <div className="col-md-6 mb-4">
                  <SelectInput
                     label="Decision Status"
                     onChange={(val) => onChange('decision_status', val)}
                     options={decision}
                     value={decision.filter((item) => item.value === filterData?.decision_status)}
                  />
               </div>
            )}

            {active.includes('turn_over') && (
               <div className="col-md-6 mb-4">
                  <SelectInput
                     label="Turnover"
                     value={turnover.filter((item) => item.value === filterData?.biz_turnover)}
                     onChange={(val) => onChange('biz_turnover', val)}
                     options={turnover}
                  />
               </div>
            )}

            {active.includes('status') && (
               <div className="col-md-6 mb-4">
                  <SelectInput
                     onChange={(val) => onChange('biz_current_status', val)}
                     label="Status"
                     options={bizCurrentStatus}
                     value={bizCurrentStatus.filter(
                        (item) => item.value === filterData?.biz_current_status,
                     )}
                  />
               </div>
            )}

            {active.includes('activity_status') && (
               <div className="col-md-6 mb-4">
                  <SelectInput
                     onChange={(val) => onChange('activity_status', val)}
                     label="Account Status"
                     options={accountStatus}
                     value={accountStatus.filter(
                        (item) => item.value === filterData?.activity_status,
                     )}
                  />
               </div>
            )}

            {active.includes('biz_state') && (
               <div className="col-md-6 mb-4">
                  <SelectInput
                     onChange={(val) => onChange('biz_state', val)}
                     label="State"
                     options={states}
                     value={states.filter((item) => item.value === filterData?.biz_state)}
                  />
               </div>
            )}

            {active.includes('biz_category') && (
               <div className="col-md-6 mb-4">
                  <SelectInput
                     onChange={(val) => onChange('biz_category', val)}
                     label="Category"
                     options={categoriesOptions}
                     value={categoriesOptions.filter(
                        (item) => item.value === filterData?.biz_category,
                     )}
                  />
               </div>
            )}

            {active.includes('deposit_volume') && (
               <div className="col-md-6 mb-4">
                  <NumberInput
                     placeholder="Enter value greater than"
                     label="Deposit Volume(min)"
                     name="deposit_volume_gte"
                     value={filterData?.deposit_volume_gte}
                     prefix="₦"
                     className={Input}
                     inputmode="numeric"
                     onChange={(valuesObject) => {
                        onChange('deposit_volume_gte', { value: valuesObject.floatValue });
                     }}
                  />
               </div>
            )}
            {active.includes('deposit_volume') && (
               <div className="col-md-6 mb-4">
                  <NumberInput
                     placeholder="Enter Value lesser than"
                     label="Deposit Volume(max)"
                     name="deposit_volume_lt"
                     value={filterData?.deposit_volume_lt}
                     prefix="₦"
                     className={Input}
                     inputmode="numeric"
                     onChange={(valuesObject) => {
                        onChange('deposit_volume_lt', { value: valuesObject.floatValue });
                     }}
                  />
               </div>
            )}

            <InputWrapper
               style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
            >
               <div style={{ marginRight: '20px' }}>
                  <Button variant="secondary" type="button" onClick={clearFilter} fullWidth>
                     clear filter
                  </Button>
               </div>

               <div>
                  <Button
                     variant="primary"
                     type="button"
                     onClick={onSubmit}
                     isLoading={isLoading}
                     disabled={isLoading}
                     fullWidth
                  >
                     Apply
                  </Button>
               </div>
            </InputWrapper>
         </form>
      </Modal>
   );
};

export { BusinessAccountFilterModal };

BusinessAccountFilterModal.propTypes = {
   open: bool.isRequired,
   onClose: func.isRequired,
   filterData: objectOf(string).isRequired,
   setfilterData: func.isRequired,
   setBusinessInfo: func.isRequired,
   currentPage: number.isRequired,
   setPaginationData: func.isRequired,
   setCurrentPage: func.isRequired,
   filterFunction: func.isRequired,
   active: bool.isRequired,
   setFilterPayload: func.isRequired,
};
