/* eslint-disable react/prop-types */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-restricted-globals */

import { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { Button } from 'components/button';
import toast from 'react-hot-toast';
import { Input } from 'components/input';
import { confirm } from 'components/confirm-box';
import { Spinner } from 'components/spinner';
import DocumentViewer from 'components/document-previewer';
import DocumentCard from 'components/document-card';
import UserAvatar from 'assets/images/user.svg';
import { useAuth } from 'hooks/auth';
import RejectDocument from 'components/reject-document';
import {
   getPendingBusiness,
   documentAction,
   preapproveBusinessWithLimit,
   updateBusinessDetails,
   feedWorkerTracker,
   documentTracker,
} from 'services/business';
import { AccountDetailsContainer, SuccessTag, ErrorTag, PendingTag, DefaultTag } from './style';

export default function Index({ business, initializeBusiness }) {
   const [documents, setDocuments] = useState(null);
   const [documentStream, setDocumentStream] = useState([]);
   const [documentID, setDocumentID] = useState(0);
   const [activeDoc, setActiveDoc] = useState(null);
   const [address, setAddress] = useState('');
   const [city, setCity] = useState('');
   const [region, setRegion] = useState('');
   const [tin, setTin] = useState('');
   const [rejectModal, setRejectModal] = useState(false);
   const [rejectType, setRejectType] = useState(false);
   const [detailsUpdateLoading, setDetailsUpdateLoading] = useState(false);
   const [documentActionLoading, setdocumentActionLoading] = useState(false);
   const [allActions, setAllActions] = useState([]);
   const [updateTinLoading, setUpdateTinLoading] = useState(false);
   const { currentUser } = useAuth();

   //  console.log(business)

   const handleDocumentTracker = (action_type) =>
      documentTracker({
         biz_account_id: business.biz_account_id,
         action_type,
         stream_type: 'document_reviewed',
      });
   const intializeDocumentStream = async () => {
      try {
         setDocuments(business);
         const stream = [
            {
               name: 'Selfie',
               status: business?.selfie_image_status,
               url: business?.selfie_image_url,
               type: {
                  primary: 'is_selfie_image_verified',
               },
            },
            {
               name: 'Identity File',
               status: business?.account_holder_details?.identity_file_status,
               url: business?.account_holder_details?.identity_file,
               type: {
                  primary: 'is_identity_verified',
               },
            },
            {
               name: 'CAC Certificate',
               status: business?.cac_cert_photo_status,
               url: business?.cac_cert_photo_url,
               type: {
                  primary: 'is_cac_cert_photo_verified',
               },
            },
            {
               name: 'Director Match',
               status: business?.director_match_status,
               data: null,
               type: {
                  primary: 'is_director_match_verified',
               },
            },
            {
               name: 'Proof Of Address',
               status: business?.proof_of_address_status,
               url: business?.proof_of_address_url,
               type: {
                  primary: 'is_address_verified',
               },
            },
            business?.tin_number_status !== 'processing'
               ? {
                    name: 'TIN Number',
                    status: business?.tin_number_status,
                    data: business?.tin_number,
                    type: {
                       primary: 'is_tin_number_verified',
                    },
                 }
               : {},
            business?.tin_number_status === 'processing' && {
               name: 'Verify CAC Documents Submitted for TIN Processing',
               status: business?.tin_number_status === 'processing' ? 'pending' : null,
               url: business?.tin_document_one,
               type: {
                  primary: 'is_tin_document_verified',
                  secondary: 'is_tin_number_verified',
               },
               btn_text: 'TIN Documents',
            },
            {
               name: 'Preapprove',
               data: '',
               type: {
                  primary: 'is_tin_document_verified',
                  secondary: 'is_tin_number_verified',
               },
               btn_text: 'Preapproval',
            },
         ];

         const filteredStream = stream.filter((value) => Object.keys(value).length !== 0);
         let seenDoc = false;
         filteredStream.forEach((item, i) => {
            if ((item.status === 'pending' || item.status === '') && !seenDoc) {
               // console.log(item);
               setActiveDoc(item);
               setDocumentID(i);
               seenDoc = true;
            }
         });
         setDocumentStream(filteredStream);
         setAddress(business?.biz_address);
         setCity(business?.biz_city);
         setRegion(business?.biz_state);
         setTin(business?.tin_number);
         await handleDocumentTracker('start');
         return business;
      } catch (e) {
         toast.error(e.message);
      }
   };

   useEffect(() => {
      if (business) {
         intializeDocumentStream();
      }
   }, [business]);

   const formatTag = (type, text) => {
      switch (type) {
         case type === '':
            return <DefaultTag>{text || 'Not Uploaded'}</DefaultTag>;

         case 'pending':
            return <PendingTag>{text || 'Pending'} </PendingTag>;

         case 'accepted':
            return <SuccessTag>{text || 'Verified'}</SuccessTag>;

         case 'rejected':
            return <ErrorTag>{text || 'Rejected'}</ErrorTag>;

         default:
            return <DefaultTag>{text || 'Not Uploaded'}</DefaultTag>;
      }
   };

   const isPendingRemaining = () => {
      let status = false;
      documentStream?.forEach((item, i) => {
         if (i > documentID) {
            console.log(item.status);
            if (item.status === 'pending') {
               status = true;
            }
         }
      });
      return status;
   };

   const handleDocumentAction = (action, type) => {
      const params = {
         biz_account_id: business.biz_account_id,
         // biz_account_id: '610',
      };

      const finalAction = async () => {
         try {
            let seenDoc = false;
            // Check if atleast one document have been rejected so as to show option to preapprove
            if (allActions.includes('rejected') || action.primary === 'rejected') {
               setActiveDoc(documentStream[documentStream.length - 1]);
               setDocumentID(documentStream.length - 1);
               seenDoc = true;
               window.scroll(0, 0);
            } else {
               setDocuments(null);
               setdocumentActionLoading(false);
               const payload = {
                  biz_account_id: business.biz_account_id,
                  prospa_manager_id: currentUser.prospa_user_id,
                  action_type: 'single',
                  stream_type: 'work_approval',
               };
               await feedWorkerTracker(payload, 'work_approval');
               location.reload();
               // const result = await initializeBusiness();
               // await intializeDocumentStream();
               // return window.scroll(0, 0);
            }
         } catch (e) {
            toast.error(e.message);
         }
      };
      confirm({
         confirmText: 'Are you sure you want to continue ?',
         onConfirm: async () => {
            try {
               params[type.primary] = action.primary;
               if (type.secondary) {
                  params[type.secondary] = action.secondary;
               }
               const { message } = await documentAction(params, type.primary, action.primary);
               toast.success(message);
               await handleDocumentTracker('end_single');
               if (action.primary === 'rejected') {
                  setRejectModal(true);
                  setRejectType(type.primary);
               }
               let seenDoc = false; // HAVE THE NEXT DOCUMENT TO BE ATTENEDED TO SEEN?
               for (let i = documentID + 1; i < documentStream.length; i += 1) {
                  if (
                     (documentStream[i].status === 'pending' || documentStream[i].status === '') &&
                     !seenDoc
                  ) {
                     setActiveDoc(documentStream[i]);
                     setDocumentID(i);
                     seenDoc = true;
                  }
               }
               setdocumentActionLoading(false);
               setAllActions([...allActions, action.primary]);
               if (documentID !== documentStream.length - 2) {
                  // START A NEW DOCUMENT PREVIEW TRACKER IF IT'S NOT THE LAST DOCUMENT
                  await handleDocumentTracker('start');
               }

               window.scroll(0, 0);

               // IF THERE'S NO PENDING DOCUMENT REMAINING ON THIS BUSINESS, GO TO THE NEXT BUSINESS
               if (!isPendingRemaining()) {
                  finalAction();
               } else if (documentID === documentStream.length - 2) {
                  // THIS IF STATEMENT CHECKS IF IT'S THE LAST DOCUMENT ON THIS BUSINESS'S STREAM
                  finalAction();
               }
            } catch (e) {
               toast.error(e.message);
               setdocumentActionLoading(false);
            }
         },
      });
   };

   const updateAddress = () => {
      const params = {
         biz_account_id: business.biz_account_id,
         biz_address: address,
         biz_city: city,
         biz_state: region,
      };
      confirm({
         confirmText: 'Are you sure you want to continue ?',
         onConfirm: async () => {
            setDetailsUpdateLoading(true);
            try {
               const { message } = await updateBusinessDetails(params, 'Address');
               toast.success(message);
            } catch (e) {
               toast.error(e.message);
            }
            setDetailsUpdateLoading(false);
         },
      });
   };

   const updateTin = () => {
      const params = {
         biz_account_id: business.biz_account_id,
         biz_tin_number: tin,
      };
      confirm({
         confirmText: 'Are you sure you want to continue ?',
         onConfirm: async () => {
            setUpdateTinLoading(true);
            try {
               const { message } = await updateBusinessDetails(params, 'TIN Number');
               toast.success(message);
            } catch (e) {
               toast.error(e.message);
            }
            setUpdateTinLoading(false);
         },
      });
   };

   const renderOtherDirectors = (data) => {
      return data
         ? data.map((item) => (
              <div className="other-directors-details" key={item.board_resolution_id}>
                 <div className="row">
                    <h3 className="mb-4">Director 1</h3>
                    <div className="col-lg-3 col-md-6">
                       <div className="business-details-item">
                          <h6>Fullname</h6>
                          <p>{item.partner_full_name}</p>
                       </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                       <div className="business-details-item">
                          <h6>Email</h6>
                          <p>{item.partner_email}</p>
                       </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                       <div className="business-details-item">
                          <h6>Phone</h6>
                          <p className="">{item.partner_phone ? item.partner_phone : 'N/A'}</p>
                       </div>
                    </div>

                    <div className="col-lg-3 col-md-6 pr-0">
                       <div className="business-details-item">
                          <h6 className="">ID Document</h6>
                          {item.partner_identity_file ? (
                             <DocumentCard name="Identity File" url={item.partner_identity_file} />
                          ) : (
                             'N/A'
                          )}
                       </div>
                    </div>
                    <hr />
                 </div>
              </div>
           ))
         : null;
   };

   const getNextBusiness = async () => {
      setdocumentActionLoading(true);
      try {
         setDocuments(null);
         const payload = {
            biz_account_id: business.biz_account_id,
            prospa_manager_id: currentUser.prospa_user_id,
            action_type: 'single',
            stream_type: 'work_approval',
         };
         await feedWorkerTracker(payload, 'work_approval');
         location.reload();
         // const result = await initializeBusiness();
         // await intializeDocumentStream();
         // window.scroll(0, 0);
      } catch (e) {
         toast.error(e.message);
      }
      setdocumentActionLoading(false);
   };

   const handlePreapprove = async () => {
      setdocumentActionLoading(true);
      try {
         const params = {
            biz_account_id: business.biz_account_id,
            // biz_account_id: '610',
         };
         const { message } = await preapproveBusinessWithLimit(params);
         toast.success(message);
         await getNextBusiness();
      } catch (e) {
         toast.error(e.message);
      }
      setdocumentActionLoading(false);
   };

   if (documentActionLoading) return <Spinner />;
   if (!business || business.length < 1) return <h1 className="px-3">No Pending Document</h1>;

   return (
      <>
         <RejectDocument
            open={rejectModal}
            onClose={() => setRejectModal(false)}
            rejectType={rejectType}
            bizName={business.biz_name}
            recipient={business?.account_holder_details?.email}
         />
         {activeDoc?.name !== 'Preapprove' && (
            <AccountDetailsContainer className="px-md-5 py-5">
               <h3 className="current-document-name">{activeDoc?.name}</h3>
               {activeDoc && activeDoc.url ? (
                  <DocumentViewer
                     url={activeDoc?.url}
                     biz_account_id={business.biz_account_id}
                     title={activeDoc.name}
                  >
                     {activeDoc && activeDoc.url && activeDoc.url.includes('.pdf') ? (
                        <div className="pending-document-preview">
                           <embed
                              src={activeDoc?.url}
                              type="application/pdf"
                              width="100%"
                              height="100%"
                           />
                        </div>
                     ) : (
                        <div
                           className={
                              activeDoc?.name === 'Selfie'
                                 ? ' d-flex gap-3 pending-document-preview w-50'
                                 : 'pending-document-preview'
                           }
                        >
                           {activeDoc?.name === 'Selfie' && (
                              <img
                                 src={business?.account_holder_details?.identity_file}
                                 alt=""
                                 className="expand-pending-document"
                              />
                           )}
                           <img src={activeDoc?.url} alt="" className="expand-pending-document" />
                        </div>
                     )}
                  </DocumentViewer>
               ) : (
                  activeDoc?.type.primary === 'is_tin_number_verified' &&
                  (activeDoc.data !== '' ? (
                     <div className="mb-5 d-flex align-items-center ">
                        <Input
                           type=""
                           className="me-3"
                           name="tin"
                           id="tin"
                           label=""
                           value={tin}
                           onChange={(e) => setTin(e.target.value)}
                           error=""
                        />
                        {updateTinLoading ? (
                           <Spinner />
                        ) : (
                           <Button type="submit" variant="primary" onClick={updateTin}>
                              Update TIN
                           </Button>
                        )}
                     </div>
                  ) : (
                     <div className="py-4 mb-5">
                        <h2 className="text text-info">Requires TIN. Please process</h2>
                     </div>
                  ))
               )}

               <div className="user-profile">
                  <div className="d-flex">
                     <img src={UserAvatar} className="user-image" alt="" />
                     <div className="">
                        <h2
                           className="user-name"
                           id="profile-user-name"
                        >{` ${business?.account_holder_details?.first_name} ${business?.account_holder_details?.last_name}`}</h2>
                        <p className="phone small-text mb-0 user-phoneno">
                           {business?.account_holder_details?.phone}
                        </p>
                        <p className="email small-text mb-0 user-emailaddress">
                           {business?.account_holder_details?.email}
                        </p>
                        <div className="biz-status-badge">
                           {business.biz_status === 'unapproved'
                              ? formatTag('rejected', 'UNAPPROVED')
                              : formatTag('', 'PREAPPROVED')}
                        </div>
                     </div>
                  </div>
               </div>

               <div className="business-details">
                  <h3>Business Details</h3>
                  <div className="row">
                     <div className="col-lg-3 col-md-6">
                        <div className="business-details-item">
                           <h6>Business Name</h6>
                           <p id="biz-name">{business?.biz_name}</p>
                        </div>
                     </div>

                     <div className="col-lg-3 col-md-6">
                        <div className="business-details-item">
                           <h6>Business Type</h6>
                           <p className="biz-type">{business?.biz_type}</p>
                        </div>
                     </div>

                     <div className="col-lg-3 col-md-6">
                        <div className="business-details-item">
                           <h6>CAC Certiticate Verified</h6>
                           <div id="cac-verification-status">
                              {formatTag(business?.cac_cert_photo_status)}
                           </div>
                        </div>
                     </div>

                     <div className="col-lg-3 col-md-6">
                        <div className="business-details-item">
                           <h6>Proof of Address</h6>
                           <div id="poa-status">{formatTag(business?.proof_of_address_status)}</div>
                        </div>
                     </div>

                     <div className="col-lg-3 col-md-6">
                        <div className="business-details-item">
                           <h6>Business Seleted from CAC</h6>
                           <div id="selected-from-cac">
                              {formatTag(
                                 business?.cac_biz_tag === 0 ? 'rejected' : 'accepted',
                                 business?.cac_biz_tag === 0 ? 'No' : 'Yes',
                              )}
                           </div>
                        </div>
                     </div>

                     <div className="col-lg-3 col-md-6">
                        <div className="business-details-item">
                           <h6>Tax Identification Number</h6>
                           <div id="tax-identification-number">
                              {tin
                                 ? tin
                                 : formatTag(
                                      business?.tin_number_status,
                                      business?.tin_number_status,
                                   )}
                           </div>
                        </div>
                     </div>

                     <div className="col-lg-3 col-md-6">
                        <div className="business-details-item">
                           <h6>Business Address</h6>
                           <div className="w-100">
                              <input
                                 id="biz-address"
                                 className="px-1"
                                 placeholder="Address"
                                 value={address}
                                 onChange={(e) => setAddress(e.target.value)}
                              />{' '}
                              <span className="mr-2"> , </span>
                              <input
                                 id="biz-city"
                                 className="mr-2"
                                 placeholder="City"
                                 value={city}
                                 onChange={(e) => setCity(e.target.value)}
                              />
                           </div>
                           <div className="d-flex mt-2">
                              <input
                                 id="biz-state"
                                 className="mr-2 px-1"
                                 placeholder="State"
                                 value={region}
                                 onChange={(e) => setRegion(e.target.value)}
                              />
                              {detailsUpdateLoading ? (
                                 <Spinner />
                              ) : (
                                 <button
                                    type="button"
                                    className="bg-primary px-4 py-1 text-white update-biz-address"
                                    onClick={updateAddress}
                                 >
                                    Save
                                 </button>
                              )}
                           </div>
                        </div>
                     </div>

                     <div className="col-lg-3 col-md-6">
                        <div className="business-details-item">
                           <h6>Registered Business Address</h6>
                           <div id="reg_biz_address">
                              {business?.reg_biz_address
                                 ? `${business?.reg_biz_address}. ${business?.reg_biz_city}, ${business?.reg_biz_state}`
                                 : 'N/A'}
                           </div>
                        </div>
                     </div>

                     <div className="col-lg-3 col-md-6">
                        <div className="business-details-item">
                           <h6>Business Registration Number</h6>
                           <p id="biz-cac-tag">{business?.rc_number}</p>
                        </div>
                     </div>

                     <div className="col-lg-3 col-md-6">
                        <div className="business-details-item">
                           <h6>Turnover</h6>
                           <p id="biz-turnover">{business?.biz_turnover}</p>
                        </div>
                     </div>

                     <div className="col-lg-3 col-md-6">
                        <div className="business-details-item">
                           <h6>Date Created</h6>
                           <div id="biz-datecreated">
                              {dayjs(business?.pub_date).format('MMM DD, YYYY')}
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="owners-info  business-details pt-4">
                     <h3>Owner’s Information</h3>
                     <div className="row">
                        <div className="col-lg-3 col-md-6">
                           <div className="business-details-item">
                              <h6>Firstname</h6>
                              <p id="user-firstname">
                                 {business?.account_holder_details?.first_name}
                              </p>
                           </div>
                        </div>

                        <div className="col-lg-3 col-md-6">
                           <div className="business-details-item">
                              <h6>Lastname</h6>
                              <p id="user-lastname">
                                 {business?.account_holder_details?.last_name}
                              </p>
                           </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                           <div className="business-details-item">
                              <h6>Phone Number</h6>
                              <p className="user-phoneno">
                                 {business?.account_holder_details?.phone}
                              </p>
                           </div>
                        </div>

                        <div className="col-lg-3 col-md-6">
                           <div className="business-details-item">
                              <h6>Email</h6>
                              <p className="user-emailaddress">
                                 {business?.account_holder_details?.email}
                              </p>
                           </div>
                        </div>

                        <div className="col-lg-3 col-md-6">
                           <div className="business-details-item">
                              <h6>ID Number</h6>
                              <p id="user-idnumber">
                                 {business?.account_holder_details?.identity_number}
                              </p>
                           </div>
                        </div>

                        <div className="col-lg-3 col-md-6">
                           <div className="business-details-item">
                              <h6>ID Type</h6>
                              <p id="user-idtype">
                                 {business?.account_holder_details?.identity_type}
                              </p>
                           </div>
                        </div>

                        <div className="col-lg-3 col-md-6">
                           <div className="business-details-item">
                              <h6>BVN Verified</h6>
                              <div id="bvn-verification-status">
                                 {formatTag(
                                    business?.account_holder_details?.bvn_number
                                       ? 'accepted'
                                       : 'rejected',
                                 )}
                              </div>
                           </div>
                        </div>

                        <div className="col-lg-3 col-md-6">
                           <div className="business-details-item">
                              <h6>Business Owner is a Director</h6>
                              <div id="is-owner-director">
                                 {formatTag(
                                    business?.director_match_status === '' ||
                                       business?.director_match_status === 'rejected'
                                       ? 'rejected'
                                       : 'accepted',
                                 )}
                              </div>
                           </div>
                        </div>

                        <div className="col-lg-3 col-md-6">
                           <div className="business-details-item">
                              <h6>Govt Issued ID Verified</h6>
                              <div id="govt-id-verified">
                                 {formatTag(business?.account_holder_details?.identity_file_status)}
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>

                  <div className="business-details pt-4" id="documents-preview-container">
                     <h3>Preview Documents</h3>
                     <div id="preview-documents" className="row">
                        <div className="col-xl-3 col-lg-4 pr-0 col-md-6">
                           <DocumentCard name="Selfie" url={business?.selfie_image_url} />
                        </div>
                        <div className="col-xl-3 col-lg-4 pr-0 col-md-6">
                           <DocumentCard
                              name="Identity File"
                              url={business?.account_holder_details?.identity_file}
                           />
                        </div>

                        <div className="col-xl-3 col-lg-4 col-md-6 pr-0">
                           <DocumentCard name="CAC Cerificate" url={business?.cac_cert_photo_url} />
                        </div>

                        <div className="col-xl-3 col-lg-4 col-md-6 pr-0">
                           <DocumentCard
                              name="Proof of Address"
                              url={business?.proof_of_address_url}
                           />
                        </div>

                        {business?.tin_document_one && (
                           <div className="col-xl-3 col-lg-4 col-md-6 pr-0">
                              <DocumentCard
                                 name="TIN Document One"
                                 url={business?.tin_document_one}
                              />
                           </div>
                        )}

                        {business?.tin_document_two && (
                           <div className="col-xl-3 col-lg-4 col-md-6 pr-0">
                              <DocumentCard
                                 name="TIN Document TWO"
                                 url={business?.tin_document_two}
                              />
                           </div>
                        )}

                        {business?.tin_document_three && (
                           <div className="col-xl-3 col-lg-4 col-md-6 pr-0">
                              <DocumentCard
                                 name="TIN Document Three"
                                 url={business?.tin_document_three}
                              />
                           </div>
                        )}

                        {business?.tin_document_four && (
                           <div className="col-xl-3 col-lg-4 col-md-6 pr-0">
                              <DocumentCard
                                 name="TIN Document Four"
                                 url={business?.tin_document_four}
                              />
                           </div>
                        )}
                     </div>
                  </div>

                  {business.board_partner_details ? (
                     <div className="business-details pt-4" id="other-director-information">
                        <h3>Other Director’s Information</h3>
                        {renderOtherDirectors(business?.board_partner_details)}
                     </div>
                  ) : null}
               </div>

               <div className="action-btns d-flex justify-content-md-end flex-wrap">
                  <span className="me-4 mb-4 mb-md-0">
                     <Button
                        type="submit"
                        variant="primary"
                        onClick={() =>
                           handleDocumentAction(
                              { primary: 'accepted', secondary: 'accepted' },
                              activeDoc && activeDoc.type,
                           )
                        }
                     >
                        Approve {activeDoc ? activeDoc.btn_text || activeDoc.name : ''}
                     </Button>
                  </span>
                  <span
                     className={`${
                        (activeDoc?.type === 'is_tin_number_verified' ||
                           'is_tin_document_verified') &&
                        'me-4'
                     } mb-4 mb-md-0`}
                  >
                     {/* {
                  // activeDoc?.type !== "is_tin_number_verified" &&
                  (activeDoc?.type.primary === 'is_tin_number_verified' ||
                     activeDoc?.type.primary === 'is_tin_document_verified') &&
                     business?.biz_status === 'unapproved' && (
                        <Button
                           type="submit"
                           variant="secondary"
                           onClick={() =>
                              handleDocumentAction({primary:'with-limit', secondary:'tin_doc_approved'}, activeDoc && activeDoc.type)
                           }
                        >
                           Approve With Limit
                        </Button>
                     )
               } */}
                  </span>

                  <span className="me-4 mb-4 mb-md-0">
                     <Button
                        type="submit"
                        variant="danger"
                        onClick={() =>
                           handleDocumentAction(
                              { primary: 'rejected', secondary: 'tin_doc_rejected' },
                              activeDoc && activeDoc.type,
                           )
                        }
                     >
                        Reject {activeDoc ? activeDoc.btn_text || activeDoc.name : ''}
                     </Button>
                  </span>
               </div>
            </AccountDetailsContainer>
         )}
         {activeDoc?.name === 'Preapprove' && (
            <div>
               <h2 className="text-center mb-5 w-100">
                  Do you want to approve this account with limit?
               </h2>
               <div className="d-flex justify-content-center">
                  <Button
                     type="submit"
                     variant="secondary"
                     className="me-4"
                     onClick={handlePreapprove}
                  >
                     Approve With Limit
                  </Button>
                  <Button type="submit" variant="primary" onClick={getNextBusiness}>
                     Next Business
                  </Button>
               </div>
            </div>
         )}
      </>
   );
}
