export const basicOperations = [
   {
      value: 'accepted',
      label: 'Accepted',
   },
   {
      value: 'pending',
      label: 'Pending',
   },
   {
      value: 'rejected',
      label: 'Rejected',
   },
];

export const accountStatus = [
   {
      value: 'active',
      label: 'Active',
   },
   {
      value: 'inactive',
      label: 'Inactive',
   },
   {
      value: 'churned',
      label: 'Churned',
   },
   {
      value: 'at_risk',
      label: ' At Risk',
   },
   {
      value: 'dormant',
      label: 'Dormant',
   },
];

export const businessType = [
   {
      value: 'registered',
      label: 'Registered',
   },
   {
      value: 'freelancer',
      label: 'Freelancer',
   },
];

export const turnover = [
   {
      value: 'less_than_100k',
      label: 'Less than 100K',
   },
   {
      value: '100k-1m',
      label: '100K - 1M',
   },
   {
      value: '1m-5m',
      label: '1M - 5M',
   },
   {
      value: '5m-10m',
      label: '5M - 10M',
   },
   {
      value: '10m-50m',
      label: '10M - 50M',
   },
   {
      value: '50m_above',
      label: 'Above 50M',
   },
];

export const bizStatus = [
   {
      value: 'approved',
      label: 'Approved',
   },
   {
      value: 'pre_approved',
      label: 'Preapproved',
   },
   {
      value: 'unapproved',
      label: 'Unapproved',
   },
];

export const bvnStatus = [
   {
      value: true,
      label: 'Accepted',
   },
   {
      value: false,
      label: 'Rejected',
   },
];

export const tinStatus = [
   ...basicOperations,
   {
      value: 'requires_tin',
      label: 'Requires TIN',
   },
   {
      value: 'tin_doc_approved',
      label: 'TIN Document Approved',
   },
   {
      value: 'tin_doc_rejected',
      label: 'TIN Document Rejected',
   },
];

export const states = [
   {
      value: 'abia',
      label: 'Abia',
   },
   {
      value: 'adamawa',
      label: 'Adamawa',
   },
   {
      value: 'akwa ibom',
      label: 'Akwa ibom',
   },
   {
      value: 'anambra',
      label: 'Anambra',
   },
   {
      value: 'bauchi',
      label: 'Bauchi',
   },
   {
      value: 'bayelsa',
      label: 'Bayelsa',
   },
   {
      value: 'benue',
      label: 'Benue',
   },
   {
      value: 'borno',
      label: 'Borno',
   },
   {
      value: 'cross river',
      label: 'Cross River',
   },
   {
      value: 'delta',
      label: 'Delta',
   },
   {
      value: 'ebonyi',
      label: 'Ebonyi',
   },
   {
      value: 'edo',
      label: 'Edo',
   },
   {
      value: 'ekiti',
      label: 'Ekiti',
   },
   {
      value: 'enugu',
      label: 'Enugu',
   },
   {
      value: 'fct - abuja',
      label: 'Fct - Abuja',
   },
   {
      value: 'gombe',
      label: 'Gombe',
   },
   {
      value: 'imo',
      label: 'Imo',
   },
   {
      value: 'jigawa',
      label: 'Jigawa',
   },
   {
      value: 'kaduna',
      label: 'Kaduna',
   },
   {
      value: 'kano',
      label: 'Kano',
   },
   {
      value: 'katsina',
      label: 'Katsina',
   },
   {
      value: 'kebbi',
      label: 'Kebbi',
   },
   {
      value: 'kogi',
      label: 'Kogi',
   },
   {
      value: 'kwara',
      label: 'Kwara',
   },
   {
      value: 'lagos',
      label: 'Lagos',
   },
   {
      value: 'nasarawa',
      label: 'Nasarawa',
   },
   {
      value: 'niger',
      label: 'Niger',
   },
   {
      value: 'ogun',
      label: 'Ogun',
   },
   {
      value: 'ondo',
      label: 'Ondo',
   },
   {
      value: 'osun',
      label: 'Osun',
   },
   {
      value: 'oyo',
      label: 'Oyo',
   },
   {
      value: 'plateau',
      label: 'Plateau',
   },
   {
      value: 'rivers',
      label: 'Rivers',
   },
   {
      value: 'sokoto',
      label: 'Sokoto',
   },
   {
      value: 'taraba',
      label: 'Taraba',
   },
   {
      value: 'yobe',
      label: 'Yobe',
   },
   {
      value: 'zamfara',
      label: 'Zamfara',
   },
];

export const interests = [
   'Credit and loans',
   'Expense management',
   'Tax support',
   'Bulk transfers',
   'POS',
   'Payroll',
   'Business insurance',
   'Automations',
   'Cards',
   'Cross border payments',
];
