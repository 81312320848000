import styled from 'styled-components';

export const Tag = styled.div`
   display: flex;
   cursor: pointer;
   width: ${(props) => (props.fullWidth ? '100%' : 'fit-content')};
   background: #e3e4e9;
   border-radius: 4px;
   padding: 10px;
   justify-content: space-between;

   .content {
      width: 90%;
   }

   .text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
   }

   .tag-heading {
      font-size: 15px;
      line-height: 24px;
      color: #82849f;
   }

   .tag-body {
      font-size: 15px;
      line-height: 24px;
      color: #82849f;
   }

   img {
      cursor: pointer;
      width: 12px;
      height: 12px;
   }
`;
