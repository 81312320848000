/* eslint-disable react/prop-types */
import { useState, useRef } from 'react';
import { TableContainer, TableContent } from 'styles/table';
import { filterFailedBvn, bvnLookUp, bvnManualVerification } from 'services/bvn';
import { getBusinessDetails } from 'services/businessDetails';
import { confirm } from 'components/confirm-box';
import { TooltipComponent } from 'components/tooltip';
import { TableComponent } from 'components/table';
import { InputWrapper } from 'styles/form';
import { Button } from 'components/button';
import { Input } from 'components/input';
import BusinessDetails from 'containers/business-details';
import { Skeleton } from '@material-ui/lab';
import toast from 'react-hot-toast';

function ManualBvnVerification() {
   const [accountHolderID, setAccountHolderId] = useState('');
   const [businessId, setBusinessId] = useState('');
   const [bvnNumber, setBvnNumber] = useState('');
   const [firstname, setFirstname] = useState('');
   const [lastname, setLastname] = useState('');
   const [dob, setDob] = useState('');
   const [filtering, setFiltering] = useState(false);
   const [tableData, setTableData] = useState(null);
   const [bvnDetailsTable, setBvnDetailsTable] = useState(null);
   const [isLoading] = useState(false);
   const [isVerifying, setIsVerifying] = useState(false);
   const [modalVisibility, setModalVisibility] = useState(false);
   const [bizAccount, setBizAccount] = useState('');
   const tableRef = useRef();

   function onRowClick({ row }) {
      setBizAccount(row.biz_account_id);
   }

   const columns = [
      {
         field: 'account_holder_id',
         headerName: 'Account Holder ID',
         renderCell: ({ row }) => (
            <TooltipComponent title={row.account_holder_id}>
               {row.account_holder_id || 'N/A'}
            </TooltipComponent>
         ),
      },
      {
         field: 'first_name',
         headerName: 'First Name',
         renderCell: ({ row }) => (
            <TooltipComponent title={row.first_name}>{row.first_name || 'N/A'}</TooltipComponent>
         ),
      },
      {
         field: 'last_name',
         headerName: 'Last Name',
         width: 150,
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.last_name}>{row.last_name || 'N/A'}</TooltipComponent>
         ),
      },
      {
         field: 'identity_file',
         headerName: 'Identity File',
         width: 150,
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.identity_file}>
               {row?.identity_file ? (
                  <a href={row?.identity_file} target="_blank" rel="noreferrer">
                     {row?.identity_file}
                  </a>
               ) : (
                  'N/A'
               )}
            </TooltipComponent>
         ),
      },
   ];

   const bvnDetailsColumns = [
      {
         field: 'bvn_number',
         headerName: 'BVN',
         width: 150,
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.bvn_number}>{row.bvn_number || 'N/A'}</TooltipComponent>
         ),
      },
      {
         field: 'first_name',
         headerName: 'First Name',
         renderCell: ({ row }) => (
            <TooltipComponent title={row.first_name}>{row.first_name || 'N/A'}</TooltipComponent>
         ),
      },
      {
         field: 'last_name',
         headerName: 'Last Name',
         width: 150,
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.last_name}>{row.last_name || 'N/A'}</TooltipComponent>
         ),
      },
      {
         field: 'middle_name',
         headerName: 'Middle Name',
         width: 150,
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.last_name}>{row.last_name || 'N/A'}</TooltipComponent>
         ),
      },
      {
         field: 'dob',
         headerName: 'Date Of Birth',
         width: 150,
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.dob}>{row.dob || 'N/A'}</TooltipComponent>
         ),
      },
      {
         field: 'phone',
         headerName: 'Phone',
         width: 150,
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.phone}>{row.phone || 'N/A'}</TooltipComponent>
         ),
      },
   ];

   async function handleFilterFailedBvn() {
      if (accountHolderID === '') return null;
      setFiltering(true);
      try {
         const result = await filterFailedBvn(accountHolderID);
         if (result.data.length > 0) {
            const businessDetails = await getBusinessDetails(businessId);
            const { account_holder_id, first_name, identity_file, last_name } =
               businessDetails.data.account_holder_details;

            setTableData([{ account_holder_id, first_name, identity_file, last_name }]);

            const bvnDetails = await bvnLookUp({
               bvn_number: result.data[0].bvn_number,
            });
            setBvnDetailsTable([
               {
                  ...bvnDetails,
                  bvn_number: result.data[0].bvn_number,
               },
            ]);
            setBvnNumber(result.data[0].bvn_number);
            setFirstname(bvnDetails.first_name);
            setLastname(bvnDetails.last_name);
            setDob(bvnDetails.dob);
         } else {
            setTableData(result.data);
         }
      } catch (e) {
         setBvnDetailsTable([]);
         toast.error(e.message || e);
      }
      setFiltering(false);
   }

   const handleVerifyBvn = async () => {
      confirm({
         confirmText:
            'Have you confirmed that the details on the account matches with that on the BVN',
         isLoading: false,
         onConfirm: async () => {
            setIsVerifying(true);
            try {
               const response = await bvnManualVerification({
                  account_holder_id: accountHolderID,
                  bvn_number: bvnNumber,
                  first_name: firstname,
                  last_name: lastname,
                  date_of_birth: dob,
                  businessId,
               });
               toast.success(response.message || 'BVN has been successfully verified');
               setBusinessId('');
               setAccountHolderId('');
               setBvnNumber('');
               setFirstname('');
               setLastname('');
               setDob('');
               setTableData(null);
               setBvnDetailsTable(null);
            } catch (e) {
               toast.error(e.message || e);
            }
            setIsVerifying(false);
         },
      });
   };

   return (
      <>
         <div className="row align-items-end">
            <InputWrapper className="col-lg-4">
               <Input
                  placeholder="Account holder ID"
                  label="Account holder ID"
                  value={accountHolderID}
                  onChange={(e) => setAccountHolderId(e.target.value)}
               />
            </InputWrapper>
            <InputWrapper className="col-lg-4">
               <Input
                  placeholder="Enter Business ID"
                  label="Business ID"
                  value={businessId}
                  onChange={(e) => setBusinessId(e.target.value)}
               />
            </InputWrapper>
            <InputWrapper className="col-lg-4">
               <Button
                  variant="primary"
                  onClick={handleFilterFailedBvn}
                  className=""
                  isLoading={filtering}
                  disabled={accountHolderID === '' || businessId === '' || filtering}
               >
                  Filter Failed BVN
               </Button>
            </InputWrapper>
         </div>

         {tableData && (
            <div className="filter-result-container">
               {tableData.length > 0 ? (
                  <div>
                     <TableContainer id="table">
                        <TableContent>
                           {isLoading ? (
                              <Skeleton width="30%" height="20" />
                           ) : (
                              <div>
                                 <h1>Member&apos;s Information on Prospa </h1>
                                 <span className="value">{tableData?.length || 'N/A'}</span>
                              </div>
                           )}
                        </TableContent>
                        <TableComponent
                           columns={columns}
                           rows={tableData}
                           isLoading={isLoading}
                           count={Math.ceil(tableData.length / 15)}
                           ref={tableRef}
                           onRowClick={onRowClick}
                        />
                     </TableContainer>
                     {bvnDetailsTable && (
                        <div className="mt-5 pt-3">
                           {bvnDetailsTable.length > 0 ? (
                              <>
                                 <TableContainer id="table">
                                    <TableContent>
                                       {isLoading ? (
                                          <Skeleton width="30%" height="20" />
                                       ) : (
                                          <div>
                                             <h1>BVN Details </h1>
                                             <span className="value">
                                                {tableData?.length || 'N/A'}
                                             </span>
                                          </div>
                                       )}
                                    </TableContent>
                                    <TableComponent
                                       columns={bvnDetailsColumns}
                                       rows={bvnDetailsTable}
                                       isLoading={isLoading}
                                       count={Math.ceil(bvnDetailsTable.length / 15)}
                                    />
                                 </TableContainer>

                                 <div className="mt-5 pt-5">
                                    <h2>Verify BVN </h2>
                                    <div className="row align-items-end">
                                       <InputWrapper className="col-lg-4">
                                          <Input
                                             placeholder="Account holder ID"
                                             label="Account holder ID"
                                             value={accountHolderID}
                                             disabled
                                          />
                                       </InputWrapper>
                                       <InputWrapper className="col-lg-4">
                                          <Input
                                             placeholder="Enter BVN"
                                             label="BVN Number"
                                             value={bvnNumber}
                                             disabled
                                          />
                                       </InputWrapper>
                                       <InputWrapper className="col-lg-4">
                                          <Input
                                             placeholder="Enter First Name"
                                             label="First Name"
                                             value={firstname}
                                             disabled
                                          />
                                       </InputWrapper>
                                       <InputWrapper className="col-lg-4">
                                          <Input
                                             placeholder="Enter Last Name"
                                             label="Last Name"
                                             value={lastname}
                                             disabled
                                          />
                                       </InputWrapper>
                                       <InputWrapper className="col-lg-4">
                                          <Input
                                             placeholder="Enter Date of Birth"
                                             label="Date of Birth"
                                             value={dob}
                                             onChange={(e) => setDob(e.target.value)}
                                          />
                                       </InputWrapper>
                                       <InputWrapper className="col-lg-4">
                                          <Button
                                             variant="primary"
                                             onClick={handleVerifyBvn}
                                             className=""
                                             isLoading={isVerifying}
                                             disabled={isVerifying}
                                          >
                                             Verify BVN
                                          </Button>
                                       </InputWrapper>
                                    </div>
                                 </div>
                              </>
                           ) : (
                              <h3>Unable to send BVN for processing</h3>
                           )}
                        </div>
                     )}
                  </div>
               ) : (
                  <p>Data not found</p>
               )}
            </div>
         )}

         <BusinessDetails
            businessAccountId={bizAccount}
            open={modalVisibility}
            onClose={() => setModalVisibility(false)}
         />
      </>
   );
}

export default ManualBvnVerification;
