/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Skeleton } from '@material-ui/lab';
import moment from 'moment';
import { Button } from 'components/button';
import { crossCheckNibssTransactions, getExportedNibssTransactions } from 'services/transactions';
import { TableContainer, TableContent } from 'styles/table';
import { TableComponent } from 'components/table';
import toast from 'react-hot-toast';

export default function CrossReference() {
   const { search } = useLocation();
   const page = new URLSearchParams(search).get('page');
   const [tableData, setTableData] = useState([]);
   const [isLoading, setIsLoading] = useState(false);
   const [paginationData, setPaginationData] = useState({});
   const [currentPage, setCurrentPage] = useState(page);
   const [file, setFile] = useState('');
   const [uploadingFile, setUploadingFile] = useState(false);

   const columns = [
      {
         field: 'initiated_by_name',
         headerName: 'Created By',
         renderCell: ({ row }) => <p>{row?.initiated_by_name}</p>,
      },
      {
         field: 'created',
         headerName: 'Response Date',
         renderCell: ({ row }) => <p>{moment(row?.created)?.format('DD MMM, YYYY | HH:mm A')}</p>,
      },
      {
         field: 'url',
         headerName: 'Downloadable Data',
         renderCell: ({ row }) => <a href={row?.url}>Download data</a>,
      },
   ];

   const fetchCrossChekedNibssTransaction = async (pageNumber) => {
      setIsLoading(true);
      Promise.all([
         getExportedNibssTransactions({
            page: pageNumber,
            export_type: 'transaction_cross_reference',
         }),
      ])
         .then(([businessAccountsData]) => {
            setTableData(businessAccountsData?.results);
            setPaginationData(businessAccountsData);
         })
         .finally(() => {
            setIsLoading(false);
         });
   };

   useEffect(() => {
      fetchCrossChekedNibssTransaction(currentPage);
   }, [currentPage]);

   function onPageChange(e, pageNumber) {
      setCurrentPage(pageNumber);
   }

   const uploadFile = async () => {
      setUploadingFile(true);
      const formData = new FormData();
      formData.append('nibss_file', file);

      try {
         const response = await crossCheckNibssTransactions(formData);
         toast.success(response?.data?.message);
         setTimeout(() => {
            fetchCrossChekedNibssTransaction();
         }, 10000);
      } catch (e) {
         toast.error(e?.message);
      }
      setUploadingFile(false);
   };

   return (
      <div>
         <TableContainer>
            <h2>Upload Transactions for Cross Referencing</h2>
            <div className="input-group mt-4 doc-file-input-group align-items-center">
               {!uploadingFile && (
                  <input
                     type="file"
                     aria-label="Upload"
                     onChange={(e) => setFile(e.target.files[0])}
                  />
               )}
               <Button
                  className="upload-btn"
                  disabled={!file || uploadingFile}
                  isLoading={uploadingFile}
                  onClick={uploadFile}
               >
                  Upload File
               </Button>
            </div>
            <TableContent style={{ borderTop: 0 }}>
               <div className="d-flex justify-content-between w-100 align-items-center mt-5">
                  {isLoading ? (
                     <Skeleton width="30%" height="20" />
                  ) : (
                     <div>
                        <h1> Cross Referencing Results</h1>
                        <span className="value">{paginationData?.count || 'N/A'}</span>
                     </div>
                  )}
               </div>
            </TableContent>

            <TableComponent
               columns={columns}
               rows={tableData}
               isLoading={isLoading}
               onPageChange={onPageChange}
               count={Math.ceil(paginationData.count / 15)}
            />
         </TableContainer>
      </div>
   );
}
