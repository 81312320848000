import React from 'react';
import { func, objectOf } from 'prop-types';
import useTransactionsSettings from 'hooks/transactionsSettings';
import { Spinner } from 'components/spinner';
import { FlagCompliance } from './flag-compliance';
import { OtpSettings } from './otp-settings';

const Compliance = ({ allSettings, handleActiveTransfer }) => {
   const { loading } = useTransactionsSettings();

   return (
      <>
         {loading ? (
            <Spinner />
         ) : (
            <div>
               <FlagCompliance
                  allSettings={allSettings}
                  handleActiveTransfer={handleActiveTransfer}
               />
               <div className="mt-4 border-top">
                  <OtpSettings allSettings={allSettings} />
               </div>
            </div>
         )}
      </>
   );
};

Compliance.propTypes = {
   allSettings: objectOf().isRequired,
   handleActiveTransfer: func.isRequired,
};

export default Compliance;
