/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import { Skeleton } from '@material-ui/lab';
import { MoreHoriz } from '@material-ui/icons';
import { SelectInput } from 'components/select';
import { IconButton } from '@material-ui/core';
import { TableContainer, TableContent } from 'styles/table';
import { TooltipComponent } from 'components/tooltip';
import { getPCRLabel } from 'utils/loans';
import { Button } from 'components/button';
import { confirm } from 'components/confirm-box';
import { TableComponent } from 'components/table';
import { MenuComponent } from 'components/menu';
import { Status } from 'styles/utils';
import { numberWithCommas } from 'utils/others';
import { deleteLoanTerm, getAllLoanTerms } from 'services/loans';
import AddLoanTerm from '../add-loan-term';

function LoanTerm() {
   const { search } = useLocation();
   const page = new URLSearchParams(search).get('page');
   const [tableData, setTableData] = useState([]);
   const [isLoading, setIsLoading] = useState(false);
   const [modalVisibility, setModalVisibility] = useState(false);
   const [paginationData, setPaginationData] = useState({});
   const [term, setTerm] = useState({});
   const [loanterm, setLoanterm] = useState('');
   const [currentPage, setCurrentPage] = useState(page || 1);

   const options = ['Edit', 'Delete'];

   const loan_terms = [
      {
         value: 'thirty_day_loan',
         label: '30 days',
      },
      {
         value: 'sixty_day_loan',
         label: '60 days',
      },
      {
         value: 'ninety_day_loan',
         label: '90 days',
      },
   ];

   const [anchorEl, setAnchorEl] = useState(null);
   const open = Boolean(anchorEl);

   const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
   };

   const handleClose = () => {
      setAnchorEl(null);
   };

   function onRowClick({ row }) {
      setTerm(row);
   }

   const initialize = async () => {
      setIsLoading(true);
      setTableData([]);
      setPaginationData({});

      try {
         const response = await getAllLoanTerms({
            page,
            tenure_type: loanterm || 'thirty_day_loan',
         });
         setTableData(response.results);
         setPaginationData(response);
      } catch (e) {
         toast.error(e.message);
      }

      setIsLoading(false);
   };
   useEffect(() => {
      initialize();
   }, [currentPage, page, loanterm]);

   const timedInitialization = () =>
      setTimeout(() => {
         initialize();
      }, 500);

   const handleDelete = async () => {
      confirm({
         confirmText: 'Are you sure you want to delete this?',
         onConfirm: async () => {
            try {
               await deleteLoanTerm({ id: term.id });
               setTerm({});
               toast.success('Tenure was deleted successfully');
               timedInitialization();
            } catch (e) {
               toast.error(e.message);
            }
         },
      });
   };

   const handleOnCloseAdd = async () => {
      setModalVisibility(false);
      timedInitialization();
   };

   const columns = [
      {
         field: '',
         width: 0,
         renderCell: () => (
            <div>
               <IconButton
                  aria-label="more"
                  aria-controls="long-menu"
                  aria-haspopup="true"
                  onClick={handleClick}
               >
                  <MoreHoriz />
               </IconButton>
               <MenuComponent
                  anchorEl={anchorEl}
                  handleClose={handleClose}
                  open={open}
                  options={options}
                  optionsFns={[() => setModalVisibility('edit'), () => handleDelete()]}
               />
            </div>
         ),
      },
      {
         field: 'min_score',
         headerName: 'Min Score',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.min_score}>{row?.min_score}</TooltipComponent>
         ),
      },
      {
         field: 'max_score',
         headerName: 'Max Score',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.max_score}>{row?.max_score}</TooltipComponent>
         ),
      },
      {
         field: 'max_score_label',
         headerName: 'CRS Score',
         renderCell: ({ row }) => (
            <TooltipComponent title={<>{getPCRLabel(row?.max_score)}</>}>
               {getPCRLabel(row?.max_score)}
            </TooltipComponent>
         ),
      },
      {
         field: 'max_loan_offer',
         headerName: 'Max Loan Offer',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.max_loan_offer}>
               ₦{numberWithCommas(row?.max_loan_offer)}
            </TooltipComponent>
         ),
      },
      {
         field: 'interest_rate',
         headerName: 'Interest Rate',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.interest_rate}>
               %{numberWithCommas(row?.interest_rate)}
            </TooltipComponent>
         ),
      },
      {
         field: 'insurance_fee',
         headerName: 'Insurance fee',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.insurance_fee}>
               ₦{numberWithCommas(row?.insurance_fee)}
            </TooltipComponent>
         ),
      },
      {
         field: 'admin_fee',
         headerName: 'Admin Fee',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.admin_fee}>
               ₦{numberWithCommas(row?.admin_fee)}
            </TooltipComponent>
         ),
      },
      {
         field: 'is_active',
         headerName: 'Active',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.is_active}>
               <Status status={row?.is_active ? 'on' : 'off'}>
                  {row.is_active ? 'Active' : 'InActive'}
               </Status>
            </TooltipComponent>
         ),
      },
      {
         field: 'created',
         headerName: 'Created Date',
         renderCell: ({ row }) => (
            <TooltipComponent title={moment(row.created).format('DD MMM, YYYY')}>
               {moment(row.pub_date).format('DD MMM, YYYY')} |{' '}
               <span style={{ opacity: '.5' }}>
                  {moment(row.pub_createddate).format('HH:mm A')}
               </span>
            </TooltipComponent>
         ),
      },
      {
         field: 'modified',
         headerName: 'Modified Date',
         renderCell: ({ row }) => (
            <TooltipComponent title={moment(row.created).format('DD MMM, YYYY')}>
               {row.modified && moment(row.modified).format('DD MMM, YYYY')} |{' '}
               {row.modified && (
                  <span style={{ opacity: '.5' }}>{moment(row.modified).format('HH:mm A')}</span>
               )}
            </TooltipComponent>
         ),
      },
   ];

   function onPageChange(e, pageNumber) {
      setCurrentPage(pageNumber);
   }

   return (
      <>
         <TableContainer>
            <TableContent>
               <div className="d-flex justify-content-between w-100 align-items-center">
                  {isLoading ? (
                     <Skeleton width="30%" height="20" />
                  ) : (
                     <div>
                        <h1> Loan Terms</h1>
                        <span className="value">{paginationData?.count || 'N/A'}</span>
                     </div>
                  )}
                  <Button
                     className="ms-3"
                     variant="primary"
                     onClick={() => setModalVisibility('add')}
                  >
                     Add New Term
                  </Button>
               </div>
            </TableContent>
            <SelectInput
               className="d-flex justify-content-between w-25 mb-4 align-items-center"
               onChange={(val) => setLoanterm(val.value)}
               label="Loan Tenure"
               options={loan_terms}
               defaultValue={loan_terms.value}
            />
            <TableComponent
               columns={columns}
               rows={tableData}
               isLoading={isLoading}
               onPageChange={onPageChange}
               count={Math.ceil(paginationData.count / 15)}
               page={page}
               onRowClick={onRowClick}
            />
         </TableContainer>

         {modalVisibility && (
            <AddLoanTerm
               open={modalVisibility}
               onClose={handleOnCloseAdd}
               term={term}
               loanterm={loanterm}
               isEdit={modalVisibility === 'edit'}
            />
         )}
      </>
   );
}

export default LoanTerm;
