import styled from 'styled-components';

export const InputFundAmountWrapper = styled.div`
   padding-bottom: 22px;

   .withdraw-header {
      display: flex;
      justify-content: center;
      position: relative;
      margin: 22px 0 44px 0;

      img {
         position: absolute;
         left: 0;
      }

      h3 {
         font-size: 20px;
         line-height: 18px;
         text-align: center;
         color: #04093f;
         font-weight: 700;
      }
   }

   .text-1 {
      color: #1354d3;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
   }

   .text-2 {
      color: #04093f;
      font-size: 15px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
   }

   .withdraw-amount {
      .extended-amount-input {
         margin-bottom: 60px;
      }
   }

   .select-account {
      display: flex;
      border-bottom: 1px solid #d9dae2;
      padding-bottom: 10px;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      margin-bottom: 41px;

      label {
         font-size: 14px;
         line-height: 12px;
         color: #82849f;
         margin-bottom: 0;
         cursor: pointer;
         text-transform: capitalize;
      }

      img {
         width: 10px;
      }
   }
`;
