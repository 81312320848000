/* eslint-disable react/prop-types */
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { sendMail } from 'services/business';
import Modal from 'components/modal';
import { Button } from 'components/button';
import { Spinner } from 'components/spinner';
import toast from 'react-hot-toast';

export default function Index({ open, onClose, rejectType, bizName, recipient }) {
   const [rejectMailLoading, setRejectMailLoading] = useState(false);
   const {
      register,
      handleSubmit,
      setValue,
      formState: { errors, isValid },
   } = useForm({
      mode: 'onChange',
   });

   const getMessage = (docType, message) => {
      let docSubject = '';
      let docMessage = '';
      switch (docType) {
         case 'is_identity_verified':
            docSubject = 'Government Issued ID Rejected';
            docMessage = `Your Government Issued ID has been rejected. Reason: ${message}`;
            break;
         case 'is_cac_cert_photo_verified':
            docSubject = `CAC Certificate For ${bizName} Rejected`;
            docMessage = `The CAC Certificate for ${bizName} has been rejected. Reason: ${message}`;
            break;
         case 'is_board_verified':
            docSubject = `Other Director Details Of ${bizName} Rejected`;
            docMessage = `The other director details of ${bizName} has been rejected. Reason: ${message}`;
            break;
         case 'is_address_verified':
            docSubject = 'Proof of Address Rejected';
            docMessage = `Your Proof of Address has been rejected. Reason: ${message}`;
            break;
         case 'is_tin_document_verified':
            docSubject = `Addtional CAC Documents Of ${bizName} For TIN Processing Rejected`;
            docMessage = `The Addtional CAC Documents of ${bizName} for TIN has been rejected. Reason: ${message}`;
            break;
         case 'is_tin_number_verified':
            docSubject = `TIN Number For ${bizName} Rejected`;
            docMessage = `The TIN Number for ${bizName} has been rejected. Reason: ${message}`;
            break;
         case 'is_director_match_verified':
            docSubject = `Business Verification For ${bizName} Failed`;
            docMessage =
               'Unfortunately we could not verify some of the details submitted. A member of our team will reach out to you shortly.';
            break;
         default:
            docSubject = '';
            docMessage = '';
      }
      return {
         docMessage,
         docSubject,
      };
   };

   const onSubmit = async (data) => {
      const { docMessage, docSubject } = getMessage(rejectType, data.message);
      const params = {
         email_address: [recipient],
         email_subject: docSubject,
         email_message: docMessage,
      };
      setRejectMailLoading(true);
      try {
         const { message } = await sendMail(params);
         setValue('message', '');
         onClose();
         setRejectMailLoading(false);
         toast.success(message);
      } catch (e) {
         toast.error(e.message);
         setRejectMailLoading(false);
      }
   };

   return (
      <div>
         <Modal
            title="Reason for Rejection"
            open={open}
            onClose={onClose}
            closeOnOverlayClick={false}
         >
            <form onSubmit={handleSubmit(onSubmit)}>
               <textarea
                  className="w-100 p-3"
                  rows="5"
                  {...register('message', { required: 'Please enter a valid reason' })}
               />
               {errors.message && <p className="text-danger">{errors?.message?.message}</p>}
               {rejectMailLoading ? (
                  <div style={{ width: '30px', float: 'right' }}>
                     <Spinner />
                  </div>
               ) : (
                  <div className="d-flex justify-content-end">
                     <Button
                        type="submit"
                        variant="primary"
                        disabled={(!isValid || errors.message) && true}
                     >
                        Send Mail
                     </Button>
                  </div>
               )}
            </form>
         </Modal>
      </div>
   );
}
