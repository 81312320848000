import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/modal';
import { Button } from 'components/button';
import DropzoneCSV from 'components/upload-csv';
import CloseIcon from 'assets/images/close-icon.svg';
import { uploadCSVAutodialer } from 'services/call-center';
import toast from 'react-hot-toast';

const UploadCSVModal = ({ open, onClose }) => {
   const [csvFile, setCsvFile] = useState(null);

   const handleFileDrop = (file) => {
      setCsvFile(file);
   };

   const handleRemoveFile = () => {
      setCsvFile(null);
   };

   const handleFileConversion = async () => {
      if (csvFile) {
         try {
            const formData = new FormData();
            formData.append('contacts', csvFile);
            const response = await uploadCSVAutodialer(formData);
            toast.success(response?.message || 'File uploaded successfully');
         } catch (error) {
            toast.error(error?.message || 'Error uploading CSV file');
         } finally {
            onClose();
         }
      }
   };

   return (
      <Modal open={open} onClose={onClose} center width="500px">
         <div>
            <h1 className="mb-4">Upload CSV</h1>
            <DropzoneCSV onFileDrop={handleFileDrop} />
            {csvFile && (
               <div
                  className="d-flex align-items-center mt-5 mx-4"
                  style={{
                     borderBottom: '1px solid #000',
                     marginLeft: 'auto',
                  }}
               >
                  <p className="mr-3">
                     <b>{csvFile.name}</b>
                  </p>
                  <div style={{ flexGrow: 1 }} />
                  <img
                     src={CloseIcon}
                     alt="Remove File"
                     onClick={handleRemoveFile}
                     style={{ cursor: 'pointer' }}
                  />
               </div>
            )}
            {csvFile && (
               <div className="col mt-4 text-center">
                  <Button variant="primary" onClick={handleFileConversion}>
                     Done
                  </Button>
               </div>
            )}
         </div>
      </Modal>
   );
};

UploadCSVModal.propTypes = {
   open: PropTypes.bool.isRequired,
   onClose: PropTypes.func.isRequired,
};

export default UploadCSVModal;
