import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { reActivateMemberAccountApi } from 'services/businessDetails';
import { Button } from 'components/button';
import { Input } from 'components/input';
import { SelectInput } from 'components/select';
import { InputWrapper } from 'styles/form';
import { hasValue, validateEmail } from 'utils/validations';

export default function ReActivateMemberAccount() {
   const [memberEmail, setMemberEmail] = useState('');
   const [businessId, setBusinessId] = useState('');
   const [loading, setLoading] = useState(false);
   const [reason, setReason] = useState('');

   const isValid = validateEmail(memberEmail) && hasValue(reason);

   const flags = [
      {
         label: 'None',
         value: '',
      },
      {
         label: 'OTP',
         value: 'multiple_failed_otp',
      },
      {
         label: 'PIN',
         value: 'multiple_failed_pin',
      },
      {
         label: 'LOGIN',
         value: 'multiple_failed_login',
      },
      {
         label: 'RESET',
         value: 'multiple_password_reset',
      },
   ];

   const reActivateAccount = async () => {
      setLoading(true);
      const payload = {
         email: memberEmail,
         businessId,
         action_type: reason.value,
      };

      try {
         const response = await reActivateMemberAccountApi(payload);
         toast.success(response.message || 'Reactivation Succesfull');
         setMemberEmail('');
         setBusinessId('');
         setReason('');
      } catch (e) {
         toast.error(e.message);
      }
      setLoading(false);
   };

   return (
      <div className="row align-items-end">
         <InputWrapper className="col-lg-4">
            <Input
               placeholder="Enter Member Email"
               label="Member Email"
               value={memberEmail}
               onChange={(e) => setMemberEmail(e.target.value)}
            />
         </InputWrapper>
         <InputWrapper className="col-lg-4">
            <Input
               placeholder="Enter Business ID"
               label="Business ID"
               value={businessId}
               onChange={(e) => setBusinessId(e.target.value)}
            />
         </InputWrapper>
         <InputWrapper className="col-lg-4">
            <SelectInput
               name="flag_type"
               label="Reason"
               value={reason}
               options={flags}
               onChange={(val) => setReason(val)}
            />
         </InputWrapper>
         <InputWrapper className="col-lg-4">
            <Button
               variant="primary"
               onClick={reActivateAccount}
               isLoading={loading}
               disabled={!isValid}
            >
               Reactivate
            </Button>
         </InputWrapper>
      </div>
   );
}
