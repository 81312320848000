import { useState } from 'react';
import { Input } from 'components/input';
import Modal from 'components/modal';
import { useHistory } from 'react-router-dom';
import { func, bool, string, objectOf } from 'prop-types';
import { InputWrapper } from 'styles/form';
import { Button } from 'components/button';

const SavingsFilterModal = ({
   open,
   onClose,
   setCurrentPage,
   filterData,
   setfilterData,
   setFilterPayload,
}) => {
   const history = useHistory();
   const [isLoading, setIsLoading] = useState(false);
   function onChange(name, data) {
      setfilterData({
         ...filterData,
         [name]: data.value,
      });
   }

   async function onSubmit() {
      try {
         setCurrentPage(() => 1);
         history.push(`${history.location.pathname}?page=1`);
         setIsLoading(true);
         setFilterPayload(filterData);
         onClose();
      } catch (err) {
         return err;
      } finally {
         setIsLoading(false);
      }
   }

   async function clearFilter() {
      try {
         setCurrentPage(1);
         setFilterPayload({});
         setfilterData({});
         onClose();
      } catch (err) {
         return err;
      }
   }

   return (
      <Modal open={open} onClose={onClose} title="Filters" center maxWidth="750px" maxHeight="100%">
         <form className="row">
            <div className="col-md-6 mb-4">
               <Input
                  placeholder="Start Date"
                  label="Start Date"
                  name="start_date"
                  type="date"
                  value={filterData?.start_date}
                  onChange={({ target }) => onChange('start_date', target)}
               />
            </div>
            <div className="col-md-6 mb-4">
               <Input
                  placeholder="End Date"
                  label="End Date"
                  name="end_date"
                  type="date"
                  value={filterData?.end_date}
                  onChange={({ target }) => onChange('end_date', target)}
               />
            </div>

            <InputWrapper
               style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
            >
               <div style={{ marginRight: '20px' }}>
                  <Button variant="secondary" type="button" onClick={clearFilter} fullWidth>
                     clear filter
                  </Button>
               </div>

               <div>
                  <Button
                     variant="primary"
                     type="button"
                     onClick={onSubmit}
                     isLoading={isLoading}
                     disabled={isLoading}
                     fullWidth
                  >
                     Apply
                  </Button>
               </div>
            </InputWrapper>
         </form>
      </Modal>
   );
};

export { SavingsFilterModal };

SavingsFilterModal.propTypes = {
   open: bool.isRequired,
   onClose: func.isRequired,
   filterData: objectOf(string).isRequired,
   setfilterData: func.isRequired,
   setCurrentPage: func.isRequired,
   setFilterPayload: func.isRequired,
};
