import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import Modal from 'components/modal';
import { InputWrapper } from 'styles/form';
import { SelectInput } from 'components/select';
import { Button } from 'components/button';
import Textarea from 'components/textarea';
import { businessSearch } from 'services/business';
import { getTicketCategories, createTicket } from 'services/ticketing';
import { AutoCompleteSelect } from 'components/autocomplete-input';
import { hasValue } from 'utils/validations';

export default function CreateTicket({ open, onClose, fetchAllTickets }) {
   const [selectedBusiness, setSelectedBusiness] = useState('');
   const [businessSearchResult, setBusinessSearchResult] = useState([]);
   const [isLoading, setIsLoading] = useState(false);
   const [isLoadingCategory, setIsLoadingCategory] = useState(false);
   const [categories, setCategories] = useState([]);
   const [category, setCategory] = useState('');
   const [message, setMessage] = useState('');
   const [creatingTicket, setCreatingTicket] = useState(false);

   const searchBusiness = async (event) => {
      setIsLoading(true);
      try {
         const response = await businessSearch(event.target.value);
         if (response.data) setBusinessSearchResult(response.data);
      } catch (e) {
         toast.error(e.message);
      }
      setIsLoading(false);
   };

   const fetchBusinessCategories = async () => {
      setIsLoadingCategory(true);
      try {
         const response = await getTicketCategories({
            page: 1,
         });
         if (response)
            setCategories(
               response?.data.map(({ category: categoryItem, id }) => ({
                  label: categoryItem,
                  value: id,
               })),
            );
      } catch (e) {
         toast.error(e.message);
      }
      setIsLoadingCategory(false);
   };

   useEffect(() => {
      fetchBusinessCategories();
   }, []);

   const submitTicket = async () => {
      const params = {
         issue_department: categories.find((item) => item.value === category)?.label,
         hbizaccount: selectedBusiness.biz_account_id,
         issue_type: 'private',
         category,
         message,
      };

      setCreatingTicket(true);

      try {
         const response = await createTicket(params);
         setCategory('');
         setMessage('');
         setSelectedBusiness('');
         toast.success(`A ticket has been created successfully for ${response.business_name}`);
         fetchAllTickets();
      } catch (e) {
         toast.error(e?.message || createTicket);
      }

      setCreatingTicket(false);
   };

   const isValid = hasValue(category) && hasValue(selectedBusiness) && hasValue(message);

   return (
      <Modal open={open} onClose={onClose} title="Create a Ticket">
         <form>
            <InputWrapper className="grid-2-2">
               <div>
                  <AutoCompleteSelect
                     onChange={(event, newValue) => {
                        setSelectedBusiness(newValue);
                     }}
                     value={selectedBusiness}
                     options={businessSearchResult}
                     getOptionLabel={(option) => option?.biz_name || ``}
                     label="Business Name"
                     loading={isLoading}
                     loadingText="Loading..."
                     inputOnchange={async (event) => {
                        if (event.target.value.length < 3) return null;
                        searchBusiness(event);
                     }}
                  />
               </div>

               <div>
                  <SelectInput
                     label="Ticket Category"
                     placeholder="Ticket Category"
                     loading={isLoadingCategory}
                     options={categories}
                     name="category"
                     onChange={(val) => setCategory(val.value)}
                     value={categories.filter((item) => item.value === category)}
                  />
               </div>
            </InputWrapper>

            <InputWrapper className="grid-2-2">
               <Textarea
                  label="Message"
                  value={message}
                  name="faq"
                  onChange={(e) => setMessage(e.target.value)}
               />
            </InputWrapper>

            <div className="d-flex justify-content-end pt-5">
               <Button
                  onClick={submitTicket}
                  isLoading={creatingTicket}
                  disabled={!isValid || creatingTicket}
               >
                  Create new ticket
               </Button>
            </div>
         </form>
      </Modal>
   );
}

CreateTicket.propTypes = {
   open: PropTypes.bool.isRequired,
   onClose: PropTypes.bool.isRequired,
   fetchAllTickets: PropTypes.func.isRequired,
};
