export const typeOptions = [
   {
      value: 'free_transfer',
      label: 'Free Transfer',
   },
   {
      value: 'biz_registration_discount',
      label: 'Business Registration Discount',
   },
   {
      value: 'free_account_upgrade',
      label: 'Free Account Upgrade',
   },
   {
      value: 'account_inflow',
      label: 'Account Inflow',
   },
   {
      value: 'biz_account_refer',
      label: 'Business Account Referral',
   },
];

export const promoStatus = [
   {
      value: 'active',
      label: 'Active',
   },
   {
      value: 'expired',
      label: 'Expired',
   },
];
