import React from 'react';
import PropTypes, { func, string } from 'prop-types';
import classNames from 'classnames';
import './style.css';

const Summary = ({
   currentFilter,
   setCurrentFilter,
   tabs,
   selectedYear,
   setSelectedYear,
   years,
}) => {
   return (
      <div className="chart-filter d-flex">
         {tabs?.map((el) => (
            <span
               className={classNames('d-flex justify-content-center align-items-center', {
                  active: currentFilter === el?.value,
               })}
               onClick={() => setCurrentFilter(el?.value)}
            >
               {el?.name}
            </span>
         ))}
         {currentFilter === 'monthly' && (
            <div className="date-picker-group d-flex justify-content-between align-items-center">
               <div>
                  <span className="mx-3">Filter by year:</span>
                  <select
                     value={selectedYear}
                     onChange={({ target }) => setSelectedYear(target.value)}
                  >
                     {years.map((item) => (
                        <option key={item} value={item}>
                           {item}
                        </option>
                     ))}
                  </select>
               </div>
            </div>
         )}
      </div>
   );
};

Summary.propTypes = {
   currentFilter: string.isRequired,
   setCurrentFilter: func.isRequired,
   tabs: PropTypes.arrayOf().isRequired,
   years: PropTypes.arrayOf().isRequired,
   selectedYear: string.isRequired,
   setSelectedYear: func.isRequired,
};

export default Summary;
