import { useEffect, useState } from 'react';
import { useAuth } from 'hooks/auth';
import moment from 'moment';
import getSymbolFromCurrency from 'currency-symbol-map';
import { can_view_international_transactions } from 'utils/user_actions';
import { hasPermission } from 'utils/user_access';
import { formatTag } from 'utils/tags';
import { Skeleton } from '@material-ui/lab';
import FilterListIcon from '@material-ui/icons/FilterList';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import { TableContainer, TableContent } from 'styles/table';
import { TooltipComponent } from 'components/tooltip';
import { TableComponent } from 'components/table';
import InboundTransactionDetails from 'components/TransactionModal/inbound-transactions';
import BusinessDetails from 'containers/business-details';
import InternationalTransactionsFilters from 'containers/filter-modal/international-transactions-filter';
import { FilterContainer } from 'pages/follow-up/follow-up.style';
import { getInboundInternationalTransactions } from 'services/international-transactions';

const initialFilter = {
   modified_at_after: moment().format('YYYY-MM-01'),
   modified_at_before: moment().format('YYYY-MM-DD'),
};

function InboundTransactionsDashboard() {
   const { currentUser } = useAuth();
   const { permissions } = currentUser?.access || {};
   const [tableData, setTableData] = useState([]);
   const [modalVisibility, setModalVisibility] = useState(false);
   const [bizAccount, setBizAccount] = useState('');
   const [isLoading, setIsLoading] = useState(false);
   const [paginationData, setPaginationData] = useState({});
   const [currentPage, setCurrentPage] = useState(1);
   const [transactionDetail, setTransactionDetail] = useState('');
   const [transactionModal, setTransactionModal] = useState(false);
   const [modalVisibilityFilter, setModalVisibilityFilter] = useState(false);
   const [filterData, setFilterData] = useState(initialFilter);
   const [filterPayload, setFilterPayload] = useState({});

   const onTransactionClick = (data) => {
      setTransactionModal(true);
      setTransactionDetail(data);
   };

   const openBusiness = (id) => {
      setBizAccount(id);
      setModalVisibility(true);
   };

   const columns = [
      {
         field: 'biz_name',
         width: 280,
         headerName: 'Business Name',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.hbizaccount?.biz_name || 'N/A'}>
               <span
                  style={{ cursor: 'pointer' }}
                  className="text-primary"
                  onClick={() => openBusiness(row?.hbizaccount?.id)}
               >
                  {row?.hbizaccount?.biz_name || 'N/A'}
               </span>
            </TooltipComponent>
         ),
      },
      {
         field: 'payout_internal_ref',
         width: 280,
         headerName: 'Transaction reference',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.payout_internal_ref || 'N/A'}>
               <span
                  style={{ cursor: 'pointer' }}
                  className="text-primary"
                  onClick={() => onTransactionClick(row)}
               >
                  {row?.payout_internal_ref || 'N/A'}
               </span>
            </TooltipComponent>
         ),
      },

      {
         field: 'amount',
         headerName: 'Amount Requested',
         renderCell: ({ row }) => (
            <span style={{ textTransform: 'capitalize' }}>
               {`${getSymbolFromCurrency(row?.currency)}${parseFloat(
                  row?.amount || 'N/A',
               ).toLocaleString()}`}
            </span>
         ),
      },
      {
         field: 'payin_status',
         headerName: 'Partner Status',
         renderCell: ({ row }) => {
            return (
               <TooltipComponent title={row?.payin_status}>
                  {formatTag(row?.payin_status, row?.payin_status)}
               </TooltipComponent>
            );
         },
      },
      {
         field: 'payout_status',
         headerName: 'Member Status',
         renderCell: ({ row }) => {
            return (
               <TooltipComponent title={row?.payout_status}>
                  {formatTag(row?.payout_status, row?.payout_status)}
               </TooltipComponent>
            );
         },
      },
      {
         field: 'created_at',
         headerName: 'Date',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.created_at}>
               {moment(row?.created_at).format('DD/MM/YYYY')} |{' '}
               <span style={{ opacity: '.5' }}>{moment(row?.created_at).format('HH:mm A')}</span>
            </TooltipComponent>
         ),
      },
   ];

   const fetchTransactions = async (pageNumber) => {
      setIsLoading(true);
      Promise.all([
         getInboundInternationalTransactions({ page: pageNumber || currentPage, ...filterPayload }),
      ])
         .then(([businessAccountsData]) => {
            setTableData(businessAccountsData?.results);
            setPaginationData(businessAccountsData);
         })
         .finally(() => {
            setIsLoading(false);
         });
   };

   useEffect(() => {
      fetchTransactions();
   }, [currentPage, filterPayload]);

   function onPageChange(e, pageNumber) {
      setCurrentPage(pageNumber);
   }

   return (
      <>
         {hasPermission(permissions, can_view_international_transactions) ? (
            <div>
               <TableContainer>
                  <TableContent>
                     {isLoading ? (
                        <Skeleton width="30%" height="20" />
                     ) : (
                        <div>
                           <h1> All Transactions</h1>
                           <span className="value">{paginationData?.count || 'N/A'}</span>
                        </div>
                     )}
                     <div className="d-flex align-items-center">
                        <FilterContainer className="me-3">
                           Export Data
                           <ImportExportIcon style={{ marginLeft: '10px' }} />
                        </FilterContainer>
                        <FilterContainer onClick={() => setModalVisibilityFilter(true)}>
                           Filter By <FilterListIcon style={{ marginLeft: '10px' }} />
                        </FilterContainer>
                     </div>
                  </TableContent>
                  <TableComponent
                     columns={columns}
                     rows={tableData}
                     isLoading={isLoading}
                     onPageChange={onPageChange}
                     count={Math.ceil(paginationData.count / 15)}
                  />
               </TableContainer>
               {transactionModal && (
                  <InboundTransactionDetails
                     open={transactionModal}
                     onClose={() => setTransactionModal(false)}
                     transactionDetail={transactionDetail}
                     fetchTransactions={fetchTransactions}
                  />
               )}
               {modalVisibilityFilter && (
                  <InternationalTransactionsFilters
                     open={modalVisibilityFilter}
                     onClose={() => setModalVisibilityFilter(false)}
                     setCurrentPage={setCurrentPage}
                     filterData={filterData}
                     setFilterData={setFilterData}
                     setFilterPayload={setFilterPayload}
                     type="inbound"
                  />
               )}
               {modalVisibility && (
                  <BusinessDetails
                     businessAccountId={bizAccount}
                     open={modalVisibility}
                     onClose={() => setModalVisibility(false)}
                  />
               )}
            </div>
         ) : (
            <div>
               <p>You do not have required permission : can_view_international_transactions </p>
            </div>
         )}
      </>
   );
}

export default InboundTransactionsDashboard;
