import { node } from 'prop-types';
import { createContext, useReducer } from 'react';

const initialState = {
   activeTab: 'overview',
};

export const BizDetailsModalContext = createContext(initialState);

function reducer(state, action) {
   switch (action.type) {
      case 'SET_ACTIVE_TAB':
         return {
            ...state,
            activeTab: action.payload,
         };
      default: {
         throw new Error(`Unhandled action type: ${action.type}`);
      }
   }
}

function BizDetailsModalProvider({ children }) {
   const [state, dispatch] = useReducer(reducer, initialState);

   return (
      <BizDetailsModalContext.Provider value={{ state, dispatch }}>
         {children}
      </BizDetailsModalContext.Provider>
   );
}

BizDetailsModalProvider.propTypes = {
   children: node.isRequired,
};

export { BizDetailsModalProvider };
