import Styled from 'styled-components';

export const AccountDetailsContainer = Styled.div`
    background:white;
    .pending-document-preview{
        height:450px;
        margin-bottom:30px;
        cursor:pointer;
    }
    .pending-document-preview img{
        height:100%;
        width:100%;
        object-fit:cover;
    }
    .user-profile{
        margin-bottom:48px;
    }
    .user-profile .user-image{
        width: 112px;
        height: 112px;
        background:#e3e3e3;
        margin-right:16px;
        border-radius:50%;
    }
    .user-profile .user-name{
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #1354D3;
        margin-bottom:8px;
    }
    .user-profile .small-text{
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        color: #3A434B;
    }
    .business-details{

    }
    .business-details h3, .current-document-name, .other-directors-details h3{
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        color: #24292E;
        border-bottom:1px solid #BAC2C9;
        padding-bottom:16px;
        margin-bottom:24px;
    }
    .other-directors-details h3{
        font-size: 16px;
        border:none;
    }
    .business-details .business-details-item{
        margin-bottom:32px;
    }
    .business-details .business-details-item h6{
        margin-bottom:8px;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        color: #81909D;
    }
    .business-details .business-details-item p{
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        color: #3A434B;
        margin-bottom:0;
        text-transform:capitalize;
        overflow-wrap: break-word;
    }
    .business-details .business-details-item span{
        margin-right: 8%;
    }
    .business-details .business-details-item #biz-state{
        width: 100%;
        margin-right:4%;
    }
    .business-details .business-details-item input{
        border:none;
        border-bottom:1px solid #e3e3e3;
        outline:#3A434B;
        /* margin-right: 10px; */
        // width: 45%;
    }
    .business-details .business-details-item #update-biz-address img{
        width: 20px;
    }
    .business-details .business-details-item #update-biz-address.disabled{
        opacity: .5;
    }
    .update-biz-address{
        border:none;
        border-radius:4px;
        font-size:14px;
    }
`;

export const StatusTag = Styled.div`
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    border-radius:4px;
    padding:4px 8px;
    text-align:center;
    width:fit-content;
`;
export const SuccessTag = Styled(StatusTag)`
    background: #EAFBF1;
    color:#41D87D !important;
`;

export const ErrorTag = Styled(StatusTag)`
    background: #FCE9E9;
    color:#E43535 !important;
`;

export const PendingTag = Styled(StatusTag)`
    background:#FDF2E7;
    color: #F08829 !important;
`;

export const DefaultTag = Styled(StatusTag)`
    background: #e3f0fd;
    color: #0c6dfd !important;
`;
