import { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import { Button } from 'components/button';
import { confirm } from 'components/confirm-box';
import { Spinner } from 'components/spinner';
import { NumberInput } from 'components/input';
import { getInterestRate, updateInterestRate } from 'services/savings';

export default function SavingsTerms() {
   const [loading, setLoading] = useState(false);
   const [interest, setInterest] = useState(0);
   const [interestSetting, setInterestSetting] = useState(null);

   const fetchInterestRate = async () => {
      setLoading(true);
      try {
         const response = await getInterestRate();
         const setting = response.data?.find((item) => item.name === 'BANKING__INTEREST_RATE');
         setInterestSetting(setting);
         setInterest(setting?.value?.interest_rate);
         setLoading(false);
      } catch (e) {
         toast.error(e?.message);
         setLoading(false);
      }
   };

   useEffect(() => {
      fetchInterestRate();
   }, []);

   const confirmSetting = () => {
      confirm({
         confirmText: 'Are you sure you want to update this setting?',
         onConfirm: async () => {
            setLoading(true);
            try {
               const payload = {
                  interest_rate: interest,
               };
               const response = await updateInterestRate(interestSetting.id, payload);
               toast.success(response.message || 'Operation was successful');
               fetchInterestRate();
            } catch (e) {
               toast.error(e?.message);
            }
            setLoading(false);
         },
      });
   };

   const valid = interest && interest > 0 && interest !== interestSetting?.value.interest_rate;

   return (
      <div>
         {loading ? (
            <div>
               <Spinner />
            </div>
         ) : (
            <div className="border-bottom pb-3">
               <h3 className="mb-5 col-lg-4">Savings Term</h3>
               <div className="mb-4 col-lg-3">
                  <div className="d-flex align-items-center mb-4">
                     <NumberInput
                        prefix="hidden"
                        suffix="%"
                        name="interest"
                        label="Interest Rate"
                        placeholder="Enter Interest Rate (%)"
                        value={interest}
                        onChange={(val) => setInterest(val.floatValue)}
                     />
                  </div>
               </div>

               <div className="col-lg-3">
                  <Button
                     className="w-100"
                     onClick={confirmSetting}
                     isLoading={loading}
                     disabled={loading || !valid}
                  >
                     Confirm Interest Rate
                  </Button>
               </div>
            </div>
         )}
      </div>
   );
}
