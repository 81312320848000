export const formatStatsTime = (statTime) => {
   if (statTime) {
      const { avg_days, avg_hours, avg_minutes, avg_seconds } = statTime;
      return `${avg_days > 0 ? `${avg_days}D ` : ''}${avg_hours}h : ${avg_minutes}m : ${parseInt(
         avg_seconds,
         10,
      )}s`;
   }
   return 'N/A';
};

export const groupObject = (obj, key) => {
   const result = obj.reduce((r, a) => {
      // eslint-disable-next-line no-param-reassign
      r[a[key]] = r[a[key]] || [];
      r[a[key]].push(a);
      return r;
   }, Object.create(null));

   return result;
};

function escapeRegExp(string) {
   return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

export function replaceAll(str, find, replace) {
   return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
}

export const formatPayloadFromFilter = (filterList, data) => {
   let payload = {};

   const selectValues = filterList
      .filter((item) => item.type === 'select')
      ?.map((item) => item.key);

   Object.keys(data)
      ?.filter((item) => data[item])
      ?.forEach((item) => {
         payload = {
            ...payload,
            [item]: selectValues.includes(item) ? data[item].value : data[item],
         };
      });

   return payload;
};
