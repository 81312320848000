import Styled from 'styled-components';

export const StatusTag = Styled.div`
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    border-radius:4px;
    padding:4px 8px;
    text-align:center;
    width:fit-content;
`;

export const SuccessTag = Styled(StatusTag)`
    background: #EAFBF1;
    color:#41D87D !important;
`;

export const ErrorTag = Styled(StatusTag)`
    background: #FCE9E9;
    color:#E43535 !important;
`;

export const PendingTag = Styled(StatusTag)`
    background:#FDF2E7;
    color: #F08829 !important;
`;

export const DefaultTag = Styled(StatusTag)`
    background: #e3f0fd;
    color: #0c6dfd !important;
`;

export const formatTag = (type, text) => {
   switch (type) {
      case type === '':
         return <DefaultTag>{text || 'Not Uploaded'}</DefaultTag>;

      case 'pending':
      case 'archived':
         return <PendingTag>{text || 'Pending'} </PendingTag>;

      case 'accepted':
      case 'active':
         return <SuccessTag>{text || 'Verified'}</SuccessTag>;

      case 'rejected':
      case 'terminated':
         return <ErrorTag>{text || 'Rejected'}</ErrorTag>;

      default:
         return <DefaultTag>{text || 'Not Uploaded'}</DefaultTag>;
   }
};
