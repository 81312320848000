import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

export const SelectInputComponentStyle = styled.div`
   .input-container {
      height: 4.8rem;
      position: relative;
   }
   .MuiOutlinedInput-notchedOutline {
      height: 4.8rem;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
   }
   .MuiFormControl-marginNormal {
      margin: 0;
      padding: 0;
      height: 4.8rem;
   }
   .MuiAutocomplete-root {
      height: 100%;
   }
   .MuiInputBase-input {
      font-size: 16px;
      font-weight: 400;
      color: #3a434b;
      font-family: 'Mark OT', sans-serif;
   }
   .MuiFormLabel-root {
      display: none;
   }
   .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] .MuiAutocomplete-input:first-child {
      transform: translateY(-5px);
   }
`;

export const Label = styled.label`
   pointer-events: none;
   color: #435a70;
   white-space: nowrap;
   margin-bottom: 0.3rem;
   font-size: 1.4rem;
   display: block;
   font-weight: 400;
   color: ${({ theme, error }) => (error ? theme.colors?.redError : theme.colors?.black)};
`;

export const ErrorP = styled(motion.p)`
   margin: 0.1rem 0 0 0;
   color: ${({ theme }) => theme.colors?.redError};
   font-size: 1.4rem;
   letter-spacing: -0.01rem;
   font-weight: 400;

   ${({ easyFlow }) =>
      easyFlow &&
      css`
         margin: 0;
         position: absolute;
         left: 65px;
         bottom: -21px;
         font-size: 11.5px;
      `}
`;

export const ListboxComponent = styled('ul')(() => ({
   width: '100%',
   margin: 0,
   padding: 0,
   zIndex: 1,
   fontSize: '14px',
   fontFamily: 'Mark OT , sans-serif',
   position: 'absolute',
   listStyle: 'none',
   backgroundColor: 'white',
   overflow: 'auto',
   maxHeight: 200,
   border: '1px solid rgba(0,0,0,.25)',
   '& li[data-focus="true"]': {
      backgroundColor: '#4a8df6',
      color: 'white',
      cursor: 'pointer',
   },
   '& li:active': {
      backgroundColor: '#2977f5',
      color: 'white',
   },
}));
