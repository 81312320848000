import { useEffect, useState } from 'react';
import { Skeleton } from '@material-ui/lab';
import FilterListIcon from '@material-ui/icons/FilterList';
import { FilterContainer } from 'pages/follow-up/follow-up.style';
import { getMarketingData } from 'services/promo-code';
import { toast } from 'react-hot-toast';
import moment from 'moment';
import { StatsCards } from 'components/stats-cards';
import { MarketingDataFilter } from '../filter-marketing-data';

export default function MarketingData() {
   const [marketingData, setMarketingData] = useState({});
   const [socialsData, setSocialsData] = useState({});
   const [modalVisibilityFilter, setModalVisibilityFilter] = useState(false);
   const [isLoading, setIsLoading] = useState(false);
   const [filterDate, setFilterDate] = useState({
      start_date: moment()?.subtract(1, 'week')?.format('YYYY-MM-DD'),
      end_date: moment()?.format('YYYY-MM-DD'),
   });

   const fetchMarketingData = async () => {
      setIsLoading(true);
      try {
         const result = await getMarketingData(filterDate);
         const state = {};

         result?.spread_name_count.forEach((item) => {
            const { spread, spread__count } = item;
            state[spread] = spread__count;
         });
         setSocialsData(state);
         setMarketingData({
            account_created_count: result?.account_created_count,
            approved_count: result?.approved_count,
            pre_approved_count: result?.pre_approved_count,
            biz_activated_count: result?.biz_activated_count,
            spread_count: result?.spread_count,
         });
      } catch (err) {
         toast.error(err?.message || 'Cannot fetch the data');
      }
      setIsLoading(false);
   };

   useEffect(() => {
      fetchMarketingData();
   }, [filterDate]);

   return (
      <div>
         {isLoading ? (
            <Skeleton width="30%" height="20" />
         ) : (
            <div className="d-flex justify-content-between align-items-center pb-5">
               <h1>Marketing Data</h1>
               <FilterContainer onClick={() => setModalVisibilityFilter(true)}>
                  Filter By <FilterListIcon style={{ marginLeft: '10px' }} />
               </FilterContainer>
            </div>
         )}
         <div className="d-flex justify-content-between flex-row">
            <StatsCards data={marketingData} />
         </div>
         <div>
            <StatsCards data={socialsData} />
         </div>

         {modalVisibilityFilter && (
            <MarketingDataFilter
               open={modalVisibilityFilter}
               onClose={() => setModalVisibilityFilter(false)}
               setFilterDate={setFilterDate}
               filterDate={filterDate}
            />
         )}
      </div>
   );
}
