import { HeadingOne } from 'styles/typography';
import LoggedInDashboard from 'components/logged-in-dashboard';
import Accounts from './features/accounts';

const RegisteredAccounts = () => {
   return (
      <LoggedInDashboard>
         <HeadingOne className="mb-5"> Business Accounts</HeadingOne>
         <Accounts />
      </LoggedInDashboard>
   );
};

export default RegisteredAccounts;
