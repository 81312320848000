/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import { Skeleton } from '@material-ui/lab';
import { MoreHoriz } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import { TableContainer, TableContent } from 'styles/table';
import { TableComponent } from 'components/table';
import { Input } from 'components/input';
import { TooltipComponent } from 'components/tooltip';
import { Button } from 'components/button';
import { Status } from 'styles/utils';
import { hasValue } from 'utils/validations';
import { MenuComponent } from 'components/menu';
import { getAllPromoCodes } from 'services/promo-code';
import AddPromoCode from '../add-promo-code';
import PromoDetails from '../promo-details';

export default function PromoCodeDashboard() {
   const { search } = useLocation();
   const page = new URLSearchParams(search).get('page');
   const [tableData, setTableData] = useState([]);
   const [promoCode, setPromoCode] = useState('');
   const [createCodeVisibilty, setCreateCodeVisibility] = useState(false);
   const [promo, setPromo] = useState(null);
   const [isLoading, setIsLoading] = useState(false);
   const [paginationData, setPaginationData] = useState({});
   const [currentPage, setCurrentPage] = useState(page);
   const [detailsModalVisibility, setDetailsModalVisibility] = useState(false);

   const options = ['Edit'];

   const [anchorEl, setAnchorEl] = useState(null);
   const open = Boolean(anchorEl);

   const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
   };

   const handleClose = () => {
      setAnchorEl(null);
   };

   function onRowClick({ row }) {
      setPromo(row);
   }

   const fetchPromoCodes = async (pageNumber) => {
      setIsLoading(true);
      setTableData([]);
      Promise.all([getAllPromoCodes({ page: pageNumber || currentPage })])
         .then(([promoCodeData]) => {
            setTableData(promoCodeData?.data);
            setPaginationData(promoCodeData);
         })
         .finally(() => {
            setIsLoading(false);
         });
   };

   useEffect(() => {
      fetchPromoCodes();
   }, [currentPage]);

   function onPageChange(e, pageNumber) {
      setCurrentPage(pageNumber);
   }

   const handleOpenDetailsModal = () => {
      setDetailsModalVisibility(true);
   };

   const columns = [
      {
         field: '',
         width: 0,
         renderCell: () => (
            <div>
               <IconButton
                  aria-label="more"
                  aria-controls="long-menu"
                  aria-haspopup="true"
                  onClick={handleClick}
               >
                  <MoreHoriz />
               </IconButton>
               <MenuComponent
                  anchorEl={anchorEl}
                  handleClose={handleClose}
                  open={open}
                  options={options}
                  optionsFns={[() => setCreateCodeVisibility('edit')]}
               />
            </div>
         ),
      },
      {
         field: 'prospa_code',
         headerName: 'Promo Code',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.prospa_code}>
               <span
                  style={{ cursor: 'pointer' }}
                  className="text-primary"
                  onClick={() => handleOpenDetailsModal()}
               >
                  {row?.prospa_code}
               </span>
            </TooltipComponent>
         ),
      },
      {
         field: 'promo_id',
         width: 150,
         headerName: 'Promo Id',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.promo_id}>{row?.promo_id}</TooltipComponent>
         ),
      },
      {
         field: 'promo_point',
         width: 350,
         headerName: 'Promo Point',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.promo_point}>{row?.promo_point}</TooltipComponent>
         ),
      },
      {
         field: 'promo_status',
         width: 150,
         headerName: 'Status',
         renderCell: ({ row }) => <Status status={row?.promo_status}>{row?.promo_status}</Status>,
      },
      {
         field: 'pub_date',
         headerName: 'Date Created',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.pub_date}>
               {moment(row?.pub_date).format('DD MMM, YYYY')} |{' '}
               <span style={{ opacity: '.5' }}>{moment(row?.pub_date).format('HH:mm A')}</span>
            </TooltipComponent>
         ),
      },
   ];

   const handleGetUsage = () => {
      setPromo({ prospa_code: promoCode });
      setDetailsModalVisibility(true);
   };

   const validUsageInput = hasValue(promoCode);

   return (
      <>
         <div className="row flex-end">
            <div className="col-lg-4">
               <Input
                  placeholder="Enter Promo Code"
                  label="Promo Code"
                  name="start_date"
                  type="text"
                  value={promoCode}
                  onChange={({ target }) => setPromoCode(target.value)}
               />
            </div>

            <div className="col-lg-4 d-flex align-items-end">
               <div>
                  <Button
                     variant="primary"
                     type="button"
                     onClick={handleGetUsage}
                     disabled={!validUsageInput}
                  >
                     Get Code Usage
                  </Button>
               </div>
            </div>
         </div>
         <TableContainer>
            <TableContent>
               {isLoading ? (
                  <Skeleton width="30%" height="20" />
               ) : (
                  <div>
                     <h1> All Promo Codes</h1>
                     <span className="value">{paginationData?.count || 'N/A'}</span>
                  </div>
               )}
               <div className="d-flex justify-content-between" />

               <div style={{ marginLeft: '10px' }}>
                  <Button onClick={() => setCreateCodeVisibility('new')}>Create New Code</Button>
               </div>
            </TableContent>
            <TableComponent
               columns={columns}
               rows={tableData}
               isLoading={isLoading}
               onPageChange={onPageChange}
               count={Math.ceil(paginationData.count / 15)}
               onRowClick={onRowClick}
            />
         </TableContainer>

         {createCodeVisibilty && (
            <AddPromoCode
               open={createCodeVisibilty}
               isEdit={createCodeVisibilty === 'edit'}
               promo={promo}
               fetchPromoCodes={fetchPromoCodes}
               onClose={() => setCreateCodeVisibility(false)}
            />
         )}

         {detailsModalVisibility && (
            <PromoDetails
               open={detailsModalVisibility}
               onClose={() => setDetailsModalVisibility(false)}
               promo={promo}
            />
         )}
      </>
   );
}
