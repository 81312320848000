/* eslint-disable react/forbid-prop-types */
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import PropTypes, { object } from 'prop-types';
import { InputWrapper } from 'styles/form';
import { confirm } from 'components/confirm-box';
import Modal from 'components/modal';
import { Button } from 'components/button';
import { SelectInput } from 'components/select';
import { changeAdminRole } from 'services/admin';

export const ChangeRoleModal = ({
   open,
   onClose,
   adminDetails,
   fetchSingleAdminStats,
   managerRoles,
}) => {
   const { prospa_admin_id } = adminDetails;
   const [changingRole, setChangingRole] = useState(false);
   const [role, setRole] = useState(null);

   useEffect(() => {
      if (adminDetails) setRole(adminDetails?.role_type);
   }, [adminDetails]);

   const handleRoleChange = async () => {
      setChangingRole(true);
      const payload = {
         prospa_admin_id,
         new_user_role: role?.value,
      };
      try {
         const res = await changeAdminRole(payload);
         toast.success(res?.message || 'Action Successful');
         setChangingRole(false);
         fetchSingleAdminStats();
         onClose();
      } catch (e) {
         setChangingRole(false);
         toast.error(e?.message || 'Something went wrong');
      }
   };

   const handleConfirm = () => {
      confirm({
         confirmText: 'Are you sure want to change the role of the manager?',
         isLoading: false,
         onConfirm: async () => handleRoleChange(),
      });
   };

   return (
      <Modal open={open} onClose={onClose} closeOnOverlayClick={false}>
         <InputWrapper className="grid-2-2">
            <SelectInput
               name="role"
               label="Select the role you want to change to"
               defaultValue={managerRoles.filter((item) => item.value === role)}
               options={managerRoles}
               onChange={(val) => setRole(val)}
            />
         </InputWrapper>
         <div className="d-flex justify-content-end">
            <Button onClick={handleConfirm} isLoading={changingRole}>
               Confirm
            </Button>
         </div>
      </Modal>
   );
};
ChangeRoleModal.propTypes = {
   open: PropTypes.bool.isRequired,
   onClose: PropTypes.func.isRequired,
   adminDetails: PropTypes.shape({
      prospa_admin_id: PropTypes.number.isRequired,
      role_type: PropTypes.string.isRequired,
   }).isRequired,
   fetchSingleAdminStats: PropTypes.func.isRequired,
   managerRoles: PropTypes.arrayOf(object).isRequired,
};
