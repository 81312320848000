import React, { useEffect, useState } from 'react';
import PropTypes, { func, string } from 'prop-types';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
   TopSection,
   AvatarWrapper,
   Name,
   Info,
   UserProfile,
   OverviewWrapper,
   SectionTitle,
   InfoGroup,
   Label,
   Value,
   OverviewSection,
} from 'styles/layout';
import Avatar from 'assets/images/user.svg';
import Whatsapp from 'assets/images/whatsapp-icon.png';
import Phone from 'assets/images/phone.png';
import { Input, NumberInput } from 'components/input';
import {
   createManagerPublicProfile,
   updateManagerPublicProfile,
   getManagerPublicProfile,
} from 'services/admin';
import { Button } from 'components/button';
import { toast } from 'react-hot-toast';
import { Spinner } from 'components/spinner';

export const PublicProfile = ({ prospaManagerId }) => {
   const [loading, setLoading] = useState(false);
   const [managerDetails, setManagerDetails] = useState(null);
   const [updatingProfilePicture, setUpdatingProfilePicture] = useState(false);
   const [fileSelected, setFileSelected] = useState(null);
   const userImage = managerDetails?.profile_picture || Avatar;

   const defaultValues = {
      first_name: managerDetails?.first_name || '',
      last_name: managerDetails?.last_name || '',
      email: managerDetails?.email || '',
      whatsapp_number: managerDetails?.whatsapp_number || '',
      phone_number: managerDetails?.phone_number || '',
   };

   const schema = yup.object().shape({
      first_name: yup.string().required(),
      last_name: yup.string().required(),
      email: yup.string().required(),
      whatsapp_number: yup.string().required('Please enter a valid phone number'),
      phone_number: yup.string().required('Please enter a valid phone number'),
   });

   const fetchPublicProfile = async () => {
      setLoading(true);
      try {
         const res = await getManagerPublicProfile(prospaManagerId);
         setManagerDetails(res);
      } catch (e) {
         toast.error(e?.message || 'Public Profile does not exist, create a New one!');
      }
      setLoading(false);
   };

   useEffect(() => {
      fetchPublicProfile();
   }, []);

   const onSubmit = async (data) => {
      setLoading(true);
      try {
         if (!managerDetails) {
            const { phone_number: phone, whatsapp_number: whatsapp, ...rest } = data;
            const payload = {
               ...rest,
               phone_number: `+${phone}`,
               whatsapp_number: `+${whatsapp}`,
               user_id: prospaManagerId,
            };
            const res = await createManagerPublicProfile(payload);
            toast.success(res?.message || 'Profile has been successfully created');
         } else {
            const { phone_number: phone, whatsapp_number: whatsapp, ...rest } = data;
            const payload = {
               ...rest,
               phone_number: `+${phone}`,
               whatsapp_number: `+${whatsapp}`,
               user_id: prospaManagerId,
            };
            const res = await updateManagerPublicProfile(payload, prospaManagerId);
            toast.success(res?.message || 'Profile has been successfully updated');
         }
         fetchPublicProfile();
      } catch (e) {
         toast.error(e?.message);
      }
      setLoading(false);
   };

   const handleProfilePictureUpdate = async () => {
      setUpdatingProfilePicture(true);
      try {
         const formData = new FormData();
         formData.append('profile_picture', fileSelected);
         formData.append('user_id', prospaManagerId);

         if (!managerDetails) {
            const res = await createManagerPublicProfile(formData);
            toast.success(res?.message || 'Profile picture has been successfully updated');
         } else {
            const res = await updateManagerPublicProfile(formData, prospaManagerId);
            toast.success(res?.message || 'Profile picture has been successfully updated');
         }
         fetchPublicProfile();
      } catch (e) {
         toast.error(e?.message);
      }
      setUpdatingProfilePicture(false);
   };

   useEffect(() => {
      if (fileSelected) {
         handleProfilePictureUpdate();
      }
   }, [fileSelected]);

   const {
      register,
      handleSubmit,
      watch,
      formState: { isValid, errors },
      setValue,
      trigger,
   } = useForm({
      defaultValues,
      mode: 'onChange',
      resolver: yupResolver(schema),
      shouldFocusError: true,
   });

   useEffect(() => {
      if (managerDetails) {
         setValue('first_name', managerDetails?.first_name || '');
         setValue('last_name', managerDetails?.last_name || '');
         setValue('email', managerDetails?.email || '');
         setValue('whatsapp_number', managerDetails?.whatsapp_number || '');
         setValue('phone_number', managerDetails?.phone_number || '');
      }
   }, [managerDetails, setValue]);

   useEffect(() => {
      register('first_name');
      register('last_name');
      register('email');
      register('whatsapp_number');
      register('phone_number');
   }, []);

   const first_name = watch('first_name');
   const last_name = watch('last_name');
   const email = watch('email');
   const whatsapp_number = watch('whatsapp_number');
   const phone_number = watch('phone_number');

   const detailsUnchanged = () =>
      managerDetails?.first_name === first_name &&
      managerDetails?.last_name === last_name &&
      managerDetails?.email === email &&
      managerDetails?.phone_number === phone_number &&
      managerDetails?.whatsapp_number === whatsapp_number;

   function handleFileSelect(e) {
      const selectedFile = e.target.files[0];

      if (selectedFile) {
         const maxSizeInBytes = 1024 * 1024 * 10;

         if (selectedFile.size <= maxSizeInBytes) {
            setFileSelected(selectedFile);
         } else {
            toast.error('File size exceeds the allowed limit');
            e.target.value = null;
         }
      }
   }

   return (
      <>
         {loading ? (
            <Spinner width="30%" height="20" />
         ) : (
            <OverviewWrapper>
               <TopSection>
                  <UserProfile>
                     {updatingProfilePicture ? (
                        <Spinner />
                     ) : (
                        <div>
                           <AvatarWrapper>
                              <img src={userImage} alt="account-holder-avatar" />
                           </AvatarWrapper>
                           <label htmlFor="upload-image">
                              <input
                                 id="upload-image"
                                 style={{ display: 'none' }}
                                 type="file"
                                 accept="image/*"
                                 onChange={(e) => handleFileSelect(e)}
                              />

                              <div className="mt-3">
                                 <p
                                    className="mt-2 ms-1 text-primary"
                                    style={{ cursor: 'pointer', textDecoration: 'underline' }}
                                 >
                                    Upload Image
                                 </p>
                              </div>
                           </label>
                        </div>
                     )}
                     {managerDetails && (
                        <div>
                           <Name className="mb-2">
                              {managerDetails?.first_name} {managerDetails?.last_name}
                           </Name>
                           <Info>
                              <b>{managerDetails?.email}</b>
                           </Info>
                           <div className="d-flex gap-3 mt-4">
                              <div className="d-flex">
                                 <img
                                    style={{ height: '30px', marginRight: '10px' }}
                                    className="mt-3"
                                    src={Phone}
                                    alt="phone"
                                 />
                                 <InfoGroup>
                                    <Label>Phone Number</Label>
                                    <Value>{managerDetails?.phone_number}</Value>
                                 </InfoGroup>
                              </div>
                              <div className="d-flex">
                                 <img
                                    style={{ height: '30px', marginRight: '10px' }}
                                    className="mt-3"
                                    src={Whatsapp}
                                    alt="phone"
                                 />
                                 <InfoGroup>
                                    <Label>Whatsapp Number</Label>
                                    <Value>{managerDetails?.whatsapp_number}</Value>
                                 </InfoGroup>
                              </div>
                           </div>
                        </div>
                     )}
                  </UserProfile>
               </TopSection>
               <div className="mt-5">
                  <SectionTitle>Account Manager Details</SectionTitle>
               </div>
               <form onSubmit={handleSubmit(onSubmit)} className="row">
                  <OverviewSection>
                     <InfoGroup>
                        <Label>First Name</Label>
                        <Input
                           name={first_name}
                           placeholder="First Name"
                           className="overview-input"
                           value={first_name}
                           {...register('first_name')}
                        />
                        {errors.first_name && (
                           <p className="text-danger input-error-message">
                              {errors.first_name.message}
                           </p>
                        )}
                     </InfoGroup>
                     <InfoGroup>
                        <Label>Last Name</Label>
                        <Input
                           name={last_name}
                           placeholder="Last Name"
                           className="overview-input"
                           value={last_name}
                           {...register('last_name')}
                        />
                        {errors.last_name && (
                           <p className="text-danger input-error-message">
                              {errors.last_name.message}
                           </p>
                        )}
                     </InfoGroup>
                     <InfoGroup>
                        <Label>Email</Label>
                        <Input
                           name={email}
                           placeholder="Email"
                           className="overview-input"
                           value={email}
                           {...register('email')}
                        />
                        {errors.email && (
                           <p className="text-danger input-error-message">{errors.email.message}</p>
                        )}
                     </InfoGroup>

                     <InfoGroup>
                        <Label>Whatsapp Number</Label>
                        <NumberInput
                           name="rep_phone"
                           prefix="+234"
                           className="overview-input"
                           format="+### ### ### ####"
                           value={whatsapp_number}
                           onChange={(val) => {
                              setValue('whatsapp_number', val.value);
                              trigger('whatsapp_number');
                           }}
                        />
                        {errors.whatsapp_number && (
                           <p className="text-danger input-error-message">
                              {errors.whatsapp_number.message}
                           </p>
                        )}
                     </InfoGroup>

                     <InfoGroup>
                        <Label>Phone Number</Label>
                        <NumberInput
                           name="rep_phone"
                           prefix="+234"
                           className="overview-input"
                           format="+### ### ### ####"
                           value={phone_number}
                           onChange={(val) => {
                              setValue('phone_number', val.value);
                              trigger('phone_number');
                           }}
                        />
                        {errors.phone_number && (
                           <p className="text-danger input-error-message">
                              {errors.phone_number.message}
                           </p>
                        )}
                     </InfoGroup>
                  </OverviewSection>

                  <div className="d-flex justify-content-end mt-2">
                     <Button
                        type="submit"
                        disabled={!isValid || detailsUnchanged() || loading}
                        isLoading={loading}
                     >
                        {managerDetails ? 'Update Manager Details' : 'Add Manager'}
                     </Button>
                  </div>
               </form>
            </OverviewWrapper>
         )}
      </>
   );
};
PublicProfile.propTypes = {
   managerDetails: PropTypes.objectOf().isRequired,
   fetchPublicProfile: func.isRequired,
   prospaManagerId: string.isRequired,
};
