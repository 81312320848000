import { Http } from 'services/http';
import { paramsObjectToQueryString } from 'utils/others';

export const getAllPromoCodes = (page) =>
   Http(`/backmen/list_promo_prospa/${paramsObjectToQueryString(page)}`);

export const addPromoCode = (payload) =>
   Http(`/backmen/promo_prospa/`, {
      method: 'POST',
      body: payload,
   });

export const editPromoCode = (payload) =>
   Http(`/backmen/modify_promo_prospa/`, {
      method: 'POST',
      body: payload,
   });

export const promoCodeUsage = (payload) => {
   const { page, ...rest } = payload;

   return Http(`/backmen/promo_code_usage/?page=${page}`, {
      method: 'POST',
      body: rest,
   });
};

export const getMarketingData = (payload) => {
   return Http(`/backmen/monday_reporting_check/`, {
      method: 'POST',
      body: payload,
   });
};

export const getBusinessData = () => {
   return Http(`/backmen/new_mng_biz_stat`);
};
