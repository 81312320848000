/* eslint-disable react/prop-types */
import { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import { Input } from 'components/input';
import Modal from 'components/modal';
import { func, bool } from 'prop-types';
import { InputWrapper } from 'styles/form';
import { Button } from 'components/button';
import { SelectInput } from 'components/select';
import useFetchManagers from 'hooks/fetchManagers';
import { states } from 'utils/business-constants';
import { getBizCategory, getMonthlyTurnover } from 'services/business';

const NewBusinessFilter = ({ open, onClose, filterData, setFilterData, setFilterPayload }) => {
   const history = useHistory();
   const [bizCategory, setBizCategory] = useState([]);
   const [monthlyTurnover, setMonthlyTurnover] = useState([]);
   const { managers } = useFetchManagers();

   const fetchBizCategories = async () => {
      try {
         const res = await getBizCategory();
         setBizCategory(res.data);
      } catch (e) {
         toast.error(e?.message);
      }
   };

   const fetchMonthlyTurnover = async () => {
      try {
         const res = await getMonthlyTurnover();
         setMonthlyTurnover(res.data);
      } catch (e) {
         toast.error(e?.message);
      }
   };

   useEffect(() => {
      fetchBizCategories();
      fetchMonthlyTurnover();
   }, []);

   const categoriesOptions = bizCategory?.map(({ category_name, category_slug }) => ({
      label: category_slug,
      value: category_name,
   }));

   const monthlyTurnoverOptions = monthlyTurnover?.map(({ name, value }) => ({
      label: name,
      value,
   }));

   const callRequestOptions = [
      {
         value: 'true',
         label: 'Yes',
      },
      {
         value: 'false',
         label: 'No',
      },
   ];

   function onChange(name, data) {
      setFilterData({
         ...filterData,
         [name]: data.value,
      });
   }

   const handleRequest = async (payload) => {
      try {
         history.push(`${history.location.pathname}?page=1`);
         setFilterData(payload);
         setFilterPayload(payload);
         onClose();
      } catch (err) {
         toast.error(err.message || err.custom_message);
         return err;
      }
   };

   function onSubmit() {
      handleRequest(filterData);
   }

   function clearFilter() {
      handleRequest({});
   }

   return (
      <Modal open={open} onClose={onClose} title="Filters" center maxWidth="700px">
         <form>
            <div className="row">
               <div className="col-lg-6 mb-4">
                  <Input
                     placeholder="Start Date"
                     label="Start Date"
                     name="date_after"
                     type="date"
                     value={filterData?.date_after}
                     onChange={(e) => {
                        setFilterData({ ...filterData, date_after: e.target.value });
                     }}
                  />
               </div>

               <div className="col-lg-6 mb-4">
                  <Input
                     placeholder="End Date"
                     label="End Date"
                     name="date_before"
                     type="date"
                     value={filterData?.date_before}
                     onChange={(e) => {
                        setFilterData({ ...filterData, date_before: e.target.value });
                     }}
                  />
               </div>

               <div className="col-lg-6 mb-4">
                  <SelectInput
                     name="biz_turnover"
                     label="Monthly Turnover"
                     options={monthlyTurnoverOptions}
                     onChange={(val) => onChange('biz_turnover', val)}
                     value={monthlyTurnoverOptions.filter(
                        (item) => item.value === filterData.biz_turnover,
                     )}
                  />
               </div>

               <div className="col-lg-6 mb-4">
                  <SelectInput
                     label="Business Sector"
                     onChange={(val) => onChange('biz_category', val)}
                     options={categoriesOptions}
                     value={categoriesOptions.filter(
                        (item) => item.value === filterData.biz_category,
                     )}
                  />
               </div>

               <div className="col-lg-6 mb-4">
                  <SelectInput
                     label="State"
                     onChange={(val) => onChange('biz_state', val)}
                     options={states}
                     value={states.filter((item) => item.value === filterData.biz_state)}
                  />
               </div>

               <div className="col-lg-6 mb-4">
                  <SelectInput
                     label="Manager"
                     onChange={(val) => onChange('assigned_agent', val)}
                     options={managers}
                     value={managers.filter((item) => item.value === filterData.assigned_agent)}
                  />
               </div>

               <div className="col-lg-6 mb-4">
                  <SelectInput
                     label="Call Request"
                     onChange={(val) => onChange('phone_verification_status', val)}
                     options={callRequestOptions}
                     value={callRequestOptions.filter(
                        (item) => item.value === filterData.phone_verification_status,
                     )}
                  />
               </div>
            </div>

            <InputWrapper
               style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
            >
               <div style={{ marginRight: '20px' }}>
                  <Button variant="secondary" type="button" onClick={clearFilter} fullWidth>
                     clear filter
                  </Button>
               </div>

               <div>
                  <Button variant="primary" type="button" onClick={onSubmit} fullWidth>
                     Apply
                  </Button>
               </div>
            </InputWrapper>
         </form>
      </Modal>
   );
};

export { NewBusinessFilter };

NewBusinessFilter.propTypes = {
   open: bool.isRequired,
   onClose: func.isRequired,
};
