import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button } from 'components/button';
import SuccessIcon from 'assets/images/success.svg';
import Modal from 'react-responsive-modal';

const SuccessWrapper = styled.div`
   display: flex;
   flex-direction: column;
   align-items: center;
   gap: 15px;

   position: relative;
   width: 454px;
   height: 271px;

   background: #ffffff;

   .success-icon {
      width: 60px;
      height: 60px;
      display: block;
      margin: 10px auto 15px;
   }

   h3 {
      font-size: 18px;
      line-height: 15px;
      text-align: center;
      color: #1e2442;
      margin-bottom: 10px;
   }

   p {
      font-size: 15px;
      line-height: 21px;
      text-align: center;
      color: #82849f;
      margin-bottom: 15px;
   }

   .d-flex {
      display: flex;
   }

   .justify-content-center {
      justify-content: center;
   }
`;

export default function Success({ open, onClose, title }) {
   return (
      <Modal open={open} onClose={onClose} center noCloseIcon>
         <SuccessWrapper>
            <img src={SuccessIcon} alt="back" className="success-icon" />
            <h3>Successful!</h3>
            <p>{title}</p>

            <div className="d-flex justify-content-center ">
               <Button className="border-radius-5" onClick={onClose}>
                  Done
               </Button>
            </div>
         </SuccessWrapper>
      </Modal>
   );
}

Success.propTypes = {
   open: PropTypes.bool.isRequired,
   onClose: PropTypes.func.isRequired,
   title: PropTypes.string.isRequired,
};
