/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { useEffect, useState, useRef } from 'react';
import LoggedInDashboard from 'components/logged-in-dashboard';
import { confirm } from 'components/confirm-box';
import { TableContainer, TableContent } from 'styles/table';
import {
   getFollowupAccounts,
   changeStatus,
   accountStats,
   getFollowUpFeed,
} from 'services/business';
import { TooltipComponent } from 'components/tooltip';
import useBusinessMeta from 'hooks/businessMeta';
import { useLocation, useHistory } from 'react-router-dom';
import { Spinner } from 'components/spinner';
import { MoreHoriz } from '@material-ui/icons';
import { Status } from 'styles/utils';
import { HeadingOne } from 'styles/typography';
import { TableComponent } from 'components/table';
import IconButton from '@material-ui/core/IconButton';
import toast from 'react-hot-toast';
import moment from 'moment';
import { BusinessWorkerBtn } from 'components/start-work-btn';
import { TabWrapper } from 'pages/pending-documents/style';
import { MenuComponent } from 'components/menu';
import BusinessDetails from 'containers/business-details';
import { useBizDetailsModal } from 'hooks/bizDetailsModal';
import Calendar from 'components/calendar';
import { dateFilter } from 'utils/others';
import { Skeleton } from '@material-ui/lab';
import _ from 'underscore';
import { useDebouncedCallback } from 'use-debounce';

function FollowUp() {
   const history = useHistory();
   const { search } = useLocation();
   const { businessCurrentStatus } = useBusinessMeta();
   const page = new URLSearchParams(search).get('page');
   const filterUrl = new URLSearchParams(search).get('filter');
   const [businessInfo, setBusinessInfo] = useState([]);
   const [isLoading, setIsLoading] = useState(false);
   const [isChangeStatusLoading, setIsChangeStatusLoading] = useState(false);
   const [anchorEl, setAnchorEl] = useState(null);
   const [workAnchorEl, setWorkAnchorEl] = useState(null);
   const [paginationData, setPaginationData] = useState({});
   const [currentPage, setCurrentPage] = useState(page || 1);
   const [statsData, setStatsData] = useState({});
   const [searchTerm, setSearchTerm] = useState('');
   const [modalVisibility, setModalVisibility] = useState(false);
   const [bizCurrentStatus, setBizCurrentStatus] = useState([]);
   const [endOfStream, setEndOfStream] = useState(true);
   // const [businessFeed, setBusinessFeed] = useState();
   const [bizAccount, setBizAccount] = useState('');
   const [noCloseIcon, setNoCloseIcon] = useState(false);
   // console.log(filterUrl);
   const [activeFilter, setActiveFilter] = useState(filterUrl || '24-hours');
   const open = Boolean(anchorEl);
   const { setActiveTab } = useBizDetailsModal();
   const tableRef = useRef();
   const [modalVisibilityFilter, setModalVisibilityFilter] = useState(false);
   // eslint-disable-next-line no-use-before-define
   const debounce = useDebouncedCallback(handleSearch, 600);
   const openStatusMenu = Boolean(workAnchorEl);
   const [dateRange, setDateRange] = useState({});
   const [streamType] = useState('work_followup');
   const [today] = useState(moment().format('YYYY-MM-DD'));
   const [startingWork, setStartingWork] = useState(false);

   const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
   };

   const handleClose = () => {
      setAnchorEl(null);
   };

   useEffect(() => {
      if (businessCurrentStatus) {
         setBizCurrentStatus(
            businessCurrentStatus.map(({ current_status_tag }) => ({
               value: current_status_tag,
               options:
                  current_status_tag.charAt(0).toUpperCase() +
                  current_status_tag.slice(1).replaceAll('_', ' '),
            })),
         );
      }
   }, [businessCurrentStatus]);

   async function handleChangeStatus({ target }, bizId) {
      confirm({
         confirmText: 'Are you sure you want to perform this action?',
         isLoading: isChangeStatusLoading,
         onConfirm: async () =>
            new Promise((resolve, reject) => {
               setIsChangeStatusLoading(true);
               changeStatus({
                  biz_current_status: target.value,
                  biz_account_id: bizId,
               })
                  .then(() => {
                     setIsChangeStatusLoading(false);
                     toast.success('success');
                     resolve();
                  })
                  .catch((err) => {
                     setIsChangeStatusLoading(false);
                     reject(err);
                     toast.error(err.message || err.custom_message);
                  });
            }),
      });
   }

   const options = ['View Details', 'Call', 'Send Message'];

   function handleOpenModal() {
      setActiveTab('overview');
      setModalVisibility(true);
      setNoCloseIcon(false);
   }

   function sendMessageModalSection() {
      setActiveTab('message');
      setModalVisibility(true);
   }

   function callNumber(phone) {
      const link = document.createElement('a');
      const linkText = document.createTextNode('call us');
      link.appendChild(linkText);
      link.href = `tel:${phone}`;
      link.click();
   }

   function onRowClick({ row }) {
      setBizAccount(row.biz_account_id);
   }

   const columns = [
      {
         field: '',
         width: 0,
         renderCell: ({ row }) => (
            <div>
               <IconButton
                  aria-label="more"
                  aria-controls="long-menu"
                  aria-haspopup="true"
                  onClick={handleClick}
               >
                  <MoreHoriz />
               </IconButton>
               <MenuComponent
                  anchorEl={anchorEl}
                  handleClose={handleClose}
                  open={open}
                  options={options}
                  optionsFns={[
                     // place the callbacks in position corresponding to the options index
                     () => handleOpenModal(row.biz_account_id),
                     () => callNumber(row?.account_holder_details?.phone),
                     () => sendMessageModalSection(row.biz_account_id),
                  ]}
               />
            </div>
         ),
      },
      {
         field: 'biz_name',
         headerName: 'Business Name',
         renderCell: ({ row }) => (
            <TooltipComponent title={row.biz_name}>
               <span
                  style={{
                     cursor: 'pointer',
                  }}
                  className="text-primary"
                  onClick={() => handleOpenModal(row.biz_account_id)}
               >
                  {row.biz_name || 'N/A'}
               </span>
            </TooltipComponent>
         ),
      },
      {
         field: 'bvn',
         headerName: 'BVN',
         width: 100,
         renderCell: ({ row }) => (
            <Status status={!row?.account_holder_details?.bvn_number ? '' : 'accepted'}>
               {!row?.account_holder_details?.bvn_number ? 'N/A' : 'verified'}
            </Status>
         ),
      },
      {
         field: 'id',
         headerName: 'ID',
         width: 100,
         renderCell: ({ row }) => (
            <Status status={row?.account_holder_details?.identity_file_status}>
               {row?.account_holder_details?.identity_file_status || 'N/A'}
            </Status>
         ),
      },
      {
         field: 'tin',
         headerName: 'TIN',
         width: 100,
         renderCell: ({ row }) => (
            <Status
               status={
                  row?.tin_number && row?.tin_number_status === ''
                     ? 'pending'
                     : row?.tin_number_status
               }
            >
               {(row?.tin_number && row?.tin_number_status === ''
                  ? 'pending'
                  : row?.tin_number_status) || 'N/A'}
            </Status>
         ),
      },
      {
         field: 'poa',
         headerName: 'POA',
         width: 100,
         renderCell: ({ row }) => (
            <Status status={row.proof_of_address_status}>
               {row.proof_of_address_status || 'N/A'}
            </Status>
         ),
      },
      {
         field: 'cac_cert_photo_status',
         headerName: 'CAC Cert',
         width: 150,
         renderCell: ({ row }) => (
            <Status status={row?.cac_cert_photo_status}>
               {row.cac_cert_photo_status || 'N/A'}
            </Status>
         ),
      },
      {
         field: 'owner',
         headerName: 'Owner',
         renderCell: ({ row }) => (
            <span style={{ textTransform: 'capitalize' }}>
               {row?.account_holder_details?.first_name.toLowerCase()}{' '}
               {row?.account_holder_details?.last_name.toLowerCase()}
            </span>
         ),
      },
      {
         field: 'account_holder_details.phone',
         headerName: 'Phone',
         renderCell: ({ row }) => <span>{row?.account_holder_details?.phone || 'N/A'}</span>,
      },
      {
         field: 'assigned_manager_details',
         headerName: 'Manager',
         renderCell: ({ row }) => (
            <>
               {!row.assigned_manager_details ? (
                  'N/A'
               ) : (
                  <span>
                     {row.assigned_manager_details.first_name}
                     {row.assigned_manager_details.first_name}
                  </span>
               )}
            </>
         ),
      },
      {
         field: 'biz_status',
         headerName: 'Status',
         renderCell: ({ row }) => (
            <select
               style={{
                  width: '100%',
                  border: 'none',
                  padding: '10px',
                  fontWeight: 400,
                  fontSize: '1.3rem',
               }}
               onChange={(e) => handleChangeStatus(e, row.biz_account_id)}
               name="status"
               defaultValue={row.biz_current_status}
            >
               {bizCurrentStatus.map((item) => (
                  <option value={item.value}>{item.options}</option>
               ))}
            </select>
         ),
      },
      {
         field: 'pub_date',
         headerName: 'Date Onboarded',
         renderCell: ({ row }) => (
            <span>
               {moment(row?.pub_date).format('MMM DD, YYYY')} |{' '}
               {moment(row?.pub_date).format('h:mm a')}
            </span>
         ),
      },
   ];

   const intialize = async () => {
      setIsLoading(true);
      setBusinessInfo([]);
      const { start_date, end_date } = dateFilter(activeFilter);
      Promise.all([
         getFollowupAccounts(
            { page },
            {
               biz_friendly_name: searchTerm,
               // start_date: startDate,
               // end_date: endDate,
               start_date,
               end_date,
            },
         ),
         // accountStats({
         //    start_date: moment().subtract(30, 'days').format('YYYY-MM-DD'),
         //    end_date: moment().format('YYYY-MM-DD'),
         // }),
      ])
         .then(([businessAccountsData]) => {
            setBusinessInfo(businessAccountsData.data);
            setPaginationData(businessAccountsData);
            // setStatsData(_.omit(stats.data, 'total_unapproved'));
         })
         .finally(() => {
            setIsLoading(false);
         });
   };
   useEffect(() => {
      intialize();
      // console.log('mount')
   }, [currentPage, searchTerm, activeFilter]);

   // useEffect(() => {
   //    setCurrentPage(page || 1);
   // }, []);

   function onPageChange(e, pageNumber) {
      setCurrentPage(pageNumber);
   }

   function handleSearch({ target }) {
      setSearchTerm(target.value);
   }

   function handleCloseModal() {
      setModalVisibilityFilter(false);
      window.document.body.style.overflow = 'scroll';
   }
   const handleCloseStatusMenu = () => {
      // setAnchorEl(null);
      setWorkAnchorEl(null);
   };

   const handleStatusButtonClick = (event) => {
      setWorkAnchorEl(event.currentTarget);
   };

   // const handleChangeStatus = async (status) => {
   //    confirm({
   //       confirmText: 'Are you sure you want to perform this action?',
   //       isLoading: false,
   //       onConfirm: () => changeStatusAction(status),
   //    });
   // };
   // console.log(page)
   if (startingWork) return <Spinner />;
   return (
      <LoggedInDashboard>
         <div className="d-flex justify-content-between align-items-center">
            <HeadingOne> Business Accounts</HeadingOne>
            <BusinessWorkerBtn
               setModalVisibility={(value) => setModalVisibility(value)}
               setBizAccount={(value) => setBizAccount(value)}
               setNoCloseIcon={(value) => setNoCloseIcon(value)}
               setActiveTab={() => setActiveTab('overview')}
               streamType={streamType}
               setDateRange={setDateRange}
            />
         </div>

         {/* <BusinessAccountFilterModal
            open={modalVisibilityFilter}
            onClose={handleCloseModal}
            setBusinessInfo={setBusinessInfo}
            setPaginationData={setPaginationData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            active={[
               'date',
               'biz_type',
               'manager',
               'identity',
               'bvn',
               'cac',
               'poa',
               'turn_over',
               'status',
            ]}
         /> */}

         {/* <StatsCards data={statsData} isLoading={isLoading} /> */}

         <TableContainer id="table">
            <TabWrapper>
               <ul className="m-0 d-flex align-items-center">
                  <li
                     className={`${
                        activeFilter === '24-hours' ? 'active' : ''
                     } pending-doc-overview pending-tab-action`}
                     onClick={() => {
                        setCurrentPage(1);
                        setActiveFilter('24-hours');
                        history.push(
                           `${history.location.pathname}?page=${1}${
                              activeFilter ? `&filter=${activeFilter}` : ''
                           }`,
                        );
                     }}
                  >
                     Last 24 hours
                  </li>

                  <li
                     className={`${
                        activeFilter === '7-days' ? 'active' : ''
                     } pending-doc-overview pending-tab-action`}
                     onClick={() => {
                        setCurrentPage(1);
                        setActiveFilter('7-days');
                        history.push(`${history.location.pathname}?page=${1}&filter=7-days`);
                     }}
                  >
                     Last 7 days
                  </li>
                  <li
                     className={`${
                        activeFilter === '30-days' ? 'active' : ''
                     } pending-doc-overview pending-tab-action`}
                     onClick={() => {
                        setCurrentPage(1);
                        setActiveFilter('30-days');
                        history.push(`${history.location.pathname}?page=${1}&filter=30-days`);
                     }}
                  >
                     Last 30 days
                  </li>

                  <li
                     className={`${
                        activeFilter === '30-days-plus' ? 'active' : ''
                     } pending-doc-overview pending-tab-action`}
                     onClick={() => {
                        setCurrentPage(1);
                        setActiveFilter('30-days-plus');
                        history.push(`${history.location.pathname}?page=${1}&filter=30-days-plus`);
                     }}
                  >
                     More than 30 days
                  </li>

                  <li
                     className={`${
                        activeFilter === 'all' ? 'active' : ''
                     } pending-doc-overview pending-tab-action`}
                     onClick={() => {
                        setCurrentPage(1);
                        setActiveFilter('all');
                        history.push(`${history.location.pathname}?page=${1}&filter=all`);
                     }}
                  >
                     All
                  </li>
               </ul>
            </TabWrapper>
            <TableContent>
               {isLoading ? (
                  <Skeleton width="30%" height="20" />
               ) : (
                  <div>
                     <h1> Business Accounts</h1>
                     <span className="value">{paginationData?.count || 'N/A'}</span>
                  </div>
               )}
               <div className="d-flex justify-content-between">
                  {/* <div>
                     <Input
                        style={{ width: '300px'}}
                        placeholder="Search"
                        onChange={debounce}
                     />
                  </div> */}
                  {/* <FilterContainer
                     style={{ margin: '0 20px' }}
                     onClick={() => setModalVisibilityFilter(true)}
                  >
                     Filter By <FilterListIcon style={{ marginLeft: '10px' }} />
                  </FilterContainer> */}
                  <Calendar />
               </div>
            </TableContent>
            <TableComponent
               columns={columns}
               rows={businessInfo}
               isLoading={isLoading}
               onPageChange={onPageChange}
               count={Math.ceil(paginationData.count / 15)}
               page={currentPage}
               ref={tableRef}
               onRowClick={onRowClick}
               filter={activeFilter}
            />
         </TableContainer>
         {modalVisibility && (
            <BusinessDetails
               businessAccountId={bizAccount}
               open={modalVisibility}
               onClose={() => {
                  // intialize()
                  setModalVisibility(false);
               }}
               noCloseIcon={noCloseIcon}
               dateRange={dateRange}
               streamType={streamType}
               endOfStream={endOfStream}
            />
         )}
      </LoggedInDashboard>
   );
}

export default FollowUp;
