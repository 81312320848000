import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { func } from 'prop-types';
import styled from 'styled-components';
import cloud from 'assets/images/cloud-upload.png';

const Container = styled.div`
   border: 2px dashed #000;
   border-radius: 8px;
   padding: 1px;
   margin: 10px;
   text-align: center;
`;

const DropzoneCSV = ({ onFileDrop }) => {
   const handleDrop = useCallback(
      (acceptedFiles) => {
         if (onFileDrop) {
            onFileDrop(acceptedFiles[0]);
         }
      },
      [onFileDrop],
   );

   const { getRootProps, getInputProps, isDragActive } = useDropzone({
      onDrop: handleDrop,
      accept: 'text/csv',
   });

   return (
      <>
         <Container {...getRootProps()}>
            <input style={{ cursor: 'pointer' }} {...getInputProps()} />
            <img src={cloud} alt="upload-file" />
            <div className="col pb-5 text-center">
               {isDragActive ? (
                  <p>Drop the CSV file here ...</p>
               ) : (
                  <p>
                     <b>Drag CSV file here or </b> click to browse
                  </p>
               )}
            </div>
         </Container>
      </>
   );
};

DropzoneCSV.propTypes = {
   onFileDrop: func.isRequired,
};

export default DropzoneCSV;
