/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Input } from 'components/input';
import * as yup from 'yup';
import { bool } from 'prop-types';
import { TooltipComponent } from 'components/tooltip';
import { yupResolver } from '@hookform/resolvers/yup';
import { TableComponent } from 'components/table';
import { useForm } from 'react-hook-form';
import { confirm } from 'components/confirm-box/confirm-box';
import toast from 'react-hot-toast';
import { getBizCurrentStatus, addBizCurrentStatus } from 'services/business';
import { SelectInput } from 'components/select';
import { InputWrapper } from 'styles/form';
import { Button } from 'components/button';

const metaTypes = [
   {
      label: 'Business Current Status',
      value: 'biz_current_status',
   },
];

export default function Index({ activeTab }) {
   const [selectedMeta, setSelectedMeta] = useState(metaTypes[0]);
   const [bizCurrentStatus, setBizCurrentStatus] = useState([]);
   const [addLoading, setAddLoading] = useState(false);

   async function fetchMetaTag() {
      try {
         const currentStatuses = await getBizCurrentStatus();
         setBizCurrentStatus(currentStatuses.data);
      } catch (err) {
         toast.error(err.message);
      }
   }
   useEffect(() => {
      if (activeTab === 'metadata') {
         fetchMetaTag();
      }
   }, [activeTab]);

   const schema = yup.object().shape({
      tag_name: yup.string().required('Please enter a valid tag name'),
      tag_desc: yup.string().required('Please enter a valid tag description'),
   });

   const {
      register,
      handleSubmit,
      reset,
      formState: { errors },
   } = useForm({
      mode: 'onChange',
      resolver: yupResolver(schema),
   });

   const onSubmitNewMeta = async (data) => {
      const { tag_name, tag_desc } = data;
      confirm({
         confirmText: 'Are you sure you want to add this tag?',
         isLoading: addLoading,
         onConfirm: async () => {
            setAddLoading(true);
            try {
               const result = await addBizCurrentStatus({
                  current_status_tag: tag_name.toLowerCase().replace(' ', '_'),
                  current_status_description: tag_desc,
               });
               toast.success(result.message);
               reset();
               fetchMetaTag();
            } catch (err) {
               toast.error(err.message);
            }
            setAddLoading(false);
         },
      });
   };

   const columns = [
      {
         field: 'current_status_tag',
         headerName: 'Tag Name',
         renderCell: ({ row }) => (
            <TooltipComponent title={row.current_status_tag}>
               {row.current_status_tag.charAt(0).toUpperCase() +
                  row.current_status_tag.slice(1).replaceAll('_', ' ')}
            </TooltipComponent>
         ),
      },
      {
         field: 'current_status_description',
         headerName: 'Tag Description',
         width: 300,
         renderCell: ({ row }) => (
            <TooltipComponent title={row.current_status_description}>
               {row.current_status_description}
            </TooltipComponent>
         ),
      },
   ];
   return (
      <div>
         <SelectInput
            label="Select Meta Type"
            options={metaTypes}
            value={selectedMeta}
            onChange={(val) => {
               setSelectedMeta(val);
            }}
         />

         <div className="mt-5">
            <div className="row">
               <div className="col-md-6">
                  <h3 className="mb-3">Current Tags</h3>
                  <TableComponent columns={columns} rows={bizCurrentStatus} pageSize={20} />
               </div>
               <div className="col-md-6">
                  <form className="mt-md-5">
                     <h3>Add New Tag</h3>
                     <InputWrapper className="grid-2-2 mt-4">
                        <div>
                           <Input
                              placeholder="Tag Name"
                              label="Tag Name"
                              name="tag_name"
                              type="text"
                              {...register('tag_name')}
                           />
                           {errors.tag_name && (
                              <p className="text-danger input-error-message">
                                 {errors.tag_name.message}
                              </p>
                           )}
                        </div>

                        <div>
                           <Input
                              placeholder="Tag Description"
                              label="Tag Description"
                              name="tag_desc"
                              type="text"
                              {...register('tag_desc')}
                           />
                           {errors.tag_desc && (
                              <p className="text-danger input-error-message">
                                 {errors.tag_desc.message}
                              </p>
                           )}
                        </div>
                     </InputWrapper>
                     <div className="d-flex justify-content-end">
                        <Button
                           onClick={handleSubmit(onSubmitNewMeta)}
                           isLoading={addLoading}
                           disabled={Object.entries(errors).length > 0 || addLoading}
                        >
                           Add New
                        </Button>
                     </div>
                  </form>
               </div>
            </div>
         </div>
      </div>
   );
}

Index.propTypes = {
   activeTab: bool.isRequired,
};
