import PropTypes from 'prop-types';
import { Info, SectionTitle } from 'styles/layout';
import { refund, extract_utility_token, manual_debit } from 'utils/user_actions';
import Refund from './refund';
import DirectDebit from './direct-debit';
import ExtractUtilityToken from './extract-utility-token';

const TransactionsTools = ({ permissions }) => {
   const checkPermission = (action) => permissions?.includes(action) || permissions === 'all';

   return (
      <>
         {checkPermission(refund) && (
            <Info className="mb-2">
               <b>REFUND</b>
               <Refund />
            </Info>
         )}

         {checkPermission(manual_debit) && (
            <Info className="mb-2">
               <SectionTitle />
               <b>DIRECT DEBIT</b>
               <DirectDebit />
            </Info>
         )}

         {checkPermission(extract_utility_token) && (
            <>
               <SectionTitle />
               <Info className="mb-2">
                  <b>EXTRACT UTILITY TOKEN</b>
                  <ExtractUtilityToken />
               </Info>
            </>
         )}
      </>
   );
};

TransactionsTools.propTypes = {
   permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default TransactionsTools;
