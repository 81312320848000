/* eslint-disable react/prop-types */
import moment from 'moment';
import toast from 'react-hot-toast';
import { IconButton } from '@material-ui/core';
import { MoreHoriz } from '@material-ui/icons';
import { updateContactStatus } from 'services/build-partner';
import { MenuComponent } from 'components/menu';
import { TooltipComponent } from 'components/tooltip';
import { numberWithCommas } from 'utils/others';
import { hasPermission } from 'utils/user_access';
import { turnover, businessType, bizStatus, accountStatus } from 'utils/business-constants';
import { confirm } from 'components/confirm-box/confirm-box';
import { Status } from 'styles/utils';

export const getTabs = (permissions) => {
   const isAdmin = hasPermission(permissions, 'activation_admin');
   const tabs = [
      {
         label: 'Accounts',
         key: 'all',
      },
      isAdmin && {
         label: 'Performance Overview',
         key: 'performance',
      },
      hasPermission(permissions, 'admin_can_view_exported_data') && {
         label: 'Data Exports',
         key: 'exports',
         permission: hasPermission(permissions, 'admin_can_view_exported_data'),
      },
   ];

   return tabs.filter((value) => Object.keys(value).length !== 0);
};

async function handleChangeStatus({ target }, bizId) {
   confirm({
      confirmText: 'Are you sure you want to perform this action?',
      onConfirm: async () => {
         const params = {
            biz_account_id: bizId,
            biz_contact_status: target.value,
         };
         try {
            const result = await updateContactStatus(params, bizId);
            toast.success(result.message);
         } catch (e) {
            toast.error(e.message);
         }
      },
   });
}

export const filters = [
   {
      label: 'Start Date',
      key: 'date_after',
      type: 'date',
   },
   {
      label: 'End Date',
      key: 'date_before',
      type: 'date',
   },
   {
      label: 'Business Type',
      key: 'biz_type',
      type: 'select',
      options: businessType,
   },
   {
      label: 'Business Status',
      key: 'biz_status',
      type: 'select',
      options: bizStatus,
   },
   {
      label: 'Account Status',
      key: 'activity_status',
      type: 'select',
      options: accountStatus,
   },
];

const fundFilter = [
   {
      label: 'Start Date',
      key: 'start_date',
      type: 'date',
   },
   {
      label: 'End Date',
      key: 'end_date',
      type: 'date',
   },
];

export const exportTypes = [
   {
      value: 'no_biz_fund_report',
      label: 'Business account on zero all-time',
      downloadPath: 'no_biz_fund_report',
      filters: fundFilter,
   },
   {
      value: 'export_biz_data',
      label: 'Business data',
      downloadPath: 'admin_biz_exports',
      filters,
   },
];

export const bizActivatedFilter = [
   {
      label: 'Start Date',
      key: 'start_date',
      type: 'date',
   },
   {
      label: 'Start Date',
      key: 'end_date',
      type: 'date',
   },
   {
      label: 'Business Turnover',
      key: 'biz_turnover',
      type: 'select',
      options: turnover,
   },
   {
      label: 'Business Type',
      key: 'biz_type',
      type: 'select',
      options: businessType,
   },
   {
      label: 'Deposit Value',
      key: 'deposit_value',
      type: 'select',
      options: [
         {
            value: true,
            label: 'Yes',
         },
         {
            value: false,
            label: 'No',
         },
      ],
   },
];

export const getColumns = ({
   handleOpenModal,
   handleClick,
   anchorEl,
   handleClose,
   open,
   options,
   callNumber,
   sendMessageModalSection,
   selectedCustomColumn,
   deleteBusinessAccountFromPool,
}) => [
   {
      field: '',
      width: 0,
      renderCell: ({ row }) => (
         <div>
            <IconButton
               aria-label="more"
               aria-controls="long-menu"
               aria-haspopup="true"
               onClick={handleClick}
            >
               <MoreHoriz />
            </IconButton>
            <MenuComponent
               anchorEl={anchorEl}
               handleClose={handleClose}
               open={open}
               options={options}
               optionsFns={[
                  // place the callbacks in position corresponding to the options index
                  () => handleOpenModal(),
                  () => callNumber(row?.account_holder_details?.phone),
                  () => sendMessageModalSection(),
                  selectedCustomColumn ? () => deleteBusinessAccountFromPool() : null,
               ]}
            />
         </div>
      ),
   },
   {
      field: 'biz_name',
      headerName: 'Business Name',
      renderCell: ({ row }) => (
         <TooltipComponent title={row.biz_name || row?.biz_friendly_name}>
            <span
               style={{
                  cursor: 'pointer',
               }}
               className="text-primary"
               onClick={() => handleOpenModal(row.id)}
            >
               {row.biz_name || row?.biz_friendly_name || 'N/A'}
            </span>
         </TooltipComponent>
      ),
   },

   {
      field: 'biz_owner',
      headerName: 'Owner',
      renderCell: ({ row }) => (
         <span style={{ textTransform: 'capitalize' }}>{row?.biz_owner?.toLowerCase()}</span>
      ),
   },
   {
      field: 'biz_turnover',
      headerName: 'Turn over',
      renderCell: ({ row }) => (
         <span style={{ textTransform: 'capitalize' }}>
            {row.biz_turnover ? row.biz_turnover.replace(/_/g, ' ') : 'N/A'}
         </span>
      ),
   },
   {
      field: 'biz_category',
      headerName: 'Category',
      renderCell: ({ row }) => (
         <TooltipComponent title={row.biz_category || 'N/A'}>
            {row.biz_category || 'N/A'}
         </TooltipComponent>
      ),
   },

   {
      field: 'biz_state',
      headerName: 'State',
      renderCell: ({ row }) => (
         <TooltipComponent title={row.biz_state || 'N/A'}>
            {row.biz_state || 'N/A'}
         </TooltipComponent>
      ),
   },
   {
      field: 'activity_status',
      headerName: 'Account Status',
      renderCell: ({ row }) => (
         <TooltipComponent title={row?.activity_status}>
            <div className="d-flex gap-2">
               {row?.activity_status?.map((status) => (
                  <Status status={status}>{status?.replace('_', ' ')}</Status>
               ))}
            </div>
         </TooltipComponent>
      ),
   },

   {
      field: 'deposit_volume',
      headerName: 'Deposit Value',
      renderCell: ({ row }) => (
         <span>₦ {numberWithCommas(row?.deposit_value || row?.deposit_volume) || 0}</span>
      ),
   },

   {
      field: 'assigned_manager',
      headerName: 'Assigned Agent',
      renderCell: ({ row }) => (
         <span>{row?.assigned_agent_name || row?.assigned_manager || 'Unassigned'}</span>
      ),
   },
   {
      field: 'biz_phone',
      headerName: 'Phone',
      renderCell: ({ row }) => <span>{row?.biz_phone || 'N/A'}</span>,
   },
   {
      field: 'biz_contact_status',
      headerName: 'Contact Status',
      renderCell: ({ row }) => (
         <select
            style={{ width: '100%', border: 'none', padding: '10px' }}
            onChange={(e) => handleChangeStatus(e, row.id)}
            name="status"
            defaultValue={row.biz_contact_status}
         >
            <option value="yes">Yes</option>
            <option value="no">No</option>
         </select>
      ),
   },
   {
      field: 'pub_date',
      headerName: 'Date Onboarded',
      renderCell: ({ row }) => (
         <span>
            {moment(row?.pub_date).format('MMM DD, YYYY')} |{' '}
            {moment(row?.pub_date).format('h:mm a')}
         </span>
      ),
   },
];
