export const getPCRLabel = (crs_score) => {
   switch (true) {
      case crs_score >= 95:
         return (
            <span className="fw-bold" style={{ color: '#20C998' }}>
               A+
            </span>
         );
      case crs_score >= 89 && crs_score <= 94.99:
         return (
            <span className="fw-bold" style={{ color: '#fd60ae' }}>
               A
            </span>
         );
      case crs_score >= 80 && crs_score <= 88.99:
         return (
            <span className="fw-bold" style={{ color: '#8948ff' }}>
               B
            </span>
         );
      case crs_score >= 70 && crs_score <= 79.99:
         return (
            <span className="fw-bold" style={{ color: '#0d6efd' }}>
               B-
            </span>
         );
      case crs_score >= 60 && crs_score <= 69.99:
         return (
            <span className="fw-bold" style={{ color: '#21daff' }}>
               C
            </span>
         );
      case crs_score >= 40 && crs_score <= 59.99:
         return (
            <span className="fw-bold" style={{ color: '#10bbdd' }}>
               C-
            </span>
         );
      case crs_score >= 30 && crs_score <= 39.99:
         return (
            <span className="fw-bold" style={{ color: '#6c757d' }}>
               D
            </span>
         );
      case crs_score >= 0 && crs_score <= 29.99:
         return <span className="text-warning fw-bold">E</span>;
      default:
         return (
            <span className="fw-bold" style={{ color: '#ff0000' }}>
               F
            </span>
         );
   }
};
