import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Modal from 'components/modal';
import { getBusinessDetails } from 'services/businessDetails';
import { withdrawFromSavings } from 'services/savings';
import ExtendedAmountInput from 'components/ExtendedAmountInput';
import { Button } from 'components/button';
import ArrowRightIcon from 'assets/images/arrow-right.svg';
import BackArrowIcon from 'assets/images/back-arrow.svg';
import { hasValue } from 'utils/validations';
import toast from 'react-hot-toast';
import SelectAccount from './select-account';
import Success from './success';

const WithdrawWrapper = styled.div`
   padding-bottom: 22px;

   .withdraw-header {
      display: flex;
      justify-content: center;
      position: relative;
      margin: 22px 0 44px 0;

      img {
         position: absolute;
         left: 0;
      }

      h3 {
         font-size: 20px;
         line-height: 18px;
         text-align: center;
         color: #04093f;
         font-weight: 700;
      }
   }

   .withdraw-amount {
      .extended-amount-input {
         margin-bottom: 60px;
      }
   }

   .select-account {
      display: flex;
      border-bottom: 1px solid #d9dae2;
      padding-bottom: 10px;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      margin-bottom: 41px;

      label {
         font-size: 14px;
         line-height: 12px;
         color: #82849f;
         margin-bottom: 0;
         cursor: pointer;
         text-transform: capitalize;
      }

      img {
         width: 10px;
      }
   }
`;

export default function Withdraw({
   open,
   onClose,
   businessAccountId,
   onBack,
   walletDetails,
   onSuccess,
}) {
   const [amount, setAmount] = useState('');
   const [step, setStep] = useState('initial');
   const [bizDetails, setBizDetails] = useState({});
   const [selectedAccount, setSelectedAccount] = useState(null);
   const [loading, setLoading] = useState(null);

   async function fetchBusinessDetails() {
      try {
         const result = await getBusinessDetails(businessAccountId);
         setBizDetails(result.data);
      } catch (e) {
         return e;
      }
   }

   useEffect(() => {
      if (businessAccountId) {
         fetchBusinessDetails();
      }
   }, [businessAccountId]);

   const handleControls = (type) => {
      if (type === 'increment') {
         setAmount(amount + 100);
      } else if (type === 'decrement' && amount > 100) {
         setAmount(amount - 100);
      }

      return null;
   };

   const onChange = (target) => setAmount(target.floatValue);
   const valid = hasValue(amount) && amount > 0 && selectedAccount;

   const handleNext = async () => {
      if (valid) {
         const payload = {
            from_wallet_id: walletDetails.biz_wallet_id,
            to_wallet_id: selectedAccount.biz_wallet_id,
            amount,
         };

         setLoading(true);

         try {
            await withdrawFromSavings(payload);
            setStep('success');
         } catch (e) {
            toast.error(e.message);
         }

         setLoading(false);
      }
   };

   const { biz_account_wallet_details } = bizDetails;
   const {
      goodnews_account_number,
      goodnews_full_bank_name,
      partner_bank_name,
      biz_account_number,
      preferred_name,
   } = selectedAccount || {};

   return (
      <Modal
         open={open}
         onClose={onClose}
         center
         noCloseIcon
         maxWidth="400px"
         modalStyle={{ maxHeight: '450px' }}
      >
         {step === 'initial' && (
            <WithdrawWrapper>
               <div className="withdraw-header">
                  <img
                     src={BackArrowIcon}
                     alt="back"
                     onClick={onBack}
                     style={{ cursor: 'pointer' }}
                  />
                  <h3>Withdraw</h3>
               </div>

               <form>
                  <ExtendedAmountInput
                     handleControls={handleControls}
                     name="amount"
                     required
                     label="Enter an amount"
                     onChange={onChange}
                     value={amount}
                  />

                  <div className="select-account" onClick={() => setStep('selectAccount')}>
                     <label htmlFor="amount">
                        {selectedAccount
                           ? `${preferred_name} - (${
                                goodnews_account_number
                                   ? `${goodnews_full_bank_name.slice(
                                        0,
                                        15,
                                     )} - ${goodnews_account_number}`
                                   : `${partner_bank_name.slice(0, 15)} - ${biz_account_number}`
                             })`
                           : 'Select Account'}
                     </label>
                     <img src={ArrowRightIcon} alt="next" />
                  </div>
               </form>
               <Button
                  disabled={!valid || loading}
                  fullWidth
                  onClick={handleNext}
                  isLoading={loading}
               >
                  Update
               </Button>
            </WithdrawWrapper>
         )}

         {step === 'selectAccount' && (
            <SelectAccount
               bizWallets={biz_account_wallet_details?.filter(
                  (item) => item.biz_wallet_type !== 'safe',
               )}
               onBack={() => setStep('initial')}
               onSelectAccount={(value) => {
                  setSelectedAccount(value);
                  setStep('initial');
               }}
            />
         )}

         {step === 'success' && (
            <Success
               onSubmit={() => {
                  setStep('initial');
                  onBack();
                  if (onSuccess) onSuccess();
               }}
            />
         )}
      </Modal>
   );
}

Withdraw.propTypes = {
   open: PropTypes.bool.isRequired,
   onClose: PropTypes.func.isRequired,
   businessAccountId: PropTypes.number.isRequired,
   onBack: PropTypes.func.isRequired,
   // eslint-disable-next-line react/forbid-prop-types
   walletDetails: PropTypes.object.isRequired,
   onSuccess: PropTypes.func.isRequired,
};
