import { Button } from 'components/button';
import PropTypes from 'prop-types';
import { NumberInput } from 'components/input';
import React, { useEffect, useState } from 'react';
import { hasValue } from 'utils/validations';
import { updateCardSettings } from 'services/cards';
import toast from 'react-hot-toast';
import { confirm } from 'components/confirm-box';

export const InterestDeduction = ({ allSettings }) => {
   const [interest, setInterest] = useState('');
   const [loading, setLoading] = useState(false);

   useEffect(() => {
      setInterest(allSettings?.BANKING__INTEREST_DEDUCTION?.value?.interest_deduction);
   }, [allSettings]);

   const isValid =
      hasValue(interest) &&
      allSettings?.BANKING__INTEREST_DEDUCTION?.value?.interest_deduction !== interest;

   const handleSettingChange = async () => {
      setLoading(true);
      try {
         const payload = {
            name: 'BANKING__INTEREST_DEDUCTION',
            value: { interest_deduction: interest },
            category: allSettings?.BANKING__INTEREST_DEDUCTION?.category,
         };

         const res = await updateCardSettings(
            allSettings?.BANKING__INTEREST_DEDUCTION?.id,
            payload,
         );
         setInterest(res?.value?.interest_deduction);
         toast.success('Settings successfully updated');
         setLoading(false);
      } catch (e) {
         toast.error(e?.message || 'Something went wrong');
         setLoading(false);
      }
   };

   const confirmSettings = () => {
      confirm({
         confirmText: 'Are you sure want to update the setting?',
         onConfirm: handleSettingChange,
      });
   };

   return (
      <div className="pt-5 row border-top ">
         <div className="col-lg-4 mb-5">
            <div className="mb-4">
               <h3 className="mb-3">Withdrawal Penalty</h3>

               <NumberInput
                  label="Charges for pre mature withdrawal (percentage based)"
                  className="wrap-label-input"
                  placeholder=""
                  name="interest_deduction"
                  value={interest}
                  prefix=" "
                  suffix="%"
                  onChange={(valuesObject) => {
                     setInterest(valuesObject.floatValue);
                  }}
               />
               {parseFloat(interest) > 100 && (
                  <p className="text-warning">Warning: Interest cannot be greater than 100%</p>
               )}
            </div>
            <Button
               fullWidth
               className="mt-4"
               onClick={confirmSettings}
               disabled={!isValid || interest > 100 || interest < 0}
               isLoading={loading}
            >
               Update
            </Button>
         </div>
      </div>
   );
};
InterestDeduction.propTypes = {
   allSettings: PropTypes.objectOf().isRequired,
};
