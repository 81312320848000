import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Status } from 'styles/utils';
import Avatar from 'assets/images/user.svg';
import {
   AvatarWrapper,
   Info,
   TopSection,
   UserProfile,
   SectionTitle,
   OverviewSection,
   InfoGroup,
   Label,
   Value,
   Name,
   OverviewWrapper,
} from 'styles/layout';

const VirtualCardOverview = ({ cardDetails, bizDetails }) => {
   const { account_holder_details } = bizDetails || {};
   const first_name = account_holder_details?.first_name;
   const last_name = account_holder_details?.last_name;
   const phone = account_holder_details?.phone;
   const email = account_holder_details?.email;
   const userImage = account_holder_details?.photo_url || Avatar;

   const {
      business_name,
      created,
      order_id,
      status,
      order_total,
      card_type,
      virtual_card_currency,
   } = cardDetails || {};

   return (
      <>
         <TopSection className="pt-4">
            <UserProfile>
               <AvatarWrapper>
                  <img src={userImage} alt="account-holder-avatar" />
               </AvatarWrapper>
               <div>
                  <Info
                     style={{
                        color: 'var(--spn-700, #3A434B)',
                        fontSize: '12px',
                     }}
                  >
                     #{order_id}
                  </Info>
                  <Name
                     style={{
                        color: '#1354D3',
                        fontSize: '12px',
                     }}
                  >
                     {business_name}
                  </Name>
                  <Status status={status}>{status}</Status>
               </div>
            </UserProfile>
         </TopSection>
         <OverviewWrapper>
            <div className="mt-5">
               <SectionTitle>Order Details</SectionTitle>
               <OverviewSection>
                  <InfoGroup>
                     <Label>Full Name</Label>
                     <Value>{`${first_name} ${last_name}`}</Value>
                  </InfoGroup>
                  <InfoGroup>
                     <Label>Mobile Number</Label>
                     <Value>{phone}</Value>
                  </InfoGroup>

                  <InfoGroup>
                     <Label>Order Total</Label>
                     <Value>{order_total}</Value>
                  </InfoGroup>
                  <InfoGroup>
                     <Label>Email Address</Label>
                     <Value>{email}</Value>
                  </InfoGroup>
                  <InfoGroup>
                     <Label>Card Currency</Label>
                     <Value>{virtual_card_currency}</Value>
                  </InfoGroup>
                  <InfoGroup>
                     <Label>Card Type</Label>
                     <Value>{card_type}</Value>
                  </InfoGroup>
                  <InfoGroup>
                     <Label>Order Date</Label>
                     <Value>{moment(created || '').format('MMM DD, YYYY')}</Value>
                  </InfoGroup>
               </OverviewSection>
            </div>
         </OverviewWrapper>
      </>
   );
};

VirtualCardOverview.propTypes = {
   bizDetails: PropTypes.objectOf(PropTypes.string).isRequired,
   cardDetails: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default VirtualCardOverview;
