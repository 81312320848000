/* eslint-disable react/prop-types */
import { func, bool } from 'prop-types';
import toast from 'react-hot-toast';
import moment from 'moment';
import { Input } from 'components/input';
import Modal from 'components/modal';
import { InputWrapper } from 'styles/form';
import { Button } from 'components/button';
import { useState, useEffect } from 'react';

const MarketingDataFilter = ({ open, onClose, setFilterDate, filterDate }) => {
   const [filterData, setfilterData] = useState({
      start_date: filterDate.start_date,
      end_date: filterDate.end_date,
   });
   const [isLoading, setIsLoading] = useState(false);
   const [isClearingFilter, setIsClearingFilter] = useState(false);

   useEffect(() => {
      return function unMount() {
         setIsLoading(false);
      };
   }, []);

   async function onSubmit() {
      setIsLoading(true);
      try {
         const { start_date, end_date } = filterData;
         setFilterDate({ start_date, end_date });
      } catch (err) {
         toast.error(err.message || err.custom_message);
         setIsLoading(false);
         return err;
      }
      setIsLoading(false);
      onClose();
   }

   async function clearFilter() {
      setIsClearingFilter(true);
      try {
         setFilterDate({
            start_date: moment()?.subtract(1, 'week')?.format('YYYY-MM-DD'),
            end_date: moment()?.format('YYYY-MM-DD'),
         });
      } catch (err) {
         return err;
      }
      setIsClearingFilter(false);
      onClose();
   }

   return (
      <Modal
         open={open}
         onClose={onClose}
         title="Filters"
         className="biz-details-modal"
         center
         width="750px"
      >
         <form>
            <InputWrapper className="grid-2-2">
               <Input
                  placeholder="Start Date"
                  label="Start Date"
                  name="start_date"
                  type="date"
                  value={filterData?.start_date}
                  onChange={(e) => {
                     setfilterData({ ...filterData, start_date: e.target.value });
                  }}
               />
               <Input
                  placeholder="End Date"
                  label="End Date"
                  name="end_date"
                  type="date"
                  value={filterData?.end_date}
                  onChange={(e) => {
                     setfilterData({ ...filterData, end_date: e.target.value });
                  }}
               />
            </InputWrapper>

            <InputWrapper
               style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
            >
               <div style={{ marginRight: '20px' }}>
                  <Button
                     variant="secondary"
                     type="button"
                     onClick={clearFilter}
                     isLoading={isClearingFilter}
                     fullWidth
                  >
                     clear filter
                  </Button>
               </div>

               <div>
                  <Button
                     variant="primary"
                     type="button"
                     onClick={onSubmit}
                     isLoading={isLoading}
                     disabled={isLoading}
                     fullWidth
                  >
                     Apply
                  </Button>
               </div>
            </InputWrapper>
         </form>
      </Modal>
   );
};

export { MarketingDataFilter };

MarketingDataFilter.propTypes = {
   open: bool.isRequired,
   onClose: func.isRequired,
};
