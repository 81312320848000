import styled from 'styled-components/macro';

export const TableWrapper = styled.div`
   width: 100%;
   overflow-x: scroll;
`;

export const Table = styled.table`
   width: 100%;
`;

export const THead = styled.thead`
   background-color: ${({ theme }) => theme.colors?.grey04};
`;

export const Th = styled.th`
   font-weight: ${({ theme }) => theme.fontWeights?.semiBold};
   font-size: 14px;
   padding: 1.3rem;
   color: #0d0f11;
   border-bottom: 1px solid #d7dce0;
`;

export const Td = styled.td`
   font-size: 14px;
   padding: 1.3rem;
   color: ${({ theme }) => theme.colors?.black01};
   border-bottom: 1px solid #d7dce0;
   white-space: nowrap;

   .log-row-date {
      color: #24292e;
   }

   .log-row-time {
      color: #81909d;
   }
`;
