import { useEffect, useState } from 'react';
import { bool, func } from 'prop-types';
import Modal from 'components/modal';
import { CardDesignContainer } from 'pages/prospa-cards/cards.style';
import { getCardDesigns, deleteCardDesign } from 'services/cards';
import toast from 'react-hot-toast';
import { Spinner } from 'components/spinner';
import { Button } from 'components/button';
import { InputWrapper } from 'styles/form';
import { confirm } from 'components/confirm-box';
import Card from '../../../../assets/images/card.svg';
import { AddCardDesignModal } from '../add-card';

export const CardDesignModal = ({ open, onClose }) => {
   const [loading, setLoading] = useState(false);
   const [cardDesigns, setCardDesigns] = useState([]);
   const [newCardModal, setNewCardModal] = useState(false);
   const [editCardModal, setEditCardModal] = useState(false);
   const [selectedDesign, setSelectedDesign] = useState(null);

   const fetchCardDesigns = async () => {
      setLoading(true);
      try {
         const res = await getCardDesigns();
         setCardDesigns(res.results);
         setLoading(false);
      } catch (e) {
         toast.error(e?.message || 'Something went wrong. Try again later!');
         setLoading(false);
      }
   };

   const handleDeleteDesign = async (id) => {
      confirm({
         confirmText: 'Are you sure want to delete this card?',
         isLoading: loading,
         onConfirm: async () => {
            setLoading(true);
            try {
               const res = await deleteCardDesign(id);
               toast.success(res?.message || 'Action Successful');
               fetchCardDesigns();
               setLoading(false);
            } catch (e) {
               toast.error(e?.message);
               setLoading(false);
            }
         },
      });
   };

   const handleEditDesign = (item) => {
      setEditCardModal(true);
      setSelectedDesign(item);
   };

   useEffect(() => {
      fetchCardDesigns();
   }, []);

   return (
      <Modal open={open} onClose={onClose} title="Cards" center width="100%">
         {loading ? (
            <Spinner />
         ) : (
            <>
               {cardDesigns ? (
                  <>
                     <div className="d-flex gap-4 align-items-center">
                        {cardDesigns?.map((item) => (
                           <CardDesignContainer key={item.id}>
                              <div className="d-flex justify-content-center">
                                 <img
                                    alt="card-designs"
                                    src={item.image}
                                    height={360}
                                    width={225}
                                 />
                              </div>
                              <div className="d-flex justify-content-between mb-3 px-2">
                                 <h4 className="font-weight-light">
                                    {item.name.charAt(0).toUpperCase() + item.name.slice(1)}
                                 </h4>
                                 <h4 className="font-weight-bold">₦{item.amount}</h4>
                              </div>
                              <div className="d-flex gap-2 justify-content-between align-items-center">
                                 <Button
                                    variant="danger-border"
                                    size="x-small"
                                    onClick={() => handleDeleteDesign(item?.id)}
                                 >
                                    Delete Card
                                 </Button>
                                 <Button
                                    variant="primary-border"
                                    size="x-small"
                                    onClick={() => handleEditDesign(item)}
                                 >
                                    Edit Card
                                 </Button>
                              </div>
                           </CardDesignContainer>
                        ))}
                     </div>
                     <InputWrapper className="d-flex align-items-center justify-content-end">
                        <div>
                           <Button type="button" fullWidth onClick={() => setNewCardModal(true)}>
                              Create New Card
                           </Button>
                        </div>
                     </InputWrapper>
                  </>
               ) : (
                  !cardDesigns && (
                     <div className="d-flex justify-content-center align-items-center">
                        <div className="text-center">
                           <img src={Card} alt="card" />
                           <p>You have not created any card</p>
                           <Button className="mt-4" fullWidth onClick={() => setNewCardModal(true)}>
                              Create new card
                           </Button>
                        </div>
                     </div>
                  )
               )}
            </>
         )}

         {newCardModal && (
            <AddCardDesignModal
               selectedDesign={selectedDesign}
               open={newCardModal}
               onClose={() => setNewCardModal(false)}
               fetchCardDesigns={fetchCardDesigns}
            />
         )}

         {editCardModal && (
            <AddCardDesignModal
               isEdit
               selectedDesign={selectedDesign}
               open={editCardModal}
               onClose={() => setEditCardModal(false)}
               fetchCardDesigns={fetchCardDesigns}
            />
         )}
      </Modal>
   );
};

CardDesignModal.propTypes = {
   open: bool.isRequired,
   onClose: func.isRequired,
};
