/* eslint-disable react/prop-types */
import { NumberInput } from 'components/input';
import { InputWrapper } from 'styles/form';
import { Button } from 'components/button';
import { SelectInput } from 'components/select';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessage } from '@hookform/error-message';
import { useEffect, useCallback } from 'react';
import { tenure } from 'utils/constants';

const LockFundsModal = ({ onSubmit, defaultFormValues }) => {
   const defaultValues = {
      interest_rate: 0,
      period: 0,
   };

   const schema = yup.object().shape({
      interest_rate: yup
         .number()
         .positive()
         .required()
         .min(0)
         .max(100, 'Maximum value allowed is 100'),
      period: yup
         .object()
         .shape({ label: yup.string().required(), value: yup.string().required() }),
   });

   const {
      register,
      setValue,
      trigger,
      watch,
      formState: { isValid, errors },
   } = useForm({
      defaultValues,
      mode: 'onChange',
      resolver: yupResolver(schema),
   });

   useEffect(() => {
      register('interest_rate');
      register('period');
   }, []);

   const interest_rate = watch('interest_rate');
   const period = watch('period');

   const handleAccountCreation = async () => {
      onSubmit({ interest_rate, period });
   };

   const sharedOnChange = useCallback(async ({ name, value }, cb) => {
      setValue(name, value);
      await trigger(name);
      if (cb) {
         cb();
      }
   }, []);

   const renderErrorMessage = (name) => (
      <ErrorMessage
         errors={errors}
         name={name}
         render={({ message }) => <p className="input-error-message text-danger">{message}</p>}
      />
   );

   useEffect(() => {
      if (defaultFormValues?.interest_rate) {
         setValue('interest_rate', defaultFormValues?.interest_rate);
      }
      if (defaultFormValues?.period) {
         setValue('period', defaultFormValues?.period);
      }
   }, []);

   return (
      <div>
         <form>
            <InputWrapper className="grid-2-2">
               <NumberInput
                  label="Interest Rate"
                  name="interest_rate"
                  value={interest_rate}
                  placeholder="Enter Interest Rate"
                  prefix="hidden"
                  suffix="%"
                  onChange={({ floatValue }) =>
                     sharedOnChange({ name: 'interest_rate', value: floatValue || 0 })
                  }
               />
               {renderErrorMessage('interest_rate')}

               <SelectInput
                  name="period"
                  onChange={(val) => sharedOnChange({ name: 'period', value: val })}
                  label="Loan Tenure"
                  options={tenure}
                  value={period}
                  defaultValue={tenure[0].value}
               />
            </InputWrapper>

            <InputWrapper
               style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
            >
               <div>
                  <Button
                     variant="primary"
                     type="button"
                     onClick={handleAccountCreation}
                     disabled={!isValid}
                  >
                     Lock Funds
                  </Button>
               </div>
            </InputWrapper>
         </form>
      </div>
   );
};

export { LockFundsModal };
