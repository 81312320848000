import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { func } from 'prop-types';
import styled from 'styled-components';
import cloud from 'assets/images/cloud.svg';

const Container = styled.div`
   display: flex;
   width: 722px;
   padding: 70px;
   flex-direction: column;
   align-items: center;
   gap: 10px;
`;

const Image = styled.img`
   height: 40px;
   width: 40px;
`;

const DropzoneImage = ({ onImageDrop }) => {
   const handleDrop = useCallback(
      (acceptedFiles) => {
         if (onImageDrop) {
            onImageDrop(acceptedFiles[0]);
         }
      },
      [onImageDrop],
   );

   const { getRootProps, getInputProps } = useDropzone({
      onDrop: handleDrop,
      accept: 'image/*',
   });

   return (
      <>
         <Container {...getRootProps()}>
            <input style={{ cursor: 'pointer' }} {...getInputProps()} />
            <Image src={cloud} alt="upload-file" />
            <div className="col text-center">
               <p>
                  <b>Upload Card Picture</b>
               </p>
            </div>
            <div className="col text-center">
               <p>
                  Drag and drop your files here or{' '}
                  <b style={{ color: '#1354D3', cursor: 'pointer' }}>click</b> to browse.
               </p>
            </div>
         </Container>
      </>
   );
};

DropzoneImage.propTypes = {
   onImageDrop: func.isRequired,
};

export default DropzoneImage;
