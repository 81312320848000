import styled from 'styled-components';

export const TableContainer = styled.div`
   margin: 30px 0 0px 0;
   background-color: #fcfcfc;

   &.activity .MuiDataGrid-cell:nth-child(2),
   &.activity .MuiDataGrid-columnHeader:nth-child(2) {
      max-width: 400px !important;
      width: 400px !important;
      min-width: 400px !important;
      display: block;
   }
`;

export const TableContent = styled.div`
   background-color: #fff;
   padding: 15px 0;

   border-top: ${(props) => (props.noBorderTop ? 'none' : '1px solid #f1f1f1')};
   border-bottom: ${(props) => (props.noBorderBottom ? 'none' : '1px solid #f1f1f1')};
   display: flex;
   justify-content: space-between;
   align-items: center;

   h1 {
      font-size: 2rem;
      display: inline-block;
      margin: 0;
   }

   .value {
      font-size: 1.2rem;
      margin-left: 10px;
      background: #efefef;
      padding: 5px;
      border-radius: 5px;
   }
`;
