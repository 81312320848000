import styled from 'styled-components/macro';

export const OverviewWrapper = styled.div`
   .overview-input {
      input {
         padding-left: 0;
         height: initial;
         border-top: none;
         border-left: none;
         border-right: none;
         border-radius: initial;
         color: ${({ theme }) => theme.colors?.black01};
         font-size: 14px;
      }
   }
`;

export const OverviewSection = styled.div`
   padding-bottom: 3rem;
   display: grid;
   grid-template-columns: repeat(4, 1fr);
   grid-column-gap: 1.5em;
   grid-row-gap: 1.5em;

   &.director-section {
      border-bottom: 1px solid #bac2c9;
      margin-bottom: 2rem;
   }

   @media (max-width: 700px) {
      grid-template-columns: repeat(auto-fit, 150px);
   }
`;

export const SectionTitle = styled.div`
   color: ${({ theme }) => theme.colors?.black02};
   font-weight: ${({ theme }) => theme.fontWeights?.semiBold};
   font-size: 18px;
   border-bottom: 1px solid #bac2c9;
   padding: 1.5rem 0;
   margin-bottom: 2rem;
`;

export const UserProfile = styled.div`
   display: flex;
`;

export const TopSection = styled.div`
   display: flex;
   justify-content: space-between;
   align-items: flex-start;
`;

export const AvatarWrapper = styled.div`
   margin-right: 1.5rem;
   border-radius: 50%;
   max-height: 112px;
   max-width: 112px;
   overflow: hidden;
   img {
      height: 100%;
      width: 100%;
      object-fit: cover;
   }
`;

export const Name = styled.p`
   font-weight: ${({ theme }) => theme.fontWeights?.semiBold};
   color: ${({ theme }) => theme.colors?.blue01};
`;

export const Info = styled.p`
   color: ${({ theme }) => theme.colors?.black01};
   font-size: 14px;
`;

export const Label = styled.p`
   color: ${({ theme }) => theme.colors?.grey03};
   font-size: 12px;
   text-transform: capitalize;
`;

export const Value = styled.p`
   color: ${({ theme }) => theme.colors?.black01};
   font-size: 14px;
   text-transform: capitalize;
`;

export const InfoGroup = styled.div`
   margin-bottom: 1rem;
`;

export const BoolStatus = styled.span`
   background-color: ${({ isTrue }) => (isTrue ? '#EAFBF1' : '#FCE9E9')};
   color: ${({ isTrue }) => (isTrue ? '#41D87D' : '#E43535')};
   font-size: 14px;
   padding: 0.5rem;
   border-radius: 4px;
   text-transform: capitalize;
`;

// export const Status = styled.span`
//    background-color: ${({ status }) => {
//       switch (status) {
//          case 'accepted':
//          case 'approved':
//             return '#EAFBF1';
//          case 'rejected':
//          case 'unapproved':
//             return '#FCE9E9';
//          case 'pending':
//          case 'preapproved':
//             return '#FDF2E7';
//          default:
//             return '#00a3ff';
//       }
//    }};
//    color: ${({ status }) => {
//       switch (status) {
//          case 'accepted':
//          case 'approved':
//             return '#41D87D';
//          case 'rejected':
//          case 'unapproved':
//             return '#E43535';
//          case 'pending':
//          case 'preapproved':
//             return '#F08829';
//          default:
//             return '#fff';
//       }
//    }};
//    font-size: 14px;
//    padding: 0.5rem;
//    border-radius: 4px;
//    text-transform: capitalize;
// `;
