/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import { Skeleton } from '@material-ui/lab';
import { MoreHoriz } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import { TableContainer, TableContent } from 'styles/table';
import { TooltipComponent } from 'components/tooltip';
import { Button } from 'components/button';
import { confirm } from 'components/confirm-box';
import { TableComponent } from 'components/table';
import { MenuComponent } from 'components/menu';
import { Status } from 'styles/utils';
import { numberWithCommas } from 'utils/others';
import { deleteLoanTenure, getLoanTunure } from 'services/loans';
import AddLoanTenure from '../add-new-tenure';

function LoanTenure() {
   const { search } = useLocation();
   const page = new URLSearchParams(search).get('page');
   const [tableData, setTableData] = useState([]);
   const [isLoading, setIsLoading] = useState(false);
   const [modalVisibility, setModalVisibility] = useState(false);
   const [paginationData, setPaginationData] = useState({});
   const [tenure, setTenure] = useState({});
   const [currentPage, setCurrentPage] = useState(page || 1);

   const options = ['Edit', 'Delete'];

   const [anchorEl, setAnchorEl] = useState(null);
   const open = Boolean(anchorEl);

   const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
   };

   const handleClose = () => {
      setAnchorEl(null);
   };

   function onRowClick({ row }) {
      setTenure(row);
   }

   const initialize = async () => {
      setIsLoading(true);
      setTableData([]);
      setPaginationData({});
      try {
         const response = await getLoanTunure({ page });
         setTableData(response.results);
         setPaginationData(response);
      } catch (e) {
         toast.error(e.message);
      }

      setIsLoading(false);
   };
   useEffect(() => {
      initialize();
   }, [currentPage, page]);

   const timedInitialization = () =>
      setTimeout(() => {
         initialize();
      }, 500);

   const handleDelete = async () => {
      confirm({
         confirmText: 'Are you sure you want to delete this?',
         onConfirm: async () => {
            try {
               await deleteLoanTenure({ id: tenure.id });
               setTenure({});
               toast.success('Tenure was deleted successfully');
               timedInitialization();
            } catch (e) {
               toast.error(e.message);
            }
         },
      });
   };

   const handleOnCloseAdd = async () => {
      setModalVisibility(false);
      timedInitialization();
   };

   const columns = [
      {
         field: '',
         width: 0,
         renderCell: () => (
            <div>
               <IconButton
                  aria-label="more"
                  aria-controls="long-menu"
                  aria-haspopup="true"
                  onClick={handleClick}
               >
                  <MoreHoriz />
               </IconButton>
               <MenuComponent
                  anchorEl={anchorEl}
                  handleClose={handleClose}
                  open={open}
                  options={options}
                  optionsFns={[() => setModalVisibility('edit'), () => handleDelete()]}
               />
            </div>
         ),
      },
      {
         field: 'min_tenure',
         headerName: 'Min Tenure',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.min_tenure}>{row?.min_tenure}</TooltipComponent>
         ),
      },
      {
         field: 'max_tenure',
         headerName: 'Max Tenure',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.max_tenure}>{row?.max_tenure}</TooltipComponent>
         ),
      },
      {
         field: 'min_loan_amount',
         headerName: 'Min Loan Amount',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.min_loan_amount}>
               ₦{numberWithCommas(row?.min_loan_amount)}
            </TooltipComponent>
         ),
      },
      {
         field: 'max_loan_amount',
         headerName: 'Max Loan Amount',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.max_loan_amount}>
               ₦{numberWithCommas(row?.max_loan_amount)}
            </TooltipComponent>
         ),
      },
      {
         field: 'is_active',
         headerName: 'Active',
         // width: 150,
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.is_active}>
               <Status status={row?.is_active ? 'on' : 'off'}>
                  {row.is_active ? 'Active' : 'InActive'}
               </Status>
            </TooltipComponent>
         ),
      },
      {
         field: 'created',
         headerName: 'Created Date',
         renderCell: ({ row }) => (
            <TooltipComponent title={moment(row.created).format('DD MMM, YYYY')}>
               {moment(row.pub_date).format('DD MMM, YYYY')} |{' '}
               <span style={{ opacity: '.5' }}>
                  {moment(row.pub_createddate).format('HH:mm A')}
               </span>
            </TooltipComponent>
         ),
      },
      {
         field: 'modified',
         headerName: 'Modified Date',
         renderCell: ({ row }) => (
            <TooltipComponent title={moment(row.created).format('DD MMM, YYYY')}>
               {row.modified && moment(row.modified).format('DD MMM, YYYY')} |{' '}
               {row.modified && (
                  <span style={{ opacity: '.5' }}>{moment(row.modified).format('HH:mm A')}</span>
               )}
            </TooltipComponent>
         ),
      },
   ];

   function onPageChange(e, pageNumber) {
      setCurrentPage(pageNumber);
   }

   return (
      <>
         <TableContainer>
            <TableContent>
               <div className="d-flex justify-content-between w-100 align-items-center">
                  {isLoading ? (
                     <Skeleton width="30%" height="20" />
                  ) : (
                     <div>
                        <h1> Loan Tenures</h1>
                        <span className="value">{paginationData?.count || 'N/A'}</span>
                     </div>
                  )}
                  <Button
                     className="ms-3"
                     variant="primary"
                     onClick={() => setModalVisibility('add')}
                  >
                     Add New Tenure
                  </Button>
               </div>
            </TableContent>
            <TableComponent
               columns={columns}
               rows={tableData}
               isLoading={isLoading}
               onPageChange={onPageChange}
               count={Math.ceil(paginationData.count / 15)}
               page={page}
               onRowClick={onRowClick}
            />
         </TableContainer>

         {modalVisibility && (
            <AddLoanTenure
               open={modalVisibility}
               onClose={handleOnCloseAdd}
               tenure={tenure}
               isEdit={modalVisibility === 'edit'}
            />
         )}
      </>
   );
}

export default LoanTenure;
