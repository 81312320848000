import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { Skeleton } from '@material-ui/lab';
import { Input } from 'components/input';
import LoggedInDashboard from 'components/logged-in-dashboard';
import { MoreHoriz } from '@material-ui/icons';
import moment from 'moment';
import { getAllManagers } from 'services/business';
import { TableComponent } from 'components/table';
import { Button } from 'components/button';
import { TableContainer, TableContent } from 'styles/table';
import { HeadingOne } from 'styles/typography';
import { TooltipComponent } from 'components/tooltip';
import { useDebouncedCallback } from 'use-debounce';
import { IconButton } from '@material-ui/core';
import { MenuComponent } from 'components/menu';
import TabMenu from 'components/TabMenu';
import { TabWrapper } from 'pages/pending-documents/style';
import AdminDetails from 'containers/admin-details';
import { confirm } from 'components/confirm-box';
import { useAuth } from 'hooks/auth';
import AddNewManager from 'containers/add-new-manager';
import { deactivateManager } from 'services/admin';
import MetaData from './features/metadata';
import Permissions from './features/permissions';

const RegisteredAccounts = () => {
   const { currentUser } = useAuth();
   const { permissions } = currentUser?.access || {};
   const { search } = useLocation();
   const page = new URLSearchParams(search).get('page');
   const [searchTerm, setSearchTerm] = useState('');
   const [managers, setManagers] = useState([]);
   const [isLoadingManagers, setIsLoadingManagers] = useState(false);
   const [paginationData, setPaginationData] = useState({});
   const [currentPage, setCurrentPage] = useState(1);
   const [modalVisibility, setModalVisibility] = useState(false);
   const [prospaManagerId, setProspaManagerId] = useState('');
   const [noCloseIcon, setNoCloseIcon] = useState(false);
   const [newManagerModal, setNewManagerModal] = useState(false);
   const [activeTab, setActiveTab] = useState('managers');
   const [anchorEl, setAnchorEl] = useState(null);
   const open = Boolean(anchorEl);

   const options = ['View Details', 'Deactivate'];

   function handleSearch({ target }) {
      setSearchTerm(target.value);
   }
   const debounce = useDebouncedCallback(handleSearch, 600);

   const fetchAllManagers = async () => {
      setIsLoadingManagers(true);
      try {
         setManagers([]);
         setPaginationData({});
         const result = await getAllManagers({
            page,
         });
         setManagers(result.data);
         setPaginationData(result);
      } catch (err) {
         return err;
      }
      setIsLoadingManagers(false);
   };

   useEffect(() => {
      fetchAllManagers();
   }, [searchTerm, page]);

   const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
   };

   const handleClose = () => {
      setAnchorEl(null);
   };

   function handleOpenModal() {
      setModalVisibility(true);
      setNoCloseIcon(false);
   }
   function onRowClick({ row }) {
      setProspaManagerId(row.prospa_manager_id);
   }

   const handleDeactivateAccount = async () => {
      confirm({
         confirmText: 'Are you sure want to deactivate the account?',
         isLoadingManagers: false,
         onConfirm: async () => {
            try {
               const result = await deactivateManager(prospaManagerId);
               setIsLoadingManagers(false);
               toast.success(result?.message || 'Manager has been succesfully deactivated');
               fetchAllManagers();
            } catch (err) {
               toast.error(err.message || err.custom_message);
               setIsLoadingManagers(false);
            }
         },
      });
   };

   function onPageChange(e, pageNumber) {
      setCurrentPage(pageNumber);
   }

   const columns = [
      {
         field: '',
         width: 0,
         renderCell: () => (
            <div>
               <IconButton
                  aria-label="more"
                  aria-controls="long-menu"
                  aria-haspopup="true"
                  onClick={handleClick}
               >
                  <MoreHoriz />
               </IconButton>
               <MenuComponent
                  anchorEl={anchorEl}
                  handleClose={handleClose}
                  open={open}
                  options={options}
                  optionsFns={[
                     // place the callbacks in position corresponding to the options index
                     () => handleOpenModal(),
                     () => handleDeactivateAccount(),
                  ]}
               />
            </div>
         ),
      },
      {
         field: 'first_name',
         headerName: 'Admin Name',
         renderCell: ({ row }) => (
            <TooltipComponent title={`${row.first_name} ${row.last_name}`}>
               <span
                  style={{
                     cursor: 'pointer',
                  }}
                  className="text-primary"
                  onClick={() => handleOpenModal(row.prospa_admin_id)}
               >
                  {`${row.first_name} ${row.last_name}` || 'N/A'}
               </span>
            </TooltipComponent>
         ),
      },
      {
         field: 'user_type',
         headerName: 'Role',
         width: 300,
         renderCell: ({ row }) => <span>{row?.user_type}</span>,
      },
      {
         field: 'email',
         headerName: 'Email',
         width: 300,
      },
      {
         field: 'date_joined',
         headerName: 'Date Onboarded',
         renderCell: ({ row }) => (
            <span>
               {moment(row?.date_joined).format('MMM DD, YYYY')} |{' '}
               {moment(row?.date_joined).format('h:mm a')}
            </span>
         ),
      },
   ];

   return (
      <LoggedInDashboard>
         <HeadingOne className="mb-5">Admin Manager </HeadingOne>
         <TabWrapper>
            <ul className="m-0 d-flex align-items-center">
               <TabMenu
                  text="Managers"
                  active={activeTab === 'managers' ? 'active' : ''}
                  setActiveTab={() => {
                     setActiveTab('managers');
                  }}
               />

               {permissions === 'all' && (
                  <TabMenu
                     text="Permissions"
                     active={activeTab === 'permissions' ? 'active' : ''}
                     setActiveTab={() => {
                        setActiveTab('permissions');
                     }}
                  />
               )}

               <TabMenu
                  text="Business Status"
                  active={activeTab === 'metadata' ? 'active' : ''}
                  setActiveTab={() => {
                     setActiveTab('metadata');
                  }}
               />
            </ul>
         </TabWrapper>
         {activeTab === 'managers' && (
            <>
               <TableContainer id="table">
                  <TableContent>
                     {isLoadingManagers ? (
                        <Skeleton width="30%" height="20" />
                     ) : (
                        <div>
                           <h1> Managers</h1>
                           <span className="value">{paginationData?.count || 'N/A'}</span>
                        </div>
                     )}
                     <div className="d-flex justify-content-between align-items-center">
                        <div className="me-3">
                           <Input
                              style={{ width: '300px' }}
                              placeholder="Search"
                              onChange={debounce}
                           />
                        </div>
                        <Button onClick={() => setNewManagerModal(true)}>Add New Manager</Button>
                     </div>
                  </TableContent>
                  <TableComponent
                     columns={columns}
                     rows={managers}
                     isLoading={isLoadingManagers}
                     onPageChange={onPageChange}
                     count={Math.round(paginationData.count / 15)}
                     page={currentPage}
                     pageSize={20}
                     onRowClick={onRowClick}
                  />
               </TableContainer>
               {modalVisibility && (
                  <AdminDetails
                     prospaManagerId={prospaManagerId}
                     open={modalVisibility}
                     onClose={() => setModalVisibility(false)}
                     noCloseIcon={noCloseIcon}
                  />
               )}
               {newManagerModal && (
                  <AddNewManager
                     open={newManagerModal}
                     onClose={() => setNewManagerModal(false)}
                     fetchAllManagers={fetchAllManagers}
                  />
               )}
            </>
         )}

         {activeTab === 'metadata' && <MetaData activeTab={activeTab} />}

         {activeTab === 'permissions' && <Permissions activeTab={activeTab} />}
      </LoggedInDashboard>
   );
};

export default RegisteredAccounts;
