import { useEffect, useState } from 'react';
import { StatsCards } from 'components/stats-cards';
import toast from 'react-hot-toast';
import { getAdminStats } from 'services/admin';
import { formatStatsTime } from 'utils/formatter';

function Index() {
   const [stat, setStat] = useState(null);

   useEffect(() => {
      (async function intializeStat() {
         try {
            const stats = await getAdminStats();
            const {
               new_average_approval,
               new_average_activation,
               new_average_followup,
               total_approved_accounts,
               total_approved_docs,
               total_document_accepted,
               total_document_rejected,
               total_pre_approved_accounts,
            } = stats?.data;
            setStat({
               new_average_approval: formatStatsTime(new_average_approval),
               new_average_activation: formatStatsTime(new_average_activation),
               new_average_followup: formatStatsTime(new_average_followup),
               total_approved_accounts,
               total_approved_docs,
               total_document_accepted,
               total_document_rejected,
               total_pre_approved_accounts,
            });
         } catch ({ message }) {
            toast.error(message);
         }
      })();
   }, []);
   return (
      <div>
         <StatsCards data={stat || []} />
      </div>
   );
}

export default Index;
