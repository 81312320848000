/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Skeleton } from '@material-ui/lab';
import moment from 'moment';
import toast from 'react-hot-toast';
// import FilterListIcon from '@material-ui/icons/FilterList';
// import { useDebouncedCallback } from 'use-debounce';
import { numberWithCommas } from 'utils/others';
import { getSavingsInterestLog } from 'services/savings';
import { TooltipComponent } from 'components/tooltip';
// import { Input } from 'components/input';
import { TableComponent } from 'components/table';
import { TableContainer, TableContent } from 'styles/table';
import BusinessDetails from 'containers/business-details';
import { SavingsFilterModal } from '../filter-modal';
// import { FilterContainer } from '../../style';

export default function InterestLog() {
   const [modalVisibility, setModalVisibility] = useState(false);
   const [isLoading, setIsLoading] = useState(false);
   const [tableData, setTableData] = useState([]);
   const [paginationData, setPaginationData] = useState({});
   const [currentPage, setCurrentPage] = useState(1);
   const [searchTerm] = useState('');
   const [bizAccount, setBizAccount] = useState('');
   const [modalVisibilityFilter, setModalVisibilityFilter] = useState(false);
   const [filterData, setfilterData] = useState({
      start_date: moment().subtract('3', 'months').format('YYYY-MM-DD'),
      end_date: moment().format('YYYY-MM-DD'),
   });
   const [filterPayload, setFilterPayload] = useState(filterData);

   useEffect(() => {
      (async () => {
         setIsLoading(true);
         const payload = {
            ...(searchTerm !== '' && { business_name: searchTerm }),
            page: currentPage,
            ...filterPayload,
         };
         setTableData([]);
         try {
            Promise.all([getSavingsInterestLog(payload)]).then(([interestLog]) => {
               setPaginationData(interestLog);
               setTableData(interestLog.results);
            });
         } catch (e) {
            toast.error(e.message);
         }
         setIsLoading(false);
      })();
   }, [currentPage, searchTerm, filterPayload]);

   function onPageChange(e, pageNumber) {
      setCurrentPage(pageNumber);
   }

   // function handleSearch({ target }) {
   //    setSearchTerm(target.value);
   // }

   function onRowClick({ row }) {
      setBizAccount(row);
   }

   // const debounce = useDebouncedCallback(handleSearch, 600);

   function handleOpenModal() {
      setModalVisibility('details');
   }

   const columns = [
      {
         field: 'business_name',
         headerName: 'Business Name',
         renderCell: ({ row }) => (
            <TooltipComponent title={row.business_name}>
               <span
                  style={{
                     cursor: 'pointer',
                  }}
                  className="text-primary"
                  onClick={() => handleOpenModal(row.business_name)}
               >
                  {row.business_name || 'N/A'}
               </span>
            </TooltipComponent>
         ),
      },
      {
         field: 'amount',
         headerName: 'Amount',
         renderCell: ({ row }) => (
            <span style={{ textTransform: 'capitalize' }}>{`₦ ${numberWithCommas(
               row.amount,
            )}`}</span>
         ),
      },
      {
         field: 'interest_earned',
         headerName: 'Total interest earned',
         renderCell: ({ row }) => (
            <span style={{ textTransform: 'capitalize' }}>{`₦ ${numberWithCommas(
               row.interest_earned,
            )}`}</span>
         ),
      },
      {
         field: 'created',
         headerName: 'Date',
         renderCell: ({ row }) => (
            <TooltipComponent title={moment(row.created).format('DD MMM, YYYY')}>
               {moment(row.created).format('DD MMM, YYYY')} |{' '}
               <span style={{ opacity: '.5' }}>{moment(row.created).format('HH:mm A')}</span>
            </TooltipComponent>
         ),
      },
   ];
   return (
      <div>
         <TableContainer>
            <TableContent>
               {isLoading ? (
                  <Skeleton width="30%" height="20" />
               ) : (
                  <div>
                     <h1> Interest Log</h1>
                     <span className="value">{paginationData?.count || 'N/A'}</span>
                  </div>
               )}
               {/* <div className="d-flex justify-content-between">
                  <div>
                     <Input style={{ width: '300px' }} placeholder="Search" onChange={debounce} />
                  </div>
                  <FilterContainer onClick={() => setModalVisibilityFilter(true)}>
                     Filter By <FilterListIcon style={{ marginLeft: '10px' }} />
                  </FilterContainer>
               </div> */}
            </TableContent>
            <TableComponent
               columns={columns}
               rows={tableData}
               isLoading={isLoading}
               onPageChange={onPageChange}
               count={Math.ceil(paginationData.count / 15)}
               onRowClick={onRowClick}
            />
         </TableContainer>

         <SavingsFilterModal
            open={modalVisibilityFilter}
            onClose={() => setModalVisibilityFilter(false)}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            setfilterData={setfilterData}
            filterData={filterData}
            setFilterPayload={setFilterPayload}
         />

         {modalVisibility && (
            <BusinessDetails
               businessAccountId={bizAccount.business_id}
               open={modalVisibility}
               onClose={() => setModalVisibility(false)}
            />
         )}
      </div>
   );
}
