import React, { useState } from 'react';
import { bool, func, string } from 'prop-types';
import toast from 'react-hot-toast';
import { InputWrapper } from 'styles/form';
import { updateRates } from 'services/international-transactions';
import Modal from 'components/modal';
import { confirm } from 'components/confirm-box';
import { NumberInput } from 'components/input';
import { Button } from 'components/button';
import getSymbolFromCurrency from 'currency-symbol-map';

export const UpdateRateModal = ({ open, onClose, selectedData, fetchAllCountries }) => {
   const { rate, id, to_currency, from_currency } = selectedData;

   const [isLoading, setIsLoading] = useState(false);
   const [updatedRate, setUpdatedRate] = useState(rate);

   const onSubmit = () => {
      setIsLoading(true);
      confirm({
         confirmText: 'Are you sure you want to update the rate?',
         isLoading: false,
         onConfirm: async () => {
            const payload = {
               rate: updatedRate,
            };
            try {
               const res = await updateRates(id, payload);
               toast.success(res?.message || 'Rate successfully updated.');
               fetchAllCountries();
               setIsLoading(false);
               onClose();
            } catch (e) {
               toast.error(e?.message);
               setIsLoading(false);
               onClose();
            }
         },
      });
   };

   return (
      <Modal open={open} onClose={onClose} center title="Update Rate">
         <div>
            <InputWrapper className="grid-2-2">
               <NumberInput
                  className="wrap-label-input"
                  label={`This rate specifies what 1 ${getSymbolFromCurrency(
                     from_currency,
                  )} is equal to  in ${getSymbolFromCurrency(to_currency)}`}
                  prefix={getSymbolFromCurrency(to_currency)}
                  name={rate}
                  value={parseFloat(updatedRate)}
                  onChange={(val) => setUpdatedRate(val.floatValue)}
               />
            </InputWrapper>
         </div>
         <InputWrapper
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
         >
            <div>
               <Button
                  variant="primary"
                  type="button"
                  onClick={onSubmit}
                  isLoading={isLoading}
                  disabled={isLoading}
                  fullWidth
               >
                  Update
               </Button>
            </div>
         </InputWrapper>
      </Modal>
   );
};

UpdateRateModal.propTypes = {
   open: bool.isRequired,
   onClose: func.isRequired,
   selectedData: string.isRequired,
   fetchAllCountries: func.isRequired,
};
