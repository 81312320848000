import PropTypes from 'prop-types';
import { Info, SectionTitle } from 'styles/layout';
import { pin_reset, deactivate_2fa, resend_otp, can_approve_otp } from 'utils/user_actions';
import PinReset from './pin-reset';
import DeActivate2FA from './deactivate-2fa';
import ResendOTP from './resend-otp';
import PasswordReset from './password-reset';

const Security = ({ permissions }) => {
   const checkPermission = (action) => permissions?.includes(action) || permissions === 'all';

   return (
      <>
         {checkPermission(pin_reset) && (
            <Info className="mb-2">
               <b>PIN RESET</b>
               <PinReset />
            </Info>
         )}

         {checkPermission(can_approve_otp) && (
            <>
               <SectionTitle />
               <Info className="mb-2">
                  <b>PASSWORD RESET</b>
                  <PasswordReset />
               </Info>
            </>
         )}

         {checkPermission(deactivate_2fa) && (
            <>
               <SectionTitle />
               <Info className="mb-2">
                  <b>DEACTIVATE 2FA</b>
                  <DeActivate2FA />
               </Info>
            </>
         )}

         {checkPermission(resend_otp) && (
            <>
               <SectionTitle />
               <Info className="mb-2">
                  <b>RESEND OTP</b>
                  <ResendOTP />
               </Info>
            </>
         )}
      </>
   );
};

Security.propTypes = {
   permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default Security;
