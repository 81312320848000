import { useEffect, useCallback, useState } from 'react';
import PropTypes, { func } from 'prop-types';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessage } from '@hookform/error-message';
import useTransactionsSettings from 'hooks/transactionsSettings';
import { NumberInput } from 'components/input';
import { confirm } from 'components/confirm-box';
import { Button } from 'components/button';
import { updateSystemTransactionLimit, updateProspaTransactionLimit } from 'services/transactions';
import classNames from 'classnames';
import { numberWithCommas } from 'utils/others';

const PROSPA_TRANSFER_LIMIT = 'prospa_transfer_limit';

export default function TransferLimits({ limits, canEdit, prospaLimit, fetchProspaTransferLimit }) {
   const [existingValues, setExistingValues] = useState({});
   const [loading, setLoading] = useState(false);
   const { fetchAllSettings } = useTransactionsSettings();
   const validationMsg = 'Please enter a value';

   const defaultValues = {
      business_daily: 0,
      business_single: 0,
      freelancer_daily: 0,
      freelancer_single: 0,
      prospa_transfer_limit: 0,
   };

   const schema = yup.object().shape({
      business_daily: yup.number().positive().required(),
      business_single: yup.number().positive().required(),
      freelancer_daily: yup.number().positive().required(),
      freelancer_single: yup.number().positive().required(),
      prospa_tranfer_limit: yup.number(validationMsg).positive(validationMsg),
   });

   const {
      register,
      handleSubmit,
      setValue,
      trigger,
      watch,
      formState: { errors },
   } = useForm({
      defaultValues,
      mode: 'onChange',
      resolver: yupResolver(schema),
      shouldFocusError: true,
   });

   useEffect(() => {
      register('business_daily');
      register('business_single');
      register('freelancer_daily');
      register('freelancer_single');
      register(PROSPA_TRANSFER_LIMIT);
   }, []);

   const settings = ['business_single', 'business_daily', 'freelancer_daily', 'freelancer_single'];

   const setDefaultLimits = async () => {
      const values = limits?.value;
      settings.forEach((item) => setValue(item, values[item]));
      setExistingValues(values);
   };

   useEffect(() => {
      if (limits?.value) {
         setDefaultLimits();
      }
   }, [limits]);

   const sharedOnChange = useCallback(async ({ name, value }) => {
      setValue(name, value);
      await trigger(name);
   }, []);

   const mapLimitToKey = {
      business_daily: 'business_daily_transaction_limit',
      business_single: 'business_single_transaction_limit',
      freelancer_daily: 'freelancer_daily_transaction_limit',
      freelancer_single: 'freelancer_single_transaction_limit',
   };

   const renderErrorMessage = (name) => (
      <ErrorMessage
         errors={errors}
         name={name}
         render={({ message }) => <p className="input-error-message text-danger">{message}</p>}
      />
   );

   const handleUpdateProspaTransactionLimit = async ({ data, name }) => {
      setLoading(name);
      try {
         const payload = {
            amount: data.prospa_transfer_limit,
         };
         const response = await updateProspaTransactionLimit(payload);
         confirm({
            proceedBtnText: 'Close',
            hide: { cancel: true },
            confirmText: response?.message || `Action successful!`,
            onConfirm: () => {
               setValue(PROSPA_TRANSFER_LIMIT, 0);
               fetchProspaTransferLimit();
            },
         });
      } catch (e) {
         confirm({
            cancelBtnColor: '#0E55CF',
            cancelBtnText: 'Close',
            hide: { proceed: true },
            confirmText: e?.message || `Action failed!`,
         });
      }
      setLoading(false);
   };

   const onSubmit = async (value) => {
      const { data, name } = value;

      confirm({
         proceedBtnText: 'Proceed',
         cancelBtnText: 'Cancel',
         confirmText: `By clicking confirm below you will be updating the ${name.replaceAll(
            '_',
            ' ',
         )} transaction limit for the entire member base. Only confirm if you are sure you want to take this action`,
         onConfirm: async () => {
            if (name === PROSPA_TRANSFER_LIMIT) return handleUpdateProspaTransactionLimit(value);
            setLoading(name);
            try {
               const key = mapLimitToKey[name];
               const payload = {
                  [key]: data[name],
               };
               const response = await updateSystemTransactionLimit(payload);
               confirm({
                  proceedBtnText: 'Close',
                  hide: { cancel: true },
                  confirmText: response?.message || `Action successful!`,
                  onConfirm: fetchAllSettings,
               });
            } catch (e) {
               confirm({
                  cancelBtnColor: '#0E55CF',
                  cancelBtnText: 'Close',
                  hide: { proceed: true },
                  confirmText: e?.message || `Action failed!`,
               });
            }

            setLoading(false);
         },
      });
   };

   const business_daily = watch('business_daily');
   const business_single = watch('business_single');
   const freelancer_daily = watch('freelancer_daily');
   const freelancer_single = watch('freelancer_single');
   const prospa_transfer_limit = watch(PROSPA_TRANSFER_LIMIT);

   const limitsList = [
      {
         sectionTitle: 'Max single transfer [Freelance]',
         label: 'e.g members can make a single transfer of the amount set below [Maximum]',
         name: 'freelancer_single',
         value: freelancer_single,
      },
      {
         sectionTitle: 'Max single transfer [Business]',
         label: 'e.g members can make a single transfer of the amount set below [Maximum]',
         name: 'business_single',
         value: business_single,
      },
      {
         sectionTitle: 'Daily transfer limit [Freelancer]',
         label: 'This is the cumulative amount a Freelancer account can send in one day',
         name: 'freelancer_daily',
         value: freelancer_daily,
      },
      {
         sectionTitle: 'Daily transfer limit [Business]',
         label: 'This is the cumulative amount a Business account can send in one day',
         name: 'business_daily',
         value: business_daily,
      },
      {
         sectionTitle: 'International transfer limit (Freelancer)',
         label: 'This is the cumulative amount an account can send in one day',
      },
      {
         sectionTitle: 'International transfer limit (Business)',
         label: 'This is the cumulative amount an account can send in one day',
      },
      {
         sectionTitle: 'Daily Ouflow limit (Prospa)',
         label: 'This is the cumulative amount Prospa can send out in one day. Note that this value will be added to existing limit',
         name: PROSPA_TRANSFER_LIMIT,
         value: prospa_transfer_limit,
         validation: { valid: !!prospa_transfer_limit },
         extra: (
            <ul className="mb-3">
               <li>Balance: ₦{numberWithCommas(prospaLimit.balance)}</li>
               <li>Current Maximum Limit: ₦{numberWithCommas(prospaLimit.max_limit)}</li>
            </ul>
         ),
      },
   ];

   return (
      <div className="border-top row pt-5">
         {limitsList.map(({ sectionTitle, label, name, value, extra, validation }, index) => (
            <form
               className={classNames('col-lg-4 mb-5', {
                  'pe-5': (index + 1) % 3 !== 0,
               })}
               onSubmit={handleSubmit((data) => onSubmit({ data, name }))}
            >
               <div className="mb-4">
                  <h3 className="mb-3">{sectionTitle}</h3>
                  {extra}
                  <NumberInput
                     label={label}
                     className="wrap-label-input"
                     placeholder=""
                     name={name}
                     value={value}
                     disabled={!canEdit}
                     onChange={({ floatValue }) => sharedOnChange({ name, value: floatValue || 0 })}
                  />
                  {renderErrorMessage(name)}
               </div>
               {canEdit && (
                  <Button
                     className="w-100"
                     type="submit"
                     {...(validation
                        ? { disabled: !validation?.valid }
                        : { disabled: existingValues[name] === value })}
                     isLoading={loading === name}
                  >
                     Update
                  </Button>
               )}
            </form>
         ))}
      </div>
   );
}

TransferLimits.propTypes = {
   limits: PropTypes.objectOf().isRequired,
   // fetchAllSettings: PropTypes.func.isRequired,
   canEdit: PropTypes.bool.isRequired,
   prospaLimit: PropTypes.objectOf().isRequired,
   fetchProspaTransferLimit: func.isRequired,
};
