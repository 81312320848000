import { useCallback, useEffect, useState } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import classNames from 'classnames';
import { Grid, Switch } from '@material-ui/core';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessage } from '@hookform/error-message';
import { Spinner } from 'components/spinner';
import { Button } from 'components/button';
import { confirm } from 'components/confirm-box';
import { NumberInput } from 'components/input';
import { getCardSettings, updateCardSettings } from 'services/cards';

export default function CardSettingsTab() {
   const [loading, setLoading] = useState(false);
   const [virtualCardToggle, setVirtualCardToggle] = useState(null);
   const [physicalCardToggle, setPhysicalCardToggle] = useState(null);
   const [existingValues, setExistingValues] = useState({});
   const [cardSettingsId, setCardSettingsId] = useState('');
   const [settingsData, setSettingsData] = useState({});

   const fetchSettings = async () => {
      setLoading(true);
      try {
         const settingRes = await getCardSettings();
         const [, ...settingArray] = Object.values(settingRes);

         const cardConfig = settingArray[0].find((item) => item.name === 'OTHER__CARD_SETTINGS');

         setSettingsData(cardConfig);
         setCardSettingsId(cardConfig?.id);
         setLoading(false);
      } catch (e) {
         toast.error(e?.message || 'Something went wrong');
         setLoading(false);
      }
   };

   useEffect(() => {
      fetchSettings();
   }, []);

   useEffect(() => {
      setVirtualCardToggle(settingsData?.value?.allow_card_virtual);
      setPhysicalCardToggle(settingsData?.value?.allow_card_physical);
   }, [settingsData]);

   const handleVirtualCardToggle = (type) => {
      confirm({
         confirmText: 'Are you sure want to proceed?',
         onConfirm: async () => {
            if (type === 'on') {
               setVirtualCardToggle(true);
            }

            if (type === 'off') {
               setVirtualCardToggle(false);
            }

            const payload =
               type === 'on'
                  ? {
                       value: { ...settingsData?.value, allow_card_virtual: true },
                       name: 'OTHER__CARD_SETTINGS',
                       category: settingsData?.category,
                    }
                  : {
                       value: { ...settingsData?.value, allow_card_virtual: false },
                       name: 'OTHER__CARD_SETTINGS',
                       category: settingsData?.category,
                    };
            try {
               const { id } = settingsData;
               const res = await updateCardSettings(id, payload);
               fetchSettings();
               toast.success(res?.message || 'Successful');
            } catch (e) {
               toast.error(e?.message);
            }
         },
      });
   };

   const handlePhysicalCardToggle = (type) => {
      confirm({
         confirmText: 'Are you sure want to proceed?',
         onConfirm: async () => {
            if (type === 'on') {
               setPhysicalCardToggle(true);
            }

            if (type === 'off') {
               setPhysicalCardToggle(false);
            }

            const payload =
               type === 'on'
                  ? {
                       value: { ...settingsData?.value, allow_card_physical: true },
                       name: 'OTHER__CARD_SETTINGS',
                       category: settingsData?.category,
                    }
                  : {
                       value: { ...settingsData?.value, allow_card_physical: false },
                       name: 'OTHER__CARD_SETTINGS',
                       category: settingsData?.category,
                    };
            try {
               const { id } = settingsData;
               const res = await updateCardSettings(id, payload);
               fetchSettings();
               toast.success(res?.message || 'Successful');
            } catch (e) {
               toast.error(e?.message);
            }
         },
      });
   };

   const defaultValues = {
      daily_limit: 0,
      monthly_limit: 0,
      delivery_fee: 0,
      virtual_card_payment_amount: 0,
      virtual_card_exchange_extra_rate: 0,
      delivery_period: 0,
   };

   const schema = yup.object().shape({
      daily_limit: yup.number().min(0).required(),
      monthly_limit: yup.number().min(0).required(),
      delivery_fee: yup.number().min(0).required(),
      virtual_card_payment_amount: yup.number().min(0).required(),
      virtual_card_exchange_extra_rate: yup.number().min(0).required(),
      delivery_period: yup.number().min(0).required(),
   });

   const {
      register,
      handleSubmit,
      setValue,
      trigger,
      watch,
      formState: { errors },
   } = useForm({
      defaultValues,
      mode: 'onChange',
      resolver: yupResolver(schema),
      shouldFocusError: true,
   });

   useEffect(() => {
      register('daily_limit');
      register('monthly_limit');
      register('delivery_fee');
      register('virtual_card_payment_amount');
      register('virtual_card_exchange_extra_rate');
      register('delivery_period');
   }, []);

   const settings = [
      'daily_limit',
      'monthly_limit',
      'delivery_fee',
      'virtual_card_payment_amount',
      'virtual_card_exchange_extra_rate',
      'delivery_period',
   ];

   const setDefaultLimits = async () => {
      const values = settingsData?.value;
      settings.forEach((item) => setValue(item, values[item]));
      setExistingValues(values);
   };

   useEffect(() => {
      if (settingsData?.value) {
         setDefaultLimits();
      }
   }, [settingsData]);

   const sharedOnChange = useCallback(async ({ name, value }) => {
      setValue(name, value);
      await trigger(name);
   }, []);

   const renderErrorMessage = (name) => (
      <ErrorMessage
         errors={errors}
         name={name}
         render={({ message }) => <p className="input-error-message text-danger">{message}</p>}
      />
   );

   const onSubmit = async (value) => {
      const { data, name } = value;
      confirm({
         proceedBtnText: 'Proceed',
         cancelBtnText: 'Cancel',
         confirmText: `By clicking confirm below you will be updating the ${name.replace(
            '_',
            ' ',
         )} for the entire member base. Only confirm if you are sure you want to take this action`,
         onConfirm: async () => {
            setLoading(name);
            try {
               const payload = {
                  name: 'OTHER__CARD_SETTINGS',
                  category: settingsData?.category,
                  value: { ...data, payment_wallet: '2275', allow_card: virtualCardToggle },
               };
               const response = await updateCardSettings(cardSettingsId, payload);
               confirm({
                  proceedBtnText: 'Close',
                  hide: { cancel: true },
                  confirmText: response?.message || `Action successful!`,
                  onConfirm: fetchSettings,
               });
            } catch (e) {
               confirm({
                  cancelBtnColor: '#0E55CF',
                  cancelBtnText: 'Close',
                  hide: { proceed: true },
                  confirmText: e?.message || `Action failed!`,
               });
            }
            setLoading(false);
         },
      });
   };

   const daily_limit = watch('daily_limit');
   const monthly_limit = watch('monthly_limit');
   const delivery_fee = watch('delivery_fee');
   const virtual_card_payment_amount = watch('virtual_card_payment_amount');
   const virtual_card_exchange_extra_rate = watch('virtual_card_exchange_extra_rate');
   const delivery_period = watch('delivery_period');

   const cardSettingsOptions = [
      {
         sectionTitle: 'Daily Limit',
         label: 'e.g members can make maximum daily transfer of the amount set below',
         name: 'daily_limit',
         value: daily_limit,
         prefix: '₦',
         suffix: ' ',
      },
      {
         sectionTitle: 'Monthly Limit',
         label: 'e.g members can make maximum monthly transfer of the amount set below',
         name: 'monthly_limit',
         value: monthly_limit,
         prefix: '₦',
         suffix: ' ',
      },
      {
         sectionTitle: 'Delivery Fees',
         label: 'This is the amount to be paid for delivery of a card',
         name: 'delivery_fee',
         value: delivery_fee,
         prefix: '₦',
         suffix: ' ',
      },
      {
         sectionTitle: 'Virtual Card Payment Amount',
         label: 'This is the cumulative card payment amount for virtual card',
         name: 'virtual_card_payment_amount',
         value: virtual_card_payment_amount,
         prefix: '₦',
         suffix: ' ',
      },
      {
         sectionTitle: 'Virtual Card Exchange Rate',
         label: 'This is virtual card extra charge on exchange rate(percentage based)',
         name: 'virtual_card_exchange_extra_rate',
         value: virtual_card_exchange_extra_rate,
         prefix: ' ',
         suffix: '%',
      },
      {
         sectionTitle: 'Delivery Period',
         label: 'This is the default estimated number of days for card delivery',
         name: 'delivery_period',
         value: delivery_period,
         prefix: ' ',
         suffix: ' ',
      },
   ];

   return (
      <>
         {loading ? (
            <Spinner />
         ) : (
            <>
               <div className="row">
                  <div className="mb-5">
                     <b>Virtual Card (Global)</b>
                     <Grid
                        component="label"
                        container
                        alignItems="center"
                        marginLeft=""
                        spacing={1}
                     >
                        <Grid item>
                           <b>Off</b>
                        </Grid>
                        <Grid item>
                           <Switch
                              checked={virtualCardToggle}
                              onClick={
                                 virtualCardToggle
                                    ? () => handleVirtualCardToggle('off')
                                    : () => handleVirtualCardToggle('on')
                              }
                           />
                        </Grid>
                        <Grid item>
                           <b>On</b>
                        </Grid>
                     </Grid>
                  </div>
                  <div className="mb-5">
                     <b>Physical Card (Global)</b>
                     <Grid
                        component="label"
                        container
                        alignItems="center"
                        marginLeft=""
                        spacing={1}
                     >
                        <Grid item>
                           <b>Off</b>
                        </Grid>
                        <Grid item>
                           <Switch
                              checked={physicalCardToggle}
                              onClick={
                                 physicalCardToggle
                                    ? () => handlePhysicalCardToggle('off')
                                    : () => handlePhysicalCardToggle('on')
                              }
                           />
                        </Grid>
                        <Grid item>
                           <b>On</b>
                        </Grid>
                     </Grid>
                  </div>
               </div>
               <div className="row border-top pt-5">
                  {cardSettingsOptions.map(
                     ({ sectionTitle, label, name, value, prefix, suffix }, index) => (
                        <form
                           className={classNames('col-lg-4 mb-5', {
                              'pe-5': (index + 1) % 3 !== 0,
                           })}
                           onSubmit={handleSubmit((data) => onSubmit({ data, name }))}
                        >
                           <div className="mb-4">
                              <h3 className="mb-3">{sectionTitle}</h3>
                              <NumberInput
                                 label={label}
                                 className="wrap-label-input"
                                 placeholder=""
                                 prefix={prefix}
                                 suffix={suffix}
                                 name={name}
                                 value={value}
                                 onChange={({ floatValue }) =>
                                    sharedOnChange({ name, value: floatValue || 0 })
                                 }
                              />
                              {renderErrorMessage(name)}
                           </div>
                           <Button
                              className="w-100"
                              type="submit"
                              disabled={existingValues[name] === value}
                              isLoading={loading === name}
                           >
                              Update
                           </Button>
                        </form>
                     ),
                  )}
               </div>
            </>
         )}
      </>
   );
}
