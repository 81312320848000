import styled from 'styled-components';

export const ActivationWrapper = styled.div``;

export const PermissionsWrapper = styled.div`
   .permission-check {
      width: 15px;
      height: 15px;
   }
   .MuiListItemText-primary {
      font-size: 16px;

      :first-letter {
         text-transform: uppercase;
      }
   }
   .MuiListItemText-secondary {
      font-size: 12px;
   }
   .permission-category-text {
      font-size: 18px;
      text-transform: capitalize;
   }
`;
