import { useEffect, useState } from 'react';
import Modal from 'components/modal';
import { useForm } from 'react-hook-form';
import { confirm } from 'components/confirm-box';
import { useBizDetailsModal } from 'hooks/bizDetailsModal';
import { bool, func, number } from 'prop-types';
import {
   getSingleBusinessRegistrations,
   convertApplicationToBusiness,
   sendDocumentsForProcessing,
} from 'services/unregistered_biz';
import toast from 'react-hot-toast';
// import { useAuth } from 'hooks/auth';
import { Spinner } from 'components/spinner';
import { Button } from 'components/button';
import { Input } from 'components/input';
import Log from './log';
import Notes from './notes';
import Overview from './overview';
import SendMessage from './sendMessage';
import { Navigation, NavigationTab } from './style';

const BusinessDetails = ({ open, onClose, businessAccountId }) => {
   const { state, setActiveTab } = useBizDetailsModal();
   const [bizDetails, setBizDetails] = useState({});
   const [loading, setLoading] = useState(true);
   const [bizNameModal, setBizNameModal] = useState(false);
   const [converting, setConverting] = useState(false);
   // const { currentUser } = useAuth();
   const { activeTab } = state;

   const {
      register,
      handleSubmit,
      formState: { errors },
      setValue,
   } = useForm({
      mode: 'onChange',
   });

   useEffect(() => {
      // if (
      //    currentUser?.access?.permissions !== 'all' &&
      //    !currentUser?.access?.pageAccess.includes('read_bizness_registration_details')
      // ) {
      //    toast.error('You are not authorize to perform this action');
      //    return onClose();
      // }
   }, []);

   const fetchBusinessDetails = async () => {
      if (businessAccountId) {
         setLoading(true);
         try {
            const result = await getSingleBusinessRegistrations(businessAccountId);
            if (result.ok) {
               setBizDetails(result.data);
            }
            setLoading(false);
         } catch (err) {
            toast.error(err.message || err.custom_message);
         }
         setLoading(false);
      }
   };

   const handleConvertApplication = (data) => {
      confirm({
         confirmText: 'Are you sure you want to perform this action?',
         isLoading: false,
         onConfirm: async () => {
            const { biz_name } = data;
            setConverting(true);
            try {
               const response = await convertApplicationToBusiness({
                  unregistered_biz_id: businessAccountId,
                  biz_status: 'approve',
                  biz_name,
               });
               toast.success(response?.message, { duration: 3000 });
               setValue('biz_name', '');
               setBizNameModal(false);
               await fetchBusinessDetails();
            } catch (err) {
               toast.error(err.message || err.custom_message);
            }
            setConverting(false);
         },
      });
   };

   const handleSendDocument = () => {
      confirm({
         confirmText: 'Are you sure you want to perform this action?',
         isLoading: false,
         onConfirm: async () => {
            try {
               const response = await sendDocumentsForProcessing({
                  unregistered_biz_id: businessAccountId,
               });
               toast.success(response?.message, { duration: 3000 });
               await fetchBusinessDetails();
            } catch (err) {
               toast.error(err.message || err.custom_message);
            }
         },
      });
   };

   useEffect(() => {
      fetchBusinessDetails();
   }, []);

   const activePage = () => {
      switch (activeTab) {
         case '':
         case 'overview':
            return (
               <Overview
                  businessAccountId={businessAccountId}
                  bizDetails={bizDetails}
                  fetchBusinessDetails={fetchBusinessDetails}
               />
            );

         case 'notes':
            return <Notes businessAccountId={businessAccountId} />;

         case 'log':
            return <Log businessAccountId={businessAccountId} />;

         case 'message':
            return <SendMessage businessAccountId={businessAccountId} />;

         default:
            return 'No Screen';
      }
   };

   return !loading ? (
      <>
         <Modal
            open={open}
            onClose={() => {
               onClose();
               setActiveTab('none');
            }}
            closeOnOverlayClick={false}
            title="Business Account Details"
            className="biz-details-modal"
         >
            <Navigation className="d-flex justify-content-between">
               <div className="d-flex">
                  <NavigationTab
                     className={activeTab === 'overview' ? 'active' : ''}
                     onClick={() => setActiveTab('overview')}
                  >
                     Overview
                  </NavigationTab>
                  <NavigationTab
                     className={activeTab === 'notes' ? 'active' : ''}
                     onClick={() => setActiveTab('notes')}
                  >
                     Notes
                  </NavigationTab>
                  <NavigationTab
                     className={activeTab === 'log' ? 'active' : ''}
                     onClick={() => setActiveTab('log')}
                  >
                     Log
                  </NavigationTab>
                  <NavigationTab
                     className={activeTab === 'message' ? 'active' : ''}
                     onClick={() => setActiveTab('message')}
                  >
                     Send Message
                  </NavigationTab>
               </div>

               {bizDetails.payment_status === 'has_paid' && (
                  <div>
                     <Button
                        onClick={() => setBizNameModal(true)}
                        className="ms-3"
                        disabled={bizDetails.decision_status === 'approved'}
                     >
                        Convert Application to Business Account
                     </Button>

                     <Button onClick={handleSendDocument} className="ms-3" variant="secondary">
                        Send Document for Processing
                     </Button>
                  </div>
               )}
            </Navigation>

            <div className="pt-5">{activePage()}</div>
         </Modal>
         {bizNameModal && (
            <Modal
               open={bizNameModal}
               onClose={() => setBizNameModal(false)}
               closeOnOverlayClick={false}
               title="Convert Application to Business Account"
            >
               <form onSubmit={handleSubmit(handleConvertApplication)}>
                  <Input
                     {...register('biz_name', {
                        required: true,
                     })}
                     placeholder="Business Name"
                     label="Business Account Name"
                     name="biz_name"
                     type="text"
                     defaultValue={bizDetails.pref_biz_name_one}
                  />
                  {errors.biz_name && (
                     <span className="text-danger" style={{ fontSize: '12px' }}>
                        Business name is a required field
                     </span>
                  )}
                  <div className="d-flex justify-content-end mt-4">
                     <Button
                        type="submit"
                        disabled={converting || Object.keys(errors).length > 0}
                        isLoading={converting}
                     >
                        Submit Application
                     </Button>
                  </div>
               </form>
            </Modal>
         )}
      </>
   ) : (
      <Spinner />
   );
};

BusinessDetails.propTypes = {
   open: bool.isRequired,
   onClose: func.isRequired,
   businessAccountId: number.isRequired,
};

export default BusinessDetails;
