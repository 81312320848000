import styled from 'styled-components';

export const CardsContainer = styled.div`
   display: grid;
   // grid-template-columns: repeat(${({ gridNumber }) => gridNumber && gridNumber}, 1fr);
   grid-template-columns: repeat(3, 1fr);
   column-gap: 20px;
   overflow-x: auto;
   width: 100%;
`;
export const Card = styled.div`
   padding: 2.4rem 2rem;
   background-color: #fff;
   box-shadow: -1px 2px 15px rgba(0, 0, 0, 0.1);
   border-radius: 10px;
   min-width: 300px;
   margin-bottom: 30px;

   &:first-child {
      background-color: var(--color-blue);
      color: #fff;
   }

   .text {
      font-size: 1.5rem;
      text-transform: capitalize;
   }

   .stat-filter {
      .react-select__control {
         height: 20px;
         min-height: 22px;
         width: 72px;
         font-size: 8px;
         background: transparent;
         color: #fff;
         border: 1px solid #fff;
      }

      .react-select__placeholder,
      .react-select__single-value {
         color: #ffff;
         height: 100%;

         transform: translateY(-57%);
      }

      .react-select__value-container,
      .react-select__indicators {
         height: 22px;
      }

      .react-select__indicator {
         color: white;
         width: 28px !important;
      }
   }

   .value {
      margin-top: 3.5rem;
      font-size: 2.3rem;
      font-weight: 500;
   }
`;
