import { objectOf, string } from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import DocumentCard from 'components/document-card';
import { InfoGroup, Label, OverviewSection, SectionTitle, Value } from './overview.style';

function DirectorInfo({ bizDetails }) {
   return (
      <>
         <SectionTitle>Other Director&apos;s Information</SectionTitle>

         {bizDetails?.board_partner_details?.map((partner, i) => (
            <div key={uuidv4()}>
               <p>Director {i + 1}</p>
               <OverviewSection className="director-section">
                  <InfoGroup>
                     <Label>Full Name</Label>
                     <Value>{partner.partner_full_name || 'N/A'}</Value>
                  </InfoGroup>

                  <InfoGroup>
                     <Label>Email</Label>
                     <Value>{partner.partner_email || 'N/A'}</Value>
                  </InfoGroup>

                  <InfoGroup>
                     <Label>Phone</Label>
                     <Value>{partner.partner_phone || 'N/A'}</Value>
                  </InfoGroup>

                  {partner.partner_identity_file && (
                     <DocumentCard name="Identity File" url={partner.partner_identity_file} />
                  )}
               </OverviewSection>
            </div>
         ))}
      </>
   );
}

DirectorInfo.propTypes = {
   bizDetails: objectOf(string).isRequired,
};
export default DirectorInfo;
