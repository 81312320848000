import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'classnames';
import toast from 'react-hot-toast';
import { Button } from 'components/button';
import { confirm } from 'components/confirm-box';
import { Input } from 'components/input';
import { Tag } from 'styles/tags';
import BluePlusIcon from 'assets/images/blue-plus.svg';
import RemoveIcon from 'assets/images/remove.svg';
import { deleteTicketSubCategory, addTicketSubCategory } from 'services/ticketing';
import { hasValue } from 'utils/validations';
import { SubCategoryWrapper } from '../../ticketing.style';

export default function SubCategoriesComponent({
   SubCategories,
   isEdit,
   setIsEdit,
   fetchTicketCategories,
   activeCategory,
   allowManage,
}) {
   const editActive = isEdit === 'sub_cactegory';
   const [subCategory, setSubCategory] = useState('');

   const handleAddCategory = async () => {
      if (hasValue(subCategory)) {
         try {
            await addTicketSubCategory([
               {
                  category: activeCategory,
                  sub_category: subCategory,
               },
            ]);
            setSubCategory('');
            await fetchTicketCategories();
         } catch (e) {
            toast.error(e?.message || e);
         }
      }
   };

   const handleRemoveSubCategories = async (id) => {
      const confirmConfig = {
         cancelBtnText: 'Cancel',
         proceedBtnText: 'Delete',
         proceedBtnColor: 'danger',
         cancelBtnColor: 'primary',
      };

      confirm({
         confirmText: 'Are you sure you want to delete this Sub category?',
         ...confirmConfig,
         onConfirm: async () => {
            try {
               await deleteTicketSubCategory(id);
               fetchTicketCategories();
            } catch (e) {
               toast.error(e?.message);
            }
         },
      });
   };

   return (
      <SubCategoryWrapper>
         <div>
            <div className="d-flex justify-content-between align-items-center">
               <h4>Sub Categories</h4>
               {!editActive && allowManage && (
                  <div className="category-action">
                     <Button size="small" onClick={() => setIsEdit('sub_cactegory')}>
                        Edit
                     </Button>
                  </div>
               )}
            </div>

            <div
               className={clsx({
                  'sub-actegory-edit': editActive,
               })}
            >
               <div className="d-flex justify-content-between align-items-center w-100">
                  <div className="sub-actegory-list col-6">
                     {editActive && (
                        <div className="d-flex align-items-center mb-3">
                           <Input
                              placeholder="Add a subcategory"
                              name="subcategory"
                              type="text"
                              value={subCategory}
                              onChange={({ target: { value } }) =>
                                 setSubCategory(value.toLowerCase())
                              }
                           />
                           <img
                              src={BluePlusIcon}
                              alt="plus"
                              onClick={handleAddCategory}
                              style={{ marginLeft: '-40px', zIndex: 10, cursor: 'pointer' }}
                           />
                        </div>
                     )}

                     <div
                        className={clsx({
                           'd-flex flex-wrap': editActive,
                        })}
                     >
                        {SubCategories?.map((item) => (
                           <Tag key={item} className="me-3 mb-3 align-items-center">
                              <p
                                 className={clsx('tag-body text', {
                                    'me-3': editActive,
                                 })}
                              >
                                 {item.sub_category}
                              </p>

                              {editActive && (
                                 <img
                                    src={RemoveIcon}
                                    alt="remove"
                                    onClick={() => handleRemoveSubCategories(item.id)}
                                 />
                              )}
                           </Tag>
                        ))}
                     </div>
                  </div>
                  {editActive && (
                     <Button onClick={() => setIsEdit(null)}>Update sub-category</Button>
                  )}
               </div>
            </div>
         </div>
      </SubCategoryWrapper>
   );
}

SubCategoriesComponent.propTypes = {
   SubCategories: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
   isEdit: PropTypes.string.isRequired,
   setIsEdit: PropTypes.func.isRequired,
   fetchTicketCategories: PropTypes.func.isRequired,
   activeCategory: PropTypes.number.isRequired,
   allowManage: PropTypes.bool.isRequired,
};
