import { useEffect, useState } from 'react';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import { logActivity } from 'services/activity';
import Avatar from 'assets/images/user.svg';
import { Button } from 'components/button';
import { func, objectOf, string } from 'prop-types';
import { changeStatus } from 'services/business';
import useBusinessMeta from 'hooks/businessMeta';
import { updateBusinessRegistrations, updatePaymentStatus } from 'services/unregistered_biz';
import { Input } from 'components/input';
import Select from 'react-select';
import { Spinner } from 'components/spinner';
import toast from 'react-hot-toast';
import DocumentCard from 'components/document-card';
import { AlertMessage } from 'components/message';
import { MenuComponent } from 'components/menu';
import { confirm } from 'components/confirm-box';
import {
   AvatarWrapper,
   BoolStatus,
   Info,
   InfoGroup,
   Label,
   Name,
   OverviewSection,
   OverviewWrapper,
   SectionTitle,
   TopSection,
   UserProfile,
   Value,
} from './overview.style';

const Overview = ({ businessAccountId, bizDetails, fetchBusinessDetails }) => {
   // const [bizDetails, setBizDetails] = useState({});
   const [updatingBiz, setUpdatingBiz] = useState(false);
   const [bizCurrentStatus, setBizCurrentStatus] = useState([]);
   const [loading] = useState(false);
   const [error, setError] = useState('');
   const { businessCurrentStatus } = useBusinessMeta();
   const userImage = bizDetails?.account_holder_details?.photo_url || Avatar;

   const firstName = bizDetails?.account_holder_details?.first_name || '';
   const lastName = bizDetails?.account_holder_details?.last_name || '';
   const accountHolderName = `${firstName} ${lastName}`;
   const phone = bizDetails?.account_holder_details?.phone;
   const email = bizDetails?.account_holder_details?.email;
   const [anchorEl, setAnchorEl] = useState(null);
   const openStatusMenu = Boolean(anchorEl);

   const [paymentAnchorEl, setPaymentAnchorEl] = useState(null);
   const openPaymentMenu = Boolean(paymentAnchorEl);

   const pref_biz_name_one = bizDetails?.pref_biz_name_one || '';
   const pref_biz_name_two = bizDetails?.pref_biz_name_two || '';
   const biz_sole_owner = bizDetails?.biz_sole_owner || '';
   const pref_biz_name_three = bizDetails?.pref_biz_name_three || '';
   const biz_description = bizDetails?.biz_description || '';
   const biz_address = bizDetails?.biz_address || '';
   const biz_city = bizDetails?.biz_city || '';
   const biz_state = bizDetails?.biz_state || '';
   const individual_first_name = bizDetails?.individual_first_name || '';
   const individual_last_name = bizDetails?.individual_last_name || '';
   const individual_middle_name = bizDetails?.individual_middle_name || '';
   const individual_former_name = bizDetails?.individual_former_name || '';
   const individual_gender = bizDetails?.individual_gender || '';
   const individual_nationality = bizDetails?.individual_nationality || '';
   const individual_former_nationality = bizDetails?.individual_former_nationality || '';
   const individual_identity_type = bizDetails?.individual_identity_type || '';
   const individual_occupation = bizDetails?.individual_occupation || '';
   const individual_date_of_birth = bizDetails?.individual_date_of_birth || '';
   const individual_identity_file = bizDetails?.individual_identity_file || '';

   useState(() => {}, [bizDetails]);

   const { register, handleSubmit, setValue } = useForm();
   const onSubmit = async (data) => {
      const payload = {
         ...data,
         individual_gender: data.individual_gender ? data.individual_gender : individual_gender,
         individual_identity_type: data.individual_identity_type
            ? data.individual_identity_type
            : individual_identity_type,
         unregistered_biz_id: businessAccountId,
      };
      setUpdatingBiz(true);
      try {
         const { message } = await updateBusinessRegistrations(payload);
         toast.success(message);
         fetchBusinessDetails();
      } catch (e) {
         toast.error(e.message);
      }
      setUpdatingBiz(false);
   };
   const dateCreated = moment(bizDetails?.pub_date).format('MMM DD, YYYY');
   const timeCreated = moment(bizDetails?.pub_date).format('h:mm a');

   const handleStatusButtonClick = (event) => {
      setAnchorEl(event.currentTarget);
   };

   const handleCloseStatusMenu = () => {
      setAnchorEl(null);
   };

   const changeStatusAction = async (status) => {
      const statusData = {
         biz_current_status: status,
         unregistered_biz_id: businessAccountId,
      };
      try {
         const result = await changeStatus(statusData, 'unreg');
         if (result.ok) {
            fetchBusinessDetails();
            toast.success(result?.message, { duration: 3000 });
         }
      } catch (err) {
         setError(err.message || err.custom_message);
      }
   };

   const changePaymentStatus = async (status) => {
      try {
         const response = await updatePaymentStatus({
            unregistered_biz_id: businessAccountId,
            unregistered_status: status,
         });
         toast.success(response?.message, { duration: 3000 });
         await fetchBusinessDetails();
      } catch (err) {
         setError(err.message || err.custom_message);
      }
   };

   const handleChangeStatus = async (status) => {
      confirm({
         confirmText: 'Are you sure you want to perform this action?',
         isLoading: false,
         onConfirm: () => changeStatusAction(status),
      });
   };

   const handlePaymentStatus = async (status) => {
      confirm({
         confirmText: 'Are you sure you want to perform this action?',
         isLoading: false,
         onConfirm: () => changePaymentStatus(status),
      });
   };

   async function logViewDetails() {
      try {
         await logActivity(`Viewed Business Details`, 'unreg', businessAccountId);
      } catch (e) {
         toast.error(e.message);
      }
   }

   useEffect(() => {
      if (businessCurrentStatus) {
         setBizCurrentStatus(
            businessCurrentStatus.map(({ current_status_tag }) => ({
               value: current_status_tag,
               options:
                  current_status_tag.charAt(0).toUpperCase() +
                  current_status_tag.slice(1).replaceAll('_', ' '),
            })),
         );
      }
   }, [businessCurrentStatus]);

   useEffect(() => {
      logViewDetails();
   }, []);

   return (
      <OverviewWrapper>
         <AlertMessage message={error} variant="error" />

         {loading ? (
            <Spinner />
         ) : (
            <>
               <div className="pb-5">
                  <TopSection>
                     <UserProfile>
                        <AvatarWrapper>
                           <img src={userImage} alt="account-holder-avatar" />
                        </AvatarWrapper>
                        <div>
                           <Name className="mb-2">{accountHolderName}</Name>
                           <Info>{phone}</Info>
                           <Info>{bizDetails.other_phone && bizDetails.other_phone}</Info>
                           <Info>{email}</Info>
                           <BoolStatus isTrue={bizDetails?.payment_status === 'has_paid'}>
                              {bizDetails?.payment_status === 'has_paid' ? 'Paid' : 'Unpaid'}
                           </BoolStatus>
                        </div>
                     </UserProfile>

                     <div className="d-flex">
                        <div className="me-2">
                           <Button
                              onClick={(event) => setPaymentAnchorEl(event.currentTarget)}
                              className="ms-3"
                              variant="secondary"
                           >
                              Update Payment Status
                           </Button>
                           <MenuComponent
                              anchorEl={paymentAnchorEl}
                              handleClose={() => setPaymentAnchorEl(null)}
                              open={openPaymentMenu}
                              options={['Paid', 'Unpaid']}
                              optionsFns={[
                                 () => handlePaymentStatus('has_paid'),
                                 () => handlePaymentStatus('not_paid'),
                              ]}
                           />
                        </div>

                        <Button onClick={handleStatusButtonClick} className="ms-3 bg-secondary">
                           Status Actions
                        </Button>
                        <MenuComponent
                           anchorEl={anchorEl}
                           handleClose={handleCloseStatusMenu}
                           open={openStatusMenu}
                           options={bizCurrentStatus.map((option) => option.options)}
                           optionsFns={bizCurrentStatus.map(
                              (option) => () => handleChangeStatus(option.value),
                           )}
                        />
                     </div>
                  </TopSection>
               </div>

               <form onSubmit={handleSubmit(onSubmit)}>
                  <SectionTitle>Business Details</SectionTitle>
                  <OverviewSection>
                     <InfoGroup>
                        <Label>Preferred Name 1</Label>
                        <Input
                           className="overview-input"
                           id="pref_biz_name_one"
                           // inputRef= {register("pref_biz_name_one")}
                           {...register('pref_biz_name_one')}
                           defaultValue={pref_biz_name_one}
                        />
                     </InfoGroup>

                     <InfoGroup>
                        <Label>Preferred Name 2</Label>
                        <Input
                           className="overview-input"
                           id="pref_biz_name_two"
                           // inputRef={register("pref_biz_name_two")}
                           {...register('pref_biz_name_two')}
                           defaultValue={pref_biz_name_two}
                        />
                     </InfoGroup>

                     <InfoGroup>
                        <Label>Preferred Name 3</Label>
                        <Input
                           className="overview-input"
                           id="pref_biz_name_three"
                           // inputRef={register("pref_biz_name_three")}
                           {...register('pref_biz_name_three')}
                           defaultValue={pref_biz_name_three}
                        />
                     </InfoGroup>
                     <InfoGroup>
                        <Label>Description</Label>
                        <Input
                           className="overview-input"
                           id="biz_description"
                           defaultValue={biz_description}
                           // inputRef={register("biz_description")}
                           {...register('biz_description')}
                        />
                     </InfoGroup>

                     <InfoGroup>
                        <Label>Business Sole Owner</Label>
                        <BoolStatus isTrue={biz_sole_owner}>
                           {biz_sole_owner ? 'Yes' : 'No'}
                        </BoolStatus>
                     </InfoGroup>

                     <InfoGroup>
                        <Label>Individual First Name</Label>
                        <Input
                           className="overview-input"
                           id="individual_first_name"
                           defaultValue={individual_first_name}
                           // inputRef={register("individual_first_name")}
                           {...register('individual_first_name')}
                        />
                     </InfoGroup>

                     <InfoGroup>
                        <Label>Individual Last Name</Label>
                        <Input
                           className="overview-input"
                           id="individual_last_name"
                           defaultValue={individual_last_name}
                           // inputRef={register("individual_last_name")}
                           {...register('individual_last_name')}
                        />
                     </InfoGroup>

                     <InfoGroup>
                        <Label>Individual Middle Name</Label>
                        <Input
                           className="overview-input"
                           id="individual_middle_name"
                           defaultValue={individual_middle_name}
                           // inputRef={register("individual_middle_name")}
                           {...register('individual_middle_name')}
                        />
                     </InfoGroup>

                     <InfoGroup>
                        <Label>Individual Former Name</Label>
                        <Input
                           className="overview-input"
                           id="individual_former_name"
                           defaultValue={individual_former_name}
                           // inputRef={register("individual_former_name")}
                           {...register('individual_former_name')}
                        />
                     </InfoGroup>

                     <InfoGroup>
                        <Label>Individual Gender</Label>
                        <Select
                           className="overview-input"
                           id="individual_gender"
                           // inputRef={register("individual_gender")}
                           {...register('individual_gender')}
                           defaultValue={{ label: individual_gender, value: individual_gender }}
                           options={[
                              { value: 'female', label: 'Female' },
                              { value: 'male', label: 'Male' },
                           ]}
                           onChange={(val) => setValue('individual_gender', val.value)}
                        >
                           {/* <option value="female">Female</option>
                           <option value="male">Male</option>
                           <option value="others">Others</option> */}
                        </Select>
                     </InfoGroup>

                     <InfoGroup>
                        <Label>Individual Nationality</Label>
                        {/* <CountryDropdown
                           value=""
                           onChange={(val) => console.log(val)} 
                        /> */}
                        <Input
                           className="overview-input"
                           id="individual_nationality"
                           defaultValue={individual_nationality}
                           // inputRef={register("individual_nationality")}
                           {...register('individual_nationality')}
                        />
                     </InfoGroup>

                     <InfoGroup>
                        <Label>Individual Former Nationality</Label>
                        <Input
                           className="overview-input"
                           id="individual_former_nationality"
                           defaultValue={individual_former_nationality}
                           // inputRef={register("individual_former_nationality")}
                           {...register('individual_former_nationality')}
                        />
                     </InfoGroup>

                     <InfoGroup>
                        <Label>Individual Identity Type</Label>
                        <Select
                           className="overview-input"
                           id="individual_gender"
                           // inputRef={register("individual_gender")}
                           {...register('individual_identity_type')}
                           defaultValue={{
                              label: individual_identity_type,
                              value: individual_identity_type,
                           }}
                           options={[
                              { value: 'national id card', label: 'National ID' },
                              { value: 'drivers license', label: "Driver's License" },
                              { value: 'international passport', label: 'International Passport' },
                              { value: 'voters card', label: "Voter's Card" },
                           ]}
                           onChange={(val) => setValue('individual_identity_type', val.value)}
                        />
                     </InfoGroup>

                     <InfoGroup>
                        <Label>Individual Occupation</Label>
                        <Input
                           className="overview-input"
                           id="individual_occupation"
                           defaultValue={individual_occupation}
                           // inputRef={register("individual_occupation")}
                           {...register('individual_occupation')}
                        />
                     </InfoGroup>

                     <InfoGroup>
                        <Label>Individual DOB</Label>
                        <Input
                           className="overview-input"
                           id="individual_date_of_birth"
                           defaultValue={individual_date_of_birth}
                           type="date"
                           // inputRef={register("individual_date_of_birth")}
                           {...register('individual_date_of_birth')}
                        />
                     </InfoGroup>

                     <InfoGroup>
                        <Label>Business Address</Label>
                        <Input
                           id="bizAddress"
                           placeholder="Address"
                           className="overview-input"
                           defaultValue={biz_address}
                           {...register('biz_address')}
                        />
                        <Input
                           id="bizCity"
                           placeholder="City"
                           className="overview-input"
                           defaultValue={biz_city}
                           {...register('biz_city')}
                        />
                        <Input
                           id="bizState"
                           placeholder="State"
                           className="overview-input"
                           defaultValue={biz_state}
                           {...register('biz_state')}
                        />
                     </InfoGroup>

                     <InfoGroup>
                        <Label>Date Created</Label>
                        <Value style={{ textTransform: 'initial' }}>
                           {dateCreated} | {timeCreated}
                        </Value>
                     </InfoGroup>
                  </OverviewSection>

                  <SectionTitle>Document</SectionTitle>
                  <OverviewSection>
                     {individual_identity_type || individual_identity_type !== '' ? (
                        <DocumentCard
                           name={individual_identity_type}
                           url={individual_identity_file}
                        />
                     ) : (
                        <p>No Document has been provided</p>
                     )}
                  </OverviewSection>

                  <div className="d-flex justify-content-end mt-2">
                     <Button
                        type="submit"
                        // disabled={hasEmptyBizField() || bizUnchanged() || updatingBiz}
                        isLoading={updatingBiz}
                     >
                        Update Details
                     </Button>
                  </div>
               </form>

               {bizDetails?.board_partner_details?.length ? (
                  <>
                     <SectionTitle>Other Director&apos;s Information</SectionTitle>

                     {bizDetails?.board_partner_details?.map((partner, i) => (
                        <div key={uuidv4()}>
                           <p>Director {i + 1}</p>
                           <OverviewSection className="director-section">
                              <InfoGroup>
                                 <Label>Full Name</Label>
                                 <Value>{partner.partner_full_name || 'N/A'}</Value>
                              </InfoGroup>

                              <InfoGroup>
                                 <Label>Email</Label>
                                 <Value>{partner.partner_email || 'N/A'}</Value>
                              </InfoGroup>

                              <InfoGroup>
                                 <Label>Phone</Label>
                                 <Value>{partner.partner_phone || 'N/A'}</Value>
                              </InfoGroup>

                              {partner.partner_identity_file && (
                                 <DocumentCard
                                    name="Identity File"
                                    url={partner.partner_identity_file}
                                 />
                              )}
                           </OverviewSection>
                        </div>
                     ))}
                  </>
               ) : (
                  <div />
               )}
            </>
         )}
      </OverviewWrapper>
   );
};

Overview.propTypes = {
   businessAccountId: string.isRequired,
   bizDetails: objectOf(string).isRequired,
   fetchBusinessDetails: func.isRequired,
};

export default Overview;
