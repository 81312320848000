import styled from 'styled-components';

export const Status = styled.span`
   background-color: ${({ status }) => {
      switch (status) {
         case 'accepted':
         case 'approved':
         case 'on':
         case 'completed':
         case 'verified':
         case 'active':
         case 'delivered':
         case 'Linked':
         case 'Delivered':
            return '#EAFBF1';

         case 'rejected':
         case 'off':
         case 'terminated':
         case 'blocked':
         case 'unapproved':
         case 'defaulted':
         case 'churned':
         case 'expired':
            return '#FCE9E9';

         case 'pending':
         case 'activated':
         case 'Ready':
         case 'Pending':
            return '#e3f0fd';

         case 'at_risk':
         case 'unverified':
            return '#FFF8DC';

         case 'inactive':
            return '#DCDCDC';

         case 'Ordered':
         case 'Processing':
         case 'waiting':
         case 'Freeze':
         case 'dormant':
         case 'deactivated':
         case 'unresolved':
            return '#FA4A84';
         case null:
         case '':
            return '#FDF2E7';

         default:
            return '#00a3ff';
      }
   }};
   color: ${({ status }) => {
      switch (status) {
         case 'accepted':
         case 'approved':
         case 'on':
         case 'completed':
         case 'verified':
         case 'Linked':
         case 'active':
         case 'delivered':
         case 'Delivered':
            return '#41D87D';

         case 'rejected':
         case 'off':
         case 'terminated':
         case 'unapproved':
         case 'blocked':
         case 'defaulted':
         case 'churned':
         case 'expired':
            return '#E43535';

         case 'at_risk':
         case 'unverified':
            return '#FFBF00';

         case 'inactive':
            return '#696969';

         case 'pending':
         case 'activated':
         case 'Pending':
         case 'Ready':
            return '#1354d2';

         case 'Ordered':
         case 'Processing':
         case 'waiting':
         case 'dormant':
         case 'deactivated':
         case 'Freeze':
         case 'unresolved':
            return '#fff';
         case null:
         case '':
            return '#F08829';

         default:
            return '#fff';
      }
   }};

   padding: 0.5rem;
   border-radius: 4px;
   text-transform: capitalize;

   max-height: 30px;
   display: inline-flex;
   justify-content: center;
   align-items: center;
`;

export const TicketStatus = styled.span`
   background-color: ${({ status }) => {
      switch (status) {
         case 'new':
            return '#FA4A84;';
         case 'open':
            return '#4CD964';
         case 'pending':
            return '#FFD500';
         case 'closed':
            return '#979797';
         case 'on_hold':
            return '#04093F';
         case 'light-pink':
            return 'rgba(250, 74, 132, 0.1)';

         default:
            return '#00a3ff';
      }
   }};
   color: ${({ status }) => {
      switch (status) {
         case 'light-pink':
            return '#FA4A84';
         default:
            return '#fff';
      }
   }};

   padding: 0.5rem;
   padding: 5px 9px;
   border-radius: 15px;
   text-transform: capitalize;
   font-size: 12px;
   line-height: 12px;

   max-height: 30px;
   display: inline-flex;
   justify-content: center;
   align-items: center;
`;
