/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Button } from 'components/button';
import { debitTransactions } from 'services/transactions';
import { TableContainer, TableContent } from 'styles/table';
import { TableComponent } from 'components/table';
import toast from 'react-hot-toast';
import { numberWithCommas } from 'utils/others';
import { TooltipComponent } from 'components/tooltip';

export default function NibssDebit() {
   const { search } = useLocation();
   const page = new URLSearchParams(search).get('page');
   const [tableData, setTableData] = useState([]);
   const [, setCurrentPage] = useState(page);
   const [file, setFile] = useState('');
   const [uploadingFile, setUploadingFile] = useState(false);

   const columns = [
      {
         field: 'session_id',
         width: 280,
         headerName: 'Session ID',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.session_id}>{row?.session_id}</TooltipComponent>
         ),
      },
      {
         field: 'amount',
         headerName: 'Amount',
         renderCell: ({ row }) => <p>₦{numberWithCommas(row?.amount)}</p>,
      },
      {
         width: 250,
         field: 'payment_reference',
         headerName: 'Payment Reference',
         renderCell: ({ row }) => <p>{row?.payment_reference}</p>,
      },
      {
         field: 'reason',
         headerName: 'Reason',
         width: 400,
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.reason}>{row?.reason}</TooltipComponent>
         ),
      },
   ];

   function onPageChange(e, pageNumber) {
      setCurrentPage(pageNumber);
   }

   const uploadFile = async () => {
      setUploadingFile(true);
      const formData = new FormData();
      formData.append('nibss_file', file);

      try {
         const response = await debitTransactions(formData);
         setTableData(response?.data?.data);
         toast.success(response?.data?.message);
      } catch (e) {
         toast.error(e?.message);
      }
      setUploadingFile(false);
   };

   return (
      <div>
         <TableContainer>
            <h2>Upload transactions to be debited</h2>
            <div className="input-group mt-4 doc-file-input-group align-items-center">
               {!uploadingFile && (
                  <input
                     accept=".csv"
                     type="file"
                     aria-label="Upload"
                     onChange={(e) => setFile(e.target.files[0])}
                  />
               )}
               <Button
                  className="upload-btn"
                  disabled={!file || uploadingFile}
                  isLoading={uploadingFile}
                  onClick={uploadFile}
               >
                  Upload File
               </Button>
            </div>

            {tableData?.length > 0 && (
               <>
                  <TableContent style={{ borderTop: 0 }}>
                     <div className="d-flex justify-content-between w-100 align-items-center mt-5">
                        <div>
                           <h1>Transactions Debit Failed For</h1>
                           <span className="value">{tableData?.length || 'N/A'}</span>
                        </div>
                     </div>
                  </TableContent>

                  <TableComponent columns={columns} rows={tableData} onPageChange={onPageChange} />
               </>
            )}
         </TableContainer>
      </div>
   );
}
