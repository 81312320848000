import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Rating from 'react-rating';
import toast from 'react-hot-toast';
import Star from 'assets/images/full-star.svg';
import EmptyStar from 'assets/images/empty-star.svg';
import { MenuComponent } from 'components/menu';
import { TicketStatus, Status } from 'styles/utils';
import { Button } from 'components/button';
import { updateSingleTicket } from 'services/ticketing';
import { confirm } from 'components/confirm-box';
import { useAuth } from 'hooks/auth';
import {
   AvatarWrapper,
   Info,
   Name,
   TopSection,
   UserProfile,
   SectionTitle,
   OverviewSection,
   InfoGroup,
   Label,
   Value,
} from 'styles/layout';
import { InputWrapper } from 'styles/form';
import { SelectInput } from 'components/select';
import useFetchManagers from 'hooks/fetchManagers';
import Avatar from 'assets/images/user.svg';
import { hasPermission } from 'utils/user_access';
import { hasValue } from 'utils/validations';
import { TicketDetails } from '../../ticketing.style';

export default function Overview({ ticketDetails, bizDetails, afterTicketUpdate }) {
   const { account_holder_details, biz_status } = bizDetails || {};
   const { currentUser } = useAuth();
   const { permissions } = currentUser?.access || {};
   const phone = account_holder_details?.phone;
   const email = account_holder_details?.email;
   const name = `${account_holder_details?.first_name} ${account_holder_details?.last_name}`;
   const { managers } = useFetchManagers();
   const [selectedManager, setSelectedManager] = useState('');

   const [anchorEl, setAnchorEl] = useState(null);
   const openStatusMenu = Boolean(anchorEl);
   const {
      category,
      resolution_time,
      assigned_to_name,
      assigned_to,
      response_time,
      service_feedback,
   } = ticketDetails || {};

   const userImage = bizDetails?.account_holder_details?.photo_url || Avatar;

   const admin = hasPermission(permissions, 'ticket_admin');

   const handleTicketUpdate = async (payload) => {
      try {
         await updateSingleTicket(ticketDetails?.id, payload);
         afterTicketUpdate();
      } catch (e) {
         return e;
      }
   };

   const handleCloseStatusMenu = () => {
      setAnchorEl(null);
   };

   const handleStatusButtonClick = (event) => {
      setAnchorEl(event.currentTarget);
   };

   const assignTicket = (payload) => {
      confirm({
         confirmText: 'Do you want to proceed with this action?',
         onConfirm: async () => {
            try {
               await handleTicketUpdate({
                  ...payload,
                  ...(!assigned_to_name && { issue_status: 'open' }),
               });
            } catch (e) {
               toast.error(e?.message);
            }
         },
      });
   };

   const ticketStatus = ['Open', 'Pending', 'On hold', 'Closed'];

   return ticketDetails ? (
      <TicketDetails>
         <TopSection>
            <UserProfile>
               <AvatarWrapper>
                  <img src={userImage} alt="account-holder-avatar" />
               </AvatarWrapper>
               <div>
                  <Name className="mb-2">{name}</Name>
                  <Info>{phone}</Info>
                  <Info>{email}</Info>
                  <Status status={biz_status}>{biz_status}</Status>
               </div>
            </UserProfile>
            {hasPermission(permissions, 'can_update_status') &&
               (assigned_to === currentUser?.prospa_user_id || admin) && (
                  <div className="mb-3 d-flex justify-content-end">
                     <Button onClick={handleStatusButtonClick} className="ms-3">
                        Update ticket Status
                     </Button>
                     <MenuComponent
                        anchorEl={anchorEl}
                        handleClose={handleCloseStatusMenu}
                        open={openStatusMenu}
                        options={ticketStatus}
                        optionsFns={ticketStatus.map(
                           (option) => () =>
                              handleTicketUpdate({
                                 issue_status: option?.toLowerCase()?.replaceAll(' ', '_'),
                              }),
                        )}
                     />
                  </div>
               )}
         </TopSection>

         <div className="mt-5">
            <SectionTitle>Ticket Information</SectionTitle>
            <OverviewSection>
               <InfoGroup>
                  <Label>Ticket ID</Label>
                  <Value>#{ticketDetails?.id}</Value>
               </InfoGroup>

               <InfoGroup>
                  <Label>Ticket status</Label>
                  <TicketStatus status={ticketDetails?.issue_status}>
                     {ticketDetails?.issue_status}
                  </TicketStatus>
               </InfoGroup>

               <InfoGroup>
                  <Label>Date submitted</Label>
                  <Value>
                     {moment(ticketDetails?.created || '').format('MMM, DD, YYYY | h:mm A')}
                  </Value>
               </InfoGroup>
               <InfoGroup>
                  <Label>Last Updated</Label>
                  <Value>
                     {moment(ticketDetails?.modified || '').format('MMM, DD, YYYY | h:mm A')}
                  </Value>
               </InfoGroup>

               <InfoGroup>
                  <Label>Category</Label>
                  <Value>{category?.category}</Value>
               </InfoGroup>

               <InfoGroup>
                  <Label> Resolution time</Label>
                  <Value>{resolution_time || 'N/A'}</Value>
               </InfoGroup>

               <InfoGroup>
                  <Label>Manager</Label>
                  <Value>{assigned_to_name || 'Unassigned'}</Value>
                  {assigned_to !== currentUser?.prospa_user_id && !assigned_to && (
                     <p
                        style={{ fontSize: '14px', color: 'blue', cursor: 'pointer' }}
                        onClick={() =>
                           assignTicket({
                              assigned_to: currentUser?.prospa_user_id,
                           })
                        }
                     >
                        Assign to me
                     </p>
                  )}
               </InfoGroup>

               <InfoGroup>
                  <Label>Response time</Label>
                  <Value>{response_time || 'N/A'}</Value>
               </InfoGroup>
            </OverviewSection>
         </div>

         <OverviewSection style={{ borderTop: '1px solid #bac2c9' }} className="pt-4">
            <InfoGroup>
               <Label>Ratings</Label>
               {service_feedback ? (
                  <Rating
                     fullSymbol={
                        <div className="star-rating">
                           <img src={Star} alt="star" />
                        </div>
                     }
                     emptySymbol={
                        <div className="star-rating">
                           <img src={EmptyStar} alt="star" />
                        </div>
                     }
                     initialRating={service_feedback?.stars}
                     readonly
                  />
               ) : (
                  <Value>N/A</Value>
               )}
            </InfoGroup>

            <InfoGroup>
               <Label>Experience Feedback</Label>
               <Value
                  style={{
                     background: 'rgba(173, 173, 173, 0.12)',
                     padding: '10px',
                     borderRadius: '4px',
                     color: '#82849F',
                  }}
               >
                  {service_feedback ? service_feedback.feedback : 'N/A'}
               </Value>
            </InfoGroup>
         </OverviewSection>

         {hasPermission(permissions, 'assign_ticket') && (
            <div>
               <SectionTitle>Assign Ticket</SectionTitle>
               <div className="pb-5 mb-5">
                  <InputWrapper className="grid-2-2 mb-5 pb-5">
                     <div className="d-flex align-items-end">
                        <SelectInput
                           label="Select Manager"
                           value={selectedManager}
                           onChange={(val) => setSelectedManager(val)}
                           options={managers}
                           className="w-100"
                        />
                        <Button
                           className="ms-3"
                           disabled={!hasValue(selectedManager)}
                           onClick={() =>
                              assignTicket({
                                 assigned_to: selectedManager.value,
                              })
                           }
                        >
                           Assign Ticket
                        </Button>
                     </div>
                  </InputWrapper>
               </div>
            </div>
         )}
      </TicketDetails>
   ) : null;
}

Overview.propTypes = {
   bizDetails: PropTypes.objectOf(PropTypes.string).isRequired,
   ticketDetails: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
   afterTicketUpdate: PropTypes.func.isRequired,
};
