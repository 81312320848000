import { useState, useEffect } from 'react';
import { toast } from 'react-hot-toast';
import getSymbolFromCurrency from 'currency-symbol-map';
import CurrencyFlag from 'react-currency-flags';
import { getRates } from 'services/international-transactions';
import { Spinner } from 'components/spinner';
import classNames from 'classnames';
import { Button } from 'components/button';
import { UpdateRateModal } from './features/update-rate-modal';
import { CreateExchangeRatesModal } from './features/create-rate-modal';

export const ExchangeRate = () => {
   const [isLoading, setIsLoading] = useState(false);
   const [countries, setCountries] = useState([]);
   const [updateRateModalVisibility, setUpdateRateModalVisibility] = useState(false);
   const [createRateModalVisibility, setCreateRateModalVisibility] = useState(false);
   const [selectedData, setSelectedData] = useState(null);

   const fetchAllCountries = async () => {
      setIsLoading(true);
      try {
         const res = await getRates();
         const updatedCountries = res.results.map((country) => ({
            ...country,
            rate: country?.rate,
         }));
         setCountries(updatedCountries);
      } catch (err) {
         toast.error(err?.message);
      }
      setIsLoading(false);
   };

   useEffect(() => {
      fetchAllCountries();
   }, []);

   const handleUpdateRateClick = (rate, id, to_currency, from_currency) => {
      setSelectedData({ rate, id, to_currency, from_currency });
      setUpdateRateModalVisibility(true);
   };

   return (
      <>
         {isLoading ? (
            <Spinner width="30%" height="20" />
         ) : (
            <>
               <div className="d-flex justify-content-between">
                  <h2>Exchange Rates</h2>
                  <Button onClick={() => setCreateRateModalVisibility(true)}>Create Rates</Button>
               </div>
               <div className="row pt-5">
                  {countries.map(({ id, rate, to_currency, from_currency }, index) => (
                     <div
                        className={classNames('col-lg-4 mb-5', {
                           'pe-5': (index + 1) % 3 !== 0,
                        })}
                        key={id}
                     >
                        <div className="mb-4">
                           <h3 className="mb-3">{`${from_currency} to ${to_currency} Rate`}</h3>
                           <div className="d-flex gap-3 align-items-center">
                              <div>
                                 <CurrencyFlag currency={to_currency} size="xl" />
                              </div>
                              <div style={{ borderBottom: '1px solid #D9E0E8' }}>
                                 {`1 ${getSymbolFromCurrency(from_currency)}`} ={' '}
                                 {`${getSymbolFromCurrency(to_currency)} ${parseFloat(rate)}`}
                              </div>
                           </div>
                        </div>
                        <Button
                           className="w-100"
                           onClick={() =>
                              handleUpdateRateClick(rate, id, to_currency, from_currency)
                           }
                        >
                           Update Rate
                        </Button>
                     </div>
                  ))}
               </div>
               {updateRateModalVisibility && (
                  <UpdateRateModal
                     open={updateRateModalVisibility}
                     onClose={() => setUpdateRateModalVisibility(false)}
                     selectedData={selectedData}
                     fetchAllCountries={fetchAllCountries}
                  />
               )}
               {createRateModalVisibility && (
                  <CreateExchangeRatesModal
                     open={createRateModalVisibility}
                     onClose={() => setCreateRateModalVisibility(false)}
                     fetchAllCountries={fetchAllCountries}
                  />
               )}
            </>
         )}
      </>
   );
};
