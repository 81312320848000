/* eslint-disable react/prop-types */
// import {  changeStatus } from 'services/business';
import { getAllPosOrders, getAllPosTerminals } from 'services/pos';
import { useEffect, useState } from 'react';
import LoggedInDashboard from 'components/logged-in-dashboard';
// import { confirm } from 'components/confirm-box/confirm-box';
import { TableContainer, TableContent } from 'styles/table';
import { TooltipComponent } from 'components/tooltip';
import { Status } from 'styles/utils';
import { Skeleton } from '@material-ui/lab';
import { TableComponent } from 'components/table';
import { TabWrapper } from 'pages/pending-documents/style';
import IconButton from '@material-ui/core/IconButton';
import toast from 'react-hot-toast';
import { numberWithCommas } from 'utils/others';
import moment from 'moment';
import { MenuComponent } from 'components/menu';
import PosDetails from 'containers/pos-details';
import { useBizDetailsModal } from 'hooks/bizDetailsModal';
import { MoreHoriz } from '@material-ui/icons';

function FollowUp() {
   const [tableData, setTableData] = useState([]);
   const [isLoading, setIsLoading] = useState(false);
   // const [isChangeStatusLoading, setIsChangeStatusLoading] = useState(false);
   const [anchorEl, setAnchorEl] = useState(null);
   const [paginationData, setPaginationData] = useState({});
   const [currentPage, setCurrentPage] = useState(1);
   const [modalVisibility, setModalVisibility] = useState(false);
   const [activeFilter, setActiveFilter] = useState('pos-orders');
   const [posOrderId, setPosOrderId] = useState('');
   const open = Boolean(anchorEl);
   const { setActiveTab } = useBizDetailsModal();
   // const [startDate] = useState('2019-01-01');
   // const [endDate] = useState(moment().format('YYYY-MM-DD'));

   const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
   };

   const handleClose = () => {
      setAnchorEl(null);
   };

   const options = ['view Details'];

   function handleOpenModal() {
      setActiveTab('order-details');
      setModalVisibility(true);
   }

   function onRowClick({ row }) {
      setPosOrderId(row.pos_order_id);
   }

   const columns = [
      {
         field: '',
         width: 0,
         renderCell: ({ row }) => (
            <div>
               <IconButton
                  aria-label="more"
                  aria-controls="long-menu"
                  aria-haspopup="true"
                  onClick={handleClick}
               >
                  <MoreHoriz />
               </IconButton>
               <MenuComponent
                  anchorEl={anchorEl}
                  handleClose={handleClose}
                  open={open}
                  options={options}
                  optionsFns={[
                     // place the callbacks in position corresponding to the options index
                     () => handleOpenModal(row.biz_account_id),
                     // () => callNumber(row?.account_holder_details?.phone),
                     // () => sendMessageModalSection(row.biz_account_id),
                  ]}
               />
            </div>
         ),
      },
      {
         field: 'biz_details?.biz_name',
         headerName: 'Business Name',
         renderCell: ({ row }) => (
            <TooltipComponent title={row.biz_details?.biz_name}>
               <span
                  style={{
                     cursor: 'pointer',
                  }}
                  className="text-primary"
                  onClick={() => handleOpenModal(row.pos_order_id)}
               >
                  {row.biz_details?.biz_name || 'N/A'}
               </span>
            </TooltipComponent>
         ),
      },
      {
         field: 'device_quantity',
         headerName: 'Device Quantity',
         renderCell: ({ row }) => (
            <TooltipComponent title={row.device_quantity}>
               {row.device_quantity || 'N/A'}
            </TooltipComponent>
         ),
      },
      {
         field: 'device_total_amount',
         headerName: 'Device Total Amount',
         renderCell: ({ row }) => (
            <TooltipComponent title={row.device_total_amount}>
               ₦{numberWithCommas(row.device_total_amount)}
            </TooltipComponent>
         ),
      },
      {
         field: 'device_total_amount_paid',
         headerName: 'Total Amount Paid',
         renderCell: ({ row }) => (
            <TooltipComponent title={row.device_total_amount_paid}>
               ₦{numberWithCommas(row.device_total_amount_paid)}
            </TooltipComponent>
         ),
      },
      {
         field: 'payment_type',
         headerName: 'Payment Type',
         renderCell: ({ row }) => (
            <Status status={row?.payment_type === 'full_payment' ? 'approved' : 'pending'}>
               {row.payment_type.replaceAll('_', ' ') || 'N/A'}
            </Status>
         ),
      },
      {
         field: 'order_status',
         headerName: 'Order Status',
         renderCell: ({ row }) => (
            <Status status={row?.order_status}>{row?.order_status || 'N/A'}</Status>
         ),
      },
      {
         field: 'delivery_status',
         headerName: 'Delivery Status',
         renderCell: ({ row }) => (
            <Status status={row?.delivery_status}>{row?.delivery_status || 'N/A'}</Status>
         ),
      },
      {
         field: 'repayment_allocation',
         headerName: 'Repayment Allocation',
         renderCell: ({ row }) => (
            <TooltipComponent title={row.repayment_allocation}>
               {row.repayment_allocation}%
            </TooltipComponent>
         ),
      },
      {
         field: 'pub_date',
         headerName: 'Date Onboarded',
         renderCell: ({ row }) => (
            <span>
               {moment(row?.pub_date).format('MMM DD, YYYY')} |{' '}
               {moment(row?.pub_date).format('h:mm a')}
            </span>
         ),
      },
   ];

   const terminalsColumns = [
      {
         field: 'terminal_name',
         headerName: 'Terminal Name',
         renderCell: ({ row }) => (
            <TooltipComponent title={row.terminal_name}>
               {row.terminal_name || 'N/A'}
            </TooltipComponent>
         ),
      },
      {
         field: 'terminal_number',
         headerName: 'Terminal Number',
         renderCell: ({ row }) => (
            <TooltipComponent title={row.terminal_number}>
               {row.terminal_number || 'N/A'}
            </TooltipComponent>
         ),
      },
      {
         field: 'terminal_status',
         headerName: 'Terminal Status',
         renderCell: ({ row }) => (
            <Status status={row?.terminal_status}>
               {row.terminal_status.replaceAll('_', ' ') || 'N/A'}
            </Status>
         ),
      },
      {
         field: 'transaction_limit',
         headerName: 'Transaction Limit',
         renderCell: ({ row }) => (
            <TooltipComponent title={row.transaction_limit}>
               ₦{numberWithCommas(row.transaction_limit)}
            </TooltipComponent>
         ),
      },
      {
         field: 'pub_date',
         headerName: 'Date Onboarded',
         renderCell: ({ row }) => (
            <span>
               {moment(row?.pub_date).format('MMM DD, YYYY')} |{' '}
               {moment(row?.pub_date).format('h:mm a')}
            </span>
         ),
      },
   ];

   const initializeTable = async () => {
      setIsLoading(true);
      setTableData([]);
      try {
         if (activeFilter === 'pos-orders') {
            const result = await getAllPosOrders({ page: currentPage });
            setTableData(result.data);
            setPaginationData(result);
         } else if (activeFilter === 'pos-terminals') {
            const result = await getAllPosTerminals({ page: currentPage });
            setTableData(result.data);
            setPaginationData(result);
         }
      } catch (e) {
         toast.error(e.message);
      }
      setIsLoading(false);
   };

   useEffect(() => {
      initializeTable();
   }, [currentPage, activeFilter]);

   function onPageChange(e, pageNumber) {
      setCurrentPage(pageNumber);
   }

   return (
      <LoggedInDashboard pageTitle="Point Of Sale (POS)">
         <TableContainer>
            <TabWrapper>
               <ul className="m-0 d-flex align-items-center">
                  <li
                     className={`${
                        activeFilter === 'pos-orders' ? 'active' : ''
                     } pending-doc-overview pending-tab-action`}
                     onClick={() => {
                        setActiveFilter('pos-orders');
                        setCurrentPage(1);
                     }}
                  >
                     POS Orders
                  </li>

                  <li
                     className={`${
                        activeFilter === 'pos-terminals' ? 'active' : ''
                     } pending-doc-overview pending-tab-action`}
                     onClick={() => {
                        setActiveFilter('pos-terminals');
                        setCurrentPage(1);
                     }}
                  >
                     POS Terminals
                  </li>
               </ul>
            </TabWrapper>
            <TableContent>
               {isLoading ? (
                  <Skeleton width="30%" height="20" />
               ) : (
                  <div>
                     <h1> POS {activeFilter === 'pos-orders' ? 'Orders' : 'Terminals'}</h1>
                     <span className="value">{paginationData?.count || 'N/A'}</span>
                  </div>
               )}
            </TableContent>
            {activeFilter === 'pos-orders' && (
               <TableComponent
                  columns={columns}
                  rows={tableData}
                  isLoading={isLoading}
                  onPageChange={onPageChange}
                  count={Math.ceil(paginationData.count / 15)}
                  onRowClick={onRowClick}
               />
            )}
            {activeFilter === 'pos-terminals' && (
               <TableComponent
                  columns={terminalsColumns}
                  rows={tableData}
                  isLoading={isLoading}
                  onPageChange={onPageChange}
                  count={Math.ceil(paginationData.count / 15)}
                  onRowClick={onRowClick}
               />
            )}
         </TableContainer>
         {modalVisibility && (
            <PosDetails
               posOrderId={posOrderId}
               open={modalVisibility}
               onClose={() => setModalVisibility(false)}
               initializeTable={initializeTable}
            />
         )}
      </LoggedInDashboard>
   );
}

export default FollowUp;
