import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import toast from 'react-hot-toast';
import { InputWrapper } from 'styles/form';
import { Input } from 'components/input';
import { Button } from 'components/button';
import Textarea from 'components/textarea';
import BluePlusIcon from 'assets/images/blue-plus.svg';
import RemoveIcon from 'assets/images/remove.svg';
import { hasValue } from 'utils/validations';
import { Tag } from 'styles/tags';
import { addTicketCategory, addTicketSubCategory, addTicketFaq } from 'services/ticketing';
import { AddCategoryWrapper } from '../../ticketing.style';

export default function AddCategory({ fetchTicketCategories }) {
   const [category, setCategory] = useState('');
   const [subCategory, setSubCategory] = useState('');
   const [subCategoryList, setSubCategoryList] = useState([]);
   const [faqList, setFaqList] = useState([]);
   const [faq, setFaq] = useState({
      question: '',
      answer: '',
   });
   const { answer, question } = faq;
   const [isLoading, setIsLoading] = useState(false);

   const createCategory = async () => {
      setIsLoading(true);
      try {
         const newCategory = await addTicketCategory({
            category,
         });

         const subCategoryPayload = subCategoryList.map((item) => ({
            category: newCategory.id,
            sub_category: item,
         }));
         await addTicketSubCategory(subCategoryPayload);

         const faqPayload = faqList.map((item) => ({
            category: newCategory.id,
            question: item.question,
            answer: item.answer,
         }));
         await addTicketFaq(faqPayload);

         setCategory('');
         setSubCategory('');
         setSubCategoryList([]);
         setFaq({
            question: '',
            answer: '',
         });
         setFaqList([]);
         await fetchTicketCategories();
         toast.success('Category has been created successfully');
      } catch (e) {
         toast.error(e?.message || 'An error occurred while creating category');
      }

      setIsLoading(false);
   };

   const handleAddCategory = () => {
      const exist = subCategoryList.indexOf(subCategory);

      if (exist < 0 && hasValue(subCategory)) {
         setSubCategoryList([...subCategoryList, subCategory]);
         setSubCategory('');
      }
   };

   const handleAddFaq = () => {
      if (hasValue(question) || hasValue(answer)) {
         const allFaq = faqList;
         const exist = allFaq.find((item) => item.id === faq.id);

         if (exist) {
            exist.question = question;
            exist.answer = answer;
            setFaqList([...allFaq]);
         } else {
            setFaqList([
               ...faqList,
               {
                  id: uuidv4(),
                  question,
                  answer,
               },
            ]);
         }

         setFaq({
            answer: '',
            question: '',
         });
      }
   };

   const triggerFaqUpdate = (data) => {
      setFaq({
         id: data.id,
         question: data.question,
         answer: data.answer,
      });
   };

   const removeFaq = (id) => {
      setFaqList(faqList.filter((item) => item.id !== id));
   };

   const handleRemoveSubCategories = (value) =>
      setSubCategoryList(subCategoryList.filter((item) => item !== value));

   const isValid = hasValue(category) && faqList.length > 0 && subCategoryList.length > 0;

   return (
      <AddCategoryWrapper>
         <h2>Add new category</h2>

         <form>
            <InputWrapper className="grid-2-2">
               <div>
                  <Input
                     placeholder="Category name"
                     label="Category Name"
                     name="category"
                     value={category}
                     type="text"
                     className="mb-5"
                     onChange={({ target: { value } }) => setCategory(value)}
                  />
                  <div>
                     <div className="d-flex align-items-center">
                        <Input
                           placeholder="Add a subcategory"
                           name="subcategory"
                           type="text"
                           value={subCategory}
                           onChange={({ target: { value } }) => setSubCategory(value.toLowerCase())}
                        />
                        <img
                           src={BluePlusIcon}
                           alt="plus"
                           onClick={handleAddCategory}
                           style={{ marginLeft: '-40px', zIndex: 10, cursor: 'pointer' }}
                        />
                     </div>
                     <div className="sub-category-list d-flex align-items-center flex-wrap mt-3">
                        {subCategoryList.map((item) => (
                           <Tag key={item} className="me-3 mb-3 align-items-center">
                              <p className="tag-body text me-3">{item}</p>
                              <img
                                 src={RemoveIcon}
                                 alt="remove"
                                 onClick={() => handleRemoveSubCategories(item)}
                              />
                           </Tag>
                        ))}
                     </div>
                  </div>
               </div>

               <div>
                  <Input
                     placeholder="FAQ Question"
                     label="FAQ Question"
                     name="question"
                     type="text"
                     className="mb-5"
                     value={question}
                     onChange={({ target: { value } }) => setFaq({ ...faq, question: value })}
                  />

                  <Textarea
                     label="FAQ Answer"
                     name="answer"
                     value={answer}
                     onChange={({ target: { value } }) => setFaq({ ...faq, answer: value })}
                  />
               </div>
            </InputWrapper>

            <div className="d-flex justify-content-end mb-5 pb-4">
               <Button
                  size="small"
                  onClick={handleAddFaq}
                  disabled={!hasValue(question) || !hasValue(answer)}
               >
                  Add FAQ
               </Button>
            </div>

            {faqList && faqList.length > 0 && (
               <div className="faq-list-container mb-5">
                  <h4>FAQ’s</h4>
                  <div className="faq-list row m-0">
                     {faqList?.map((item) => (
                        <div className="col-md-6" key={item.id}>
                           <Tag className="faq-list-item" fullWidth>
                              <div className="content" onClick={() => triggerFaqUpdate(item)}>
                                 <h6 className="faq-list-item-question tag-heading text">
                                    {item.question}
                                 </h6>
                                 <p className="faq-list-item-answer tag-body text">{item.answer}</p>
                              </div>
                              <img
                                 src={RemoveIcon}
                                 alt="remove"
                                 onClick={() => removeFaq(item.id)}
                              />
                           </Tag>
                        </div>
                     ))}
                  </div>
               </div>
            )}

            <div className="d-flex justify-content-end">
               <Button
                  onClick={createCategory}
                  disabled={!isValid || isLoading}
                  isLoading={isLoading}
               >
                  Create new category
               </Button>
            </div>
         </form>
      </AddCategoryWrapper>
   );
}

AddCategory.propTypes = {
   fetchTicketCategories: PropTypes.func.isRequired,
};
