/* eslint-disable no-else-return */
import { useEffect, useState } from 'react';
import Modal from 'components/modal';
import { getSinglePosOrders, getTerminalsSingleBiz, changePosDeliveryStatus } from 'services/pos';
import { bool, func, number } from 'prop-types';
import { Button } from 'components/button';
import { Spinner } from 'components/spinner';
import { toast } from 'react-hot-toast';
import { MenuComponent } from 'components/menu';
import { confirm } from 'components/confirm-box';
import OrderOverview from './order-overview';
import Terminals from './terminals';
import AddTerminal from './add-terminal';
import { Navigation, NavigationTab } from './style';

const BusinessDetails = ({ open, onClose, posOrderId, initializeTable }) => {
   const [activeTab, setActiveTab] = useState('order-details');
   const [orderDetails, setOrderDetails] = useState(null);
   const [bizTerminals, setBizTerminals] = useState([]);
   const [bizAccountId, setBizAccountId] = useState('');
   const [anchorEl, setAnchorEl] = useState(null);
   const [loading, setLoading] = useState(false);
   const [wasUpdateMade, setWasUpdateMade] = useState(false);
   const openStatusMenu = Boolean(anchorEl);
   const handleStatusButtonClick = (event) => {
      setAnchorEl(event.currentTarget);
   };
   const handleCloseStatusMenu = () => {
      setAnchorEl(null);
   };
   async function initialize() {
      try {
         const result = await getSinglePosOrders(posOrderId);
         const terminals = await getTerminalsSingleBiz(result.data?.biz_details?.biz_account_id);
         setBizAccountId(result.data?.biz_details?.biz_account_id);
         setBizTerminals(terminals.data);
         setOrderDetails(result.data);
      } catch ({ message }) {
         toast.error(message);
      }
   }

   useEffect(() => {
      initialize();
   }, []);
   const handleChangeStatus = async (status) => {
      confirm({
         confirmText: 'Are you sure you want to perform this action?',
         isLoading: false,
         onConfirm: async () => {
            setLoading(true);
            try {
               const res = await changePosDeliveryStatus({
                  pos_order_id: posOrderId,
                  delivery_status: status,
               });
               toast.success(res.message || 'Successful');
               setWasUpdateMade(true);
               await initialize();
            } catch (e) {
               toast.error(e.message);
            }
            setLoading(false);
         },
      });
   };

   // const { currentUser } = useAuth();

   const activePage = () => {
      switch (activeTab) {
         case '':
         case 'order-details':
            return <OrderOverview orderDetails={orderDetails} />;

         case 'terminals':
            return <Terminals terminals={bizTerminals} />;

         case 'add-terminal':
            return (
               <AddTerminal
                  terminals={bizTerminals}
                  bizAccountId={bizAccountId}
                  posOrderId={posOrderId}
                  initialize={initialize}
               />
            );

         default:
            return 'No Screen';
      }
   };

   return (
      <Modal
         open={open}
         onClose={() => {
            onClose();
            setActiveTab('none');
            if (wasUpdateMade) initializeTable();
            setWasUpdateMade(false);
         }}
         closeOnOverlayClick={false}
         title="POS Order Details"
         className="biz-details-modal"
      >
         <div className="d-flex justify-content-between align-items-end">
            <Navigation>
               <NavigationTab
                  className={activeTab === 'order-details' ? 'active' : ''}
                  onClick={() => setActiveTab('order-details')}
               >
                  Order Details
               </NavigationTab>
               <NavigationTab
                  className={activeTab === 'terminals' ? 'active' : ''}
                  onClick={() => setActiveTab('terminals')}
               >
                  Terminals
               </NavigationTab>
               <NavigationTab
                  className={activeTab === 'add-terminal' ? 'active' : ''}
                  onClick={() => setActiveTab('add-terminal')}
               >
                  Add New Terminal
               </NavigationTab>
            </Navigation>
            <div>
               <Button onClick={handleStatusButtonClick} className="ms-3 bg-secondary">
                  Change Delivery Status
               </Button>
               <MenuComponent
                  anchorEl={anchorEl}
                  handleClose={handleCloseStatusMenu}
                  open={openStatusMenu}
                  options={['Dispatched', 'Delivered']}
                  loading
                  optionsFns={[
                     () => handleChangeStatus('dispatched'),
                     () => handleChangeStatus('delivered'),
                  ]}
               />
            </div>
         </div>
         {!loading ? <div className="pt-5">{activePage()}</div> : <Spinner />}
      </Modal>
   );
};

BusinessDetails.propTypes = {
   open: bool.isRequired,
   onClose: func.isRequired,
   posOrderId: number.isRequired,
   initializeTable: func.isRequired,
};

export default BusinessDetails;
