import Styled from 'styled-components';

export const CalendarWrapper = Styled.div`
    .calendar-note-block{
        padding: 12px;
        background: #895bab42;
        border-radius: 6px;
    }

    span{
        font-weight: 500;
        font-size:14px;
    }

    div{
        font-weight: 400;
        font-size:15px;

    }

    h3{
        font-size:18px;
        font-weight:600;
    }
    td{
        cursor:pointer;
    }
    td[data-date='${(props) => props.date}'] {
        background: #82c8ff !important;
    }
    
`;

export const CalendarButtonWrapper = Styled.div`
    border: 1px solid #D7DCE0;
    padding: 10px 16px;
    border-radius: 6px;
    height: 40px;
    cursor: pointer;
    width: 200px;
    img{
        width:20px;
    }
`;
