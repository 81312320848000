import { AuthContextProvider } from 'context/auth';
import { BizDetailsModalProvider } from 'context/bizDetailsModal';
import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';

import reportWebVitals from './reportWebVitals';
import App from './App';

if (process.env.NODE_ENV === 'production') {
   Sentry.init({
      dsn: 'https://9b5ddd7f34fa4238854b75c33e254698@o402352.ingest.sentry.io/5263453',
      integrations: [new Sentry.BrowserTracing()],
      // We recommend adjusting this value in production, or using tracesSampler
      // for finer control
      tracesSampleRate: 1.0,
   });
}

ReactDOM.render(
   <React.StrictMode>
      <AuthContextProvider>
         <BizDetailsModalProvider>
            <App />
         </BizDetailsModalProvider>
      </AuthContextProvider>
   </React.StrictMode>,
   document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
