import axios from 'axios';
import { getToken, getUserInfoFromStorage } from './storage';

const axiosCustom = (params) => {
   const { action } = params || {};
   const token = getToken();
   const userData = getUserInfoFromStorage();
   const { permissions } = userData?.access || {};

   if (action && !permissions.includes(action) && permissions !== 'all') {
      throw new Error(`You are not authorized to perform this action: ${action}`);
   }

   return axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      headers: {
         'Content-type': 'application/json',
         Authorization: `Token ${token}`,
      },
   });
};

export default axiosCustom;
