import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { updateBusinessNameWithCac } from 'services/businessDetails';
import { Button } from 'components/button';
import { confirm } from 'components/confirm-box';
import { Input } from 'components/input';
import { InputWrapper } from 'styles/form';
import { hasValue } from 'utils/validations';

export default function UpdateBusinessName() {
   const [rcNumber, setRcNumber] = useState('');
   const [loading, setLoading] = useState(false);
   const [businessId, setBusinessId] = useState('');

   const isValid = hasValue(rcNumber) && hasValue(businessId);

   const updateBusinessNameApi = async () => {
      setLoading(true);

      const payload = {
         rc_number: rcNumber,
         businessId,
      };

      try {
         const response = await updateBusinessNameWithCac(payload);
         toast.success(response.message);
         setRcNumber('');
         setBusinessId('');
      } catch (e) {
         toast.error(e.message);
      }
      setLoading(false);
   };

   const confirmAction = () =>
      confirm({
         confirmText: 'Are you sure you want to continue ?',
         onConfirm: updateBusinessNameApi,
      });

   return (
      <div className="row align-items-end">
         <InputWrapper className="col-lg-4">
            <Input
               placeholder="Enter RC number"
               label="RC Number"
               value={rcNumber}
               onChange={(e) => setRcNumber(e.target.value)}
            />
         </InputWrapper>
         <InputWrapper className="col-lg-4">
            <Input
               placeholder="Enter Business ID"
               label="Business ID"
               value={businessId}
               onChange={(e) => setBusinessId(e.target.value)}
            />
         </InputWrapper>
         <InputWrapper className="col-lg-4">
            <Button
               variant="primary"
               onClick={confirmAction}
               isLoading={loading}
               disabled={!isValid}
            >
               Update
            </Button>
         </InputWrapper>
      </div>
   );
}
