import React, { useEffect, useState } from 'react';
import { objectOf } from 'prop-types';
import toast from 'react-hot-toast';
import { confirm } from 'components/confirm-box';
import { Spinner } from 'components/spinner';
import { Button } from 'components/button';
import { getCards, editCardDetails } from 'services/cards';
import { CardContainer, CardTypeWrapper } from './cards.style';

export default function CardsTab({ bizDetails }) {
   const [loading, setLoading] = useState(false);
   const [cards, setCards] = useState([]);

   const { biz_name } = bizDetails;

   const fetchCards = async () => {
      setLoading(true);
      try {
         const res = await getCards({ account_name: biz_name });
         setCards(res.results);
         setLoading(false);
      } catch (e) {
         toast.error(e?.message || 'Something went wrong!');
         setLoading(false);
      }
   };

   const handleFreezeCard = (item) => {
      confirm({
         confirmText:
            item?.status === 'deactivated'
               ? 'Are you sure want to unfreeze this card?'
               : 'Are you sure want to freeze this card?',
         onConfirm: async () => {
            setLoading(true);
            const payload = {
               status: item?.status === 'deactivated' ? 'active' : 'deactivated',
            };
            try {
               const res = await editCardDetails(payload, item?.id);
               toast.success(res?.message || 'Action successful');
               setLoading(false);
               fetchCards();
            } catch (e) {
               toast.error(e?.message || 'Something went wrong');
               setLoading(false);
            }
         },
      });
   };

   const handleTerminateCard = async (id) => {
      confirm({
         confirmText: 'Are you sure want to terminate this card?',
         isLoading: loading,
         onConfirm: async () => {
            setLoading(true);
            try {
               const payload = {
                  is_active: false,
               };
               const result = await editCardDetails(payload, id);
               if (result.ok) {
                  toast.success(result?.message || 'Card successfully Terminated');
                  fetchCards();
               }
               setLoading(false);
            } catch (err) {
               toast.error(err.message || err.custom_message);
               setLoading(false);
            }
         },
      });
   };

   useEffect(() => {
      fetchCards();
   }, []);

   return (
      <div className="row ">
         {loading ? (
            <Spinner />
         ) : (
            <>
               {cards?.map((item) => (
                  <div className="col-md-6 mb-5" key={item.id}>
                     <CardContainer card_status={item?.status}>
                        <div className="d-flex justify-content-between">
                           <div className="d-flex align-items-center mb-2">
                              <h3>
                                 <span className="mb-4 d-flex align-items-center">{item.name}</span>
                                 <p
                                    className="font-weight-normal"
                                    style={{ fontSize: '12px', fontWeight: '400' }}
                                 >
                                    {item.tokenized_pan}
                                 </p>
                              </h3>
                           </div>
                           <div>
                              <CardTypeWrapper cardType={item.card_type}>
                                 {item.card_type === 'physical' ? 'Physical Card' : 'Virtual Card'}
                              </CardTypeWrapper>
                           </div>
                        </div>
                        <div className="row gap-3">
                           <h4>Balance</h4>
                           <p style={{ fontSize: '28px', fontWeight: '700' }}>
                              ₦{item.debit_wallet_details?.available_balance}
                           </p>
                        </div>
                        <div className="d-flex gap-5 justify-content-between">
                           <div className="row mb-2">
                              <span
                                 style={{ fontSize: '14px', fontWeight: '400', color: '#82849F' }}
                              >
                                 Monthly Limit
                              </span>
                              <span
                                 style={{ fontSize: '14px', fontWeight: '400', color: '#04093F' }}
                              >
                                 {item?.limits?.limit === 'monthly'
                                    ? `₦${item?.limits?.amount}`
                                    : '-'}
                              </span>
                           </div>
                           <div className="row mb-2">
                              <span
                                 style={{ fontSize: '14px', fontWeight: '400', color: '#82849F' }}
                              >
                                 Daily Limit
                              </span>
                              <span
                                 style={{ fontSize: '14px', fontWeight: '400', color: '#04093F' }}
                              >
                                 {item?.limits?.limit === 'daily'
                                    ? `₦${item?.limits?.amount}`
                                    : '-'}
                              </span>
                           </div>
                        </div>
                        <div className="d-flex gap-2 justify-content-between align-items-center">
                           <Button
                              variant="secondary"
                              fullWidth
                              onClick={() => handleFreezeCard(item)}
                           >
                              {item?.status === 'deactivated' ? 'Unfreeze Card' : 'Freeze Card'}
                           </Button>
                           <Button
                              variant="danger"
                              fullWidth
                              onClick={() => handleTerminateCard(item?.id)}
                           >
                              Terminate
                           </Button>
                        </div>
                     </CardContainer>
                  </div>
               ))}
            </>
         )}
      </div>
   );
}
CardsTab.propTypes = {
   bizDetails: objectOf().isRequired,
};
