import { useState, useEffect } from 'react';
import { toast } from 'react-hot-toast';
import { getBizCurrentStatus } from 'services/business';

export default function businessMeta() {
   const [businessCurrentStatus, setBusinessCurrentStatus] = useState(null);
   useEffect(() => {
      (async function getBizStatus() {
         try {
            const currentStatuses = await getBizCurrentStatus();
            setBusinessCurrentStatus(currentStatuses.data);
         } catch (e) {
            toast.error(e.message);
         }
      })();
   }, []);
   return { businessCurrentStatus };
}
