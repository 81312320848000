/* eslint-disable react/prop-types */
import { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import { Input, NumberInput } from 'components/input';
import Modal from 'components/modal';
import useBusinessMeta from 'hooks/businessMeta';
import { func, bool, objectOf, string } from 'prop-types';
import { InputWrapper } from 'styles/form';
import { Button } from 'components/button';
import { SelectInput } from 'components/select';
import useFetchManagers from 'hooks/fetchManagers';
import { businessType, turnover, accountStatus, states } from 'utils/business-constants';
import { getBizCategory, getNewBizCategories } from 'services/business';

const assignedType = [
   {
      label: 'Assigned',
      value: 'assigned',
   },
   {
      label: 'Unassigned',
      value: 'unassigned',
   },
];

const ApprovedBusinessFilter = ({
   open,
   onClose,
   filterData,
   setFilterData,
   setFilterPayload,
   isAdmin,
   initialFilter,
}) => {
   const history = useHistory();
   const { businessCurrentStatus } = useBusinessMeta();
   const [bizCurrentStatus, setBizCurrentStatus] = useState([]);
   const [bizCategory, setBizCategory] = useState([]);
   const [newBizCategory, setNewBizCategory] = useState([]);
   const { managers } = useFetchManagers();

   const fetchBizCategories = async () => {
      try {
         const res = await getBizCategory();
         setBizCategory(res.data);
      } catch (e) {
         toast.error(e?.message);
      }
   };

   const fetchNewBizCategories = async () => {
      try {
         const res = await getNewBizCategories();
         setNewBizCategory(res.data);
      } catch (e) {
         toast.error(e?.message);
      }
   };

   useEffect(() => {
      fetchBizCategories();
      fetchNewBizCategories();
   }, []);

   const categoriesOptions = bizCategory?.map(({ category_name, category_slug }) => ({
      label: category_slug,
      value: category_name,
   }));

   const newCategoriesOptions = newBizCategory?.map(({ name }) => ({
      label: name,
      value: name,
   }));

   const categories = [...categoriesOptions, ...newCategoriesOptions];

   useEffect(() => {
      if (businessCurrentStatus) {
         setBizCurrentStatus(
            businessCurrentStatus.map(({ current_status_tag }) => ({
               value: current_status_tag,
               label:
                  current_status_tag.charAt(0).toUpperCase() +
                  current_status_tag.slice(1).replaceAll('_', ' '),
            })),
         );
      }
   }, [businessCurrentStatus]);

   function onChange(name, data) {
      setFilterData({
         ...filterData,
         [name]: data.value,
      });
   }

   const handleRequest = async (payload) => {
      try {
         history.push(`${history.location.pathname}?page=1`);
         setFilterData(payload);
         setFilterPayload(payload);
         onClose();
      } catch (err) {
         toast.error(err.message || err.custom_message);
         return err;
      }
   };

   function onSubmit() {
      handleRequest(filterData);
   }

   function clearFilter() {
      handleRequest(initialFilter || {});
   }

   return (
      <Modal open={open} onClose={onClose} title="Filters" center maxWidth="700px">
         <form>
            <div className="row">
               <div className="col-lg-6 mb-4">
                  <Input
                     placeholder="Start Date"
                     label="Start Date"
                     name="date_after"
                     type="date"
                     value={filterData?.date_after}
                     onChange={(e) => {
                        setFilterData({ ...filterData, date_after: e.target.value });
                     }}
                  />
               </div>
               <div className="col-lg-6 mb-4">
                  <Input
                     placeholder="End Date"
                     label="End Date"
                     name="date_before"
                     type="date"
                     value={filterData?.date_before}
                     onChange={(e) => {
                        setFilterData({ ...filterData, date_before: e.target.value });
                     }}
                  />
               </div>
               <div className="col-lg-6 mb-4">
                  <SelectInput
                     name="biz_type"
                     label="Business Type"
                     options={businessType}
                     onChange={(val) => onChange('biz_type', val)}
                     value={businessType.filter((item) => item.value === filterData.biz_type)}
                  />
               </div>

               {isAdmin && (
                  <div className="col-lg-6 mb-4">
                     <SelectInput
                        label="Manager"
                        onChange={(val) => onChange('assigned_agent', val)}
                        options={managers}
                        value={managers.filter((item) => item.value === filterData.assigned_agent)}
                     />
                  </div>
               )}

               {isAdmin && (
                  <div className="col-lg-6 mb-4">
                     <SelectInput
                        label="Assigned Type"
                        onChange={(val) => onChange('assigned_type', val)}
                        options={assignedType}
                        value={assignedType.filter(
                           (item) => item.value === filterData.assigned_type,
                        )}
                     />
                  </div>
               )}

               <div className="col-lg-6 mb-4">
                  <SelectInput
                     onChange={(val) => onChange('biz_current_status', val)}
                     label="Status"
                     options={bizCurrentStatus}
                     value={bizCurrentStatus.filter(
                        (item) => item.value === filterData.biz_current_status,
                     )}
                  />
               </div>
               <div className="col-lg-6 mb-4">
                  <SelectInput
                     label="Turnover"
                     onChange={(val) => onChange('biz_turnover', val)}
                     options={turnover}
                     value={turnover.filter((item) => item.value === filterData.biz_turnover)}
                  />
               </div>
               <div className="col-lg-6 mb-4">
                  <SelectInput
                     label="Account Status"
                     onChange={(val) => onChange('activity_status', val)}
                     options={accountStatus}
                     value={accountStatus.filter(
                        (item) => item.value === filterData.activity_status,
                     )}
                  />
               </div>
               <div className="col-lg-6 mb-4">
                  <SelectInput
                     label="State"
                     onChange={(val) => onChange('biz_state', val)}
                     options={states}
                     value={states.filter((item) => item.value === filterData.biz_state)}
                  />
               </div>
               <div className="col-lg-6 mb-4">
                  <NumberInput
                     placeholder="Enter value greater than"
                     label="Deposit Volume(min)"
                     name="deposit_volume_gte"
                     value={filterData?.deposit_volume_gte}
                     prefix="₦"
                     className={Input}
                     inputmode="numeric"
                     onChange={(val) =>
                        setFilterData({ ...filterData, deposit_volume_gte: val.floatValue })
                     }
                  />
               </div>
               <div className="col-lg-6 mb-4">
                  <NumberInput
                     placeholder="Enter Value lesser than"
                     label="Deposit Volume(max)"
                     name="deposit_volume_lt"
                     value={filterData?.deposit_volume_lt}
                     prefix="₦"
                     className={Input}
                     inputmode="numeric"
                     onChange={(val) =>
                        setFilterData({ ...filterData, deposit_volume_lt: val.floatValue })
                     }
                  />
               </div>
               <div className="col-lg-6 mb-4">
                  <SelectInput
                     label="Category"
                     onChange={(val) => onChange('biz_category', val)}
                     options={categories}
                     value={categories.filter((item) => item.value === filterData.biz_category)}
                  />
               </div>
            </div>

            <InputWrapper
               style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
            >
               <div style={{ marginRight: '20px' }}>
                  <Button variant="secondary" type="button" onClick={clearFilter} fullWidth>
                     clear filter
                  </Button>
               </div>

               <div>
                  <Button variant="primary" type="button" onClick={onSubmit} fullWidth>
                     Apply
                  </Button>
               </div>
            </InputWrapper>
         </form>
      </Modal>
   );
};

export { ApprovedBusinessFilter };

ApprovedBusinessFilter.propTypes = {
   open: bool.isRequired,
   onClose: func.isRequired,
   isAdmin: bool.isRequired,
   initialFilter: objectOf(string).isRequired,
};
