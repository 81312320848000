import { Http } from 'services/http';
import { paramsObjectToQueryString } from 'utils/others';
import axiosCustom from 'utils/axios';

export const getAllPromotions = (page) =>
   Http(`/backmen/list_campaign${paramsObjectToQueryString(page)}`);

export const createNewCampaign = async (payload) => {
   try {
      const response = await axiosCustom().post(`/backmen/add_campaign/`, payload);
      return response;
   } catch (e) {
      throw new Error(e.response?.data?.message || e.message);
   }
};

export const updateCampaignDetails = async (payload) => {
   try {
      const response = await axiosCustom().post(`/backmen/edit_campaign/`, payload);
      return response;
   } catch (e) {
      throw new Error(e.message);
   }
};

export const createNewChildCampaign = async (payload) => {
   try {
      const response = await axiosCustom().post(`/backmen/add_child_campaign/`, payload);
      return response;
   } catch (e) {
      throw new Error(e.response?.data?.message || e.message);
   }
};

export const updateChildCampaignDetails = async (payload) => {
   try {
      const response = await axiosCustom().post(`/backmen/edit_child_campaign/`, payload);
      return response;
   } catch (e) {
      throw new Error(e.message);
   }
};
