import { Http } from 'services/http';
import { paramsObjectToQueryString } from 'utils/others';
import {
   can_set_transaction_limit,
   can_view_queued_transfers,
   can_view_transaction_limit,
} from 'utils/user_actions';
import { logActivity } from 'services/activity';
import axiosCustom from 'utils/axios';

export const getAllTransactions = (page) =>
   Http(`/backmen/export_transactions_data${paramsObjectToQueryString(page)}`);

// PERMISSION IS HANDLED BY PAGE ACCESS- nibss_transactions_api
export const getAllNibssTransactions = (query) =>
   Http(`/express/transfer_logs${paramsObjectToQueryString(query)}`);

export const getChildTransactions = ({ id, ...params }) =>
   Http(`/runna/sub_transactions/${id}/${paramsObjectToQueryString(params)}`);

export const getComplianceBlockedAccounts = (query) =>
   Http(`/compliance/flags${paramsObjectToQueryString(query)}`);

export const exportNibssTransactions = (query) =>
   Http(`/express/transfer_exports${paramsObjectToQueryString(query)}`, {
      action: 'export_nibss_transactions',
   });

export const getExportedNibssTransactions = (params) =>
   Http(`/express/exported_transactions${paramsObjectToQueryString(params)}`, {
      action: 'export_nibss_transactions',
   });

export const crossCheckNibssTransactions = async (payload) => {
   try {
      const response = await axiosCustom().post(`/express/transactions_cross_check`, payload);
      await logActivity(`Cross checked NIBSS Transactions`, 'reg');
      return response;
   } catch (e) {
      throw new Error(e.response?.data?.message || e.message);
   }
};

export const debitTransactions = async (payload) => {
   try {
      const response = await axiosCustom({
         action: 'can_debit_nibss_member_wallet',
      }).post(`/express/nibss_debit_wallet`, payload);
      await logActivity(`Ran debit for NIBSS transactions`, 'reg');
      return response;
   } catch (e) {
      throw new Error(e.response?.data?.message || e.message);
   }
};

export const listTransactionSettings = () => Http(`/settings/settings/`);

export const listDefaultTransactionSettings = () => Http(`/settings/settings/defaults/`);

export const updateTransactionSettings = async (id, payload, logMessage) => {
   try {
      const response = await Http(`/settings/settings/${id}/`, {
         method: 'PUT',
         body: payload,
      });
      await logActivity(logMessage || `Updated transaction channel settings`);
      return response;
   } catch (e) {
      throw new Error(e.response?.data?.message || e.message);
   }
};

export const getMonthlyInflow = async (payload) => {
   try {
      const response = await Http(`/backmen/ranger_inflow_monthly/`, {
         method: 'POST',
         body: payload,
      });
      return response;
   } catch (e) {
      throw new Error(e.response?.data?.message || e.message);
   }
};

export const getSystemTransactionLimits = () => Http(`/express/transaction_limits/system/`);

export const getBusinessTransactionLimits = (params) =>
   Http(`/express/transaction_limits/user/${paramsObjectToQueryString(params)}`, {
      action: can_view_transaction_limit,
   });

export const updateSystemTransactionLimit = async (payload) => {
   try {
      const response = await Http(`/express/transaction_limits/set/`, {
         method: 'POST',
         body: payload,
         action: can_set_transaction_limit,
      });
      await logActivity(`updated transaction limit`);
      return response;
   } catch (e) {
      throw new Error(e.response?.data?.message || e.message);
   }
};

export const getTransactionsDailyStats = (payload) => {
   return Http(`/backmen/total_daily_flow${paramsObjectToQueryString(payload)}`);
};

export const getTransactionMonthlyStats = (payload) => {
   return Http(`/backmen/total_monthly_flow${paramsObjectToQueryString(payload)}`);
};

export const getProspaTransactionLimit = (payload) => {
   return Http(`/backmen/transfer_setting${paramsObjectToQueryString(payload)}`);
};

export const getPendingTransferStats = (payload) => {
   return Http(`/backmen/queued_transfers_stats${paramsObjectToQueryString(payload)}`);
};

export const getPendingTransfers = (payload) => {
   return Http(`/backmen/queued_transfers${paramsObjectToQueryString(payload)}`);
};

export const approvePendingTransactions = (payload) =>
   Http(`/backmen/queued_transfers`, {
      method: 'POST',
      body: payload,
      action: can_view_queued_transfers,
   });

export const updateProspaTransactionLimit = async (payload) => {
   const response = await Http(`/backmen/transfer_setting`, {
      method: 'PATCH',
      body: payload,
      action: can_set_transaction_limit,
   });
   await logActivity(`updated prospa transaction limit`);
   return response;
};
