import styled, { css } from 'styled-components';

export const CardContainer = styled.div.attrs((props) => ({
   disabled: props.card_status === 'deactivated',
}))`
   display: flex;
   padding: 20px;
   flex-direction: column;
   gap: 32px;
   justify-content: between;

   border-radius: 8px;
   background: #fff;
   box-shadow: 2px 2px 20px 0px rgba(0, 0, 0, 0.08);

   ${(props) =>
      props.disabled &&
      css`
         opacity: 0.5;
         pointer-events: none;

         button {
            pointer-events: auto;
            opacity: 1;
         }
      `}
`;

export const CardTypeWrapper = styled.div`
   display: flex;
   padding: 10px;
   justify-content: center;
   align-items: center;
   gap: 10px;
   border-radius: 100px;
   background: ${(props) =>
      props.cardType === 'physical' ? 'rgba(19, 84, 211, 0.10)' : 'rgba(169, 169, 169, 0.10)'};
   color: ${(props) => (props.cardType === 'physical' ? '#1354D3' : '#C8C8C8')};
`;
