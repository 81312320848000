import { useEffect, useState } from 'react';
import { number } from 'prop-types';
import moment from 'moment';
import { Spinner } from 'components/spinner';
import { getAdminLogs } from 'services/businessDetails';
import { AlertMessage } from 'components/message';
import LogRow from './log-row';
import { TableWrapper, Table, Th, THead } from './log.style';

const Log = ({ businessAccountId }) => {
   const [logs, setLogs] = useState([]);
   const [error, setError] = useState('');
   const [loading, setLoading] = useState(true);

   const fetchAdminLogs = async () => {
      try {
         const result = await getAdminLogs({ unreg_biz_id: businessAccountId });
         if (result.ok) {
            setLogs(result?.data);
            setError('');
         }
         setLoading(false);
      } catch (err) {
         setError(err.message || err.custom_message);
         setLoading(false);
      }
   };

   useEffect(() => {
      fetchAdminLogs();
   }, []);
   return (
      <TableWrapper className="overflow-auto">
         {loading ? (
            <Spinner />
         ) : (
            <>
               <AlertMessage message={error} variant="error" />
               <Table>
                  <THead>
                     <tr>
                        <Th>Admin</Th>
                        <Th>Description</Th>
                        <Th>Time Stamp</Th>
                     </tr>
                  </THead>
                  <tbody>
                     {logs?.length ? (
                        logs?.map((logItem) => {
                           const { first_name, last_name } = logItem?.manager_details;
                           const { timestamp } = logItem;
                           const adminName = `${first_name} ${last_name}`;
                           const date = moment(timestamp).format('MMM DD, YYYY');
                           const time = moment(timestamp).format('h:mm a');
                           return (
                              <LogRow
                                 key={logItem.action_id}
                                 adminName={adminName}
                                 description={logItem.description}
                                 date={date}
                                 time={time}
                              />
                           );
                        })
                     ) : (
                        <div />
                     )}
                  </tbody>
               </Table>
            </>
         )}
      </TableWrapper>
   );
};

Log.propTypes = {
   businessAccountId: number.isRequired,
};

export default Log;
