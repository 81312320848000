/* eslint-disable react/prop-types */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-unused-vars  */
import { useEffect, useState } from 'react';
import { getBusinessDetails } from 'services/businessDetails';
import moment from 'moment';
import { func, number } from 'prop-types';
import { Spinner } from 'components/spinner';
import { TableComponent } from 'components/table';
import { TableContainer, TableContent } from 'styles/table';
import { Status } from 'styles/utils';
import IconButton from '@material-ui/core/IconButton';
import { MenuComponent } from 'components/menu';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { validateBvn } from 'services/misc';
import { businessRefActions } from 'services/loans';
import { confirm } from 'components/confirm-box';
// import { numberWithCommas } from 'utils/others';
import { toast } from 'react-hot-toast';
// import { formatTag } from 'utils/tags';
import { TooltipComponent } from 'components/tooltip';
import { Route } from 'react-router-dom';
// import { AlertMessage } from 'components/message';
// import { Input } from 'components/input';
// import { OverviewWrapper } from 'styles/layout';

const RequestedLoans = ({ businessAccountId, initialize }) => {
   const [paginationData, setPaginationData] = useState({});
   const [currentPage, setCurrentPage] = useState(1);
   const [loading, setLoading] = useState(false);
   const [tableData, setTableData] = useState([]);
   const [anchorEl, setAnchorEl] = useState(null);
   const [isChangeStatusLoading, setIsChangeStatusLoading] = useState(false);
   const [bvnData, setBvnData] = useState({});
   const [validatingBvn, setValidationBvn] = useState(false);
   const open = Boolean(anchorEl);
   // const [error, setError] = useState('');
   // const [bizAccount, setBizAccount] = useState('');
   // const [detailsModal, setDetailsModal] = useState(false);
   // const [singleLoan, setSingleLoan] = useState('');

   async function fetchLoansRequested() {
      setLoading(true);
      try {
         const result = await getBusinessDetails(businessAccountId);
         setPaginationData(result);
         // console.log(result.data.biz_reference_details);
         setTableData(result.data.biz_reference_details);
      } catch ({ message }) {
         toast.error(message);
      }
      setLoading(false);
   }

   useEffect(() => {
      fetchLoansRequested();
   }, []);

   // function handleOpenModal(row) {
   //    // setActiveTab('loan-overview');
   //    // console.log(row.biz_account_id);
   //    // setBizAccount(row.biz_account_id);
   //    // setSingleLoan(row.biz_loan_id);
   //    // setDetailsModal(true);
   // }

   function onPageChange(e, pageNumber) {
      setCurrentPage(pageNumber);
   }

   const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
   };

   const handleClose = () => {
      setAnchorEl(null);
   };

   const options = (row) => {
      // if (row.ref_bvn_number !== '') return ['Validate BVN', 'Accept', 'Reject'];
      return ['Accept', 'Reject'];
   };

   const handleBvnValidation = async () => {
      if (!bvnData.ref_bvn_number) return toast.error('Error validating BVN');
      setValidationBvn(true);
      try {
         const response = await validateBvn({
            bvn_number: bvnData.ref_bvn_number,
         });
         // console.log(response);
         toast.success('BVN validated successfully');
         const references = tableData;
         const arrayIndex = references.findIndex((item) => item.biz_reference_id === bvnData.id);
         references[arrayIndex].ref_bvn_status = 'accepted';
         setTableData(references);
         // console.log('Success');
      } catch (e) {
         toast.error(e.message);
         const references = tableData;
         const arrayIndex = references.findIndex(
            (item) => item.biz_reference_id === bvnData.biz_reference_id,
         );
         references[arrayIndex].ref_bvn_status = 'rejected';
         setTableData([references]);
      }
      setValidationBvn(false);
   };

   function onRowClick({ row }) {
      setBvnData(row);
   }

   // useEffect(() =>{
   //    if(bvnData.biz_reference_id && bvnData.biz_reference_id !== ''){
   //       handleBvnValidation()
   //    }
   // },[bvnData.biz_reference_id])

   const columns = [
      {
         field: 'ref_first_name',
         headerName: 'Name',
         renderCell: ({ row }) => (
            <TooltipComponent title={row.ref_first_name}>
               {`${row.ref_first_name} ${row.ref_last_name}`}
            </TooltipComponent>
         ),
      },
      {
         field: 'ref_phone',
         headerName: 'Phone',
         renderCell: ({ row }) => (
            <TooltipComponent title={row.ref_phone}>{`${row.ref_phone}`}</TooltipComponent>
         ),
      },
      {
         field: 'ref_email',
         headerName: 'Email',
         renderCell: ({ row }) => (
            <TooltipComponent title={row.ref_email}>{row.ref_email}</TooltipComponent>
         ),
      },
      {
         field: 'ref_address',
         headerName: 'Address',
         renderCell: ({ row }) => (
            <TooltipComponent title={row.ref_address}>
               {row.ref_address} {', '} {row.ref_city}
            </TooltipComponent>
         ),
      },
      {
         field: 'ref_state',
         headerName: 'State',
         renderCell: ({ row }) => (
            <TooltipComponent title={row.ref_state}>{row.ref_state}</TooltipComponent>
         ),
      },
      {
         field: 'ref_status',
         headerName: 'Status',
         // width: 150,
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.ref_status}>
               <Status status={row?.ref_status}>{row.ref_status}</Status>
            </TooltipComponent>
         ),
      },
      // {
      //    field: 'ref_bvn_status',
      //    headerName: 'BVN',
      // renderCell: ({ row }) => (
      // <TooltipComponent title={row.ref_bvn_status}>
      //    {
      //       row.ref_bvn_status && row.ref_bvn_status === ''
      //       ?<Status status={row?.ref_bvn_status}>{row.ref_bvn_status}</Status>
      //       : "N/A"
      //    }
      // </TooltipComponent>
      // <Status status='rejected'>rejected</Status>
      // <TooltipComponent title={row.ref_bvn_status}>
      //    <Status status={row?.ref_bvn_status}>{row.ref_bvn_status}</Status>
      //    {row.ref_bvn_status}
      // </TooltipComponent>
      // ),
      // },
      {
         field: 'pub_date',
         headerName: 'Date',
         renderCell: ({ row }) => (
            <TooltipComponent title={moment(row.pub_date).format('DD MMM, YYYY')}>
               {moment(row.pub_date).format('DD MMM, YYYY')} |{' '}
               <span style={{ opacity: '.5' }}>{moment(row.pub_date).format('HH:mm A')}</span>
            </TooltipComponent>
         ),
      },
      {
         field: '',
         width: 0,
         renderCell: ({ row }) =>
            row.ref_status === 'pending' && (
               <div>
                  <IconButton
                     aria-label="more"
                     aria-controls="long-menu"
                     aria-haspopup="true"
                     onClick={handleClick}
                  >
                     <MoreVertIcon />
                  </IconButton>
                  <MenuComponent
                     anchorEl={anchorEl}
                     handleClose={handleClose}
                     open={open}
                     options={options(row)}
                     optionsFns={[
                        // place the callbacks in position corresponding to the options index
                        // () => {
                        //    handleBvnValidation();
                        // },

                        () => {
                           confirm({
                              confirmText: 'Are you sure you want to perform this action?',
                              isLoading: isChangeStatusLoading,
                              onConfirm: async () => {
                                 setIsChangeStatusLoading(true);
                                 try {
                                    const result = await businessRefActions(
                                       {
                                          biz_reference_id: bvnData.biz_reference_id,
                                          reference_action: 'approved',
                                       },
                                       paginationData.data.biz_account_id,
                                    );
                                    // console.log(result);
                                    toast.success(result.message);
                                    await fetchLoansRequested();
                                    initialize();
                                 } catch (e) {
                                    // console.log(e);
                                    toast.error(e.message);
                                 }
                                 setIsChangeStatusLoading(false);
                              },
                           });
                        },
                        () => {
                           confirm({
                              confirmText: 'Are you sure you want to perform this action?',
                              isLoading: isChangeStatusLoading,
                              onConfirm: async () => {
                                 try {
                                    setIsChangeStatusLoading(true);
                                    const result = businessRefActions(
                                       {
                                          biz_reference_id: bvnData.biz_reference_id,
                                          reference_action: 'rejected',
                                       },
                                       paginationData.data.biz_account_i,
                                    );
                                    toast.success(result.message);
                                    await fetchLoansRequested();
                                    initialize();
                                 } catch (e) {
                                    toast.error(e.message);
                                 }
                                 setIsChangeStatusLoading(false);
                              },
                           });
                        },

                        // () => handleOpenModal(row.biz_account_id),
                        // () => callNumber(row?.account_holder_details?.phone),
                        // () => sendMessageModalSection(row.biz_account_id),
                     ]}
                  />
               </div>
            ),
      },
   ];
   // console.log("BVN DATA : ", bvnData.biz_reference_id)
   // console.log("TABLE DATA",tableData)
   return (
      <div>
         {/* <AlertMessage message={error} variant="error" /> */}

         {loading ? (
            <Spinner />
         ) : (
            <TableContainer>
               <TableContent>
                  <div>
                     <h1> Requested Loans</h1>
                     <span>{paginationData?.count}</span>
                  </div>
               </TableContent>
               <TableComponent
                  columns={columns}
                  rows={tableData}
                  isLoading={loading}
                  onPageChange={onPageChange}
                  count={paginationData.count}
                  onRowClick={onRowClick}
               />
            </TableContainer>
         )}
      </div>
   );
};

RequestedLoans.propTypes = {
   businessAccountId: number.isRequired,
   initialize: func.isRequired,
};

export default RequestedLoans;
