import { useEffect, useState } from 'react';
import { getAllManagersNew } from 'services/business';

export default function useFetchManagers() {
   const [managers, setManagers] = useState([]);
   useEffect(() => {
      (async () => {
         try {
            const result = await getAllManagersNew();
            setManagers(
               result.data.map((item) => ({
                  value: item.prospa_manager_id,
                  label: `${item.first_name} ${item.last_name}`,
                  user_type: item.user_type,
               })),
            );
         } catch (err) {
            return err;
         }
      })();
   }, []);
   return {
      managers,
   };
}
