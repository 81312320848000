import { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import moment from 'moment';
import Modal from 'components/modal';
import { getSingleAdminStats, adminTrackaCount, getAdminDocumentStats } from 'services/admin';
import { useAuth } from 'hooks/auth';
import { Spinner } from 'components/spinner';
import { bool, func, number } from 'prop-types';
import Overview from './overview';
import FilteredStats from './filtered-stats';
import { PublicProfile } from './public-profile';
import { Navigation, NavigationTab } from './style';

const AdminDetails = ({ open, onClose, prospaManagerId, noCloseIcon }) => {
   const [activeTab, setActiveTab] = useState('overview');
   const { currentUser } = useAuth();
   const [adminDetails, setAdminDetails] = useState(null);
   const [loading, setLoading] = useState(true);
   const [isStatLoading, setIsStatLoading] = useState(false);
   const [stats, setStats] = useState(null);
   const { permissions } = currentUser?.access || {};
   const [filteredDate, setFilteredDate] = useState({
      start_date: moment().subtract(1, 'day').format('YYYY-MM-DD'),
      end_date: moment().format('YYYY-MM-DD'),
   });

   const action = 'admin_manager_stats';

   useEffect(() => {
      if (!permissions?.includes() && permissions !== 'all') {
         toast.error(`You are not authorized to perform this action: ${action}`);
         return onClose();
      }
   }, []);

   useEffect(() => {
      setFilteredDate({
         start_date: moment().subtract(1, 'day').format('YYYY-MM-DD'),
         end_date: moment().format('YYYY-MM-DD'),
      });
   }, [activeTab]);

   const fetchAdminFilteredStats = async () => {
      setIsStatLoading(true);
      try {
         const { start_date, end_date } = filteredDate;
         const result = await adminTrackaCount({
            prospa_manager_id: prospaManagerId,
            start_date,
            end_date,
         });
         if (result.ok) {
            setStats(result.data);
         }
      } catch (err) {
         toast.error(err.message || err.custom_message);
      }
      setIsStatLoading(false);
   };

   const fetchDocumentStats = async () => {
      setIsStatLoading(true);
      try {
         const { start_date, end_date } = filteredDate;
         const result = await getAdminDocumentStats({
            prospa_manager_id: prospaManagerId,
            start_date,
            end_date,
         });
         if (result.ok) {
            setStats(result.data);
         }
      } catch (err) {
         toast.error(err.message || err.custom_message);
      }
      setIsStatLoading(false);
   };

   const fetchSingleAdminStats = async () => {
      try {
         const result = await getSingleAdminStats(prospaManagerId);
         if (result.ok) {
            setAdminDetails(result.data);
         }
         setLoading(false);
      } catch (err) {
         toast.error(err.message || err.custom_message);
         setLoading(false);
      }
   };
   useEffect(() => {
      (async function init() {
         await fetchSingleAdminStats();
      })();
   }, []);

   const activePage = () => {
      switch (activeTab) {
         case '':
         case 'overview':
            return (
               <Overview
                  adminDetails={adminDetails}
                  fetchSingleAdminStats={fetchSingleAdminStats}
               />
            );

         case 'filtered-stats':
            return (
               <FilteredStats
                  fetchFilteredStats={fetchAdminFilteredStats}
                  stats={stats}
                  activeStat={activeTab}
                  filteredDate={filteredDate}
                  setFilteredDate={setFilteredDate}
                  loading={isStatLoading}
               />
            );

         case 'document-stats':
            return (
               <FilteredStats
                  fetchFilteredStats={fetchDocumentStats}
                  stats={stats}
                  activeStat={activeTab}
                  filteredDate={filteredDate}
                  setFilteredDate={setFilteredDate}
                  loading={isStatLoading}
               />
            );
         case 'public-profile':
            return <PublicProfile prospaManagerId={prospaManagerId} adminDetails={adminDetails} />;
         default:
            return 'No Screen';
      }
   };

   if (loading) return <Spinner />;
   return (
      <Modal
         open={open}
         onClose={() => {
            onClose();
            setActiveTab(null);
         }}
         closeOnOverlayClick={false}
         noCloseIcon={noCloseIcon}
         title="Business Account Details"
         className="biz-details-modal"
      >
         <div className="d-flex justify-content-between">
            <Navigation>
               <NavigationTab
                  className={activeTab === 'overview' ? 'active' : ''}
                  onClick={() => setActiveTab('overview')}
               >
                  Overview
               </NavigationTab>

               <NavigationTab
                  className={activeTab === 'filtered-stats' ? 'active' : ''}
                  onClick={() => setActiveTab('filtered-stats')}
               >
                  Filtered Stats
               </NavigationTab>

               <NavigationTab
                  className={activeTab === 'document-stats' ? 'active' : ''}
                  onClick={() => setActiveTab('document-stats')}
               >
                  Document Stats
               </NavigationTab>
               <NavigationTab
                  className={activeTab === 'public-profile' ? 'active' : ''}
                  onClick={() => setActiveTab('public-profile')}
               >
                  Public Profile
               </NavigationTab>
            </Navigation>
         </div>
         <div className="pt-5">{activePage()}</div>
      </Modal>
   );
};

AdminDetails.propTypes = {
   open: bool.isRequired,
   onClose: func.isRequired,
   prospaManagerId: number.isRequired,
   noCloseIcon: bool.isRequired,
};
export default AdminDetails;
