/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import LoggedInDashboard from 'components/logged-in-dashboard';
import { confirm } from 'components/confirm-box/confirm-box';
import useBusinessMeta from 'hooks/businessMeta';
import { TableContainer, TableContent } from 'styles/table';
import BusinessRegDetails from 'containers/business-reg-details';
import { getBusinessRegistrations } from 'services/unregistered_biz';
import { TooltipComponent } from 'components/tooltip';
import { changeStatus } from 'services/business';
import { Skeleton } from '@material-ui/lab';
import { TableComponent } from 'components/table';
import IconButton from '@material-ui/core/IconButton';
import toast, { Toaster } from 'react-hot-toast';
import moment from 'moment';
import { Input } from 'components/input';
import Calendar from 'components/calendar';
import { Status } from 'styles/utils';
import FilterListIcon from '@material-ui/icons/FilterList';
import { MenuComponent } from 'components/menu';
import { useBizDetailsModal } from 'hooks/bizDetailsModal';
import { BusinessAccountFilterModal } from 'containers/business-account-filter-modal';
import { useDebouncedCallback } from 'use-debounce';
import { numberWithCommas } from 'utils/others';
import { MoreHoriz } from '@material-ui/icons';
import { FilterContainer } from './style';

function BusinessRegistration() {
   const { businessCurrentStatus } = useBusinessMeta();
   const [bizCurrentStatus, setBizCurrentStatus] = useState([]);
   const [tableData, setTableData] = useState([]);
   const [isLoading, setIsLoading] = useState(false);
   const [anchorEl, setAnchorEl] = useState(null);
   const [paginationData, setPaginationData] = useState({});
   const [currentPage, setCurrentPage] = useState(1);
   const [modalVisibility, setModalVisibility] = useState(false);
   const [searchTerm, setSearchTerm] = useState('');
   // const [years, setYears] = useState([]);
   const [isChangeStatusLoading, setIsChangeStatusLoading] = useState(false);
   const [modalVisibilityFilter, setModalVisibilityFilter] = useState(false);
   const [bizAccount, setBizAccount] = useState('');
   const { setActiveTab } = useBizDetailsModal();
   const open = Boolean(anchorEl);
   const [filterData, setfilterData] = useState({
      start_date: '2019-01-01',
      end_date: moment().format('YYYY-MM-DD'),
   });

   const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
   };

   const handleClose = () => {
      setAnchorEl(null);
   };

   useEffect(() => {
      if (businessCurrentStatus) {
         setBizCurrentStatus(
            businessCurrentStatus.map(({ current_status_tag }) => ({
               value: current_status_tag,
               options:
                  current_status_tag.charAt(0).toUpperCase() +
                  current_status_tag.slice(1).replaceAll('_', ' '),
            })),
         );
      }
   }, [businessCurrentStatus]);

   async function handleChangeStatus({ target }, bizId) {
      confirm({
         confirmText: 'Are you sure you want to perform this action?',
         isLoading: isChangeStatusLoading,
         onConfirm: async () =>
            new Promise((resolve, reject) => {
               setIsChangeStatusLoading(true);
               changeStatus(
                  {
                     biz_current_status: target.value,
                     unregistered_biz_id: bizId,
                  },
                  'unreg',
               )
                  .then(() => {
                     setIsChangeStatusLoading(false);
                     toast.success('success');
                     resolve();
                  })
                  .catch((err) => {
                     setIsChangeStatusLoading(false);
                     reject(err);
                     toast.error(err.message || err.custom_message);
                  });
            }),
      });
   }

   function handleOpenModal() {
      // setBizAccount(id);
      setActiveTab('overview');
      setModalVisibility(true);
   }

   function sendMessageModalSection() {
      // setBizAccount(id);
      setActiveTab('message');
      setModalVisibility(true);
   }

   function callNumber(phone) {
      const link = document.createElement('a');
      const linkText = document.createTextNode('call us');
      link.appendChild(linkText);
      link.href = `tel:${phone}`;
      link.click();
   }

   const options = ['view Details', 'Call', 'Send Message'];

   const columns = [
      {
         field: '',
         width: 0,
         renderCell: ({ row }) => (
            <div>
               <IconButton
                  aria-label="more"
                  aria-controls="long-menu"
                  aria-haspopup="true"
                  onClick={handleClick}
               >
                  <MoreHoriz />
               </IconButton>
               <MenuComponent
                  anchorEl={anchorEl}
                  handleClose={handleClose}
                  open={open}
                  options={options}
                  optionsFns={[
                     // place the callbacks in position corresponding to the options index
                     () => handleOpenModal(row.unregistered_biz_id),
                     () => callNumber(row?.account_holder_details?.phone),
                     () => sendMessageModalSection(row.unregistered_biz_id),
                  ]}
               />
            </div>
         ),
      },
      {
         field: 'pref_biz_name_one',
         headerName: 'Preferred Name',
         renderCell: ({ row }) => (
            <TooltipComponent title={row.pref_biz_name_one}>
               <span
                  style={{
                     cursor: 'pointer',
                  }}
                  className="text-primary"
                  onClick={() => handleOpenModal(row.unregistered_biz_id)}
               >
                  {row.pref_biz_name_one || 'N/A'}
               </span>
            </TooltipComponent>
         ),
      },
      {
         field: 'owner',
         headerName: 'Owner',
         renderCell: ({ row }) => (
            <span style={{ textTransform: 'capitalize' }}>
               {row?.account_holder_details?.first_name.toLowerCase()}{' '}
               {row?.account_holder_details?.last_name.toLowerCase()}
            </span>
         ),
      },
      {
         field: 'payment_status',
         headerName: 'Payment',
         renderCell: ({ row }) => (
            <Status status={row.payment_status === 'has_paid' ? 'accepted' : 'rejected'}>
               {row.payment_status === 'has_paid' ? 'Paid' : 'Unpaid'}
            </Status>
         ),
      },
      {
         field: 'amount',
         headerName: 'Amount Paid',
         renderCell: ({ row }) => (
            <TooltipComponent title={row.amount}>₦{numberWithCommas(row.amount)}</TooltipComponent>
         ),
      },
      {
         field: 'account_holder_details.phone',
         headerName: 'Phone',
         renderCell: ({ row }) => <span>{row?.account_holder_details?.phone || 'N/A'}</span>,
      },
      {
         field: 'assigned_manager_details.email',
         headerName: 'Manager',
         renderCell: ({ row }) => (
            <span>
               {row.assigned_manager_details
                  ? `${row?.assigned_manager_details?.first_name} ${row?.assigned_manager_details?.last_name}`
                  : 'N/A'}
            </span>
         ),
      },
      {
         field: 'biz_status',
         headerName: 'Status',
         renderCell: ({ row }) => (
            <select
               style={{
                  width: '100%',
                  border: 'none',
                  padding: '10px',
                  fontWeight: 400,
                  fontSize: '1.3rem',
               }}
               onChange={(e) => handleChangeStatus(e, row.unregistered_biz_id)}
               name="status"
               defaultValue={row.biz_current_status}
            >
               {bizCurrentStatus.map((item) => (
                  <option value={item.value}>{item.options}</option>
               ))}
            </select>
         ),
      },
      {
         field: 'modified_date',
         headerName: 'Date Modified ',
         renderCell: ({ row }) => (
            <span>
               {`${moment(row?.modified_date).format('MMM DD, YYYY')} | ${moment(
                  row?.modified_date,
               ).format('h:mm a')}`}
            </span>
         ),
      },
      {
         field: 'pub_date',
         headerName: 'Date Created ',
         renderCell: ({ row }) => (
            <span>
               {`${moment(row?.pub_date).format('MMM DD, YYYY')} | ${moment(row?.pub_date).format(
                  'h:mm a',
               )}`}
            </span>
         ),
      },
   ];

   useEffect(() => {
      (async () => {
         setIsLoading(true);
         const payload = {
            // ...(searchTerm !== '' && { biz_friendly_name: searchTerm }),
            start_date: '2019-01-01',
            end_date: moment().format('YYYY-MM-DD'),
            ...filterData,
         };
         setTableData([]);
         try {
            Promise.all([getBusinessRegistrations({ page: currentPage }, payload)]).then(
               ([businessAccountsData]) => {
                  setTableData(businessAccountsData.data);
                  setPaginationData(businessAccountsData);
               },
            );
         } catch (e) {
            toast.error(e.message);
         }
         setIsLoading(false);
      })();
   }, [currentPage, searchTerm]);

   function onPageChange(e, pageNumber) {
      setCurrentPage(pageNumber);
   }

   function handleSearch({ target }) {
      setSearchTerm(target.value);
   }
   const debounce = useDebouncedCallback(handleSearch, 600);

   function onRowClick({ row }) {
      setBizAccount(row.unregistered_biz_id);
   }

   return (
      <LoggedInDashboard pageTitle="Business Registrations">
         <BusinessAccountFilterModal
            open={modalVisibilityFilter}
            onClose={() => setModalVisibilityFilter(false)}
            setBusinessInfo={setTableData}
            setPaginationData={setPaginationData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            setfilterData={setfilterData}
            filterData={filterData}
            filterFunction={(page, payload) =>
               getBusinessRegistrations(page, {
                  ...payload,
               })
            }
            active={['date', 'payment', 'status', 'decision']}
         />

         <Toaster />
         <TableContainer>
            <TableContent>
               {isLoading ? (
                  <Skeleton width="30%" height="20" />
               ) : (
                  <div>
                     <h1> Business Accounts</h1>
                     <span className="value">{paginationData?.count || 'N/A'}</span>
                  </div>
               )}
               <div className="d-flex justify-content-between">
                  <div>
                     <Input style={{ width: '300px' }} placeholder="Search" onChange={debounce} />
                  </div>
                  <FilterContainer onClick={() => setModalVisibilityFilter(true)}>
                     Filter By <FilterListIcon style={{ marginLeft: '10px' }} />
                  </FilterContainer>
                  <Calendar />
               </div>
            </TableContent>
            <TableComponent
               columns={columns}
               rows={tableData}
               isLoading={isLoading}
               onPageChange={onPageChange}
               count={Math.ceil(paginationData.count / 15)}
               onRowClick={onRowClick}
            />
         </TableContainer>
         {modalVisibility && (
            <BusinessRegDetails
               businessAccountId={bizAccount}
               open={modalVisibility}
               onClose={() => setModalVisibility(false)}
            />
         )}
      </LoggedInDashboard>
   );
}

export default BusinessRegistration;
