import React, { useState } from 'react';
import { toast } from 'react-hot-toast';
import { updatePersonalDetails } from 'services/businessDetails';
import { Button } from 'components/button';
import { Input } from 'components/input';
import { confirm } from 'components/confirm-box';
import { InputWrapper } from 'styles/form';
import { hasValue, validateEmail } from 'utils/validations';

export default function UpdateBusinessEmail() {
   const [memberEmail, setMemberEmail] = useState('');
   const [loading, setLoading] = useState(false);
   const [accountId, setAccountId] = useState('');

   const isValid = validateEmail(memberEmail) && hasValue(accountId);

   const updateMemberEmail = async () => {
      setLoading(true);
      const payload = {
         rep_email: memberEmail,
         customer_id: accountId,
      };

      try {
         const response = await updatePersonalDetails(payload);
         toast.success(response.message);
         setMemberEmail('');
         setAccountId('');
      } catch (e) {
         toast.error(e.message);
      }
      setLoading(false);
   };

   const confirmAction = () =>
      confirm({
         confirmText: 'Are you sure you want to continue ?',
         onConfirm: updateMemberEmail,
      });

   return (
      <div className="row align-items-end">
         <InputWrapper className="col-lg-4">
            <Input
               placeholder="Enter Member Email"
               label="Member Email"
               value={memberEmail}
               onChange={(e) => setMemberEmail(e.target.value)}
            />
         </InputWrapper>
         <InputWrapper className="col-lg-4">
            <Input
               placeholder="Enter Account Holder ID"
               label="Account Holder ID"
               value={accountId}
               onChange={(e) => setAccountId(e.target.value)}
            />
         </InputWrapper>
         <InputWrapper className="col-lg-4">
            <Button
               variant="primary"
               onClick={confirmAction}
               isLoading={loading}
               disabled={!isValid}
            >
               Update
            </Button>
         </InputWrapper>
      </div>
   );
}
