import { useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessage } from '@hookform/error-message';
import { NumberInput } from 'components/input';
import { confirm } from 'components/confirm-box';
import { Button } from 'components/button';
import { updateSystemTransactionLimit, getBusinessTransactionLimits } from 'services/transactions';
import classNames from 'classnames';

export default function TransferLimits({ limits, bizDetails, canEdit }) {
   const [existingValues, setExistingValues] = useState({});

   const defaultValues = {
      daily_transaction_limit: 0,
      single_transaction_limit: 0,
   };

   const schema = yup.object().shape({
      daily_transaction_limit: yup.number().required(),
      single_transaction_limit: yup.number().required(),
   });

   const {
      register,
      handleSubmit,
      setValue,
      trigger,
      watch,
      formState: { errors },
   } = useForm({
      defaultValues,
      mode: 'onChange',
      resolver: yupResolver(schema),
      shouldFocusError: true,
   });

   const fetchBusinessTransactionLimits = async () => {
      const response = await getBusinessTransactionLimits({ business: bizDetails?.biz_account_id });
      setValue('daily_transaction_limit', response.daily);
      setValue('single_transaction_limit', response.single);
   };

   useEffect(() => {
      register('daily_transaction_limit');
      register('single_transaction_limit');
   }, []);

   useEffect(() => {
      if (bizDetails?.biz_account_id) {
         fetchBusinessTransactionLimits();
      }
   }, [bizDetails]);

   const settings = ['daily_transaction_limit', 'single_transaction_limit'];

   const setDefaultLimits = async () => {
      const values = limits?.value;
      settings.forEach((item) => setValue(item, values[item]));
      setExistingValues(values);
   };

   useEffect(() => {
      if (limits?.value) {
         setDefaultLimits();
      }
   }, [limits]);

   const sharedOnChange = useCallback(async ({ name, value }) => {
      setValue(name, value);
      await trigger(name);
   }, []);

   const renderErrorMessage = (name) => (
      <ErrorMessage
         errors={errors}
         name={name}
         render={({ message }) => <p className="input-error-message text-danger">{message}</p>}
      />
   );

   const onSubmit = async (value) => {
      const { data, name } = value;
      confirm({
         proceedBtnText: 'Proceed',
         cancelBtnText: 'Cancel',
         confirmText: ` By clicking confirm below you will be updating the ${name.replace(
            '_',
            ' ',
         )} limit for this account, this will override system settings. Only confirm if you are sure you want to take this action`,
         onConfirm: async () => {
            try {
               const payload = {
                  [name]: data[name],
                  business: bizDetails?.biz_account_id,
                  user: bizDetails?.account_holder_details?.account_holder_id,
               };
               const response = await updateSystemTransactionLimit(payload);
               confirm({
                  proceedBtnText: 'Close',
                  hide: { cancel: true },
                  confirmText: response?.message || `Action successful!`,
                  onConfirm: fetchBusinessTransactionLimits,
               });
            } catch (e) {
               confirm({
                  cancelBtnColor: '#0E55CF',
                  cancelBtnText: 'Close',
                  hide: { proceed: true },
                  confirmText: e?.message || `Action failed!`,
               });
            }
         },
      });
   };

   const daily_transaction_limit = watch('daily_transaction_limit');
   const single_transaction_limit = watch('single_transaction_limit');

   const limitsList = [
      {
         sectionTitle: 'Max single transfer',
         label: 'e.g account can make a single transfer of the amount set below [Maximum]',
         name: 'single_transaction_limit',
         value: single_transaction_limit,
      },
      {
         sectionTitle: 'Daily transfer limit',
         label: 'This is the cumulative amount a business account can send in one day',
         name: 'daily_transaction_limit',
         value: daily_transaction_limit,
      },
      {
         sectionTitle: 'International transfer limit',
         label: 'e.g account can make a single international transfer of the amount set below (Maximum)',
         // name: 'daily_transaction_limit',
         // value: daily_transaction_limit,
      },
   ];

   return (
      <div className="row pt-5 border-top">
         {limitsList.map(({ sectionTitle, label, name, value }, index) => (
            <form
               className={classNames('col-lg-4 mb-5', {
                  'pe-5': (index + 1) % 3 !== 0,
               })}
               onSubmit={handleSubmit((data) => onSubmit({ data, name }))}
            >
               <div className="mb-4">
                  <h3 className="mb-3">{sectionTitle}</h3>
                  <NumberInput
                     label={label}
                     className="wrap-label-input"
                     placeholder=""
                     name={name}
                     value={value}
                     disabled={!canEdit}
                     onChange={({ floatValue }) => sharedOnChange({ name, value: floatValue || 0 })}
                  />
                  {renderErrorMessage(name)}
               </div>
               {canEdit && (
                  <Button className="w-100" type="submit" disabled={existingValues[name] === value}>
                     Update
                  </Button>
               )}
            </form>
         ))}
      </div>
   );
}

TransferLimits.propTypes = {
   limits: PropTypes.objectOf().isRequired,
   bizDetails: PropTypes.objectOf().isRequired,
   canEdit: PropTypes.bool.isRequired,
};
