export const COUNTRIES = [
   {
      Currency: 'Euro',
      AlphabeticCode: 'EUR',
      NumericCode: 978,
      MinorUnit: 2,
      WithdrawalDate: '',
   },
   {
      Currency: 'Naira',
      AlphabeticCode: 'NGN',
      NumericCode: 566,
      MinorUnit: 2,
      WithdrawalDate: '',
      countryCode: 'NG',
   },
   {
      Currency: 'Pound Sterling',
      AlphabeticCode: 'GBP',
      NumericCode: 826,
      MinorUnit: 2,
      WithdrawalDate: '',
      countryCode: 'GB',
   },
   {
      Currency: 'US Dollar',
      AlphabeticCode: 'USD',
      NumericCode: 840,
      MinorUnit: 2,
      WithdrawalDate: '',
      countryCode: 'US',
   },
];
