import React, { useState } from 'react';
import PropTypes, { func, arrayOf, string } from 'prop-types';
import { Input } from 'components/input';
import Modal from 'components/modal';
import { Button } from 'components/button';
import { EmailListSection, EmailName } from './style';

const WhitelistedEmails = ({ open, onClose, handleClick, whitelistedEmails }) => {
   const [searchText, setSearchText] = useState('');

   const filteredList = whitelistedEmails.filter(
      (el) => el !== '' && el?.toLowerCase()?.includes(searchText?.toLowerCase()),
   );

   const handleRemove = (targetEmail) => {
      handleClick(whitelistedEmails.filter((email) => email !== targetEmail));
   };

   const handleRemoveAll = () => {
      handleClick([]);
   };

   return (
      <Modal
         open={open}
         onClose={onClose}
         center
         className="biz-details-modal"
         maxWidth="712px"
         closeOnOverlayClick={false}
      >
         <div className="d-flex justify-content-between w-100 align-items-center mb-4">
            <div className="d-flex">
               <h1>Whitelist</h1>
               <span className="mx-3 my-2 value" style={{ backgroundColor: '#F3F3F3' }}>
                  {filteredList?.length || 'N/A'}
               </span>
            </div>
         </div>
         <div className="d-flex justify-content-between align-items-center">
            <div>
               <Input
                  style={{ width: '350px' }}
                  placeholder="Search"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
               />
            </div>
            <div>
               <Button variant="danger" onClick={handleRemoveAll} disabled>
                  Delete All
               </Button>
            </div>
         </div>
         {filteredList?.map((email) => {
            return (
               <div className="mt-5">
                  <EmailListSection>
                     <EmailName>
                        <b>{email}</b>
                     </EmailName>
                     <div>
                        <Button variant="secondary" onClick={() => handleRemove(email)}>
                           Delete Email
                        </Button>
                     </div>
                  </EmailListSection>
               </div>
            );
         })}
      </Modal>
   );
};

export default WhitelistedEmails;

WhitelistedEmails.propTypes = {
   open: PropTypes.bool.isRequired,
   onClose: PropTypes.func.isRequired,
   handleClick: func.isRequired,
   whitelistedEmails: arrayOf(string).isRequired,
};
