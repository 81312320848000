import styled from 'styled-components';
import Chart from 'react-apexcharts';
import { shape } from 'prop-types';
import { numberWithCommas } from 'utils/others';

export const GraphWrapper = styled.div`
   min-height: 30rem;
`;

const Graph = ({ categories, data }) => {
   const options = {
      chart: {
         id: 'area',
         toolbar: {
            show: false,
         },
         brush: {
            enabled: true,
            target: 'area',
         },
      },

      xaxis: {
         categories,
      },
      yaxis: {
         labels: {
            show: true,
            style: {
               fontSize: '14px',
               fontWeight: 400,
            },
            formatter: (value) => `₦${numberWithCommas(value)}`,
         },
      },
      colors: ['#C0F2D4'],
      tooltip: {
         enabled: true,
         style: {
            fontSize: '16px',
         },
         x: {
            show: true,
            labels: {
               show: true,
               style: {
                  fontSize: '14px',
                  fontWeight: 400,
               },
               formatter: (value) => value,
            },
         },
         y: {
            formatter: (value) => {
               return `₦${numberWithCommas(value)}`;
            },
            title: {
               formatter: () => `Amount - `,
            },
         },
         marker: {
            show: true,
         },
      },
   };

   const series = [
      {
         name: 'series-1',
         data,
      },
   ];

   return (
      <GraphWrapper>
         <Chart
            options={options}
            categories={categories}
            series={series}
            type="area"
            height="100%"
         />
      </GraphWrapper>
   );
};

Graph.propTypes = {
   categories: shape([]).isRequired,
   data: shape([]).isRequired,
};

export default Graph;
// import { PureComponent } from 'react';
// import {
//    AreaChart,
//    Area,
//    XAxis,
//    YAxis,
//    CartesianGrid,
//    Tooltip,
//    ResponsiveContainer,
// } from 'recharts';

// const datas = [
//    {
//       name: 'JAN',
//       amount: 4000,
//    },
//    {
//       name: 'FEB',
//       amount: 9800,
//    },
//    {
//       name: 'MAR',
//       amount: 11000,
//    },
//    {
//       name: 'APR',
//       amount: 12500,
//    },
//    {
//       name: 'MAY',
//       amount: 13000,
//    },
//    {
//       name: 'JUN',
//       amount: 9800,
//    },
//    {
//       name: 'JUL',
//       amount: 9000,
//    },
//    {
//       name: 'AUG',
//       amount: 6000,
//    },
// ];
// export default class Example extends PureComponent {
//    static demoUrl = 'https://codesandbox.io/s/simple-area-chart-4ujxw';

//    formatYAxis = (tickItem) => {
//       // return `${tickItem / 1000}K`;
//       return `${tickItem}`;
//    };

//    render() {
//       // const { categories, data } = this.props;
//       // let thing = {};

//       // for (let y = 0; y < categories.length; y + 1) {
//       //    thing = {};
//       //    for (let i = 0; i < data.length; i + 1) {
//       //       thing[categories[i]] = data[y][i];
//       //    }
//       // }

//       // console.log(thing);
//       const { data, xAxis, yAxis } = this.props;
//       console.log(data);
//       return (
//          <ResponsiveContainer width="100%" height="100%">
//             <AreaChart data={data} margin={{ top: 27, right: 0, left: 0, bottom: 0 }}>
//                <defs>
//                   <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
//                      <stop offset="5%" stopColor="#FA4A84" stopOpacity={0.4} />
//                      <stop offset="95%" stopColor="#fff" stopOpacity={0} />
//                   </linearGradient>
//                </defs>

//                <XAxis
//                   dataKey={xAxis}
//                   axisLine={false}
//                   tickLine={false}
//                   padding={{ left: 20, right: 5 }}
//                   tickMargin={27}
//                   height={50}
//                />
//                <YAxis
//                   domain={[0, 'dataMax + 2000']}
//                   width={70}
//                   padding={{ top: 0, right: 0, left: 0, bottom: 0 }}
//                   axisLine={false}
//                   tickLine={false}
//                   tickFormatter={this.formatYAxis}
//                />
//                <CartesianGrid vertical={false} height={1} />
//                <Tooltip cursor={{ stroke: '#fa4a84', strokeWidth: 1 }} />
//                <Area
//                   type="monotone"
//                   dataKey={yAxis}
//                   stroke="#FA4A84"
//                   fillOpacity={1}
//                   fill="url(#colorUv)"
//                   dot
//                   activeDot={{ stroke: 'rgba(250,74,123,.43)', strokeWidth: 5, r: 4 }}
//                   animationDuration={1000}
//                />
//             </AreaChart>
//          </ResponsiveContainer>
//       );
//    }
// }
