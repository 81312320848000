import axiosCustom from 'utils/axios';

export const uploadCSVAutodialer = async (payload) => {
   try {
      const response = await axiosCustom().put(`/callcenter/contacts/`, payload);
      return response;
   } catch (e) {
      throw new Error(e.response?.data?.message || e.message);
   }
};
