import { getToken, getUserInfoFromStorage } from 'utils/storage';

function logout() {
   window.localStorage.clear();
}

function Http(endpoint, { method = 'GET', ...customConfig } = {}) {
   const token = getToken();
   const userData = getUserInfoFromStorage();
   const { permissions, pageAccess } = userData?.access || {};

   let url = '';
   const { action, ...remainingCustomConfig } = customConfig || {};

   if (
      action &&
      !permissions?.includes(action) &&
      !pageAccess?.includes(action) &&
      permissions !== 'all'
   ) {
      throw new Error(`You are not authorized to perform this action; ${action}`);
   }

   const headers = { 'Content-type': 'application/json' };

   if (token) {
      headers.Authorization = `Token ${token}`;
   }

   const config = {
      method,
      ...remainingCustomConfig,
      headers: {
         ...headers,
         ...remainingCustomConfig.headers,
      },
   };

   if (config.body) {
      config.body = JSON.stringify(config.body);
   }

   if (!process.env.REACT_APP_API_URL) {
      url = endpoint;
   } else {
      url = `${process.env.REACT_APP_API_URL}${endpoint}`;
   }

   return window.fetch(url, config).then(async (response) => {
      let message;
      const isJson = response.headers.get('content-type')?.includes('application/json');
      const isCSV = response.headers.get('content-type')?.includes('text/csv');
      let data = null;
      let responseIsArray = true;

      if (isJson) {
         data = await response.json();
         responseIsArray = Array.isArray(data);
      }

      if (isCSV) {
         data = await response.text();
      }

      if (response.ok) {
         return Promise.resolve({
            ok: response.ok,
            ...(responseIsArray ? { data } : { ...data }),
         });
      }

      switch (response.status) {
         case 400:
            message = 'Bad Request';
            break;
         case 401:
            logout();
            window.location.assign(window.location);
            message = "You're not Authenticated. Kindly Login";
            break;
         case 403:
            message = 'UnAuthorised User';
            break;
         case 404:
            message = 'Resource not found';
            break;
         case 500:
            message = 'Internal server error';
            break;
         default:
            message = '';
      }

      const errorRes = {
         ok: response.ok,
         custom_message: message,
         message,
         ...(action && {
            action_type: action,
         }),
         ...data,
      };

      await Promise.reject(errorRes);
   });
}
export { Http };
