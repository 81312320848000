/* eslint-disable react/forbid-prop-types */
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import Modal from 'components/modal';
import { AlertMessage } from 'components/message';
import { func, bool } from 'prop-types';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Input, NumberInput } from 'components/input';
import { SelectInput } from 'components/select';
import { toast } from 'react-hot-toast';
import { InputWrapper } from 'styles/form';
import { Button } from 'components/button';
import { addAdminUser, enable2FA } from 'services/admin';
import { emailFor2FA } from 'utils/variables';

export default function LoanOverview({ fetchAllManagers, onClose, open }) {
   const [loading, setLoading] = useState(false);
   const [step, setStep] = useState('add-admin');
   const [newAdmin, setNewadmin] = useState('');

   const schema = yup.object().shape({
      last_name: yup.string().required('Please enter a valid lastname'),
      first_name: yup.string().required('Please enter a valid firstname'),
      password1: yup.string().required('Please enter a valid password'),
      password2: yup
         .string()
         .required('Password must match')
         .oneOf([yup.ref('password1'), null], 'Passwords must match'),
      rep_email: yup
         .string()
         .email('Please enter a valid email address')
         .required('Please enter a valid email address'),
      rep_phone: yup.string().max(10).required('Please enter a valid phone number'),
      role_type: yup.string().required('Please select a valid role'),
   });

   const schemaOTP = yup.object().shape({
      otp: yup.string().required('Please enter a valid OTP'),
   });

   const adminRoles = [
      {
         value: 'prospacsr',
         label: 'Prospa CSR',
      },
      {
         value: 'prospabuildpartner',
         label: 'Prospa Build Partner',
      },
   ];

   const defaultValues = {
      password1: Math.random().toString(36),
   };
   const {
      register,
      setValue,
      watch,
      handleSubmit,
      reset,
      trigger,
      formState: { errors },
   } = useForm({
      defaultValues,
      mode: 'onChange',
      resolver: yupResolver(schema),
   });

   const {
      register: registerOTP,
      setValue: setValueOTP,
      watch: watchOTP,
      handleSubmit: handleSubmitOTP,
      formState: { errors: errorsOTP },
   } = useForm({
      defaultValues: {
         otp: '',
      },
      mode: 'onChange',
      resolver: yupResolver(schemaOTP),
   });

   const onSubmit = async (data) => {
      const { rep_phone: phone, ...rest } = data;
      const params = {
         ...rest,
         rep_phone: `+234${phone}`,
      };
      setLoading(true);
      try {
         const result = await addAdminUser(params);
         toast.success(result.message || 'Operation was successful');
         setNewadmin(result);
         setValue('rep_phone', '');
         reset();
         await enable2FA(
            {
               enable_step: 'first_step',
               action_sub_type: 'email',
               has_agreed: true,
               email_to_use: emailFor2FA,
            },
            result.token,
         );
         setStep('enter-otp');
      } catch (e) {
         toast.error(e.message || 'An error occured');
      }
      setLoading(false);
   };

   const handleEnable2FA = async (data) => {
      const { otp } = data;
      setLoading(true);
      try {
         const response = await enable2FA(
            {
               enable_step: 'second_step',
               enable_otp: otp,
            },
            newAdmin.token,
         );
         toast.success(response.message);
         setStep('add-admin');
         setValueOTP('otp', '');
         await fetchAllManagers();
      } catch (e) {
         toast.error(e.message);
      }
      setLoading(false);
   };
   const role_type = watch('role_type');
   const rep_phone = watch('rep_phone');
   const otp = watchOTP('watchOTP');
   return (
      <Modal open={open} onClose={onClose} closeOnOverlayClick={false} title="Add New Manager">
         {step === 'add-admin' ? (
            <div>
               {(errors?.terminal_name ||
                  errors?.terminal_number ||
                  errors?.serial_number ||
                  errors?.transaction_limit) && (
                  <AlertMessage message="Please fill out all fields" variant="error" />
               )}
               <form>
                  <InputWrapper className="grid-2-2">
                     <div>
                        <Input
                           placeholder="First Name"
                           label="First Name"
                           name="first_name"
                           type="text"
                           {...register('first_name')}
                        />
                        {errors.first_name && (
                           <p className="text-danger input-error-message">
                              {errors.first_name.message}
                           </p>
                        )}
                     </div>

                     <div>
                        <Input
                           placeholder="Last Name"
                           label="Last Name"
                           name="last_name"
                           type="text"
                           {...register('last_name')}
                        />
                        {errors.last_name && (
                           <p className="text-danger input-error-message">
                              {errors.last_name.message}
                           </p>
                        )}
                     </div>
                  </InputWrapper>

                  <InputWrapper className="grid-2-2">
                     <div>
                        <Input
                           placeholder="Email"
                           label="Email"
                           name="rep_email"
                           type="email"
                           {...register('rep_email', {
                              required: 'This is a required field',
                           })}
                        />
                        {errors.rep_email && (
                           <p className="text-danger input-error-message">
                              {errors.rep_email.message}
                           </p>
                        )}
                     </div>
                     <div>
                        <SelectInput
                           label="Role"
                           options={adminRoles}
                           value={{
                              value: role_type,
                              label: adminRoles.filter((item) => item.value === role_type)[0]
                                 ?.label,
                           }}
                           onChange={(val) => {
                              setValue('role_type', val.value);
                              trigger('role_type');
                           }}
                        />
                        {errors.role_type && (
                           <p className="text-danger input-error-message">
                              {errors.role_type?.message}
                           </p>
                        )}
                     </div>
                  </InputWrapper>

                  <InputWrapper className="grid-2-2">
                     <div>
                        <Input
                           placeholder="Password"
                           label="Password"
                           name="password1"
                           type="password"
                           endAdornment
                           {...register('password1', {
                              required: 'This is a required field',
                           })}
                        />
                        <p
                           className="generate-password"
                           onClick={() => setValue('password1', Math.random().toString(36))}
                        >
                           Generate Password
                        </p>
                        {errors.password1 && (
                           <p className="text-danger input-error-message">
                              {errors.password1?.message}
                           </p>
                        )}
                     </div>
                     <div>
                        <Input
                           placeholder="Confirm Password"
                           label="Confirm Password"
                           name="password2"
                           type="password"
                           endAdornment
                           {...register('password2', {
                              required: 'This is a required field',
                           })}
                        />
                        {errors.password2 && (
                           <p className="text-danger input-error-message">
                              {errors.password2?.message}
                           </p>
                        )}
                     </div>
                  </InputWrapper>

                  <NumberInput
                     label="Phone Number"
                     name="rep_phone"
                     prefix="+234"
                     className={Input}
                     format="+234 ### ### ####"
                     value={rep_phone}
                     onChange={(val) => {
                        setValue('rep_phone', val.value);
                        trigger('rep_phone');
                     }}
                  />
                  {errors.rep_phone && (
                     <p className="text-danger input-error-message">{errors.rep_phone?.message}</p>
                  )}
                  <div className="d-flex justify-content-end pt-5">
                     <Button
                        onClick={handleSubmit(onSubmit)}
                        isLoading={loading}
                        disabled={Object.entries(errors).length > 0 || loading}
                     >
                        Add New Manager
                     </Button>
                  </div>
               </form>
            </div>
         ) : (
            <div>
               <form>
                  <div className="mb-4 mt-4">
                     <Input
                        placeholder="OTP"
                        label="OTP"
                        name="otp"
                        type="password"
                        value={otp}
                        endAdornment
                        {...registerOTP('otp', {
                           required: 'This is a required field',
                        })}
                     />
                  </div>

                  {errorsOTP.otp && (
                     <p className="text-danger input-error-message">{errorsOTP.otp?.message}</p>
                  )}

                  <div className="d-flex justify-content-end">
                     <Button
                        onClick={handleSubmitOTP(handleEnable2FA)}
                        isLoading={loading}
                        disabled={Object.entries(errorsOTP).length > 0 || loading}
                     >
                        Enable 2FA
                     </Button>
                  </div>
               </form>
            </div>
         )}
      </Modal>
   );
}

LoanOverview.propTypes = {
   fetchAllManagers: func.isRequired,
   onClose: func.isRequired,
   open: bool.isRequired,
};
