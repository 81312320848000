export const manual_bvn_verification = 'manual_bvn_verification';
export const pin_reset = 'pin_reset';
export const refund = 'can_refund_transfers';
export const extract_utility_token = 'extract_utility_token';
export const change_business_details = 'change_business_details';
export const deactivate_2fa = 'deactivate_2fa';
export const reactivate_member_account = 'user_reactivation';
export const update_business_name = 'update_business_name';
export const update_business_email = 'update_business_email';
export const resend_otp = 'resend_otp';
export const manual_debit = 'manual_debit';
export const can_view_all_safe_transactions = 'can_view_all_safe_transactions';
export const can_transact_with_savings_account = 'can_transact_with_savings_account';
export const can_operate_settings = 'can_operate_settings';
export const update_owner_details = 'update_owner_details';
export const can_view_safe_stats = 'can_view_safe_stats';
export const can_view_safe_interest_earnings = 'can_view_safe_interest_earnings';
export const view_nibbs_inflow_graph = 'view_nibbs_inflow_graph';
export const can_approve_otp = 'can_approve_otp';
export const view_activated_transaction_zero = 'view_activated_transaction_zero';
export const lookup_bvn = 'lookup_bvn';
export const can_update_tenure = 'can_update_tenure';
export const can_update_loan_terms = 'can_update_loan_terms';
export const can_view_transaction_limit = 'can_view_transaction_limit';
export const can_set_transaction_limit = 'can_set_transaction_limit';
export const reassign_managers = 'reassign_managers';
export const can_upgrade_account_plan = 'can_upgrade_account_plan';
export const can_view_limit_boards = 'can_view_limit_boards';
export const can_read_marketing_data = 'can_read_marketing_data';
export const can_read_business_data = 'can_read_business_data';
export const can_read_promo_code = 'can_read_promo_code';
export const can_view_fixed_deposits = 'can_view_fixed_deposits';
export const can_view_fixed_deposits_terms = 'can_view_fixed_deposits_terms';
export const can_update_fixed_deposits = 'can_update_fixed_deposits';
export const can_update_global_payment_settings = 'can_update_global_payment_settings';
export const can_view_international_transactions = 'can_view_international_transactions';
export const can_view_exchange_rates = 'can_view_exchange_rates';
export const can_approve_card_request = 'can_approve_card_request';
export const can_create_card_benefits = 'can_create_card_benefits';
export const can_create_card_designs = 'can_create_card_designs';
export const can_create_card_permissions = 'can_create_card_permissions';
export const can_view_card_stats = 'can_view_card_stats';
export const can_update_card_business_transaction_limits =
   'can_update_card_business_transaction_limits';
export const can_export_all_card_requests = 'can_export_all_card_requests';
export const can_create_cards = 'can_create_cards';
export const can_view_card_transactions = 'can_view_card_transactions';
export const can_export_card_transactions = 'can_export_card_transactions';
export const can_access_cards_settings = 'can_access_cards_settings';
export const can_access_withdrawal = 'can_access_withdrawal';
export const can_access_transfer_charges_settings = 'can_access_transfer_charges_settings';
export const can_access_interest_withdrawal_penalty_settings =
   'can_access_interest_withdrawal_penalty_settings';
export const can_view_billing_records = 'can_view_billing_records';
export const can_upload_csv = 'can_upload_csv';
export const admin_can_view_exported_data = 'admin_can_view_exported_data';
export const can_view_queued_transfers = 'can_view_queued_transfers';
