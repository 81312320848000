/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Skeleton } from '@material-ui/lab';
import moment from 'moment';
import toast from 'react-hot-toast';
import FilterListIcon from '@material-ui/icons/FilterList';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import { useDebouncedCallback } from 'use-debounce';
import { numberWithCommas } from 'utils/others';
import { StatsCards } from 'components/stats-cards';
import { useAuth } from 'hooks/auth';
import { can_view_safe_stats } from 'utils/user_actions';
import {
   getActiveSavings,
   getTotalSafesCount,
   getTotalSafeBalance,
   getTotalSafeInterestEarned,
} from 'services/savings';
import { TooltipComponent } from 'components/tooltip';
import { Input } from 'components/input';
import { TableComponent } from 'components/table';
import { TableContainer, TableContent } from 'styles/table';
import BusinessDetails from 'containers/business-details';
import { SavingsFilterModal } from '../filter-modal';
import { FilterContainer } from '../../style';
import SavingsExportDataModal from '../export-data';

export default function SavingTable() {
   const { currentUser } = useAuth();
   const { permissions } = currentUser?.access || {};
   const [modalVisibility, setModalVisibility] = useState(false);
   const [isLoading, setIsLoading] = useState(false);
   const [tableData, setTableData] = useState([]);
   const [paginationData, setPaginationData] = useState({});
   const [currentPage, setCurrentPage] = useState(1);
   const [searchTerm, setSearchTerm] = useState('');
   const [bizAccount, setBizAccount] = useState('');
   const [showExportModal, setShowExportModal] = useState(false);
   const [modalVisibilityFilter, setModalVisibilityFilter] = useState(false);
   const [stats, setStats] = useState({});
   const [filterData, setfilterData] = useState({
      start_date: moment().subtract('3', 'months').format('YYYY-MM-DD'),
      end_date: moment().format('YYYY-MM-DD'),
   });
   const [filterPayload, setFilterPayload] = useState(filterData);

   useEffect(() => {
      (async () => {
         setIsLoading(true);
         const payload = {
            ...(searchTerm !== '' && { business_name: searchTerm }),
            page: currentPage,
            ...filterPayload,
         };
         setTableData([]);
         try {
            Promise.all([
               getActiveSavings(payload),
               getTotalSafesCount(payload),
               getTotalSafeBalance(payload),
               getTotalSafeInterestEarned(payload),
            ]).then(([savings, safeCount, safeBalance, safeInterest]) => {
               setPaginationData(savings);
               setTableData(savings.results);

               const { active_safes, inactive_safes } = safeCount;
               const { total_safe_balance } = safeBalance;
               const { total_interest } = safeInterest;

               setStats({
                  active_safes: numberWithCommas(active_safes),
                  inactive_safes: numberWithCommas(inactive_safes),
                  total_safe_balance: `₦${numberWithCommas(total_safe_balance)}`,
                  total_interest: `₦${numberWithCommas(total_interest)}`,
               });
            });
         } catch (e) {
            toast.error(e.message);
         }
         setIsLoading(false);
      })();
   }, [currentPage, searchTerm, filterPayload]);

   function onPageChange(e, pageNumber) {
      setCurrentPage(pageNumber);
   }

   function handleSearch({ target }) {
      setSearchTerm(target.value);
   }

   function onRowClick({ row }) {
      setBizAccount(row);
   }

   const debounce = useDebouncedCallback(handleSearch, 600);

   function handleOpenModal() {
      // setBizAccount(id);
      setModalVisibility('details');
   }

   const columns = [
      {
         field: 'business_name',
         headerName: 'Business Name',
         renderCell: ({ row }) => (
            <TooltipComponent title={row.business_name}>
               <span
                  style={{
                     cursor: 'pointer',
                  }}
                  className="text-primary"
                  onClick={() => handleOpenModal(row.business_name)}
               >
                  {row.business_name || 'N/A'}
               </span>
            </TooltipComponent>
         ),
      },
      {
         field: 'total_balance',
         headerName: 'Total savings balance',
         renderCell: ({ row }) => (
            <span style={{ textTransform: 'capitalize' }}>{`₦ ${numberWithCommas(
               row.total_balance,
            )}`}</span>
         ),
      },
      {
         field: 'interest_earned',
         headerName: 'Total interest earned',
         renderCell: ({ row }) => (
            <span style={{ textTransform: 'capitalize' }}>{`₦ ${numberWithCommas(
               row.interest_earned,
            )}`}</span>
         ),
      },
      {
         field: 'interest_rate',
         headerName: 'Interest rate',
         renderCell: ({ row }) => (
            <span style={{ textTransform: 'capitalize' }}>{`${row.interest_rate}%`}</span>
         ),
      },
   ];
   return (
      <div>
         {(permissions === 'all' || permissions?.includes(can_view_safe_stats)) && (
            <StatsCards data={stats} isLoading={isLoading} />
         )}
         <TableContainer>
            <TableContent>
               {isLoading ? (
                  <Skeleton width="30%" height="20" />
               ) : (
                  <div>
                     <h1> Flexi Accounts</h1>
                     <span className="value">{paginationData?.count || 'N/A'}</span>
                  </div>
               )}
               <div className="d-flex justify-content-between">
                  <div>
                     <Input style={{ width: '300px' }} placeholder="Search" onChange={debounce} />
                  </div>
                  <div>
                     <FilterContainer className="me-3" onClick={() => setShowExportModal(true)}>
                        Export Data
                        <ImportExportIcon style={{ marginLeft: '10px' }} />
                     </FilterContainer>
                  </div>
                  <FilterContainer onClick={() => setModalVisibilityFilter(true)}>
                     Filter By <FilterListIcon style={{ marginLeft: '10px' }} />
                  </FilterContainer>
               </div>
            </TableContent>
            <TableComponent
               columns={columns}
               rows={tableData}
               isLoading={isLoading}
               onPageChange={onPageChange}
               count={Math.ceil(paginationData.count / 15)}
               onRowClick={onRowClick}
            />
         </TableContainer>

         <SavingsFilterModal
            open={modalVisibilityFilter}
            onClose={() => setModalVisibilityFilter(false)}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            setfilterData={setfilterData}
            filterData={filterData}
            setFilterPayload={setFilterPayload}
         />

         {modalVisibility && (
            <BusinessDetails
               businessAccountId={bizAccount.business_id}
               open={modalVisibility}
               onClose={() => setModalVisibility(false)}
            />
         )}
         {showExportModal && (
            <SavingsExportDataModal
               open={showExportModal}
               onClose={() => setShowExportModal(false)}
            />
         )}
      </div>
   );
}
