import styled from 'styled-components/macro';

export const EmailListSection = styled.div`
   padding-bottom: 0.5rem;
   display: flex;
   justify-content: space-between;
   align-items: center;
   width: 100%;
   border-bottom: 1px solid #bac2c9;
`;
export const EmailName = styled.p`
   color: ${({ theme }) => theme.colors?.black01};
   font-size: 18px;
   margin-left: 10px;
   margin-top: 10px;
`;
