/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import moment from 'moment';
import { number } from 'prop-types';
import { getBusinessAccountTransactions } from 'services/businessDetails';
import { Spinner } from 'components/spinner';
import { TableComponent } from 'components/table';
import { TableContainer, TableContent } from 'styles/table';
import { numberWithCommas } from 'utils/others';
import { formatTag } from 'utils/tags';
import { TooltipComponent } from 'components/tooltip';
import TransactionModal from 'components/TransactionModal';
import { AlertMessage } from 'components/message';
import { renderTransactionStatus } from 'utils/business';
// import { Input } from 'components/input';
import { OverviewWrapper } from '../utils/overview.style';

const Overview = ({ businessAccountId }) => {
   const [businessTransactions, setBusinessTransactions] = useState([]);
   const [paginationData, setPaginationData] = useState({});
   const [currentPage, setCurrentPage] = useState(1);
   const [loading, setLoading] = useState(true);
   const [error, setError] = useState('');
   const [transactionDetail, setTransactionDetail] = useState('');
   const [transactionModal, setTransactionModal] = useState(false);

   const fetchBusinessDetails = async () => {
      try {
         const result = await getBusinessAccountTransactions(
            { page: currentPage },
            businessAccountId,
         );
         if (result.ok) {
            setBusinessTransactions(result.data);
            setPaginationData(result);
            setError('');
         }
         setLoading(false);
      } catch (err) {
         setError(err.message || err.custom_message);
         setLoading(false);
      }
   };

   useEffect(() => {
      fetchBusinessDetails();
   }, [currentPage]);

   function onPageChange(e, pageNumber) {
      setCurrentPage(pageNumber);
   }

   const onTransactionClick = (data) => {
      setTransactionModal(true);
      setTransactionDetail(data);
   };

   const columns = [
      {
         field: 'tx_ref',
         headerName: 'Transaction ref',
         renderCell: ({ row }) => (
            <TooltipComponent title={row.tx_ref}>
               <span
                  style={{ cursor: 'pointer' }}
                  className="text-primary"
                  onClick={() => onTransactionClick(row)}
               >
                  {row.tx_ref}
               </span>
            </TooltipComponent>
         ),
      },
      {
         field: 'transaction_id',
         headerName: 'Transaction ID',
         renderCell: ({ row }) => (
            <TooltipComponent title={row.transaction_id}>{row.transaction_id}</TooltipComponent>
         ),
      },
      {
         field: 'transfer_details.recipient_account_name',
         headerName: 'Recipient',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.transfer_details?.recipient_account_name || row.tx_note}>
               {row?.transfer_details?.recipient_account_name || row.tx_note}
            </TooltipComponent>
         ),
      },
      {
         field: 'trans_amount',
         headerName: 'Amount',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.trans_amount}>
               ₦{numberWithCommas(row?.trans_amount)}
            </TooltipComponent>
         ),
      },
      {
         field: 'balance_after',
         headerName: 'Balance After',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.balance_after}>
               ₦{numberWithCommas(row?.balance_after)}
            </TooltipComponent>
         ),
      },
      {
         field: 'transfer_details.transfer_status',
         headerName: 'Status',
         renderCell: ({ row }) => renderTransactionStatus(row),
      },
      {
         field: 'tx_action',
         headerName: 'Type',
         renderCell: ({ row }) => {
            let template = '';
            if (row.tx_action.includes('credit')) {
               template = formatTag('accepted', 'Credit');
            } else if (row.tx_action.includes('debit')) {
               template = formatTag('', 'Debit');
            } else {
               template = formatTag(row.tx_action, row.tx_action);
            }
            return <TooltipComponent title={row.tx_action}>{template}</TooltipComponent>;
         },
      },
      {
         field: 'source_account_name',
         headerName: 'Initiated By',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.source_account_name}>
               {row?.source_account_name}
            </TooltipComponent>
         ),
      },
      {
         field: 'pub_date',
         headerName: 'Date Created',
         renderCell: ({ row }) => (
            <TooltipComponent title={row.pub_date}>
               {moment(row.pub_date).format('DD MMM, YYYY')} |{' '}
               <span style={{ opacity: '.5' }}>{moment(row.pub_date).format('HH:mm A')}</span>
            </TooltipComponent>
         ),
      },
   ];

   return (
      <>
         <OverviewWrapper>
            <AlertMessage message={error} variant="error" />

            {loading ? (
               <Spinner />
            ) : (
               <TableContainer>
                  <TableContent>
                     <div>
                        <h1> All Transactions</h1>
                        <span className="value">{paginationData?.count || 'N/A'}</span>
                     </div>
                  </TableContent>
                  <TableComponent
                     preventUrl
                     columns={columns}
                     rows={businessTransactions}
                     isLoading={loading}
                     onPageChange={onPageChange}
                     page={currentPage}
                     count={Math.ceil(paginationData.count / 15)}
                  />
               </TableContainer>
            )}
         </OverviewWrapper>
         <TransactionModal
            open={transactionModal}
            onClose={() => setTransactionModal(false)}
            data={transactionDetail}
         />
      </>
   );
};

Overview.propTypes = {
   businessAccountId: number.isRequired,
};

export default Overview;
