import React, { useState, useEffect } from 'react';
import { func, objectOf, string, any } from 'prop-types';
import useTicketCategory from 'hooks/fetchTicketCategory';
import MultiSelectDropdown from 'components/multi-select-dropdown';
import { CategoryFilterWrapper } from '../../ticketing.style';

export default function CategoryFilter({ onSubmit, filterPayload, trigger, placeholder, label }) {
   const { categories } = useTicketCategory();
   const [selectedCategories, setSelectedCategories] = useState([]);

   useEffect(() => {
      if (filterPayload?.category && categories?.length > 0) {
         const parsed = filterPayload?.category?.split(',');

         const existingCategories = parsed?.map((item) => {
            return categories?.find((cat) => cat.id === parseInt(item, 10));
         });
         setSelectedCategories(
            existingCategories?.map(({ category, id }) => ({
               label: category,
               value: id,
            })),
         );
      }
   }, [categories]);

   const onHandleSubmit = (value) => {
      let payload = '';
      const hasAll = value?.find((item) => item.value === null);
      const length = hasAll ? value.length - 1 : value.length;

      value
         ?.filter((item) => item.value !== null)
         .forEach((item, index) => {
            if (item.value) {
               payload += index + 1 === length ? `${item.value}` : `${item.value},`;
            }
         });
      onSubmit(payload);
      setSelectedCategories(value);
   };

   const options = categories?.map(({ category, id }) => ({
      label: category,
      value: id,
   }));

   return (
      <CategoryFilterWrapper>
         <MultiSelectDropdown
            trigger={trigger}
            value={selectedCategories}
            options={options}
            onChange={onHandleSubmit}
            placeholder={placeholder}
            label={label}
         />
      </CategoryFilterWrapper>
   );
}

CategoryFilter.propTypes = {
   onSubmit: func.isRequired,
   filterPayload: objectOf(string).isRequired,
   // eslint-disable-next-line react/forbid-prop-types
   trigger: any,
   placeholder: string,
   label: string,
};

CategoryFilter.defaultProps = {
   trigger: null,
   placeholder: '',
   label: '',
};
