import Styled from 'styled-components';

export const Wrapper = Styled.div`
    .file-preview-frame{
        background:#FDF2E7;
        padding:12px 16px;
        border-radius:6px;
        border:1px solid #F08829;
    }
    .file-preview-frame h6{
        font-weight: normal;
        font-size: 10px;
        line-height: 16px;
        color: #0D0F11;
    }
    .file-preview-frame h4{
        font-weight: normal;
        font-size: 15px;
        line-height: 24px;
        width:75%;
        color: #0D0F11;
        text-overflow: ellipsis;
        overflow-x: hidden;
    }
    .file-preview-frame #view-pending-document{
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        text-decoration-line: underline;
        color: #1354D3;
        cursor:pointer;
    }
    .file-preview-frame .file-bg{
        background:white;
        border-radius:50%;
        width:42px;
        height:42px;
        margin-right:5px;
    }
    .file-preview-frame .file-bg img{
        width:25px;
        height:25px;
    }
    .file-preview-upload-input{
        background: #dee2e6;
        border-radius: 6px;
        /* border-bottom: 2px solid #F08829; */
        height:60px;
        width: calc(100% - 42px);
        cursor: pointer;
    }
    .view-btn{
        background-color: #ffc107;
        border-color: #ffc107;
        color:#333;
        font-size:14px;
        border-radius:4px;
    }
`;
