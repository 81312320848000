export const CARD_PRIORITY = [
   {
      value: null,
      label: 'All',
   },
   {
      value: 'express',
      label: 'Express',
   },
   {
      value: 'standard',
      label: 'Standard',
   },
   {
      value: 'priority',
      label: 'Priority',
   },
];

export const CARD_TYPE = [
   {
      value: 'black',
      label: 'Black',
   },
   {
      value: 'pink',
      label: 'Pink',
   },
   {
      value: 'purple',
      label: 'Purple',
   },
];

export const CARD_STATUS = [
   {
      value: 'active',
      label: 'Active',
   },
   {
      value: 'inactive',
      label: 'Inactive',
   },
   {
      value: 'deactivated',
      label: 'Deactivated',
   },
];

export const CARD_GEN_STATUS = [
   {
      value: null,
      label: 'All',
   },
   {
      value: 'Validated',
      label: 'Validated',
   },
   {
      value: 'Approved',
      label: 'Approved',
   },
   {
      value: 'Declined',
      label: 'Declined',
   },
   {
      value: 'FullyReceived',
      label: 'Fully Received',
   },
   {
      value: 'PartiallyReceived',
      label: 'Partially Received',
   },
   {
      value: 'Generated',
      label: 'Generated',
   },
   {
      value: 'Failed',
      label: 'Failed',
   },
];

export const CARD_TRANSACTION_TYPE = [
   {
      value: null,
      label: 'All',
   },
   {
      value: 'atm',
      label: 'ATM',
   },
   {
      value: 'pos',
      label: 'POS',
   },
   {
      value: 'refund',
      label: 'Refund',
   },
];
export const CARD_TRANSACTION_STATUS = [
   {
      value: null,
      label: 'All',
   },
   {
      value: 'completed',
      label: 'Completed',
   },
   {
      value: 'reverted',
      label: 'Reverted',
   },
   {
      value: 'failed',
      label: 'Failed',
   },
];

export const CARD_DELIVERY_STATUS = [
   {
      value: 'processing',
      label: 'Pending',
   },
   {
      value: 'delivered',
      label: 'Delivered',
   },
];
