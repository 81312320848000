import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Button } from 'components/button';
import { confirm } from 'components/confirm-box';
import { Spinner } from 'components/spinner';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import {
   createBusinessFees,
   getBusinessFees,
   updateBusinessFees,
} from 'services/international-transactions';
import { UpdateBusinessFeesModal } from './create-fees-modal';

export const BusinessInboundPaymentFees = ({ businessAccountId }) => {
   const [loading, setLoading] = useState(false);
   const [feesData, setFeesData] = useState([]);
   const [selectedCurrency, setSelectedCurrency] = useState('');
   const [updateFeesModalVisibility, setUpdateFeesModalVisibility] = useState(false);
   const [createFeesModalVisibility, setCreateFeesModalVisibility] = useState(false);

   const fetchBusinessFee = async () => {
      setLoading(true);
      try {
         const res = await getBusinessFees({ hbizaccount_id: businessAccountId });
         setFeesData(res?.results);
         setLoading(false);
      } catch (e) {
         toast.error(e?.message || 'Something went wrong');
         setLoading(false);
      }
   };

   useEffect(() => {
      fetchBusinessFee();
   }, []);

   const handleUpdate = (data) => {
      setSelectedCurrency(data);
      setUpdateFeesModalVisibility(true);
   };

   const handleFeesUpdate = (data) => {
      const { id, ...rest } = data;
      confirm({
         confirmText: 'Are you sure want to update the fees?',
         onConfirm: async () => {
            setLoading(true);
            try {
               const res = await updateBusinessFees(id, rest);
               toast.success(res?.message || 'Data Successfully updated');
               setLoading(false);
               fetchBusinessFee();
            } catch (e) {
               toast.error(e?.message || 'Something went wrong');
               setLoading(false);
            }
         },
      });
   };

   const handleCreateFees = (data) => {
      confirm({
         confirmText: 'Are you sure want to create the fees?',
         onConfirm: async () => {
            setLoading(true);
            const payload = {
               hbizaccount_id: businessAccountId,
               ...data,
            };
            try {
               const res = await createBusinessFees(payload);
               toast.success(res?.message || 'Action Successful');
               setLoading(false);
               fetchBusinessFee();
            } catch (e) {
               toast.error(e?.message);
               setLoading(false);
            }
         },
      });
   };

   return (
      <div className="mt-5 pt-5 mb-5 border-top">
         <div>
            {loading ? (
               <Spinner />
            ) : (
               <div>
                  <h3 className="mb-3 pb-4">Inbound Transactions Fees</h3>
                  <div className="border-top row pt-5">
                     {feesData?.map((item, index) => (
                        <div
                           className={classNames('col-lg-4 mb-5', {
                              'pe-5': (index + 1) % 3 !== 0,
                           })}
                        >
                           <div className="border p-3 mb-4">
                              <h3 className="mb-3">{item?.fee_currency}</h3>
                              <div className="d-flex gap-2 align-items-center mb-2">
                                 <h4>Flat Fee:</h4>
                                 {parseFloat(item?.flat_fee)?.toFixed(2)}
                              </div>
                              <div className="d-flex gap-2 align-items-center mb-4">
                                 <h4>Percentage Fee:</h4>
                                 {parseFloat(item?.percentage_fee)?.toFixed(2)}%
                              </div>
                              <Button className="w-100" onClick={() => handleUpdate(item)}>
                                 Update
                              </Button>
                           </div>
                        </div>
                     ))}
                  </div>
                  <Button onClick={() => setCreateFeesModalVisibility(true)}>Create Fees</Button>

                  {createFeesModalVisibility && (
                     <UpdateBusinessFeesModal
                        open={createFeesModalVisibility}
                        onClose={() => setCreateFeesModalVisibility(false)}
                        feesData={feesData}
                        loading={loading}
                        selectedData={selectedCurrency}
                        onSubmit={handleCreateFees}
                     />
                  )}
                  {updateFeesModalVisibility && (
                     <UpdateBusinessFeesModal
                        isEdit
                        open={updateFeesModalVisibility}
                        onClose={() => setUpdateFeesModalVisibility(false)}
                        feesData={feesData}
                        loading={loading}
                        selectedData={selectedCurrency}
                        onSubmit={handleFeesUpdate}
                     />
                  )}
               </div>
            )}
         </div>
      </div>
   );
};

BusinessInboundPaymentFees.propTypes = {
   businessAccountId: PropTypes.string.isRequired,
};
