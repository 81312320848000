import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Picker from 'emoji-picker-react';
import toast from 'react-hot-toast';
import moment from 'moment';
import clsx from 'classnames';
import Avatar from 'assets/images/user.svg';
import { useAuth } from 'hooks/auth';
import ProspaProfile from 'assets/images/prospa-profile.svg';
import FileIcon from 'assets/images/file.svg';
import PaperClipIcon from 'assets/images/paperclip.svg';
import SmileIcon from 'assets/images/smile.svg';
import { replyTicketMessage } from 'services/ticketing';
import DocumentPreviewer from 'components/document-previewer';
import { hasValue } from 'utils/validations';
import { TicketMessagingWrapper } from '../../ticketing.style';

export default function TicketMessage({
   ticketId,
   bizDetails,
   messages,
   fetchTicketMessages,
   ticketDetails,
}) {
   const [emojiActive, setEmojiActive] = useState(false);
   const [message, setMessage] = useState('');
   const [sendingMessage, setSendingMessage] = useState(false);
   const inputRef = useRef(null);
   const [smooth, setSmooth] = useState(false);
   const [file, setFile] = useState(null);
   const messagesEndRef = useRef(null);
   const { currentUser } = useAuth();

   const onEmojiClick = (event, { emoji }) => {
      const ref = inputRef.current;
      ref.focus();
      const start = message.substring(0, ref.selectionStart);
      const end = message.substring(ref.selectionStart);
      const text = start + emoji + end;
      setMessage(text);
   };

   const linkRegex = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:\d{1,5})?(\/[^\s]*)?$/;

   const replyMessage = async (event, auto) => {
      if (event?.key === 'Enter' || auto) {
         if (hasValue(message) || file) {
            setSmooth(true);
            const formData = new FormData();
            formData.append(`ticket`, ticketId);
            if (file) {
               formData.append(`issue_file_one`, file[0]);
            }

            formData.append(`message`, message);
            setSendingMessage(true);
            try {
               await replyTicketMessage(formData);
               fetchTicketMessages();
               setMessage('');
            } catch (e) {
               toast.error(e?.message);
            }

            setMessage('');
            setFile(null);
            setSendingMessage(false);
         }
      }
   };

   const scrollToBottom = () => {
      messagesEndRef.current?.scrollIntoView({
         ...(smooth && { behavior: 'smooth' }),
      });
   };

   useEffect(() => {
      scrollToBottom();
   }, [messages, sendingMessage]);

   useEffect(() => {
      replyMessage({}, true);
   }, [file]);

   const { assigned_to, issue_status } = ticketDetails || {};

   const isMessageDisabled =
      assigned_to !== currentUser?.prospa_user_id || issue_status === 'closed';

   const { account_holder_id, identity_file } = bizDetails?.account_holder_details || {};
   return (
      <TicketMessagingWrapper>
         <div className="message-list">
            {messages?.map((item) => {
               const membersMessage = account_holder_id === item.created_by;
               const fileName = item?.issue_file_one ? item.issue_file_one.split('/') : [];

               return (
                  <div
                     className={clsx({
                        'd-flex justify-content-end': !membersMessage,
                     })}
                  >
                     <div
                        className={clsx({
                           'prospa-message': !membersMessage,
                        })}
                        key={item.id}
                     >
                        <div
                           className={clsx('message-profile', {
                              'prospa-profile': !membersMessage,
                           })}
                        >
                           <img
                              src={membersMessage ? identity_file || Avatar : ProspaProfile}
                              className={clsx('profile-img', {
                                 'me-3': membersMessage,
                                 'ms-3': !membersMessage,
                              })}
                              alt="user"
                           />
                           <p className="message-name">{item.created_by_name}</p>
                        </div>

                        <div className="message-container">
                           <p
                              className={clsx('message-content', {
                                 'prospa-message-content': !membersMessage,
                              })}
                           >
                              {item.message && (
                                 <div className={clsx({ 'mb-4': item?.issue_file_one })}>
                                    {linkRegex.test(item.message) ? (
                                       <a href={item.message} target="_blank" rel="noreferrer">
                                          {item.message}
                                       </a>
                                    ) : (
                                       <p>{item.message}</p>
                                    )}
                                 </div>
                              )}

                              {item?.issue_file_one && (
                                 <DocumentPreviewer url={item.issue_file_one} title="Message File">
                                    <div className="d-flex align-items-center message-file">
                                       <img src={FileIcon} alt="file" className="me-3" />
                                       <p className="mb-0">
                                          {fileName[fileName.length - 1]?.slice(0, 20)}
                                       </p>
                                    </div>
                                 </DocumentPreviewer>
                              )}
                           </p>

                           <p
                              className={clsx('time-created d-flex', {
                                 'justify-content-end': !membersMessage,
                                 'ps-3': membersMessage,
                                 'pe-3': !membersMessage,
                              })}
                              title={moment(item.created).format('MMM DD, YYYY | h:mm A')}
                           >
                              {moment(item.created).format('h:mm A')}
                           </p>
                        </div>
                     </div>
                  </div>
               );
            })}
            {sendingMessage && (
               <div className="d-flex justify-content-end">
                  <div className="prospa-message">
                     <div className="prospa-profile message-profile">
                        <img src={ProspaProfile} className="profile-img ms-3" alt="user" />
                        <p className="message-name">{`${currentUser.first_name} ${currentUser.last_name}`}</p>
                     </div>

                     <div className="message-container">
                        <p className="message-content">
                           {message && (
                              <p
                                 className={clsx({
                                    'mb-4': file,
                                 })}
                              >
                                 {message}
                              </p>
                           )}
                           {file && (
                              <div className="d-flex align-items-center message-file">
                                 <img src={FileIcon} alt="file" className="me-3" />
                                 <p className="mb-0">File</p>
                              </div>
                           )}
                        </p>

                        <p className="time-created ps-3">
                           <i>Sending...</i>
                        </p>
                     </div>
                  </div>
               </div>
            )}
            <div ref={messagesEndRef} />
         </div>

         <div className="compose-message">
            {emojiActive && (
               <div className="emoji-picker">
                  <Picker onEmojiClick={onEmojiClick} disableAutoFocus native />
               </div>
            )}

            {!isMessageDisabled && (
               <div className="message-input">
                  <img
                     src={SmileIcon}
                     className="smile-icon"
                     alt="emoji"
                     onClick={() => setEmojiActive(!emojiActive)}
                  />
                  <input
                     type="text"
                     allow=".png, .jpeg, .jpg, .pdf"
                     className="message-text-input"
                     value={message}
                     onChange={({ target: { value } }) => setMessage(value)}
                     ref={inputRef}
                     onKeyDown={replyMessage}
                     disabled={isMessageDisabled}
                  />
                  {message.length < 1 && (
                     <div className="message-file-container">
                        <label htmlFor="message-file">
                           <img src={PaperClipIcon} className="file-icon" alt="file" />{' '}
                        </label>
                        <input
                           type="file"
                           className="message-file-input"
                           id="message-file"
                           onChange={(e) => setFile(e.target.files)}
                        />
                     </div>
                  )}
               </div>
            )}
         </div>
      </TicketMessagingWrapper>
   );
}

TicketMessage.propTypes = {
   ticketId: PropTypes.number.isRequired,
   bizDetails: PropTypes.objectOf(PropTypes.string).isRequired,
   messages: PropTypes.arrayOf(PropTypes.objectOf()).isRequired,
   fetchTicketMessages: PropTypes.func.isRequired,
   ticketDetails: PropTypes.objectOf(PropTypes.string).isRequired,
};
