import { useEffect, useCallback } from 'react';
import { objectOf } from 'prop-types';
import classNames from 'classnames';
import { confirm } from 'components/confirm-box';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessage } from '@hookform/error-message';
import { NumberInput } from 'components/input';
import { Button } from 'components/button';
import useTransactionsSettings from 'hooks/transactionsSettings';
import { updateTransactionSettings } from 'services/transactions';
import toast from 'react-hot-toast';

export const OtpSettings = ({ allSettings }) => {
   const { loading, fetchSetting } = useTransactionsSettings();

   const defaultValues = {
      period: 0,
      max_limit: 0,
      expiry_hours: 0,
   };

   const schema = yup.object().shape({
      period: yup.number().positive().required(),
      max_limit: yup.number().positive().required(),
      expiry_hours: yup.number().positive().required(),
   });

   const {
      register,
      setValue,
      trigger,
      watch,
      formState: { isValid, errors },
   } = useForm({
      defaultValues,
      mode: 'onChange',
      resolver: yupResolver(schema),
      shouldFocusError: true,
   });

   useEffect(() => {
      register('period');
      register('max_limit');
      register('expiry_hours');
   }, []);

   const settings = ['max_limit', 'period', 'expiry_hours'];

   const setDefaultValues = async () => {
      const values = allSettings?.OTHER__OTP_SETTINGS?.value;
      settings.forEach((item) => setValue(item, values[item]));
   };

   useEffect(() => {
      if (allSettings?.OTHER__OTP_SETTINGS?.value) {
         setDefaultValues();
      }
   }, [allSettings]);

   const sharedOnChange = useCallback(async ({ name, value }) => {
      setValue(name, value);
      await trigger(name);
   }, []);

   const renderErrorMessage = (name) => (
      <ErrorMessage
         errors={errors}
         name={name}
         render={({ message }) => <p className="input-error-message text-danger">{message}</p>}
      />
   );

   const period = watch('period');
   const max_limit = watch('max_limit');
   const expiry_hours = watch('expiry_hours');

   const otpSettingsList = [
      {
         label: 'Period',
         name: 'period',
         value: period,
      },
      {
         label: 'Maximum Limit',
         name: 'max_limit',
         value: max_limit,
      },
      {
         label: 'Expiry Hours',
         name: 'expiry_hours',
         value: expiry_hours,
      },
   ];

   const onSubmit = async () => {
      confirm({
         confirmText: 'Are you sure you want to update this setting?',
         onConfirm: async () => {
            try {
               const response = await updateTransactionSettings(
                  allSettings?.OTHER__OTP_SETTINGS?.id,
                  {
                     value: {
                        period,
                        max_limit,
                        expiry_hours,
                     },
                     name: 'OTHER__OTP_SETTINGS',
                  },
               );
               toast.success(response.message || 'Settings has been updated successfully');
               fetchSetting('OTHER__OTP_SETTINGS');
            } catch (e) {
               toast.error(e?.message);
            }
         },
      });
   };

   return (
      <div>
         <h3 className="mt-4">Otp Settings</h3>
         <div className=" mt-5">
            {otpSettingsList.map(({ label, name, value }) => (
               <form className={classNames('col-lg-4 mb-5')}>
                  <div className="mb-4">
                     <NumberInput
                        label={label}
                        className="wrap-label-input"
                        placeholder=""
                        prefix=" "
                        name={name}
                        value={value}
                        onChange={({ floatValue }) =>
                           sharedOnChange({ name, value: floatValue || 0 })
                        }
                     />
                     {renderErrorMessage(name)}
                  </div>
               </form>
            ))}
            <Button className="col-lg-4" onClick={onSubmit} disabled={!isValid} isLoading={loading}>
               Update Settings
            </Button>
         </div>
      </div>
   );
};

OtpSettings.propTypes = {
   allSettings: objectOf().isRequired,
};
