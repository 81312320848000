import { useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-hot-toast';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessage } from '@hookform/error-message';
import { NumberInput } from 'components/input';
import { confirm } from 'components/confirm-box';
import { changeBillingPlan, getBillingCycles } from 'services/new-billing';
import { numberWithCommas } from 'utils/others';
import { capitilizeText } from 'utils/string';
import Modal from 'components/modal';
import { SelectInput } from 'components/select';
import { InputWrapper } from 'styles/form';
import { Button } from 'components/button';

export default function ChangePlanNewBilling({ open, onClose, bizDetails, plans }) {
   const [cycles, setCycles] = useState([[]]);
   const [loading, setLoading] = useState(false);

   const fetchCycles = async () => {
      try {
         const response = await getBillingCycles();
         setCycles(response.results);
      } catch (e) {
         toast.error(e?.message);
      }
   };

   useEffect(() => {
      fetchCycles();
   }, []);

   const wallets = bizDetails?.biz_account_wallet_details
      ?.filter(
         (item) => item.biz_wallet_type !== 'safe' && item.biz_wallet_type !== 'fixed_deposits',
      )
      .map(({ biz_wallet_type, available_balance, biz_wallet_id }) => ({
         label: capitilizeText(`${biz_wallet_type} -  ₦${numberWithCommas(available_balance)}`),
         value: biz_wallet_id,
      }));

   const planOptions = plans?.map(({ name, type }) => ({
      label: type,
      value: name,
   }));

   const cyclesOptions = cycles.map(({ cycle }) => ({
      label: cycle,
      value: cycle,
   }));

   const schema = yup.object().shape({
      discount: yup.number().max(100).min(0),
      plan: yup.string(),
      wallet: yup.string(),
      cycle: yup.string(),
   });

   const {
      register,
      handleSubmit,
      setValue,
      trigger,
      watch,
      formState: { errors, isValid },
   } = useForm({
      mode: 'onChange',
      resolver: yupResolver(schema),
      shouldFocusError: true,
   });

   useEffect(() => {
      register('discount');
      register('plan');
      register('wallet');
      register('cycle');
   }, []);

   const discount = watch('discount');
   const plan = watch('plan');
   const wallet = watch('wallet');
   const cycle = watch('cycle');

   const sharedOnChange = useCallback(async ({ name, value }) => {
      setValue(name, value);
      await trigger(name);
   }, []);

   const renderErrorMessage = (name) => (
      <ErrorMessage
         errors={errors}
         name={name}
         render={({ message }) => <p className="input-error-message text-danger">{message}</p>}
      />
   );

   const onSubmit = async (data) => {
      setLoading(true);

      try {
         const payload = {
            plan_type: data.plan,
            biz_wallet_id: data.wallet,
            plan_cycle: data.cycle,
            discount,
         };
         await changeBillingPlan(payload, bizDetails.biz_account_id);
         toast.success('Plan upgraded successfully');
         onClose();
      } catch (e) {
         toast.error(e?.message);
      }
      setLoading(false);
   };

   const handleConfirm = (data) => {
      confirm({
         confirmText: `You are about to change this account to a subscription plan. Once you click confirm below the account will automatically be debited and changed. Only click confirm if you are sure`,
         isLoading: false,
         onConfirm: async () => onSubmit(data),
         proceedBtnText: 'Confirm',
         cancelBtnText: 'Cancel',
      });
   };

   return (
      <Modal open={open} onClose={onClose} closeOnOverlayClick={false}>
         <form onSubmit={handleSubmit(handleConfirm)}>
            <InputWrapper className="grid-2-2">
               <SelectInput
                  name="plan"
                  onChange={({ value }) => sharedOnChange({ name: 'plan', value })}
                  label="Select the plan you want to upgrade to"
                  options={planOptions}
                  defaultValue={plan}
               />
               <SelectInput
                  name="cycle"
                  onChange={({ value }) => sharedOnChange({ name: 'cycle', value })}
                  label="Select billing cycle"
                  options={cyclesOptions}
                  defaultValue={cycle}
               />
            </InputWrapper>
            <InputWrapper className="grid-2-2">
               <SelectInput
                  name="wallet"
                  onChange={({ value }) => sharedOnChange({ name: 'wallet', value })}
                  label="Select account to debit from"
                  options={wallets}
                  defaultValue={wallet}
               />

               <div>
                  <NumberInput
                     label="Discount"
                     placeholder="Enter discount (%)"
                     name="discount"
                     value={discount}
                     prefix="%"
                     onChange={({ floatValue }) =>
                        sharedOnChange({ name: 'discount', value: floatValue || 0 })
                     }
                  />
                  {renderErrorMessage('discount')}
               </div>
            </InputWrapper>

            <div className="d-flex justify-content-end">
               <Button type="submit" isLoading={loading} disabled={!isValid}>
                  Confirm
               </Button>
            </div>
         </form>
      </Modal>
   );
}

ChangePlanNewBilling.propTypes = {
   open: PropTypes.bool.isRequired,
   onClose: PropTypes.func.isRequired,
   bizDetails: PropTypes.objectOf().isRequired,
   plans: PropTypes.objectOf().isRequired,
};
