import { Http } from 'services/http';
import { logActivity } from 'services/activity';
import { paramsObjectToQueryString } from 'utils/others';
import { can_update_tenure, can_update_loan_terms } from 'utils/user_actions';

export const fetchLoanApplications = (payload, page) =>
   Http(`/backmen/loan_list_check/${paramsObjectToQueryString(page)}`, {
      method: 'POST',
      body: payload,
   });

export const getPendingLoanStream = (payload) =>
   Http(`/backmen/pending_loan_stream_check/`, {
      method: 'POST',
      body: payload,
   });

export const loanWorkerTracker = (payload) =>
   Http(`/backmen/admin_loan_work_action/`, {
      method: 'POST',
      body: payload,
   });

export const fetchLoanRepaymentHistory = (id) => Http(`/backmen/loan_and_repayment/${id}/`);

export const loanRequestedByBiz = (id) =>
   Http(`/backmen/loan_requested_by_biz/${id}/`, {
      action: 'read_business_loans',
   });

export const businessRefActions = async (payload, biz_account_id) => {
   try {
      const response = await Http(`/backmen/biz_reference_decision/`, {
         method: 'POST',
         body: payload,
         action: 'approve_reject_business_reference',
      });
      await logActivity(
         `Business reference for loan application ${payload.reference_action}`,
         'reg',
         biz_account_id,
      );
      return response;
   } catch (e) {
      throw new Error(e.message);
   }
};

export const firstLoanApproval = async (payload, biz_account_id) => {
   try {
      const response = await Http(`/backmen/intial_loan_officer_decision/`, {
         method: 'POST',
         body: payload,
      });
      await logActivity(
         `Business loan application ${payload.biz_loan_decision} (first approval)`,
         'reg',
         biz_account_id,
      );
      return response;
   } catch (e) {
      throw new Error(e.message);
   }
};

export const finalLoanApproval = async (payload, biz_account_id) => {
   try {
      const response = await Http(`/backmen/final_loan_decision/`, {
         method: 'POST',
         body: payload,
      });
      await logActivity(
         `Business loan application ${payload.biz_loan_decision} (final approval)`,
         'reg',
         biz_account_id,
      );
      return response;
   } catch (e) {
      throw new Error(e.message);
   }
};

export const fetchLoanStats = () =>
   Http(`/backmen/loan_general_stat/`, {
      action: 'read_loan_statistics',
   });

// const route_map = {
//    data_req: 'export_lender_data',
//    ht_dump: 'export_transaction_history_data',
//    data_req_biz_agg: 'export_wallet_data',
//    export_loan_data: 'export_wallet_data',
// };

export const exportDataDump = (params) => {
   const { download_type, ...remainingParams } = params;
   return Http(`/backmen/${params.download_type}${paramsObjectToQueryString(remainingParams)}`, {
      action: 'admin_can_view_exported_data',
   });
};

export const exportFiles = (params) =>
   Http(`/backmen/admin_exported_data${paramsObjectToQueryString(params)}`);

export const getLoanTunure = (params) =>
   Http(`/backmen/loan_tenure/${paramsObjectToQueryString(params)}`);

export const getSingleTenure = (id) =>
   Http(`/backmen/loan_tenure/1${id}`, {
      action: can_update_tenure,
   });

export const createLoanTenure = (params) =>
   Http(`/backmen/loan_tenure/`, {
      method: 'POST',
      body: params,
      action: can_update_tenure,
   });

export const updateLoanTenure = (params) => {
   const { id, ...remainingParams } = params;
   Http(`/backmen/loan_tenure/${id}/`, {
      method: 'PATCH',
      body: remainingParams,
      action: can_update_tenure,
   });
};

export const deleteLoanTenure = (params) => {
   const { id, ...remainingParams } = params;
   Http(`/backmen/loan_tenure/${id}/`, {
      method: 'DELETE',
      body: remainingParams,
      action: can_update_tenure,
   });
};

export const getAllLoanTerms = (params) =>
   Http(`/backmen/loan_terms/${paramsObjectToQueryString(params)}`, {
      action: can_update_loan_terms,
   });

export const createLoanTerm = (params) =>
   Http(`/backmen/loan_terms/`, {
      method: 'POST',
      body: params,
      action: can_update_loan_terms,
   });

export const updateLoanTerm = (params) => {
   const { id, ...remainingParams } = params;
   Http(`/backmen/loan_terms/${id}/`, {
      method: 'PATCH',
      body: remainingParams,
      action: can_update_tenure,
   });
};

export const deleteLoanTerm = (params) => {
   const { id, ...remainingParams } = params;
   Http(`/backmen/loan_terms/${id}/`, {
      method: 'DELETE',
      body: remainingParams,
      action: can_update_tenure,
   });
};
