import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import toast from 'react-hot-toast';
import moment from 'moment';
import { TooltipComponent } from 'components/tooltip';
import FilterListIcon from '@material-ui/icons/FilterList';
import { TableContainer, TableContent } from 'styles/table';
import { Skeleton } from '@material-ui/lab';
import { FilterContainer } from 'pages/follow-up/follow-up.style';
import { TableComponent } from 'components/table';
import { getResetLogs } from 'services/businessDetails';
import ResetLogsFilter from 'containers/filter-modal/reset-logs-filter';

export const PinAndPasswordResetLogs = () => {
   const { search } = useLocation();
   const page = new URLSearchParams(search).get('page');
   const [tableData, setTableData] = useState([]);
   const [isLoading, setIsLoading] = useState(false);
   const [paginationData, setPaginationData] = useState({});
   const [currentPage, setCurrentPage] = useState(1);
   const [modalVisibilityFilter, setModalVisibilityFilter] = useState(false);
   const [filterPayload, setFilterPayload] = useState({});
   const [filterData, setFilterData] = useState({
      start_date: moment().format('YYYY-MM-01'),
      end_date: moment().format('YYYY-MM-DD'),
   });

   const fetchResetLogs = async () => {
      setIsLoading(true);
      try {
         const res = await getResetLogs({
            page: page || currentPage,
            ...filterPayload,
         });
         setTableData(res.results);
         setPaginationData(res);
         setIsLoading(false);
      } catch (e) {
         toast.error(e?.message);
         setIsLoading(false);
      }
   };

   useEffect(() => {
      fetchResetLogs();
   }, [currentPage, filterPayload]);

   const columns = [
      {
         field: 'user_name',
         width: 250,
         headerName: 'User Name',
         renderCell: ({ row }) => (
            <TooltipComponent title={row.user_name}>{row.user_name || 'N/A'}</TooltipComponent>
         ),
      },
      {
         field: 'reset_type',
         headerName: 'Reset Type',
         renderCell: ({ row }) => (
            <TooltipComponent title={row.reset_type}>{row.reset_type || 'N/A'}</TooltipComponent>
         ),
      },
      {
         field: 'status',
         headerName: 'Status',
         renderCell: ({ row }) => (
            <TooltipComponent title={row.status}>{row.status || 'N/A'}</TooltipComponent>
         ),
      },
      {
         field: 'request_time',
         headerName: 'Time',
         renderCell: ({ row }) => (
            <TooltipComponent title={row.request_time}>
               {moment(row.request_time).format('DD MMM, YYYY')} |{' '}
               <span style={{ opacity: '.5' }}>{moment(row.request_time).format('HH:mm A')}</span>
            </TooltipComponent>
         ),
      },
      {
         field: 'request_ip_address',
         headerName: 'IP Address',
         renderCell: ({ row }) => (
            <TooltipComponent title={row.request_ip_address}>
               {row.request_ip_address || 'N/A'}
            </TooltipComponent>
         ),
      },
      {
         field: 'phone',
         headerName: 'Phone Number',
         renderCell: ({ row }) => (
            <TooltipComponent title={row.phone}>{row.phone || 'N/A'}</TooltipComponent>
         ),
      },
      {
         field: 'email',
         width: 300,
         headerName: 'Email Address',
         renderCell: ({ row }) => <span title={row.email}>{row.email || 'N/A'}</span>,
      },
      {
         field: 'user_type',
         headerName: 'User Type',
         renderCell: ({ row }) => (
            <TooltipComponent title={row.user_type}>{row.user_type || 'N/A'}</TooltipComponent>
         ),
      },
   ];

   function onPageChange(e, pageNumber) {
      setCurrentPage(pageNumber);
   }

   return (
      <>
         <TableContainer>
            <TableContent>
               <div className="d-flex justify-content-between w-100 align-items-center">
                  {isLoading ? (
                     <Skeleton width="30%" height="20" />
                  ) : (
                     <div>
                        <h1> All Logs</h1>
                        <span className="value">{paginationData?.count || 'N/A'}</span>
                     </div>
                  )}

                  <div className="d-flex align-items-center">
                     <FilterContainer onClick={() => setModalVisibilityFilter(true)}>
                        Filter By <FilterListIcon style={{ marginLeft: '10px' }} />
                     </FilterContainer>
                  </div>
               </div>
            </TableContent>
            <TableComponent
               columns={columns}
               rows={tableData}
               isLoading={isLoading}
               onPageChange={onPageChange}
               count={Math.ceil(paginationData.count / 15)}
            />
         </TableContainer>
         {modalVisibilityFilter && (
            <ResetLogsFilter
               open={modalVisibilityFilter}
               onClose={() => setModalVisibilityFilter(false)}
               filterData={filterData}
               setFilterData={setFilterData}
               setFilterPayload={setFilterPayload}
            />
         )}
      </>
   );
};
