import { useContext } from 'react';
import { BizDetailsModalContext } from 'context/bizDetailsModal';

const setActiveTabAction = (payload) => ({
   type: 'SET_ACTIVE_TAB',
   payload,
});

function useBizDetailsModal() {
   const context = useContext(BizDetailsModalContext);
   const { state, dispatch } = context;

   function setActiveTab(mode) {
      dispatch(setActiveTabAction(mode));
   }

   if (context === undefined) {
      throw new Error('useBizDetailsModal must be used within a SubAccountModalProvider');
   }

   return {
      setActiveTab,
      state,
   };
}

export { useBizDetailsModal };
