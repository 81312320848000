/* eslint-disable no-console */
import { Component } from 'react';
import { getCalendarNotes, addNoteToCalendar } from 'services/admin';
import toast from 'react-hot-toast';
import Modal from 'components/modal';
import { Button } from 'components/button';
import FullCalendar from '@fullcalendar/react'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import CalendarImg from 'assets/images/calendar.png';
import moment from 'moment';
import { CalendarWrapper, CalendarButtonWrapper } from './style';

export default class Calendar extends Component {
   constructor() {
      super();
      this.state = {
         showModal: false,
         events: [
            { id: 1, title: 'event 1', date: '2021-08-04' },
            { id: 2, title: 'event 2', date: '2021-08-20' },
         ],
         activeEvents: [],
         newNote: '',
         date: moment(new Date()).format('YYYY-MM-DD'),
      };
   }

   componentDidMount() {
      this.intializeCalendar();
   }

   intializeCalendar = async (value) => {
      try {
         const { data } = await getCalendarNotes();

         // const ev = data.map(({
         //    calendar_note: title,
         //    calendar_stamp: date,
         //    ...rest
         //  }) => ({
         //    title,
         //    date,
         //    ...rest
         //  }))
         this.setState({
            events: data,
         });
         this.getSelectedDateEvents(value || moment().format('YYYY-MM-DD'));
      } catch (e) {
         toast.error(e.message);
      }
   };

   getSelectedDateEvents = (value) => {
      const { events } = this.state;
      const activeEvents = events.filter(({ calendar_stamp }) => {
         return calendar_stamp === value;
      });
      this.setState({ activeEvents });
   };

   handleDateClick = (info) => {
      const date = moment(info.date).format('YYYY-MM-DD');
      this.getSelectedDateEvents(date);
      this.setState({ date });
   };

   handleTextarea = ({ target }) => {
      this.setState({
         newNote: target.value,
      });
   };

   addNewNote = async () => {
      const { newNote, date } = this.state;
      if (newNote === '') return 'Input Cannot be empty!';

      const note = {
         calendar_note: newNote,
         calendar_stamp: date,
      };

      try {
         const { message } = await addNoteToCalendar(note);
         this.setState({ newNote: '' });
         toast.success(message);
         this.intializeCalendar(date);
      } catch (e) {
         toast.error(e.message);
      }
   };

   render() {
      const { showModal, events, activeEvents, newNote, date } = this.state;
      return (
         <>
            <div>
               <CalendarButtonWrapper
                  className="d-flex justify-content-between align-items-center"
                  onClick={() => this.setState({ showModal: true })}
               >
                  <span>Book Calendar</span>
                  <img src={CalendarImg} alt="" />
               </CalendarButtonWrapper>
            </div>

            <Modal
               title="Book Calendar"
               open={showModal}
               onClose={() => this.setState({ showModal: false })}
               closeOnOverlayClick={false}
            >
               <CalendarWrapper date={date}>
                  <div className="row px-3 pt-5 mb-5">
                     <div className="col-lg-8 mb-4 mb-lg-0">
                        <FullCalendar
                           plugins={[
                              dayGridPlugin,
                              interactionPlugin,
                              timeGridPlugin,
                              listPlugin,
                              interactionPlugin,
                              bootstrapPlugin,
                           ]}
                           initialView="dayGridMonth"
                           selectable
                           events={events}
                           dateClick={(info) => this.handleDateClick(info)}
                        />
                     </div>
                     <div className="col-lg-4">
                        <h3 className="calendar-notes-title mb-4">
                           {moment(date).format('DD MMM, YYYY')}
                        </h3>
                        <div
                           id="calendar-notes"
                           className=""
                           style={{ overflow: 'scroll', height: '337px' }}
                        >
                           {activeEvents.length > 0 ? (
                              activeEvents.map(({ calendar_note, calendar_id }, i) => (
                                 <div className="calendar-note-block mb-3" key={calendar_id}>
                                    <span>Note {i + 1}</span> : <div>{calendar_note}</div>
                                 </div>
                              ))
                           ) : (
                              <h4 className="mb-0">No note available for this day</h4>
                           )}
                        </div>
                     </div>
                     <div className="mt-4 filter-meta">
                        <p>Add New Note</p>
                        <textarea
                           name=""
                           id="input-calendar-new-note"
                           cols="20"
                           rows="4"
                           className="w-100 p-3"
                           value={newNote}
                           onChange={(e) => this.handleTextarea(e)}
                        />
                        <div className="d-flex justify-content-end">
                           <Button
                              variant="primary"
                              onClick={this.addNewNote}
                              disabled={newNote === '' && true}
                           >
                              Book Calendar
                           </Button>
                        </div>
                     </div>
                     {/* <div className="col-lg-3">
                        <Button variant="primary" onClick={() => this.setState({ showModal: false })}>
                           Close
                        </Button>
                     </div> */}
                  </div>
               </CalendarWrapper>
            </Modal>
         </>
      );
   }
}
