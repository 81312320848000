import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { extractUtilityTokenWithApi } from 'services/businessDetails';
import { Button } from 'components/button';
import { Input } from 'components/input';
import { InputWrapper } from 'styles/form';
import { hasValue } from 'utils/validations';

export default function ExtractUtilityToken() {
   const [transactionRef, setTransactionRef] = useState('');
   const [loading, setLoading] = useState(false);
   const [businessId, setBusinessId] = useState('');

   const isValid = hasValue(transactionRef) && hasValue(businessId);

   const extractUtilityTokenApi = async () => {
      setLoading(true);

      const payload = {
         transaction_ref: transactionRef,
         businessId,
      };

      try {
         const response = await extractUtilityTokenWithApi(payload);
         toast.success(response.message);
         setTransactionRef('');
      } catch (e) {
         toast.error(e.message);
      }
      setLoading(false);
   };

   return (
      <div className="row align-items-end">
         <InputWrapper className="col-lg-4">
            <Input
               placeholder="Enter Transaction Ref"
               label="Transaction Ref"
               value={transactionRef}
               onChange={(e) => setTransactionRef(e.target.value)}
            />
         </InputWrapper>
         <InputWrapper className="col-lg-4">
            <Input
               placeholder="Enter Business ID"
               label="Business ID"
               value={businessId}
               onChange={(e) => setBusinessId(e.target.value)}
            />
         </InputWrapper>
         <InputWrapper className="col-lg-4">
            <Button
               variant="primary"
               onClick={extractUtilityTokenApi}
               isLoading={loading}
               disabled={!isValid}
            >
               Extract
            </Button>
         </InputWrapper>
      </div>
   );
}
