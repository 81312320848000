/* eslint-disable react/prop-types */
/* eslint-disable react/forbid-prop-types */
import { useState } from 'react';
import moment from 'moment';
import { array } from 'prop-types';
import { Spinner } from 'components/spinner';
import { TableComponent } from 'components/table';
import { TableContainer, TableContent } from 'styles/table';
import { numberWithCommas } from 'utils/others';
// import { formatTag } from 'utils/tags';
import { TooltipComponent } from 'components/tooltip';
import { AlertMessage } from 'components/message';
// import { Input } from 'components/input';
// import { OverviewWrapper } from 'styles/layout';

const Overview = ({ repaymentHistory }) => {
   const [paginationData] = useState({});
   // const [currentPage, setCurrentPage] = useState(1);
   const [loading] = useState(false);
   const [error] = useState('');

   // function onPageChange(e, pageNumber) {
   //    // setCurrentPage(pageNumber);
   // }

   const columns = [
      {
         field: 'trans_amount',
         headerName: 'Transaction Amount',
         renderCell: ({ row }) => (
            <TooltipComponent title={`₦${numberWithCommas(row?.trans_amount)}`}>
               ₦{numberWithCommas(row?.trans_amount)}
            </TooltipComponent>
         ),
      },
      {
         field: 'created_by',
         headerName: 'Initiated By',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.created_by}>{row?.created_by}</TooltipComponent>
         ),
      },
      {
         field: 'source_account_name',
         headerName: 'Source Account Name',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.source_account_name}>
               {row?.source_account_name}
            </TooltipComponent>
         ),
      },
      //   {
      //      field: 'transfer_details.transfer_status',
      //      headerName: 'Status',
      //      renderCell: ({ row }) => {
      //         let template = '';
      //         if (row?.transfer_details) {
      //            template =
      //               row?.transfer_details?.transfer_status === 'success'
      //                  ? formatTag('accepted', 'Success')
      //                  : formatTag('rejected', 'Failed');
      //         } else if (row?.bill_payment_details) {
      //            template =
      //               row?.bill_payment_details?.biller_status === 'success'
      //                  ? formatTag('accepted', 'Success')
      //                  : formatTag('rejected', 'Failed');
      //         } else if (row?.payout_details) {
      //            template =
      //               row?.payout_details?.payout_status === 'success'
      //                  ? formatTag('accepted', 'Success')
      //                  : formatTag('rejected', 'Failed');
      //         } else {
      //            template = row?.is_done
      //               ? formatTag('accepted', 'Success')
      //               : formatTag('rejected', 'Failed');
      //         }
      //         return (
      //            <TooltipComponent title={row?.transfer_details?.transfer_status}>
      //               {template}
      //            </TooltipComponent>
      //         );
      //      },
      //   },
      //   {
      //      field: 'tx_action',
      //      headerName: 'Type',
      //      renderCell: ({ row }) => {
      //         let template = '';
      //         if (row.tx_action.includes('credit')) {
      //            template = formatTag('accepted', 'Credit');
      //         } else if (row.tx_action.includes('debit')) {
      //            template = formatTag('', 'Debit');
      //         } else {
      //            template = formatTag('pending', 'NA');
      //         }
      //         return <TooltipComponent title={row.tx_action}>{template}</TooltipComponent>;
      //      },
      //   },
      //   {
      //      field: 'source_account_name',
      //      headerName: 'Initiated By',
      //      renderCell: ({ row }) => (
      //         <TooltipComponent title={row?.source_account_name}>
      //            {row?.source_account_name}
      //         </TooltipComponent>
      //      ),
      //   },
      {
         field: 'pub_date',
         headerName: 'Date Created',
         renderCell: ({ row }) => (
            <TooltipComponent title={row.pub_date}>
               {moment(row.pub_date).format('DD MMM, YYYY')} |{' '}
               <span style={{ opacity: '.5' }}>{moment(row.pub_date).format('HH:mm A')}</span>
            </TooltipComponent>
         ),
      },
   ];

   return (
      <div>
         <AlertMessage message={error} variant="error" />

         {loading ? (
            <Spinner />
         ) : (
            <TableContainer>
               <TableContent>
                  <div>
                     <h1> Repayment History</h1>
                     <span>{paginationData?.count}</span>
                  </div>
               </TableContent>
               <TableComponent
                  columns={columns}
                  rows={repaymentHistory}
                  isLoading={loading}
                  // onPageChange={onPageChange}
                  count={paginationData.count}
               />
            </TableContainer>
         )}
      </div>
   );
};

Overview.propTypes = {
   repaymentHistory: array.isRequired,
};

export default Overview;
