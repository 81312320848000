import { useState } from 'react';
import { useAuth } from 'hooks/auth';
import clx from 'classnames';
import {
   manual_bvn_verification,
   pin_reset,
   refund,
   extract_utility_token,
   deactivate_2fa,
   reactivate_member_account,
   update_business_name,
   update_business_email,
   resend_otp,
   manual_debit,
   update_owner_details,
   can_approve_otp,
} from 'utils/user_actions';
import LoggedInDashboard from 'components/logged-in-dashboard';
import { TabWrapper } from 'pages/pending-documents/style';
import BvnVerification from './features/bvn-verification';
import MemberAccount from './features/member-account';
import ChangeBusinessDetails from './features/business-details';
import Security from './features/security';
import TransactionsTools from './features/transactions';

function DebuggingTools() {
   const { currentUser } = useAuth();
   const [activeTab, setActiveTab] = useState('bvn-verification');
   const { permissions } = currentUser?.access || {};

   const checkPermission = (action) => permissions?.includes(action) || permissions === 'all';

   const tabs = [
      {
         label: 'BVN Verification',
         value: 'bvn-verification',
         permission: checkPermission(manual_bvn_verification),
         component: () => <BvnVerification permissions={permissions} />,
      },
      {
         label: 'Member Account',
         value: 'member-account',
         permission:
            checkPermission(reactivate_member_account) || checkPermission(update_owner_details),
         component: () => <MemberAccount permissions={permissions} />,
      },
      {
         label: 'Transactions',
         value: 'transactions',
         permission:
            checkPermission(refund) ||
            checkPermission(extract_utility_token) ||
            checkPermission(manual_debit),
         component: () => <TransactionsTools permissions={permissions} />,
      },
      {
         label: 'Business Details',
         value: 'change-business-details',
         permission:
            checkPermission(update_business_name) || checkPermission(update_business_email),
         component: () => <ChangeBusinessDetails permissions={permissions} />,
      },
      {
         label: 'Security',
         value: 'security',
         permission:
            checkPermission(pin_reset) ||
            checkPermission(deactivate_2fa) ||
            checkPermission(resend_otp) ||
            checkPermission(can_approve_otp),
         component: () => <Security permissions={permissions} />,
      },
   ];

   return (
      <LoggedInDashboard pageTitle="Debugging Tools">
         <TabWrapper>
            <ul className="m-0 d-flex align-items-center">
               {tabs?.map(
                  ({ permission, label, value }) =>
                     permission && (
                        <li
                           className={clx('pending-doc-overview pending-tab-action', {
                              active: activeTab === value,
                           })}
                           onClick={() => setActiveTab(value)}
                        >
                           {label}
                        </li>
                     ),
               )}
            </ul>
         </TabWrapper>

         {tabs?.map(
            ({ permission, value, component }) => permission && activeTab === value && component(),
         )}
      </LoggedInDashboard>
   );
}

export default DebuggingTools;
