import styled from 'styled-components';

export const SelectInputComponentStyle = styled.div`
   .react-select__value-container {
      width: 100%;
      height: 4.8rem;
   }

   .react-select__control {
      height: 4.8rem;
      width: 100%;
      font-size: 1.6rem;
      color: #dadada;
      border: ${({ theme, error }) => `1px solid ${error ? theme.colors?.redError : '#bdbdbdbd'}`};
   }

   .react-select__placeholder {
      font-weight: 400;
      color: #8fa7bc;
   }

   .react-select__indicator-seperator {
      display: none !important;
   }

   .react-select__single-value {
      font-weight: 400;
      color: ${({ theme }) => theme.colors?.black01};
      text-transform: capitalize;
   }
`;
