import { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import { getTicketCategories } from 'services/ticketing';

export default function useTicketCategory() {
   const [categories, setCategories] = useState([]);

   const fetchTicketCategories = async () => {
      try {
         const response = await getTicketCategories();
         setCategories([
            {
               category: 'All',
               id: null,
            },
            ...response.data,
         ]);
      } catch (e) {
         toast.error(e?.message);
      }
   };

   useEffect(() => {
      fetchTicketCategories();
   }, []);

   return {
      categories,
   };
}
