import { useState, useEffect } from 'react';
import Modal from 'components/modal';
import { useHistory } from 'react-router-dom';
import { func, bool, arrayOf, objectOf, string } from 'prop-types';
import { InputWrapper } from 'styles/form';
import { Button } from 'components/button';
import FilterInputRender from 'components/FilterInputRender';

const BusinessFilterModal = ({ open, onClose, filterData, handleSubmit, filters }) => {
   const [value, setValue] = useState({});
   const history = useHistory();
   const [defaultValue, setDefaultValue] = useState({});

   useEffect(() => {
      if (filterData) {
         setValue(filterData);
         setDefaultValue(filterData);
      }
   }, []);

   function onChange(val) {
      setValue({
         ...value,
         ...val,
      });
   }

   const handleOnSubmit = () => {
      handleSubmit(value);
      onClose();
   };

   const clearFilter = () => {
      const resetValue = defaultValue || {};
      setValue(resetValue);
      history.push(`${history.location.pathname}?page=1`);
      handleSubmit(resetValue);
      onClose();
   };

   return (
      <Modal open={open} onClose={onClose} title="Filters" center maxWidth="700px">
         <form className="row">
            {filters &&
               filters.map((item) => (
                  <div className="col-md-6 mb-4">
                     <FilterInputRender onChange={onChange} value={value} item={item} />
                  </div>
               ))}

            <InputWrapper
               style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
            >
               <div style={{ marginRight: '20px' }}>
                  <Button variant="secondary" type="button" onClick={clearFilter} fullWidth>
                     clear filter
                  </Button>
               </div>

               <div>
                  <Button variant="primary" type="button" onClick={handleOnSubmit} fullWidth>
                     Apply
                  </Button>
               </div>
            </InputWrapper>
         </form>
      </Modal>
   );
};

export { BusinessFilterModal };

BusinessFilterModal.propTypes = {
   open: bool.isRequired,
   onClose: func.isRequired,
   filterData: arrayOf(objectOf(string)).isRequired,
   handleSubmit: func.isRequired,
   filters: arrayOf(objectOf(string)).isRequired,
};
