import { useEffect, useState } from 'react';
import moment from 'moment';
import toast from 'react-hot-toast';
import { useDebouncedCallback } from 'use-debounce';
import { Skeleton } from '@material-ui/lab';
import FilterListIcon from '@material-ui/icons/FilterList';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import { numberWithCommas, openNewWindow } from 'utils/others';
import { useBizDetailsModal } from 'hooks/bizDetailsModal';
import { FilterContainer } from 'pages/follow-up/follow-up.style';
import { TableContainer, TableContent } from 'styles/table';
import { HeadingOne } from 'styles/typography';
import { Input } from 'components/input';
import LoggedInDashboard from 'components/logged-in-dashboard';
import { TableComponent } from 'components/table';
import { TooltipComponent } from 'components/tooltip';
import BusinessDetails from 'containers/business-details';
import BillingDebtLogsFilterModal from 'containers/filter-modal/billing-debt-logs-filter';
import { getBillingDebtLogs } from 'services/savings';

function BillingDebtLogs() {
   const { setActiveTab } = useBizDetailsModal();
   const [searchTerm, setSearchTerm] = useState('');
   const [loading, setLoading] = useState(false);
   const [noCloseIcon, setNoCloseIcon] = useState(false);
   const [modalVisibility, setModalVisibility] = useState(false);
   const [modalVisibilityFilter, setModalVisibilityFilter] = useState(false);
   const [currentPage, setCurrentPage] = useState(1);
   const [bizAccount, setBizAccount] = useState('');
   const [tableData, setTableData] = useState([]);
   const [paginationData, setPaginationData] = useState({});
   const [filterPayload, setFilterPayload] = useState({});
   const [filterData, setFilterData] = useState({});

   const fetchLogs = async () => {
      setLoading(true);
      try {
         const res = await getBillingDebtLogs({
            page: currentPage,
            biz_name: searchTerm,
            ...filterPayload,
         });
         setPaginationData(res);
         setTableData(res?.result);
         setLoading(false);
      } catch (e) {
         toast.error(e?.message || 'Something went wrong. Please try again');
         setLoading(false);
      }
   };

   const handleExport = async () => {
      setLoading(true);
      try {
         const res = await getBillingDebtLogs({
            page: currentPage,
            biz_name: searchTerm,
            ...filterPayload,
            export: true,
         });
         openNewWindow(res?.file_url);
         setLoading(false);
      } catch (e) {
         toast.error(e?.message || 'Something went wrong.Please try again.');
         setLoading(false);
      }
   };

   useEffect(() => {
      fetchLogs();
   }, [currentPage, searchTerm, filterPayload]);

   function handleOpenModal() {
      setActiveTab('overview');
      setModalVisibility(true);
      setNoCloseIcon(false);
   }

   const columns = [
      {
         field: 'business_name',
         headerName: 'Business Name',
         renderCell: ({ row }) => (
            <TooltipComponent title={row.business_name}>
               <span
                  style={{
                     cursor: 'pointer',
                  }}
                  className="text-primary"
                  onClick={() => handleOpenModal(row.business_id)}
               >
                  {row.business_name || 'N/A'}
               </span>
            </TooltipComponent>
         ),
      },
      {
         field: 'amount',
         headerName: 'Amount',
         renderCell: ({ row }) => <span>{`₦ ${numberWithCommas(row?.amount)}` || 'N/A'}</span>,
      },
      {
         field: 'cycle',
         headerName: 'Cycle',
         renderCell: ({ row }) => <span>{row?.cycle || 'N/A'}</span>,
      },
      {
         field: 'plan',
         width: 300,
         headerName: 'Plan',
         renderCell: ({ row }) => <span>{row?.plan?.replaceAll('_', ' ') || 'N/A'}</span>,
      },
      {
         field: 'bill_start_date',
         headerName: 'Bill Start Date',
         renderCell: ({ row }) => (
            <span>
               {moment(row?.bill_start_date).format('MMM DD, YYYY')} |{' '}
               {moment(row?.bill_start_date).format('h:mm a')}
            </span>
         ),
      },
      {
         field: 'end_date',
         headerName: 'End Date',
         renderCell: ({ row }) => (
            <span>
               {moment(row?.end_date).format('MMM DD, YYYY')} |{' '}
               {moment(row?.end_date).format('h:mm a')}
            </span>
         ),
      },
      {
         field: 'bill_expected_end_date',
         headerName: 'Bill Expected End Date',
         renderCell: ({ row }) => (
            <span>
               {moment(row?.bill_expected_end_date).format('MMM DD, YYYY')} |{' '}
               {moment(row?.bill_expected_end_date).format('h:mm a')}
            </span>
         ),
      },
   ];

   function onPageChange(e, pageNumber) {
      setCurrentPage(pageNumber);
   }

   function onRowClick({ row }) {
      setBizAccount(row.business_id);
   }

   function handleSearch({ target }) {
      setSearchTerm(target.value);
   }

   const debounce = useDebouncedCallback(handleSearch, 600);

   return (
      <LoggedInDashboard>
         <HeadingOne>Billing Debt Logs</HeadingOne>
         <div>
            <TableContainer id="table">
               <TableContent>
                  {loading ? (
                     <Skeleton width="30%" height="20" />
                  ) : (
                     <div>
                        <h1> Business Accounts</h1>
                        <span className="value">{paginationData?.count || 'N/A'}</span>
                     </div>
                  )}
                  <div className="d-flex justify-content-between gap-3">
                     <div>
                        <Input
                           id="search"
                           style={{ width: '300px' }}
                           placeholder="Search Business Name"
                           onChange={debounce}
                        />
                     </div>
                     <div>
                        <FilterContainer className="ms-3" onClick={handleExport}>
                           Export Data
                           <ImportExportIcon style={{ marginLeft: '10px' }} />
                        </FilterContainer>
                     </div>
                     <FilterContainer onClick={() => setModalVisibilityFilter(true)}>
                        Filter By <FilterListIcon style={{ marginLeft: '10px' }} />
                     </FilterContainer>
                  </div>
               </TableContent>
               <TableComponent
                  columns={columns}
                  rows={tableData}
                  isLoading={loading}
                  onPageChange={onPageChange}
                  count={Math.round(paginationData.count / 15)}
                  page={currentPage}
                  pageSize={20}
                  onRowClick={onRowClick}
               />
            </TableContainer>
            {modalVisibility && (
               <BusinessDetails
                  businessAccountId={bizAccount}
                  open={modalVisibility}
                  onClose={() => setModalVisibility(false)}
                  noCloseIcon={noCloseIcon}
               />
            )}
            {modalVisibilityFilter && (
               <BillingDebtLogsFilterModal
                  open={modalVisibilityFilter}
                  onClose={() => setModalVisibilityFilter(false)}
                  filterData={filterData}
                  setFilterData={setFilterData}
                  setFilterPayload={setFilterPayload}
               />
            )}
         </div>
      </LoggedInDashboard>
   );
}

export default BillingDebtLogs;
