import React from 'react';
import { Grid, Switch } from '@material-ui/core';
import useTransactionsSettings from 'hooks/transactionsSettings';

export default function GlobalPaymentToggle() {
   const { globalSettings, updateGlobalPaymentToggle } = useTransactionsSettings();

   const { is_enabled } = globalSettings;

   return (
      <div className="border-bottom pb-4 pt-5">
         <b>International Transfers</b>
         <div className="pb-4">
            <Grid component="label" container alignItems="center" marginLeft="" spacing={1}>
               <Grid item>
                  <b>Off</b>
               </Grid>
               <Grid item>
                  <Switch
                     checked={is_enabled}
                     onClick={
                        is_enabled
                           ? () => updateGlobalPaymentToggle('off')
                           : () => updateGlobalPaymentToggle('on')
                     }
                  />
               </Grid>
               <Grid item>
                  <b>On</b>
               </Grid>
            </Grid>
         </div>
      </div>
   );
}
