import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useAuth } from 'hooks/auth';
import { NavItemWrapper } from './style';

function NavItem({ link, text, icon }) {
   const { logout } = useAuth();

   return (
      <NavItemWrapper onClick={link === 'sign-out' ? logout : () => {}}>
         <NavLink to={link === 'sign-out' ? '/#' : link} className="nav-link" exact>
            <span data-feather={icon} />
            {text}
         </NavLink>
      </NavItemWrapper>
   );
}
NavItem.propTypes = {
   link: PropTypes.string.isRequired,
   text: PropTypes.string.isRequired,
   icon: PropTypes.string.isRequired,
};

export default NavItem;
