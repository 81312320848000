import React, { useState, useEffect } from 'react';
import { toast } from 'react-hot-toast';
import { Skeleton } from '@material-ui/lab';
import { StatsCards } from 'components/stats-cards';

import { getBusinessData } from 'services/promo-code';

export default function BusinessData() {
   const [businessData, setBusinessData] = useState({});
   const [isLoading, setIsLoading] = useState(false);

   const fetchMarketingData = async () => {
      setIsLoading(true);
      try {
         const result = await getBusinessData();
         setBusinessData({
            // total_business_account: result?.total_biz_account,
            total_refer_account: result?.total_refer_acount,
            // total_approved_freelancer_account: result?.total_approved_freelancer_account,
            // total_approved_registered_business_account: result?.total_approved_reg_biz_account,
            total_unapproved_freelancer_account: result?.total_unapproved_freelancer_account,
            total_unapproved_registered_business_account: result?.total_unapproved_reg_biz_account,
            total_unapproved_unregistered_account: result?.total_unapproved_unreg_account,
            total_approved_unregistered_account: result?.total_approved_unreg_account,
         });
      } catch (err) {
         toast.error(err?.message || 'Cannot fetch the data');
      }
      setIsLoading(false);
   };

   useEffect(() => {
      fetchMarketingData();
   }, []);

   return (
      <>
         {isLoading ? (
            <Skeleton width="30%" height="20" />
         ) : (
            <div className="d-flex justify-content-between align-items-center pb-5">
               <h1>Business Data</h1>
            </div>
         )}
         <div className="d-flex justify-content-between flex-row">
            <StatsCards data={businessData} />
         </div>
      </>
   );
}
