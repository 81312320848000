/* eslint-disable array-callback-return */
/* eslint-disable react/no-array-index-key */
import { MenuItem, Menu } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { func, string, arrayOf, bool, node } from 'prop-types';

const useStyles = makeStyles({
   paper: {
      boxShadow: '0px 3px 8px rgba(0,0,0,0.04)',
   },
   list: {
      fontSize: '1.4rem',
      fontFamily: "'Mark OT', sans-serif",
   },
});

const useItemStyles = makeStyles({
   root: {
      fontSize: '1.4rem',
      fontFamily: "'Mark OT', sans-serif",
   },
});

export const MenuComponent = ({ anchorEl, handleClose, open, options, optionsFns }) => {
   const classes = useStyles();
   const itemClasses = useItemStyles();

   function handleOption(i) {
      try {
         optionsFns[i]();
         return handleClose();
      } catch (err) {
         return err;
      }
   }

   return (
      <Menu
         classes={classes}
         id="long-menu"
         anchorEl={anchorEl}
         keepMounted
         open={open}
         onClose={handleClose}
      >
         {options.map((option, index) => (
            <MenuItem
               classes={itemClasses}
               key={`${option}-${index}`}
               onClick={() => handleOption(index)}
            >
               {option}
            </MenuItem>
         ))}
      </Menu>
   );
};

MenuComponent.propTypes = {
   open: bool,
   options: arrayOf(string.isRequired).isRequired,
   handleClose: func.isRequired,
   anchorEl: node,
   optionsFns: arrayOf(func.isRequired).isRequired,
};

MenuComponent.defaultProps = {
   open: false,
   anchorEl: null,
};
