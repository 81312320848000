import { Http } from 'services/http';
import { logActivity } from 'services/activity';
import { paramsObjectToQueryString, trimObject } from 'utils/others';

export const getBusinessRegistrations = (page, payload) =>
   Http(`/backmen/filter_biz_registrations/${paramsObjectToQueryString(page)}`, {
      method: 'POST',
      body: trimObject(payload),
   });

export const getSingleBusinessRegistrations = (id) =>
   Http(`/backmen/just_unregistered_biz_details/${id}/`);

export const updateBusinessRegistrations = (payload) => {
   logActivity(`Updated Business Registration status`, 'unreg', payload.unregistered_biz_id);
   return Http(`/backmen/update_unregistered_biz/`, {
      method: 'POST',
      body: trimObject(payload),
      action: 'update_bizness_registration_details',
   });
};

export const getNotes = (businessAccountId) =>
   Http(`/backmen/listed_unregbiz_note/${businessAccountId}`);

export const addNote = async (payload) => {
   try {
      const response = await Http('/backmen/submit_message_unregbiz/', {
         body: payload,
         method: 'POST',
      });
      await logActivity(
         `Added a new note -  ${payload.drop_message}`,
         'unreg',
         payload.unregistered_biz_id,
      );
      return response;
   } catch (e) {
      throw new Error(e.message);
   }
};

export const sendDocumentsForProcessing = async (payload) => {
   try {
      const response = await Http('/backmen/trigger_biz_email/', {
         body: payload,
         method: 'POST',
      });
      await logActivity(`Sent Documents for processing`, 'unreg', payload.unregistered_biz_id);
      return response;
   } catch (e) {
      throw new Error(e.message);
   }
};

export const updatePaymentStatus = async (payload) => {
   try {
      const response = await Http('/backmen/strict_manual_update_unregistered_biz/', {
         body: payload,
         method: 'POST',
         action: 'update_bizness_registration_payment_status',
      });
      await logActivity(
         `Updated business registration payment status`,
         'unreg',
         payload.unregistered_biz_id,
      );
      return response;
   } catch (e) {
      throw new Error(e.message);
   }
};

export const convertApplicationToBusiness = async (payload) => {
   try {
      const response = await Http('/backmen/convert_unregistered_biz/', {
         body: payload,
         method: 'POST',
      });
      await logActivity(
         `Updated business registration payment status`,
         'unreg',
         payload.unregistered_biz_id,
      );
      return response;
   } catch (e) {
      throw new Error(e.message);
   }
};
