import { useState, useEffect } from 'react';
import { toast } from 'react-hot-toast';
import PropTypes from 'prop-types';
import { numberWithCommas } from 'utils/others';
import { confirm } from 'components/confirm-box';
import { Button } from 'components/button';
import { NumberInput } from 'components/input';
import { updateFixedDepositsAccount } from 'services/fixed-deposits';
import CreateFixedDeposits from './create-fixed-deposits';
import AddFundsModal from './add-funds';
import WithdrawFundsModal from './withdraw-funds';

export default function FixedDeposits({
   businessAccountId,
   bizDetails,
   fdDetails,
   fetchFixedDepositsAccount,
}) {
   const [inputModalVisible, setInputModalVisible] = useState(false);
   const [interest, setInterest] = useState('');
   const [loading, setLoading] = useState(false);
   const [addFundsModalVisibility, setAddFundsModalVisibility] = useState(false);
   const [withdrawFundsModalVisibility, setWithdrawFundsModalVisibility] = useState(false);

   useEffect(() => {
      if (fdDetails) {
         setInterest(fdDetails?.interest_rate || '');
      }
   }, [fdDetails]);

   const { id, safe } = fdDetails || {};

   const { safe_wallet_details } = safe || {};

   const { available_balance } = safe_wallet_details || {};

   const handleUpdateInterestRate = async () => {
      setLoading(true);
      const payload = {
         id,
         interest_rate: interest,
      };
      try {
         await updateFixedDepositsAccount(payload);
         toast.success('Successfully updated!');
      } catch (err) {
         toast.error(err?.message);
      }
      setLoading(false);
   };

   const confirmAction = () =>
      confirm({
         confirmText: 'Are you sure you want to continue ?',
         onConfirm: handleUpdateInterestRate,
      });

   const handleProcessInitiation = () => {
      setInputModalVisible(true);
   };

   const handleAccountCreationWarning = () =>
      confirm({
         confirmText: (
            <div>
               <p className="mb-2 text-danger">[Important]:</p>
               <p>
                  Do not Click &#34;Lock Funds&#34; if you do not have all the information needed to
                  create a funded fixed account.
               </p>
               <p className="mt-4 text-info">Guidelines:</p>
               <ol>
                  <li>-Ensure the member’s account is sufficiently funded.</li>
                  <li>-Ensure you have the interest rate and term.</li>
               </ol>
               <p className="mt-4">If you do not have all the information, do not proceed.</p>
            </div>
         ),
         onConfirm: handleProcessInitiation,
         proceedBtnText: 'Proceed',
         cancelBtnText: 'Close',
      });

   return (
      <>
         <div className="mb-5 pt-5 pb-4 border-top border-bottom">
            <h3>Savings</h3>
            <div className="d-flex gap-5">
               <div>
                  {!fdDetails ? (
                     <div className="mb-5 pe-5">
                        <Button
                           variant="secondary"
                           className="mt-5 mb-3 w-50"
                           onClick={handleAccountCreationWarning}
                        >
                           Create a Fixed Deposit Account
                        </Button>
                     </div>
                  ) : (
                     <>
                        <div>
                           <p
                              style={{
                                 color: '#3A434B',
                                 fontSize: '16px',
                                 fontWeight: '500',
                                 marginTop: '10px',
                              }}
                           >
                              Fixed deposit Balance
                           </p>
                        </div>
                        <div>
                           <p
                              style={{
                                 color: 'var(--Deep-Blue, #04093F)',
                                 fontSize: '24px',
                                 marginTop: '8px',
                                 fontWeight: '700',
                              }}
                           >
                              {`₦${numberWithCommas(available_balance)}`}
                           </p>
                        </div>
                        <div className="mb-5 pe-5">
                           <Button
                              variant="primary"
                              fullWidth
                              className="mt-4"
                              onClick={() => setAddFundsModalVisibility(true)}
                           >
                              Add Funds
                           </Button>
                           <Button
                              variant="secondary"
                              fullWidth
                              className="mt-4"
                              onClick={() => setWithdrawFundsModalVisibility(true)}
                           >
                              Withdraw Funds
                           </Button>
                        </div>
                     </>
                  )}
               </div>

               <div className="col-lg-4 pe-5">
                  <h3 className="mb-3">Fixed Deposits Interest Rate</h3>
                  <NumberInput
                     className="wrap-label-input"
                     placeholder=""
                     prefix="hidden"
                     suffix="%"
                     name="interest_rate"
                     value={interest}
                     onChange={(valuesObject) => {
                        setInterest(valuesObject.floatValue);
                     }}
                  />
                  <Button
                     className="mt-4 w-100"
                     onClick={confirmAction}
                     isLoading={loading}
                     disabled={!interest}
                  >
                     Confirm Interest Rates
                  </Button>
               </div>
            </div>
         </div>
         {inputModalVisible && (
            <CreateFixedDeposits
               open={inputModalVisible}
               bizDetails={bizDetails}
               businessAccountId={businessAccountId}
               onClose={() => setInputModalVisible(false)}
               fdDetails={fdDetails}
               fetchFixedDepositsAccount={fetchFixedDepositsAccount}
            />
         )}
         {addFundsModalVisibility && (
            <AddFundsModal
               open={addFundsModalVisibility}
               onClose={() => setAddFundsModalVisibility(false)}
               bizDetails={bizDetails}
               fdDetails={fdDetails}
               fetchFixedDepositsAccount={fetchFixedDepositsAccount}
            />
         )}
         {withdrawFundsModalVisibility && (
            <WithdrawFundsModal
               open={withdrawFundsModalVisibility}
               onClose={() => setWithdrawFundsModalVisibility(false)}
               bizDetails={bizDetails}
               fdDetails={fdDetails}
               fetchFixedDepositsAccount={fetchFixedDepositsAccount}
            />
         )}
      </>
   );
}

FixedDeposits.propTypes = {
   businessAccountId: PropTypes.number.isRequired,
   bizDetails: PropTypes.objectOf().isRequired,
   fdDetails: PropTypes.objectOf().isRequired,
   fetchFixedDepositsAccount: PropTypes.func.isRequired,
};
