/* eslint-disable react/prop-types */
import DocumentViewer from 'components/document-previewer';
import { string } from 'prop-types';
import FileSvg from 'assets/images/empty.svg';
import { Wrapper } from './style';

function Index({ name, url }) {
   return (
      <Wrapper className="business-details-item">
         <div className="file-preview-frame">
            <div className="d-flex justify-content-between align-items-center mb-2">
               <h6 className="mb-0">DOCUMENT NAME</h6>
               <DocumentViewer url={url} title={name}>
                  <span className="px-3 py-2 view-btn ">View</span>
               </DocumentViewer>
            </div>
            <div className="d-flex align-items-center">
               <div className="file-bg d-flex align-items-center justify-content-center">
                  <img src={FileSvg} alt="" />
               </div>
               {/* <img src={FileSvg} alt="" className="d-none" id="document-for-image-viewer"/> */}
               <h4 className="mb-0" id="biz-poa-name">
                  {name}
               </h4>
            </div>
         </div>
      </Wrapper>
   );
}

Index.propTypes = {
   name: string,
   url: string.isRequired,
};

Index.defaultProps = {
   name: 'Document Name',
};

export default Index;
