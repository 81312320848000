import { useState, useEffect } from 'react';
import { toast } from 'react-hot-toast';
import PropTypes from 'prop-types';
import { confirm } from 'components/confirm-box';
import { NumberInput } from 'components/input';
import { Button } from 'components/button';
import { changeBillingPlan, getActivePlan } from 'services/new-billing';

export const BillingRates = ({ businessAccountId }) => {
   const [rate, setRate] = useState('');
   const [activePlan, setActivePlan] = useState('');
   const [loading, setLoading] = useState(false);

   useEffect(() => {
      if (activePlan) {
         setRate(activePlan?.billing_rate || '');
      }
   }, [activePlan]);

   const fetchActivePlan = async () => {
      try {
         const res = await getActivePlan(businessAccountId);
         setActivePlan(res);
      } catch (err) {
         toast.error(err?.message);
      }
   };

   useEffect(() => {
      fetchActivePlan();
   }, []);

   const UpdateBillingRate = async () => {
      setLoading(true);
      const payload = {
         billing_rate: rate,
      };
      try {
         await changeBillingPlan(payload, businessAccountId);
         toast.success('Successfully updated!');
      } catch (err) {
         toast.error(err?.message);
      }
      setLoading(false);
   };

   const confirmAction = () =>
      confirm({
         confirmText: 'Are you sure you want to continue ?',
         onConfirm: UpdateBillingRate,
      });

   return (
      <div className="col-lg-4 mb-5 pe-5">
         <h3 className="mb-3">Billing</h3>
         <NumberInput
            className="wrap-label-input"
            label="Pay As You Go Rate"
            placeholder=""
            prefix="hidden"
            suffix="%"
            name="interest_rate"
            value={rate}
            onChange={(valuesObject) => {
               setRate(valuesObject.floatValue);
            }}
         />
         <Button
            className="mt-4 w-100"
            onClick={confirmAction}
            isLoading={loading}
            disabled={!rate}
         >
            Confirm Interest Rates
         </Button>
      </div>
   );
};
BillingRates.propTypes = {
   businessAccountId: PropTypes.number.isRequired,
};
