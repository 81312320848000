import { Http } from 'services/http';
import { paramsObjectToQueryString } from 'utils/others';
import { can_update_global_payment_settings } from 'utils/user_actions';

export const getInternationalTransactions = (page) => {
   return Http(`/backmen/admin_international_tranfers/${paramsObjectToQueryString(page)}`);
};

export const getInboundInternationalTransactions = (page) => {
   return Http(`/backmen/admin_international_tranfers_in/${paramsObjectToQueryString(page)}`);
};

export const getTransactionDetails = (id) => {
   return Http(`/backmen/admin_international_tranfers/${id}/`);
};

export const getInboundTransactionDetail = (id) => {
   return Http(`/backmen/admin_international_tranfers_in/${id}/`);
};

export const approveOrRejectTransaction = (id, payload) => {
   return Http(`/backmen/admin_international_tranfers/${id}/status/`, {
      method: 'PUT',
      body: payload,
   });
};

export const approveOrRejectInboundTransaction = (id, payload) => {
   return Http(`/backmen/admin_international_tranfers_in/${id}/status/`, {
      method: 'PUT',
      body: payload,
   });
};

export const toggleInternationalPayment = (payload) => {
   return Http(`/backmen/toggle_international_transfer/`, {
      method: 'PUT',
      body: payload,
      action: can_update_global_payment_settings,
   });
};

export const getRates = () => {
   return Http(`/backmen/admin_international_transfer_rates/`);
};

export const updateRates = (id, payload) => {
   return Http(`/backmen/admin_international_transfer_rates/${id}/`, {
      method: 'PUT',
      body: payload,
   });
};

export const createRates = (payload) => {
   return Http(`/backmen/admin_international_transfer_rates/`, {
      method: 'POST',
      body: payload,
   });
};

export const getInternationalTransactionsSettings = () => {
   return Http(`/backmen/international_transfers_global_settings/`);
};

export const globalToggleInternationalPayment = (payload) => {
   return Http(`/backmen/international_transfers_global_settings/`, {
      method: 'PUT',
      body: payload,
      action: can_update_global_payment_settings,
   });
};

export const getGlobalFees = () => {
   return Http(`/backmen/tranfers_in_global_fees/`);
};

export const getBusinessFees = (params) => {
   return Http(
      `/backmen/admin_international_tranfers_in_fees/${paramsObjectToQueryString(params)}`,
   );
};

export const updateGlobalFees = (payload) => {
   return Http(`/backmen/tranfers_in_global_fees/`, {
      method: 'PUT',
      body: payload,
   });
};

export const updateBusinessFees = (id, payload) => {
   return Http(`/backmen/admin_international_tranfers_in_fees/${id}/`, {
      method: 'PUT',
      body: payload,
   });
};

export const createBusinessFees = (payload) => {
   return Http(`/backmen/admin_international_tranfers_in_fees/`, {
      method: 'POST',
      body: payload,
   });
};

export const getRateSpread = () => {
   return Http(`/backmen/currency_exchange_spread/`);
};

export const updateRateSpread = (payload) => {
   return Http(`/backmen/currency_exchange_spread/`, {
      method: 'PUT',
      body: payload,
   });
};
