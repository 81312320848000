// PROSPA USER TYPES
export const prospasuperadmin = 'prospasuperadmin';
export const prospabuildpartner = 'prospabuildpartner';
export const loan_admin = 'prospastaff';
export const loan_officer = 'prospaloanofficer';
export const prospa_auditor = 'prospaauditor';
export const prospacsr = 'prospacsr';
export const prospabizpartner = 'prospabizpartner';
export const prospaapprover = 'prospaapprover';

export const managerRoles = [
   {
      value: 'prospacsr',
      label: 'Prospa CSR',
   },
   {
      value: 'prospabuildpartner',
      label: 'Prospa Build Partner',
   },
   {
      value: 'prospabizpartner',
      label: 'Prospa Biz Partner',
   },
   {
      value: 'prospastaff',
      label: 'Prospa Staff',
   },
   {
      value: 'prospaauditor',
      label: 'Prospa Auditor',
   },
   {
      value: 'prospaloanofficer',
      label: 'Prospa Loan Officer',
   },
];
