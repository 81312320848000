/* eslint-disable no-restricted-syntax */
/* eslint-disable no-undef */
/* eslint-disable array-callback-return */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import LoggedInDashboard from 'components/logged-in-dashboard';

function FollowUp() {
   return (
      <LoggedInDashboard pageTitle="CAC Business Search">
         <div className="container">
            <div className="h_iframe" style={{ height: `calc(100vh - 100px)` }}>
               <iframe
                  src="https://cac.getprospa.co/cacsearch.php"
                  frameBorder="0"
                  allowFullScreen
                  title="cac-search"
                  width="100%"
                  height="100%"
               />
            </div>
         </div>
      </LoggedInDashboard>
   );
}

export default FollowUp;
