/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { objectOf, string } from 'prop-types';
import { useLocation } from 'react-router-dom';
import { Skeleton } from '@material-ui/lab';
import moment from 'moment';
import { InputWrapper } from 'styles/form';
import { SelectInput } from 'components/select';
import { TableContainer, TableContent } from 'styles/table';
import { TableComponent } from 'components/table';
import { exportFiles } from 'services/loans';

export default function ExportedData({ exportTypes }) {
   const { search } = useLocation();
   const page = new URLSearchParams(search).get('page');
   const [tableData, setTableData] = useState([]);
   const [isLoading, setIsLoading] = useState(false);
   const [paginationData, setPaginationData] = useState({});
   const [currentPage, setCurrentPage] = useState(page);
   const [exportType, setExportType] = useState(exportTypes[0]);

   const columns = [
      {
         field: 'initiated_by_name',
         headerName: 'Created By',
         renderCell: ({ row }) => <p>{row?.initiated_by_name}</p>,
      },
      {
         width: 180,
         field: 'start_date',
         headerName: 'Data from',
         renderCell: ({ row }) => (
            <p>{row.start_date ? moment(row?.start_date)?.format('DD MMM, YYYY') : 'N/A'}</p>
         ),
      },
      {
         width: 180,
         field: 'end_date',
         headerName: 'Data to',
         renderCell: ({ row }) => (
            <p>{row.end_date ? moment(row?.end_date)?.format('DD MMM, YYYY') : 'N/A'}</p>
         ),
      },
      {
         field: 'created',
         headerName: 'Date Exported',
         renderCell: ({ row }) => <p>{moment(row?.created)?.format('DD MMM, YYYY | HH:mm A')}</p>,
      },
      {
         width: 300,
         field: 'export_type',
         headerName: 'Export Type',
         renderCell: ({ row }) => <p>{(row?.export_type).replaceAll('_', ' ').toUpperCase()}</p>,
      },
      {
         field: 'url',
         headerName: 'Downloadable Data',
         renderCell: ({ row }) => <a href={row?.url}>Download data</a>,
      },
   ];

   const fetchExportedData = async (pageNumber) => {
      setIsLoading(true);
      setTableData([]);
      setPaginationData({});
      Promise.all([
         exportFiles({
            page: pageNumber,
            export_type: exportType.downloadPath,
         }),
      ])
         .then(([result]) => {
            setTableData(result?.results);
            setPaginationData(result);
         })
         .finally(() => {
            setIsLoading(false);
         });
   };

   useEffect(() => {
      fetchExportedData(currentPage);
   }, [currentPage, exportType]);

   function onPageChange(e, pageNumber) {
      setCurrentPage(pageNumber);
   }

   return (
      <div>
         <TableContainer>
            <InputWrapper className="grid-2-2">
               <SelectInput
                  onChange={(val) => setExportType(val)}
                  label="Export Type"
                  options={exportTypes}
                  defaultValue={exportType}
               />
            </InputWrapper>
            <TableContent>
               <div className="d-flex justify-content-between w-100 align-items-center">
                  {isLoading ? (
                     <Skeleton width="30%" height="20" />
                  ) : (
                     <div>
                        <h1> Exported Data</h1>
                        <span className="value">{paginationData?.count || 'N/A'}</span>
                     </div>
                  )}
               </div>
            </TableContent>
            <TableComponent
               columns={columns}
               rows={tableData}
               isLoading={isLoading}
               onPageChange={onPageChange}
               count={Math.ceil(paginationData.count / 15)}
            />
         </TableContainer>
      </div>
   );
}

ExportedData.propTypes = {
   exportTypes: objectOf(string).isRequired,
};
