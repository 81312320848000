import { Http } from 'services/http';
import { logActivity } from 'services/activity';

export const filterFailedBvn = (account_holder_id) =>
   Http(`/backmen/check_bvn_customer/${account_holder_id}/`);

export const bvnLookUp = (payload) =>
   Http(`/backmen/loan_ref_bvn_lookup/`, {
      body: payload,
      method: 'POST',
   });

export const bvnManualVerification = async (payload) => {
   const { businessId, ...rest } = payload;
   try {
      const response = await Http(`/backmen/quick_manual_bvn_fix/`, {
         body: rest,
         method: 'POST',
         action: 'manual_bvn_verification',
      });
      await logActivity(
         `Busienss owner's BVN was manually verified`,
         'reg',
         businessId,
         'manual_bvn_verification',
      );
      return response;
   } catch (e) {
      throw new Error(e.message);
   }
};
