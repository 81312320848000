import React from 'react';
import PropTypes from 'prop-types';

import Modal from 'components/modal';
import { InputWrapper } from 'styles/form';
import { Button } from 'components/button';
import { Input } from 'components/input';

export default function AddNewPermission({ open, onClose }) {
   return (
      <Modal open={open} onClose={onClose} title="Add new permissions" center width="750px">
         <form>
            <InputWrapper className="grid-2-2">
               <Input placeholder="Start Date" label="Start Date" name="start_date" type="date" />
               <Input placeholder="End Date" label="End Date" name="end_date" />
            </InputWrapper>

            <InputWrapper
               style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
            >
               <div>
                  <Button variant="primary" type="button">
                     Apply
                  </Button>
               </div>
            </InputWrapper>
         </form>
      </Modal>
   );
}

AddNewPermission.propTypes = {
   open: PropTypes.bool.isRequired,
   onClose: PropTypes.bool.isRequired,
};
