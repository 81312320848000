/* eslint-disable react/prop-types */
import { Button } from 'components/button';
import { confirm } from 'components/confirm-box';
import { StatsCards } from 'components/stats-cards';
import { TableComponent } from 'components/table';
import { TooltipComponent } from 'components/tooltip';
import moment from 'moment';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { editProspect, getProspectData, targetStats } from 'services/business';
import { TableContent } from 'styles/table';
import { AddTargetModal } from './add-target-modal';

const Target = () => {
   const [targetStatsData, setTargetStatsData] = useState({});
   const [isLoadingStats, setIsLoadingStats] = useState(false);
   const [isLoadingTargetData, setIsLoadingTargetData] = useState(false);
   const [targetData, setTargetData] = useState([]);
   const [isChangeStatusLoading, setIsChangeStatusLoading] = useState(false);
   const [modalVisibilityFilter, setModalVisibilityFilter] = useState(false);
   const [paginationData, setPaginationData] = useState({});
   const [currentPage, setCurrentPage] = useState(1);

   function onPageChange(e, pageNumber) {
      setCurrentPage(pageNumber);
   }

   function eo() {
      (async () => {
         try {
            setIsLoadingStats(true);
            const { target_current_status_count } = await targetStats();
            setTargetStatsData(
               target_current_status_count.reduce((acc, element) => {
                  acc[element.target_current_status] = element.target_current_status__count;
                  return acc;
               }, {}),
            );
         } catch (err) {
            return err;
         } finally {
            setIsLoadingStats(false);
         }
      })();
   }
   useEffect(() => {
      eo();
   }, []);

   async function handleRefetchProspectData() {
      const result = await getProspectData(
         {},
         {
            start_date: '2019-01-01',
            end_date: moment().format('YYYY-MM-DD'),
         },
      );
      setTargetData(result.data);
   }

   useEffect(() => {
      (async () => {
         try {
            setIsLoadingTargetData(true);
            const { data, ...rest } = await getProspectData(
               {},
               {
                  start_date: '2019-01-01',
                  end_date: moment().format('YYYY-MM-DD'),
               },
            );
            setTargetData(data);
            setPaginationData(rest);
         } catch (err) {
            return err;
         } finally {
            setIsLoadingTargetData(false);
         }
      })();
   }, []);

   async function handleChangeStatus({ target }, targetId) {
      confirm({
         confirmText: 'Are you sure you want to perform this action?',
         isLoading: isChangeStatusLoading,
         onConfirm: async () =>
            new Promise((resolve, reject) => {
               setIsChangeStatusLoading(true);
               editProspect({
                  target_current_status: target.value,
                  target_id: targetId,
               })
                  .then(() => {
                     setIsChangeStatusLoading(false);
                     toast.success('success');
                     eo();
                     resolve();
                  })
                  .catch((err) => {
                     setIsChangeStatusLoading(false);
                     reject(err);
                     toast.error(err.message || err.custom_message);
                  });
            }),
      });
   }

   const columns = [
      {
         field: 'target_first_name',
         headerName: 'Business Name',
         renderCell: ({ row }) => (
            <TooltipComponent title={row.target_first_name}>
               {`${row.target_first_name}-${row.target_last_name}`}
            </TooltipComponent>
         ),
      },

      {
         field: 'target_address',
         headerName: 'Address',
      },
      {
         field: 'target_biz_category',
         headerName: 'Category',
      },
      {
         field: 'target_phone',
         headerName: 'Phone Number',
      },
      {
         field: 'target_state',
         headerName: 'State',
      },
      {
         field: 'target_current_status',
         headerName: 'Status',
         renderCell: ({ row }) => (
            <select
               style={{
                  width: '100%',
                  border: 'none',
                  padding: '10px',
                  fontWeight: 400,
                  fontSize: '1.3rem',
               }}
               onChange={(e) => handleChangeStatus(e, row.target_id)}
               name="status"
               defaultValue={row.target_current_status}
            >
               <option value="pitched">Pitched</option>
               <option value="followup">Follow Up</option>
               <option value="closed">Closed</option>
               <option value="out">Out</option>
            </select>
         ),
      },
      {
         field: 'pub_date',
         headerName: 'Date Onboarded',
         renderCell: ({ row }) => (
            <span>
               {moment(row?.pub_date).format('MMM DD, YYYY')} |{' '}
               {moment(row?.pub_date).format('h:mm a')}
            </span>
         ),
      },
   ];

   return (
      <div>
         <StatsCards data={targetStatsData} isLoading={isLoadingStats} />
         <AddTargetModal
            open={modalVisibilityFilter}
            onClose={() => setModalVisibilityFilter(false)}
            handleRefetchProspectData={handleRefetchProspectData}
         />
         <TableContent>
            <div>
               <h1>Targets</h1>
               <span className="value">{paginationData?.count || 'N/A'}</span>
            </div>

            <div className="my-4 d-flex justify-content-end">
               <Button onClick={() => setModalVisibilityFilter(true)}>Add Target</Button>
            </div>
         </TableContent>
         <TableComponent
            columns={columns}
            rows={targetData}
            isLoading={isLoadingTargetData}
            count={Math.round(paginationData.count / 15)}
            page={currentPage}
            onPageChange={onPageChange}
         />
      </div>
   );
};

export { Target };
