import { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import { func, bool } from 'prop-types';
import { Input } from 'components/input';
import Modal from 'components/modal';
import { InputWrapper } from 'styles/form';
import { Button } from 'components/button';
import { SelectInput } from 'components/select';
import { CARD_STATUS } from 'utils/card-constants';

const CardFilterModal = ({
   open,
   onClose,
   setCurrentPage,
   filterData,
   setFilterData,
   setFilterPayload,
}) => {
   const [isLoading, setIsLoading] = useState(false);
   const [isClearingFilter, setIsClearingFilter] = useState(false);
   const history = useHistory();

   useEffect(() => {
      return function unMount() {
         setIsLoading(false);
      };
   }, []);

   function onChange(name, data) {
      setFilterData({
         ...filterData,
         [name]: data.value,
      });
   }

   async function onSubmit() {
      setIsLoading(true);
      try {
         setCurrentPage(() => 1);
         history.push(`${history.location.pathname}?page=1`);
         setFilterPayload(filterData);
         onClose();
      } catch (err) {
         toast.error(err.message || err.custom_message);
         setIsLoading(false);
         return err;
      } finally {
         setIsLoading(false);
      }
   }

   async function handleClearFilter() {
      setIsClearingFilter(true);
      try {
         setCurrentPage(() => 1);
         history.push(`${history.location.pathname}?page=1`);
         setFilterData({});
         setFilterPayload({});
         onClose();
      } catch (err) {
         return err;
      }
      setIsClearingFilter(false);
   }
   return (
      <Modal
         open={open}
         onClose={onClose}
         title="Filters"
         className="biz-details-modal"
         center
         width="750px"
      >
         <form>
            <InputWrapper className="grid-2-2">
               <Input
                  placeholder="Start Date"
                  label="Start Date"
                  name="payment_date__start"
                  type="date"
                  value={filterData?.payment_date__start}
                  onChange={(e) => {
                     onChange('payment_date__start', e.target);
                  }}
               />
               <Input
                  placeholder="End Date"
                  label="End Date"
                  name="payment_date__end"
                  type="date"
                  value={filterData?.payment_date__end}
                  onChange={(e) => {
                     onChange('payment_date__end', e.target);
                  }}
               />
            </InputWrapper>

            <InputWrapper className="grid-2-2">
               <SelectInput
                  onChange={(val) => onChange('status', val)}
                  label=" Status"
                  options={CARD_STATUS}
                  defaultValue={CARD_STATUS.filter((item) => item.value === filterData.status)}
               />
            </InputWrapper>

            <InputWrapper
               style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
            >
               <div style={{ marginRight: '20px' }}>
                  <Button
                     variant="secondary"
                     type="button"
                     onClick={handleClearFilter}
                     isLoading={isClearingFilter}
                     fullWidth
                  >
                     clear filter
                  </Button>
               </div>

               <div>
                  <Button
                     variant="primary"
                     type="button"
                     onClick={onSubmit}
                     isLoading={isLoading}
                     disabled={isLoading}
                     fullWidth
                  >
                     Apply
                  </Button>
               </div>
            </InputWrapper>
         </form>
      </Modal>
   );
};

export default CardFilterModal;

CardFilterModal.propTypes = {
   open: bool.isRequired,
   onClose: func.isRequired,
   setCurrentPage: func.isRequired,
   filterData: func.isRequired,
   setFilterData: func.isRequired,
   setFilterPayload: func.isRequired,
};
