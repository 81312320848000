import styled from 'styled-components';

export const GraphContainer = styled.div`
   background: #fff;
   padding: 20px 10px;
   border: 1px solid #d7dce0;
   border-radius: 8px;

   .deposit-title {
      font-size: 16px;
   }

   .moment {
      font-size: 14px;
      margin-bottom: 30px;
      display: inline-block;
   }
   .feeds-btn {
      padding: 10px 0;
      width: 120px;
      border: 1px solid #e3e3e3;
      border-radius: 5px;
      text-align: center;
      cursor: pointer;
      color: #1354d3;
      transition: all ease 0.3s;
      p {
         margin: 0;
      }
   }
   .feeds-btn.active,
   .feeds-btn:hover {
      background: #1354d3;
      color: #fff;
   }
   .date-picker-group {
      span {
         font-size: 14px;
         line-height: 16px;
         color: #435a70;
      }
      select {
         width: 100px;
         background: #ffffff;
         border: 1px solid #d7e0e9;
         box-sizing: border-box;
         border-radius: 4px;
         width: 120px;
         height: 38px;
         font-size: 14px;
         line-height: 16px;
         color: #435a70;
         padding: 0 10px;
         :placeholder {
            font-size: 14px;
            line-height: 16px;
            color: #435a70;
         }
      }
   }
`;
