import React, { useState } from 'react';
import toast from 'react-hot-toast';

import { Button } from 'components/button';
import { Input } from 'components/input';
import { InputWrapper } from 'styles/form';
import { confirm } from 'components/confirm-box';
import { validateEmail, hasValue } from 'utils/validations';
import { sendPinResetApi } from 'services/user';

export default function PinReset() {
   const [email, setEmail] = useState('');
   const [loading, setLoading] = useState(false);
   const [businessId, setBusinessId] = useState('');

   const isValid = validateEmail(email) && hasValue(businessId);

   const sendResetLink = async () => {
      if (!isValid) return toast.error('Please enter a valid email address');
      setLoading(true);
      try {
         const payload = {
            rep_email: email,
            businessId,
         };
         await sendPinResetApi({
            ...payload,
            action_verb: 'reset_mfa',
            grand_step: 'first_step',
         });
         const response2 = await sendPinResetApi({
            ...payload,
            action_verb: 'reset_pin',
            grand_step: 'second_step',
         });
         setEmail('');
         setBusinessId('');
         toast.success(response2.message);
      } catch (e) {
         toast.error(e?.message);
      }
      setLoading(false);
   };

   const confirmAction = () =>
      confirm({
         confirmText: 'Are you sure you want to continue ?',
         onConfirm: sendResetLink,
      });

   return (
      <div className="row align-items-end">
         <InputWrapper className="col-lg-4">
            <Input
               placeholder="Enter email address"
               label="Email Address"
               value={email}
               onChange={(e) => setEmail(e.target.value)}
            />
         </InputWrapper>
         <InputWrapper className="col-lg-4">
            <Input
               placeholder="Enter Business ID"
               label="Business ID"
               value={businessId}
               onChange={(e) => setBusinessId(e.target.value)}
            />
         </InputWrapper>
         <InputWrapper className="col-lg-4">
            <Button
               variant="primary"
               onClick={confirmAction}
               isLoading={loading}
               disabled={!isValid}
            >
               Send Reset Mail
            </Button>
         </InputWrapper>
      </div>
   );
}
