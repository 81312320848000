import { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import { func, bool, objectOf, string } from 'prop-types';
import { Input } from 'components/input';
import Modal from 'components/modal';
import { useAuth } from 'hooks/auth';
import { hasPermission } from 'utils/user_access';
import { InputWrapper } from 'styles/form';
import { Button } from 'components/button';
import { SelectInput } from 'components/select';
import useFetchManagers from 'hooks/fetchManagers';
import CategoryFilter from '../category-filter';

const TicketFilterModal = ({
   open,
   onClose,
   setCurrentPage,
   filterData,
   setFilterData,
   setFilterPayload,
   filterPayload,
}) => {
   const [isLoading, setIsLoading] = useState(false);
   const [isClearingFilter, setIsClearingFilter] = useState(false);
   const history = useHistory();
   const { managers } = useFetchManagers();
   const { currentUser } = useAuth();
   const { permissions } = currentUser?.access || {};

   const admin = hasPermission(permissions, 'ticket_admin');

   useEffect(() => {
      return function unMount() {
         setIsLoading(false);
      };
   }, []);

   function onChange(name, data) {
      setFilterData({
         ...filterData,
         [name]: data.value,
      });
   }

   async function onSubmit() {
      setIsLoading(true);
      try {
         setCurrentPage(() => 1);
         history.push(`${history.location.pathname}?page=1`);
         setFilterPayload({ ...filterPayload, ...filterData });
         onClose();
      } catch (err) {
         toast.error(err.message || err.custom_message);
         setIsLoading(false);
         return err;
      } finally {
         setIsLoading(false);
      }
   }

   async function handleClearFilter() {
      setIsClearingFilter(true);
      try {
         setCurrentPage(() => 1);
         history.push(`${history.location.pathname}?page=1`);
         setFilterData({});
         if (filterPayload?.issue_status) {
            setFilterPayload({ issue_status: filterPayload?.issue_status });
         } else {
            setFilterPayload({});
         }

         onClose();
      } catch (err) {
         return err;
      }
      setIsClearingFilter(false);
   }

   const managerList = [
      {
         label: 'All',
         value: null,
      },
      ...managers,
   ];

   return (
      <Modal
         open={open}
         onClose={onClose}
         title="Filters"
         className="biz-details-modal"
         center
         width="750px"
      >
         <form>
            <InputWrapper className="grid-2-2">
               <Input
                  placeholder="Start Date"
                  label="Start Date"
                  name="start_date"
                  type="date"
                  value={filterData?.start_date}
                  onChange={(e) => {
                     onChange('start_date', e.target);
                  }}
               />
               <Input
                  placeholder="End Date"
                  label="End Date"
                  name="end_date"
                  type="date"
                  value={filterData?.end_date}
                  onChange={(e) => {
                     onChange('end_date', e.target);
                  }}
               />
            </InputWrapper>

            <InputWrapper className="grid-2-2">
               {admin && (
                  <SelectInput
                     onChange={(val) => onChange('assigned_to', val)}
                     label="Manager"
                     options={managerList}
                     value={managerList.find((item) => item.value === filterData.assigned_to)}
                  />
               )}

               <CategoryFilter
                  filterPayload={filterData}
                  onSubmit={(value) => onChange('category', { value })}
                  placeholder="Select Categories"
                  label="Categories"
               />
            </InputWrapper>

            <InputWrapper
               style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
            >
               <div style={{ marginRight: '20px' }}>
                  <Button
                     variant="secondary"
                     type="button"
                     onClick={handleClearFilter}
                     isLoading={isClearingFilter}
                     fullWidth
                  >
                     clear filter
                  </Button>
               </div>

               <div>
                  <Button
                     variant="primary"
                     type="button"
                     onClick={onSubmit}
                     isLoading={isLoading}
                     disabled={isLoading}
                     fullWidth
                  >
                     Apply
                  </Button>
               </div>
            </InputWrapper>
         </form>
      </Modal>
   );
};

export default TicketFilterModal;

TicketFilterModal.propTypes = {
   open: bool.isRequired,
   onClose: func.isRequired,
   setCurrentPage: func.isRequired,
   filterData: func.isRequired,
   setFilterData: func.isRequired,
   setFilterPayload: func.isRequired,
   filterPayload: objectOf(string).isRequired,
};
