/* eslint-disable no-else-return */
import { useEffect, useState } from 'react';
import Modal from 'components/modal';
import { bool, func, number, objectOf, string } from 'prop-types';
import { toast } from 'react-hot-toast';
import { confirm } from 'components/confirm-box';
import {
   firstLoanApproval,
   finalLoanApproval,
   fetchLoanRepaymentHistory,
   loanWorkerTracker,
   getPendingLoanStream,
} from 'services/loans';
import { getBusinessDetails } from 'services/businessDetails';
import { Button } from 'components/button';
import { useAuth } from 'hooks/auth';
import BusinessOverview from 'containers/business-details/overview';
import Accounts from 'containers/business-details/accounts';
import LoanOverview from './loan-overview';
import BusinessReference from './business-reference';
import Repayments from './repayments';
import RequestedLoans from './requested-loans';
import Transactions from '../business-details/transactions';
import Notes from '../business-details/notes';
import { Navigation, NavigationTab } from './style';

const BusinessDetails = ({
   open,
   onClose,
   businessAccountId,
   loan,
   workerActiveStatus,
   workerDateRange,
}) => {
   const [activeTab, setActiveTab] = useState('loan-overview');
   const { currentUser } = useAuth();
   const [loanDetails, setLoanDetails] = useState('');
   const [repaymentHistory, setRepaymentHistory] = useState('');
   const [isApproving, setIsApproving] = useState(false);
   const [isRejecting, setIsRejecting] = useState(false);
   const [approveBtnText, setApproveBtnText] = useState('');
   const [NumberReferenceApproved, setNumberReferenceApproved] = useState(0);
   const [newLoan, setNewLoan] = useState(false);
   const [newBusiness] = useState(false);
   const { permissions } = currentUser?.access || {};

   async function initialize() {
      setLoanDetails([]);
      try {
         const result = await fetchLoanRepaymentHistory(newLoan || loan);
         const biz = await getBusinessDetails(newBusiness || businessAccountId);
         setLoanDetails(result.data[0].loan_details);
         const e = biz.data.biz_reference_details.filter((item) => item.ref_status === 'approved');
         setNumberReferenceApproved(e.length);
         setRepaymentHistory(result.data[0].repayment_history);
      } catch ({ message }) {
         toast.error(message);
      }
   }

   useEffect(() => {
      initialize();
   }, [newLoan]);

   const activePage = () => {
      switch (activeTab) {
         case '':
         case 'loan-overview':
            return <LoanOverview businessAccountId={businessAccountId} loanDetails={loanDetails} />;

         case 'notes':
            return <Notes businessAccountId={businessAccountId} />;

         case 'business-overview':
            return <BusinessOverview businessAccountId={businessAccountId} />;

         case 'accounts':
            return <Accounts businessAccountId={businessAccountId} userData={currentUser} />;

         case 'repayments':
            return <Repayments repaymentHistory={repaymentHistory} />;

         case 'requested-loans':
            return <RequestedLoans businessAccountId={businessAccountId} />;

         case 'biz_reference':
            return (
               <BusinessReference businessAccountId={businessAccountId} initialize={initialize} />
            );
         case 'transactions':
            return <Transactions businessAccountId={businessAccountId} />;

         default:
            return 'No Screen';
      }
   };

   const handleFirstApproval = async (status) => {
      confirm({
         confirmText: 'Are you sure you want to perform this action?',
         isLoading: isApproving,
         onConfirm: async () => {
            if (status === 'pre_approved') {
               setIsApproving(true);
            } else if (status === 'rejected') {
               setIsRejecting(true);
            }
            try {
               const result = await firstLoanApproval({
                  biz_loan_id: loanDetails.biz_loan_id,
                  biz_loan_decision: status,
               });
               toast.success(result.message);
               await initialize();
            } catch (e) {
               toast.error(e.message);
            }
            if (status === 'pre_approved') {
               setIsApproving(false);
            } else if (status === 'rejected') {
               setIsRejecting(false);
            }
         },
      });
   };

   const handleFinalApproval = async (status) => {
      confirm({
         confirmText: 'Are you sure you want to perform this action?',
         isLoading: isApproving,
         onConfirm: async () => {
            if (status === 'approved') {
               setIsApproving(true);
            } else if (status === 'rejected') {
               setIsRejecting(true);
            }
            try {
               const result = await finalLoanApproval({
                  biz_loan_id: loanDetails.biz_loan_id,
                  biz_loan_decision: status,
               });
               toast.success(result.message);
               await initialize();
            } catch (e) {
               toast.error(e.message);
            }
            if (status === 'approved') {
               setIsApproving(false);
            } else if (status === 'rejected') {
               setIsRejecting(false);
            }
         },
      });
   };

   const runTracker = async () => {
      await loanWorkerTracker({
         biz_loan_id: loan,
         stream_type: 'work_loan_pending',
         action_type: 'single',
      });
   };

   const workerActions = () => (
      <div className="d-flex align-items-end">
         <Button
            variant="primary"
            className="me-3"
            onClick={() => {
               confirm({
                  confirmText: 'Are you sure you want to go to next loan?',
                  isLoading: false,
                  onConfirm: async () => {
                     try {
                        runTracker();
                        const stream = await getPendingLoanStream(workerDateRange);
                        if (!stream.data)
                           return toast.error('No business is currently in this time range stream');
                        setNewLoan(stream.data.biz_loan_id);
                        // toast.success(tracker.message)
                     } catch (e) {
                        toast.error(e?.message);
                     }
                  },
               });
            }}
            // isLoading={isApproving}
         >
            Next Loan
         </Button>
         <Button
            variant="danger"
            onClick={() => {
               confirm({
                  confirmText: 'Are you sure you want to end work?',
                  isLoading: false,
                  onConfirm: async () => {
                     try {
                        runTracker();
                        onClose();
                        setActiveTab('none');
                        toast.success('You have ended work successfully');
                     } catch ({ message }) {
                        toast.error(message);
                     }
                  },
               });
            }}
         >
            End Work
         </Button>
      </div>
   );

   // const renderActions = () => {
   //    let template = '';
   //    if (loanDetails.loan_status === 'pending' && currentUser.user_type !== 'prospasuperadmin') {
   //       template = (
   //          <>
   //             <Button
   //                variant="primary"
   //                className="me-3"
   //                onClick={() =>
   //                   NumberReferenceApproved > 1
   //                      ? handleFirstApproval('pre_approved')
   //                      : toast.error('Business has less than 2 references approved')
   //                }
   //                isLoading={isApproving}
   //             >
   //                Approve
   //             </Button>
   //             <Button
   //                variant="danger"
   //                onClick={() => handleFirstApproval('rejected')}
   //                isLoading={isRejecting}
   //             >
   //                Reject
   //             </Button>
   //          </>
   //       );
   //    } else if (
   //       loanDetails.loan_status === 'pending' &&
   //       currentUser.user_type === 'prospasuperadmin'
   //    ) {
   //       template = (
   //          <>
   //             <Button
   //                variant="primary"
   //                className="me-2"
   //                onClick={() =>
   //                   NumberReferenceApproved > 1
   //                      ? handleFinalApproval('approved')
   //                      : toast.error('Business has less than 2 references approved')
   //                }
   //                isLoading={isApproving}
   //             >
   //                Approve &amp; Disburse
   //             </Button>
   //             <Button
   //                variant="danger"
   //                onClick={() => handleFinalApproval('rejected')}
   //                isLoading={isRejecting}
   //             >
   //                Reject
   //             </Button>
   //          </>
   //       );
   //    } else if (
   //       loanDetails.loan_status === 'pre_approved' &&
   //       currentUser.user_type === 'prospasuperadmin'
   //    ) {
   //       template = (
   //          <>
   //             <Button
   //                variant="primary"
   //                className="me-3"
   //                onClick={() =>
   //                   NumberReferenceApproved > 1
   //                      ? handleFinalApproval('approved')
   //                      : toast.error('Business has less than 2 references approved')
   //                }
   //                isLoading={isApproving}
   //             >
   //                Disburse
   //             </Button>
   //             <Button
   //                variant="danger"
   //                onClick={() => handleFinalApproval('rejected')}
   //                isLoading={isRejecting}
   //             >
   //                Reject
   //             </Button>
   //          </>
   //       );
   //    }
   //    return loanDetails?.biz_name ? template : '';
   // };

   const preapprover = permissions?.includes('pre_approve_or_reject_loans');
   const disburser = permissions?.includes('disburse_loans') || currentUser?.permissions === 'all';
   const isSuper = permissions === 'all';
   const fullLoanAccess = disburser || isSuper;

   useEffect(() => {
      const { loan_status } = loanDetails;

      if (loan_status === 'pre_approved' && fullLoanAccess) {
         setApproveBtnText('Disburse');
      } else if (loan_status === 'pending' && fullLoanAccess) {
         setApproveBtnText('Preapprove & Disburse');
      } else if (loan_status === 'pending' && preapprover) {
         setApproveBtnText('Preapprove');
      } else {
         setApproveBtnText('Preapprove');
      }
   }, [permissions, loanDetails]);

   const handleApplication = (type) => {
      const { loan_status } = loanDetails;
      const isUnapproved = loan_status === 'pending' || loan_status === 'pre_approved';

      if (NumberReferenceApproved < 2 && type === 'approve')
         return toast.error('Business has less than 2 references approved');

      if (isUnapproved && type === 'approve' && fullLoanAccess) {
         handleFinalApproval('approved');
      } else if (isUnapproved && type === 'reject' && fullLoanAccess) {
         handleFinalApproval('rejected');
      } else if (loan_status === 'pending' && type === 'approve' && preapprover) {
         handleFirstApproval('pre_approved');
      } else if (loan_status === 'pending' && type === 'reject' && preapprover) {
         handleFirstApproval('rejected');
      }
   };

   const renderActionBtns = () => {
      const { loan_status } = loanDetails;
      const isUnapproved = loan_status === 'pending' || loan_status === 'pre_approved';

      if (
         (loan_status === 'pending' && preapprover) ||
         (isUnapproved && disburser) ||
         (isUnapproved && isSuper)
      )
         return (
            <>
               <Button
                  variant="primary"
                  className="me-3"
                  onClick={() => handleApplication('approve')}
                  isLoading={isApproving}
               >
                  {approveBtnText}
               </Button>
               <Button
                  variant="danger"
                  onClick={() => handleApplication('reject')}
                  isLoading={isRejecting}
               >
                  Reject
               </Button>
            </>
         );
   };

   return (
      <Modal
         open={open}
         onClose={() => {
            onClose();
            setActiveTab('none');
         }}
         closeOnOverlayClick={false}
         title="Business Account Details"
         className="biz-details-modal"
      >
         <div className="d-flex justify-content-between">
            <Navigation>
               <NavigationTab
                  className={activeTab === 'loan-overview' ? 'active' : ''}
                  onClick={() => setActiveTab('loan-overview')}
               >
                  Loan Overview
               </NavigationTab>
               <NavigationTab
                  className={activeTab === 'business-overview' ? 'active' : ''}
                  onClick={() => setActiveTab('business-overview')}
               >
                  Business Overview
               </NavigationTab>
               <NavigationTab
                  className={activeTab === 'accounts' ? 'active' : ''}
                  onClick={() => setActiveTab('accounts')}
               >
                  Accounts
               </NavigationTab>
               <NavigationTab
                  className={activeTab === 'notes' ? 'active' : ''}
                  onClick={() => setActiveTab('notes')}
               >
                  Notes
               </NavigationTab>

               <NavigationTab
                  className={activeTab === 'transactions' ? 'active' : ''}
                  onClick={() => setActiveTab('transactions')}
               >
                  Transactions
               </NavigationTab>
               <NavigationTab
                  className={activeTab === 'repayments' ? 'active' : ''}
                  onClick={() => setActiveTab('repayments')}
               >
                  Repayments
               </NavigationTab>

               <NavigationTab
                  className={activeTab === 'biz_reference' ? 'active' : ''}
                  onClick={() => setActiveTab('biz_reference')}
               >
                  Business Reference
               </NavigationTab>

               <NavigationTab
                  className={activeTab === 'requested-loans' ? 'active' : ''}
                  onClick={() => setActiveTab('requested-loans')}
               >
                  Requested Loans
               </NavigationTab>
            </Navigation>
            {workerActiveStatus ? (
               workerActions()
            ) : (
               <div className="d-flex align-items-end">
                  {/* {renderActions()} */}
                  {renderActionBtns()}
               </div>
            )}
         </div>

         <div className="pt-5">{activePage()}</div>
         {workerActiveStatus && (
            <div className="d-flex align-items-end justify-content-end">
               {/* {renderActions()} */}
               {renderActionBtns()}
            </div>
         )}
      </Modal>
   );
};

BusinessDetails.propTypes = {
   open: bool.isRequired,
   loan: number.isRequired,
   onClose: func.isRequired,
   businessAccountId: number.isRequired,
   workerActiveStatus: bool.isRequired,
   workerDateRange: objectOf(string).isRequired,
};

export default BusinessDetails;
