import styled from 'styled-components/macro';

export const DocumentsWrapper = styled.div`
   .approve-btn {
      background-color: #198754;
   }

   .director-icon {
      margin-right: 0.3rem;
      font-size: 20px;
   }

   .doc-btn {
      padding: 1.2rem;
      min-height: initial;
      margin-top: 1rem;
   }

   .upload-btn {
      @media (max-width: 600px) {
         display: block !important;
         display: none;
      }
   }

   .file-input {
      box-shadow: none;
      &::-webkit-file-upload-button {
         min-height: 50px;
      }

      @media (max-width: 600px) {
         display: block !important;
      }
   }
`;

export const DocumentSection = styled.div`
   .doc-label {
      margin-right: 1rem;
      font-weight: 600;
   }

   border-bottom: 1px solid #d7dce0;
   padding-bottom: 2rem;
   margin-bottom: 2rem;
`;

export const DocumentHeading = styled.div`
   display: flex;
   align-items: center;
`;

export const DocumentControls = styled.div`
   display: flex;
   align-items: center;

   @media (max-width: 365px) {
      display: initial;
   }
`;
