import React from 'react';
import PropTypes from 'prop-types';
import { Status } from 'styles/utils';
import { BankAccountWrapper } from './style';

export default function OtherBusinessAccountCard({ otherBusinessDetails, fetchBusinessDetails }) {
   const { biz_friendly_name, biz_name, biz_status, biz_account_id, biz_type } =
      otherBusinessDetails;
   return (
      <BankAccountWrapper style={{ background: '#eee3fd' }}>
         <h3 className="me-3 mb-4 account-type d-flex align-items-center justify-content-between">
            <strong> Business Name</strong>
            <span className="click-link" onClick={() => fetchBusinessDetails(biz_account_id)}>
               {biz_name || biz_friendly_name}
            </span>
         </h3>

         <h3 className="me-3 account-type d-flex align-items-center justify-content-between mb-4">
            <strong> Business Type</strong>
            <span className="click-link" onClick={() => fetchBusinessDetails(biz_account_id)}>
               {biz_type}
            </span>
         </h3>
         <div className="d-flex align-items-center justify-content-between">
            <strong>Status</strong>
            <Status className="m-0" status={biz_status}>
               {biz_status}
            </Status>
         </div>
      </BankAccountWrapper>
   );
}

OtherBusinessAccountCard.propTypes = {
   // eslint-disable-next-line react/forbid-prop-types
   otherBusinessDetails: PropTypes.objectOf(PropTypes.any).isRequired,
   fetchBusinessDetails: PropTypes.func.isRequired,
};
