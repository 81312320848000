import { Http } from 'services/http';
import { logActivity } from 'services/activity';
import { paramsObjectToQueryString } from 'utils/others';

export const getBuildPartnerPerformance = (page) =>
   Http(`/backmen/list_build_partner${paramsObjectToQueryString(page)}`);

export const getBuildPartnerApprovedAccounts = (payload = {}) =>
   Http(`/backmen/list_bizacct_view/${paramsObjectToQueryString(payload)}`);

export const getBuildPartnerAdminApprovedAccounts = (id, page) =>
   Http(`/backmen/list_businessbuild_partner/${117}/${paramsObjectToQueryString(page)}`);

export const getBuildPartnerMonthlyDeposit = (payload) =>
   Http(`/backmen/month_on_month_build_deposit/`, {
      method: 'POST',
      body: payload,
   });

export const getBuildPartnerWeeklyDeposit = (payload, id) =>
   Http(`/backmen/weekly_total_deposit_biz_partner/${id}`, {
      method: 'POST',
      body: payload,
   });

export const getBuildPartnerBusinessDeposit = (payload = {}) =>
   Http(`/backmen/monthly_deposit_volume${paramsObjectToQueryString(payload)}`);

export const getActivationPerformance = (payload = {}) =>
   Http(`/backmen/list_activation_manager${paramsObjectToQueryString(payload)}`);

export const updateContactStatus = (payload, biz_account_id) => {
   logActivity(`Updated Contact Status to ${payload.biz_contact_status}`, 'reg', biz_account_id);
   return Http(`/backmen/manual_biz_contact_stat_update/`, {
      method: 'POST',
      body: payload,
   });
};

export const getBuildPartnerAssignedStats = (payload = {}) =>
   Http(`/backmen/biz_deposit_volume_stats${paramsObjectToQueryString(payload)}`);

export const assignBuildParter = (payload) =>
   Http(`/backmen/psp_assign_buildpartner/`, {
      method: 'POST',
      body: payload,
      action: '/assign_build_partners',
   });
