import styled from 'styled-components';

export const ConfirmModalWrapper = styled.div`
   background-color: #fff;
   border-radius: 10px;
   box-shadow: -1px 2px 20px rgba(0, 0, 0, 0.1);
   padding: 30px;
   max-width: 500px;
   min-width: 400px;
   text-align: center;

   .text {
      font-weight: 500;
      font-size: 1.7rem;
   }

   .btn-container {
      margin-top: 30px;
   }

   span {
      /* margin-bottom: 30px; */
   }
`;
