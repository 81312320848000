import React, { useState } from 'react';
import PropTypes, { func } from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { Button } from 'components/button';
import {
   AvatarWrapper,
   InfoGroup,
   Label,
   OverviewSection,
   SectionTitle,
   Value,
   TopSection,
} from 'styles/layout';
import { Status } from 'styles/utils';
import AddChildCampaign from '../add-child-campaign';

export const ChildCampaign = ({
   campaignDetails,
   childCampaignDetails,
   fetchCampaigns,
   setOverviewModalVisibility,
}) => {
   const [singleChildCampaignDetail, setSingleChildCampaignDetail] = useState({});
   const [newChildCampaign, setNewChildCampaign] = useState(false);
   const [editChildCampaign, setEditChildCampaign] = useState(false);

   return (
      <>
         <TopSection>
            <SectionTitle>All Child Campaigns</SectionTitle>
            <div className="d-flex justify-content-end">
               <Button
                  onClick={() => {
                     setNewChildCampaign(true);
                     setOverviewModalVisibility('hidden');
                  }}
               >
                  Create New Child Campaign
               </Button>
            </div>
         </TopSection>
         {childCampaignDetails?.map((child_campaign, i) => (
            <div key={uuidv4()}>
               <h3>Child Campaign {i + 1}</h3>
               <OverviewSection className="director-section">
                  <InfoGroup>
                     <Label>Location</Label>
                     <Value>{child_campaign.child_location || 'N/A'}</Value>
                  </InfoGroup>
                  <InfoGroup>
                     <Label>Button</Label>
                     <Value>{child_campaign.child_button || 'N/A'}</Value>
                  </InfoGroup>
                  <InfoGroup>
                     <Label>Status</Label>
                     <Status status={child_campaign.child_status}>
                        {child_campaign.child_status || 'N/A'}
                     </Status>
                  </InfoGroup>
                  <div>
                     <Button
                        variant="secondary"
                        onClick={() => {
                           setEditChildCampaign(true);
                           setOverviewModalVisibility('hidden');
                           setSingleChildCampaignDetail(childCampaignDetails[i]);
                        }}
                     >
                        Edit Child Campaign
                     </Button>
                  </div>
                  <InfoGroup>
                     <Label>Type</Label>
                     <Value>{child_campaign.child_type || 'N/A'}</Value>
                  </InfoGroup>
                  <AvatarWrapper>
                     <img src={child_campaign.child_image} alt="campaign-pic" />
                  </AvatarWrapper>
               </OverviewSection>

               {editChildCampaign && (
                  <AddChildCampaign
                     isEdit
                     open={editChildCampaign}
                     childCampaignDetails={singleChildCampaignDetail}
                     campaignDetails={campaignDetails}
                     fetchCampaigns={fetchCampaigns}
                     onClose={() => {
                        setEditChildCampaign(false);
                        setOverviewModalVisibility('visible');
                     }}
                  />
               )}
            </div>
         ))}
         {newChildCampaign && (
            <AddChildCampaign
               open={newChildCampaign}
               campaignDetails={campaignDetails}
               childCampaignDetails={childCampaignDetails}
               fetchCampaigns={fetchCampaigns}
               onClose={() => {
                  setNewChildCampaign(false);
                  setOverviewModalVisibility('visible');
               }}
            />
         )}
      </>
   );
};

ChildCampaign.propTypes = {
   campaignDetails: PropTypes.objectOf(PropTypes.string).isRequired,
   childCampaignDetails: PropTypes.objectOf(PropTypes.string).isRequired,
   fetchCampaigns: func.isRequired,
   setOverviewModalVisibility: func.isRequired,
};

export default ChildCampaign;
