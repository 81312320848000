import { useState } from 'react';
import { useAuth } from 'hooks/auth';
import { useHistory, useLocation } from 'react-router-dom';
import {
   admin_can_view_exported_data,
   can_view_card_transactions,
   can_view_queued_transfers,
} from 'utils/user_actions';
import { hasPermission } from 'utils/user_access';
import LoggedInDashboard from 'components/logged-in-dashboard';
import TabMenu from 'components/TabMenu';
import { TabWrapper } from 'pages/pending-documents/style';
import ExportedData from 'components/exported-data';
import AllTransactions from './all-transactions';
import AllCardTransactions from './card-transactions';
import PendingTransactions from './pending-transactions';

function Transactions() {
   const { pathname } = useLocation();
   const [activeTab, setActiveTab] = useState('transactions');
   const history = useHistory();
   const { currentUser } = useAuth();
   const { permissions } = currentUser?.access || {};

   const handleActiveTab = (value) => {
      if (value !== activeTab) {
         history.push(pathname);
      }
      setActiveTab(value);
   };

   const tabs = [
      {
         label: 'Transactions',
         value: 'transactions',
         permission: true,
      },
      {
         label: 'Card Transactions',
         value: 'card-transactions',
         permission: hasPermission(permissions, can_view_card_transactions),
      },
      {
         label: 'Data Exports',
         value: 'exports',
         permission: hasPermission(permissions, admin_can_view_exported_data),
      },
      {
         label: 'Pending Transactions',
         value: 'pending-transactions',
         permission: hasPermission(permissions, can_view_queued_transfers),
      },
   ];

   const exportFilters = [
      {
         label: 'Start Date',
         key: 'start_date',
         type: 'date',
      },
      {
         label: 'End Date',
         key: 'end_date',
         type: 'date',
      },
   ];

   const exportTypes = [
      {
         value: 'export_transactions_data',
         label: 'Transactions Data (Table)',
         downloadPath: 'admin_txn_history_export',
      },
      {
         value: 'export_all_transactions',
         label: 'NIBSS transactions',
         downloadPath: 'admin_nibss_exports',
         filters: exportFilters,
      },
   ];

   return (
      <LoggedInDashboard pageTitle="Transactions">
         <TabWrapper>
            <ul className="m-0 d-flex align-items-center">
               {tabs?.map(({ label, value, permission }) => {
                  if (permission) {
                     return (
                        <TabMenu
                           key={value}
                           text={label}
                           active={activeTab === value ? 'active' : ''}
                           setActiveTab={() => {
                              handleActiveTab(value);
                           }}
                        />
                     );
                  }
                  return null;
               })}
            </ul>
         </TabWrapper>
         {activeTab === 'transactions' && <AllTransactions exportTypes={exportTypes} />}
         {activeTab === 'exports' && <ExportedData exportTypes={exportTypes} />}
         {activeTab === 'card-transactions' && <AllCardTransactions exportTypes={exportTypes} />}
         {activeTab === 'pending-transactions' && <PendingTransactions />}
      </LoggedInDashboard>
   );
}

export default Transactions;
