import { useState } from 'react';
import { Link } from 'react-router-dom';
import {
   userSearch,
   transactionSearch,
   businessSearch,
   registrationsSearch,
} from 'services/business';
import { useDebouncedCallback } from 'use-debounce';
import { func, bool } from 'prop-types';
import { useAuth } from 'hooks/auth';
import { useBizDetailsModal } from 'hooks/bizDetailsModal';
import { hasPermission } from 'utils/user_access';
import { can_upload_csv } from 'utils/user_actions';
import MenuImg from 'assets/images/menu.svg';
import CancelImg from 'assets/images/cancel.svg';
import BusinessRegDetails from 'containers/business-reg-details';
import BusinessDetails from 'containers/business-details';
import TransactionModal from 'components/TransactionModal';
import { Button } from 'components/button';
import { HeaderWrapper } from './style';
import UploadCSVModal from './upload-csv';

function Header({ setSideBar, sideBar }) {
   const { setActiveTab } = useBizDetailsModal();
   const { currentUser } = useAuth();
   const { permissions } = currentUser?.access || {};
   const [search, setSearch] = useState('');
   const [searchLoading, setSearchLoading] = useState(false);
   const [searchType, setSearchType] = useState('business');
   const [modalVisibility, setModalVisibility] = useState(false);
   const [regModalVisibility, setRegModalVisibility] = useState(false);
   const [transactionModal, setTransactionModal] = useState(false);
   const [bizRegAccount, setRegBizAccount] = useState('');
   const [bizAccount, setBizAccount] = useState('');
   const [searchResult, setSearchResult] = useState([]);
   const [transactionDetail, setTransactionDetail] = useState('');
   const [uploadCsv, setUploadCsv] = useState(false);

   const handleSearch = async (value, inputSearchType) => {
      if (value.length < 3) {
         setSearch(value);
         return setSearchResult([]);
      }
      try {
         setSearchLoading(true);
         if (inputSearchType === 'member') {
            const { data } = await userSearch(value);
            setSearchResult(data);
         } else if (inputSearchType === 'transaction') {
            const { data } = await transactionSearch(value);
            setSearchResult(data);
         } else if (inputSearchType === 'business') {
            const { data } = await businessSearch(value);
            setSearchResult(data);
         } else if (inputSearchType === 'registrations') {
            const { data } = await registrationsSearch(value);
            setSearchResult(data);
         }
      } catch (e) {
         return e;
      }
      setSearchLoading(false);
   };

   const debounced = useDebouncedCallback((value) => {
      handleSearch(value, searchType);
   }, 1000);

   const openBusiness = (id) => {
      setBizAccount(id);
      setModalVisibility(true);
      setActiveTab('overview');
   };

   const openBusinessRegistration = (id) => {
      setRegBizAccount(id);
      setActiveTab('overview');
      setRegModalVisibility(true);
   };

   const onTransactionClick = (id, data) => {
      setTransactionModal(id);
      setTransactionModal(true);
      setTransactionDetail(data);
   };

   const handleCsvUpload = () => {
      setUploadCsv(true);
   };

   const renderSearchResults = () => {
      if (searchLoading) return <p>Searching...</p>;
      return searchResult.length > 0 ? (
         searchResult.map((item) => {
            const {
               biz_friendly_name,
               biz_account_id,
               biz_status,
               biz_related_meta,
               pref_biz_name_one,
               pref_biz_name_two,
               pref_biz_name_three,
               payment_status,
               tx_note,
               trans_amount,
               unregistered_biz_id,
               transaction_id,
               email,
               transfer_details,
            } = item;
            let template = '';

            if (biz_related_meta && biz_related_meta[0].biz_friendly_name) {
               template = (
                  <>
                     <h4
                        onClick={() => openBusiness(biz_related_meta[0].biz_account_id)}
                        className="name text-primary"
                     >
                        <strong>{biz_related_meta[0].biz_friendly_name}</strong>
                     </h4>
                     <p className="biz-status">{biz_related_meta[0].biz_status}</p>
                  </>
               );
            } else if (biz_friendly_name) {
               template = (
                  <>
                     <h4
                        onClick={() => openBusiness(biz_account_id)}
                        className="name search-result-title text-primary"
                     >
                        {' '}
                        <strong>{biz_friendly_name}</strong>
                     </h4>
                     <p className="biz-status">{biz_status}</p>
                  </>
               );
            } else if (pref_biz_name_one) {
               template = (
                  <>
                     <h4
                        onClick={() => openBusinessRegistration(unregistered_biz_id)}
                        className="name search-result-title text-primary"
                     >
                        {' '}
                        <strong>
                           {pref_biz_name_one} / {pref_biz_name_two} / {pref_biz_name_three}
                        </strong>
                     </h4>
                     <p className="biz-status">{payment_status}</p>
                  </>
               );
            } else if (trans_amount) {
               template = (
                  <>
                     <h4
                        onClick={() => onTransactionClick(transaction_id, item)}
                        className="name search-result-title text-primary"
                     >
                        {' '}
                        <strong>₦{trans_amount}</strong>
                     </h4>
                     {transfer_details &&
                        transfer_details.transfer_stack_ref &&
                        transfer_details.transfer_stack_ref !== '' && (
                           <p className="biz-status">
                              session id: {transfer_details.transfer_stack_ref}
                           </p>
                        )}
                     <p className="biz-status">{tx_note}</p>
                  </>
               );
            } else {
               template = (
                  <>
                     <strong>User does not have a business account</strong>
                     <p className="biz-status">{email}</p>
                  </>
               );
            }

            return <li key={biz_account_id}>{template}</li>;
         })
      ) : (
         <p>
            Oops, no result found for <strong>{search}</strong>
         </p>
      );
   };

   return (
      <>
         <HeaderWrapper className="navbar flex-md-nowrap p-0 shadow py-4 bg-white">
            <div className="d-flex justify-content-between logo-container px-4">
               <Link
                  to="/"
                  className="navbar-brand col-md-3 col-lg-2 me-0 d-flex align-items-center mb-4 mb-md-0"
               >
                  <img
                     src="https://psp-zenvault.s3.eu-west-2.amazonaws.com/static/assets/img/prospa-logo3.svg"
                     alt="Prospa Logo"
                  />{' '}
               </Link>
               <div className="nav-icons d-md-none">
                  {sideBar ? (
                     <img src={CancelImg} alt="cancel" className="close-nav" onClick={setSideBar} />
                  ) : (
                     <img src={MenuImg} alt="menu" className="open-nav" onClick={setSideBar} />
                  )}
               </div>
            </div>
            {(permissions?.includes('business_search') || permissions === 'all') && (
               <div className="d-flex justify-content-center align-items-center">
                  <form autoComplete="off">
                     <div className="row container pe-2 pe-md-4">
                        <div className="col-6 pe-0 search-term-container">
                           <input
                              className="form-control form-control-dark w-100"
                              id="q"
                              type="text"
                              placeholder="Search business accounts, payments..."
                              aria-label="Search"
                              value={search}
                              onChange={({ target }) => {
                                 setSearch(target.value);
                                 setSearchLoading(true);
                                 debounced(target.value);
                              }}
                           />
                           {(searchResult.length > 0 || search.length > 2) && (
                              <div className="search-result p-4">
                                 <ul className="">{renderSearchResults()}</ul>
                              </div>
                           )}
                        </div>
                        <div className="col-6 pe-0">
                           <select
                              className="form-select"
                              id="q-type"
                              style={{ color: '#777' }}
                              value={searchType}
                              onChange={({ target }) => {
                                 setSearchType(target.value);
                                 handleSearch(search, target.value);
                              }}
                           >
                              <option value="business">Search for Business</option>
                              <option value="member">Search for Prospa member</option>
                              <option value="transaction">Search for Transaction</option>
                              <option value="registrations">Search for Registrations</option>
                           </select>
                        </div>
                     </div>
                  </form>
                  {hasPermission(permissions, can_upload_csv) && (
                     <Button
                        size="small"
                        variant="secondary"
                        className="me-3"
                        onClick={handleCsvUpload}
                     >
                        Upload CSV
                     </Button>
                  )}
               </div>
            )}
         </HeaderWrapper>
         {modalVisibility && (
            <BusinessDetails
               businessAccountId={bizAccount}
               open={modalVisibility}
               onClose={() => setModalVisibility(false)}
            />
         )}
         {regModalVisibility && (
            <BusinessRegDetails
               businessAccountId={bizRegAccount}
               open={regModalVisibility}
               onClose={() => setModalVisibility(false)}
            />
         )}

         {uploadCsv && <UploadCSVModal open={uploadCsv} onClose={() => setUploadCsv(false)} />}

         <TransactionModal
            open={transactionModal}
            onClose={() => setTransactionModal(false)}
            data={transactionDetail}
         />
      </>
   );
}

Header.propTypes = {
   setSideBar: func.isRequired,
   sideBar: bool.isRequired,
};

export default Header;
