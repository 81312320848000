import React, { useState } from 'react';
import PropTypes, { func } from 'prop-types';
import moment from 'moment';
import { toast } from 'react-hot-toast';
import { Status } from 'styles/utils';
import { SelectInput } from 'components/select';
import {
   AvatarWrapper,
   Info,
   TopSection,
   UserProfile,
   SectionTitle,
   OverviewSection,
   InfoGroup,
   Label,
   Value,
   Name,
   OverviewWrapper,
} from 'styles/layout';
import Avatar from 'assets/images/user.svg';
import { editCardDetails } from 'services/cards';
import { CARD_DELIVERY_STATUS } from 'utils/card-constants';
import { Button } from 'components/button';
import { confirm } from 'components/confirm-box';

const PhysicalCardOverview = ({ cardDetails, bizDetails, fetchCard }) => {
   const { account_holder_details } = bizDetails || {};
   const phone = account_holder_details?.phone;
   const email = account_holder_details?.email;
   const userImage = bizDetails?.account_holder_details?.photo_url || Avatar;

   const {
      id,
      business_name,
      created,
      order_id,
      status,
      delivery_date,
      delivery_address,
      delivery_status,
      design,
      order_total,
      card_type,
      estimated_delivery_date,
   } = cardDetails || {};

   const [cardDeliveryStatus, setCardDeliveryStatus] = useState(delivery_status);

   const handleSubmit = () => {
      confirm({
         confirmText: 'Are you sure want to update the delivery Status?',
         onConfirm: async () => {
            const cardData = {
               request_status: cardDeliveryStatus,
            };

            try {
               const editDetails = await editCardDetails(cardData, id);
               if (editDetails.ok) {
                  fetchCard();
                  toast.success(editDetails?.message || 'Details Successfully Updated', {
                     duration: 3000,
                  });
               }
            } catch (err) {
               toast.error(err.message || err.custom_message);
            }
         },
      });
   };

   return (
      <>
         <TopSection className="pt-4">
            <UserProfile>
               <AvatarWrapper>
                  <img src={userImage} alt="account-holder-avatar" />
               </AvatarWrapper>
               <div>
                  <Info
                     style={{
                        color: 'var(--spn-700, #3A434B)',
                        fontSize: '12px',
                     }}
                  >
                     #{order_id}
                  </Info>
                  <Name
                     style={{
                        color: '#1354D3',
                        fontSize: '12px',
                     }}
                  >
                     {business_name}
                  </Name>
                  <Status status={status}>{status}</Status>
               </div>
            </UserProfile>

            {delivery_status !== 'delivered' && card_type === 'physical' && (
               <div className="d-flex gap-3">
                  <div style={{ width: '200px' }}>
                     <SelectInput
                        placeholder={cardDeliveryStatus}
                        options={CARD_DELIVERY_STATUS}
                        onChange={(e) => setCardDeliveryStatus(e.value)}
                     />
                  </div>
                  <div>
                     <Button variant="pink" onClick={handleSubmit}>
                        Update
                     </Button>
                  </div>
               </div>
            )}
         </TopSection>
         <OverviewWrapper>
            <div className="mt-5">
               <SectionTitle>Order Details</SectionTitle>
               <OverviewSection>
                  <InfoGroup>
                     <Label>Full Name</Label>
                     <Value>{delivery_address?.name}</Value>
                  </InfoGroup>
                  <InfoGroup>
                     <Label>Mobile Number</Label>
                     <Value>{phone}</Value>
                  </InfoGroup>
                  <InfoGroup>
                     <Label>Delivery Option</Label>
                     <Value>Standard</Value>
                  </InfoGroup>
                  <InfoGroup>
                     <Label>Order Total</Label>
                     <Value>{order_total}</Value>
                  </InfoGroup>
                  <InfoGroup>
                     <Label>Email Address</Label>
                     <Value>{email}</Value>
                  </InfoGroup>
                  <InfoGroup>
                     <Label>Delivery Address</Label>
                     <Value> {delivery_address?.street}</Value>
                     <Value>{delivery_address?.city}</Value>
                     <Value>{delivery_address?.state} </Value>
                  </InfoGroup>
                  <InfoGroup>
                     <Label>Order Date</Label>
                     <Value>{moment(created || '').format('MMM DD, YYYY')}</Value>
                  </InfoGroup>
                  <InfoGroup>
                     <Label>Est. Delivery Date</Label>
                     <Value>
                        {estimated_delivery_date
                           ? moment(estimated_delivery_date || '').format('MMM DD, YYYY')
                           : '-'}
                     </Value>
                  </InfoGroup>

                  <InfoGroup>
                     <Label>Card Color</Label>
                     <Value>{design?.name}</Value>
                  </InfoGroup>
                  <InfoGroup>
                     <Label>Actual Delivery Date</Label>
                     <Value>
                        {delivery_date ? moment(delivery_date || '-').format('MMM DD, YYYY') : '-'}
                     </Value>
                  </InfoGroup>
                  <InfoGroup>
                     <Label>Card Type</Label>
                     <Value>{card_type}</Value>
                  </InfoGroup>
                  {/* <InfoGroup>
                     <Label>Card Holder</Label>
                     <Value>
                        {delivery_date ? moment(delivery_date || '-').format('MMM DD, YYYY') : '-'}
                     </Value>
                  </InfoGroup> */}
               </OverviewSection>
            </div>
         </OverviewWrapper>
      </>
   );
};

PhysicalCardOverview.propTypes = {
   bizDetails: PropTypes.objectOf(PropTypes.string).isRequired,
   cardDetails: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
   fetchCard: func.isRequired,
};

export default PhysicalCardOverview;
