import { ProtectedRoute } from 'components/protected-route';
import { useAuth } from 'hooks/auth';
import { AnimatedRoutes } from 'components/route-animation/route-animation';
import BusinessPartner from 'pages/business-partner';
import Activation from 'pages/activation';
import FollowUp from 'pages/follow-up';
import Login from 'pages/login';
import Pending from 'pages/pending-documents';
import BuildPartner from 'pages/build-partner';
import Transactions from 'pages/transactions';
import NibssTransactions from 'pages/nibss-transactions';
import DebuggingTools from 'pages/debugging-tools';
import RequiresTin from 'pages/requires-tin';
import Preapproved from 'pages/preapproved-accounts';
import CacSearch from 'pages/cac-search';
import AssignBuildPartners from 'pages/assign-build-partners';
import Savings from 'pages/savings';
import { Redirect } from 'react-router-dom';
import BusinessRegistration from 'pages/business-registration';
import Loans from 'pages/loans';
import Partners from 'pages/partners';
import Pos from 'pages/pos';
import BusinessAccounts from 'pages/business-accounts';
import { NewAccountsDashboard } from 'pages/new-accounts';
import AdminManager from 'pages/admin-manager';
import ActivityLog from 'pages/activity-logs';
import { Cards } from 'pages/prospa-cards';
import Ticketing from 'pages/ticketing';
import Campaigns from 'pages/campaigns';
import Marketing from 'pages/marketing';
import SettingsAndCompliance from 'pages/settings-and-compliance';
import InternationalTransactions from 'pages/international-transactions';
import BillingDebtLogs from 'pages/billing-logs';
import ReferAndEarn from 'pages/refer-and-earn';

function Routes() {
   const { currentUser } = useAuth();

   return (
      <AnimatedRoutes exitBeforeEnter initial={false}>
         <ProtectedRoute path="/marketing" component={Marketing} exact />
         <ProtectedRoute path="/ticketing" component={Ticketing} exact />
         <ProtectedRoute path="/admin-manager" component={AdminManager} exact />
         <ProtectedRoute path="/pos" component={Pos} exact />
         <ProtectedRoute path="/assign-build-partners" component={AssignBuildPartners} exact />
         <ProtectedRoute path="/partners" component={Partners} exact />
         <ProtectedRoute path="/loans" component={Loans} exact />
         <ProtectedRoute path="/business-registrations" component={BusinessRegistration} exact />
         <ProtectedRoute path="/cac-business-search" component={CacSearch} exact />
         <ProtectedRoute path="/business-accounts" component={BusinessAccounts} exact />
         <ProtectedRoute path="/pre-approved-accounts" component={Preapproved} exact />
         <ProtectedRoute path="/debugging-tools" component={DebuggingTools} exact />
         <ProtectedRoute path="/requires-tin" component={RequiresTin} exact />
         <ProtectedRoute path="/build-partner" component={BuildPartner} exact />
         <ProtectedRoute path="/transactions" component={Transactions} exact />
         <ProtectedRoute path="/nibss-transactions" component={NibssTransactions} exact />
         <ProtectedRoute path="/refer-and-earn" component={ReferAndEarn} exact />
         <ProtectedRoute path="/settings-and-compliance" component={SettingsAndCompliance} exact />
         <ProtectedRoute
            path="/international-transactions"
            component={InternationalTransactions}
            exact
         />
         <ProtectedRoute path="/activity-log" component={ActivityLog} exact />
         <ProtectedRoute path="/corporate-cards" component={Cards} exact />
         <ProtectedRoute path="/savings" component={Savings} exact />
         <ProtectedRoute path="/billing-debt-logs" component={BillingDebtLogs} exact />
         <ProtectedRoute path="/campaigns" component={Campaigns} exact />
         <ProtectedRoute path="/new-accounts" component={NewAccountsDashboard} exact />

         <Redirect
            from="/"
            to={
               currentUser?.access?.permissions === 'all'
                  ? '/approver'
                  : currentUser?.access?.pageAccess[0]?.replaceAll('_', '-') || '/business-accounts'
            }
            exact
         />
         <ProtectedRoute notProtected path="/login" component={Login} exact />
         <ProtectedRoute path="/follow-up" component={FollowUp} exact />
         <ProtectedRoute path="/business-partner" component={BusinessPartner} exact />
         <ProtectedRoute path="/activation" component={Activation} exact />
         <ProtectedRoute path="/approver" component={Pending} exact />
      </AnimatedRoutes>
   );
}

export default Routes;
