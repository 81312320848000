import { useEffect, useState } from 'react';
import { func, number, objectOf, string } from 'prop-types';
import { toast } from 'react-hot-toast';
import { getActivePlan } from 'services/new-billing';
import { Status } from 'styles/utils';
import { Button } from 'components/button';
import { useAuth } from 'hooks/auth';
import { can_upgrade_account_plan } from 'utils/user_actions';
import { hasPermission } from 'utils/user_access';
import { InfoGroup, Label, Value } from '../utils/overview.style';

export default function PlanField({ bizDetails, changePlanFocus }) {
   const { currentUser } = useAuth();
   const { permissions } = currentUser?.access || {};
   const [activePlan, setActivePlan] = useState('');

   const { option } = activePlan || {};

   const hasAccess = hasPermission(permissions, can_upgrade_account_plan);

   const fetchActivePlan = async () => {
      try {
         const res = await getActivePlan(bizDetails?.biz_account_id);
         setActivePlan(res);
      } catch (err) {
         toast.error('Billing Plan not found' || err?.message);
      }
   };

   useEffect(() => {
      fetchActivePlan();
   }, []);

   const plan = () => {
      switch (option) {
         case 'BILLING__BILL_PAY_AS_YOU_GO':
            return `Pay As You Go Plan`;

         case 'BILLING__BILL_MEMBERS___GROWTH':
            return `Growth Plan `;

         case 'BILLING__BILL_MEMBERS___SCALE':
            return `Scale Plan `;
         default:
            return `${option} Plan`;
      }
   };

   return (
      <InfoGroup>
         <Label>Plan </Label>
         <Value>
            <Status status="pending" className="modal-plantype-text">
               <strong>{plan()}</strong>
            </Status>
            <div className="mt-3 d-flex">
               <Status className="py-1 me-2 ms-0" status={option ? 'accepted' : 'rejected'}>
                  {option ? 'active' : 'inactive'}
               </Status>
               {hasAccess && (
                  <Button
                     style={{
                        minHeight: '25px',
                        padding: '7px',
                        fontSize: '12px',
                        background: '#FA4A84',
                     }}
                     onClick={changePlanFocus}
                  >
                     Upgrade
                  </Button>
               )}
            </div>
         </Value>
      </InfoGroup>
   );
}

PlanField.propTypes = {
   bizDetails: objectOf(string, number).isRequired,
   changePlanFocus: func.isRequired,
};
