import styled from 'styled-components';

export const FilterContainer = styled.div`
   border: 1px solid #d7dce0;
   padding: 10px 16px;
   height: 54px;
   border-radius: 6px;
   height: 4.8rem;
   cursor: pointer;
   font-size: 14px;
   width: 200px;
   display: flex;
   align-items: center;
   justify-content: space-between;
`;
