export const ticketStatus = [
   {
      label: 'New',
      value: 'new',
   },
   {
      label: 'Open',
      value: 'open',
   },
   {
      label: 'Pending',
      value: 'pending',
   },
   {
      label: 'On-hold',
      value: 'on_hold',
   },
   {
      label: 'Closed',
      value: 'closed',
   },
];
