import { string } from 'prop-types';
import { Td } from './log.style';

const LogRow = ({ adminName, description, date, time }) => (
   <tr>
      <Td>{adminName}</Td>
      <Td>{description}</Td>
      <Td>
         <span className="log-row-date">{date}</span> | <span className="log-row-time">{time}</span>
      </Td>
   </tr>
);

LogRow.propTypes = {
   adminName: string.isRequired,
   description: string.isRequired,
   date: string.isRequired,
   time: string.isRequired,
};

export default LogRow;
