export const TRANSFER_STATUS = [
   {
      value: null,
      label: 'All',
   },
   {
      value: 'success',
      label: 'Success',
   },
   {
      value: 'failed',
      label: 'Failed',
   },
   {
      value: 'error',
      label: 'Error',
   },
   {
      value: 'submitted',
      label: 'Submitted',
   },
];

export const NIBBS_TRANSACTION_TYPE = [
   {
      value: null,
      label: 'All',
   },
   {
      value: 'debit',
      label: 'Debit',
   },
   {
      value: 'credit',
      label: 'Credit',
   },
];

export const TRANSACTION_TYPE = [
   {
      value: null,
      label: 'All',
   },
   {
      value: 'pos',
      label: 'POS',
   },
   {
      value: 'flutterwave',
      label: 'FlutterWave',
   },
   {
      value: 'nibbs',
      label: 'Nibbs',
   },
   {
      value: 'paystack',
      label: 'PayStack',
   },
];

export const INTERNATIONAL_PAYMENT_STATUS = [
   {
      value: 'pending',
      label: 'Pending',
   },
   {
      value: 'rejected',
      label: 'Rejected',
   },
   {
      value: 'approved',
      label: 'Approved',
   },
];

export const INBOUND_INTERNATIONAL_PAYMENT_STATUS = [
   {
      value: 'pending',
      label: 'Pending',
   },
   {
      value: 'partner_processing',
      label: 'Processing',
   },
   {
      value: 'partner_rejected',
      label: 'Rejected',
   },
   {
      value: 'partner_successful',
      label: 'Successful',
   },
];
