/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import { useAuth } from 'hooks/auth';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import toast from 'react-hot-toast';
import FilterListIcon from '@material-ui/icons/FilterList';
import Graph from 'components/graph';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import { TableContainer, TableContent } from 'styles/table';
import { formatTag } from 'utils/tags';
import {
   getAllNibssTransactions,
   exportNibssTransactions,
   getMonthlyInflow,
} from 'services/transactions';
import { view_nibbs_inflow_graph } from 'utils/user_actions';
import { Spinner } from 'components/spinner';
import { TooltipComponent } from 'components/tooltip';
import { Skeleton } from '@material-ui/lab';
import { TableComponent } from 'components/table';
import { FilterContainer } from 'pages/follow-up/follow-up.style';
import { numberWithCommas } from 'utils/others';
import { renderTransactionStatus } from 'utils/business';
import TranasactionModal from 'components/TransactionModal';
import TransactionsFilterModal from 'containers/filter-modal/nibss-transactions-filter';
import MultipleTransactionsModal from '../multiple-transactions-details';
import { DepositFeedWrapper, GraphWrapper } from '../../../build-partner/style';

const intialFilter = {
   created_after: moment().format('YYYY-MM-01'),
   created_before: moment().format('YYYY-MM-DD'),
};
function NibssTransaction() {
   const { currentUser } = useAuth();
   const { permissions } = currentUser?.access || {};
   const { search } = useLocation();
   const page = new URLSearchParams(search).get('page');
   const [tableData, setTableData] = useState([]);
   const [isLoading, setIsLoading] = useState(false);
   const [paginationData, setPaginationData] = useState({});
   const [currentPage, setCurrentPage] = useState(page);
   const [monthlyInflowFeed, setMonthlyInflowFeed] = useState([]);
   const [feedsFilter] = useState('monthly');
   const [selectedYear, setSelectedYear] = useState(`${moment().format('YYYY')}`);
   const [years, setYears] = useState([]);
   const [multipleTransactions, setMultipleTransactions] = useState(false);
   const [transactionDetail, setTransactionDetail] = useState('');
   const [transactionModal, setTransactionModal] = useState(false);
   const [modalVisibilityFilter, setModalVisibilityFilter] = useState(false);
   const [isExporting, setisExporting] = useState(false);
   const [filterData, setFilterData] = useState(intialFilter);
   const [filterPayload, setFilterPayload] = useState({});

   const checkPermission = (action) => permissions?.includes(action) || permissions === 'all';

   const onTransactionClick = (data) => {
      setTransactionModal(true);
      setTransactionDetail(data);
   };

   const fetchMonthlyInflow = async () => {
      try {
         const response = await getMonthlyInflow({
            ...(Object.keys(selectedYear)?.length > 1 && {
               start_date: `${selectedYear}-01-01`,
               end_date: `${selectedYear}-12-31`,
            }),
         });
         setMonthlyInflowFeed(response?.data);
      } catch (e) {
         toast.error(e?.message);
      }
   };
   useEffect(() => {
      fetchMonthlyInflow();
   }, [selectedYear]);
   useEffect(() => {
      (async function MontlyInflowStats() {
         const currentYear = new Date().getFullYear();
         const range = (start, stop, step) =>
            Array.from({ length: (stop - start) / step + 1 }, (pick, i) => start + i * step);
         setYears(range(currentYear, '2019', -1));
      })();
   }, []);

   const exportTransaction = async () => {
      if (isExporting) return null;

      setisExporting(true);
      try {
         const response = await exportNibssTransactions(filterPayload);
         toast.success(
            response?.message ||
               'Export is currently processing and will available for download shortly',
         );
      } catch (e) {
         toast.error(e.message);
      }
      setisExporting(false);
   };

   const columns = [
      {
         field: 'tx_ref',
         width: 280,
         headerName: 'Transaction ref',
         renderCell: ({ row }) => (
            <TooltipComponent title={row.tx_ref}>
               <span
                  style={{ cursor: 'pointer' }}
                  className="text-primary"
                  onClick={() => onTransactionClick(row)}
               >
                  {row.tx_ref}
               </span>
            </TooltipComponent>
         ),
      },
      {
         field: 'transaction_id',
         headerName: 'Transaction ID',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.transaction_id}>
               {row?.transaction_id}{' '}
               {row?.child_count > 0 && (
                  <span
                     className="ms-3 prospa-pink"
                     style={{ fontSize: '11px', cursor: 'pointer' }}
                     onClick={() => setMultipleTransactions(row)}
                  >
                     +{row?.child_count} more
                  </span>
               )}
            </TooltipComponent>
         ),
      },
      {
         field: 'transfer_details.recipient_account_name',
         width: 300,
         headerName: 'Recipient',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.transfer_details?.recipient_account_name || row.tx_note}>
               {row?.transfer_details?.recipient_account_name || row.tx_note}
            </TooltipComponent>
         ),
      },
      {
         field: 'trans_amount',
         headerName: 'Amount',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.trans_amount}>
               ₦{numberWithCommas(row?.trans_amount)}
            </TooltipComponent>
         ),
      },
      {
         field: 'transfer_details.transfer_status',
         headerName: 'Status',
         renderCell: ({ row }) => renderTransactionStatus(row),
      },
      {
         field: 'tx_action',
         headerName: 'Type',
         renderCell: ({ row }) => {
            let template = '';
            if (row.tx_action.includes('credit')) {
               template = formatTag('accepted', 'Credit');
            } else if (row.tx_action.includes('debit')) {
               template = formatTag('rejected', 'Debit');
            } else {
               template = formatTag(row.tx_action, row.tx_action);
            }
            return <TooltipComponent title={row.tx_action}>{template}</TooltipComponent>;
         },
      },
      {
         field: 'source_account_name',
         headerName: 'Initiated By',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.source_account_name}>
               {row?.source_account_name}
            </TooltipComponent>
         ),
      },
      {
         field: 'pub_date',
         headerName: 'Date Created',
         renderCell: ({ row }) => (
            <TooltipComponent title={row.pub_date}>
               {moment(row.pub_date).format('DD MMM, YYYY')} |{' '}
               <span style={{ opacity: '.5' }}>{moment(row.pub_date).format('HH:mm A')}</span>
            </TooltipComponent>
         ),
      },
   ];

   const fetchTransactions = async (pageNumber) => {
      setIsLoading(true);
      Promise.all([getAllNibssTransactions({ page: pageNumber || currentPage, ...filterPayload })])
         .then(([businessAccountsData]) => {
            setTableData(businessAccountsData?.results);
            setPaginationData(businessAccountsData);
         })
         .finally(() => {
            setIsLoading(false);
         });
   };

   useEffect(() => {
      fetchTransactions();
   }, [currentPage, filterPayload]);

   function onPageChange(e, pageNumber) {
      setCurrentPage(pageNumber);
   }

   return (
      <>
         <TableContainer>
            {checkPermission(view_nibbs_inflow_graph) && (
               <>
                  {!isLoading ? (
                     <GraphWrapper className="mt-4">
                        <DepositFeedWrapper className="d-flex justify-content-between px-4 align-items-center">
                           <div className="">
                              <h3 className="deposit-title">Monthly Inflow</h3>
                              <p className="mb-0 moment">
                                 As of {moment().format('DD MMM YYYY, HH:mm A')}
                              </p>
                           </div>
                           <div>
                              {feedsFilter === 'monthly' && (
                                 <div className=" date-picker-group d-flex justify-content-between align-items-center">
                                    <span className="me-3">Filter by year:</span>
                                    <select
                                       value={selectedYear}
                                       onChange={({ target }) => setSelectedYear(target.value)}
                                    >
                                       {years.map((item) => (
                                          <option key={item} value={item}>
                                             {item}
                                          </option>
                                       ))}
                                    </select>
                                 </div>
                              )}
                              <div className=" date-picker-group d-flex justify-content-between align-items-center" />
                           </div>
                        </DepositFeedWrapper>
                        <div
                           className=""
                           style={{
                              height: '300px',
                           }}
                        >
                           <Graph
                              data={monthlyInflowFeed.map((item) => parseInt(item.inflow, 10))}
                              categories={monthlyInflowFeed.map((item) => item.month || item.day)}
                           />
                        </div>
                     </GraphWrapper>
                  ) : (
                     <Spinner />
                  )}
               </>
            )}
            <TableContent>
               <div className="d-flex justify-content-between w-100 align-items-center">
                  {isLoading ? (
                     <Skeleton width="30%" height="20" />
                  ) : (
                     <div>
                        <h1> All Transactions</h1>
                        <span className="value">{paginationData?.count || 'N/A'}</span>
                     </div>
                  )}
                  <div className="d-flex align-items-center">
                     <FilterContainer className="me-3" onClick={exportTransaction}>
                        <>
                           {isExporting ? 'Exporting...' : 'Export Data'}{' '}
                           <ImportExportIcon style={{ marginLeft: '10px' }} />
                        </>
                     </FilterContainer>
                     <FilterContainer onClick={() => setModalVisibilityFilter(true)}>
                        Filter By <FilterListIcon style={{ marginLeft: '10px' }} />
                     </FilterContainer>
                  </div>
               </div>
            </TableContent>
            <TableComponent
               columns={columns}
               rows={tableData}
               isLoading={isLoading}
               onPageChange={onPageChange}
               count={Math.ceil(paginationData.count / 15)}
            />
         </TableContainer>
         <TranasactionModal
            open={transactionModal}
            onClose={() => setTransactionModal(false)}
            data={transactionDetail}
         />
         {modalVisibilityFilter && (
            <TransactionsFilterModal
               open={modalVisibilityFilter}
               onClose={() => setModalVisibilityFilter(false)}
               setCurrentPage={setCurrentPage}
               filterData={filterData}
               setFilterData={setFilterData}
               setFilterPayload={setFilterPayload}
            />
         )}

         {multipleTransactions && (
            <MultipleTransactionsModal
               open={multipleTransactions}
               onClose={() => setMultipleTransactions(false)}
               id={!!multipleTransactions}
               transaction={multipleTransactions}
            />
         )}
      </>
   );
}

export default NibssTransaction;
