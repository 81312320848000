import styled from 'styled-components';

export const TextareaWrapper = styled.div`
   label {
      pointer-events: none;
      color: #435a70;
      white-space: nowrap;
      margin-bottom: 0.3rem;
      font-size: 1.4rem;
      display: block;
      font-weight: 400;
      color: var(--color-black);
   }
   textarea {
      border-radius: 4px;
      background-color: var(--color-white);
      border: 1px solid var(--color-grey);
      padding: 1rem;
      background-color: var(--color-white);
      font-size: 1.5rem;
      height: 140px;
      width: 100%;
   }
`;
