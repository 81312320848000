import { GlobalStyles } from './global';
import { colors } from './color';
import { mediaQueries } from './custom-queries';
import { fontWeights } from './fontWeight';

const theme = {
   colors,
   fontWeights,
   mediaQueries,
};

export { theme, GlobalStyles };
