import PropTypes from 'prop-types';
import BackArrowIcon from 'assets/images/back-arrow.svg';
import LockIcon from 'assets/images/lock.svg';
import InterestIcon from 'assets/images/percent.svg';
import DownArrow from 'assets/images/circle-arrow-down.svg';
import ReceiptIcon from 'assets/images/receipt-tax.svg';
import { InputFundAmountWrapper } from 'components/input-amount/InputFundAmount.style';
import { Button } from 'components/button';
import { numberWithCommas } from 'utils/others';

export default function ReviewWithdrawFunds({ onClick, onBack, fdDetails }) {
   const { lifetime_interest, withdrawal_penalty, withholding_tax, lock_amount } = fdDetails || {};

   const reviewValues = [
      {
         icon: LockIcon,
         heading: 'Fixed Amount',
         value: `₦ ${parseFloat(lock_amount || 0).toLocaleString()}`,
      },
      {
         icon: InterestIcon,
         heading: 'Interest Earned',
         value: `+ ₦ ${lifetime_interest || 0}`,
      },
      {
         icon: DownArrow,
         heading: 'Withdrawal penalty',
         value: `- ₦ ${withdrawal_penalty || 0}`,
      },
      {
         icon: ReceiptIcon,
         heading: 'Withholding tax',
         value: `- ₦ ${withholding_tax || 0}`,
      },
   ];

   const resultingAmount = (
      (parseFloat(lock_amount) || 0) +
      (parseFloat(lifetime_interest) || 0) -
      (parseFloat(withdrawal_penalty) || 0) -
      (parseFloat(withholding_tax) || 0)
   ).toFixed(2);

   return (
      <InputFundAmountWrapper>
         <div className="withdraw-header">
            <img src={BackArrowIcon} alt="back" onClick={onBack} style={{ cursor: 'pointer' }} />
            <h3>Review and Confirm</h3>
         </div>
         {reviewValues.map((item) => (
            <div className="d-flex justify-content-between">
               <div className="d-flex gap-2 mt-4">
                  <img src={item.icon} alt="lock" />
                  <p>{item.heading}</p>
               </div>
               <div className="text-2 mt-4">{item.value}</div>
            </div>
         ))}
         <div className="d-flex justify-content-between mt-5">
            <div className="ms-5">Total Amount</div>
            <div className="text-1">{`₦ ${numberWithCommas(resultingAmount)}`}</div>
         </div>
         <div className="mt-5">
            <Button fullWidth onClick={() => onClick(resultingAmount)}>
               Continue
            </Button>
         </div>
      </InputFundAmountWrapper>
   );
}

ReviewWithdrawFunds.propTypes = {
   onClick: PropTypes.func.isRequired,
   onBack: PropTypes.func.isRequired,
   fdDetails: PropTypes.objectOf().isRequired,
};
