import { Button } from 'components/button';
import { NumberInput } from 'components/input';
import Modal from 'components/modal';
import React, { useEffect, useState } from 'react';
import PropTypes, { string } from 'prop-types';
import { InputWrapper } from 'styles/form';

export const UpdateFeesModal = ({ open, onClose, currency, feesData, loading, onSubmit }) => {
   const [flatFee, setFlatFee] = useState('');
   const [percentageFee, setPercentageFee] = useState('');

   useEffect(() => {
      setFlatFee(feesData[currency].flat_fee);
      setPercentageFee(feesData[currency].percentage_fee);
   }, [feesData]);

   const handleUpdateClick = (data) => {
      onSubmit(data);
      onClose();
   };

   return (
      <Modal
         open={open}
         onClose={onClose}
         center
         title={`Update ${currency} Inbound Transactions Fees`}
      >
         <InputWrapper className="grid-2-2">
            <NumberInput
               label="Flat Fee"
               placeholder="Enter the Flat Fees"
               value={flatFee}
               prefix=" "
               onChange={(val) => setFlatFee(val?.floatValue)}
            />
            <NumberInput
               label="Percentage Fee"
               placeholder="Enter the Percentage Fees"
               value={percentageFee}
               prefix=" "
               suffix="%"
               onChange={(val) => setPercentageFee(val?.floatValue)}
            />
         </InputWrapper>
         <InputWrapper
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
         >
            <div>
               <Button
                  variant="primary"
                  type="button"
                  onClick={() =>
                     handleUpdateClick({
                        flat_fee: flatFee,
                        percentage_fee: percentageFee,
                        currency,
                     })
                  }
                  isLoading={loading}
                  disabled={loading}
                  fullWidth
               >
                  Update
               </Button>
            </div>
         </InputWrapper>
      </Modal>
   );
};

UpdateFeesModal.propTypes = {
   open: PropTypes.bool.isRequired,
   onClose: PropTypes.func.isRequired,
   currency: PropTypes.string.isRequired,
   loading: PropTypes.bool.isRequired,
   feesData: PropTypes.objectOf(string).isRequired,
   onSubmit: PropTypes.func.isRequired,
};
