/* eslint-disable jsx-a11y/label-has-associated-control */
import { useState } from 'react';
import LoggedInDashboard from 'components/logged-in-dashboard';
import { assignBuildParter } from 'services/build-partner';
import { confirm } from 'components/confirm-box';
import { InputWrapper } from 'styles/form';
import { businessSearch } from 'services/business';
import { Button } from 'components/button';
import { SelectInput } from 'components/select';
import { useDebouncedCallback } from 'use-debounce';
import DeleteImg from 'assets/images/close-icon.svg';
import toast from 'react-hot-toast';
import { AutoCompleteSelect } from 'components/autocomplete-input';
import useFetchManagers from 'hooks/fetchManagers';
import { prospabuildpartner } from 'utils/user_types';

function LandingPage() {
   const { managers } = useFetchManagers();
   const [managerAssigned, setManagerAssigned] = useState('');
   const [searchBusiness, setSearchBusiness] = useState('');
   const [businessSearchResult, setBusinessSearchResult] = useState([]);
   const [businessList, setBusinessList] = useState([]);
   const [isAssigning, setIsAssigning] = useState();
   const [isLoading, setIsLoading] = useState(false);

   const debounced = useDebouncedCallback(
      async (event) => {
         try {
            const response = await businessSearch(event.target.value);
            if (response.data) setBusinessSearchResult(response.data);
         } catch (e) {
            toast.error(e.message);
         }
         setIsLoading(false);
      },
      // delay in ms
      800,
   );

   const handleAddbusiness = () => {
      if (
         managerAssigned?.value === '' ||
         !managerAssigned?.value ||
         !searchBusiness?.biz_account_id ||
         searchBusiness?.biz_account_id === ''
      )
         return toast.error('Enter valid inputs');
      const { biz_account_id, biz_name } = searchBusiness;
      const exist = businessList.filter((item) => item.biz_account_id === biz_account_id);
      if (exist.length > 0) return toast.error('Business already exists!');
      setBusinessList([...businessList, { biz_name, biz_account_id }]);
   };

   const removeBusiness = (id) => {
      const newArr = businessList.filter((item) => item.biz_account_id !== id);
      setBusinessList(newArr);
   };

   const handleAssignBuildPartner = () => {
      confirm({
         confirmText: 'Are you sure you want to perform this action?',
         isLoading: isAssigning,
         onConfirm: async () => {
            setIsAssigning(true);
            const build_biz = [];
            businessList.forEach((item) => build_biz.push(item.biz_account_id));
            try {
               const payload = {
                  prospa_manager_id: managerAssigned.value,
                  build_biz,
               };
               const response = await assignBuildParter(payload);
               toast.success(response.message);
               setBusinessList([]);
               setManagerAssigned('');
               setSearchBusiness('');
            } catch (e) {
               toast.error(e.message);
            }
            setIsAssigning(false);
         },
      });
   };

   return (
      <LoggedInDashboard pageTitle="Assign Build Partners">
         <InputWrapper className="col-lg-6">
            <SelectInput
               value={managerAssigned}
               onChange={(val) => setManagerAssigned(val)}
               label="Select Build Partner"
               options={managers?.filter((manager) => manager.user_type === prospabuildpartner)}
            />
         </InputWrapper>
         <div className="d-flex align-items-end flex-wrap">
            <InputWrapper className="me-3 mb-3 mb-lg-0 col-12 col-lg-6">
               <AutoCompleteSelect
                  onChange={(event, newValue) => {
                     setSearchBusiness(newValue);
                  }}
                  value={searchBusiness}
                  options={businessSearchResult}
                  getOptionLabel={(option) => option?.biz_name || ``}
                  label="Business Name"
                  loading={isLoading}
                  loadingText="Loading..."
                  inputOnchange={async (event) => {
                     if (event.target.value.length < 3) return null;
                     setIsLoading(true);
                     await debounced(event);
                  }}
               />
            </InputWrapper>
            <Button
               variant="primary"
               onClick={handleAddbusiness}
               className=""
               disabled={
                  managerAssigned?.value === '' ||
                  !managerAssigned?.value ||
                  !searchBusiness?.biz_account_id ||
                  searchBusiness?.biz_account_id === ''
               }
            >
               Add Business
            </Button>
         </div>

         <div className="col-lg-6 mt-5">
            <ul className="pt-5">
               {businessList.map((item) => (
                  <li
                     key={item.biz_id}
                     className="d-flex align-items-center justify-content-between mb-4"
                  >
                     <span>{item.biz_name}</span>
                     <img
                        src={DeleteImg}
                        alt="delete-icon"
                        style={{ width: '15px', cursor: 'pointer' }}
                        onClick={() => removeBusiness(item.biz_account_id)}
                     />
                  </li>
               ))}
            </ul>
            {managerAssigned.value !== '' && managerAssigned.value && businessList.length > 0 && (
               <Button variant="primary" onClick={handleAssignBuildPartner}>
                  Assign Businesses
               </Button>
            )}
         </div>
      </LoggedInDashboard>
   );
}

export default LandingPage;
