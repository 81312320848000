import { useEffect, useLayoutEffect } from 'react';
import { theme, GlobalStyles } from 'theme';
import { BrowserRouter, useLocation } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import Routes from 'routes';
import { useAuth } from 'hooks/auth';
import 'react-responsive-modal/styles.css';
import { Toaster } from 'react-hot-toast';

function App() {
   const { initUserSession } = useAuth();

   useEffect(() => {
      initUserSession();
   }, []);

   return (
      <BrowserRouter>
         <GlobalStyles />
         <ScrollToTop />
         <ThemeProvider theme={theme}>
            <Routes />
            <Toaster />
         </ThemeProvider>
      </BrowserRouter>
   );
}

const ScrollToTop = () => {
   const { pathname } = useLocation();

   useLayoutEffect(() => {
      window.scrollTo(0, 0);
   }, [pathname]);

   return null;
};

export default App;
