import { Http } from 'services/http';
import axiosCustom from 'utils/axios';
import { logActivity } from 'services/activity';

export const getCalendarNotes = () => Http(`/backmen/psp_manager_calendars/`);

export const getSingleAdminStats = (prospa_admin_id) =>
   Http(`/backmen/admin_stat_details/${prospa_admin_id}/`, {
      action: 'admin_manager_stats',
   });

export const addNoteToCalendar = async (payload) => {
   try {
      const response = await Http(`/backmen/psp_add_manager_calendar/`, {
         body: payload,
         method: 'POST',
      });
      await logActivity(`Added note to calendar - ${payload.calendar_note}`);
      return response;
   } catch (e) {
      throw new Error(e.message);
   }
};

export const getAdminStats = () => Http(`/backmen/admin_own_stat_details/`);

export const addAdminUser = (payload) =>
   Http(`/backmen/add_prospa_others/`, {
      body: payload,
      method: 'POST',
      action: 'add_admin_manager',
   });

export const adminTrackaCount = (payload) =>
   Http(`/backmen/direct_manager_round_stat/`, {
      body: payload,
      method: 'POST',
      action: 'admin_manager_stats',
   });

export const getAdminDocumentStats = (payload) =>
   Http(`/backmen/direct_manager_doc_other_stat/`, {
      body: payload,
      method: 'POST',
      action: 'admin_manager_stats',
   });

export const enable2FA = (payload, token) =>
   Http(`/account/enable_prospa_mfa/`, {
      body: payload,
      method: 'POST',
      headers: {
         Authorization: `Token ${token}`,
      },
   });

export const getPermissions = () => Http(`/backmen/manage_permission/`);

export const getManagerPermissions = (id, config) =>
   Http(`/backmen/manage_user/${id}/`, {
      ...config,
   });

export const getLoggedinManagerPermissions = (config) =>
   Http(`/backmen/my_permissions/`, {
      ...config,
   });

export const updateManagerPermissions = (id, payload) =>
   Http(`/backmen/manage_permission/${id}/`, {
      body: payload,
      method: 'PUT',
   });

export const createPermissions = (payload) =>
   Http(`/backmen/manage_permission/`, {
      body: payload,
      method: 'POST',
   });

export const assignPermissions = (id, payload) =>
   Http(`/backmen/manage_user/${id}/`, {
      body: payload,
      method: 'PATCH',
   });

export const deactivateManager = (id) =>
   Http(`/account/deactivate/${id}/`, {
      method: 'PATCH',
      action: 'deactivate_manager',
   });

export const getManagerPublicProfile = (id) => {
   return Http(`/backmen/update_public_profile/${id}/`);
};

export const createManagerPublicProfile = async (payload) => {
   try {
      const result = await axiosCustom().post(`/backmen/create_public_profile/`, payload);
      return result;
   } catch (e) {
      throw new Error(e.response?.data?.message || e.message);
   }
};

export const updateManagerPublicProfile = async (payload, id) => {
   try {
      const result = await axiosCustom().patch(`/backmen/update_public_profile/${id}/`, payload);
      return result;
   } catch (e) {
      throw new Error(e.response?.data?.message || e.message);
   }
};

export const changeAdminRole = async (payload) => {
   try {
      const response = await Http(`/backmen/change_new_back_role/`, {
         body: payload,
         method: 'POST',
      });
      await logActivity(
         `Changed admin with id ${payload?.prospa_admin_id} role to ${payload?.new_user_role.label}`,
      );
      return response;
   } catch (e) {
      throw new Error(e.message);
   }
};
