import styled from 'styled-components';

export const Wrapper = styled.div`
   border-radius: 6px;
   background: #e3f0fd;
   border: 1px dashed #1354d2;
   .warning-icon {
      width: 100px;
      align-self: center;
   }
   ul {
      li {
         font-size: 14px;
         display: flex;
         justify-content: space-between;
      }
      .title{
         width:50%;
      }
      .number{
         text-align:left;
         width:50%;
      }}
   }
`;
