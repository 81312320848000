import React from 'react';
import { func, objectOf } from 'prop-types';
import { Input } from 'components/input';
import { Button } from 'components/button';
import useTransactionsSettings from 'hooks/transactionsSettings';

export const FlagCompliance = ({ allSettings, handleActiveTransfer }) => {
   const { switching, confirmSetting } = useTransactionsSettings();

   return (
      <>
         <div>
            {allSettings?.COMPLIANCE__FLAG_IDENTICAL_TRANSACTIONS && (
               <div className="pb-3 col-lg-4 d-flex flex-column justify-content-between">
                  <div>
                     <h3 className="mb-3">Compliance Identity Flag</h3>
                     <div className="mb-4">
                        {Object.keys(
                           allSettings?.COMPLIANCE__FLAG_IDENTICAL_TRANSACTIONS.value,
                        ).map((setting) => (
                           <div className="d-flex align-items-center mb-4">
                              <Input
                                 name={allSettings?.COMPLIANCE__FLAG_IDENTICAL_TRANSACTIONS.name}
                                 id={setting}
                                 type="number"
                                 label="Number of Identical Transactions"
                                 placeholder={setting}
                                 value={
                                    allSettings?.COMPLIANCE__FLAG_IDENTICAL_TRANSACTIONS.value[
                                       setting
                                    ]
                                 }
                                 onChange={(e) =>
                                    handleActiveTransfer(
                                       allSettings?.COMPLIANCE__FLAG_IDENTICAL_TRANSACTIONS,
                                       parseInt(e.target.value, 10),
                                    )
                                 }
                              />
                           </div>
                        ))}
                     </div>
                  </div>
                  <Button
                     className="w-100"
                     onClick={() =>
                        confirmSetting(allSettings?.COMPLIANCE__FLAG_IDENTICAL_TRANSACTIONS)
                     }
                     isLoading={switching.COMPLIANCE__FLAG_IDENTICAL_TRANSACTIONS}
                     isDisabled={switching.COMPLIANCE__FLAG_IDENTICAL_TRANSACTIONS}
                  >
                     Confirm Identity Flag Setting
                  </Button>
               </div>
            )}
         </div>
      </>
   );
};

FlagCompliance.propTypes = {
   allSettings: objectOf().isRequired,
   handleActiveTransfer: func.isRequired,
};
