/* eslint-disable react/prop-types */
import { useHistory } from 'react-router-dom';
import { func, bool } from 'prop-types';
import toast from 'react-hot-toast';
import moment from 'moment';
import { Input } from 'components/input';
import Modal from 'components/modal';
import { InputWrapper } from 'styles/form';
import { Button } from 'components/button';
import { SelectInput } from 'components/select';
import { useState, useEffect } from 'react';

const status = [
   {
      value: null,
      label: 'All',
   },
   {
      value: 'pending',
      label: 'Pending',
   },
   {
      value: 'approved',
      label: 'Approved',
   },
   {
      value: 'rejected',
      label: 'Rejected',
   },
   {
      value: 'pre_approved',
      label: 'Pre approved',
   },
   {
      value: 'defaulted_completed',
      label: 'Defaulted Completed',
   },
   {
      value: 'defaulted',
      label: 'Defaulted',
   },
   {
      value: 'completed',
      label: 'Completed',
   },
   {
      value: 'upcoming',
      label: 'Upcoming',
   },
];

const LoansFilterModal = ({
   open,
   onClose,
   setCurrentPage,
   setFilterDate,
   filterDate,
   setFilterLoanStatus,
   filterLoanStatus,
}) => {
   const [filterData, setfilterData] = useState({
      start_date: filterDate.start_date,
      end_date: filterDate.end_date,
      loan_status: filterLoanStatus,
   });
   const [isLoading, setIsLoading] = useState(false);
   const [isClearingFilter, setIsClearingFilter] = useState(false);
   const history = useHistory();

   useEffect(() => {
      return function unMount() {
         setIsLoading(false);
      };
   }, []);
   function onChange(name, data) {
      setfilterData({
         ...filterData,
         [name]: data.value,
      });
   }

   async function onSubmit() {
      setIsLoading(true);
      try {
         setCurrentPage(() => 1);
         history.push(`${history.location.pathname}?page=1`);

         // const result = await getApprovedAccounts({ page: currentPage }, filterData);
         const { start_date, end_date, loan_status } = filterData;
         setFilterLoanStatus(loan_status);
         setFilterDate({ start_date, end_date }); // Set Filter Date so when next page is clicked, it remembers this date and fetches for it.
      } catch (err) {
         toast.error(err.message || err.custom_message);
         setIsLoading(false);
         return err;
      } finally {
         // setIsLoading(false);
      }
   }

   async function clearFilter() {
      setIsClearingFilter(true);
      try {
         setCurrentPage(1);
         history.push(`${history.location.pathname}?page=1`);
         setFilterLoanStatus(null);
         setFilterDate({
            start_date: '2020-01-01',
            end_date: moment().format('YYYY-MM-DD'),
         });
      } catch (err) {
         return err;
      }
      setIsClearingFilter(false);
   }

   return (
      <Modal
         open={open}
         onClose={onClose}
         title="Filters"
         className="biz-details-modal"
         center
         width="750px"
      >
         <form>
            <InputWrapper className="grid-2-2">
               <Input
                  placeholder="Start Date"
                  label="Start Date"
                  name="start_date"
                  type="date"
                  value={filterData?.start_date}
                  onChange={(e) => {
                     setfilterData({ ...filterData, start_date: e.target.value });
                  }}
               />
               <Input
                  placeholder="End Date"
                  label="End Date"
                  name="end_date"
                  type="date"
                  value={filterData?.end_date}
                  onChange={(e) => {
                     setfilterData({ ...filterData, end_date: e.target.value });
                  }}
               />
            </InputWrapper>

            <InputWrapper className="grid-2-2">
               <SelectInput
                  onChange={(val) => onChange('loan_status', val)}
                  label="Status"
                  options={status}
                  defaultValue={filterData.loan_status}
               />

               {/* <SelectInput
                  label="Turnover"
                  onChange={(val) => onChange('biz_turnover', val)}
                  options={turnover}
               /> */}
            </InputWrapper>

            <InputWrapper
               style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
            >
               <div style={{ marginRight: '20px' }}>
                  <Button
                     variant="secondary"
                     type="button"
                     onClick={clearFilter}
                     isLoading={isClearingFilter}
                     fullWidth
                  >
                     clear filter
                  </Button>
               </div>

               <div>
                  <Button
                     variant="primary"
                     type="button"
                     onClick={onSubmit}
                     isLoading={isLoading}
                     disabled={isLoading}
                     fullWidth
                  >
                     Apply
                  </Button>
               </div>
            </InputWrapper>
         </form>
      </Modal>
   );
};

export { LoansFilterModal };

LoansFilterModal.propTypes = {
   open: bool.isRequired,
   onClose: func.isRequired,
};
