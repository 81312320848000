import React, { useState } from 'react';
import { bool, number, string, func } from 'prop-types';
import Modal from 'components/modal';
import { Button } from 'components/button';
import CloseIcon from 'assets/images/close-icon-bg.svg';
import DropzoneImage from 'components/upload-photo';
import styled from 'styled-components';
import { createCardDesign } from 'services/cards';
import toast from 'react-hot-toast';
import { confirm } from 'components/confirm-box';

const Container = styled.div`
   display: flex;
   justify-content: center;
   align-items: center;
   margin-bottom: 20px;

   border-radius: 12px;
   border: 1px dashed var(--ui-deep-blue-50, #82849f);
   background: var(--ui-white, #fff);
`;

const CardPhotoUploadModal = ({ open, onClose, cardName, cardPrice, fetchCardDesigns }) => {
   const [loading, setLoading] = useState(false);
   const [image, setImage] = useState(null);
   const [imagePreview, setImagePreview] = useState(null);

   const handleImageDrop = (file) => {
      setImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
         setImagePreview(reader.result);
      };
      if (file) {
         reader.readAsDataURL(file);
      }
   };

   const handleRemoveImage = () => {
      setImagePreview(null);
   };

   const handleCreateCard = () => {
      setLoading(true);
      confirm({
         confirmText: 'Are you sure want to create this card?',
         onConfirm: async () => {
            const formData = new FormData();
            formData.append('name', cardName);
            formData.append('amount', cardPrice);
            formData.append('image', image);
            try {
               const res = await createCardDesign(formData);
               toast.success(res?.message || 'Card successfully created');
               setLoading(false);
               fetchCardDesigns();
            } catch (e) {
               toast.error(e?.message);
            }
         },
      });
      onClose();
   };

   return (
      <Modal open={open} onClose={onClose} center width="100%">
         <h1 className="mb-4">Card Picture</h1>
         <Container>
            {!imagePreview && <DropzoneImage onImageDrop={handleImageDrop} />}
            {imagePreview && (
               <div className="d-flex jus">
                  <img
                     src={imagePreview}
                     alt="Uploaded"
                     style={{ maxWidth: '100%', maxHeight: '300px' }}
                  />
                  <img
                     src={CloseIcon}
                     alt="close-icon"
                     onClick={handleRemoveImage}
                     style={{
                        cursor: 'pointer',
                        position: 'relative',
                        bottom: '130px',
                        right: '20px',
                     }}
                  />
               </div>
            )}
         </Container>
         <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            <div>
               <Button
                  variant="primary"
                  type="button"
                  onClick={handleCreateCard}
                  isLoading={loading}
                  disabled={!imagePreview}
                  fullWidth
               >
                  Create Card
               </Button>
            </div>
         </div>
      </Modal>
   );
};

CardPhotoUploadModal.propTypes = {
   open: bool.isRequired,
   onClose: func.isRequired,
   cardName: string.isRequired,
   cardPrice: number.isRequired,
   fetchCardDesigns: func.isRequired,
};

export default CardPhotoUploadModal;
