import Select from 'react-select';
import { ErrorP, Label } from 'components/input/input.style';
import { string } from 'prop-types';
import { SelectInputComponentStyle } from './select-input.styles';

const SelectInput = ({ label, error, ...rest }) => {
   const selectId = `select-${label.replace(/\s+/g, '-').toLowerCase()}`;

   return (
      <SelectInputComponentStyle error={error} className="other-selct w-100">
         <Label className="select-label" htmlFor={selectId}>
            {label}
         </Label>
         <Select
            inputId={selectId}
            classNamePrefix="react-select"
            className="red"
            components={{ IndicatorSeparator: () => null }}
            {...rest}
            menuPortalTarget={document.body}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
         />
         {error && (
            <ErrorP
               initial="pageInitial"
               animate="pageAnimate"
               exit="pageExit"
               variants={{
                  pageInitial: {
                     opacity: 1,
                     x: 0,
                     y: -5,
                  },
                  pageAnimate: {
                     opacity: 1,
                     x: 0,
                     y: 0,
                  },
                  pageExit: {
                     opacity: 0,
                  },
               }}
            >
               {error}
            </ErrorP>
         )}
      </SelectInputComponentStyle>
   );
};

SelectInput.propTypes = {
   error: string,
   label: string,
};

SelectInput.defaultProps = {
   label: '',
   error: '',
};

export { SelectInput };
