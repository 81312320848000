import { useEffect, useState } from 'react';
import { number } from 'prop-types';
import { Button } from 'components/button';
import {
   getBusinessDetails,
   triggerTinMail,
   updatePersonalDocs,
   uploadBusinessDocs,
   verifyDocument,
} from 'services/businessDetails';
import { Spinner } from 'components/spinner';
import { PersonOutline, PhoneOutlined, MailOutline } from '@material-ui/icons';
import DocumentPreviewer from 'components/document-previewer';
import { AlertMessage } from 'components/message';
import RejectDocument from 'components/reject-document';
import { confirm } from 'components/confirm-box';
import toast from 'react-hot-toast';
import { Status } from 'styles/utils';
import { BoolStatus } from '../utils/overview.style';
import {
   DocumentControls,
   DocumentHeading,
   DocumentSection,
   DocumentsWrapper,
} from './documents.style';

const Documents = ({ businessAccountId }) => {
   const [bizDetails, setBizDetails] = useState({});

   const [identityFile, setIdentityFile] = useState(null);
   const [uploadingId, setUploadingId] = useState(false);

   const [proofOfAddress, setProofOfAddress] = useState(null);
   const [uploadingProofOfAddress, setUploadingProofOfAddress] = useState(false);

   const [selfie, setSelfie] = useState(null);
   const [uploadingSelfie, setUploadingSelfie] = useState(false);

   const [cacCert, setCacCert] = useState(null);
   const [uploadingCacCert, setUploadingCacCert] = useState(false);

   const [tinDoc1, setTinDoc1] = useState(null);
   const [uploadingTinDoc1, setUploadingTinDoc1] = useState(false);

   const [tinDoc2, setTinDoc2] = useState(null);
   const [uploadingTinDoc2, setUploadingTinDoc2] = useState(false);

   const [tinDoc3, setTinDoc3] = useState(null);
   const [uploadingTinDoc3, setUploadingTinDoc3] = useState(false);

   const [tinDoc4, setTinDoc4] = useState(null);
   const [uploadingTinDoc4, setUploadingTinDoc4] = useState(false);

   const [isVerifying, setIsVerifying] = useState(false);

   const [openRejectModal, setOpenRejectModal] = useState(false);
   const [rejectType, setRejectType] = useState('');

   const accepted = 'accepted';
   const rejected = 'rejected';
   const pending = 'pending';

   const [loading, setLoading] = useState(true);
   const [error, setError] = useState('');

   const fetchBusinessDetails = async () => {
      try {
         const result = await getBusinessDetails(businessAccountId);
         if (result.ok) {
            setBizDetails(result.data);
            setError('');
         }
         setLoading(false);
      } catch (err) {
         setError(err.message || err.custom_message);
         setLoading(false);
      }
   };

   const processTIN = async () => {
      try {
         const result = await triggerTinMail({ biz_account_id: businessAccountId });
         if (result.ok) {
            setError('');
            toast.success(result?.message, { duration: 3000 });
         }
      } catch (err) {
         setError(err.message || err.custom_message);
         toast.error(err.message || err.custom_message, { duration: 3000 });
      }
   };

   const verifyDoc = async (docKey, verificationAction) => {
      setIsVerifying(true);
      const verifyData = {
         biz_account_id: businessAccountId,
         [docKey]: verificationAction,
      };

      if (verificationAction === rejected) setRejectType(docKey);

      try {
         const result = await verifyDocument(verifyData, docKey, verificationAction);
         if (result.ok) {
            fetchBusinessDetails();
            toast.success('Operation successful');
            setError('');

            if (verificationAction === rejected) setOpenRejectModal(true);
         }
         setLoading(false);
         setIsVerifying(false);
      } catch (err) {
         toast.error(err.message || err.custom_message, { duration: 3000 });
         setError(err.message || err.custom_message);
         setLoading(false);
         setIsVerifying(false);
      }
   };

   const uploadID = async () => {
      setUploadingId(true);
      try {
         const formData = new FormData();
         formData.append('customer_id', bizDetails?.account_holder_details?.account_holder_id);
         formData.append('identity_file', identityFile);

         const result = await updatePersonalDocs(formData, businessAccountId);
         if (result) {
            fetchBusinessDetails();
            toast.success(result?.data?.message);
            setError('');
            setIdentityFile(null);
            setUploadingId(false);
         }
      } catch (err) {
         toast.error(err?.message, { duration: 3000 });
         setError(err?.message);
         setUploadingId(false);
      }
   };

   const uploadBizDoc = async (docKey) => {
      if (docKey === 'proof_of_address') setUploadingProofOfAddress(true);
      if (docKey === 'selfie_image') setUploadingSelfie(true);
      if (docKey === 'cac_cert_photo') setUploadingCacCert(true);
      if (docKey === 'tin_document_one') setUploadingTinDoc1(true);
      if (docKey === 'tin_document_two') setUploadingTinDoc2(true);
      if (docKey === 'tin_document_three') setUploadingTinDoc3(true);
      if (docKey === 'tin_document_four') setUploadingTinDoc4(true);

      const file = (key) => {
         if (key === 'proof_of_address') return proofOfAddress;
         if (key === 'selfie_image') return selfie;
         if (key === 'cac_cert_photo') return cacCert;
         if (key === 'tin_document_one') return tinDoc1;
         if (key === 'tin_document_two') return tinDoc2;
         if (key === 'tin_document_three') return tinDoc3;
         if (key === 'tin_document_four') return tinDoc4;
         return null;
      };
      try {
         const formData = new FormData();
         formData.append('biz_account_id', businessAccountId);
         formData.append(docKey, file(docKey));

         const result = await uploadBusinessDocs(formData, docKey);
         if (result) {
            fetchBusinessDetails();
            toast.success(result?.data?.message);
            setError('');
            setCacCert(null);
            setProofOfAddress(null);
            setTinDoc1(null);
            setTinDoc2(null);
            setTinDoc3(null);
            setTinDoc4(null);

            setUploadingProofOfAddress(false);
            setUploadingSelfie(false);
            setUploadingCacCert(false);
            setUploadingTinDoc1(false);
            setUploadingTinDoc2(false);
            setUploadingTinDoc3(false);
            setUploadingTinDoc4(false);
         }
      } catch (err) {
         toast.error(err.response?.data?.message || err?.message, { duration: 3000 });
         setError(err.response?.data?.message || err?.message);
         setUploadingProofOfAddress(false);
         setUploadingSelfie(false);
         setUploadingCacCert(false);
         setUploadingTinDoc1(false);
         setUploadingTinDoc2(false);
         setUploadingTinDoc3(false);
         setUploadingTinDoc4(false);
      }
   };

   const statusValue = (status) => {
      switch (status) {
         case null:
            return 'not uploaded';
         case pending:
            return 'uploaded';
         default:
            return status;
      }
   };

   const handleVerify = (key, value) => {
      confirm({
         confirmText: 'Are you sure you want to perform this action?',
         isLoading: isVerifying,
         onConfirm: () => verifyDoc(key, value),
      });
   };

   const confirmTinMail = () => {
      confirm({
         confirmText: 'Are you sure you want to perform this action?',
         isLoading: isVerifying,
         onConfirm: processTIN,
      });
   };

   useEffect(() => {
      fetchBusinessDetails();
   }, []);

   return (
      <DocumentsWrapper>
         <AlertMessage message={error} variant="error" />
         {loading ? (
            <Spinner />
         ) : (
            <>
               <DocumentSection>
                  <DocumentHeading>
                     <p className="doc-label">Business Type</p>
                     <Status status={bizDetails?.biz_type !== 'unregistered' ? accepted : pending}>
                        {bizDetails?.biz_type}
                     </Status>
                  </DocumentHeading>
               </DocumentSection>

               {bizDetails?.account_holder_details?.bvn_number && bizDetails?.tin_number && (
                  <DocumentSection>
                     <DocumentHeading>
                        <p className="doc-label">TIN Number [{bizDetails?.tin_number || 'N/A'}]</p>

                        <Status
                           status={
                              bizDetails?.tin_number && !bizDetails?.tin_number_status
                                 ? pending
                                 : bizDetails?.tin_number_status
                           }
                        >
                           {!bizDetails?.tin_number
                              ? 'NO TIN'
                              : bizDetails?.tin_number_status || pending}
                        </Status>
                     </DocumentHeading>

                     {bizDetails?.tin_number && (
                        <div className="mt-2">
                           {bizDetails?.tin_number_status !== accepted && (
                              <Button
                                 className="doc-btn bg-success me-3"
                                 onClick={() => handleVerify('is_tin_number_verified', accepted)}
                              >
                                 Approve TIN Number
                              </Button>
                           )}

                           {bizDetails?.tin_number_status !== rejected && (
                              <Button
                                 className="doc-btn bg-danger"
                                 onClick={() => handleVerify('is_tin_number_verified', rejected)}
                              >
                                 Reject
                              </Button>
                           )}
                        </div>
                     )}
                  </DocumentSection>
               )}

               {bizDetails?.account_holder_details?.bvn_number &&
                  bizDetails?.cac_cert_photo_url && (
                     <DocumentSection>
                        <DocumentHeading>
                           <p className="doc-label">Business Owner is a Director</p>
                           <Status status={bizDetails?.director_match_status || pending}>
                              {bizDetails?.director_match_status || pending}
                           </Status>
                        </DocumentHeading>
                        <div className="mt-2">
                           {bizDetails?.director_match_status !== accepted && (
                              <Button
                                 className="doc-btn bg-success me-3"
                                 onClick={() =>
                                    handleVerify('is_director_match_verified', accepted)
                                 }
                              >
                                 Approve Business Owner
                              </Button>
                           )}
                           {bizDetails?.director_match_status !== rejected && (
                              <Button
                                 className="doc-btn bg-danger"
                                 onClick={() =>
                                    handleVerify('is_director_match_verified', rejected)
                                 }
                              >
                                 Reject
                              </Button>
                           )}
                        </div>
                     </DocumentSection>
                  )}

               <DocumentSection>
                  <DocumentHeading>
                     <p className="doc-label">Business Selected from CAC</p>
                     <BoolStatus isTrue={bizDetails?.cac_biz_tag !== 0}>
                        {bizDetails?.cac_biz_tag === 0 ? 'No' : 'Yes'}
                     </BoolStatus>
                  </DocumentHeading>
               </DocumentSection>

               <DocumentSection>
                  <DocumentHeading>
                     <p className="doc-label">BVN Verification</p>
                     <BoolStatus isTrue={!!bizDetails?.account_holder_details?.bvn_number}>
                        {bizDetails?.account_holder_details?.bvn_number
                           ? 'Verified'
                           : 'Not Verified'}
                     </BoolStatus>
                  </DocumentHeading>
               </DocumentSection>

               <DocumentSection>
                  <DocumentHeading>
                     <p className="doc-label">Account Holder Government Issued ID</p>
                     <Status status={bizDetails?.account_holder_details?.identity_file_status}>
                        {statusValue(bizDetails?.account_holder_details?.identity_file_status)}
                     </Status>
                  </DocumentHeading>

                  <div className="mt-2">
                     {bizDetails?.account_holder_details?.identity_file && (
                        <DocumentControls>
                           <DocumentPreviewer
                              url={bizDetails?.account_holder_details?.identity_file}
                              biz_account_id={bizDetails.biz_account_id}
                              title="Identity File"
                           >
                              <Button className="doc-btn me-3">Preview Document</Button>
                           </DocumentPreviewer>
                           {bizDetails?.account_holder_details?.identity_file_status !==
                              accepted && (
                              <Button
                                 className="doc-btn bg-success me-3"
                                 onClick={() => handleVerify('is_identity_verified', accepted)}
                              >
                                 Approve
                              </Button>
                           )}
                           {bizDetails?.account_holder_details?.identity_file_status !==
                              rejected && (
                              <Button
                                 className="doc-btn bg-danger"
                                 onClick={() => handleVerify('is_identity_verified', rejected)}
                              >
                                 Reject
                              </Button>
                           )}
                        </DocumentControls>
                     )}

                     {bizDetails?.account_holder_details?.identity_file_status !== accepted && (
                        <div className="input-group mt-5 doc-file-input-group">
                           <input
                              type="file"
                              className="form-control file-input"
                              aria-label="Upload"
                              onChange={(e) => setIdentityFile(e.target.files[0])}
                           />
                           <Button
                              className="upload-btn"
                              disabled={!identityFile || uploadingId}
                              onClick={uploadID}
                              isLoading={uploadingId}
                           >
                              Upload ID File
                           </Button>
                        </div>
                     )}
                  </div>
               </DocumentSection>

               {bizDetails?.biz_type === 'registered' && (
                  <DocumentSection>
                     <DocumentHeading>
                        <p className="doc-label">CAC Certificate</p>
                        <Status status={bizDetails?.cac_cert_photo_status}>
                           {statusValue(bizDetails?.cac_cert_photo_status)}
                        </Status>
                     </DocumentHeading>

                     <div className="mt-2">
                        {bizDetails?.cac_cert_photo_url && (
                           <DocumentControls>
                              <DocumentPreviewer
                                 url={bizDetails?.cac_cert_photo_url}
                                 biz_account_id={bizDetails.biz_account_id}
                                 title="CAC Cert"
                              >
                                 <Button className="doc-btn me-3">Preview Document</Button>
                              </DocumentPreviewer>
                              {bizDetails?.cac_cert_photo_status !== accepted && (
                                 <Button
                                    className="doc-btn bg-success me-3"
                                    onClick={() =>
                                       handleVerify('is_cac_cert_photo_verified', accepted)
                                    }
                                 >
                                    Approve
                                 </Button>
                              )}
                              {bizDetails?.cac_cert_photo_status !== rejected && (
                                 <Button
                                    className="doc-btn bg-danger"
                                    onClick={() =>
                                       handleVerify('is_cac_cert_photo_verified', rejected)
                                    }
                                 >
                                    Reject
                                 </Button>
                              )}
                           </DocumentControls>
                        )}

                        {bizDetails?.cac_cert_photo_status !== accepted && (
                           <div className="input-group mt-5 doc-file-input-group">
                              <input
                                 type="file"
                                 className="form-control file-input"
                                 aria-label="Upload"
                                 onChange={(e) => setCacCert(e.target.files[0])}
                              />
                              <Button
                                 className="upload-btn"
                                 disabled={!cacCert || uploadingCacCert}
                                 isLoading={uploadingCacCert}
                                 onClick={() => uploadBizDoc('cac_cert_photo')}
                              >
                                 Upload CAC Certificate
                              </Button>
                           </div>
                        )}
                     </div>
                  </DocumentSection>
               )}

               {bizDetails?.board_partner_details?.length ? (
                  bizDetails?.board_partner_details?.map((partner) => (
                     <DocumentSection key={partner.board_resolution_id}>
                        <DocumentHeading>
                           <p className="doc-label">Other Director</p>
                           <Status status={bizDetails?.board_resolution_status}>
                              {bizDetails?.board_resolution_status === pending
                                 ? pending
                                 : bizDetails?.board_resolution_status}
                           </Status>
                        </DocumentHeading>
                        <p className="mb-2">
                           <PersonOutline className="director-icon" />{' '}
                           {partner.partner_full_name || 'N/A'}
                        </p>
                        <p className="mb-2">
                           <PhoneOutlined className="director-icon" />{' '}
                           {partner.partner_phone || 'N/A'}
                        </p>
                        <p className="mb-2">
                           <MailOutline className="director-icon" />{' '}
                           {partner.partner_email || 'N/A'}
                        </p>

                        <DocumentControls>
                           {partner.partner_identity_file && (
                              <DocumentPreviewer
                                 url={partner.partner_identity_file}
                                 biz_account_id={bizDetails.biz_account_id}
                                 title="Partner's ID"
                              >
                                 <Button className="doc-btn me-3">Preview Document</Button>
                              </DocumentPreviewer>
                           )}
                           {bizDetails?.board_resolution_status !== accepted && (
                              <Button
                                 className="doc-btn bg-success me-3"
                                 onClick={() => handleVerify('is_board_verified', accepted)}
                              >
                                 Approve
                              </Button>
                           )}
                           {bizDetails?.board_resolution_status !== rejected && (
                              <Button
                                 className="doc-btn bg-danger me-3"
                                 onClick={() => handleVerify('is_board_verified', rejected)}
                              >
                                 Reject
                              </Button>
                           )}
                        </DocumentControls>
                     </DocumentSection>
                  ))
               ) : (
                  <div />
               )}

               <DocumentSection>
                  <DocumentHeading>
                     <p className="doc-label">Proof of Address</p>
                     <Status status={bizDetails?.proof_of_address_status}>
                        {statusValue(bizDetails?.proof_of_address_status)}
                     </Status>
                  </DocumentHeading>

                  <div className="mt-2">
                     {bizDetails?.proof_of_address_url && (
                        <DocumentControls>
                           <DocumentPreviewer
                              url={bizDetails?.proof_of_address_url}
                              biz_account_id={bizDetails.biz_account_id}
                              title="Proof of Address"
                           >
                              <Button className="doc-btn me-3">Preview Document</Button>
                           </DocumentPreviewer>
                           {bizDetails?.proof_of_address_status !== accepted && (
                              <Button
                                 className="doc-btn bg-success me-3"
                                 onClick={() => handleVerify('is_address_verified', accepted)}
                              >
                                 Approve
                              </Button>
                           )}
                           {bizDetails?.proof_of_address_status !== rejected && (
                              <Button
                                 className="doc-btn bg-danger"
                                 onClick={() => handleVerify('is_address_verified', rejected)}
                              >
                                 Reject
                              </Button>
                           )}
                        </DocumentControls>
                     )}
                     {bizDetails?.proof_of_address_status !== accepted && (
                        <div className="input-group mt-5 doc-file-input-group">
                           <input
                              type="file"
                              className="form-control file-input"
                              aria-label="Upload"
                              onChange={(e) => setProofOfAddress(e.target.files[0])}
                           />
                           <Button
                              className="upload-btn"
                              disabled={!proofOfAddress || uploadingProofOfAddress}
                              isLoading={uploadingProofOfAddress}
                              onClick={() => uploadBizDoc('proof_of_address')}
                           >
                              Upload Proof of Address
                           </Button>
                        </div>
                     )}
                  </div>
               </DocumentSection>

               <DocumentSection>
                  <DocumentHeading>
                     <p className="doc-label">Selfie</p>
                     <Status status={bizDetails?.selfie_image_status}>
                        {statusValue(bizDetails?.selfie_image_status)}
                     </Status>
                  </DocumentHeading>

                  <div className="mt-2">
                     {bizDetails?.selfie_image_url && (
                        <DocumentControls>
                           <DocumentPreviewer
                              url={bizDetails?.selfie_image_url}
                              biz_account_id={bizDetails.biz_account_id}
                              title="Selfie"
                           >
                              <Button className="doc-btn me-3">Preview Document</Button>
                           </DocumentPreviewer>
                           {bizDetails?.selfie_image_status !== accepted && (
                              <Button
                                 className="doc-btn bg-success me-3"
                                 onClick={() => handleVerify('is_selfie_image_verified', accepted)}
                              >
                                 Approve
                              </Button>
                           )}
                           {bizDetails?.selfie_image_status !== rejected && (
                              <Button
                                 className="doc-btn bg-danger"
                                 onClick={() => handleVerify('is_selfie_image_verified', rejected)}
                              >
                                 Reject
                              </Button>
                           )}
                        </DocumentControls>
                     )}
                     {bizDetails?.selfie_image_status !== accepted && (
                        <div className="input-group mt-5 doc-file-input-group">
                           <input
                              type="file"
                              className="form-control file-input"
                              aria-label="Upload"
                              onChange={(e) => setSelfie(e.target.files[0])}
                           />
                           <Button
                              className="upload-btn"
                              disabled={!selfie || uploadingSelfie}
                              isLoading={uploadingSelfie}
                              onClick={() => uploadBizDoc('selfie_image')}
                           >
                              Upload Selfie
                           </Button>
                        </div>
                     )}
                  </div>
               </DocumentSection>

               {bizDetails?.tin_document_one || bizDetails?.tin_number_status === rejected ? (
                  <DocumentSection>
                     <DocumentHeading>
                        <p className="doc-label">Additional Documents for TIN 01</p>
                        <Status status={bizDetails?.tin_document_status}>
                           {statusValue(bizDetails?.tin_document_status)}
                        </Status>
                     </DocumentHeading>

                     <div className="mt-2">
                        {(bizDetails?.tin_document_one ||
                           bizDetails?.tin_document_two ||
                           bizDetails?.tin_document_three ||
                           bizDetails?.tin_document_four) && (
                           <DocumentControls style={{ display: 'initial' }}>
                              {bizDetails?.tin_document_one && (
                                 <DocumentPreviewer
                                    url={bizDetails?.tin_document_one}
                                    biz_account_id={bizDetails.biz_account_id}
                                    title="TIN Document 1"
                                 >
                                    <Button className="doc-btn me-3">
                                       Preview TIN Document One
                                    </Button>
                                 </DocumentPreviewer>
                              )}
                              {bizDetails?.tin_document_two && (
                                 <DocumentPreviewer
                                    url={bizDetails?.tin_document_two}
                                    biz_account_id={bizDetails.biz_account_id}
                                    title="TIN Document 2"
                                 >
                                    <Button className="doc-btn me-3">
                                       Preview TIN Document Two
                                    </Button>
                                 </DocumentPreviewer>
                              )}
                              {bizDetails?.tin_document_three && (
                                 <DocumentPreviewer
                                    url={bizDetails?.tin_document_three}
                                    biz_account_id={bizDetails.biz_account_id}
                                    title="TIN Document 3"
                                 >
                                    <Button className="doc-btn me-3">
                                       Preview TIN Document Three
                                    </Button>
                                 </DocumentPreviewer>
                              )}
                              {bizDetails?.tin_document_four && (
                                 <DocumentPreviewer
                                    url={bizDetails?.tin_document_four}
                                    biz_account_id={bizDetails.biz_account_id}
                                    title="TIN Document 4"
                                 >
                                    <Button className="doc-btn me-3">
                                       Preview TIN Document Four
                                    </Button>
                                 </DocumentPreviewer>
                              )}

                              {bizDetails?.tin_document_status !== accepted && (
                                 <Button
                                    className="doc-btn bg-success me-3"
                                    onClick={() =>
                                       handleVerify('is_tin_document_verified', accepted)
                                    }
                                 >
                                    Approve
                                 </Button>
                              )}

                              {bizDetails?.tin_document_status === accepted && (
                                 <Button
                                    className="doc-btn bg-success me-3"
                                    onClick={confirmTinMail}
                                 >
                                    Send TIN Documents For Processing
                                 </Button>
                              )}

                              {bizDetails?.tin_document_status !== rejected && (
                                 <Button
                                    className="doc-btn bg-danger"
                                    onClick={() =>
                                       handleVerify('is_tin_document_verified', rejected)
                                    }
                                 >
                                    Reject
                                 </Button>
                              )}
                           </DocumentControls>
                        )}

                        {bizDetails?.tin_document_status !== accepted && (
                           <div className="input-group mt-5 doc-file-input-group">
                              <input
                                 type="file"
                                 className="form-control file-input"
                                 aria-label="Upload"
                                 onChange={(e) => setTinDoc1(e.target.files[0])}
                              />
                              <Button
                                 className="upload-btn"
                                 disabled={!tinDoc1 || uploadingTinDoc1}
                                 isLoading={uploadingTinDoc1}
                                 onClick={() => uploadBizDoc('tin_document_one')}
                              >
                                 Upload TIN Document One
                              </Button>
                           </div>
                        )}
                     </div>
                  </DocumentSection>
               ) : (
                  <>
                     {!bizDetails.tin_number && bizDetails.cac_cert_photo ? (
                        <DocumentSection>
                           <DocumentHeading>
                              <p className="doc-label">Additional Documents for TIN</p>
                              <Status status={bizDetails?.tin_document_status}>
                                 {statusValue(bizDetails?.tin_document_status)}
                              </Status>
                           </DocumentHeading>

                           <div className="mt-2">
                              {bizDetails?.tin_document_status !== accepted && (
                                 <>
                                    <div className="input-group mt-5 doc-file-input-group">
                                       <input
                                          type="file"
                                          className="form-control file-input"
                                          aria-label="Upload"
                                          onChange={(e) => setTinDoc1(e.target.files[0])}
                                       />
                                       <Button
                                          className="upload-btn"
                                          disabled={!tinDoc1 || uploadingTinDoc1}
                                          isLoading={uploadingTinDoc1}
                                          onClick={() => uploadBizDoc('tin_document_one')}
                                       >
                                          Upload TIN Document One
                                       </Button>
                                    </div>

                                    <div className="input-group mt-5 doc-file-input-group">
                                       <input
                                          type="file"
                                          className="form-control file-input"
                                          aria-label="Upload"
                                          onChange={(e) => setTinDoc2(e.target.files[0])}
                                       />
                                       <Button
                                          className="upload-btn"
                                          disabled={!tinDoc2 || uploadingTinDoc2}
                                          isLoading={uploadingTinDoc2}
                                          onClick={() => uploadBizDoc('tin_document_two')}
                                       >
                                          Upload TIN Document Two
                                       </Button>
                                    </div>

                                    <div className="input-group mt-5 doc-file-input-group">
                                       <input
                                          type="file"
                                          className="form-control file-input"
                                          aria-label="Upload"
                                          onChange={(e) => setTinDoc3(e.target.files[0])}
                                       />
                                       <Button
                                          className="upload-btn"
                                          disabled={!tinDoc3 || uploadingTinDoc3}
                                          isLoading={uploadingTinDoc3}
                                          onClick={() => uploadBizDoc('tin_document_three')}
                                       >
                                          Upload TIN Document Three
                                       </Button>
                                    </div>

                                    <div className="input-group mt-5 doc-file-input-group">
                                       <input
                                          type="file"
                                          className="form-control file-input"
                                          aria-label="Upload"
                                          onChange={(e) => setTinDoc4(e.target.files[0])}
                                       />
                                       <Button
                                          className="upload-btn"
                                          disabled={!tinDoc4 || uploadingTinDoc4}
                                          isLoading={uploadingTinDoc4}
                                          onClick={() => uploadBizDoc('tin_document_four')}
                                       >
                                          Upload TIN Document Four
                                       </Button>
                                    </div>
                                 </>
                              )}
                           </div>
                        </DocumentSection>
                     ) : (
                        <div />
                     )}
                  </>
               )}

               {(bizDetails?.tin_document_two || bizDetails?.tin_number_status === rejected) &&
                  bizDetails?.tin_document_status !== accepted && (
                     <DocumentSection>
                        <DocumentHeading>
                           <p className="doc-label">Additional Documents for TIN 02</p>
                           <Status status={bizDetails?.tin_document_status}>
                              {statusValue(bizDetails?.tin_document_status)}
                           </Status>
                        </DocumentHeading>

                        <div className="mt-2">
                           {bizDetails?.tin_document_two && (
                              <DocumentControls>
                                 <DocumentPreviewer
                                    url={bizDetails?.tin_document_two}
                                    biz_account_id={bizDetails.biz_account_id}
                                    title="TIN Document 2"
                                 >
                                    <Button className="doc-btn me-3">Preview Document</Button>
                                 </DocumentPreviewer>

                                 <Button
                                    className="doc-btn bg-success me-3"
                                    onClick={() =>
                                       handleVerify('is_tin_document_verified', accepted)
                                    }
                                 >
                                    Approve
                                 </Button>

                                 {bizDetails?.tin_document_status !== rejected && (
                                    <Button
                                       className="doc-btn bg-danger"
                                       onClick={() =>
                                          handleVerify('is_tin_document_verified', rejected)
                                       }
                                    >
                                       Reject
                                    </Button>
                                 )}
                              </DocumentControls>
                           )}

                           {bizDetails?.tin_document_status !== accepted && (
                              <div className="input-group mt-5 doc-file-input-group">
                                 <input
                                    type="file"
                                    className="form-control file-input"
                                    aria-label="Upload"
                                    onChange={(e) => setTinDoc2(e.target.files[0])}
                                 />
                                 <Button
                                    className="upload-btn"
                                    disabled={!tinDoc2 || uploadingTinDoc2}
                                    isLoading={uploadingTinDoc2}
                                    onClick={() => uploadBizDoc('tin_document_two')}
                                 >
                                    Upload TIN Document Two
                                 </Button>
                              </div>
                           )}
                        </div>
                     </DocumentSection>
                  )}

               {(bizDetails?.tin_document_three || bizDetails?.tin_number_status === rejected) &&
                  bizDetails?.tin_document_status !== accepted && (
                     <DocumentSection>
                        <DocumentHeading>
                           <p className="doc-label">Additional Documents for TIN 03</p>
                           <Status status={bizDetails?.tin_document_status}>
                              {statusValue(bizDetails?.tin_document_status)}
                           </Status>
                        </DocumentHeading>

                        <div className="mt-2">
                           {bizDetails?.tin_document_three && (
                              <DocumentControls>
                                 <DocumentPreviewer
                                    url={bizDetails?.tin_document_three}
                                    biz_account_id={bizDetails.biz_account_id}
                                    title="TIN Document 3"
                                 >
                                    <Button className="doc-btn me-3">Preview Document</Button>
                                 </DocumentPreviewer>

                                 <Button
                                    className="doc-btn bg-success me-3"
                                    onClick={() =>
                                       handleVerify('is_tin_document_verified', accepted)
                                    }
                                 >
                                    Approve
                                 </Button>

                                 {bizDetails?.tin_document_status !== rejected && (
                                    <Button
                                       className="doc-btn bg-danger"
                                       onClick={() =>
                                          handleVerify('is_tin_document_verified', rejected)
                                       }
                                    >
                                       Reject
                                    </Button>
                                 )}
                              </DocumentControls>
                           )}

                           {bizDetails?.tin_document_status !== accepted && (
                              <div className="input-group mt-5 doc-file-input-group">
                                 <input
                                    type="file"
                                    className="form-control file-input"
                                    aria-label="Upload"
                                    onChange={(e) => setTinDoc3(e.target.files[0])}
                                 />
                                 <Button
                                    className="upload-btn"
                                    disabled={!tinDoc3 || uploadingTinDoc3}
                                    isLoading={uploadingTinDoc3}
                                    onClick={() => uploadBizDoc('tin_document_three')}
                                 >
                                    Upload TIN Document Three
                                 </Button>
                              </div>
                           )}
                        </div>
                     </DocumentSection>
                  )}

               {(bizDetails?.tin_document_four || bizDetails?.tin_number_status === rejected) &&
                  bizDetails?.tin_document_status !== accepted && (
                     <DocumentSection>
                        <DocumentHeading>
                           <p className="doc-label">Additional Documents for TIN 04</p>
                           <Status status={bizDetails?.tin_document_status}>
                              {statusValue(bizDetails?.tin_document_status)}
                           </Status>
                        </DocumentHeading>

                        <div className="mt-2">
                           {bizDetails?.tin_document_four && (
                              <DocumentControls>
                                 <DocumentPreviewer
                                    url={bizDetails?.tin_document_four}
                                    biz_account_id={bizDetails.biz_account_id}
                                    title="TIN Document 4"
                                 >
                                    <Button className="doc-btn me-3">Preview Document</Button>
                                 </DocumentPreviewer>

                                 <Button
                                    className="doc-btn bg-success me-3"
                                    onClick={() =>
                                       handleVerify('is_tin_document_verified', accepted)
                                    }
                                 >
                                    Approve
                                 </Button>

                                 {bizDetails?.tin_document_status !== rejected && (
                                    <Button
                                       className="doc-btn bg-danger"
                                       onClick={() =>
                                          handleVerify('is_tin_document_verified', rejected)
                                       }
                                    >
                                       Reject
                                    </Button>
                                 )}
                              </DocumentControls>
                           )}

                           {bizDetails?.tin_document_status !== accepted && (
                              <div className="input-group mt-5 doc-file-input-group">
                                 <input
                                    type="file"
                                    className="form-control file-input"
                                    aria-label="Upload"
                                    onChange={(e) => setTinDoc4(e.target.files[0])}
                                 />
                                 <Button
                                    className="upload-btn"
                                    disabled={!tinDoc4 || uploadingTinDoc4}
                                    isLoading={uploadingTinDoc4}
                                    onClick={() => uploadBizDoc('tin_document_four')}
                                 >
                                    Upload TIN Document Four
                                 </Button>
                              </div>
                           )}
                        </div>
                     </DocumentSection>
                  )}
            </>
         )}
         <RejectDocument
            open={openRejectModal}
            onClose={() => setOpenRejectModal(false)}
            rejectType={rejectType}
            bizName={bizDetails?.biz_name}
            recipient={bizDetails?.account_holder_details?.email}
         />
      </DocumentsWrapper>
   );
};

Documents.propTypes = {
   businessAccountId: number.isRequired,
};

export default Documents;
