import { Http } from 'services/http';
import { logActivity } from 'services/activity';
import { paramsObjectToQueryString, trimObject } from 'utils/others';
import { getDocumentType } from 'utils/business';
import { manual_debit, refund, reassign_managers, can_view_limit_boards } from 'utils/user_actions';

export const getUnApprovedAccounts = (page, payload) =>
   Http(`/backmen/filter_unapproved_accounts/${paramsObjectToQueryString(page)}`, {
      method: 'POST',
      body: trimObject(payload),
   });

export const getFollowupAccounts = (page, payload) =>
   Http(`/backmen/list_follow_up_strict/${paramsObjectToQueryString(page)}`, {
      method: 'POST',
      body: trimObject(payload),
   });

export const getBusinessRequiresTin = (page, payload) =>
   Http(`/backmen/all_tin_document_biz/${paramsObjectToQueryString(page)}`, {
      method: 'POST',
      body: payload,
   });

export const getPreapprovedAccounts = (page) =>
   Http(`/backmen/all_pre_approved_biz/${paramsObjectToQueryString(page)}`);

export const changeStatus = async (payload, type) => {
   try {
      const response = await Http('/backmen/manager_change_status/', {
         method: 'POST',
         body: payload,
      });
      await logActivity(
         `Changes business status to ${payload.biz_current_status}`,
         type || 'reg',
         payload.biz_account_id || payload.unregistered_biz_id,
      );
      return response;
   } catch (e) {
      throw new Error(e.message);
   }
};

export const accountStats = (payload) =>
   Http(`/backmen/based_on_current_n_status/`, {
      method: 'POST',
      body: payload,
   });

export const getPendingBusiness = () =>
   Http(`/backmen/all_pending_biz_doc/`, {
      action: '/approver',
   });

export const getLatestPendingBusiness = (payload) =>
   Http(`/backmen/new_pending_approval_feed/`, {
      body: payload,
      method: 'POST',
   });

export const documentAction = async (payload, type, action) => {
   try {
      const response = await Http(`/backmen/biz_kyc_verify/`, {
         body: payload,
         method: 'POST',
         action: 'accept_or_reject_documents',
      });
      await logActivity(
         `${action.charAt(0).toUpperCase() + action.slice(1)} Document - ${getDocumentType(type)}`,
         'reg',
         payload.biz_account_id,
      );
      return response;
   } catch (e) {
      throw new Error(e.message);
   }
};

export const preapproveBusinessWithLimit = async (payload) => {
   try {
      const response = await Http(`/backmen/pre_approve_direct_biz/`, {
         body: payload,
         method: 'POST',
      });
      await logActivity(`Preapproved Business With Limit`, 'reg', payload.biz_account_id);
      return response;
   } catch (e) {
      throw new Error(e.message);
   }
};

export const updateBusinessDetails = async (payload, type) => {
   try {
      const response = await Http(`/backmen/upper_self_biz_update/`, {
         body: payload,
         method: 'POST',
         action: 'update_business_details',
      });
      await logActivity(`Updated Business Details - ${type}`, 'reg', payload.biz_account_id);
      return response;
   } catch (e) {
      throw new Error(e.message);
   }
};

export const sendMail = (payload) =>
   Http(`/backmen/direct_send_customer_mail/`, {
      body: payload,
      method: 'POST',
      action: 'send_messages',
   });

export const userSearch = (payload) => {
   logActivity(`Searched for member - ${payload}`);
   return Http(`/backmen/prospa_customers_search/?q=${payload}`, {
      action: 'business_search',
   });
};
export const transactionSearch = (payload) => {
   logActivity(`Searched for transaction - ${payload}`);
   return Http(`/backmen/backdoor_find_transaction/?q=${payload}`);
};
export const businessSearch = (payload) => {
   logActivity(`Searched for business - ${payload}`);
   return Http(`/backmen/prospa_bizness_search/?q=${payload}`);
};
export const registrationsSearch = (payload) => {
   logActivity(`Searched for business registration - ${payload}`);
   return Http(`/backmen/prospa_unregistered_search/?q=${payload}`);
};

export const getAllManagers = (page) =>
   Http(`/backmen/psp_onboarded_managers/${paramsObjectToQueryString(page)}`);

export const getAllManagersNew = () => Http(`/backmen/psp_onboarded_managers_diff/`);

export const AddManagerCalendar = (payload) =>
   Http(`/backmen/psp_add_manager_calendar/`, { method: 'POST', body: payload });
export const fetchManagerCalendarData = () => Http(`/backmen/psp_add_manager_calendar/`);

export const targetStats = () => Http(`/backmen/partner_target_stat/`);

export const getApprovedAccounts = (page, payload = {}) =>
   Http(`/backmen/approved_biz_under_buildpartner/${paramsObjectToQueryString(page)}`, {
      method: 'POST',
      body: trimObject(payload),
   });

export const getActivationAccounts = (payload = {}) => {
   return Http(`/backmen/activation_bizacct_view${paramsObjectToQueryString(payload)}`);
};

export const assignActivationManager = async (id, payload = {}) => {
   try {
      const response = await Http(`/backmen/assign_account/${id}/`, {
         method: 'PATCH',
         body: payload,
      });
      await logActivity(`Assigned Activations Manager`, 'reg', id);
      return response;
   } catch (e) {
      throw new Error(e.message);
   }
};

export const reAssignActivationManager = async (id, payload = {}) => {
   try {
      const response = await Http(`/backmen/re_assign_account/${id}/`, {
         method: 'PATCH',
         body: payload,
         action: reassign_managers,
      });
      await logActivity(`Re-Assigned Activations Manager`, 'reg', id);
      return response;
   } catch (e) {
      throw new Error(e.message);
   }
};

export const getActivationStats = (payload = {}) =>
   Http(`/backmen/activation_stats${paramsObjectToQueryString(payload)}`);

export const getProspectData = (page, payload = {}) =>
   Http(`/backmen/run_check_partner_target/${paramsObjectToQueryString(page)}`, {
      method: 'POST',
      body: trimObject(payload),
   });

export const buildPartnerAssignedBusiness = (id) => Http(`/backmen/build_partner_biz_stat/${id}/`);
export const getbuildPartnerFeed = (payload) =>
   Http(`/backmen/month_on_month_build_deposit/`, { method: 'POST', body: trimObject(payload) });

export const editProspect = (payload) =>
   Http(`/backmen/edit_partner_target/`, { method: 'POST', body: payload });

export const addProspect = async (payload) => {
   try {
      const response = await Http(`/backmen/add_partner_target/`, {
         method: 'POST',
         body: payload,
      });
      await logActivity(`Added a new prospect - ${payload.pool_name}`);
      return response;
   } catch (e) {
      throw new Error(e.message);
   }
};

export const listCategories = () => Http(`/extra/list_category/`);
export const createPool = async (payload) => {
   try {
      const response = await Http(`/backmen/create_new_biz_pool/`, {
         method: 'POST',
         body: payload,
      });
      await logActivity(`Created new custom column - ${payload.pool_name}`);
      return response;
   } catch (e) {
      throw new Error(e.message);
   }
};
export const addBizAccountToPool = (payload) =>
   Http(`/backmen/add_extra_biz_pool/`, { method: 'POST', body: payload });

export const listPools = () => Http(`/backmen/active_pool_by_manager/`);
export const listBizAccountPerPools = (page, payload) =>
   Http(`/backmen/run_pool_param_search/${paramsObjectToQueryString(page)}`, {
      method: 'POST',
      body: payload,
   });
export const deletePool = async (payload) => {
   try {
      const response = Http(`/backmen/delete_manager_grand_pool/`, {
         method: 'POST',
         body: payload,
      });
      await logActivity(`Deleted custom column id:${payload.pool_grand_id}`);
      return response;
   } catch (e) {
      throw new Error(e.message);
   }
};

export const removeBusinessAccountFromPool = async (payload) => {
   try {
      const response = await Http(`/backmen/remove_pool_biz/`, { method: 'POST', body: payload });
      await logActivity(`Removed Business from custom column`);
      return response;
   } catch (e) {
      throw new Error(e.message);
   }
};

export const getFollowUpFeed = async (payload = {}, type) => {
   try {
      const response = await Http(`/backmen/admin_biz_followup_feed/`, {
         method: 'POST',
         body: trimObject(payload),
      });
      await logActivity(
         type === 'start'
            ? `Started work on follow up stream`
            : 'Fetched next business on the followup stream',
      );

      return response;
   } catch (e) {
      throw new Error(e.message);
   }
};

export const getActivationFeed = async (payload = {}, type) => {
   try {
      const response = await Http(`/backmen/biz_activated_zero_transaction_feed/`, {
         method: 'POST',
         body: trimObject(payload),
      });
      await logActivity(
         type === 'start'
            ? `Started work on activation stream`
            : 'Fetched next business on the activation stream',
      );

      return response;
   } catch (e) {
      throw new Error(e.message);
   }
};

export const feedWorkerTracker = async (payload = {}, category, type) => {
   try {
      const response = await Http(`/backmen/admin_work_action/`, {
         method: 'POST',
         body: trimObject(payload),
      });
      await logActivity(
         type === 'end_work'
            ? `Ended work on ${category} stream`
            : `Clicked next business on ${category} stream`,
      );

      return response;
   } catch (e) {
      throw new Error(e.message);
   }
};

export const whatsappMessageTracker = async (payload) =>
   Http(`/backmen/whatsapp_message_tracka/`, {
      method: 'POST',
      body: trimObject(payload),
   });

export const getBizCurrentStatus = () => Http(`/backmen/list_biz_current_status/`);

export const addBizCurrentStatus = async (payload) =>
   Http('/backmen/add_admin_biz_current_status/', {
      body: payload,
      method: 'POST',
   });

export const documentTracker = async (payload) =>
   Http('/backmen/document_self_tracka/', {
      body: payload,
      method: 'POST',
   });

export const cancelActivePlan = async (payload) => {
   try {
      const response = await Http('/backmen/direct_biz_plan_cancel/', {
         body: payload,
         method: 'POST',
      });
      await logActivity('Billing Plan cancelled', 'reg', payload.biz_account_id);
      return response;
   } catch (e) {
      throw new Error(e.message);
   }
};

export const changeBillingPlan = async (payload) => {
   try {
      const response = await Http('/backmen/upgrade_account_plan', {
         body: payload,
         method: 'PATCH',
      });
      await logActivity(
         `Billing plan changed to ${payload.plan_name}`,
         'reg',
         payload.business_id,
         'account_upgrade',
      );
      return response;
   } catch (e) {
      throw new Error(e.message);
   }
};

export const getBillingPlans = async () =>
   Http('/backmen/prospa_boards?action_type=upgrade_biz_plan', {
      action: can_view_limit_boards,
   });

export const generateAccountStatementApi = async (payload = {}) => {
   try {
      const response = await Http(`/backmen/month_long_statement/`, {
         method: 'POST',
         body: trimObject(payload),
         action: 'generate_account_statement',
      });
      await logActivity('Account statement generated', 'reg', payload.biz_account_id);
      return response;
   } catch (e) {
      throw new Error(e.message);
   }
};
export const reactivateTerminatedAccountApi = async (payload = {}) => {
   try {
      const response = await Http(`/backmen/terminate_to_activate/`, {
         method: 'POST',
         body: trimObject(payload),
      });
      await logActivity(
         `Wallet ${payload.biz_wallet_id} was reactivated.`,
         'reg',
         payload.biz_account_id,
      );
      return response;
   } catch (e) {
      throw new Error(e.message);
   }
};

export const dealWithUserApi = async (payload = {}, isLastAction) => {
   const { message, ...rest } = payload;
   try {
      const response = await Http(`/backmen/deal_with_user/`, {
         method: 'POST',
         body: trimObject(rest),
         action: 'block_or_unblock_accounts',
      });
      if (isLastAction) {
         await logActivity(message, 'reg', payload.biz_account_id);
      }
      return response;
   } catch (e) {
      throw new Error(e.message);
   }
};

export const walletRefund = async (payload = {}) => {
   try {
      const response = await Http(`/backmen/transfer_refund/`, {
         method: 'POST',
         body: trimObject(payload),
      });
      await logActivity(`NGN ${payload.amount} wallet refund made `, 'reg', payload.biz_id, refund);
      return response;
   } catch (e) {
      throw new Error(e.message);
   }
};

export const walletDirectDebit = async (payload = {}) => {
   const { biz_id, ...remainingPayload } = payload;
   try {
      const response = await Http(`/backmen/deduct_manual_debit/`, {
         method: 'POST',
         body: trimObject(remainingPayload),
         action: manual_debit,
      });
      await logActivity(
         `NGN ${payload.amount} manual debit was made on Wallet ID:${payload.biz_wallet_id} `,
         'reg',
         biz_id,
         'manual_debit',
      );
      return response;
   } catch (e) {
      throw new Error(e.message);
   }
};

export const getMemberBusinessesList = (id) => Http(`/account/business_accounts/${id}`);

export const getBizActivatedZeroTransaction = (page, payload) =>
   Http(`/backmen/biz_activated_zero_transaction/${paramsObjectToQueryString(page)}`, {
      method: 'POST',
      body: payload,
   });

export const getBizCategory = () => {
   return Http(`/extra/list_category/`);
};

export const getNewBizCategories = () => {
   return Http(`/account/business_categories/`);
};

export const getMonthlyTurnover = () => {
   return Http(`/account/monthly_turnover_categories/`);
};

export const bulkAssignAccounts = (params, payload) => {
   return Http(`/backmen/bulk_assign_account_managers/${paramsObjectToQueryString(params)}`, {
      method: 'PUT',
      body: payload,
   });
};

export const bulkAssignCategories = (params, payload) => {
   return Http(
      `/backmen/bulk_assign_account_business_categories/${paramsObjectToQueryString(params)}`,
      {
         method: 'PUT',
         body: payload,
      },
   );
};

export const getNewAccounts = (page) => {
   return Http(
      `/backmen/unapproved_accounts_qualifying_questions/${paramsObjectToQueryString(page)}`,
   );
};
