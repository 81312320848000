import { Input } from 'components/input';
import Modal from 'components/modal';
import { func, bool } from 'prop-types';
import { InputWrapper } from 'styles/form';
import { Button } from 'components/button';
import { SelectInput } from 'components/select';
import { addProspect, getProspectData, listCategories } from 'services/business';
import { useEffect, useState } from 'react';
import moment from 'moment';
import toast from 'react-hot-toast';

const AddTargetModal = ({ open, onClose, handleRefetchProspectData }) => {
   const [categories, setCategories] = useState([]);
   const [filterData, setfilterData] = useState({});
   const [isLoading, setIsLoading] = useState(false);

   function onChange(name, data) {
      if (name === 'target_phone') {
         setfilterData({
            ...filterData,
            [name]: `+234${data.value}`,
         });
         return;
      }
      setfilterData({
         ...filterData,
         [name]: data.value,
      });
   }

   async function onSubmit() {
      try {
         setIsLoading(true);
         await addProspect(filterData);
         await getProspectData(
            {},
            {
               start_date: '2019-01-01',
               end_date: moment().format('YYYY-MM-DD'),
            },
         );
         handleRefetchProspectData();
         onClose();
      } catch (err) {
         toast.error(err.message);
         return err;
      } finally {
         setIsLoading(false);
      }
   }

   useEffect(() => {
      (async () => {
         try {
            const result = await listCategories();
            setCategories(
               result.data.map((item) => ({
                  value: item.category_name,
                  label: `${item.category_name}`,
               })),
            );
         } catch (err) {
            return err;
         }
      })();
   }, []);

   return (
      <Modal
         open={open}
         onClose={onClose}
         title="Add New Target"
         className="biz-details-modal"
         center
         width="750px"
      >
         <form>
            <InputWrapper className="grid-2-2">
               <Input
                  placeholder="First Name"
                  label="First Name"
                  type="text"
                  onChange={(e) => onChange('target_first_name', e.target)}
               />
               <Input
                  placeholder="Last Name"
                  label="Last Name"
                  type="text"
                  onChange={(e) => onChange('target_last_name', e.target)}
               />
            </InputWrapper>
            <InputWrapper className="grid-2-2">
               <Input
                  placeholder="Email Address"
                  label="Email"
                  name="target_email"
                  type="email"
                  onChange={(e) => onChange('target_email', e.target)}
               />
               <Input
                  placeholder=""
                  startAdornment="234"
                  label="Phone Number"
                  type="number"
                  onChange={(e) => onChange('target_phone', e.target)}
               />
            </InputWrapper>
            <InputWrapper className="grid-2-2">
               <Input
                  placeholder="Business Name"
                  label="Business Name"
                  type="text"
                  onChange={(e) => onChange('target_biz_name', e.target)}
               />
               <Input
                  placeholder="Address"
                  label="Address"
                  type="text"
                  onChange={(e) => onChange('target_address', e.target)}
               />
            </InputWrapper>
            <InputWrapper className="grid-2-2">
               <Input
                  placeholder="State"
                  label="State"
                  name="target_state"
                  type="text"
                  onChange={(e) => onChange('target_state', e.target)}
               />
               <Input
                  placeholder="City"
                  label="City"
                  type="text"
                  onChange={(e) => onChange('target_city', e.target)}
               />
            </InputWrapper>
            <InputWrapper>
               <SelectInput
                  onChange={(val) => onChange('target_biz_category', val)}
                  label="Business Category"
                  options={categories}
               />
            </InputWrapper>

            <InputWrapper
               style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
            >
               <div style={{ marginRight: '20px' }}>
                  <Button variant="secondary" type="button" onClick={onClose} fullWidth>
                     cancel
                  </Button>
               </div>

               <div>
                  <Button
                     variant="primary"
                     type="button"
                     onClick={onSubmit}
                     isLoading={isLoading}
                     disabled={isLoading}
                     fullWidth
                  >
                     Add
                  </Button>
               </div>
            </InputWrapper>
         </form>
      </Modal>
   );
};

export { AddTargetModal };

AddTargetModal.propTypes = {
   open: bool.isRequired,
   onClose: func.isRequired,
   handleRefetchProspectData: func.isRequired,
};
