import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import {
   clearStorage,
   getToken,
   getUserInfoFromStorage,
   saveUserInfoToStorage,
} from 'utils/storage';
import { AuthContext } from 'context/auth';
import { SET_CURRENT_USER, CLEAR_CURRENT_USER } from 'utils/constants';
import { logoutUser } from 'services/auth';
import { initializeUserPermissions } from 'utils/user_access';

const setCurrentUser = (payload) => ({
   type: SET_CURRENT_USER,
   payload,
});

const clearCurrentUser = () => ({
   type: CLEAR_CURRENT_USER,
});

function useAuth() {
   const context = useContext(AuthContext);
   const { state, dispatch } = context;
   const history = useHistory();
   const userData = getUserInfoFromStorage();
   const { currentUser } = state;

   function checkSessionValidity() {
      try {
         const token = getToken();
         return !!token;
      } catch (err) {
         return false;
      }
   }

   async function logout() {
      try {
         clearStorage();
         await logoutUser();
         history.replace('/login?redirected=true');
         dispatch(clearCurrentUser());
      } catch (error) {
         return error;
      }
   }

   function setAuthenticatedUser(result) {
      dispatch(setCurrentUser(result));
   }

   async function initUserSession() {
      if (checkSessionValidity()) {
         const token = await getToken();
         const access = await initializeUserPermissions(token, userData);
         setAuthenticatedUser({ token, ...userData, access });
         saveUserInfoToStorage({ ...userData, access });
         return;
      }
      setAuthenticatedUser({});
   }

   if (context === undefined) {
      throw new Error('useSnackbar must be used within a SnackbarProvider');
   }

   return {
      currentUser,
      checkSessionValidity,
      initUserSession,
      setAuthenticatedUser,
      isAuthenticated: checkSessionValidity(),
      logout,
   };
}

export { useAuth };
