import React, { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import { hasValue } from 'utils/validations';
import { Button } from 'components/button';
import { confirm } from 'components/confirm-box';
import { Input, NumberInput } from 'components/input';
import { getBusinessDetails } from 'services/businessDetails';
import { InputWrapper } from 'styles/form';
import { walletDirectDebit } from 'services/business';
import { useDebounce } from 'use-debounce';

export default function DirectDebit() {
   const [walletId, setWalletId] = useState('');
   const [bizDetails, setBizDetails] = useState(null);
   const [walletDetails, setWalletDetails] = useState(null);
   const [amount, setAmount] = useState('');
   const [bizAccountId, setBizAccountId] = useState('');
   const [reason, setReason] = useState('');
   const [loading, setLoading] = useState(false);
   const [debouncedSearchTerm] = useDebounce(bizAccountId, 500);

   const isValid =
      hasValue(walletId) && hasValue(amount) && hasValue(bizAccountId) && walletDetails;

   const getWalletDetails = (data) =>
      data?.biz_account_wallet_details.find(
         // eslint-disable-next-line eqeqeq
         (item) => item.biz_wallet_id == walletId,
      );

   useEffect(
      () => {
         if (debouncedSearchTerm && debouncedSearchTerm !== '') {
            getBusinessDetails(debouncedSearchTerm).then((results) => {
               setBizDetails(results.data);
               if (hasValue(walletId)) {
                  setWalletDetails(getWalletDetails(results.data));
               }
            });
         }
      },
      [debouncedSearchTerm], // Only call effect if debounced search term changes
   );

   useEffect(() => {
      if (bizDetails && walletId) {
         setWalletDetails(getWalletDetails(bizDetails));
      }
   }, [walletId]);

   const debitWallet = async () => {
      if (!isValid) return toast.error('Please enter a valid email address');
      setLoading(true);

      try {
         const payload = {
            biz_wallet_id: walletId,
            debit_action_type: 'debit_direct',
            tx_note: reason,
            amount,
            action_extra: 'no_payback', // payback,
            biz_id: bizAccountId,
         };
         const response = await walletDirectDebit(payload);
         setAmount('');
         setBizAccountId('');
         setWalletId('');
         setReason('');
         setBizDetails('');
         setWalletDetails('');
         toast.success(response.message);
      } catch (e) {
         toast.error(e?.message);
      }
      setLoading(false);
   };

   const confirmAction = () =>
      confirm({
         confirmText: 'Are you sure you want to continue ?',
         onConfirm: debitWallet,
      });

   return (
      <div className="row align-items-end">
         <InputWrapper className="col-lg-4">
            <Input
               placeholder="Enter Wallet ID"
               label="Wallet ID"
               value={walletId}
               onChange={(e) => setWalletId(e.target.value)}
            />
            {bizDetails && (
               <p className="mb-0">
                  {walletDetails ? `${walletDetails?.preferred_name}` : 'No wallet found'}
               </p>
            )}
         </InputWrapper>
         <InputWrapper className="col-lg-4">
            <Input
               placeholder="Enter Business ID"
               label="Business ID"
               value={bizAccountId}
               onChange={(e) => setBizAccountId(e.target.value)}
            />
            {bizDetails && <p className="mb-0">{bizDetails?.biz_friendly_name} </p>}
         </InputWrapper>
         <InputWrapper className="col-lg-4">
            <NumberInput
               label="Amount"
               name="amount"
               value={amount}
               prefix="₦"
               className={Input}
               inputmode="numeric"
               onChange={(valuesObject) => {
                  setAmount(valuesObject.floatValue);
               }}
            />
            {bizDetails && (
               <p className="mb-0">Wallet Available Balance: ₦{walletDetails?.available_balance}</p>
            )}
         </InputWrapper>
         <InputWrapper className="col-lg-4">
            <Input
               placeholder="Reason"
               label="Reason"
               value={reason}
               onChange={(e) => setReason(e.target.value)}
            />
         </InputWrapper>
         <InputWrapper className="col-lg-4">
            <Button
               variant="primary"
               onClick={confirmAction}
               isLoading={loading}
               disabled={!isValid}
            >
               Debit Wallet
            </Button>
         </InputWrapper>
      </div>
   );
}
