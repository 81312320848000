import { useState } from 'react';
import PropTypes from 'prop-types';
import ExtendedAmountInput from 'components/ExtendedAmountInput';
import { Button } from 'components/button';
import BackArrowIcon from 'assets/images/back-arrow.svg';
import { hasValue } from 'utils/validations';
import { InputFundAmountWrapper } from './InputFundAmount.style';

export default function InputFundAmount({ onBack, onClick, title }) {
   const [amount, setAmount] = useState('');

   const handleControls = (type) => {
      if (type === 'increment') {
         setAmount(amount + 100);
      } else if (type === 'decrement' && amount >= 100) {
         setAmount(amount - 100);
      }

      return null;
   };

   const handleContinue = () => {
      onClick({ amount });
   };

   const onChange = (target) => setAmount(target.floatValue);

   const valid = hasValue(amount) && amount > 0;

   return (
      <div>
         <InputFundAmountWrapper>
            <div className="withdraw-header">
               <img src={BackArrowIcon} alt="back" onClick={onBack} style={{ cursor: 'pointer' }} />
               <h3>{title}</h3>
            </div>

            <form>
               <ExtendedAmountInput
                  handleControls={handleControls}
                  name="amount"
                  required
                  label="Enter an amount"
                  onChange={onChange}
                  value={amount}
               />
            </form>
            <Button disabled={!valid} fullWidth onClick={handleContinue}>
               Continue
            </Button>
         </InputFundAmountWrapper>
      </div>
   );
}

InputFundAmount.propTypes = {
   onClick: PropTypes.func.isRequired,
   onBack: PropTypes.func.isRequired,
   title: PropTypes.string.isRequired,
};
