/* eslint-disable react/prop-types */
import { useState } from 'react';
import { TableContainer, TableContent } from 'styles/table';
import { bvnLookUp } from 'services/bvn';
import { TooltipComponent } from 'components/tooltip';
import { TableComponent } from 'components/table';
import { InputWrapper } from 'styles/form';
import { Button } from 'components/button';
import { Input } from 'components/input';
import { Skeleton } from '@material-ui/lab';
import toast from 'react-hot-toast';

function LookupBvn() {
   const [bvn, setBvn] = useState('');
   const [filtering, setFiltering] = useState(false);
   const [bvnDetailsTable, setBvnDetailsTable] = useState(null);
   const [isLoading] = useState(false);

   const bvnDetailsColumns = [
      {
         field: 'bvn_number',
         headerName: 'BVN',
         width: 150,
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.bvn_number}>{row.bvn_number || 'N/A'}</TooltipComponent>
         ),
      },
      {
         field: 'first_name',
         headerName: 'First Name',
         renderCell: ({ row }) => (
            <TooltipComponent title={row.first_name}>{row.first_name || 'N/A'}</TooltipComponent>
         ),
      },
      {
         field: 'last_name',
         headerName: 'Last Name',
         width: 150,
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.last_name}>{row.last_name || 'N/A'}</TooltipComponent>
         ),
      },
      {
         field: 'middle_name',
         headerName: 'Middle Name',
         width: 150,
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.last_name}>{row.last_name || 'N/A'}</TooltipComponent>
         ),
      },
      {
         field: 'dob',
         headerName: 'Date Of Birth',
         width: 150,
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.dob}>{row.dob || 'N/A'}</TooltipComponent>
         ),
      },
      {
         field: 'phone',
         headerName: 'Phone',
         width: 150,
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.phone}>{row.phone || 'N/A'}</TooltipComponent>
         ),
      },
   ];

   async function handleFilterFailedBvn() {
      if (bvn === '') return null;
      setFiltering(true);
      try {
         const bvnDetails = await bvnLookUp({
            bvn_number: bvn,
         });
         setBvnDetailsTable([
            {
               ...bvnDetails,
               bvn_number: bvn,
            },
         ]);
      } catch (e) {
         setBvnDetailsTable([]);
         toast.error(e.message || e);
      }
      setFiltering(false);
   }

   const isValid = bvn && bvn.length > 9;

   return (
      <>
         <div className="row align-items-end">
            <InputWrapper className="col-lg-4">
               <Input
                  placeholder="Enter BVN"
                  label="BVN"
                  value={bvn}
                  onChange={(e) => {
                     setBvn(e.target.value);
                     setBvnDetailsTable(null);
                  }}
               />
            </InputWrapper>
            <InputWrapper className="col-lg-4">
               <Button
                  variant="primary"
                  onClick={handleFilterFailedBvn}
                  className=""
                  isLoading={filtering}
                  disabled={!isValid}
               >
                  Lookup BVN
               </Button>
            </InputWrapper>
         </div>

         <div className="filter-result-container">
            {bvnDetailsTable && (
               <div className="mb-4">
                  {bvnDetailsTable?.length > 0 ? (
                     <TableContainer id="table">
                        <TableContent>
                           {isLoading ? (
                              <Skeleton width="30%" height="20" />
                           ) : (
                              <div>
                                 <h1>BVN Details </h1>
                              </div>
                           )}
                        </TableContent>
                        <TableComponent
                           columns={bvnDetailsColumns}
                           rows={bvnDetailsTable}
                           isLoading={isLoading}
                           count={Math.ceil(bvnDetailsTable.length / 15)}
                        />
                     </TableContainer>
                  ) : (
                     <h3>Unable to send BVN for processing</h3>
                  )}
               </div>
            )}
         </div>
      </>
   );
}

export default LookupBvn;
