import { useState } from 'react';
import toast from 'react-hot-toast';
import { func, bool, objectOf } from 'prop-types';
import Modal from 'components/modal';
import { InputWrapper } from 'styles/form';
import { Button } from 'components/button';
import { SelectInput } from 'components/select';
import FilterInputRender from 'components/FilterInputRender';

const ExportDataModal = ({ open, onClose, exportOptions, onSubmit }) => {
   const [isLoading, setIsLoading] = useState(false);
   const [exportState, setExport] = useState({});

   async function handleOnSubmit() {
      setIsLoading(true);
      try {
         const { download_type, ...remainingState } = exportState;
         const params = {
            download_type: download_type.value,
            ...remainingState,
         };
         await onSubmit(params);
      } catch (err) {
         toast.error(err.message || err.custom_message);
      }
      setIsLoading(false);
   }

   const handleSetExport = (val) => {
      setExport({ ...exportState, ...val });
   };

   const filters = exportState?.download_type?.filters;

   return (
      <Modal open={open} onClose={onClose} title="Filters" center maxWidth="700px">
         <form className="row">
            <InputWrapper className="grid-2-2">
               <SelectInput
                  onChange={(val) => setExport({ download_type: val })}
                  label="Export Type"
                  options={exportOptions}
                  defaultValue={exportState.download_type}
               />
            </InputWrapper>
            {filters &&
               filters.map((item) => (
                  <div className="col-md-6 mb-4">
                     <FilterInputRender
                        onChange={handleSetExport}
                        value={exportState}
                        item={item}
                     />
                  </div>
               ))}
            <InputWrapper
               style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
            >
               <div>
                  <Button
                     variant="primary"
                     type="button"
                     onClick={handleOnSubmit}
                     isLoading={isLoading}
                     disabled={isLoading || !exportState.download_type}
                     fullWidth
                  >
                     Export
                  </Button>
               </div>
            </InputWrapper>
         </form>
      </Modal>
   );
};

export default ExportDataModal;

ExportDataModal.propTypes = {
   open: bool.isRequired,
   onClose: func.isRequired,
   exportOptions: objectOf().isRequired,
   onSubmit: func.isRequired,
};
