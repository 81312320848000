/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import { bool, func, number, objectOf, string } from 'prop-types';
import { useAuth } from 'hooks/auth';
import { changeStatus, dealWithUserApi } from 'services/business';
import { MenuComponent } from 'components/menu';
import Avatar from 'assets/images/user.svg';
import { Status } from 'styles/utils';
import { Button } from 'components/button';
import useBusinessMeta from 'hooks/businessMeta';
import { confirm } from 'components/confirm-box';
import {
   approveBusiness,
   preapproveBusiness,
   updatePhoneVerificationStatus,
} from 'services/businessDetails';
import { toast } from 'react-hot-toast';
import { AvatarWrapper, Info, Name, TopSection, UserProfile } from 'styles/layout';

function UserProfileContainer({
   businessAccountId,
   bizDetails,
   fetchBusinessDetails,
   setLoading,
   noCloseIcon,
}) {
   const { currentUser } = useAuth();
   const [isApproving, setIsApproving] = useState(false);
   const [isBlocking, setIsBlocking] = useState(false);
   const [bizCurrentStatus, setBizCurrentStatus] = useState([]);
   const { businessCurrentStatus } = useBusinessMeta();
   const [anchorEl, setAnchorEl] = useState(null);
   const openStatusMenu = Boolean(anchorEl);
   const userImage = bizDetails?.account_holder_details?.photo_url || Avatar;
   const firstName = bizDetails?.account_holder_details?.first_name || '';
   const lastName = bizDetails?.account_holder_details?.last_name || '';
   const accountHolderName = `${firstName} ${lastName}`;
   const phone = bizDetails?.account_holder_details?.phone;
   const newPhone = bizDetails?.account_holder_details?.new_phone;
   const email = bizDetails?.account_holder_details?.email;
   const userId = bizDetails?.account_holder_details?.account_holder_id;

   useEffect(() => {
      if (businessCurrentStatus) {
         setBizCurrentStatus(
            businessCurrentStatus.map(({ current_status_tag }) => ({
               value: current_status_tag,
               options:
                  current_status_tag.charAt(0).toUpperCase() +
                  current_status_tag.slice(1).replaceAll('_', ' '),
            })),
         );
      }
   }, [businessCurrentStatus]);

   const handleCloseStatusMenu = () => {
      setAnchorEl(null);
   };

   const handleStatusButtonClick = (event) => {
      setAnchorEl(event.currentTarget);
   };

   const approveOrPreapprove = async () => {
      setIsApproving(true);
      try {
         let result;
         if (currentUser?.access?.permissions === 'all') {
            result = await approveBusiness({ biz_account_id: businessAccountId });
         } else {
            result = await preapproveBusiness({ biz_account_id: businessAccountId });
         }
         if (result.ok) {
            fetchBusinessDetails();
            toast.success(result?.message, { duration: 3000 });
         }
         setLoading(false);
         setIsApproving(false);
      } catch (err) {
         toast.error(err.message || err.custom_message);
         setLoading(false);
         setIsApproving(false);
      }
   };

   const handlePhoneVerificationStatus = async (type) => {
      setLoading(true);
      try {
         let status;
         if (type === 'approve') {
            status = 'accepted';
         } else {
            status = 'rejected';
         }

         const payload = {
            phone_verification_status: status,
         };

         if (newPhone) {
            payload.phone = newPhone;
         } else {
            payload.phone = phone;
         }

         const res = await updatePhoneVerificationStatus(userId, businessAccountId, payload);
         toast.success(res?.message);
         fetchBusinessDetails();
      } catch (e) {
         toast.error(e?.message);
      }
      setLoading(false);
   };

   const handlePhoneVerification = async (type) => {
      confirm({
         confirmText:
            type === 'approve'
               ? 'Are you sure want to approve this number?'
               : 'Are you sure want to reject this number?',
         isLoading: false,
         onConfirm: () => handlePhoneVerificationStatus(type),
      });
   };

   const changeStatusAction = async (status) => {
      const statusData = {
         biz_current_status: status,
         biz_account_id: businessAccountId,
      };
      try {
         const result = await changeStatus(statusData);
         if (result.ok) {
            fetchBusinessDetails();
            toast.success(result?.message, { duration: 3000 });
         }
      } catch (err) {
         toast.error(err.message || err.custom_message);
      }
   };

   const handleChangeStatus = async (status) => {
      confirm({
         confirmText: 'Are you sure you want to perform this action?',
         isLoading: false,
         onConfirm: () => changeStatusAction(status),
      });
   };

   const approveAccount = () => {
      confirm({
         confirmText: 'Are you sure you want to perform this action?',
         isLoading: isApproving,
         onConfirm: approveOrPreapprove,
      });
   };

   const blockAccount = (action_word, message) => {
      confirm({
         confirmText: 'Are you sure you want to block this account?',
         isLoading: isBlocking,
         onConfirm: async () => {
            action_word.forEach(async (action, index) => {
               setIsBlocking(true);
               const isLastAction = index === action_word.length - 1;
               try {
                  const response = await dealWithUserApi(
                     {
                        prospa_user_id: bizDetails.account_holder_details.account_holder_id,
                        action_word: action,
                        biz_account_id: businessAccountId,
                        message,
                     },
                     isLastAction,
                  );
                  if (isLastAction) {
                     toast.success(response?.message);
                     await fetchBusinessDetails();
                  }
               } catch (e) {
                  toast.error(e?.message);
               }
               setIsBlocking(false);
            });
         },
      });
   };

   const penalties = ['flagged_suspicious_activity_approved'];

   return (
      <>
         <TopSection>
            <UserProfile>
               <AvatarWrapper>
                  <img src={userImage} alt="account-holder-avatar" />
               </AvatarWrapper>
               <div>
                  <Name className="mb-2">{accountHolderName}</Name>
                  <Info>{phone}</Info>
                  <Info>{email}</Info>
                  <Status status={bizDetails?.biz_status}>{bizDetails?.biz_status}</Status>
               </div>
            </UserProfile>
            <div>
               {!noCloseIcon && bizDetails?.biz_status !== 'approved' && (
                  <div className="mb-3 d-flex justify-content-end">
                     <Button onClick={handleStatusButtonClick} className="ms-3 bg-secondary">
                        Status Actions
                     </Button>
                     <MenuComponent
                        anchorEl={anchorEl}
                        handleClose={handleCloseStatusMenu}
                        open={openStatusMenu}
                        options={bizCurrentStatus.map((option) => option.options)}
                        optionsFns={bizCurrentStatus.map(
                           (option) => () => handleChangeStatus(option.value),
                        )}
                     />
                  </div>
               )}
               <div className="d-flex justify-content-end">
                  {bizDetails?.biz_status?.includes('blocked') ||
                  penalties?.includes(bizDetails?.biz_status) ? (
                     <Button
                        variant="primary"
                        onClick={() =>
                           blockAccount(['unblock_with_rule', 'unblock'], 'Account was unblocked')
                        }
                        isLoading={isBlocking}
                     >
                        Unblock Account
                     </Button>
                  ) : (
                     <>
                        <span className="me-3">
                           <Button
                              variant="secondary"
                              onClick={() =>
                                 blockAccount(['block_with_rule'], 'Account was blocked partially')
                              } // Can login but can't transact
                              isLoading={isBlocking}
                           >
                              Partial Block
                           </Button>
                        </span>

                        <Button
                           variant="danger"
                           onClick={() =>
                              blockAccount(
                                 ['block', 'block_with_rule'],
                                 'Account was blocked fully',
                              )
                           } // Can't login and can't transact(auto payments)
                           isLoading={isBlocking}
                        >
                           Block Account
                        </Button>
                     </>
                  )}
               </div>
            </div>
         </TopSection>
         {bizDetails?.phone_verification_status === 'call_requested' && (
            <div className="d-flex justify-content-between">
               <div className=" mt-5 text-wrap" style={{ width: '35rem' }}>
                  <b>Call Request</b>
                  <p className="mt-3">
                     {firstName} {lastName} has requested a call for phone number verification on{' '}
                     {newPhone ? (
                        <span>
                           <b>{newPhone}</b>
                        </span>
                     ) : (
                        <span>
                           <b>{phone}</b>
                        </span>
                     )}
                  </p>
               </div>
               <div className="d-flex gap-3 align-items-center ">
                  <Button onClick={() => handlePhoneVerification('approve')}>Approve</Button>
                  <Button variant="danger" onClick={() => handlePhoneVerification('reject')}>
                     Reject
                  </Button>
               </div>
            </div>
         )}
      </>
   );
}

UserProfileContainer.propTypes = {
   businessAccountId: number.isRequired,
   bizDetails: objectOf(string).isRequired,
   fetchBusinessDetails: func.isRequired,
   setLoading: func.isRequired,
   noCloseIcon: bool.isRequired,
};
export default UserProfileContainer;
