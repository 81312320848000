import React, { useState, useEffect } from 'react';
import { Skeleton } from '@material-ui/lab';
import toast from 'react-hot-toast';
import { numberWithCommas } from 'utils/others';
import { getAllFixedDeposits, updateFixedDepositsAccount } from 'services/fixed-deposits';
import { TooltipComponent } from 'components/tooltip';
import { TableComponent } from 'components/table';
import { TableContainer, TableContent } from 'styles/table';
import BusinessDetails from 'containers/business-details';
import { Button } from 'components/button';
import { confirm } from 'components/confirm-box';

export default function WithdrawalTab() {
   const [modalVisibility, setModalVisibility] = useState(false);
   const [isLoading, setIsLoading] = useState(false);
   const [tableData, setTableData] = useState([]);
   const [paginationData, setPaginationData] = useState({});
   const [currentPage, setCurrentPage] = useState(1);
   const [bizAccount, setBizAccount] = useState('');

   const fetchWithdrawalData = async () => {
      setIsLoading(true);
      const payload = {
         page: currentPage,
         payment_status: 'pending_withdraw_approval',
      };
      setTableData([]);
      try {
         const savings = await getAllFixedDeposits(payload);
         setPaginationData(savings);
         setTableData(savings.results);
         setIsLoading(false);
      } catch (e) {
         toast.error(e.message);
         setIsLoading(false);
      }
   };

   useEffect(() => {
      fetchWithdrawalData();
   }, [currentPage]);

   const handleApproveOrRejectTransaction = (type, id) => {
      setIsLoading(true);
      confirm({
         confirmText:
            type === 'approve'
               ? 'Are you sure want to approve this transaction?'
               : 'Are you sure want to reject the transaction?',
         onConfirm: async () => {
            try {
               const payload = {
                  id,
                  payment_status: type === 'approve' ? 'withdraw_approved' : 'withdraw_rejected',
               };
               const res = await updateFixedDepositsAccount(payload);
               toast.success(res.message || 'Action Successful');
               fetchWithdrawalData();
               setIsLoading(false);
            } catch (e) {
               toast.error(e?.message || 'Something went wrong');
               setIsLoading(false);
            }
         },
      });
   };

   function onPageChange(e, pageNumber) {
      setCurrentPage(pageNumber);
   }

   function onRowClick({ row }) {
      setBizAccount(row);
   }

   function handleOpenModal() {
      setModalVisibility('details');
   }

   const columns = [
      {
         field: 'business_name',
         headerName: 'Business Name',
         renderCell: ({ row }) => (
            <TooltipComponent title={row.business_name}>
               <span
                  style={{
                     cursor: 'pointer',
                  }}
                  className="text-primary"
                  onClick={() => handleOpenModal(row.business_name)}
               >
                  {row.business_name || 'N/A'}
               </span>
            </TooltipComponent>
         ),
      },
      {
         field: 'total_balance',
         headerName: 'Total Savings Balance',
         renderCell: ({ row }) => (
            <span style={{ textTransform: 'capitalize' }}>
               {`₦ ${numberWithCommas(row?.safe?.safe_wallet_details?.total_balance)}`}
            </span>
         ),
      },

      {
         field: 'lifetime_interest',
         headerName: 'Total Interest earned ',
         renderCell: ({ row }) => (
            <span style={{ textTransform: 'capitalize' }}>{`₦ ${numberWithCommas(
               row.lifetime_interest,
            )}`}</span>
         ),
      },
      {
         field: 'interest_rate',
         headerName: 'Interest rate',
         renderCell: ({ row }) => (
            <span style={{ textTransform: 'capitalize' }}>{`${row.interest_rate}%`}</span>
         ),
      },
      {
         field: 'lock_amount',
         headerName: 'Withdrawal Amount',
         renderCell: ({ row }) => (
            <span style={{ textTransform: 'capitalize' }}>{`₦ ${numberWithCommas(
               row.lock_amount,
            )}`}</span>
         ),
      },
      {
         field: '',
         width: 500,
         renderCell: ({ row }) => (
            <div className="d-flex gap-3 m-2">
               <Button onClick={() => handleApproveOrRejectTransaction('approve', row?.id)}>
                  Approve
               </Button>
               <Button
                  variant="danger"
                  onClick={() => handleApproveOrRejectTransaction('reject', row?.id)}
               >
                  Reject
               </Button>
            </div>
         ),
      },
   ];
   return (
      <div>
         <TableContainer>
            <TableContent>
               {isLoading ? (
                  <Skeleton width="30%" height="20" />
               ) : (
                  <div>
                     <h1>Withdrawal</h1>
                     <span className="value">{paginationData?.count || 'N/A'}</span>
                  </div>
               )}
            </TableContent>
            <TableComponent
               columns={columns}
               rows={tableData}
               isLoading={isLoading}
               onPageChange={onPageChange}
               count={Math.ceil(paginationData.count / 15)}
               onRowClick={onRowClick}
            />
         </TableContainer>

         {modalVisibility && (
            <BusinessDetails
               businessAccountId={bizAccount.biz_account_id}
               open={modalVisibility}
               onClose={() => setModalVisibility(false)}
            />
         )}
      </div>
   );
}
