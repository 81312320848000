import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import toast from 'react-hot-toast';
import { HeadingOne } from 'styles/typography';
import moment from 'moment';
import { TableComponent } from 'components/table';
import { TableContainer } from 'styles/table';
import { Status } from 'styles/utils';
import { TooltipComponent } from 'components/tooltip';
import { useBizDetailsModal } from 'hooks/bizDetailsModal';
import BusinessDetails from 'containers/business-details';
import { getReferEarnLogs } from 'services/refer-and-earn';

const ReferEarnLogs = () => {
   const { setActiveTab } = useBizDetailsModal();
   const { search } = useLocation();
   const page = new URLSearchParams(search).get('page');
   const [approvedAccountData, setApprovedAccountData] = useState([]);
   const [isLoadingApprovedData, setIsLoadingApprovedData] = useState(false);
   const [paginationData, setPaginationData] = useState({});
   const [currentPage, setCurrentPage] = useState(1);
   const [modalVisibility, setModalVisibility] = useState(false);
   const [bizAccount, setBizAccount] = useState('');
   const [noCloseIcon, setNoCloseIcon] = useState(false);

   const fetchUnApprovedAccounts = async () => {
      try {
         setIsLoadingApprovedData(true);
         setApprovedAccountData([]);
         setPaginationData({});
         const response = await getReferEarnLogs({ page });
         setApprovedAccountData(response.data);
         setPaginationData(response);
      } catch (err) {
         toast.error(err.message);
      } finally {
         setIsLoadingApprovedData(false);
      }
   };

   useEffect(() => {
      fetchUnApprovedAccounts();
   }, [page]);

   function handleOpenModal() {
      setActiveTab('overview');
      setModalVisibility(true);
      setNoCloseIcon(false);
   }

   function onPageChange(e, pageNumber) {
      setCurrentPage(pageNumber);
   }

   const columns = [
      {
         field: 'referred_account',
         headerName: 'Referred Account',
         renderCell: ({ row }) => (
            <TooltipComponent title={row.referred_account}>
               <span
                  className="text-primary"
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleOpenModal(row.referred_account_id)}
               >
                  {row.referred_account || 'N/A'}
               </span>
            </TooltipComponent>
         ),
      },

      {
         field: 'code_used',
         headerName: 'Code Used',
         renderCell: ({ row }) => (
            <span style={{ textTransform: 'capitalize' }}>{row?.code_used} </span>
         ),
      },

      {
         field: 'referer',
         headerName: 'Referrer',
         renderCell: ({ row }) => (
            <TooltipComponent title={row.referer}>
               <span
                  style={{
                     cursor: 'pointer',
                  }}
                  className="text-primary"
               >
                  {row.referer || 'N/A'}
               </span>
            </TooltipComponent>
         ),
      },

      {
         field: 'referral_status',
         headerName: 'Referral Status',
         renderCell: ({ row }) => (
            <Status status={row?.referral_status}>{row?.referral_status || 'N/A'}</Status>
         ),
      },
      {
         field: 'referral_date',
         headerName: 'Referral date',
         renderCell: ({ row }) => (
            <span>
               {moment(row?.referral_date).format('MMM DD, YYYY')} |{' '}
               {moment(row?.referral_date).format('h:mm a')}
            </span>
         ),
      },
   ];
   function onRowClick({ row }) {
      setBizAccount(row.referred_account_id);
   }

   return (
      <>
         <HeadingOne className="mb-5">Refer and Earn Logs</HeadingOne>
         <TableContainer id="table">
            <TableComponent
               columns={columns}
               rows={approvedAccountData}
               isLoading={isLoadingApprovedData}
               onPageChange={onPageChange}
               count={Math.ceil(paginationData.count / 15)}
               page={currentPage}
               pageSize={20}
               onRowClick={onRowClick}
            />
         </TableContainer>
         {modalVisibility && (
            <BusinessDetails
               businessAccountId={bizAccount}
               open={modalVisibility}
               onClose={() => setModalVisibility(false)}
               noCloseIcon={noCloseIcon}
            />
         )}
      </>
   );
};

export default ReferEarnLogs;
