/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import moment from 'moment';
import toast from 'react-hot-toast';
import clsx from 'classnames';
import ArrowRight from 'assets/images/arrow-right.svg';
import ArrowLeft from 'assets/images/arrow-left.svg';
import { bool, func, number, string, objectOf } from 'prop-types';
import { Button } from 'components/button';
import { Spinner } from 'components/spinner';
import Modal from 'components/modal';
import {
   getTicketCategories,
   deleteTicketCategory,
   getTicketFaq,
   updateTicketCategoryName,
} from 'services/ticketing';
import { confirm } from 'components/confirm-box';
import { Input } from 'components/input';
import { useAuth } from 'hooks/auth';
import { hasPermission } from 'utils/user_access';
import { UpdateCategoryWrapper } from '../../ticketing.style';
import AddCategory from './add-category';
import SubCategories from './sub-categories';
import Faq from './faq';

const TicketDetails = ({ open, onClose }) => {
   const [activeCategory, setActiveCategory] = useState(null);
   const { currentUser } = useAuth();
   const [categories, setCategories] = useState([]);
   const [categoryState, setCategoryState] = useState('');
   const [updatingCategory, setUpdatingCategory] = useState(false);
   const [faq, setFaq] = useState([]);
   const [loadingCategory, setLoadingCategory] = useState(false);
   const [deletingCategory, setDeletingCategory] = useState(false);
   const selectedCategory = categories.find((item) => item.id === activeCategory);
   const [isEdit, setIsEdit] = useState(null);
   const { permissions } = currentUser?.access || {};
   const allowManage = hasPermission(permissions, 'update_delete_ticket_category');

   const confirmConfig = {
      cancelBtnText: 'Cancel',
      proceedBtnText: 'Delete',
      proceedBtnColor: 'danger',
      cancelBtnColor: 'primary',
   };

   const fetchTicketCategories = async () => {
      setLoadingCategory(true);
      try {
         const response = await getTicketCategories();
         setCategories(response.data);
      } catch (e) {
         toast.error(e?.message);
      }
      setLoadingCategory(false);
   };

   useEffect(() => {
      fetchTicketCategories();
   }, []);

   const fetchTicketFaq = async () => {
      try {
         const response = await getTicketFaq({
            category: selectedCategory?.category.replaceAll(' ', '+'),
         });
         setFaq(response.data.filter((item) => item.id));
      } catch (e) {
         toast.error(e?.message);
      }
   };

   function deleteCategory(id) {
      confirm({
         confirmText:
            'Are you sure you want to continue with this action as this will delete all the content in this category?',
         ...confirmConfig,
         isLoading: deletingCategory,
         onConfirm: async () => {
            try {
               setDeletingCategory(true);
               await deleteTicketCategory(id);
               await fetchTicketCategories();
               setActiveCategory(null);
               toast.success('Ticket category has been deleted successfully');
               setDeletingCategory(false);
            } catch (e) {
               toast.error(e?.message);
            }
         },
      });
   }

   useEffect(() => {
      if (activeCategory && selectedCategory) {
         fetchTicketFaq();
      }
   }, [activeCategory, selectedCategory]);

   const isCategoryEdit = isEdit === 'category';

   useEffect(() => {
      if (isCategoryEdit) {
         setCategoryState(selectedCategory?.category);
      }
   }, [isCategoryEdit]);

   const updateCategory = async () => {
      setUpdatingCategory(true);
      try {
         const response = await updateTicketCategoryName(activeCategory, {
            category: categoryState,
         });
         setCategoryState('');
         toast.success(response.message);
         setIsEdit(null);
         await fetchTicketCategories();
      } catch (e) {
         toast.error(e?.message);
      }
      setUpdatingCategory(false);
   };

   return (
      <Modal
         open={open}
         onClose={onClose}
         closeOnOverlayClick={false}
         noCloseIcon={activeCategory}
         {...(!activeCategory && { title: 'Update Categories' })}
         className="biz-details-modal"
         titleStyle={{
            fontSize: '22px',
            marginBottom: '49px',
         }}
      >
         {!loadingCategory ? (
            <UpdateCategoryWrapper>
               {!activeCategory && (
                  <div className="categories">
                     <div className="category-list">
                        {categories.map((item) => (
                           <div
                              className="single-category d-flex align-items-center justify-content-between"
                              key={item.id}
                              onClick={() => setActiveCategory(item.id)}
                           >
                              <h3 className="mb-0">{item.category}</h3>
                              <img src={ArrowRight} alt="arrow" />
                           </div>
                        ))}
                     </div>
                     {hasPermission(permissions, 'add_ticket_category') && (
                        <div className="d-flex justify-content-end">
                           <Button onClick={() => setActiveCategory('add-category')}>
                              Add new category
                           </Button>
                        </div>
                     )}
                  </div>
               )}

               {activeCategory && (
                  <div className="manage-ticketing">
                     <img
                        src={ArrowLeft}
                        alt="back"
                        className="mb-5"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                           setActiveCategory(null);
                           setIsEdit(null);
                        }}
                     />

                     {activeCategory === 'add-category' ? (
                        <AddCategory fetchTicketCategories={fetchTicketCategories} />
                     ) : (
                        <div>
                           <div
                              className={clsx(
                                 'category-title d-flex justify-content-between align-items-center',
                                 {
                                    'edit-active': isCategoryEdit,
                                 },
                              )}
                           >
                              {isCategoryEdit ? (
                                 <>
                                    <Input
                                       placeholder="Category name"
                                       label="Category Name"
                                       name="category"
                                       value={categoryState}
                                       type="text"
                                       className="mb-5"
                                       onChange={({ target: { value } }) => setCategoryState(value)}
                                    />

                                    <Button
                                       isLoading={updatingCategory}
                                       onClick={() => updateCategory()}
                                    >
                                       Update Category
                                    </Button>
                                 </>
                              ) : (
                                 <h3>{selectedCategory?.category}</h3>
                              )}

                              {!isCategoryEdit && allowManage && (
                                 <div className="category-action d-flex">
                                    <Button
                                       size="small"
                                       className="me-4"
                                       onClick={() => setIsEdit('category')}
                                    >
                                       Edit
                                    </Button>
                                    <Button
                                       size="small"
                                       variant="danger"
                                       onClick={() => deleteCategory(selectedCategory?.id)}
                                    >
                                       Delete
                                    </Button>
                                 </div>
                              )}
                           </div>

                           <SubCategories
                              SubCategories={selectedCategory?.sub_categories}
                              isEdit={isEdit}
                              setIsEdit={setIsEdit}
                              fetchTicketCategories={fetchTicketCategories}
                              activeCategory={activeCategory}
                              allowManage={allowManage}
                           />

                           <Faq
                              faq={faq}
                              isEdit={isEdit}
                              setIsEdit={setIsEdit}
                              fetchTicketCategories={fetchTicketCategories}
                              activeCategory={activeCategory}
                              fetchTicketFaq={fetchTicketFaq}
                              allowManage={allowManage}
                           />
                        </div>
                     )}
                  </div>
               )}
            </UpdateCategoryWrapper>
         ) : (
            <Spinner />
         )}
      </Modal>
   );
};

TicketDetails.propTypes = {
   open: bool.isRequired,
   ticketId: number.isRequired,
   onClose: func.isRequired,
   businessAccountId: number.isRequired,
   type: string,
   dateRange: objectOf(string),
   noCloseIcon: bool,
   streamType: string.isRequired,
};
TicketDetails.defaultProps = {
   type: '',
   dateRange: {
      start_date: '2019-01-01',
      end_date: moment().format('YYYY-MM-DD'),
   },
   noCloseIcon: false,
};
export default TicketDetails;
