import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { hasValue } from 'utils/validations';
import { Button } from 'components/button';
import { confirm } from 'components/confirm-box';
import { Input, NumberInput } from 'components/input';
import { SelectInput } from 'components/select';
import { InputWrapper } from 'styles/form';
import { walletRefund } from 'services/business';

export default function Refund() {
   const [walletId, setWalletId] = useState('');
   const [amount, setAmount] = useState('');
   const [bizAccountId, setBizAccountId] = useState('');
   const [reason, setReason] = useState('');
   const [sessionID, setSessionID] = useState('');
   const [loading, setLoading] = useState(false);

   const isValid =
      hasValue(walletId) &&
      hasValue(amount) &&
      hasValue(bizAccountId) &&
      hasValue(sessionID) &&
      hasValue(reason);

   const refundReasons = [
      {
         value: 'plan upgrade',
         label: 'Plan Upgrade',
      },
      {
         value: 'airtime purchase',
         label: 'Airtime Purchase',
      },
      {
         value: 'bill payment',
         label: 'Bill Payment',
      },
      {
         value: 'delayed incoming',
         label: 'Delayed Incoming',
      },
      {
         value: 'manual reversal',
         label: 'Manual Reversal',
      },
      {
         value: 'business registration refund',
         label: 'Business Regsitration Refund',
      },
      {
         value: 'pos refund',
         label: 'POS Refund',
      },
      {
         value: 'pos inflow',
         label: 'POS Inflow',
      },
   ];

   const refundWallet = async () => {
      if (!isValid) return toast.error('Please enter a valid email address');
      setLoading(true);

      try {
         const payload = {
            biz_wallet_id: walletId,
            amount,
            biz_id: bizAccountId,
            refund_reason: reason.value,
            session_id: sessionID,
         };
         const response = await walletRefund(payload);
         setAmount('');
         setBizAccountId('');
         setWalletId('');
         setReason('');
         setSessionID('');
         toast.success(response.message);
      } catch (e) {
         toast.error(e?.message);
      }
      setLoading(false);
   };

   const confirmAction = () =>
      confirm({
         confirmText: 'Are you sure you want to continue ?',
         onConfirm: refundWallet,
      });

   return (
      <div className="row align-items-end">
         <InputWrapper className="col-lg-4">
            <Input
               placeholder="Enter Wallet ID"
               label="Wallet ID"
               value={walletId}
               onChange={(e) => setWalletId(e.target.value)}
            />
         </InputWrapper>
         <InputWrapper className="col-lg-4">
            <Input
               placeholder="Enter Business ID"
               label="Business ID"
               value={bizAccountId}
               onChange={(e) => setBizAccountId(e.target.value)}
            />
         </InputWrapper>
         <InputWrapper className="col-lg-4">
            <NumberInput
               label="Amount"
               name="amount"
               value={amount}
               prefix="₦"
               className={Input}
               inputmode="numeric"
               onChange={(valuesObject) => {
                  setAmount(valuesObject.floatValue);
               }}
            />
         </InputWrapper>
         <InputWrapper className="col-lg-4">
            <SelectInput
               name="reason"
               label="Reason"
               value={reason}
               options={refundReasons}
               onChange={(val) => setReason(val)}
            />
         </InputWrapper>
         <InputWrapper className="col-lg-4">
            <Input
               placeholder="Enter Session ID"
               label="Session ID"
               value={sessionID}
               onChange={(e) => setSessionID(e.target.value)}
            />
         </InputWrapper>
         <InputWrapper className="col-lg-4">
            <Button
               variant="primary"
               onClick={confirmAction}
               isLoading={loading}
               disabled={!isValid}
            >
               Refund
            </Button>
         </InputWrapper>
      </div>
   );
}
