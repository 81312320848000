import { useState, useEffect, useCallback } from 'react';
import { useAuth } from 'hooks/auth';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import { Skeleton } from '@material-ui/lab';
import FilterListIcon from '@material-ui/icons/FilterList';
import { hasPermission } from 'utils/user_access';
import { TableContainer, TableContent } from 'styles/table';
import { TooltipComponent } from 'components/tooltip';
import { TableComponent } from 'components/table';
import { getAdminLogs } from 'services/businessDetails';
import { FilterContainer } from 'pages/follow-up/follow-up.style';
import LogFilterModal from 'containers/filter-modal/log-filter';
import toast from 'react-hot-toast';

export default function ActivityLogs() {
   const { currentUser } = useAuth();
   const { permissions } = currentUser?.access || {};
   const { search } = useLocation();
   const page = new URLSearchParams(search).get('page');
   const [tableData, setTableData] = useState([]);
   const [, setError] = useState('');
   const [isLoading, setIsLoading] = useState(false);
   const [paginationData, setPaginationData] = useState({});
   const [currentPage, setCurrentPage] = useState(1);
   const [modalVisibilityFilter, setModalVisibilityFilter] = useState(false);
   const [filterData, setFilterData] = useState({
      start_date: moment().format('YYYY-01-01'),
      end_date: moment().format('YYYY-MM-DD'),
   });
   const [isInitialized, setIsInitialized] = useState(false);

   const [filterPayload, setFilterPayload] = useState({
      start_date: moment().format('YYYY-01-01'),
      end_date: moment().format('YYYY-MM-DD'),
   });

   const fetchActivityLogs = useCallback(async () => {
      if (!isInitialized) return;

      setIsLoading(true);
      try {
         const result = await getAdminLogs(filterPayload, { page: page || currentPage });
         if (result.ok) {
            setTableData(result?.data);
            setPaginationData(result);
            setError('');
            toast.success('Logs fetched successfully');
         }
      } catch (err) {
         setError(err.message || err.custom_message);
      } finally {
         setIsLoading(false);
      }
   }, [filterPayload, page, currentPage, isInitialized]);

   useEffect(() => {
      if (currentUser && permissions) {
         setFilterPayload((prevPayload) => {
            const newPayload = { ...prevPayload };
            if (!hasPermission(permissions, 'all')) {
               newPayload.manager_id = currentUser.prospa_user_id;
            } else {
               delete newPayload.manager_id;
            }
            return newPayload;
         });
         setIsInitialized(true);
      }
   }, [currentUser, permissions]);

   useEffect(() => {
      if (isInitialized) {
         fetchActivityLogs();
      }
   }, [fetchActivityLogs, isInitialized]);

   const columns = [
      {
         field: 'first_name',
         headerName: 'Admin Name',
         renderCell: ({ row }) => (
            <TooltipComponent
               title={`${row.manager_details.first_name} ${row.manager_details.last_name}`}
            >
               {`${row.manager_details.first_name} ${row.manager_details.last_name}` || 'N/A'}
            </TooltipComponent>
         ),
      },
      {
         field: 'description',
         headerName: 'Activity',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.description}>{row?.description}</TooltipComponent>
         ),
      },
      {
         field: 'single_target_object_details',
         headerName: 'Business',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.single_target_object_details?.name}>
               {row?.single_target_object_details?.name || ' - '}
            </TooltipComponent>
         ),
      },
      {
         field: 'timestamp',
         headerName: 'Time Stamp',
         renderCell: ({ row }) => (
            <TooltipComponent title={row.timestamp}>
               {moment(row.timestamp).format('DD MMM, YYYY')} |{' '}
               <span style={{ opacity: '.5' }}>{moment(row.timestamp).format('HH:mm A')}</span>
            </TooltipComponent>
         ),
      },
   ];

   function onPageChange(e, pageNumber) {
      setCurrentPage(pageNumber);
   }

   return (
      <>
         <TableContainer className="activity">
            <TableContent>
               <div className="d-flex justify-content-between w-100 align-items-center">
                  {isLoading ? (
                     <Skeleton width="30%" height="20" />
                  ) : (
                     <div>
                        <h1> All Logs</h1>
                        <span className="value">{paginationData?.count || 'N/A'}</span>
                     </div>
                  )}
                  <div className="d-flex align-items-center">
                     <FilterContainer onClick={() => setModalVisibilityFilter(true)}>
                        Filter By <FilterListIcon style={{ marginLeft: '10px' }} />
                     </FilterContainer>
                  </div>
               </div>
            </TableContent>
            <TableComponent
               columns={columns}
               rows={tableData}
               isLoading={isLoading}
               onPageChange={onPageChange}
               count={Math.ceil(paginationData.count / 15)}
            />
         </TableContainer>
         {modalVisibilityFilter && (
            <LogFilterModal
               open={modalVisibilityFilter}
               onClose={() => setModalVisibilityFilter(false)}
               filterData={filterData}
               setFilterData={setFilterData}
               setFilterPayload={setFilterPayload}
               canFilterByManager={hasPermission(permissions, 'all')}
               loggedInManagerId={currentUser.prospa_user_id}
            />
         )}
      </>
   );
}
