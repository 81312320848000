import { React, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { bool, func, objectOf } from 'prop-types';
import getSymbolFromCurrency from 'currency-symbol-map';
import Modal from 'components/modal';
import { Button } from 'components/button';
import { confirm } from 'components/confirm-box';
import { formatTag } from 'utils/tags';
import { Spinner } from 'components/spinner';
import {
   approveOrRejectInboundTransaction,
   getInboundTransactionDetail,
} from 'services/international-transactions';
import moment from 'moment';
import { InternationalTransferWrapper } from './style';

export default function InboundTransactionDetails({
   open,
   onClose,
   transactionDetail,
   fetchTransactions,
}) {
   const [details, setDetails] = useState({});
   const [loading, setLoading] = useState(false);

   const fetchDetails = async () => {
      setLoading(true);
      try {
         const res = await getInboundTransactionDetail(transactionDetail?.id);
         setDetails(res);
         setLoading(false);
      } catch (e) {
         toast.error(e?.message || ' Something went wrong');
      }
   };

   useEffect(() => {
      fetchDetails();
   }, []);

   const renderTransactionStatus = (row) => {
      let template = '';
      template =
         row?.payout_status === 'success'
            ? formatTag('accepted', 'Success')
            : formatTag(row?.payout_status, row?.payout_status);
      return template;
   };

   const { currency, amount, created_at } = details;

   const handleTransactionAction = (type) => {
      confirm({
         confirmText:
            type === 'approve'
               ? 'Are you sure you want to approve this transaction?'
               : 'Are you sure you want to reject this transaction?',
         isLoading: false,
         onConfirm: async () => {
            setLoading(true);
            const payload =
               type === 'approve' ? { payout_status: 'approved' } : { payout_status: 'rejected' };
            try {
               const res = await approveOrRejectInboundTransaction(details?.id, payload);
               toast.success(res?.message || 'Successful');
               setLoading(false);
               fetchDetails();
               fetchTransactions();
            } catch (e) {
               toast.error(e?.message);
               setLoading(false);
            }
         },
      });
   };

   return (
      <>
         <Modal
            title="Transaction Details"
            open={open}
            onClose={onClose}
            closeOnOverlayClick={false}
            className="biz-details-modal"
         >
            {loading ? (
               <Spinner />
            ) : (
               <>
                  <InternationalTransferWrapper>
                     <div className="row pt-5">
                        <div className="col-lg-3 col-md-6 ">
                           <div className="business-details-item">
                              <h3> Receiver Gets </h3>
                              <p>
                                 {`${getSymbolFromCurrency(currency) || 'N/A'}${parseFloat(
                                    amount || 'N/A',
                                 ).toLocaleString()}`}
                              </p>
                           </div>
                        </div>

                        <div className="col-lg-3 col-md-6 ">
                           <div className="business-details-item">
                              <h3>Status</h3>
                              <p>{renderTransactionStatus(details)}</p>
                           </div>
                        </div>

                        <div className="col-lg-3 col-md-6 ">
                           <div className="business-details-item">
                              <h3>Date Submitted</h3>
                              <p>
                                 {moment(created_at).format('DD MMM, YYYY')} |{' '}
                                 {moment().format('HH:mm A')}
                              </p>
                           </div>
                        </div>

                        <div className="d-flex mt-4 mb-5 align-items-center justify-content-between">
                           {details?.payin_status === 'partner_successful' &&
                              details?.payout_status === 'pending' && (
                                 <div className="d-flex align-items-center">
                                    <Button
                                       variant="danger"
                                       className="me-3"
                                       onClick={() => handleTransactionAction('reject')}
                                       disabled={loading}
                                    >
                                       Reject
                                    </Button>

                                    <Button
                                       variant="green"
                                       onClick={() => handleTransactionAction('approve')}
                                       disabled={loading}
                                    >
                                       Approve
                                    </Button>
                                 </div>
                              )}
                        </div>
                     </div>
                  </InternationalTransferWrapper>
               </>
            )}
         </Modal>
      </>
   );
}
InboundTransactionDetails.propTypes = {
   open: bool.isRequired,
   onClose: func.isRequired,
   transactionDetail: objectOf.isRequired,
   fetchTransactions: func.isRequired,
};
