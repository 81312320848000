import PropTypes from 'prop-types';
import { Info, SectionTitle } from 'styles/layout';
import { update_business_name, update_business_email } from 'utils/user_actions';
import UpdateBusinessEmail from './update-business-email';
import UpdateBusinessName from './update-business-name';

const ChangeBusinessDetails = ({ permissions }) => {
   const checkPermission = (action) => permissions?.includes(action) || permissions === 'all';

   return (
      <>
         {checkPermission(update_business_name) && (
            <Info className="mb-2">
               <b>UPDATE BUSINESS NAME</b>
               <UpdateBusinessName />
            </Info>
         )}

         {checkPermission(update_business_email) && (
            <>
               <SectionTitle />
               <Info className="mb-2">
                  <b>UPDATE BUSINESS EMAIL</b>
                  <UpdateBusinessEmail />
               </Info>
            </>
         )}
      </>
   );
};

ChangeBusinessDetails.propTypes = {
   permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default ChangeBusinessDetails;
