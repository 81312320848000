import { useReducer, createContext } from 'react';
import { node } from 'prop-types';
import { CLEAR_CURRENT_USER, SET_CURRENT_USER } from 'utils/constants';

const initialState = {
   currentUser: {},
};

const AuthContext = createContext({ state: initialState, dispatch: () => null });

function authReducer(state, action) {
   switch (action.type) {
      case SET_CURRENT_USER:
         return {
            ...state,
            currentUser: action.payload,
         };

      case CLEAR_CURRENT_USER:
         return {
            ...state,
            currentUser: {},
         };
      default: {
         throw new Error(`Unhandled action type: ${action.type}`);
      }
   }
}

function AuthContextProvider({ children }) {
   const [state, dispatch] = useReducer(authReducer, initialState);

   return <AuthContext.Provider value={{ state, dispatch }}>{children}</AuthContext.Provider>;
}

AuthContextProvider.propTypes = {
   children: node.isRequired,
};

export { AuthContextProvider, AuthContext };
