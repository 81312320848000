/* eslint-disable react/forbid-prop-types */
import { useState } from 'react';
import PropTypes, { bool, func, number } from 'prop-types';
import { Navigation, NavigationTab } from 'styles/layout';
import Modal from 'components/modal';
import PromotionsOverview from '../campaign-overview';
import { ChildCampaign } from '../list-child-campaigns';

const PromotionsDetails = ({
   open,
   fetchCampaigns,
   campaigns,
   campaignId,
   onClose,
   noCloseIcon,
}) => {
   const [activeTab, setActiveTab] = useState('overview');
   const [overviewModalVisibility, setOverviewModalVisibility] = useState('visible');

   const campaignDetails = campaigns.find((promo) => promo.campaign_id === campaignId);
   const childCampaignDetails = campaignDetails?.all_child_items;

   const activePage = () => {
      switch (activeTab) {
         case 'child-campaigns':
            return (
               <ChildCampaign
                  setOverviewModalVisibility={setOverviewModalVisibility}
                  campaignDetails={campaignDetails}
                  childCampaignDetails={childCampaignDetails}
                  fetchCampaigns={fetchCampaigns}
               />
            );

         case '':
         case 'overview':
         default:
            return <PromotionsOverview campaignDetails={campaignDetails} />;
      }
   };

   return (
      <Modal
         open={open}
         onClose={() => {
            setActiveTab(null);
            onClose();
         }}
         closeOnOverlayClick={false}
         noCloseIcon={noCloseIcon}
         modalStyle={{ visibility: overviewModalVisibility }}
         title="Promotions Details"
         className="biz-details-modal"
      >
         <div className="d-flex justify-content-between">
            <Navigation>
               <NavigationTab
                  className={activeTab === 'overview' ? 'active' : ''}
                  onClick={() => setActiveTab('overview')}
               >
                  Overview
               </NavigationTab>

               <NavigationTab
                  className={activeTab === 'child-campaigns' ? 'active' : ''}
                  onClick={() => setActiveTab('child-campaigns')}
               >
                  Child Campaigns
               </NavigationTab>
            </Navigation>
         </div>
         <div className="pt-5">{activePage()}</div>
      </Modal>
   );
};

PromotionsDetails.propTypes = {
   open: bool.isRequired,
   campaigns: PropTypes.arrayOf(PropTypes.any).isRequired,
   fetchCampaigns: func.isRequired,
   campaignId: number.isRequired,
   onClose: func.isRequired,
   noCloseIcon: bool,
};
PromotionsDetails.defaultProps = {
   noCloseIcon: false,
};
export default PromotionsDetails;
