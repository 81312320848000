import Modal from 'components/modal';
import React, { useState } from 'react';
import { toast } from 'react-hot-toast';
import { PropTypes } from 'prop-types';
import InputFundAmount from 'components/input-amount';
import { LockFundsModal } from 'components/lock-funds';
import { createFixedDepositsAccount } from 'services/fixed-deposits';
import { withdrawFromSavings } from 'services/savings';
import SelectBankAccount from 'components/select-bank-account';
import Success from 'components/success';

export default function CreateFixedDeposits({
   open,
   onClose,
   bizDetails,
   businessAccountId,
   fdDetails,
   fetchFixedDepositsAccount,
}) {
   const [step, setStep] = useState('input-amount');
   const [fixedDepositsDetails, setFixedDepositsDetails] = useState({});
   const [successModal, setSuccessModal] = useState(false);
   const [payload, setPayload] = useState({});
   const [loading, setLoading] = useState(false);

   const { safe } = fixedDepositsDetails || fdDetails;
   const { payment_status } = fdDetails || {};
   const { safe_wallet_details } = safe || {};

   const { biz_account_wallet_details } = bizDetails;

   const onClick = (data) => {
      setPayload((prevPayload) => ({ ...prevPayload, ...data }));
      setStep('lock-funds');
   };

   const handleAccountCreation = async (data) => {
      const { interest_rate, period } = data;
      const { amount } = payload;
      setPayload((prevPayload) => ({ ...prevPayload, ...data }));

      const params = {
         biz_account_id: businessAccountId,
         interest_rate,
         period: period?.value,
         lock_amount: amount,
      };
      try {
         const result = await createFixedDepositsAccount(params);
         setFixedDepositsDetails(result?.data);
         setStep('select-account');
      } catch (err) {
         toast.error(err?.message);
      }
   };

   const handleDeposit = async (data) => {
      const { biz_wallet_id } = data;
      const { amount } = payload;

      const params = {
         from_wallet_id: biz_wallet_id,
         to_wallet_id:
            safe_wallet_details?.biz_wallet_id ||
            fdDetails?.safe?.safe_wallet_details?.biz_wallet_id,
         amount,
      };
      setLoading(true);
      try {
         await withdrawFromSavings(params);
         setSuccessModal(true);
      } catch (e) {
         toast.error(e.message);
      }

      setLoading(false);
   };

   const handleAccountFunding = (data) => {
      if (fdDetails) {
         if (payment_status && payment_status === 'pending') {
            setStep('select-account');
         } else {
            handleAccountCreation(data);
         }
      } else {
         handleAccountCreation(data);
      }
   };

   const handleAfterSuccess = () => {
      onClose();
      setSuccessModal(false);
      fetchFixedDepositsAccount();
   };

   return (
      <>
         <Modal
            open={open}
            onClose={onClose}
            center
            noCloseIcon
            {...(step !== 'lock-funds' && { maxWidth: '400px' })}
            modalStyle={{ maxHeight: '450px' }}
         >
            {step === 'input-amount' && (
               <InputFundAmount
                  onBack={onClose}
                  onClick={onClick}
                  title="Create a Fixed Deposit Account"
               />
            )}
            {step === 'lock-funds' && (
               <LockFundsModal
                  bizDetails={bizDetails}
                  onSubmit={handleAccountFunding}
                  defaultFormValues={payload}
               />
            )}
            {step === 'select-account' && (
               <SelectBankAccount
                  onBack={() => setStep('lock-funds')}
                  bizWallets={biz_account_wallet_details?.filter(
                     (item) => item.biz_wallet_type !== 'fixed_deposits',
                  )}
                  onSubmit={handleDeposit}
                  loading={loading}
               />
            )}
         </Modal>
         {successModal && (
            <Success
               open={successModal}
               onClose={handleAfterSuccess}
               title="Fixed Deposit Account has been created"
            />
         )}
      </>
   );
}

CreateFixedDeposits.propTypes = {
   open: PropTypes.bool.isRequired,
   onClose: PropTypes.func.isRequired,
   bizDetails: PropTypes.objectOf().isRequired,
   fdDetails: PropTypes.objectOf().isRequired,
   businessAccountId: PropTypes.number.isRequired,
   fetchFixedDepositsAccount: PropTypes.func.isRequired,
};
