import { func, string } from 'prop-types';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { SelectInputComponentStyle, Label, ErrorP, ListboxComponent } from './select-input.styles';

const AutoCompleteSelect = ({ label, error, inputOnchange, ...rest }) => {
   return (
      <SelectInputComponentStyle error={error} className="other-selct">
         <Label className="select-label">{label}</Label>
         <div className="input-container">
            <Autocomplete
               ListboxComponent={ListboxComponent}
               {...rest}
               renderInput={(params) => (
                  <TextField
                     {...params}
                     onChange={inputOnchange}
                     label={label}
                     margin="normal"
                     variant="outlined"
                     InputProps={{ ...params.InputProps, type: 'search' }}
                     placeholder="Search Business"
                  />
               )}
            />
            {error && (
               <ErrorP
                  initial="pageInitial"
                  animate="pageAnimate"
                  exit="pageExit"
                  variants={{
                     pageInitial: {
                        opacity: 1,
                        x: 0,
                        y: -5,
                     },
                     pageAnimate: {
                        opacity: 1,
                        x: 0,
                        y: 0,
                     },
                     pageExit: {
                        opacity: 0,
                     },
                  }}
               >
                  {error}
               </ErrorP>
            )}
         </div>
      </SelectInputComponentStyle>
   );
};

AutoCompleteSelect.propTypes = {
   error: string.isRequired,
   label: string,
   inputOnchange: func.isRequired,
};

AutoCompleteSelect.defaultProps = {
   label: '',
};

export { AutoCompleteSelect };
