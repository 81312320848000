import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import LoggedInDashboard from 'components/logged-in-dashboard';
import TabMenu from 'components/TabMenu';
import { TabWrapper } from 'pages/pending-documents/style';
import { useAuth } from 'hooks/auth';
import { can_view_exchange_rates } from 'utils/user_actions';
import { hasPermission } from 'utils/user_access';
import { ExchangeRate } from './features/exchange-rate';
import TransactionsDashboard from './features/transactions';
import InboundTransactionsDashboard from './features/inbound-transactions';
import { InternationalTransactionsSettings } from './features/settings';

function InternationalTransactions() {
   const history = useHistory();
   const { pathname } = useLocation();
   const { currentUser } = useAuth();
   const { permissions } = currentUser?.access || {};
   const [activeTab, setActiveTab] = useState('transactions');

   const handleActiveTab = (value) => {
      if (value !== activeTab) {
         history.push(pathname);
      }
      setActiveTab(value);
   };

   const tabs = [
      {
         label: 'Transactions',
         value: 'transactions',
         permission: true,
      },
      {
         label: 'Inbound Transactions',
         value: 'inbound-transactions',
         permission: true,
      },
      {
         label: 'Exchange Rate',
         value: 'exchange-rate',
         permission: hasPermission(permissions, can_view_exchange_rates),
      },
      {
         label: 'Settings',
         value: 'settings',
         permission: true,
      },
   ];

   return (
      <LoggedInDashboard pageTitle="International Transactions">
         <TabWrapper>
            <ul className="m-0 d-flex align-items-center">
               {tabs?.map(({ label, value, permission }) => {
                  if (permission) {
                     return (
                        <TabMenu
                           key={value}
                           text={label}
                           active={activeTab === value ? 'active' : ''}
                           setActiveTab={() => {
                              handleActiveTab(value);
                           }}
                        />
                     );
                  }
                  return null;
               })}
            </ul>
         </TabWrapper>
         {activeTab === 'transactions' && <TransactionsDashboard />}
         {activeTab === 'exchange-rate' && <ExchangeRate />}
         {activeTab === 'inbound-transactions' && <InboundTransactionsDashboard />}
         {activeTab === 'settings' && <InternationalTransactionsSettings />}
      </LoggedInDashboard>
   );
}

export default InternationalTransactions;
