import { Button } from 'components/button';
import { Input, NumberInput } from 'components/input';
import Modal from 'components/modal';
import React, { useEffect, useState } from 'react';
import { Grid, Switch } from '@material-ui/core';
import PropTypes, { string } from 'prop-types';
import { InputWrapper } from 'styles/form';
import { SelectInput } from 'components/select';
import { COUNTRIES } from 'utils/countries';

export const UpdateBusinessFeesModal = ({
   open,
   onClose,
   selectedData,
   feesData,
   loading,
   onSubmit,
   isEdit = false,
}) => {
   const [flatFee, setFlatFee] = useState('');
   const [percentageFee, setPercentageFee] = useState('');
   const [currencyValue, setCurrencyValue] = useState('');
   const [note, setNote] = useState('');
   const [currencyToggle, setCurrencyToggle] = useState(false);

   const countryOptions = COUNTRIES.filter((item) => item.AlphabeticCode !== 'NGN').map((item) => ({
      value: item.AlphabeticCode,
      label: `${item.Currency}-${item.AlphabeticCode}`,
   }));

   useEffect(() => {
      setFlatFee(selectedData?.flat_fee);
      setPercentageFee(selectedData?.percentage_fee);
      setCurrencyToggle(selectedData?.is_enabled);
      setNote(selectedData?.fee_note);
   }, [feesData]);

   const handleUpdate = (data) => {
      onSubmit(data);
      onClose();
   };

   const handleCreate = (data) => {
      onSubmit(data);
      onClose();
   };

   return (
      <Modal
         open={open}
         onClose={onClose}
         center
         title={`Update ${selectedData?.fee_currency} Inbound Transactions Fees`}
      >
         <InputWrapper className="grid-2-2">
            <NumberInput
               label="Flat Fee"
               placeholder="Enter the Flat Fees"
               value={flatFee}
               prefix=" "
               onChange={(val) => setFlatFee(val?.floatValue)}
            />
            <NumberInput
               label="Percentage Fee"
               placeholder="Enter the Percentage Fees"
               value={percentageFee}
               prefix=" "
               suffix="%"
               onChange={(val) => setPercentageFee(val?.floatValue)}
            />
         </InputWrapper>

         <InputWrapper className="grid-2-2">
            <Input
               label="Fee Note"
               placeholder="Enter Fees Note"
               value={note}
               name="note"
               onChange={(e) => setNote(e.target.value)}
            />
         </InputWrapper>

         {!isEdit && (
            <>
               <InputWrapper className="grid-2-2">
                  <SelectInput
                     label="Currency"
                     options={countryOptions}
                     defaultValue={currencyValue}
                     onChange={(val) => setCurrencyValue(val?.value)}
                  />
               </InputWrapper>
            </>
         )}

         {isEdit && (
            <InputWrapper>
               <div className="mb-5">
                  <b>Activate/Deactivate Currency</b>
                  <Grid component="label" container alignItems="center" spacing={1}>
                     <Grid item>
                        <b>Off</b>
                     </Grid>
                     <Grid item>
                        <Switch
                           data-testid="selectedData-toggle-switch"
                           checked={currencyToggle}
                           onClick={
                              currencyToggle
                                 ? () => setCurrencyToggle(false)
                                 : () => setCurrencyToggle(true)
                           }
                        />
                     </Grid>
                     <Grid item>
                        <b>On</b>
                     </Grid>
                  </Grid>
               </div>
            </InputWrapper>
         )}

         <InputWrapper
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
         >
            <div>
               <Button
                  variant="primary"
                  type="button"
                  onClick={() =>
                     isEdit
                        ? handleUpdate({
                             id: selectedData?.id,
                             flat_fee: flatFee,
                             percentage_fee: percentageFee,
                             fee_note: note,
                             is_enabled: currencyToggle,
                          })
                        : handleCreate({
                             fee_currency: currencyValue,
                             flat_fee: flatFee,
                             percentage_fee: percentageFee,
                             fee_note: note,
                             is_enabled: true,
                          })
                  }
                  isLoading={loading}
                  disabled={loading}
                  fullWidth
               >
                  {isEdit ? 'Update' : 'Create'}
               </Button>
            </div>
         </InputWrapper>
      </Modal>
   );
};

UpdateBusinessFeesModal.propTypes = {
   open: PropTypes.bool.isRequired,
   onClose: PropTypes.func.isRequired,
   selectedData: PropTypes.objectOf(string).isRequired,
   loading: PropTypes.bool.isRequired,
   feesData: PropTypes.objectOf(string).isRequired,
   onSubmit: PropTypes.func.isRequired,
   isEdit: PropTypes.bool.isRequired,
};
