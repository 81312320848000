import { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import { func, bool, string } from 'prop-types';
import {
   INTERNATIONAL_PAYMENT_STATUS,
   INBOUND_INTERNATIONAL_PAYMENT_STATUS,
} from 'utils/transactions';
import { Input } from 'components/input';
import Modal from 'components/modal';
import { Button } from 'components/button';
import { SelectInput } from 'components/select';
import { InputWrapper } from 'styles/form';

const InternationalTransactionsFilters = ({
   open,
   onClose,
   setCurrentPage,
   filterData,
   setFilterData,
   setFilterPayload,
   type,
}) => {
   const [isLoading, setIsLoading] = useState(false);
   const [isClearingFilter, setIsClearingFilter] = useState(false);
   const history = useHistory();

   useEffect(() => {
      return function unMount() {
         setIsLoading(false);
      };
   }, []);

   const payStatus = type === 'outbound' ? 'payout_status' : 'payin_status';

   function onChange(name, data) {
      setFilterData({
         ...filterData,
         [name]: data.value,
      });
   }

   async function onSubmit() {
      setIsLoading(true);
      try {
         setCurrentPage(() => 1);
         history.push(`${history.location.pathname}?page=1`);
         setFilterPayload(filterData);
         onClose();
      } catch (err) {
         toast.error(err.message || err.custom_message);
         setIsLoading(false);
         return err;
      } finally {
         setIsLoading(false);
      }
   }

   async function handleClearFilter() {
      setIsClearingFilter(true);
      try {
         setCurrentPage(() => 1);
         history.push(`${history.location.pathname}?page=1`);
         setFilterData({});
         setFilterPayload({});
         onClose();
      } catch (err) {
         return err;
      }
      setIsClearingFilter(false);
   }

   return (
      <Modal open={open} onClose={onClose} title="Filters" center>
         <form>
            <InputWrapper className="grid-2-2">
               <Input
                  placeholder="Start Date"
                  label="Start Date"
                  name="modified_at_after"
                  type="date"
                  value={filterData?.modified_at_after}
                  onChange={(e) => {
                     onChange('modified_at_after', e.target);
                  }}
               />
               <Input
                  placeholder="End Date"
                  label="End Date"
                  name="modified_at_before"
                  type="date"
                  value={filterData?.modified_at_before}
                  onChange={(e) => {
                     onChange('modified_at_before', e.target);
                  }}
               />
            </InputWrapper>

            <InputWrapper className="grid-2-2">
               <SelectInput
                  onChange={(val) => onChange(payStatus, val)}
                  label={type === 'outbound' ? 'Status' : 'Partner Status'}
                  options={
                     type === 'outbound'
                        ? INTERNATIONAL_PAYMENT_STATUS
                        : INBOUND_INTERNATIONAL_PAYMENT_STATUS
                  }
                  defaultValue={INBOUND_INTERNATIONAL_PAYMENT_STATUS.filter((item) =>
                     type === 'outbound'
                        ? item.value === filterData.payout_status
                        : item.value === filterData.payin_status,
                  )}
               />
            </InputWrapper>

            <InputWrapper
               style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
            >
               <div style={{ marginRight: '20px' }}>
                  <Button
                     variant="secondary"
                     type="button"
                     onClick={handleClearFilter}
                     isLoading={isClearingFilter}
                     fullWidth
                  >
                     clear filter
                  </Button>
               </div>

               <div>
                  <Button
                     variant="primary"
                     type="button"
                     onClick={onSubmit}
                     isLoading={isLoading}
                     disabled={isLoading}
                     fullWidth
                  >
                     Apply
                  </Button>
               </div>
            </InputWrapper>
         </form>
      </Modal>
   );
};

export default InternationalTransactionsFilters;

InternationalTransactionsFilters.propTypes = {
   open: bool.isRequired,
   onClose: func.isRequired,
   setCurrentPage: func.isRequired,
   filterData: func.isRequired,
   setFilterData: func.isRequired,
   setFilterPayload: func.isRequired,
   type: string.isRequired,
};
