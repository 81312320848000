import React from 'react';
import PropTypes from 'prop-types';
import { TextareaWrapper } from './textarea.style';

export default function Textarea({ label, value, onChange, name, ...rest }) {
   return (
      <TextareaWrapper>
         <label htmlFor={name} className="d-block">
            {label}
         </label>
         <textarea name={name} value={value} onChange={onChange} {...rest} />
      </TextareaWrapper>
   );
}

Textarea.propTypes = {
   label: PropTypes.string.isRequired,
   value: PropTypes.string.isRequired,
   onChange: PropTypes.func.isRequired,
   name: PropTypes.string.isRequired,
};
