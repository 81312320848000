/* eslint-disable react/forbid-prop-types */
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { AlertMessage } from 'components/message';
import { Spinner } from 'components/spinner';
import { array, string, func } from 'prop-types';
import { Input, NumberInput } from 'components/input';
import { toast } from 'react-hot-toast';
import { InputWrapper } from 'styles/form';
import { Button } from 'components/button';
import { addPosTerminal } from 'services/pos/index';
import { SectionTitle } from 'styles/layout';

export default function LoanOverview({ terminals, bizAccountId, posOrderId, initialize }) {
   const [loading, setLoading] = useState(false);
   const defaultValues = {
      transaction_limit: 20_000_000,
   };
   const {
      register,
      setValue,
      watch,
      handleSubmit,
      reset,
      formState: { errors },
   } = useForm({
      defaultValues,
   });

   const onSubmit = async (data) => {
      const { terminal_name, terminal_number, serial_number, transaction_limit } = data;
      const params = {
         biz_account_id: bizAccountId,
         terminal_name,
         terminal_number,
         serial_number,
         transaction_limit,
         pos_order_id: posOrderId,
      };
      setLoading(true);
      try {
         const result = await addPosTerminal(params);
         toast.success(result.message || 'Operation was successful');
         reset();
         setValue('transaction_limit', '');
         initialize();
      } catch (e) {
         toast.error(e.message || 'An error occured');
      }
      setLoading(false);
   };
   const transaction_limit_value = watch('transaction_limit');
   return terminals ? (
      <div>
         <div className="mb-5">
            <SectionTitle>Add New Terminals</SectionTitle>
         </div>

         <div>
            {(errors?.terminal_name ||
               errors?.terminal_number ||
               errors?.serial_number ||
               errors?.transaction_limit) && (
               <AlertMessage message="Please fill out all fields" variant="error" />
            )}
            <form>
               <InputWrapper className="grid-2-2">
                  <Input
                     placeholder="Terminal Name"
                     label="Terminal Name"
                     name="terminal_name"
                     type="text"
                     {...register('terminal_name', {
                        required: 'This is a required field',
                     })}
                  />
                  <Input
                     placeholder="Terminal Number"
                     label="Terminal Number"
                     name="terminal_number"
                     type="text"
                     {...register('terminal_number', {
                        required: 'This is a required field',
                     })}
                  />
               </InputWrapper>

               <InputWrapper className="grid-2-2">
                  <Input
                     placeholder="Serial Number"
                     label="Serial Number"
                     name="serial_number"
                     type="text"
                     {...register('serial_number', {
                        required: 'This is a required field',
                     })}
                  />
                  <NumberInput
                     label="Transaction Limit"
                     name="transaction_limit"
                     value={transaction_limit_value}
                     prefix="₦"
                     className={Input}
                     disabled
                     inputmode="numeric"
                     onChange={(valuesObject) => {
                        setValue('transaction_limit', valuesObject.floatValue);
                     }}
                  />
               </InputWrapper>
               <div className="d-flex justify-content-end pt-5">
                  <Button
                     onClick={handleSubmit(onSubmit)}
                     isLoading={loading}
                     disabled={
                        errors?.terminal_name ||
                        errors?.terminal_number ||
                        errors?.serial_number ||
                        errors?.transaction_limit
                     }
                  >
                     Add New Terminal
                  </Button>
               </div>
            </form>
         </div>
      </div>
   ) : (
      <Spinner />
   );
}

LoanOverview.propTypes = {
   terminals: array.isRequired,
   bizAccountId: string.isRequired,
   posOrderId: string.isRequired,
   initialize: func.isRequired,
};
