import { Button } from 'components/button';
import { Input, NumberInput } from 'components/input';
import Modal from 'components/modal';
import { bool, func, objectOf } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { InputWrapper } from 'styles/form';
import { updateCardDesign } from 'services/cards';
import toast from 'react-hot-toast';
import { confirm } from 'components/confirm-box';
import CardPhotoUploadModal from './upload-card-picture';

export const AddCardDesignModal = ({
   open,
   onClose,
   isEdit = false,
   selectedDesign,
   fetchCardDesigns,
}) => {
   const [loading, setLoading] = useState(false);
   const [cardName, setCardName] = useState('');
   const [cardPrice, setCardPrice] = useState('');
   const [uploadPhotoModal, setUploadPhotoModal] = useState(false);

   const hasEmptyField = () => !cardName || !cardPrice;

   const detailsUnchanged = () =>
      cardName === selectedDesign?.name && cardPrice === selectedDesign?.amount;

   useEffect(() => {
      if (isEdit) {
         setCardName(selectedDesign?.name);
         setCardPrice(selectedDesign?.amount);
      }
   }, []);

   const handleCreateDesign = async () => {
      setUploadPhotoModal(true);
   };

   const handleUpdateDesign = async () => {
      confirm({
         confirmText: 'Are you sure want to perform this action?',
         onConfirm: async () => {
            setLoading(true);
            const payload = {
               name: cardName,
               amount: cardPrice,
            };
            try {
               const res = await updateCardDesign(selectedDesign?.id, payload);
               toast.success(res?.message || 'Design successfully updated');
               fetchCardDesigns();
               onClose();
            } catch (e) {
               toast.error(e?.message);
               setLoading(false);
               onClose();
            }
         },
      });
   };

   return (
      <Modal
         open={open}
         onClose={onClose}
         center
         title={isEdit ? 'Update Card Details' : 'Card Details'}
      >
         <InputWrapper className="grid-2-2">
            <Input
               placeholder="Card Name"
               label="Card Name"
               name="name"
               value={cardName}
               onChange={(e) => {
                  setCardName(e.target.value);
               }}
            />
            <NumberInput
               placeholder="Card Price"
               label="Card Price"
               name="amount"
               value={cardPrice}
               onChange={(val) => {
                  setCardPrice(val.floatValue);
               }}
            />
         </InputWrapper>

         <InputWrapper
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
         >
            <div>
               <Button
                  variant="primary"
                  type="button"
                  onClick={isEdit ? handleUpdateDesign : handleCreateDesign}
                  isLoading={loading}
                  disabled={hasEmptyField() || detailsUnchanged() || loading}
                  fullWidth
               >
                  Continue
               </Button>
            </div>
         </InputWrapper>

         {uploadPhotoModal && (
            <CardPhotoUploadModal
               open={uploadPhotoModal}
               onClose={() => {
                  setUploadPhotoModal(false);
                  onClose();
               }}
               cardName={cardName}
               cardPrice={cardPrice}
               fetchCardDesigns={fetchCardDesigns}
            />
         )}
      </Modal>
   );
};

AddCardDesignModal.propTypes = {
   open: bool.isRequired,
   onClose: func.isRequired,
   isEdit: bool.isRequired,
   selectedDesign: objectOf().isRequired,
   fetchCardDesigns: func.isRequired,
};
