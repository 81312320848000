import { useEffect, useState } from 'react';
import { TableComponent } from 'components/table';
import PropTypes, { string } from 'prop-types';
import { TableContainer } from 'styles/table';
import { TooltipComponent } from 'components/tooltip';

const PerformanceOverview = ({ data }) => {
   const [tableData, setTableData] = useState([]);
   const [isLoadingMangers] = useState(false);
   const [paginationData, setPaginationData] = useState({});
   const [currentPage, setCurrentPage] = useState(1);

   useEffect(() => {
      if (data) {
         setPaginationData(data);
         setTableData(data?.result || []);
      }
   }, [data]);

   function onPageChange(e, pageNumber) {
      setCurrentPage(pageNumber);
   }

   const columns = [
      {
         field: 'assigned_to',
         headerName: 'Manager',
         renderCell: ({ row }) => (
            <TooltipComponent className="m-0" title={`${row.assigned_to?.first_name}`}>
               {row.assigned_to?.first_name} {row.assigned_to?.last_name}
            </TooltipComponent>
         ),
      },
      {
         field: 'open',
         headerName: 'Opened',
         renderCell: ({ row }) => <span>{row?.open || 0}</span>,
      },
      {
         field: 'pending',
         headerName: 'Pending',
         renderCell: ({ row }) => <span>{row?.pending || 0}</span>,
      },
      {
         field: 'on_hold',
         headerName: 'On-hold',
         width: 300,
         renderCell: ({ row }) => <span>{row?.on_hold || 0}</span>,
      },
      {
         field: 'closed',
         headerName: 'Closed',
         renderCell: ({ row }) => <span>{row?.closed || 0}</span>,
      },
      {
         field: 'average_response_time',
         headerName: 'Average Response Time',
         renderCell: ({ row }) => <span>{row?.average_response_time || 0}</span>,
      },
   ];

   return (
      <div>
         <TableContainer id="table">
            <TableComponent
               columns={columns}
               rows={tableData}
               isLoading={isLoadingMangers}
               onPageChange={onPageChange}
               count={Math.round(paginationData.count / 15)}
               page={currentPage}
               pageSize={20}
            />
         </TableContainer>
      </div>
   );
};

export default PerformanceOverview;

PerformanceOverview.propTypes = {
   data: PropTypes.objectOf(string).isRequired,
};
