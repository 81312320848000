import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { Grid, Switch } from '@material-ui/core';
import { confirm } from 'components/confirm-box';
import { Spinner } from 'components/spinner';
import {
   createCardBusinessSettings,
   getCardBusinessSettings,
   updateCardBusinessSettings,
} from 'services/cards';

export default function CardToggle({ businessAccountId }) {
   const [loading, setLoading] = useState(false);
   const [cardSettings, setCardSettings] = useState([]);
   const [virtualCardToggle, setVirtualCardToggle] = useState(false);
   const [virtualSettings, setVirtualSettings] = useState({});
   const [physicalCardToggle, setPhysicalCardToggle] = useState(false);
   const [physicalSettings, setPhysicalSettings] = useState({});
   const [isUpdatingSettings, setIsUpdatingSettings] = useState(false);

   const fetchCardBusinessSetting = async () => {
      setLoading(true);
      try {
         const res = await getCardBusinessSettings({ biz_account_id: businessAccountId });
         const { results } = res;
         if (results) {
            setCardSettings(results);
            setVirtualSettings(results?.find((item) => item.card_type === 'virtual'));
            setPhysicalSettings(results?.find((item) => item.card_type === 'physical'));
         }
         setLoading(false);
      } catch (e) {
         toast.error(e?.message || 'Something went wrong');
         setLoading(false);
      }
   };

   useEffect(() => {
      fetchCardBusinessSetting();
   }, []);

   useEffect(() => {
      if (virtualSettings) setVirtualCardToggle(virtualSettings?.allow_card);
      if (physicalSettings) setPhysicalCardToggle(physicalSettings?.allow_card);
   }, [virtualSettings, physicalSettings]);

   const handleCreateSettings = (type) => {
      confirm({
         confirmText: 'Are you sure want to proceed?',
         onConfirm: async () => {
            setIsUpdatingSettings(true);

            try {
               const payload = {
                  biz_account: businessAccountId,
                  card_type: type,
                  allow_card: true,
               };
               const res = await createCardBusinessSettings(payload);
               toast.success(res?.message || 'Successful');
               fetchCardBusinessSetting();
               setIsUpdatingSettings(false);
            } catch (e) {
               toast.error(e?.message || 'Something went wrong');
               setIsUpdatingSettings(false);
            }
         },
      });
   };

   const handleVirtualCardToggle = (type) => {
      confirm({
         confirmText: 'Are you sure want to proceed?',
         onConfirm: async () => {
            if (type === 'on') {
               setVirtualCardToggle(true);
            }

            if (type === 'off') {
               setVirtualCardToggle(false);
            }

            const payload =
               type === 'on'
                  ? {
                       allow_card: true,
                       card_type: 'virtual',
                    }
                  : {
                       allow_card: false,
                       card_type: 'virtual',
                    };
            try {
               const virtualAllowed = cardSettings?.find((item) => item?.card_type === 'virtual');
               const { id } = virtualAllowed || {};
               const res = await updateCardBusinessSettings(id, payload);
               fetchCardBusinessSetting();
               toast.success(res?.message || 'Successful');
            } catch (e) {
               toast.error(e?.message);
            }
         },
      });
   };

   const handlePhysicalCardToggle = (type) => {
      confirm({
         confirmText: 'Are you sure want to proceed?',
         onConfirm: async () => {
            if (type === 'on') {
               setPhysicalCardToggle(true);
            }

            if (type === 'off') {
               setPhysicalCardToggle(false);
            }

            const payload =
               type === 'on'
                  ? {
                       allow_card: true,
                       card_type: 'physical',
                    }
                  : {
                       allow_card: false,
                       card_type: 'physical',
                    };
            try {
               const physicalAllowed = cardSettings?.find((item) => item.card_type === 'physical');
               const { id } = physicalAllowed || {};
               const res = await updateCardBusinessSettings(id, payload);
               fetchCardBusinessSetting();
               toast.success(res?.message || 'Successful');
            } catch (e) {
               toast.error(e?.message);
            }
         },
      });
   };

   const handleCheckSettingsVirtual = (type) => {
      if (!virtualSettings) {
         handleCreateSettings(type);
      } else {
         handleVirtualCardToggle('on');
      }
   };

   const handleCheckSettingsPhysical = (type) => {
      if (!physicalSettings) {
         handleCreateSettings(type);
      } else {
         handlePhysicalCardToggle('on');
      }
   };

   return (
      <div className="row pt-5 border-top">
         {isUpdatingSettings || loading ? (
            <Spinner />
         ) : (
            <div className="row">
               <div className="mb-5">
                  <b>Virtual Card</b>
                  <Grid component="label" container alignItems="center" spacing={1}>
                     <Grid item>
                        <b>Off</b>
                     </Grid>
                     <Grid item>
                        <Switch
                           data-testid="virtual-card-switch"
                           checked={virtualCardToggle}
                           onClick={
                              virtualCardToggle
                                 ? () => handleVirtualCardToggle('off')
                                 : () => handleCheckSettingsVirtual('virtual')
                           }
                        />
                     </Grid>
                     <Grid item>
                        <b>On</b>
                     </Grid>
                  </Grid>
               </div>
               <div className="mb-5">
                  <b>Physical Card</b>
                  <Grid component="label" container alignItems="center" spacing={1}>
                     <Grid item>
                        <b>Off</b>
                     </Grid>
                     <Grid item>
                        <Switch
                           data-testid="physical-card-switch"
                           checked={physicalCardToggle}
                           onClick={
                              physicalCardToggle
                                 ? () => handlePhysicalCardToggle('off')
                                 : () => handleCheckSettingsPhysical('physical')
                           }
                        />
                     </Grid>
                     <Grid item>
                        <b>On</b>
                     </Grid>
                  </Grid>
               </div>
            </div>
         )}
      </div>
   );
}
CardToggle.propTypes = {
   businessAccountId: PropTypes.string.isRequired,
};
