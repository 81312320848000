import Styled from 'styled-components';

export const TabWrapper = Styled.div`
    margin-bottom:30px;
    a:hover{
        text-decoration:none;
    }
     ul{
        border-bottom: 1px solid #D7DCE0;
        cursor:pointer;
        padding-left:10px;
    }
     li{
        padding:0 10px;
        padding-bottom:14px;
        text-transform :capitalize;
        position: relative;


        .close-icon{
            position: absolute;
            top:-5px;
            right:-10px;
            font-size:0.8rem;
            color:red;
            border: 1px solid;
            display: flex;
            border-radius: 4px;
            

        }
    }
     li.active{
        position:relative;
        font-weight:600;
   
    }
     li.active a{
        position:relative;
        color:#1354D3;
        
    }
     li.active:after{
        content:'';
        position:absolute;
        height:2px;
        width:100%;
        background:#1354D3;
        bottom:0;
        left:0;
    }

     li{
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: #81909D;
        margin-right:15px;
    }
`;
