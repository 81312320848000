export const validateEmail = (email) => {
   return String(email)
      .toLowerCase()
      .match(
         /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      );
};

export const hasValue = (data) => {
   return data && data !== '';
};

export const objectsEqual = (o1, o2) =>
   Object.keys(o1).length === Object.keys(o2).length &&
   Object.keys(o1).every((p) => o1[p] === o2[p]);

export const arraysEqual = (a1, a2) =>
   a1?.length === a2?.length && a1?.every((o, idx) => objectsEqual(o, a2[idx]));
