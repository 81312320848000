import { React, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { bool, func, objectOf } from 'prop-types';
import Modal from 'components/modal';
import { Button } from 'components/button';
import CurrencyFlag from 'react-currency-flags';
import { confirm } from 'components/confirm-box';
import { formatTag } from 'utils/tags';
import { Spinner } from 'components/spinner';
import DocumentPreviewer from 'components/document-previewer';
import {
   approveOrRejectTransaction,
   getTransactionDetails,
} from 'services/international-transactions';
import Arrow from 'assets/images/forward-arrow.svg';
import BusinessDetails from 'containers/business-details';
import moment from 'moment';
import { InternationalTransferWrapper } from './style';

export default function InternationalTransactionModal({
   open,
   onClose,
   transactionDetail,
   fetchTransactions,
}) {
   const [modalVisibility, setModalVisibility] = useState(false);
   const [bizAccount, setBizAccount] = useState('');
   const [details, setDetails] = useState({});
   const [loading, setLoading] = useState(false);

   const fetchDetails = async () => {
      setLoading(true);
      try {
         const res = await getTransactionDetails(transactionDetail?.id);
         setDetails(res);
         setLoading(false);
      } catch (e) {
         toast.error(e?.message || ' Something went wrong');
         setLoading(false);
      }
   };

   useEffect(() => {
      fetchDetails();
   }, []);

   const renderTransactionStatus = (row) => {
      let template = '';
      template =
         row?.payout_status === 'success'
            ? formatTag('accepted', 'Success')
            : formatTag(row?.payout_status, row?.payout_status);
      return template;
   };

   const openBusiness = (id) => {
      setBizAccount(id);
      setModalVisibility(true);
   };

   const {
      hbizaccount,
      created_by,
      beneficiary,
      source_currency,
      source_amount,
      amount,
      created_at,
      transfer_image,
      rate,
   } = details;

   function getAccountInfo() {
      const fieldMapping = {
         account_no: 'Bank Account Number',
         clabe: 'Clabe Number',
         iban: 'IBAN',
      };

      const field = Object.keys(fieldMapping).find((key) => beneficiary?.[key]);
      const value = beneficiary?.[field];

      return {
         field: fieldMapping[field] || 'N/A',
         value: value || 'N/A',
      };
   }

   function getAccountCodeInfo() {
      const fieldMapping = {
         routing_number: 'Routing Number',
         bic: 'BIC',
         ifsc: 'IFSC',
         bsb_code: 'BSB Code',
         sort_code: 'Sort Code',
      };

      const field = Object.keys(fieldMapping).find((key) => beneficiary?.[key]);
      const value = beneficiary?.[field];

      return {
         field: fieldMapping[field] || 'N/A',
         value: value || 'N/A',
      };
   }

   function downloadTransactionProof() {
      if (transfer_image) {
         const link = document.createElement('a');
         link.href = transfer_image;
         link.target = '_blank';
         link.download = 'transaction_proof.jpg';
         link.click();
      }
   }

   const handleTransactionAction = (type) => {
      confirm({
         confirmText:
            type === 'approve'
               ? 'Are you sure you want to approve this transaction?'
               : 'Are you sure you want to reject this transaction?',
         isLoading: false,
         onConfirm: async () => {
            setLoading(true);
            const payload =
               type === 'approve' ? { payout_status: 'approved' } : { payout_status: 'rejected' };
            try {
               const res = await approveOrRejectTransaction(details?.id, payload);
               toast.success(res?.message || 'Successful');
               setLoading(false);
               fetchDetails();
               fetchTransactions();
            } catch (e) {
               toast.error(e?.message);
               setLoading(false);
            }
         },
      });
   };

   const accountInfo = getAccountInfo();
   const accountCodeInfo = getAccountCodeInfo();

   return (
      <>
         <Modal
            title="Transaction Details"
            open={open}
            onClose={onClose}
            closeOnOverlayClick={false}
            className="biz-details-modal"
         >
            {loading ? (
               <Spinner />
            ) : (
               <>
                  <div className="d-flex mt-3 mb-4 align-items-center justify-content-between">
                     <div className="d-flex gap-3">
                        <CurrencyFlag currency={source_currency} size="xl" />
                        <img src={Arrow} alt="arrow" />
                        <CurrencyFlag currency={beneficiary?.currency} size="xl" />
                     </div>

                     {details?.payout_status === 'pending' && (
                        <div className="d-flex align-items-center">
                           <Button
                              variant="danger"
                              className="me-3"
                              onClick={() => handleTransactionAction('reject')}
                              disabled={loading}
                           >
                              Reject
                           </Button>

                           <Button
                              variant="green"
                              onClick={() => handleTransactionAction('approve')}
                              disabled={loading}
                           >
                              Approve
                           </Button>
                        </div>
                     )}
                  </div>
                  <InternationalTransferWrapper>
                     <div className="row pt-5">
                        <div className="col-lg-3 col-md-6 ">
                           <div className="business-details-item">
                              <h3>Initiated By</h3>
                              <p>
                                 {created_by?.first_name || 'N/A'} {created_by?.last_name || 'N/A'}
                              </p>
                           </div>
                        </div>

                        <div className="col-lg-3 col-md-6 ">
                           <div className="business-details-item">
                              <h3> Beneficiary</h3>
                              <p>
                                 {`${beneficiary?.first_name || 'N/A'} ${
                                    beneficiary?.last_name || 'N/A'
                                 }`}
                              </p>
                           </div>
                        </div>

                        <div className="col-lg-3 col-md-6 ">
                           <div className="business-details-item">
                              <h3> Amount Sent</h3>
                              <p>{`${source_currency || 'N/A'} ${parseFloat(
                                 source_amount || 'N/A',
                              ).toLocaleString()}`}</p>
                           </div>
                        </div>

                        <div className="col-lg-3 col-md-6 ">
                           <div className="business-details-item">
                              <h3> Receiver Gets </h3>
                              <p>
                                 {' '}
                                 {`${beneficiary?.currency || 'N/A'} ${parseFloat(
                                    amount || 'N/A',
                                 ).toLocaleString()}`}
                              </p>
                           </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                           <div className="business-details-item">
                              <h3>{accountInfo.field}</h3>
                              <p>{accountInfo.value || 'N/A'}</p>
                           </div>
                        </div>

                        <div className="col-lg-3 col-md-6 ">
                           <div className="business-details-item">
                              <h3> Beneficiary Bank Name </h3>
                              <p>N/A</p>
                           </div>
                        </div>

                        <div className="col-lg-3 col-md-6 ">
                           <div className="business-details-item">
                              <h3> Country</h3>
                              <p>{beneficiary ? beneficiary?.country : 'N/A'}</p>
                           </div>
                        </div>

                        <div className="col-lg-3 col-md-6 ">
                           <div className="business-details-item">
                              <h3> Exchange Rate</h3>
                              <p>{`${parseFloat(rate)}` || 'N/A'}</p>
                           </div>
                        </div>

                        <div className="col-lg-3 col-md-6 ">
                           <div className="business-details-item">
                              <h3>Status</h3>
                              <p>{renderTransactionStatus(details)}</p>
                           </div>
                        </div>

                        <div className="col-lg-3 col-md-6">
                           <div className="business-details-item">
                              <h3>{accountCodeInfo.field}</h3>
                              <p>{accountCodeInfo.value || 'N/A'}</p>
                           </div>
                        </div>

                        <div className="col-lg-3 col-md-6 ">
                           <div className="business-details-item">
                              <h3>Street Line 1</h3>
                              <p>{beneficiary ? beneficiary?.street_line1 : 'N/A'}</p>
                           </div>
                        </div>

                        <div className="col-lg-3 col-md-6 ">
                           <div className="business-details-item">
                              <h3>Street Line 2</h3>
                              <p>{beneficiary ? beneficiary?.street_line2 : 'N/A'}</p>
                           </div>
                        </div>

                        <div className="col-lg-3 col-md-6 ">
                           <div className="business-details-item">
                              <h3>Region</h3>
                              <p>{beneficiary ? beneficiary?.region : 'N/A'}</p>
                           </div>
                        </div>

                        <div className="col-lg-3 col-md-6 ">
                           <div className="business-details-item">
                              <h3>City</h3>
                              <p>{beneficiary ? beneficiary?.city : 'N/A'}</p>
                           </div>
                        </div>

                        <div className="col-lg-3 col-md-6 ">
                           <div className="business-details-item">
                              <h3>Post Code</h3>
                              <p>{beneficiary ? beneficiary?.postcode : 'N/A'}</p>
                           </div>
                        </div>

                        <div className="col-lg-3 col-md-6 ">
                           <div className="business-details-item">
                              <h3>Date Submitted</h3>
                              <p>
                                 {moment(created_at).format('DD MMM, YYYY')} |{' '}
                                 {moment().format('HH:mm A')}
                              </p>
                           </div>
                        </div>

                        <h3 className="mt-4">Transaction Proof</h3>
                        <div className="d-flex mt-4 mb-5 align-items-center justify-content-between">
                           {transfer_image ? (
                              <div className="d-flex align-items-center">
                                 <DocumentPreviewer
                                    url={transfer_image}
                                    biz_account_id={hbizaccount}
                                    title="Transaction Proof"
                                 >
                                    <Button className="doc-btn me-3">Preview</Button>
                                 </DocumentPreviewer>
                                 <Button variant="green" onClick={downloadTransactionProof}>
                                    Download Receipt
                                 </Button>
                              </div>
                           ) : (
                              <div>
                                 <Button className="doc-btn me-3" disabled>
                                    Preview
                                 </Button>
                                 <Button variant="green" disabled>
                                    Download Receipt
                                 </Button>
                                 <p className="text-danger">Transaction Proof does not exist</p>
                              </div>
                           )}
                           <Button variant="primary" onClick={() => openBusiness(hbizaccount)}>
                              View Business
                           </Button>
                        </div>
                     </div>
                  </InternationalTransferWrapper>
               </>
            )}
         </Modal>
         {modalVisibility && (
            <BusinessDetails
               businessAccountId={bizAccount}
               open={modalVisibility}
               onClose={() => setModalVisibility(false)}
            />
         )}
      </>
   );
}
InternationalTransactionModal.propTypes = {
   open: bool.isRequired,
   onClose: func.isRequired,
   transactionDetail: objectOf.isRequired,
   fetchTransactions: func.isRequired,
};
