/* eslint-disable react/prop-types */
import { useEffect, useState, useRef } from 'react';
import LoggedInDashboard from 'components/logged-in-dashboard';
import { TableContainer, TableContent } from 'styles/table';
import { getPartners } from 'services/partners';
import { TooltipComponent } from 'components/tooltip';
import { TableComponent } from 'components/table';
import moment from 'moment';
import Calendar from 'components/calendar';
import BusinessDetails from 'containers/business-details';
import { Skeleton } from '@material-ui/lab';

function FollowUp() {
   const [tableData, setTableData] = useState([]);
   const [isLoading, setIsLoading] = useState(false);
   const [paginationData, setPaginationData] = useState({});
   const [currentPage, setCurrentPage] = useState(1);
   const [modalVisibility, setModalVisibility] = useState(false);
   const [bizAccount, setBizAccount] = useState('');
   const [startDate] = useState('2019-01-01');
   const [endDate] = useState(moment().format('YYYY-MM-DD'));
   const tableRef = useRef();

   function onRowClick({ row }) {
      setBizAccount(row.biz_account_id);
   }

   const columns = [
      {
         field: 'partner_full_name',
         headerName: 'Fullname',
         renderCell: ({ row }) => (
            <TooltipComponent title={row.partner_full_name}>
               <span>{row.partner_full_name || 'N/A'}</span>
            </TooltipComponent>
         ),
      },
      {
         field: 'partner_email',
         headerName: 'Email',
         width: 150,
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.partner_email}>
               {row.partner_email || 'N/A'}
            </TooltipComponent>
         ),
      },
      {
         field: 'partner_phone',
         headerName: 'Phone',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.partner_phone}>
               {row.partner_phone || 'N/A'}
            </TooltipComponent>
         ),
      },
      {
         field: 'partner_biz_name',
         headerName: 'Business Name',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.partner_biz_name}>
               {row.partner_biz_name || 'N/A'}
            </TooltipComponent>
         ),
      },
      {
         field: 'partner_category',
         headerName: 'Category',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.partner_category}>
               {row.partner_category || 'N/A'}
            </TooltipComponent>
         ),
      },
      {
         field: 'partner_client_num',
         headerName: 'Total Clients',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.partner_client_num}>
               {row.partner_client_num || 'N/A'}
            </TooltipComponent>
         ),
      },
      {
         field: 'partner_website',
         headerName: 'Website',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.partner_website}>
               {row?.partner_website ? (
                  <a href={row?.partner_website} rel="noreferrer" target="_blank">
                     {row.partner_website}
                  </a>
               ) : (
                  'N/A'
               )}
            </TooltipComponent>
         ),
      },
      {
         field: 'partner_twitter',
         headerName: 'Twitter',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.partner_twitter}>
               {row?.partner_twitter ? (
                  <a href={row?.partner_twitter} rel="noreferrer" target="_blank">
                     {row.partner_twitter}
                  </a>
               ) : (
                  'N/A'
               )}
            </TooltipComponent>
         ),
      },
      {
         field: 'partner_instagram',
         headerName: 'Instagram',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.partner_instagram}>
               {row?.partner_instagram ? (
                  <a href={row?.partner_instagram} rel="noreferrer" target="_blank">
                     {row.partner_instagram}
                  </a>
               ) : (
                  'N/A'
               )}
            </TooltipComponent>
         ),
      },
      {
         field: 'partner_facebook',
         headerName: 'Facebook',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.partner_facebook}>
               {row?.partner_facebook ? (
                  <a href={row?.partner_facebook} rel="noreferrer" target="_blank">
                     {row.partner_facebook}
                  </a>
               ) : (
                  'N/A'
               )}
            </TooltipComponent>
         ),
      },
   ];

   useEffect(() => {
      (async () => {
         setIsLoading(true);
         const params = {
            biz_turnover: '',
            biz_current_status: '',
            start_date: startDate,
            end_date: endDate,
         };
         Promise.all([getPartners({ page: currentPage }, params)])
            .then(([businessAccountsData]) => {
               setTableData(businessAccountsData.data);
               setPaginationData(businessAccountsData);
            })
            .finally(() => {
               setIsLoading(false);
            });
      })();
   }, [currentPage]);

   function onPageChange(e, pageNumber) {
      setCurrentPage(pageNumber);
   }

   return (
      <LoggedInDashboard pageTitle="Partners">
         <TableContainer id="table">
            <TableContent>
               {isLoading ? (
                  <Skeleton width="30%" height="20" />
               ) : (
                  <div>
                     <h1>Partners </h1>
                     <span className="value">{paginationData?.count || 'N/A'}</span>
                  </div>
               )}
               <div className="d-flex justify-content-between">
                  <Calendar />
               </div>
            </TableContent>
            <TableComponent
               columns={columns}
               rows={tableData}
               isLoading={isLoading}
               onPageChange={onPageChange}
               count={Math.ceil(paginationData.count / 15)}
               page={currentPage}
               ref={tableRef}
               onRowClick={onRowClick}
            />
         </TableContainer>
         <BusinessDetails
            businessAccountId={bizAccount}
            open={modalVisibility}
            onClose={() => setModalVisibility(false)}
         />
      </LoggedInDashboard>
   );
}

export default FollowUp;
