import { useEffect, useState, useCallback } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessage } from '@hookform/error-message';
import { func, objectOf, bool, string } from 'prop-types';
import Modal from 'components/modal';
import { NumberInput } from 'components/input';
import { toast } from 'react-hot-toast';
import { Button } from 'components/button';
import { confirm } from 'components/confirm-box';
import { createLoanTerm, updateLoanTerm } from 'services/loans';

function AddLoanTerm({ isEdit = false, onClose, open, term, loanterm }) {
   const [loading, setLoading] = useState(false);
   const [changed, setChanged] = useState(true);
   const [tenure, setTenure] = useState(1);

   useEffect(() => {
      switch (loanterm) {
         case 'sixty_day_loan':
            setTenure(2);
            break;
         case 'ninety_day_loan':
            setTenure(3);
            break;
         default:
            setTenure(1);
            break;
      }
   }, [loanterm]);

   const defaultValues = {
      min_score: 0,
      max_score: 0,
      interest_rate: 0,
      max_loan_offer: 0,
      insurance_fee: 0,
      admin_fee: 0,
   };

   const schema = yup.object().shape({
      min_score: yup.number().positive().required().min(1, 'Minimum value must be at least 1'),
      max_score: yup
         .number()
         .positive()
         .required()
         .when('min_score', (min_score) => {
            if (min_score) {
               return yup
                  .number()
                  .required()
                  .min(parseInt(min_score, 10) + 1, 'Max score must be greater than min score');
            }
         }),
      interest_rate: yup
         .number()
         .positive()
         .required()
         .min(0)
         .max(100, 'Minimum value allowed is 100'),
      max_loan_offer: yup.number().positive().required().min(1),
      insurance_fee: yup.number().positive().required().min(0),
      admin_fee: yup.number().positive().required().min(0),
   });

   const {
      register,
      handleSubmit,
      setValue,
      trigger,
      watch,
      formState: { isValid, errors },
   } = useForm({
      defaultValues,
      mode: 'onChange',
      resolver: yupResolver(schema),
      shouldFocusError: true,
   });

   useEffect(() => {
      register('min_score');
      register('max_score');
      register('interest_rate');
      register('max_loan_offer');
      register('insurance_fee');
      register('admin_fee');
   }, []);

   const min_score = watch('min_score');
   const max_score = watch('max_score');
   const interest_rate = watch('interest_rate');
   const max_loan_offer = watch('max_loan_offer');
   const insurance_fee = watch('insurance_fee');
   const admin_fee = watch('admin_fee');

   useEffect(() => {
      if (isEdit) {
         setValue('min_score', term?.min_score);
         setValue('max_score', term?.max_score);
         setValue('interest_rate', term?.interest_rate);
         setValue('max_loan_offer', term?.max_loan_offer);
         setValue('insurance_fee', term?.insurance_fee);
         setValue('admin_fee', term?.admin_fee);

         trigger();
      }
   }, [isEdit]);

   const sharedOnChange = useCallback(async ({ name, value }, cb) => {
      setValue(name, value);
      await trigger(name);
      if (cb) {
         cb();
      }
   }, []);

   const getChanged = () => ({
      ...(min_score !== term.min_score && { min_score }),
      ...(max_score !== term.max_score && { max_score }),
      ...(interest_rate !== term.interest_rate && { interest_rate }),
      ...(max_loan_offer !== term.max_loan_offer && { max_loan_offer }),
      ...(insurance_fee !== term.insurance_fee && { insurance_fee }),
      ...(admin_fee !== term.admin_fee && { admin_fee }),
   });

   useEffect(() => {
      if (isEdit) {
         const changedCheck = getChanged();
         setChanged(!!Object.keys(changedCheck).length);
      }
   }, [min_score, max_score, interest_rate, max_loan_offer, insurance_fee, admin_fee]);

   const onSubmit = (data) => {
      confirm({
         confirmText: 'Are you sure you want to continue?',
         onConfirm: async () => {
            setLoading(true);
            try {
               if (!isEdit) {
                  await createLoanTerm({ ...data, loan_tenure: tenure });
                  toast.success('New term was created successfully');
               } else {
                  const payload = getChanged();
                  await updateLoanTerm({ ...payload, id: term.id, loan_tenure: tenure });
                  toast.success('Tenure was successful');
               }
               onClose();
            } catch (e) {
               toast.error(e.message);
            }
            setLoading(false);
         },
      });
   };

   const renderErrorMessage = (name) => (
      <ErrorMessage
         errors={errors}
         name={name}
         render={({ message }) => <p className="input-error-message text-danger">{message}</p>}
      />
   );

   return (
      <Modal open={open} onClose={onClose} title={isEdit ? 'Edit Term' : 'Add New Term'}>
         <form onSubmit={handleSubmit(onSubmit)} className="row">
            <div className="col-md-6 mb-4">
               <NumberInput
                  label="Minimum Score"
                  name="min_score"
                  prefix="hidden"
                  value={min_score}
                  placeholder="Enter Score"
                  onChange={({ floatValue }) =>
                     sharedOnChange({ name: 'min_score', value: floatValue || 0 }, async () => {
                        await trigger('max_score');
                     })
                  }
               />
               {renderErrorMessage('min_score')}
            </div>
            <div className="col-md-6 mb-4">
               <NumberInput
                  label="Maximum Score"
                  name="max_score"
                  prefix="hidden"
                  value={max_score}
                  placeholder="Enter Score"
                  onChange={({ floatValue }) =>
                     sharedOnChange({ name: 'max_score', value: floatValue || 0 })
                  }
               />
               {renderErrorMessage('max_score')}
            </div>

            <div className="col-md-6 mb-4">
               <NumberInput
                  label="Interest Rate"
                  name="interest_rate"
                  value={interest_rate}
                  placeholder="Enter Interest Rate"
                  prefix="hidden"
                  suffix="%"
                  onChange={({ floatValue }) =>
                     sharedOnChange({ name: 'interest_rate', value: floatValue || 0 })
                  }
               />
               {renderErrorMessage('interest_rate')}
            </div>
            <div className="col-md-6 mb-4">
               <NumberInput
                  label="Maximum Loan Offer"
                  name="max_loan_offer"
                  value={max_loan_offer}
                  placeholder="Enter Maximum Loan Offer"
                  onChange={({ floatValue }) =>
                     sharedOnChange({ name: 'max_loan_offer', value: floatValue || 0 })
                  }
               />
               {renderErrorMessage('max_loan_offer')}
            </div>

            <div className="col-md-6 mb-4">
               <NumberInput
                  label="Insurance Fee"
                  name="insurance_fee"
                  value={insurance_fee}
                  placeholder="Enter Insurance Fee"
                  onChange={({ floatValue }) =>
                     sharedOnChange({ name: 'insurance_fee', value: floatValue || 0 })
                  }
               />
               {renderErrorMessage('insurance_fee')}
            </div>

            <div className="col-md-6 mb-4">
               <NumberInput
                  label="Admin Fee"
                  name="admin_fee"
                  value={admin_fee}
                  placeholder="Enter Admin Fee"
                  onChange={({ floatValue }) =>
                     sharedOnChange({ name: 'admin_fee', value: floatValue || 0 })
                  }
               />
               {renderErrorMessage('admin_fee')}
            </div>

            <div className="d-flex justify-content-end pt-5">
               <Button type="submit" isLoading={loading} disabled={!isValid || !changed}>
                  {isEdit ? 'Update Term' : 'Add New Term'}
               </Button>
            </div>
         </form>
      </Modal>
   );
}

AddLoanTerm.propTypes = {
   isEdit: bool.isRequired,
   onClose: func.isRequired,
   open: func.isRequired,
   term: objectOf().isRequired,
   loanterm: string.isRequired,
};

export default AddLoanTerm;
