import { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { bool } from 'prop-types';
import { useAuth } from 'hooks/auth';
import { prospabuildpartner } from 'utils/user_types';
import NavItem from './nav-item';
import { SideBarWrapper } from './style';

function SideBar({ sideBar }) {
   const { currentUser } = useAuth();
   const { permissions } = currentUser?.access || {};
   const [navs, setNavs] = useState([]);

   const navLinks = [
      {
         id: uuidv4(),
         link: '/approver',
         text: 'Approver',
         icon: 'file',
      },
      {
         id: uuidv4(),
         link: '/follow-up',
         text: 'Follow Up',
         icon: 'file',
      },
      {
         id: uuidv4(),
         link: '/activation',
         text: 'Activation',
         icon: 'file',
      },
      {
         id: uuidv4(),
         link: '/business-accounts',
         text: 'Business Accounts',
         icon: 'file',
      },
      {
         id: uuidv4(),
         link: '/pre-approved-accounts',
         text: 'Pre-approved accounts',
         icon: 'file',
      },
      {
         id: uuidv4(),
         link: '/new-accounts',
         text: 'New accounts',
         icon: 'file',
      },
      {
         id: uuidv4(),
         link: '/requires-tin',
         text: 'Requires TIN [Uploaded All Documents]',
         icon: 'file',
      },
      {
         id: uuidv4(),
         link: '/transactions',
         text: 'Transactions',
         icon: 'list',
      },
      {
         id: uuidv4(),
         link: '/nibss-transactions',
         text: 'NIBSS Transactions',
         icon: 'list',
      },
      {
         id: uuidv4(),
         link: '/refer-and-earn',
         text: 'Refer and Earn',
         icon: 'list',
      },
      {
         id: uuidv4(),
         link: '/settings-and-compliance',
         text: 'Settings and Compliance',
         icon: 'list',
      },
      {
         id: uuidv4(),
         link: '/international-transactions',
         text: 'International Transactions',
         icon: 'list',
      },
      {
         id: uuidv4(),
         link: '/savings',
         text: 'Savings',
         icon: 'list',
      },
      {
         id: uuidv4(),
         link: '/billing-debt-logs',
         text: 'Billing Debt Logs',
         icon: 'list',
      },
      {
         id: uuidv4(),
         link: '/business-registrations',
         text: 'Business registrations',
         icon: 'briefcase',
      },
      {
         id: uuidv4(),
         link: '/activity-log',
         text: 'Activity Log',
         icon: 'file',
      },
      {
         id: uuidv4(),
         link: '/cac-business-search',
         text: 'CAC Business Search',
         icon: 'file',
      },
      {
         id: uuidv4(),
         link: '/corporate-cards',
         text: 'Corporate Cards',
         icon: 'file',
      },
      {
         id: uuidv4(),
         link: '/build-partner',
         text: 'Build Partners',
         icon: 'file',
      },
      ...(currentUser.user_type === prospabuildpartner
         ? [
              {
                 id: uuidv4(),
                 link: '/business-partner',
                 text: 'Business Partners',
                 icon: 'file',
              },
           ]
         : []),
      {
         id: uuidv4(),
         link: '/loans',
         text: 'Loans',
         icon: 'file',
      },
      {
         id: uuidv4(),
         link: '/pos',
         text: 'POS',
         icon: 'file',
      },
      {
         id: uuidv4(),
         link: '/debugging-tools',
         text: 'Debugging Tools',
         icon: 'file',
      },
      {
         id: uuidv4(),
         link: '/admin-manager',
         text: 'Admin Manager',
         icon: 'file',
      },
      {
         id: uuidv4(),
         link: '/assign-build-partners',
         text: 'Assign Build Partners',
         icon: 'file',
      },
      {
         id: uuidv4(),
         link: '/ticketing',
         text: 'Ticketing',
         icon: 'file',
      },
      {
         id: uuidv4(),
         link: '/campaigns',
         text: 'Campaigns',
         icon: 'file',
      },
      {
         id: uuidv4(),
         link: '/marketing',
         text: 'Marketing',
         icon: 'file',
      },
      {
         id: uuidv4(),
         link: '/partners',
         text: 'Partners',
         icon: 'file',
      },
      {
         id: uuidv4(),
         link: 'sign-out',
         text: 'Sign Out',
         icon: 'lock',
      },
   ];

   navLinks.sort((a, b) => {
      const textA = a.text.toUpperCase();
      const textB = b.text.toUpperCase();
      if (textA < textB) {
         return -1;
      }
      if (textA > textB) {
         return 1;
      }
      return 0;
   });

   useEffect(async () => {
      if (currentUser) {
         if (permissions === 'all') return setNavs(navLinks);
         const { pageAccess } = currentUser?.access || {};
         const pageAccessLinks = navLinks.filter(
            (item) => pageAccess && pageAccess.includes(item.link?.replaceAll('-', '_')),
         );
         pageAccessLinks.push({
            id: uuidv4(),
            link: 'sign-out',
            text: 'Sign Out',
            icon: 'lock',
         });
         return setNavs(pageAccessLinks);
      }
   }, [currentUser, permissions]);

   const navList = navs.filter(
      (value) => Object.keys(value).length !== 0 && value.link !== 'sign-out',
   );

   const sortedNavlist = [
      ...navList,
      {
         id: uuidv4(),
         link: 'sign-out',
         text: 'Sign Out',
         icon: 'lock',
      },
   ];

   return (
      <SideBarWrapper
         id="sidebarMenu"
         className="col-md-3 col-lg-2 d-md-block bg-light"
         show={sideBar}
      >
         <div className="position-sticky pt-5">
            <ul className="nav flex-column mb-2 js-nav">
               {sortedNavlist.map(({ link, icon, text, id }) => (
                  <NavItem link={link} icon={icon} text={text} key={id} />
               ))}
            </ul>
         </div>
      </SideBarWrapper>
   );
}
SideBar.propTypes = {
   sideBar: bool.isRequired,
};
export default SideBar;
