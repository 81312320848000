import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button } from 'components/button';
import SuccessIcon from 'assets/images/success.svg';

const SuccessWrapper = styled.div`
   padding: 20px 0 15px 0;

   .success-icon {
      width: 60px;
      height: 60px;
      display: block;
      margin: 0 auto 25px;
   }

   h3 {
      font-size: 18px;
      line-height: 15px;
      text-align: center;
      color: #1e2442;
      margin-bottom: 15px;
   }

   p {
      font-size: 15px;
      line-height: 21px;
      text-align: center;
      color: #82849f;
      margin-bottom: 25px;
   }
`;

export default function Success({ onSubmit }) {
   return (
      <SuccessWrapper>
         <img src={SuccessIcon} alt="back" className="success-icon" />
         <h3>Successful!</h3>
         <p>Funds have been withdrawn successfully</p>

         <div className="d-flex justify-content-center">
            <Button onClick={onSubmit}>Done</Button>
         </div>
      </SuccessWrapper>
   );
}

Success.propTypes = {
   onSubmit: PropTypes.func.isRequired,
};
