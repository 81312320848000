/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useAuth } from 'hooks/auth';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import { Skeleton } from '@material-ui/lab';
import FilterListIcon from '@material-ui/icons/FilterList';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import { TableContainer, TableContent } from 'styles/table';
import { TooltipComponent } from 'components/tooltip';
import { HeadingOne } from 'styles/typography';
import { LoanWorkerBtn } from 'components/start-work-btn';
import { TableComponent } from 'components/table';
import { Status } from 'styles/utils';
import { numberWithCommas } from 'utils/others';
import { FilterContainer } from 'pages/follow-up/follow-up.style';
import LoanDetailsModal from 'containers/loan-details';
import { LoansFilterModal } from 'containers/business-account-filter-modal';
import { getPCRLabel } from 'utils/loans';
import { StatsCards } from 'components/stats-cards';
import ExportDataModal from 'components/export-data';
import { fetchLoanApplications, fetchLoanStats, exportDataDump } from 'services/loans';

function LoansDashboard({ exportTypes }) {
   const { search } = useLocation();
   const page = new URLSearchParams(search).get('page');
   const [tableData, setTableData] = useState([]);
   const [isLoading, setIsLoading] = useState(false);
   const [modalVisibilityFilter, setModalVisibilityFilter] = useState(false);
   const [paginationData, setPaginationData] = useState({});
   const [currentPage, setCurrentPage] = useState(page || 1);
   const [bizAccount, setBizAccount] = useState('');
   const [detailsModal, setDetailsModal] = useState(false);
   const [singleLoan, setSingleLoan] = useState('');
   const [workerActiveStatus, setWorkerActiveStatus] = useState(false);
   const [statsData, setStatsData] = useState({});
   const [workerDateRange, setWorkerDateRange] = useState({});
   const [showExport, setShowExport] = useState(false);
   const [filterDate, setFilterDate] = useState({
      start_date: '2021-01-01',
      end_date: moment().format('YYYY-MM-DD'),
   });
   const [exportState, setExportState] = useState({
      start_date: moment().format('YYYY-MM-01'),
      end_date: moment().format('YYYY-MM-DD'),
      allowRange: false,
   });
   const [filterLoanStatus, setFilterLoanStatus] = useState(null);
   const { currentUser } = useAuth();
   const { permissions } = currentUser?.access || {};

   function handleOpenModal(row) {
      // setActiveTab('loan-overview');
      setBizAccount(row.biz_account_id);
      setSingleLoan(row.biz_loan_id);
      setDetailsModal(true);
      setWorkerActiveStatus(false);
   }

   async function handleOnSubmit(payload) {
      try {
         const result = await exportDataDump(payload);
         toast.success(
            result?.message ||
               'Export is currently processing and will available for download shortly',
         );
         setShowExport(false);
      } catch (err) {
         toast.error(err.message || err.custom_message);
      }
   }

   const columns = [
      {
         field: 'biz_name',
         headerName: 'Business Name',
         renderCell: ({ row }) => (
            <TooltipComponent title={row.biz_name}>
               <span
                  style={{ cursor: 'pointer' }}
                  className="text-primary"
                  onClick={() => handleOpenModal(row, false)}
               >
                  {row.biz_name}
               </span>
            </TooltipComponent>
         ),
      },
      {
         field: 'loan_amount',
         headerName: 'Requested Amount',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.loan_amount}>
               ₦{numberWithCommas(row?.loan_amount)}
            </TooltipComponent>
         ),
      },
      {
         field: 'loan_purpose',
         width: 300,
         headerName: 'Loan Purpose',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.loan_purpose}>{row?.loan_purpose}</TooltipComponent>
         ),
      },
      {
         field: 'loan_status',
         headerName: 'Loan Status',
         // width: 150,
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.loan_status}>
               <Status status={row?.loan_status}>{row.loan_status}</Status>
            </TooltipComponent>
         ),
      },
      {
         field: 'loan_repayment_status',
         headerName: 'Loan Repayment Status',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.loan_repayment_status}>
               <Status
                  status={
                     row?.loan_repayment_status === 'defaulted'
                        ? 'rejected'
                        : row?.loan_repayment_status
                  }
               >
                  {row.loan_repayment_status}
               </Status>
            </TooltipComponent>
         ),
      },
      {
         field: 'loan_crs_score',
         headerName: 'Loan CRS Score',
         renderCell: ({ row }) => (
            <TooltipComponent
               title={
                  <>
                     {getPCRLabel(row?.loan_crs_score)} | {row?.loan_crs_score}
                  </>
               }
            >
               {getPCRLabel(row?.loan_crs_score)} | {row?.loan_crs_score || 'N/A'}
            </TooltipComponent>
         ),
      },
      {
         field: 'loan_tenure',
         headerName: 'Loan Tenure',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.loan_tenure}>{row?.loan_tenure} days</TooltipComponent>
         ),
      },
      {
         field: 'loan_disbursement_status',
         headerName: 'Loan Disbursement',
         width: 300,
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.loan_officer_details?.first_name}>
               <Status status={row?.loan_disbursement_status}>
                  {row.loan_disbursement_status || 'N/A'}
               </Status>
               <span>
                  {' '}
                  {row?.loan_approved_by_details &&
                     ` | ${row?.loan_approved_by_details.first_name} ${row?.loan_approved_by_details.last_name}`}
               </span>
            </TooltipComponent>
         ),
      },
      {
         field: 'loan_outstanding',
         headerName: 'Loan Outstanding Balance',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.loan_outstanding}>
               ₦{numberWithCommas(row?.loan_outstanding)}
            </TooltipComponent>
         ),
      },
      {
         field: 'loan_repayment_balance',
         headerName: 'Total Repayed Amount',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.loan_repayment_balance}>
               ₦{numberWithCommas(row?.loan_repayment_balance)}
            </TooltipComponent>
         ),
      },
      {
         field: 'loan_repay_end_date',
         headerName: 'Loan Repayment Date',
         renderCell: ({ row }) => (
            <TooltipComponent title={moment(row?.loan_repay_end_date).format('DD MMM, YYYY')}>
               {moment(row?.loan_repay_end_date).format('DD MMM, YYYY')} |{' '}
               <span style={{ opacity: '.5' }}>
                  {moment(row?.loan_repay_end_date).format('HH:mm A')}
               </span>
            </TooltipComponent>
         ),
      },
      {
         field: 'loan_officer_details',
         headerName: 'Loan Officer',
         renderCell: ({ row }) =>
            row?.loan_officer_details ? (
               <TooltipComponent
                  title={`${row?.loan_officer_details?.first_name} ${row?.loan_officer_details?.last_name}`}
               >
                  {`${row?.loan_officer_details?.first_name} ${row?.loan_officer_details?.last_name}`}
               </TooltipComponent>
            ) : (
               'N/A'
            ),
      },
      {
         field: 'pub_date',
         headerName: 'Requested Date',
         renderCell: ({ row }) => (
            <TooltipComponent title={moment(row.pub_date).format('DD MMM, YYYY')}>
               {moment(row.pub_date).format('DD MMM, YYYY')} |{' '}
               <span style={{ opacity: '.5' }}>{moment(row.pub_date).format('HH:mm A')}</span>
            </TooltipComponent>
         ),
      },
   ];

   const initializeLoanTable = async () => {
      setIsLoading(true);
      try {
         Promise.all([
            fetchLoanApplications(
               {
                  ...filterDate,
                  ...(filterLoanStatus && { loan_status: filterLoanStatus }),
               },
               { page },
            ),
         ]).then(([loans]) => {
            setTableData(loans.data);
            setPaginationData(loans);
            setModalVisibilityFilter(false);
         });
      } catch (e) {
         toast.error(e.message);
      }

      setIsLoading(false);
   };
   useEffect(() => {
      if (permissions === 'all' || permissions?.includes('read_loan_statistics')) {
         (async function getLoanStats() {
            try {
               const result = await fetchLoanStats();
               const {
                  total_loan_disbursement_amt,
                  total_loan_disbursed,
                  loan_float_balance,
                  total_amount_repaid,
                  total_loan_earnings_interest,
                  total_loan_earnings_admin_fee,
                  total_defaulted_loan,
                  total_defaulted_loan_amt,
               } = result;

               setStatsData({
                  loan_float_balance: `₦${numberWithCommas(loan_float_balance)}`,
                  total_loan_disbursed,
                  total_loan_disbursed_amt: `₦${numberWithCommas(total_loan_disbursement_amt)}`,
                  total_amount_repaid: `₦${numberWithCommas(total_amount_repaid)}`,
                  total_loan_earnings_interest: `₦${numberWithCommas(
                     total_loan_earnings_interest,
                  )}`,
                  total_loan_earnings_admin_fee: `₦${numberWithCommas(
                     total_loan_earnings_admin_fee,
                  )}`,
                  total_defaulted_loan,
                  total_defaulted_loan_amt: `₦${numberWithCommas(total_defaulted_loan_amt)}`,
               });
            } catch (e) {
               toast.error(e.message);
            }
         })();
      }
   }, [currentUser]);
   useEffect(() => {
      initializeLoanTable();
   }, [currentPage, page, filterDate]);

   function onPageChange(e, pageNumber) {
      setCurrentPage(pageNumber);
   }

   return (
      <>
         <div className="d-flex justify-content-between align-items-center">
            <HeadingOne className="mb-0"> Loan Applications</HeadingOne>
            <LoanWorkerBtn
               setModalVisibility={(value) => {
                  setBizAccount(value.biz_account_id);
                  setSingleLoan(value.biz_loan_id);
                  setDetailsModal(true);
                  setWorkerActiveStatus(true);
               }}
               setWorkerDateRange={setWorkerDateRange}
               workerDateRange={workerDateRange}
            />
         </div>
         {(permissions === 'all' || permissions?.includes('read_loan_statistics')) && (
            <StatsCards data={statsData} />
         )}
         <TableContainer>
            <TableContent>
               <div className="d-flex justify-content-between w-100 align-items-center">
                  {isLoading ? (
                     <Skeleton width="30%" height="20" />
                  ) : (
                     <div>
                        <h1> All Loan Applications</h1>
                        <span className="value">{paginationData?.count || 'N/A'}</span>
                     </div>
                  )}
                  <div className="d-flex">
                     <div>
                        <FilterContainer className="me-3" onClick={() => setShowExport(true)}>
                           Export Data
                           <ImportExportIcon style={{ marginLeft: '10px' }} />
                        </FilterContainer>
                     </div>
                     <FilterContainer onClick={() => setModalVisibilityFilter(true)}>
                        Filter By <FilterListIcon style={{ marginLeft: '10px' }} />
                     </FilterContainer>
                  </div>
               </div>
            </TableContent>
            <TableComponent
               columns={columns}
               rows={tableData}
               isLoading={isLoading}
               onPageChange={onPageChange}
               count={Math.ceil(paginationData.count / 15)}
               page={page}
            />
         </TableContainer>

         {detailsModal && (
            <LoanDetailsModal
               open={detailsModal}
               onClose={() => {
                  setDetailsModal(false);
                  initializeLoanTable();
                  setWorkerActiveStatus('');
               }}
               // data={transactionDetail}
               businessAccountId={bizAccount}
               loan={singleLoan}
               workerActiveStatus={workerActiveStatus}
               workerDateRange={workerDateRange}
            />
         )}
         {modalVisibilityFilter && (
            <LoansFilterModal
               open={modalVisibilityFilter}
               onClose={() => setModalVisibilityFilter(false)}
               setCurrentPage={setCurrentPage}
               setFilterDate={setFilterDate}
               filterDate={filterDate}
               setFilterLoanStatus={setFilterLoanStatus}
               filterLoanStatus={filterLoanStatus}
            />
         )}

         {showExport && (
            <ExportDataModal
               open={showExport}
               onClose={() => setShowExport(false)}
               exportOptions={exportTypes}
               exportState={exportState}
               setExportState={setExportState}
               onSubmit={handleOnSubmit}
            />
         )}
      </>
   );
}

export default LoansDashboard;
