import { Http } from 'services/http';

export const logActivity = (content, biz_type, biz_id, activity_type) => {
   const params = {
      activity_content: content,
      ...(biz_type === 'reg' && { biz_account_id: biz_id }),
      ...(biz_type === 'unreg' && { unregistered_biz_id: biz_id }),
      ...(activity_type && { activity_type }),
   };

   return Http(`/backmen/direct_backmen_activity_log/`, {
      method: 'POST',
      body: params,
   });
};
