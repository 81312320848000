import { PropTypes } from 'prop-types';
import Modal from 'components/modal';
import { useState } from 'react';
import SelectBankAccount from 'components/select-bank-account';
import { withdrawFromSavings } from 'services/savings';
import toast from 'react-hot-toast';
import Success from 'components/success';
import ReviewWithdrawFunds from './review-modal';

export default function WithdrawFundsModal({
   open,
   onClose,
   bizDetails,
   fdDetails,
   fetchFixedDepositsAccount,
}) {
   const [loading, setLoading] = useState(false);
   const [step, setStep] = useState('review-amount');
   const [successModal, setSuccessModal] = useState(false);
   const [amount, setAmount] = useState('');

   const { safe } = fdDetails;
   const { safe_wallet_details } = safe || {};

   const { biz_account_wallet_details } = bizDetails;

   const onClick = (data) => {
      setAmount(data);
      setStep('select-account');
   };

   const handleDeposit = async (data) => {
      const { biz_wallet_id } = data;

      const params = {
         amount: safe_wallet_details?.available_balance,
         from_wallet_id: safe_wallet_details?.biz_wallet_id,
         to_wallet_id: biz_wallet_id,
      };
      setLoading(true);
      try {
         await withdrawFromSavings(params);
         setSuccessModal(true);
      } catch (e) {
         toast.error(e.message);
      }
      setLoading(false);
   };

   const handleAfterSuccess = () => {
      onClose();
      setSuccessModal(false);
      fetchFixedDepositsAccount();
   };

   return (
      <Modal
         open={open}
         onClose={onClose}
         center
         noCloseIcon
         maxWidth="400px"
         modalStyle={{ maxHeight: '450px' }}
      >
         {step === 'review-amount' && (
            <ReviewWithdrawFunds
               onBack={onClose}
               onClick={onClick}
               title="Add Funds"
               fdDetails={fdDetails}
            />
         )}
         {step === 'select-account' && (
            <SelectBankAccount
               onBack={() => setStep('input-amount')}
               bizWallets={biz_account_wallet_details?.filter(
                  (item) => item.biz_wallet_type !== 'fixed_deposits',
               )}
               onSubmit={handleDeposit}
               loading={loading}
            />
         )}
         {successModal && (
            <Success
               open={successModal}
               onClose={handleAfterSuccess}
               title={`₦${parseFloat(
                  amount,
               ).toLocaleString()} has been added to the fixed deposit account`}
            />
         )}
      </Modal>
   );
}
WithdrawFundsModal.propTypes = {
   open: PropTypes.bool.isRequired,
   onClose: PropTypes.func.isRequired,
   bizDetails: PropTypes.objectOf().isRequired,
   fdDetails: PropTypes.objectOf().isRequired,
   fetchFixedDepositsAccount: PropTypes.func.isRequired,
};
