import PropTypes from 'prop-types';
import { Info, SectionTitle } from 'styles/layout';
import { lookup_bvn, manual_bvn_verification } from 'utils/user_actions';
import ManualBvnVerification from './manual-bvn-verification';
import LookupBvn from './lookup-bvn';

const BvnVerification = ({ permissions }) => {
   const checkPermission = (action) => permissions?.includes(action) || permissions === 'all';

   return (
      <>
         {checkPermission(lookup_bvn) && (
            <>
               <Info className="mb-2">
                  <b>LOOKUP BVN (BANK VERIFICATION NUMBER)</b>
                  <LookupBvn />
               </Info>
            </>
         )}
         {checkPermission(manual_bvn_verification) && (
            <>
               <SectionTitle />
               <Info className="mb-2">
                  <b>MANUAL BVN VERIFICATION</b>
                  <ManualBvnVerification />
               </Info>
            </>
         )}
      </>
   );
};

BvnVerification.propTypes = {
   permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default BvnVerification;
