import { useState } from 'react';
import PropTypes, { func, string } from 'prop-types';
import { Button } from 'components/button';
import { toast } from 'react-hot-toast';
import { numberWithCommas } from 'utils/others';
import { confirm } from 'components/confirm-box/confirm-box';
import { OverviewSection, InfoGroup, Value, Label } from 'styles/layout';
import { editCardDetails } from 'services/cards';
import ReasonModal from './reason-modal';
import { ChangeLimitModal } from './change-limit-modal';

const CardSettings = ({ cardDetails, fetchCard, cardId }) => {
   const [reasonModal, setReasonModal] = useState(false);
   const [isLoading, setIsLoading] = useState(false);
   const [changeLimitModal, setChangeLimitModal] = useState(false);
   const [limitType, setLimitType] = useState('');

   const { status, wallet_details, tokenized_pan, limits } = cardDetails;

   const handleTerminateCard = async () => {
      confirm({
         confirmText: 'Are you sure want to terminate this card?',
         isLoading,
         onConfirm: async () => {
            setIsLoading(true);
            try {
               const payload = {
                  is_active: false,
               };
               const result = await editCardDetails(payload, cardId);
               if (result.ok) {
                  toast.success(result?.message || 'Card successfully Terminated');
                  fetchCard();
               }
               setIsLoading(false);
            } catch (err) {
               toast.error(err.message || err.custom_message);
               setIsLoading(false);
            }
         },
      });
   };

   const handleFreezeCard = () => {
      confirm({
         confirmText:
            status === 'deactivated'
               ? 'Are you sure want to unfreeze this card?'
               : 'Are you sure want to freeze this card?',
         onConfirm: async () => {
            setIsLoading(true);
            const payload = {
               status: status === 'deactivated' ? 'active' : 'deactivated',
            };
            try {
               const res = await editCardDetails(payload, cardId);
               toast.success(res?.message || 'Action successful');
               setIsLoading(false);
               fetchCard();
            } catch (e) {
               toast.error(e?.message || 'Something went wrong');
               setIsLoading(false);
            }
         },
      });
   };

   const handleOpenLimitModal = (type) => {
      setChangeLimitModal(true);
      setLimitType(type);
   };

   return (
      <>
         <div style={{ backgroundColor: '#F8F8F8' }} className="d-flex justify-content-between p-4">
            <div>
               <InfoGroup>
                  <Label>Funding Account</Label>
                  <Value>{wallet_details?.biz_account_number}</Value>
               </InfoGroup>
               <div>
                  <div className="mt-5">
                     <OverviewSection>
                        <InfoGroup>
                           <Label>Daily Limit</Label>
                           <div className="d-flex">
                              <Value>
                                 {limits?.limit === 'daily' ? `₦${limits?.amount}` : '-'}
                              </Value>
                              <div onClick={() => handleOpenLimitModal('daily')}>
                                 <p
                                    style={{
                                       color: '#1354D3',
                                       fontSize: '14px',
                                       fontWeight: '400',
                                       marginLeft: '50px',
                                       cursor: 'pointer',
                                    }}
                                 >
                                    change
                                 </p>
                              </div>
                           </div>
                        </InfoGroup>

                        <InfoGroup>
                           <Label>Monthly Limit</Label>
                           <div className="d-flex">
                              <Value>
                                 {limits?.limit === 'monthly' ? `₦${limits?.amount}` : '-'}
                              </Value>
                              <div onClick={() => handleOpenLimitModal('monthly')}>
                                 <p
                                    style={{
                                       color: '#1354D3',
                                       fontSize: '14px',
                                       fontWeight: '400',
                                       marginLeft: '50px',
                                       cursor: 'pointer',
                                    }}
                                 >
                                    change
                                 </p>
                              </div>
                           </div>
                        </InfoGroup>
                     </OverviewSection>
                  </div>
               </div>
            </div>
            <div>
               <div
                  style={{
                     backgroundColor: '#FFF',
                     width: '344px',
                     height: '140px',
                     flexShrink: '0',
                     boxShadow: '0px 2px 4px 0px rgba(81, 89, 120, 0.16)',
                  }}
               >
                  <div className="p-4">
                     <p style={{ fontSize: '14px', fontWeight: '600' }}>Balance</p>
                     <p style={{ color: '#515978', fontSize: '14px', fontWeight: '500' }}>
                        {tokenized_pan}
                     </p>
                     <p
                        className="mt-4"
                        style={{ color: '#1E2442', fontSize: '28px', fontWeight: '600' }}
                     >
                        {numberWithCommas(wallet_details?.available_balance)}
                     </p>
                  </div>
               </div>
               <div className="d-flex gap-4 justify-content-end mb-4 mt-4">
                  {status === 'deactivated' ? (
                     <Button variant="secondary" isLoading={isLoading} onClick={handleFreezeCard}>
                        Unfreeze Card
                     </Button>
                  ) : (
                     <Button variant="secondary" isLoading={isLoading} onClick={handleFreezeCard}>
                        Freeze Card
                     </Button>
                  )}

                  <Button variant="danger" onClick={handleTerminateCard}>
                     Terminate
                  </Button>
               </div>
            </div>
         </div>

         {reasonModal && (
            <ReasonModal
               open={reasonModal}
               onClose={() => setReasonModal(false)}
               cardDetails={cardDetails}
               fetchCard={fetchCard}
            />
         )}
         {changeLimitModal && (
            <ChangeLimitModal
               open={changeLimitModal}
               onClose={() => setChangeLimitModal(false)}
               limitType={limitType}
               limits={limits}
               cardId={cardId}
               fetchCard={fetchCard}
            />
         )}
      </>
   );
};

CardSettings.propTypes = {
   cardDetails: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
   fetchCard: func.isRequired,
   cardId: string.isRequired,
};

export default CardSettings;
