import { useEffect, useState } from 'react';
import { func, objectOf, string } from 'prop-types';
import { managerRoles } from 'utils/user_types';
import { StatsCards } from 'components/stats-cards';
import { Spinner } from 'components/spinner';
import { formatStatsTime } from 'utils/formatter';
import {
   InfoGroup,
   Label,
   OverviewSection,
   SectionTitle,
   Value,
} from 'containers/business-details/utils/overview.style';
import { Button } from 'components/button';
import { ChangeRoleModal } from '../change-role';

const Overview = ({ adminDetails, fetchSingleAdminStats }) => {
   const [stats, setStats] = useState(null);
   const [changeRoleModal, setChangeRoleModal] = useState(false);
   const {
      first_name,
      last_name,
      phone,
      email,
      role_type,
      new_average_activation,
      new_average_approval,
      new_average_followup,
      total_approved_accounts,
      total_approved_docs,
      total_document_accepted,
      total_document_rejected,
      total_pre_approved_accounts,
   } = adminDetails || {};
   useEffect(() => {
      setStats({
         new_average_approval: formatStatsTime(new_average_approval),
         new_average_activation: formatStatsTime(new_average_activation),
         new_average_followup: formatStatsTime(new_average_followup),
         total_approved_accounts,
         total_approved_docs,
         total_document_accepted,
         total_document_rejected,
         total_pre_approved_accounts,
      });
   }, []);

   return (
      <div>
         {!adminDetails ? (
            <Spinner />
         ) : (
            <>
               <div>
                  <SectionTitle>Manager Details</SectionTitle>
                  <OverviewSection>
                     <InfoGroup>
                        <Label>Manager Name</Label>
                        <Value>{`${first_name} ${last_name}`}</Value>
                     </InfoGroup>

                     <InfoGroup>
                        <Label>Email</Label>
                        <Value>{email}</Value>
                     </InfoGroup>

                     <InfoGroup>
                        <Label>Role</Label>
                        <div className="d-flex gap-3">
                           <Value>{role_type}</Value>
                           <Button
                              style={{
                                 minHeight: '20px',
                                 padding: '5px',
                                 fontSize: '10px',
                              }}
                              onClick={() => setChangeRoleModal(true)}
                           >
                              Change
                           </Button>
                        </div>
                     </InfoGroup>

                     <InfoGroup>
                        <Label>Phone</Label>
                        <Value>{phone || 'N/A'}</Value>
                     </InfoGroup>
                  </OverviewSection>

                  <SectionTitle>Manager Stats</SectionTitle>
                  <div>
                     <StatsCards data={stats || []} />
                  </div>
               </div>
            </>
         )}
         {changeRoleModal && (
            <ChangeRoleModal
               open={changeRoleModal}
               onClose={() => setChangeRoleModal(false)}
               adminDetails={adminDetails}
               managerRoles={managerRoles}
               fetchSingleAdminStats={fetchSingleAdminStats}
            />
         )}
      </div>
   );
};

Overview.propTypes = {
   adminDetails: objectOf(string).isRequired,
   fetchSingleAdminStats: func.isRequired,
};

export default Overview;
