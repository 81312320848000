import React, { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ListItemText } from '@material-ui/core';
import useFetchManagers from 'hooks/fetchManagers';
import {
   getPermissions,
   getManagerPermissions,
   assignPermissions as assigningPermisssionsApi,
} from 'services/admin';
import { InputWrapper } from 'styles/form';
import { Button } from 'components/button';
import { SelectInput } from 'components/select';
import { confirm } from 'components/confirm-box';
import { groupObject, replaceAll } from 'utils/formatter';
import { arraysEqual } from 'utils/validations';
import { can_upgrade_account_plan, can_view_limit_boards } from 'utils/user_actions';
import AddNewPermission from '../add-new-permission';
import { PermissionsWrapper } from '../admin-manager.style';

const useStyles = makeStyles((theme) => ({
   root: {
      flexGrow: 1,
      maxWidth: 752,
   },
   demo: {
      backgroundColor: theme.palette.background.paper,
   },
   title: {
      margin: theme.spacing(4, 0, 2),
      fontSize: '100px',
   },
}));

const special = [
   {
      action: can_upgrade_account_plan,
      required: [can_view_limit_boards],
   },
];

export default function Permissions() {
   const classes = useStyles();
   const { managers } = useFetchManagers();
   const [selectedManager, setSelectedManager] = useState('');
   const [existingManagerAccess, setExistingManagerAccess] = useState([]);
   const [managerAccess, setManagerAccess] = useState([]);
   const [assigningPermisssions, setAssigningPermisssions] = useState(false);
   const [isClearing, setIsClearing] = useState(false);
   const [actions, setActions] = useState([]);
   const [allPermissions, setAllPermissions] = useState([]);
   const [groupedPermissionList, setGroupedPermissionList] = useState([]);
   const [modalVisible, setModalVisible] = useState(false);

   async function fetchPermissions() {
      try {
         const response = await getPermissions();
         setAllPermissions(response?.data);
         const pList = groupObject(response?.data, 'category');
         const {
            page_access,
            admin,
            business_account,
            business_registration,
            debugging_tools,
            general,
            loans,
            pos,
            ...rest
         } = pList;

         const [
            accept_or_reject_documents,
            update_documents,
            block_or_unblock_accounts,
            ...remainingBusinessAccounts
         ] = business_account;

         const [
            business_registrations_api,
            loans_api,
            transactions_api,
            nibss_transactions_api,
            pos_api,
            admin_manager_api,
            partners_api,
            ...remainingPages
         ] = page_access;

         setGroupedPermissionList({
            page_access: [
               ...remainingPages,
               transactions_api,
               nibss_transactions_api,
               business_registrations_api,
               loans_api,
               pos_api,
               admin_manager_api,
               partners_api,
            ],
            business_account: [
               ...remainingBusinessAccounts,
               accept_or_reject_documents,
               update_documents,
               block_or_unblock_accounts,
            ],
            loans,
            pos,
            debugging_tools,
            business_registration,
            general,
            admin,
            ...rest,
         });
      } catch (e) {
         toast.error(e?.message);
      }
   }

   const setCurrentPermissions = async (id) => {
      try {
         const response = await getManagerPermissions(id);
         setManagerAccess(response.permissions);
         setExistingManagerAccess(response.permissions);
      } catch (e) {
         toast.error(e?.message);
      }
   };

   useEffect(() => {
      fetchPermissions();
   }, []);

   useEffect(() => {
      if (selectedManager.value) {
         setCurrentPermissions(selectedManager.value);
      }
   }, [selectedManager]);

   const handleActions = (val) => {
      const actionExist = managerAccess?.filter((item) => item.action === val.action);

      if (actionExist.length) {
         const newPermission = managerAccess?.filter((item) => item.action !== val.action);
         return setManagerAccess(newPermission);
      }

      const permission = [val];
      special.forEach((item) => {
         if (item.action === val.action) {
            item.required.forEach((req) => {
               const required = allPermissions.find((perm) => perm.action === req);
               if (required) permission.push(required);
            });
         }
      });
      setManagerAccess([...managerAccess, ...permission]);
   };

   const resetPermissions = () => {
      confirm({
         confirmText: "This will reset manager's permissions to the last saved result",
         isLoading: false,
         onConfirm: () => {
            setManagerAccess(existingManagerAccess);
            toast.success("Manager's permissions has be reset");
         },
      });
   };

   const clearPermission = () => {
      confirm({
         confirmText: 'All existing permissions assigned to this manager will be cleared',
         isLoading: false,
         onConfirm: async () => {
            try {
               setIsClearing(true);
               setManagerAccess([]);
               setExistingManagerAccess([]);
               const payload = {
                  permissions: [],
               };
               await assigningPermisssionsApi(selectedManager.value, payload);
               await setCurrentPermissions(selectedManager.value);
               setIsClearing(false);
               toast.success("Manager's permissions has be cleared");
            } catch (e) {
               toast.error(e?.message);
            }
         },
      });
   };

   const assignPermissions = () => {
      confirm({
         confirmText: 'This manager will be assigned this new set of permissions',
         isLoading: false,
         onConfirm: async () => {
            setAssigningPermisssions(true);
            try {
               const payload = {
                  permissions: managerAccess.map((item) => item.id),
               };

               await assigningPermisssionsApi(selectedManager.value, payload);
               await setCurrentPermissions(selectedManager.value);
               toast.success('Permissions has been assigned to manager');
            } catch (e) {
               toast.error(e?.message);
            }
            setAssigningPermisssions(false);
         },
      });
   };

   useEffect(() => {
      if (managerAccess) {
         const actionList = managerAccess.map((item) => item.action);
         setActions(actionList);
      }
   }, [managerAccess]);

   return (
      <PermissionsWrapper>
         <div className="d-flex justify-content-between align-center mb-5">
            <h2 className="mb-0 d-flex align-center">Assign Permissions</h2>
            <Button onClick={() => setModalVisible(true)} className="d-none">
               Create new permission
            </Button>
         </div>
         <form>
            <InputWrapper className="grid-2-2 mb-5">
               <SelectInput
                  label="Select Manager"
                  value={selectedManager}
                  onChange={(val) => setSelectedManager(val)}
                  options={managers}
               />
            </InputWrapper>

            {selectedManager?.value && (
               <div>
                  <p className="mb-3">Permissions</p>
                  {Object.keys(groupedPermissionList)?.map((item) => (
                     <Accordion key={item}>
                        <AccordionSummary
                           expandIcon={<ExpandMoreIcon />}
                           aria-controls="panel1a-content"
                           id="panel1a-header"
                        >
                           <h4 className="permission-category-text">
                              {replaceAll(item, '_', ' ')}
                           </h4>
                        </AccordionSummary>
                        <AccordionDetails>
                           <div className={classes.demo}>
                              <ul className="m-0 ps-4">
                                 {groupedPermissionList[item]?.map((action) => (
                                    <li className="d-flex">
                                       <input
                                          id={action.action}
                                          type="checkbox"
                                          className="permission-check me-4 mt-2"
                                          checked={actions?.includes(action.action)}
                                          onChange={() => handleActions(action)}
                                       />
                                       <label htmlFor={action.action}>
                                          <ListItemText
                                             primary={replaceAll(action.action, '_', ' ')?.replace(
                                                'api',
                                                'page',
                                             )}
                                             secondary={action.description}
                                             className="mt-0 mb-4"
                                          />
                                       </label>
                                    </li>
                                 ))}
                              </ul>
                           </div>
                        </AccordionDetails>
                     </Accordion>
                  ))}

                  <div className="mt-5 pt-4 d-flex justify-content-end">
                     {!arraysEqual(managerAccess, existingManagerAccess) && (
                        <div className="me-4">
                           <Button onClick={resetPermissions} variant="secondary">
                              Reset Permissions
                           </Button>
                        </div>
                     )}
                     <div className="me-4">
                        <Button onClick={clearPermission} variant="danger" isLoading={isClearing}>
                           Clear Permissions
                        </Button>
                     </div>
                     <Button
                        onClick={assignPermissions}
                        disabled={arraysEqual(managerAccess, existingManagerAccess)}
                        isLoading={assigningPermisssions}
                     >
                        Assign Permissions
                     </Button>
                  </div>
               </div>
            )}
         </form>

         {modalVisible && (
            <AddNewPermission open={modalVisible} onClose={() => setModalVisible(false)} />
         )}
      </PermissionsWrapper>
   );
}
