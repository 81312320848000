/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import toast from 'react-hot-toast';
import { IconButton } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import { FilterContainer } from 'pages/follow-up/follow-up.style';
import { TableContainer, TableContent } from 'styles/table';
import { getBlockedUserAccounts, reActivateMemberAccountApi } from 'services/businessDetails';
import { TooltipComponent } from 'components/tooltip';
import { confirm } from 'components/confirm-box/confirm-box';
import { MenuComponent } from 'components/menu';
import { Skeleton } from '@material-ui/lab';
import { flags } from 'utils/constants';
import { TableComponent } from 'components/table';
import BlockedUserAccountsFilter from 'containers/filter-modal/blocked-accounts-filter';
import { MoreHoriz } from '@material-ui/icons';

function BlockedUserAccounts() {
   const { search } = useLocation();
   const page = new URLSearchParams(search).get('page');
   const [tableData, setTableData] = useState([]);
   const [isLoading, setIsLoading] = useState(false);
   const [paginationData, setPaginationData] = useState({});
   const [currentPage, setCurrentPage] = useState(page);
   const [userEmail, setUserEmail] = useState('');
   const [actionType, setActionType] = useState('');
   const [modalVisibilityFilter, setModalVisibilityFilter] = useState(false);
   const [filterData, setFilterData] = useState();
   const [filterPayload, setFilterPayload] = useState({});

   const [anchorEl, setAnchorEl] = useState(null);
   const open = Boolean(anchorEl);

   const handleClose = () => {
      setAnchorEl(null);
   };

   const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
   };

   const options = ['Unblock Account'];

   function onRowClick({ row }) {
      setUserEmail(row?.user_details.email);
      setActionType(row?.flag_type);
   }

   const status = filterData?.flag_status === 'resolved' ? 'resolved' : 'flagged';

   const fetchBlockeduserAccounts = async () => {
      setIsLoading(true);
      try {
         const result = await getBlockedUserAccounts({
            page: page || currentPage,
            flag_status: status,
            ...filterPayload,
         });
         if (result.ok) {
            setTableData(result.results);
            setPaginationData(result);
         }
         setIsLoading(false);
      } catch (err) {
         toast.error(err.message || err.custom_message);
         setIsLoading(false);
      }
   };

   function unblockAccount() {
      confirm({
         confirmText: 'Are you sure you want to unblock this account?',
         isLoading: false,
         onConfirm: async () => {
            const payload = {
               email: userEmail,
               action_type: actionType,
            };
            try {
               const response = await reActivateMemberAccountApi(payload);
               toast.success(response?.message || 'Account successfully unblocked');
               await fetchBlockeduserAccounts();
            } catch (err) {
               toast.error(err.message || err.custom_message);
            }
         },
      });
   }

   useEffect(() => {
      fetchBlockeduserAccounts();
   }, [currentPage, filterPayload]);

   function onPageChange(e, pageNumber) {
      setCurrentPage(pageNumber);
   }

   const columns = [
      {
         field: '',
         width: 0,
         renderCell: () => (
            <div>
               <IconButton
                  aria-label="more"
                  aria-controls="long-menu"
                  aria-haspopup="true"
                  onClick={handleClick}
               >
                  <MoreHoriz />
               </IconButton>
               <MenuComponent
                  anchorEl={anchorEl}
                  handleClose={handleClose}
                  open={open}
                  options={options}
                  optionsFns={[
                     // place the callbacks in position corresponding to the options index
                     () => unblockAccount(),
                  ]}
               />
            </div>
         ),
      },
      {
         field: 'user_details.first_name',
         headerName: 'Owner Name',
         width: 250,
         renderCell: ({ row }) => (
            <TooltipComponent
               title={`${row?.user_details.first_name} ${row?.user_details.last_name}`}
            >{`${row?.user_details.first_name} ${row?.user_details.last_name}`}</TooltipComponent>
         ),
      },
      {
         field: 'flag_type',
         headerName: 'Flag Type',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.flag_type}>
               {flags?.map(({ label, value }) => (row?.flag_type === value ? label : ''))}
            </TooltipComponent>
         ),
      },
      {
         field: 'user_details.email',
         headerName: 'Email',
         width: 250,
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.user_details.email || 'N/A'}>
               {row?.user_details.email || 'N/A'}
            </TooltipComponent>
         ),
      },
      {
         field: 'user_details.phone',
         headerName: 'Phone',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.user_details?.phone || 'N/A'}>
               {row?.user_details?.phone || 'N/A'}
            </TooltipComponent>
         ),
      },
      {
         field: 'flag_status',
         headerName: 'Status',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.flag_status || 'N/A'}>
               {row?.flag_status || 'N/A'}
            </TooltipComponent>
         ),
      },
   ];

   return (
      <>
         <TableContainer>
            <TableContent>
               <div className="d-flex justify-content-between w-100 align-items-center">
                  {isLoading ? (
                     <Skeleton width="30%" height="20" />
                  ) : (
                     <div>
                        <h1> Blocked Accounts</h1>
                        <span className="value">{paginationData?.count || 0}</span>
                     </div>
                  )}
               </div>
               <div className="d-flex align-items-center">
                  <FilterContainer onClick={() => setModalVisibilityFilter(true)}>
                     Filter By <FilterListIcon style={{ marginLeft: '10px' }} />
                  </FilterContainer>
               </div>
            </TableContent>
            <TableComponent
               columns={columns}
               rows={tableData}
               isLoading={isLoading}
               onPageChange={onPageChange}
               count={Math.ceil(paginationData.count / 15)}
               onRowClick={onRowClick}
            />
         </TableContainer>

         {modalVisibilityFilter && (
            <BlockedUserAccountsFilter
               open={modalVisibilityFilter}
               onClose={() => setModalVisibilityFilter(false)}
               filterData={filterData}
               setFilterData={setFilterData}
               setFilterPayload={setFilterPayload}
            />
         )}
      </>
   );
}

export default BlockedUserAccounts;
