/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
// import { useDebouncedCallback } from 'use-debounce';
import { Skeleton } from '@material-ui/lab';
import { TableContainer, TableContent } from 'styles/table';
// import { Input } from 'components/input';
// import Calendar from 'components/calendar';
import { TableComponent } from 'components/table';
import { numberWithCommas } from 'utils/others';
import { TooltipComponent } from 'components/tooltip';
import { useAuth } from 'hooks/auth';
import { getBuildPartnerPerformance } from 'services/build-partner';
// import { FilterContainer } from '../../style';

export default function PerformanceOverview({ filters }) {
   const { currentUser } = useAuth();
   const { permissions } = currentUser?.access || {};
   const [tableData, setTableData] = useState([]);
   const [isLoading, setIsLoading] = useState(false);
   const [paginationData, setPaginationData] = useState({});
   // const [searchTerm, setSearchTerm] = useState('');
   const [currentPage, setCurrentPage] = useState(1);

   const tableRef = useRef();

   // function handleSearch({ target }) {
   //    setSearchTerm(target.value);
   // }

   // const debounce = useDebouncedCallback(handleSearch, 600);

   function onPageChange(e, pageNumber) {
      setCurrentPage(pageNumber);
   }

   // function handleOpenModal() {
   //   setActive('overview');
   //   setModalVisibility(true);
   // document.body.style.overflow = "hidden";
   // }

   // const handleClick = (event) => {
   //    setAnchorEl(event.currentTarget);
   // };

   // const handleClose = () => {
   //    setAnchorEl(null);
   // };

   // function callNumber(phone) {
   //    const link = document.createElement('a');
   //    const linkText = document.createTextNode('call us');
   //    link.appendChild(linkText);
   //    link.href = `tel:${phone}`;
   //    link.click();
   // }

   // function sendMessageModalSection() {
   //   setActiveTab('message');
   //   setModalVisibility(true);
   // }

   const fetchManagerPerformance = async () => {
      setIsLoading(true);
      const { start_date: date_after, end_date: date_before } = filters;
      Promise.all([
         getBuildPartnerPerformance({
            date_after,
            date_before,
         }),
      ])
         .then(([businessAccountsData]) => {
            setTableData(businessAccountsData.results);
            setPaginationData(businessAccountsData);
         })
         .finally(() => {
            setIsLoading(false);
         });
   };

   useEffect(() => {
      if (permissions) {
         fetchManagerPerformance();
      }
   }, [currentPage, permissions]);

   const columns = [
      {
         field: 'partner_name',
         headerName: 'Name of Agent',
         renderCell: ({ row }) => (
            <TooltipComponent title={row.partner_name}>{row.partner_name}</TooltipComponent>
         ),
      },
      {
         field: 'accounts_assigned',
         headerName: 'Acounts Assigned',
         renderCell: ({ row }) => (
            <span style={{ textTransform: 'capitalize' }}>{row?.accounts_assigned}</span>
         ),
      },
      {
         field: 'deposit_volume',
         headerName: 'Deposit Value',
         renderCell: ({ row }) => (
            <TooltipComponent title={numberWithCommas(row.deposit_volume)}>
               ₦{numberWithCommas(row.deposit_volume)}
            </TooltipComponent>
         ),
      },
      {
         field: 'active_accounts',
         headerName: 'Active Accounts',
         renderCell: ({ row }) => (
            <TooltipComponent title={row.active_accounts}>
               {numberWithCommas(row.active_accounts)}
            </TooltipComponent>
         ),
      },
      {
         field: 'inactive_accounts',
         headerName: 'Inactive Accounts',
         renderCell: ({ row }) => (
            <TooltipComponent title={row.inactive_accounts}>
               {numberWithCommas(row.inactive_accounts)}
            </TooltipComponent>
         ),
      },
      {
         field: 'dormant_accounts',
         headerName: 'Dormant Accounts',
         renderCell: ({ row }) => (
            <TooltipComponent title={row.dormant_accounts}>
               {numberWithCommas(row.dormant_accounts)}
            </TooltipComponent>
         ),
      },
      {
         field: 'at_risk_accounts',
         headerName: 'At Risk Accounts',
         renderCell: ({ row }) => (
            <TooltipComponent title={row.at_risk_accounts}>
               {numberWithCommas(row.at_risk_accounts)}
            </TooltipComponent>
         ),
      },
      {
         field: 'churned_accounts',
         headerName: 'Churned Accounts',
         renderCell: ({ row }) => (
            <TooltipComponent title={row.churned_accounts}>
               {numberWithCommas(row.churned_accounts)}
            </TooltipComponent>
         ),
      },
   ];

   return (
      <div>
         <TableContainer>
            <TableContent>
               {isLoading ? (
                  <Skeleton width="30%" height="20" />
               ) : (
                  <div>
                     <h1> Managers</h1>
                     <span className="value">{paginationData?.count || 'N/A'}</span>
                  </div>
               )}
               {/* <div className="d-flex justify-content-between">
                  <div>
                     <Input
                        style={{ width: '300px', marginRight: '30px' }}
                        placeholder="Search"
                        onChange={debounce}
                     />
                  </div>
                  <Calendar />
               </div> */}
            </TableContent>
            <TableComponent
               columns={columns}
               rows={tableData}
               isLoading={isLoading}
               onPageChange={onPageChange}
               page={currentPage}
               ref={tableRef}
               count={Math.ceil(paginationData.count / 15)}
            />
         </TableContainer>
      </div>
   );
}

PerformanceOverview.propTypes = {
   filters: PropTypes.objectOf(PropTypes.string).isRequired,
};
