import { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import { func, bool } from 'prop-types';
import Modal from 'components/modal';
import { InputWrapper } from 'styles/form';
import { Button } from 'components/button';
import { SelectInput } from 'components/select';
import { flags } from 'utils/constants';

const status = [
   {
      label: 'Resolved',
      value: 'resolved',
   },
   {
      label: 'Flagged',
      value: 'flagged',
   },
];

const BlockedUserAccountsFilter = ({
   open,
   onClose,
   filterData,
   setFilterData,
   setFilterPayload,
}) => {
   const [isLoading, setIsLoading] = useState(false);
   const [isClearingFilter, setIsClearingFilter] = useState(false);
   const history = useHistory();

   useEffect(() => {
      return function unMount() {
         setIsLoading(false);
      };
   }, []);

   function onChange(name, data) {
      setFilterData({
         ...filterData,
         [name]: data.value,
      });
   }

   async function onSubmit() {
      setIsLoading(true);
      try {
         history.push(`${history.location.pathname}?page=1`);
         setFilterPayload(filterData);
         onClose();
      } catch (err) {
         toast.error(err.message || err.custom_message);
         setIsLoading(false);
         return err;
      } finally {
         setIsLoading(false);
      }
   }

   async function handleClearFilter() {
      setIsClearingFilter(true);
      try {
         history.push(`${history.location.pathname}?page=1`);
         setFilterData({});
         setFilterPayload({});
         onClose();
      } catch (err) {
         return err;
      }
      setIsClearingFilter(false);
   }

   return (
      <Modal
         open={open}
         onClose={onClose}
         title="Filters"
         className="biz-details-modal"
         center
         width="750px"
      >
         <form>
            <InputWrapper className="grid-2-2">
               <SelectInput
                  label="Flag Type"
                  options={flags}
                  value={flags.filter((item) => item.value === filterData?.flag_type)}
                  onChange={(val) => onChange('flag_type', val)}
               />

               <SelectInput
                  label="Status"
                  options={status}
                  value={status.filter((item) => item.value === filterData?.flag_status)}
                  onChange={(val) => onChange('flag_status', val)}
               />
            </InputWrapper>

            <InputWrapper
               style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
            >
               <div style={{ marginRight: '20px' }}>
                  <Button
                     variant="secondary"
                     type="button"
                     onClick={handleClearFilter}
                     isLoading={isClearingFilter}
                     fullWidth
                  >
                     clear filter
                  </Button>
               </div>

               <div>
                  <Button
                     variant="primary"
                     type="button"
                     onClick={onSubmit}
                     isLoading={isLoading}
                     disabled={isLoading}
                     fullWidth
                  >
                     Apply
                  </Button>
               </div>
            </InputWrapper>
         </form>
      </Modal>
   );
};

export default BlockedUserAccountsFilter;

BlockedUserAccountsFilter.propTypes = {
   open: bool.isRequired,
   onClose: func.isRequired,
   filterData: func.isRequired,
   setFilterData: func.isRequired,
   setFilterPayload: func.isRequired,
};
