import styled, { css } from 'styled-components';

function variantButtonStyles({ theme, variant }) {
   switch (variant) {
      case 'secondary':
         return css`
            color: ${theme.colors?.blue || 'var(--color-blue)'};
            background-color: ${theme.colors?.white || 'var(--color-white)'};
            border: 1px solid ${theme.colors?.blue || 'var(--color-blue)'};

            &:hover,
            &:active {
               color: ${theme.colors?.white || 'var(--color-white)'};
               background-color: ${theme.colors?.primary || 'var(--color-blue)'};
               border: 1px solid transparent;
            }
         `;
      case 'danger':
         return css`
            color: ${theme.colors?.white || 'var(--color-white)'};
            background-color: ${theme.colors?.redError || 'var(--color-red)'};
            border: 1px solid ${theme.colors?.redError || 'var(--color-red)'};

            &:hover,
            &:active {
               color: ${theme.colors?.white || 'var(--color-white)'};
               background-color: ${theme.colors?.redError || 'var(--color-red)'};
               border: 1px solid transparent;
               opacity: 0.8;
            }
         `;

      case 'danger-border':
         return css`
            color: ${theme.colors?.redError || 'var(--color-redError)'};
            background-color: ${theme.colors?.white || 'var(--color-white)'};
            border: 1px solid ${theme.colors?.redError || 'var(--color-white)'};

            &:hover,
            &:active {
               color: ${theme.colors?.white || 'var(--color-white)'};
               background-color: ${theme.colors?.redError || 'var(--color-red)'};
               border: 1px solid transparent;
               opacity: 0.8;
            }
         `;

      case 'primary-border':
         return css`
            color: ${theme.colors?.primary || 'var(--color-primary)'};
            background-color: ${theme.colors?.white || 'var(--color-white)'};
            border: 1px solid ${theme.colors?.primary || 'var(--color-primary)'};

            &:hover,
            &:active {
               color: ${theme.colors?.white || 'var(--color-white)'};
               background-color: ${theme.colors?.primary || 'var(--color-red)'};
               border: 1px solid transparent;
               opacity: 0.8;
            }
         `;

      case 'link':
         return css`
            color: ${theme.colors?.primary || 'var(--color-blue)'};
            background: none;
            font-weight: 500;

            &:hover,
            &:active {
               opacity: 0.8;
            }
         `;

      case 'green':
         return css`
            color: ${theme.colors?.white || 'var(--color-white)'};
            background: #148952;
            font-weight: 500;

            &:hover,
            &:active {
               opacity: 0.8;
            }
         `;
      case 'prospa-pink':
         return css`
            color: ${theme.colors?.white || 'var(--color-white)'};
            background: ${theme.colors?.pink || 'var(--color-pink)'};
            font-weight: 500;

            &:hover,
            &:active {
               opacity: 0.8;
            }
         `;
      case 'pink':
         return css`
            color: ${theme.colors?.white || 'var(--color-white)'};
            background: #fa4a84;
            font-weight: 500;

            &:hover,
            &:active {
               opacity: 0.8;
            }
         `;
      default:
      case 'primary':
         return css`
            color: ${theme.colors?.white || 'var(--color-white)'};
            background-color: ${theme.colors?.primary || 'var(--color-blue)'};

            &:hover,
            &:active {
               color: ${theme.colors?.white || 'var(--color-white)'};
               background-color: ${theme.colors?.primary || 'var(--color-blue)'};
               opacity: 0.8;
            }
         `;
   }
}

export const ButtonStyledComponent = styled.button`
   border: 0;
   padding: 0;
   cursor: pointer;
   box-sizing: border-box;
   display: inline-flex;
   max-width: 100%;
   text-align: center;
   padding: 1.6rem 2.4rem;
   min-height: 50px;
   overflow: hidden;
   white-space: nowrap;
   text-overflow: ellipsis;
   font-size: 1.6rem;
   letter-spacing: 0;
   line-height: 1.6rem;
   font-weight: ${({ theme }) => theme.colors?.transparent};
   border-radius: 3px;
   text-transform: capitalize;
   transition: transform 300ms ease-in-out;
   background-color: ${({ theme }) => theme.colors?.grey};
   color: ${({ theme }) => theme.colors?.grey};
   font-weight: 500;
   transition: all 300ms ease-out;
   justify-content: center;
   min-width: fit-content;

   &:disabled,
   &:disabled:active,
   &:disabled:hover {
      opacity: 0.5;
      cursor: not-allowed;
   }

   &:focus {
      outline: none;
   }

   &:hover {
      transform: scale(0.99);
   }

   ${variantButtonStyles}

   ${({ fullWidth }) =>
      fullWidth &&
      css`
         width: 100%;
      `};

   .spinner-border {
      width: 1.5rem;
      height: 1.5rem;
      border-width: 2px;
      margin-left: 1rem;
      transition: all 300ms ease-out;
      padding: 0;
      margin: 0;
   }

   &.confirm-btn-primary {
      margin-right: 15px;

      &:focus {
         outline: none;
      }
   }

   &.confirm-btn-secondary {
      &:focus {
         outline: none;
      }
   }

   ${({ size }) =>
      size === 'small' &&
      css`
         border-radius: 8px;
         height: 36px;
         padding: 0 10px;
         display: flex;
         align-items: center;
         min-height: initial;
      `}

   ${({ size }) =>
      size === 'x-small' &&
      css`
         border-radius: 4px;
         height: 40px;
         padding: 5px 5px;
         align-items: center;
         flex: 1 0 0;
      `}
`;
