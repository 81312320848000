import { formatTag } from 'utils/tags';
import {
   can_set_transaction_limit,
   can_view_transaction_limit,
   reassign_managers,
} from 'utils/user_actions';
import { hasPermission } from './user_access';

export const getDocumentType = (type) => {
   let value = '';
   switch (type) {
      case 'is_identity_verified':
         value = 'Identity File';
         break;
      case 'is_cac_cert_photo_verified':
         value = `CAC Certificate`;
         break;
      case 'is_board_verified':
         value = `Other Director Details`;
         break;
      case 'is_address_verified':
         value = 'Proof of Address';
         break;
      case 'is_tin_document_verified':
         value = `Addtional CAC Documents`;
         break;
      case 'is_tin_number_verified':
         value = `TIN Number`;
         break;
      case 'is_director_match_verified':
         value = `Business Verification for Director Match`;
         break;
      default:
         value = '';
   }

   return value;
};

export const renderTransactionStatus = (row) => {
   let template = '';
   if (row?.transfer_details) {
      template =
         row?.transfer_details?.transfer_status === 'success'
            ? formatTag('accepted', 'Success')
            : formatTag(
                 row?.transfer_details?.transfer_status,
                 row?.transfer_details?.transfer_status,
              );
   } else if (row?.bill_payment_details) {
      template =
         row?.bill_payment_details?.biller_status === 'success'
            ? formatTag('accepted', 'Success')
            : formatTag(
                 row?.bill_payment_details?.biller_status,
                 row?.bill_payment_details?.biller_status,
              );
   } else if (row?.payout_details) {
      template =
         row?.payout_details?.payout_status === 'success'
            ? formatTag('accepted', 'Success')
            : formatTag(row?.payout_details?.payout_status, row?.payout_details?.payout_status);
   } else {
      template = row?.is_done ? formatTag('accepted', 'Success') : formatTag('rejected', 'Failed');
   }
   return template;
};

const getSettingPermissions = (permissions) =>
   hasPermission(permissions, [
      reassign_managers,
      can_set_transaction_limit,
      can_view_transaction_limit,
   ]);

export const businessModalTab = (permissions) => {
   const tabs = [
      {
         label: 'Business Overview',
         value: 'business-overview',
      },
      {
         label: 'Qualifying Information',
         value: 'qualifying-information',
      },
      {
         label: 'Notes',
         value: 'notes',
      },
      {
         label: 'Log',
         value: 'log',
      },
      {
         label: 'Documents',
         value: 'documents',
      },
      {
         label: 'Send Message',
         value: 'message',
      },
      {
         label: 'Transaction Feeds',
         value: 'transactions',
      },
      {
         label: 'Accounts',
         value: 'accounts',
      },
      {
         label: 'Cards',
         value: 'cards',
      },
      {
         label: 'Requested Loans',
         value: 'requested-loans',
      },
      getSettingPermissions(permissions)
         ? {
              label: 'Settings',
              value: 'settings',
           }
         : {},
   ];

   return tabs.filter((value) => Object.keys(value).length !== 0);
};

export const questionAndAnswer = [
   {
      question: "Member's Date of Birth",
      type: 'date_of_birth',
      inputType: 'date',
   },
   {
      question: "Member's Full name",
      type: 'full_name',
      inputType: 'text',
   },
   {
      question: "Member's last transaction amount",
      type: 'last_transaction_amount',
      inputType: 'number',
   },
   {
      question: 'Phone number',
      type: 'phone_number',
      inputType: 'number',
      defaultValue: '234',
   },
];
