import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import LoggedInDashboard from 'components/logged-in-dashboard';
import TabMenu from 'components/TabMenu';
import { TabWrapper } from 'pages/pending-documents/style';
import { useAuth } from 'hooks/auth';
import useTransactionsSettings from 'hooks/transactionsSettings';
import { hasPermission } from 'utils/user_access';
import Compliance from './features/compliance';
import Settings from './features/settings';
import BlockedAccounts from './features/blocked-accounts';
import BlockedUserAccounts from './features/blocked-user-accounts';

function SettingsAndCompliance() {
   const history = useHistory();
   const { pathname } = useLocation();
   const { currentUser } = useAuth();
   const { permissions } = currentUser?.access || {};
   const [activeTab, setActiveTab] = useState('settings');

   const {
      fetchAllSettings,
      allSettings,
      prospaLimit,
      handleActiveTransfer,
      fetchProspaTransferLimit,
   } = useTransactionsSettings();

   useEffect(() => {
      fetchAllSettings();
      fetchProspaTransferLimit();
   }, []);

   const handleActiveTab = (value) => {
      if (value !== activeTab) {
         history.push(pathname);
      }
      setActiveTab(value);
   };

   const tabs = [
      {
         label: 'Settings',
         value: 'settings',
         permission: true,
      },
      {
         label: 'Compliance',
         value: 'compliance',
         permission: hasPermission(permissions, 'can_operate_settings'),
      },
      {
         label: 'Blocked Accounts',
         value: 'blocked-accounts',
         permission: hasPermission(permissions, 'can_view_compliance_flag'),
      },
      {
         label: 'User Accounts(Blocked)',
         value: 'blocked-user-accounts',
         permission: hasPermission(permissions, 'can_view_compliance_flag'),
      },
   ];

   return (
      <LoggedInDashboard pageTitle="Settings &amp; Compliance">
         <TabWrapper>
            <ul className="m-0 d-flex align-items-center">
               {tabs?.map(({ label, value, permission }) => {
                  if (permission) {
                     return (
                        <TabMenu
                           key={value}
                           text={label}
                           active={activeTab === value ? 'active' : ''}
                           setActiveTab={() => {
                              handleActiveTab(value);
                           }}
                        />
                     );
                  }
                  return null;
               })}
            </ul>
         </TabWrapper>

         {activeTab === 'settings' && (
            <Settings
               allSettings={allSettings}
               handleActiveTransfer={handleActiveTransfer}
               prospaLimit={prospaLimit}
               fetchProspaTransferLimit={fetchProspaTransferLimit}
            />
         )}
         {activeTab === 'compliance' && (
            <Compliance allSettings={allSettings} handleActiveTransfer={handleActiveTransfer} />
         )}
         {activeTab === 'blocked-accounts' && <BlockedAccounts />}
         {activeTab === 'blocked-user-accounts' && <BlockedUserAccounts />}
      </LoggedInDashboard>
   );
}

export default SettingsAndCompliance;
