import styled from 'styled-components';

export const TicketDetails = styled.div`
   .star-rating {
      width: 30px;

      img {
         width: 25px;
      }
   }
`;

export const UpdateCategoryWrapper = styled.div`
   .category-list {
      .single-category {
         border-bottom: 1px solid #d9d9d9;
         padding: 28px 0;
         cursor: pointer;

         :last-child {
            margin-bottom: 57px;
         }

         h3 {
            font-size: 20px;
            line-height: 18px;
            align-items: center;
            color: #3a434b;
         }

         img {
            height: 22px;
         }
      }
   }

   .manage-ticketing {
      h4 {
         font-size: 14px;
         line-height: 18px;
         color: #3a434b;
         margin-bottom: 14px;
      }

      .category-title {
         margin-bottom: 30px;
         border-bottom: 1px solid #d9d9d9;
         padding-bottom: 30px;

         h3 {
            font-size: 20px;
            line-height: 18px;
            color: #3a434b;
            margin-bottom: 0;
         }

         .category-action {
         }
      }
   }

   .edit-active {
      border: 1px solid #d9d9d9;
      padding: 20px;
   }
`;

export const AddCategoryWrapper = styled.div`
   .sub-category-list {
      .sub-category-item {
         height: 31.33px;
         background: rgba(81, 89, 120, 0.16);
         border-radius: 4px;
         padding: 0 10px;
      }
   }

   .faq-list-container {
      h4 {
         font-size: 14px;
         line-height: 11px;
         margin-bottom: 10px;
         font-weight: 400;
      }

      .faq-list {
         border: 1px solid #e3e4e9;
         border-radius: 8px;
         padding: 10px 0;

         .faq-list-item {
            margin-bottom: 20px;
         }

         .col-md-6:last-child .faq-list-item,
         .col-md-6:nth-last-child(2) .faq-list-item {
            margin-bottom: 0;
         }
      }
   }
`;

export const SubCategoryWrapper = styled.div`
   border-bottom: 1px solid #d9d9d9;
   padding-bottom: 30px;
   margin-bottom: 30px;

   .sub-actegory-edit {
      border: 1px solid #d9d9d9;
      padding: 20px;
   }
`;

export const FaqWrapper = styled.div`
   .faq-list {
      .faq-item {
         margin-bottom: 30px;

         h5 {
            font-size: 15px;
            line-height: 24px;
            color: #3a434b;
            font-weight: 600;
         }

         p {
            font-size: 15px;
            line-height: 24px;
            color: #3a434b;
         }
      }
   }

   .edit-faq {
      border: 1px solid #d9d9d9;
      padding: 20px;
   }
}`;

export const TicketMessagingWrapper = styled.div`
   padding: 0 60px;
   min-height: 100%;
   height: calc(100vh - 200px);
   display: flex;
   flex-direction: column;
   justify-content: space-between;

   .message-list {
      height: calc(100% - 30px);
      overflow-y: auto;

      .message-profile {
         margin-bottom: 7px;
         display: flex;
         align-items: center;

         .profile-img {
            width: 30px;
            height: 30px;
            border-radius: 50%;
         }
         .message-name {
            font-size: 10px;
            line-height: 10px;
            font-weight: 500;
            color: #3a434b;
         }
      }

      .message-container {
         margin-bottom: 16px;
      }

      .message-content {
         background: #f5f5f7;
         border-radius: 15px;
         font-size: 12px;
         line-height: 24px;
         color: #1c1335;
         padding: 15px 25px;
         width: fit-content;

         .message-file {
            cursor: pointer;
         }
      }

      .prospa-message-content {
         width: 100%;
      }

      .time-created {
         font-size: 8px;
         cursor: pointer;
      }

      .prospa-message {
         .prospa-profile {
            justify-content: end;
            flex-direction: row-reverse;
         }
         .message-content {
            background: #ffedf3;
         }
      }
   }

   .message-list::-webkit-scrollbar {
      width: 0;
   }

   .message-list::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
   }

   .message-list::-webkit-scrollbar-thumb {
      background-color: darkgrey;
      outline: 1px solid slategrey;
   }

   .compose-message {
      position: relative;

      .emoji-picker {
         position: absolute;
         transform: translateY(-102%);
      }

      .message-input {
         height: 42px;
         background: #f4f4f4;
         border-radius: 8px;
         width: 100%;
         display: flex;
         align-items: center;
         padding: 0 13px;

         .message-text-input {
            outline: none;
            height: 100%;
            margin: 0 20px;
            width: 100%;
            border: none;
            background: transparent;
         }

         .message-file-input {
            display: none;
         }

         img {
            cursor: pointer;
         }
      }
   }
`;

export const CategoryFilterWrapper = styled.div`
   width: 100%;
`;
