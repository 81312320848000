import React, { useState, useEffect } from 'react';
import { useAuth } from 'hooks/auth';
import { objectOf, func } from 'prop-types';
import { hasPermission } from 'utils/user_access';
import useTransactionsSettings from 'hooks/transactionsSettings';
import {
   can_access_interest_withdrawal_penalty_settings,
   can_access_transfer_charges_settings,
   can_set_transaction_limit,
   can_update_global_payment_settings,
   can_view_transaction_limit,
} from 'utils/user_actions';
import { Spinner } from 'components/spinner';
import CsvUpload from './csv-upload';
import WhitelistedEmails from './whitelist-emails';
import TransferLimits from './transfer-limits';
import TransferChannels from './transfer-channels';
import GlobalPaymentToggle from './global-payments-toggle';
import { TransferCharges } from './transfer-charges';
import { InterestDeduction } from './withdrawal-penalty';

const Settings = ({ allSettings, prospaLimit, fetchProspaTransferLimit }) => {
   const { currentUser } = useAuth();
   const { permissions } = currentUser?.access || {};
   const [viewWhitelist, setViewWhitelist] = useState(false);
   const [uploadCsv, setUploadCsv] = useState(false);

   const settingKeys = ['BANKING__FUND_TRANSFER_SERVICE', 'BANKING__EXTERNAL_TRANSFER_CHARGES'];

   const {
      loading,
      setWhitelistedEmails,
      fetchSetting,
      settings,
      updateSettings,
      fetchGlobalPaymentSettings,
   } = useTransactionsSettings();

   const bankSettings = settings[0];
   const transferChargesSettings = settings[1];
   const whitelistedEmails = bankSettings?.value?.easypay_allowed_emails;

   useEffect(() => {
      fetchSetting(settingKeys);
   }, []);

   useEffect(() => {
      fetchGlobalPaymentSettings();
   }, []);

   const handleCsvUpload = () => {
      setUploadCsv(true);
   };

   const changeTransfer = (value) => {
      updateSettings(value, settingKeys);
   };

   const addWhitelistedEmails = (data) => {
      const value = {
         ...bankSettings,
         value: {
            ...bankSettings.value,
            easypay_allowed_emails: data,
         },
      };
      updateSettings(value, settingKeys);
   };

   return (
      <div>
         {loading ? (
            <Spinner />
         ) : (
            <div>
               <TransferChannels
                  settings={bankSettings}
                  onChange={changeTransfer}
                  handleEmail={addWhitelistedEmails}
                  handleCsv={handleCsvUpload}
                  isLoading={false}
                  viewWhiteList={() => setViewWhitelist(true)}
                  whitelistedEmails={whitelistedEmails}
               />

               {hasPermission(permissions, can_update_global_payment_settings) && (
                  <GlobalPaymentToggle />
               )}

               {hasPermission(permissions, can_access_transfer_charges_settings) && (
                  <TransferCharges settings={transferChargesSettings} onChange={changeTransfer} />
               )}

               {hasPermission(permissions, can_view_transaction_limit) && (
                  <TransferLimits
                     limits={allSettings?.SYSTEM_TRANSACTION_LIMITS}
                     canEdit={hasPermission(permissions, can_set_transaction_limit)}
                     prospaLimit={prospaLimit}
                     fetchProspaTransferLimit={fetchProspaTransferLimit}
                  />
               )}

               {viewWhitelist && (
                  <WhitelistedEmails
                     open={viewWhitelist}
                     onClose={() => setViewWhitelist(false)}
                     handleClick={addWhitelistedEmails}
                     whitelistedEmails={whitelistedEmails}
                     setWhitelistedEmails={setWhitelistedEmails}
                  />
               )}

               {uploadCsv && (
                  <CsvUpload
                     open={uploadCsv}
                     onClose={() => setUploadCsv(false)}
                     handleClick={addWhitelistedEmails}
                     whitelistedEmails={whitelistedEmails}
                     setWhitelistedEmails={setWhitelistedEmails}
                  />
               )}

               {hasPermission(permissions, can_access_interest_withdrawal_penalty_settings) && (
                  <InterestDeduction allSettings={allSettings} />
               )}
            </div>
         )}
      </div>
   );
};

Settings.propTypes = {
   allSettings: objectOf().isRequired,
   prospaLimit: objectOf().isRequired,
   fetchProspaTransferLimit: func.isRequired,
};

export default Settings;
