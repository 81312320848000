import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { COUNTRIES } from 'utils/countries';
import { Input } from 'components/input';
import { InputWrapper } from 'styles/form';
import Modal from 'components/modal';
import { SelectInput } from 'components/select';
import { createRates } from 'services/international-transactions';
import toast from 'react-hot-toast';
import { Button } from 'components/button';
import { confirm } from 'components/confirm-box';

export const CreateExchangeRatesModal = ({ open, onClose, fetchAllCountries }) => {
   const [loading, setLoading] = useState(false);
   const [toCurrency, setToCurrency] = useState('');
   const [fromCurrency, setFromCurrency] = useState('');
   const [rate, setRate] = useState('');

   const countryOptions = COUNTRIES.map((item) => ({
      value: item.AlphabeticCode,
      label: `${item.AlphabeticCode} - ${item.Currency}`,
   }));

   const handleCreateRate = async () => {
      setLoading(true);
      const payload = {
         from_currency: fromCurrency,
         to_currency: toCurrency,
         rate,
      };
      try {
         const res = await createRates(payload);
         toast.success(res?.message || 'Exchange rate created successfully.');
         fetchAllCountries();
         onClose();
      } catch (e) {
         toast.error(e?.message || 'Something went wrong!');
      }
   };

   const handleConfirmCreateRate = () => {
      confirm({
         confirmText: 'Are you sure want to create the rate?',
         onConfirm: handleCreateRate,
      });
   };

   return (
      <Modal open={open} onClose={onClose} center title="Create Rates">
         <InputWrapper className="grid-2-2">
            <SelectInput
               label="From Currency"
               options={countryOptions}
               defaultValue={fromCurrency}
               onChange={(val) => setFromCurrency(val?.value)}
            />
            <SelectInput
               label="To Currency"
               options={countryOptions}
               defaultValue={toCurrency}
               onChange={(val) => setToCurrency(val?.value)}
            />
         </InputWrapper>

         <InputWrapper className="grid-2-2">
            <Input
               placeholder="Rate"
               label="Rate"
               name="rate"
               value={rate}
               onChange={(e) => {
                  setRate(e.target.value);
               }}
            />
         </InputWrapper>

         <InputWrapper
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
         >
            <div>
               <Button
                  variant="primary"
                  type="button"
                  onClick={handleConfirmCreateRate}
                  isLoading={loading}
                  disabled={loading || !toCurrency || !fromCurrency || !rate}
                  fullWidth
               >
                  Apply
               </Button>
            </div>
         </InputWrapper>
      </Modal>
   );
};

CreateExchangeRatesModal.propTypes = {
   open: PropTypes.bool.isRequired,
   onClose: PropTypes.func.isRequired,
   fetchAllCountries: PropTypes.func.isRequired,
};
