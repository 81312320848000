import { useEffect, useState, useCallback } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessage } from '@hookform/error-message';
import { func, objectOf, bool, string } from 'prop-types';
import Modal from 'components/modal';
import { Input, NumberInput } from 'components/input';
import { SelectInput } from 'components/select';
import { toast } from 'react-hot-toast';
import { confirm } from 'components/confirm-box';
import { typeOptions, promoStatus } from 'utils/promo-code';
import { Button } from 'components/button';
import { prospaPlans } from 'utils/prospa-plans';
import { addPromoCode, editPromoCode } from 'services/promo-code';
import moment from 'moment';

function AddPromoCode({ isEdit = false, fetchPromoCodes, onClose, open, promo }) {
   const [loading, setLoading] = useState(false);
   const [changed, setChanged] = useState(true);

   const defaultValues = {
      promo_start_date: '',
      promo_end_date: '',
      promo_type: '',
      prospa_code: '',
      promo_point: 0,
      promo_plan_name: '',
      promo_reward_duration: 0,
      promo_percent: 0,
      promo_status: '',
   };

   const promoStatusCheck = (config = {}) => {
      const { then } = config;
      return yup.string().when('promo_status', {
         is: (promo_status) => promo_status === '',
         then: then || yup.string().required(),
      });
   };

   const schema = yup.object().shape({
      promo_start_date: promoStatusCheck(),
      promo_end_date: promoStatusCheck(),
      promo_type: promoStatusCheck(),
      prospa_code: promoStatusCheck({
         then: yup.string().required('Field is required').max(7, 'Maximum length allowed is 7'),
      }),
      promo_point: yup.number().when('promo_type', {
         is: (promo_type) => promo_type === 'free_transfer',
         then: yup.number().required().min(1),
      }),
      promo_plan_name: yup.string().when('promo_type', {
         is: (promo_type) => promo_type === 'free_account_upgrade',
         then: yup.string().required().min(1),
      }),
      promo_reward_duration: yup.number().when('promo_type', {
         is: (promo_type) =>
            promo_type === 'free_account_upgrade' || promo_type === 'account_inflow',
         then: yup.number().required().min(1),
      }),
      promo_percent: yup.number().when('promo_type', {
         is: (promo_type) => promo_type === 'biz_registration_discount',
         then: yup.number().required().min(1).max(100),
      }),
      promo_status: yup.string(),
   });

   const {
      register,
      handleSubmit,
      setValue,
      trigger,
      watch,
      reset,
      formState: { isValid, errors },
   } = useForm({
      defaultValues,
      mode: 'onChange',
      resolver: yupResolver(schema),
      shouldFocusError: true,
   });

   useEffect(() => {
      register('promo_type');
      register('promo_point');
      register('promo_plan_name');
      register('promo_reward_duration');
      register('promo_percent');
      register('promo_status');
   }, []);

   const promo_type = watch('promo_type');
   const promo_point = watch('promo_point');
   const promo_percent = watch('promo_percent');
   const promo_reward_duration = watch('promo_reward_duration');
   const promo_plan_name = watch('promo_plan_name');
   const promo_status = watch('promo_status');
   const prospa_code = watch('prospa_code');
   const promo_start_date = watch('promo_start_date');

   useEffect(() => {
      if (isEdit && promo) {
         setValue('promo_status', promo.promo_status);
         setValue('prospa_code', promo.prospa_code);
         setValue('promo_point', promo.promo_point);
      }
   }, [promo, isEdit]);

   useEffect(() => {
      if (isEdit && promo) {
         setChanged(promo.promo_status !== promo_status || promo.prospa_code !== prospa_code);
      }
   }, [promo, isEdit, promo_status, prospa_code]);

   const sharedOnChange = useCallback(async ({ name, value }) => {
      setValue(name, value);
      await trigger(name);
   }, []);

   const onSubmit = (data) => {
      confirm({
         confirmText: 'Are you sure you want to continue?',
         onConfirm: async () => {
            setLoading(true);

            const {
               promo_point: promo_point_input,
               promo_percent: promo_percent_input,
               promo_reward_duration: promo_reward_duration_input,
               promo_plan_name: promo_plan_name_input,
               promo_type: promo_type_input,
               promo_status: promo_status_input,
               ...remainingData
            } = data;

            try {
               if (!isEdit) {
                  const payload = {
                     ...remainingData,
                     promo_type: promo_type_input,
                     ...(promo_type_input === 'free_transfer' && {
                        promo_point: promo_point_input,
                     }),
                     ...(promo_type_input === 'free_account_upgrade' && {
                        promo_plan_name: promo_plan_name_input,
                     }),
                     ...((promo_type_input === 'free_account_upgrade' ||
                        promo_type_input === 'account_inflow') && {
                        promo_reward_duration: promo_reward_duration_input,
                     }),
                     ...(promo_type_input === 'biz_registration_discount' && {
                        promo_percent: promo_percent_input,
                     }),
                     ...(isEdit && {
                        promo_status: promo_status_input,
                     }),
                  };
                  const { message } = await addPromoCode(payload);
                  toast.success(message);
               } else {
                  const payload = {
                     promo_id: promo.promo_id,
                     ...(promo.prospa_code !== data.prospa_code && {
                        prospa_code: data.prospa_code,
                     }),
                     promo_status: data.promo_status,
                  };
                  const { message } = await editPromoCode(payload);
                  toast.success(message);
               }
               fetchPromoCodes();
               reset(defaultValues);
               onClose();
            } catch (e) {
               toast.error(e.message);
            }
            setLoading(false);
         },
      });
   };

   const renderCodeLimit = (type) => {
      switch (type) {
         case 'free_account_upgrade':
            return (
               <>
                  <div className="col-md-6 mb-4">
                     <SelectInput
                        label="Plan"
                        name="promo_plan_name"
                        options={prospaPlans}
                        value={prospaPlans.filter((item) => item.value === promo_plan_name)}
                        onChange={({ value }) => sharedOnChange({ name: 'promo_plan_name', value })}
                     />
                  </div>
                  <div className="col-md-6 mb-4">
                     <NumberInput
                        label="Plan Duration (month)"
                        name="promo_reward_duration"
                        value={promo_reward_duration}
                        placeholder="2"
                        prefix="hidden"
                        onChange={({ floatValue }) =>
                           sharedOnChange({ name: 'promo_reward_duration', value: floatValue || 0 })
                        }
                     />
                  </div>
               </>
            );

         case 'biz_registration_discount':
            return (
               <div className="col-md-6 mb-4">
                  <NumberInput
                     label="Percentage Discount"
                     name="promo_percent"
                     placeholder="50"
                     prefix="hidden"
                     suffix="%"
                     value={promo_percent}
                     onChange={({ floatValue }) =>
                        sharedOnChange({ name: 'promo_percent', value: floatValue || 0 })
                     }
                  />
               </div>
            );

         case 'account_inflow':
            return (
               <div className="col-md-6 mb-4">
                  <NumberInput
                     label="Promo Reward Duration"
                     name="promo_reward_duration"
                     placeholder="2"
                     prefix="hidden"
                     value={promo_reward_duration}
                     onChange={({ floatValue }) =>
                        sharedOnChange({ name: 'promo_reward_duration', value: floatValue || 0 })
                     }
                  />
               </div>
            );

         // case 'biz_account_refer':
         case 'free_transfer':
            return (
               <div className="col-md-6 mb-4">
                  <NumberInput
                     label="No. Of Free Transfers"
                     name="promo_point"
                     placeholder="50"
                     prefix="hidden"
                     value={promo_point}
                     onChange={({ floatValue }) =>
                        sharedOnChange({ name: 'promo_point', value: floatValue || 0 })
                     }
                  />
               </div>
            );

         default:
            return null;
      }
   };

   return (
      <Modal
         open={open}
         onClose={onClose}
         title={isEdit ? 'Edit Promo Code' : 'Create New Promo Code'}
      >
         <form onSubmit={handleSubmit(onSubmit)} className="row">
            {!isEdit && (
               <>
                  <div className="col-md-6 mb-4">
                     <Input
                        label="Start Date"
                        type="date"
                        {...register('promo_start_date')}
                        min={moment().format('YYYY-MM-DD')}
                     />
                  </div>

                  <div className="col-md-6 mb-4">
                     <Input
                        label="End Date"
                        type="date"
                        {...register('promo_end_date')}
                        min={promo_start_date}
                     />
                  </div>

                  <div className="col-md-6 mb-4">
                     <SelectInput
                        label="Promo Code Type"
                        name="promo_type"
                        options={typeOptions}
                        value={typeOptions.filter((item) => item.value === promo_type)}
                        onChange={(value) =>
                           sharedOnChange({ name: 'promo_type', value: value.value })
                        }
                     />
                  </div>

                  {renderCodeLimit(promo_type)}
               </>
            )}

            <div className="col-md-6 mb-4">
               <Input
                  label="Promo Code"
                  placeholder="PROSPATRANSFERFREE"
                  {...register('prospa_code')}
               />
               <ErrorMessage
                  errors={errors}
                  name="prospa_code"
                  render={({ message }) => (
                     <p className="input-error-message text-danger">{message}</p>
                  )}
               />
            </div>

            {isEdit && (
               <div className="col-md-6 mb-4">
                  <SelectInput
                     label="Status"
                     name="status"
                     options={promoStatus}
                     value={promoStatus.filter((item) => item.value === promo_status)}
                     onChange={({ value }) => sharedOnChange({ name: 'promo_status', value })}
                  />
               </div>
            )}

            <div className="d-flex justify-content-end pt-5">
               <Button type="submit" isLoading={loading} disabled={!isValid || !changed}>
                  {isEdit ? 'Update Code' : 'Add New Code'}
               </Button>
            </div>
         </form>
      </Modal>
   );
}

AddPromoCode.propTypes = {
   isEdit: bool.isRequired,
   onClose: func.isRequired,
   open: func.isRequired,
   editDefault: objectOf(string).isRequired,
   fetchPromoCodes: func.isRequired,
   promo: objectOf().isRequired,
};

export default AddPromoCode;
