/* eslint-disable react/prop-types */
// import { useEffect, useState } from 'react';
import { numberWithCommas } from 'utils/others';
import moment from 'moment';
import { Status } from 'styles/utils';
import { InfoGroup, Label, OverviewSection, SectionTitle } from 'styles/layout';
import { Spinner } from 'components/spinner';
import { TooltipComponent } from 'components/tooltip';
import { TableContainer } from 'styles/table';
import { TableComponent } from 'components/table';
// import { getPCRLabel } from 'utils/loans';
import { objectOf, string } from 'prop-types';
// import './style';

export default function LoanOverview({ orderDetails }) {
   // const [tableData, setTableData] = useState();

   // useEffect(() => {
   //    setTableData(orderDetails?.payment_details);
   // }, [orderDetails]);

   const columns = [
      {
         field: 'row?.transaction_id',
         headerName: 'Transaction ID',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.transaction_id}>{row?.transaction_id}</TooltipComponent>
         ),
      },
      {
         field: 'row?.source_account_name',
         headerName: 'Source Account Name',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.source_account_name}>
               {row?.source_account_name}
            </TooltipComponent>
         ),
      },
      {
         field: 'row?.trans_amount',
         headerName: 'Amount',
         renderCell: ({ row }) => (
            <TooltipComponent
               title={row?.trans_amount && `₦ ${numberWithCommas(row?.trans_amount)}`}
            >
               {row?.trans_amount && `₦ ${numberWithCommas(row?.trans_amount)}`}
            </TooltipComponent>
         ),
      },
      {
         field: 'row?.pub_date',
         headerName: 'Date Created',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.pub_date}>
               <span>
                  {moment(row?.pub_date).format('MMM DD, YYYY')} |{' '}
                  {moment(row?.pub_date).format('h:mm a')}
               </span>
            </TooltipComponent>
         ),
      },
   ];
   return orderDetails?.pos_order_id ? (
      <div>
         <OverviewSection>
            <InfoGroup>
               <Label>Business Name</Label>
               {orderDetails?.biz_details?.biz_name}
            </InfoGroup>

            <InfoGroup>
               <Label>Device Quantity</Label>
               {orderDetails?.device_quantity || 'N/A'}
            </InfoGroup>

            <InfoGroup>
               <Label>Device Total Amount</Label>
               {orderDetails?.device_total_amount &&
                  `₦ ${numberWithCommas(orderDetails?.device_total_amount)}`}
            </InfoGroup>

            <InfoGroup>
               <Label>Total Amount Paid</Label>
               {orderDetails?.device_total_amount &&
                  `₦ ${numberWithCommas(orderDetails?.device_total_amount)}`}
            </InfoGroup>

            <InfoGroup>
               <Label>Order Status</Label>
               <Status status={orderDetails?.order_status}>
                  {orderDetails?.order_status || 'N/A'}
               </Status>
            </InfoGroup>

            <InfoGroup>
               <Label>Delivery Status</Label>
               <Status status={orderDetails?.delivery_status}>
                  {orderDetails?.delivery_status || 'N/A'}
               </Status>
            </InfoGroup>

            <InfoGroup>
               <InfoGroup>
                  <Label>Payment Type</Label>
                  <Status
                     status={orderDetails?.payment_type === 'full_payment' ? 'approved' : 'pending'}
                  >
                     {orderDetails.payment_type.replaceAll('_', ' ') || 'N/A'}
                  </Status>
               </InfoGroup>
               <Label>Repayment Allocation</Label>
               {orderDetails?.repayment_allocation || 0}%
            </InfoGroup>

            <InfoGroup>
               <Label>Delivery Address</Label>
               {`${orderDetails?.delivery_street}, ${orderDetails?.delivery_city}, ${orderDetails?.delivery_state}`}
            </InfoGroup>

            <InfoGroup>
               <Label>Delivery Note</Label>
               {orderDetails?.delivery_note || 'N/A'}
            </InfoGroup>

            <InfoGroup>
               <Label>Delivery Phone</Label>
               {orderDetails?.delivery_phone || 'N/A'}
            </InfoGroup>

            <InfoGroup>
               <Label>Created Date</Label>
               <span>
                  {moment(orderDetails?.pub_date).format('MMM DD, YYYY')} |{' '}
                  {moment(orderDetails?.pub_date).format('h:mm a')}
               </span>
            </InfoGroup>

            {/* <InfoGroup>
               <Label>Loan PCR Score</Label>
               {orderDetails?.loan_crs_score ? (
                  <>
                     {getPCRLabel(orderDetails?.loan_crs_score)}
                     <span style={{ fontSize: '12px' }}> - {orderDetails?.loan_crs_score}</span>
                  </>
               ) : (
                  <>
                     {getPCRLabel('N/A')} <span style={{ fontSize: '12px' }}> - N/A</span>
                  </>
               )}
            </InfoGroup> */}

            {/* <InfoGroup>
               <Label>Loan PCR Max Amount</Label>
               {(orderDetails && `₦ ${numberWithCommas(orderDetails?.loan_crs_max_amount)}`) || 'N/A'}
            </InfoGroup> */}
         </OverviewSection>

         <SectionTitle>Payment Details</SectionTitle>
         <TableContainer>
            <TableComponent
               columns={columns}
               rows={orderDetails?.payment_details}
               isLoading={false}
               // onPageChange={() => ''}
               // count={Math.ceil(15 / 15)}
               // onRowClick={() => ''}
            />
         </TableContainer>
      </div>
   ) : (
      <Spinner />
   );
}

LoanOverview.propTypes = {
   orderDetails: objectOf(string).isRequired,
   // row:objectOf(string).isRequired
};
