import { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import LoggedInDashboard from 'components/logged-in-dashboard';
import TabMenu from 'components/TabMenu';
import { TabWrapper } from 'pages/pending-documents/style';
import { useAuth } from 'hooks/auth';
import { can_update_tenure, can_update_loan_terms } from 'utils/user_actions';
import { hasPermission } from 'utils/user_access';
import ExportedData from 'components/exported-data';
import LoansDashboard from './features/loans-dashboard';
import LoanTenure from './features/loan-tenure';
import LoanTerm from './features/loan-term';

function Loans() {
   const { pathname } = useLocation();
   const [activeTab, setActiveTab] = useState('loans');
   const history = useHistory();
   const { currentUser } = useAuth();
   const { permissions } = currentUser?.access || {};

   const handleActiveTab = (value) => {
      if (value !== activeTab) {
         history.push(pathname);
      }
      setActiveTab(value);
   };

   const exportFilters = [
      {
         label: 'Start Date',
         key: 'start_date',
         type: 'date',
      },
      {
         label: 'End Date',
         key: 'end_date',
         type: 'date',
      },
   ];

   const tabs = [
      {
         label: 'Loans',
         value: 'loans',
         permission: true,
      },
      {
         label: 'Data Exports',
         value: 'exports',
         permission: hasPermission(permissions, 'admin_can_view_exported_data'),
      },
      {
         label: 'Loan Tenure',
         value: 'tenure',
         permission: hasPermission(permissions, can_update_tenure),
      },
      {
         label: 'Loan Terms',
         value: 'terms',
         permission: hasPermission(permissions, can_update_loan_terms),
      },
   ];

   const exportTypes = [
      {
         value: 'export_lender_data',
         label: 'Loan data dump',
         downloadPath: 'admin_lender_export',
         filters: exportFilters,
      },
      {
         value: 'export_loan_data',
         label: 'Loan Report',
         downloadPath: 'admin_loan_exports',
         filters: exportFilters,
      },
      // {
      //    value: 'export_transaction_history_data',
      //    label: 'Transactions data dump',
      // },
      // {
      //    value: 'export_wallet_data',
      //    label: 'Conversion data dump',
      // },
   ];

   return (
      <LoggedInDashboard pageTitle="Loans">
         <TabWrapper>
            <ul className="m-0 d-flex align-items-center">
               {tabs?.map(({ label, value, permission }) => {
                  if (permission) {
                     return (
                        <TabMenu
                           key={value}
                           text={label}
                           active={activeTab === value ? 'active' : ''}
                           setActiveTab={() => {
                              handleActiveTab(value);
                           }}
                        />
                     );
                  }
                  return null;
               })}
            </ul>
         </TabWrapper>
         {activeTab === 'loans' && <LoansDashboard exportTypes={exportTypes} />}
         {activeTab === 'exports' && <ExportedData exportTypes={exportTypes} />}
         {activeTab === 'tenure' && <LoanTenure />}
         {activeTab === 'terms' && <LoanTerm />}
      </LoggedInDashboard>
   );
}

export default Loans;
