import { Http } from 'services/http';
import { paramsObjectToQueryString } from 'utils/others';
import {
   can_operate_settings,
   can_view_all_safe_transactions,
   can_transact_with_savings_account,
   can_view_safe_stats,
   can_view_safe_interest_earnings,
   can_view_billing_records,
} from 'utils/user_actions';

export const getActiveSavings = (page) =>
   Http(`/express/savings/dashboard/${paramsObjectToQueryString(page)}`);

export const getTotalSafesCount = (params) =>
   Http(`/backmen/total_safes_count${paramsObjectToQueryString(params)}`, {
      action: can_view_safe_stats,
   });

export const getTotalSafeBalance = (params) =>
   Http(`/backmen/total_safe_balance${paramsObjectToQueryString(params)}`, {
      action: can_view_safe_stats,
   });

export const getTotalSafeInterestEarned = (params) =>
   Http(`/backmen/safe_interest_earned${paramsObjectToQueryString(params)}`, {
      action: can_view_safe_stats,
   });

export const getSavingsInterestLog = (params) =>
   Http(`/backmen/safe_interest_log${paramsObjectToQueryString(params)}`, {
      action: can_view_safe_interest_earnings,
   });

export const getSingleSafe = (id) => Http(`/express/savings/${id}/`);

export const listSavingsTransactions = (params) => {
   const { businessAccountId, ...rest } = params;
   return Http(
      `/express/savings/transactions/${businessAccountId}${paramsObjectToQueryString(rest)}`,
      {
         action: can_view_all_safe_transactions,
      },
   );
};
export const withdrawFromSavings = (payload) =>
   Http(`/backmen/admin_wallet_fund_transfer`, {
      method: 'POST',
      body: payload,
      action: can_transact_with_savings_account,
   });

export const getInterestRate = () =>
   Http(`/settings/settings/`, {
      action: can_operate_settings,
   });

export const createInterestRate = (payload) =>
   Http(`/settings/settings/`, {
      method: 'POST',
      body: payload,
      action: can_operate_settings,
   });

export const updateInterestRate = (id, payload) =>
   Http(`/settings/${id}/`, {
      method: 'PATCH',
      body: payload,
      action: can_operate_settings,
   });

export const getBusinessSafeSettings = (params) =>
   Http(`/express/savings/dashboard?${paramsObjectToQueryString(params)}`);

export const exportSavingsData = (params) =>
   Http(`/express/savings/report/${paramsObjectToQueryString(params)}`, {
      action: can_view_all_safe_transactions,
   });

export const getBillingDebtLogs = (params) => {
   return Http(`/backmen/billing_records${paramsObjectToQueryString(params)}`, {
      action: can_view_billing_records,
   });
};
