import React, { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { string } from 'prop-types';
import { Input } from 'components/input';
import { Spinner } from 'components/spinner';
import { interests } from 'utils/business-constants';
import { updateQualifyingInformation, getQualifyingInformation } from 'services/businessDetails';
import {
   BoolStatus,
   InfoGroup,
   Label,
   OverviewSection,
   OverviewWrapper,
   SectionTitle,
   Value,
} from 'styles/layout';
import { Button } from 'components/button';

export default function QualifyingInformation({ businessAccountId }) {
   const [bizDescription, setBizDescription] = useState('');
   const [noOfBranches, setNoOfBranches] = useState('');
   const [loading, setLoading] = useState(false);
   const [qualifyingInfo, setQualifyingInfo] = useState(null);

   const hasEmptyField = () => !bizDescription || !noOfBranches;

   const valueUnchanged = () =>
      bizDescription === qualifyingInfo?.biz_description &&
      noOfBranches === qualifyingInfo?.no_of_branches;

   useEffect(() => {
      setBizDescription(qualifyingInfo?.biz_description);
      setNoOfBranches(qualifyingInfo?.no_of_branches);
   }, [qualifyingInfo]);

   const fetchQualifyingInfo = async () => {
      setLoading(true);
      if (businessAccountId) {
         try {
            const res = await getQualifyingInformation(businessAccountId);
            setQualifyingInfo(res.data);
         } catch (e) {
            toast.error(e?.message);
         }
         setLoading(false);
      }
   };

   const handleUpdateInformation = async (e) => {
      e.preventDefault();
      setLoading(true);
      try {
         const payload = {
            ...(bizDescription && { biz_description: bizDescription }),
            ...(noOfBranches && { no_of_branches: noOfBranches }),
         };
         const res = await updateQualifyingInformation(businessAccountId, payload);
         toast.success(res?.message || 'Details updated Successfully');
         fetchQualifyingInfo();
      } catch (err) {
         toast.error(err?.message);
      }
   };

   useEffect(() => {
      fetchQualifyingInfo();
   }, []);

   return (
      <>
         {loading ? (
            <Spinner />
         ) : (
            <>
               <form onSubmit={handleUpdateInformation}>
                  <OverviewWrapper>
                     <OverviewSection>
                        <InfoGroup>
                           <Label>Number of Employees</Label>
                           <Value>{qualifyingInfo?.no_of_employees || 'N/A'}</Value>
                        </InfoGroup>
                        <InfoGroup>
                           <Label>Business Description</Label>
                           <Input
                              id="bizDescription"
                              placeholder="Business Description"
                              className="overview-input"
                              value={bizDescription}
                              onChange={(e) => setBizDescription(e.target?.value)}
                           />
                        </InfoGroup>
                        <InfoGroup>
                           <Label>AVG Monthly Turnover</Label>
                           <Value>{qualifyingInfo?.monthly_turnover || 'N/A'}</Value>
                        </InfoGroup>
                        <InfoGroup>
                           <Label>Website or Social media</Label>
                           <Value>
                              <a
                                 href={qualifyingInfo?.website_link}
                                 target="_blank"
                                 rel="noreferrer"
                              >
                                 {qualifyingInfo?.website_link || 'N/A'}
                              </a>
                           </Value>
                        </InfoGroup>
                        <InfoGroup>
                           <Label>Sector of Business</Label>
                           <Value>{qualifyingInfo?.sector || 'N/A'}</Value>
                        </InfoGroup>
                        <InfoGroup>
                           <Label>Years of Operation</Label>
                           <Value>{qualifyingInfo?.years_in_operation || 'N/A'}</Value>
                        </InfoGroup>
                        <InfoGroup>
                           <Label>Number of Branches</Label>
                           <Input
                              id="noOfBranches"
                              placeholder="Number of Branches"
                              className="overview-input"
                              value={noOfBranches}
                              onChange={(e) => setNoOfBranches(e.target?.value)}
                           />
                        </InfoGroup>
                        <InfoGroup>
                           <Label>Country of Operation</Label>
                           <Value>{qualifyingInfo?.country_of_operation || 'N/A'}</Value>
                        </InfoGroup>
                        <InfoGroup>
                           <Label>How soon do you intend to start using your Account?</Label>
                           <Value>{qualifyingInfo?.how_soon_to_start || 'N/A'}</Value>
                        </InfoGroup>
                        <InfoGroup>
                           <Label>Other Businesses</Label>
                           <BoolStatus isTrue={qualifyingInfo?.other_businesses}>
                              {qualifyingInfo?.other_businesses ? 'Yes' : 'No'}
                           </BoolStatus>
                        </InfoGroup>
                     </OverviewSection>
                  </OverviewWrapper>
                  <SectionTitle />
                  <div>
                     <span>
                        <b>Interests</b>
                     </span>
                     <div className="d-flex flex-wrap gap-3 align-items-center justify-content-start">
                        {interests.map((interest) => (
                           <div
                              key={interest}
                              className="rounded-pill mt-3 "
                              style={
                                 qualifyingInfo?.interested_products?.includes(interest)
                                    ? {
                                         backgroundColor: '#1354D31A',
                                         borderStyle: 'solid',
                                         borderWidth: '1px',
                                         borderColor: '#1354D3',
                                      }
                                    : {
                                         backgroundColor: 'white',
                                         borderStyle: 'solid',
                                         borderWidth: '1px',
                                         borderColor: 'grey',
                                      }
                              }
                           >
                              <p
                                 className="m-3"
                                 style={
                                    qualifyingInfo?.interested_products?.includes(interest)
                                       ? { color: '#1354D3' }
                                       : { color: 'grey' }
                                 }
                              >
                                 {interest}
                              </p>
                           </div>
                        ))}
                     </div>
                  </div>
                  <div className="d-flex justify-content-end mt-3">
                     <Button
                        type="submit"
                        isLoading={loading}
                        disabled={hasEmptyField() || valueUnchanged() || loading}
                     >
                        Update Details
                     </Button>
                  </div>
               </form>
            </>
         )}
      </>
   );
}
QualifyingInformation.propTypes = {
   businessAccountId: string.isRequired,
};
