import { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import { func, bool } from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Input, NumberInput } from 'components/input';
import Modal from 'components/modal';
import { InputWrapper } from 'styles/form';
import { Button } from 'components/button';
import { SelectInput } from 'components/select';
import { TRANSACTION_TYPE } from 'utils/transactions';

const FilterTransactionModal = ({
   open,
   onClose,
   setCurrentPage,
   filterData,
   setFilterData,
   setFilterPayload,
}) => {
   const [isLoading, setIsLoading] = useState(false);
   const [isClearingFilter, setIsClearingFilter] = useState(false);
   const history = useHistory();

   useEffect(() => {
      return function unMount() {
         setIsLoading(false);
      };
   }, []);

   const getTransactionType = (type) => {
      switch (type) {
         case null:
            return {};
         case 'pos':
            return {
               child_category: 'pos inflow',
               parent_category: 'transfer',
               tx_action: 'credit_transfer_external',
            };
         case 'flutterwave':
            return {
               source_provider: 'flutterwave',
               is_done: 'true',
            };
         case 'paystack':
            return {
               source_provider: 'paystack',
               is_done: 'true',
            };
         case 'nibbs':
            return {
               source_provider: 'goodnews_mfb',
               is_done: 'true',
               tx_note__icontains: 'NIP Transfer',
            };
         default:
            return {};
      }
   };

   const getTransactionAmount = (status, value) => {
      switch (status) {
         case 'amount_gt':
            return {
               trans_amount__gt: value,
            };
         case 'amount_lt':
            return {
               trans_amount__lt: value,
            };
         default:
            return {};
      }
   };
   const getTransactionDate = (status, value) => {
      switch (status) {
         case 'start_date':
            return {
               start_date: value,
            };
         case 'end_date':
            return {
               end_date: value,
            };
         default:
            return {};
      }
   };
   async function onSubmit() {
      setIsLoading(true);
      setCurrentPage(() => 1);
      try {
         const { type, amount_gt, amount_lt, start_date, end_date } = filterData;

         const param = {
            ...(type && { type: type.value }),
            ...(amount_gt && { amount_gt: amount_gt.value }),
            ...(amount_lt && { amount_lt: amount_lt.value }),
            ...(start_date && { start_date }),
            ...(end_date && { end_date }),
         };

         const params = {
            ...(type && getTransactionType(param.type)),
            ...(amount_gt && getTransactionAmount('amount_gt', param.amount_gt)),
            ...(amount_lt && getTransactionAmount('amount_lt', param.amount_lt)),
            ...(start_date && getTransactionDate('start_date', param.start_date)),
            ...(end_date && getTransactionDate('end_date', param.end_date)),
         };

         setFilterPayload(params);
         onClose();
      } catch (err) {
         toast.error(err.message || err.custom_message);
      }
      setIsLoading(false);
   }

   async function handleClearFilter() {
      setIsClearingFilter(true);
      try {
         setCurrentPage(() => 1);
         history.push(`${history.location.pathname}?page=1`);
         setFilterData({});
         setFilterPayload({});
         onClose();
      } catch (err) {
         return err;
      }
      setIsClearingFilter(false);
   }

   return (
      <Modal
         open={open}
         onClose={onClose}
         title="Filters"
         className="biz-details-modal"
         center
         width="750px"
      >
         <form>
            <InputWrapper className="grid-2-2">
               <Input
                  placeholder="Start Date"
                  label="Start Date"
                  name="start_date"
                  type="date"
                  value={filterData?.start_date}
                  onChange={(val) => setFilterData({ ...filterData, start_date: val.target.value })}
               />
               <Input
                  placeholder="End Date"
                  label="End Date"
                  name="end_date"
                  type="date"
                  value={filterData?.end_date}
                  onChange={(val) => setFilterData({ ...filterData, end_date: val.target.value })}
               />
            </InputWrapper>
            <InputWrapper className="grid-2-2">
               <SelectInput
                  onChange={(val) => setFilterData({ ...filterData, type: val })}
                  label="Transfer Type"
                  options={TRANSACTION_TYPE}
                  defaultValue={filterData?.type}
               />
            </InputWrapper>
            <InputWrapper className="grid-2-2">
               <NumberInput
                  label="Value Greater Than"
                  name="amount_gt"
                  prefix="₦"
                  value={filterData?.amount_gt?.value}
                  className={Input}
                  inputmode="numeric"
                  onChange={(val) => setFilterData({ ...filterData, amount_gt: val })}
               />
               <NumberInput
                  label="Value Lesser Than"
                  name="amount_lt"
                  prefix="₦"
                  value={filterData?.amount_lt?.value}
                  className={Input}
                  inputmode="numeric"
                  onChange={(val) => setFilterData({ ...filterData, amount_lt: val })}
               />
            </InputWrapper>

            <InputWrapper
               style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
            >
               <div style={{ marginRight: '20px' }}>
                  <Button
                     variant="secondary"
                     type="button"
                     onClick={handleClearFilter}
                     isLoading={isClearingFilter}
                     fullWidth
                  >
                     clear filter
                  </Button>
               </div>

               <div>
                  <Button
                     variant="primary"
                     type="button"
                     onClick={onSubmit}
                     isLoading={isLoading}
                     disabled={isLoading}
                     fullWidth
                  >
                     Apply
                  </Button>
               </div>
            </InputWrapper>
         </form>
      </Modal>
   );
};

export default FilterTransactionModal;

FilterTransactionModal.propTypes = {
   open: bool.isRequired,
   onClose: func.isRequired,
   setCurrentPage: func.isRequired,
   filterData: func.isRequired,
   setFilterData: func.isRequired,
   setFilterPayload: func.isRequired,
};
