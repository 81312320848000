/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { Button } from 'components/button';
import { Input } from 'components/input';
import { Label } from 'components/input/input.style';
import { AlertMessage } from 'components/message';
import { SelectInput } from 'components/select';
import { number } from 'prop-types';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { getBusinessDetails, sendEmail, sendSMS } from 'services/businessDetails';
import { whatsappMessageTracker } from 'services/business';
import {
   ButtonWrapper,
   CheckboxContainer,
   InputContainer,
   InputGroup,
   MessageContainer,
   MessageForm,
   MessageTextArea,
} from './sendMessage.style';

const SendMessage = ({ businessAccountId }) => {
   const [bizDetails, setBizDetails] = useState({});
   const [individualPhone, setIndividualPhone] = useState('');
   const [individualEmail, setIndividualEmail] = useState('');

   const [isEmail, setIsEmail] = useState(true);
   const [isText, setIsText] = useState(false);

   const [bizPhone, setBizPhone] = useState('');
   const [bizEmail, setBizEmail] = useState('');

   const [subject, setSubject] = useState('');
   const [message, setMessage] = useState('');

   const [loading, setLoading] = useState(false);

   const [selectedContacts, setSelectedContacts] = useState({});

   const [error, setError] = useState('');

   const fetchBusinessDetails = async () => {
      try {
         const result = await getBusinessDetails(businessAccountId);
         if (result.ok) {
            setBizDetails(result.data);
            setError('');
         }
      } catch (err) {
         setError(err.message || err.custom_message);
      }
   };

   const handleSelectContacts = (option) => {
      if (option?.value === 'individual') {
         setSelectedContacts({ email: individualEmail, phone: individualPhone });
      } else if (option?.value === 'business') {
         setSelectedContacts({ email: bizEmail, phone: bizPhone });
      }
   };

   const handleSelectMessageType = (option) => {
      if (option?.value === 'messageType1') {
         setMessage('You have selected Message Type 1');
      } else if (option?.value === 'messageType2') {
         setMessage('You have selected Message Type 2');
      }
   };

   const sendEmailMessage = async () => {
      setLoading(true);
      const { email } = selectedContacts;
      const messageData = {
         email_address: [email || individualEmail],
         email_subject: subject,
         email_message: message,
      };
      try {
         const result = await sendEmail(messageData, businessAccountId);
         if (result.ok) {
            toast.success(result?.message, { duration: 3000 });
            setSubject('');
            setMessage('');
            setError('');
         }
         setLoading(false);
      } catch (err) {
         setError(err.message || err.custom_message);
         setLoading(false);
      }
   };

   const sendTextMessage = async () => {
      setLoading(true);
      const { phone } = selectedContacts;
      const messageData = {
         sms_recipient: [phone || individualPhone],
         sms_message: message,
      };
      try {
         const result = await sendSMS(messageData, businessAccountId);
         if (result.ok) {
            toast.success('SMS has been processed.', { duration: 3000 });
            setSubject('');
            setMessage('');
            setError('');
         }
         setLoading(false);
      } catch (err) {
         setError(err.message || err.custom_message);
         setLoading(false);
      }
   };

   const sendWhatsAppMessage = async () => {
      try {
         await whatsappMessageTracker({ phone: selectedContacts?.phone });
         const url = `https://wa.me/${selectedContacts?.phone}/?text=${message}`;
         window.open(url);
      } catch (e) {
         toast.error(e.message || 'An error occured!');
      }
   };

   const handleSubmit = (e) => {
      e.preventDefault();

      if (isEmail) {
         sendEmailMessage();
      }

      if (isText) {
         sendTextMessage();
      }
   };

   const messageTypeOptions = [
      { value: 'messageType1', label: 'Message Type 1' },
      { value: 'messageType2', label: 'Message Type 2' },
   ];

   const recipientOptions = [
      { value: 'individual', label: `Individual - ${individualEmail} - ${individualPhone}` },
      {
         value: 'business',
         label: `Business ${bizEmail ? `- ${bizEmail}` : ''} ${bizPhone ? `- ${bizPhone}` : ''}`,
      },
   ];

   useEffect(() => {
      fetchBusinessDetails();
   }, []);

   useEffect(() => {
      setIndividualEmail(bizDetails?.account_holder_details?.email);
      setIndividualPhone(bizDetails?.account_holder_details?.phone);

      setBizEmail(bizDetails?.biz_email);
      setBizPhone(bizDetails?.biz_phone);
   }, [bizDetails]);

   return (
      <div>
         <AlertMessage message={error} variant="error" />
         <MessageForm onSubmit={handleSubmit}>
            <MessageContainer>
               <InputGroup>
                  <InputContainer>
                     <SelectInput
                        options={messageTypeOptions}
                        label="Message Type"
                        onChange={handleSelectMessageType}
                     />
                  </InputContainer>

                  <InputContainer>
                     <Label>Channels</Label>
                     <CheckboxContainer>
                        <label htmlFor="email" className="me-5 checkbox-label">
                           <span>Email</span>
                           <input
                              type="checkbox"
                              id="email"
                              className="message-checkbox"
                              checked={isEmail}
                              onChange={() => setIsEmail((prevState) => !prevState)}
                           />
                        </label>

                        <label htmlFor="textMessage" className="checkbox-label">
                           Text Message
                           <input
                              type="checkbox"
                              id="textMessage"
                              className="message-checkbox"
                              checked={isText}
                              onChange={() => setIsText((prevState) => !prevState)}
                           />
                        </label>
                     </CheckboxContainer>
                  </InputContainer>
               </InputGroup>

               <InputGroup>
                  <InputContainer>
                     <SelectInput
                        options={recipientOptions}
                        label="Send To"
                        onChange={handleSelectContacts}
                     />
                  </InputContainer>

                  <InputContainer className="subject-container">
                     <Input
                        id="subject"
                        placeholder="Subject"
                        name="subject"
                        className="message-input"
                        label="Subject"
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                     />
                  </InputContainer>
               </InputGroup>

               <Label>Message</Label>

               <MessageTextArea value={message} onChange={(e) => setMessage(e.target.value)} />
            </MessageContainer>

            <ButtonWrapper>
               <Button
                  type="button"
                  className="me-3 whatsapp-btn"
                  disabled={!selectedContacts?.phone || !message}
                  onClick={sendWhatsAppMessage}
               >
                  Send WhatsApp Message
               </Button>
               <Button
                  type="submit"
                  disabled={
                     !Object.keys(selectedContacts)?.length ||
                     !message ||
                     (isEmail && !subject) ||
                     (!isEmail && !isText) ||
                     loading
                  }
                  isLoading={loading}
               >
                  Send Message
               </Button>
            </ButtonWrapper>
         </MessageForm>
      </div>
   );
};

SendMessage.propTypes = {
   businessAccountId: number.isRequired,
};

export default SendMessage;
