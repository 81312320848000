import React from 'react';
import { func, number } from 'prop-types';
import { Bar } from 'react-chartjs-2';

const BarChart = ({ data, height }) => {
   const options = {
      spanGaps: true,
      legend: {
         display: false,
      },
      tooltips: {
         backgroundColor: '#04093F',
         titleFontColor: '#FFFFFF',
         bodyFontColor: '#FFFFFF',
         callbacks: {
            label(tooltipItem, details) {
               let label = details.datasets[tooltipItem.datasetIndex].label || '';

               if (label) {
                  label += ': ₦';
               }
               label += (Math.round(tooltipItem.yLabel * 100) / 100).toLocaleString();
               return label;
            },
         },
      },
      maintainAspectRatio: false,
      elements: {
         point: {
            radius: 4,
            borderWidth: 1,
            hoverRadius: 4,
            hoverBorderWidth: 1,
         },
         line: {
            tension: 0,
         },
      },
      scales: {
         xAxes: [
            {
               display: true,
               stacked: true,
               gridLines: {
                  display: false,
                  drawBorder: false,
               },
            },
         ],
         yAxes: [
            {
               display: true,
               stacked: true,
               ticks: {
                  padding: 20,
                  maxTicksLimit: 6,
               },
            },
         ],
      },
   };

   return <Bar data={data} height={height || 185} options={options} />;
};

BarChart.propTypes = {
   data: func.isRequired,
   height: number.isRequired,
};
export { BarChart };
