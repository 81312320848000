import styled from 'styled-components/macro';

export const Navigation = styled.div`
   display: flex;
   align-items: center;
   margin-top: 2.5rem;
   position: relative;
   border-bottom: 1px solid #d7dce0;
   width: 100%;
   overflow-x: scroll;

   @media (max-width: 550px) {
      justify-content: space-between;
      font-size: 0.9rem;
   }

   @media (max-width: 360px) {
      font-size: 0.8rem;
   }
`;

export const NavigationTab = styled.div`
   cursor: pointer;
   padding: 1rem 1.5rem;
   font-size: 14px;
   font-weight: ${({ theme }) => theme.fontWeights?.semiBold};
   color: ${({ theme }) => theme.colors?.grey03};
   text-align: center;
   position: relative;
   white-space: nowrap;

   &.active {
      border-bottom: 2px solid ${({ theme }) => theme.colors?.blue01};
   }
`;

export const UserCoursesFooter = styled.div`
   text-align: center;
   background-color: #faf8f5;
   padding: 5rem;
   margin-top: 8rem;

   button {
      margin-top: 3rem;
   }
`;
