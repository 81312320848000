import { bool } from 'prop-types';
import { SpinnerContainer } from './spinner.style';

export const Spinner = ({ full }) => {
   return (
      <SpinnerContainer full={full}>
         <div
            style={{ width: '3rem', height: '3rem' }}
            className="spinner-border spinner-border-lg text-primary"
            role="status"
         />
      </SpinnerContainer>
   );
};

Spinner.propTypes = {
   full: bool,
};

Spinner.defaultProps = {
   full: false,
};
