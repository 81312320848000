import { Button } from 'components/button';
import { confirm } from 'components/confirm-box';
import { NumberInput } from 'components/input';
import Modal from 'components/modal';
import { bool, func, number, objectOf, string } from 'prop-types';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { editCardDetails } from 'services/cards';
import { InputWrapper } from 'styles/form';

export const ChangeLimitModal = ({ open, onClose, limitType, limits, cardId, fetchCard }) => {
   const [loading, setLoading] = useState(false);
   const [limitAmount, setLimitAmount] = useState(limits?.amount);

   const handleUpdateLimit = () => {
      setLoading(true);
      confirm({
         confirmText: 'Are you sure want to update the limit?',
         onConfirm: async () => {
            const payload = {
               limits: {
                  rate: limitType,
                  amount: limitAmount,
                  user: limits.user,
               },
            };
            try {
               const res = await editCardDetails(payload, cardId);
               toast.success(res?.response || 'Limit successfully updated');
               fetchCard();
            } catch (e) {
               toast.error(e?.message);
            }
         },
      });
   };
   return (
      <Modal
         open={open}
         onClose={onClose}
         title={limitType === 'monthly' ? 'Monthly Limit ' : 'Daily Limit'}
         center
         closeOnOverlayClick={false}
      >
         <div className="d-flex justify-content-between">
            <NumberInput
               label="Amount"
               placeholder="Enter the Amount"
               name="limitAmount"
               value={limitAmount}
               onChange={(e) => setLimitAmount(e.floatValue)}
            />
         </div>
         <InputWrapper
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
         >
            <div>
               <Button variant="primary" disabled={loading} onClick={handleUpdateLimit}>
                  Apply
               </Button>
            </div>
         </InputWrapper>
      </Modal>
   );
};

ChangeLimitModal.propTypes = {
   open: bool.isRequired,
   onClose: func.isRequired,
   limitType: string.isRequired,
   limits: objectOf().isRequired,
   cardId: number.isRequired,
   fetchCard: func.isRequired,
};
