import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { toast } from 'react-hot-toast';
import { useLocation } from 'react-router-dom';
import { IconButton } from '@material-ui/core';
import { MoreHoriz } from '@material-ui/icons';
import FilterListIcon from '@material-ui/icons/FilterList';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import { useDebouncedCallback } from 'use-debounce';
import { Skeleton } from '@material-ui/lab';
import { MenuComponent } from 'components/menu';
import { useBizDetailsModal } from 'hooks/bizDetailsModal';
import { Input } from 'components/input';
import { TableComponent } from 'components/table';
import { TooltipComponent } from 'components/tooltip';
import LoggedInDashboard from 'components/logged-in-dashboard';
import { Status } from 'styles/utils';
import { TableContainer, TableContent } from 'styles/table';
import { FilterContainer } from 'pages/follow-up/follow-up.style';
import { getNewAccounts } from 'services/business';
import BusinessDetails from 'containers/business-details';
import { NewBusinessFilter } from './filter-new-accounts';

const initialFilter = {
   date_after: moment().subtract(30, 'days').format('YYYY-MM-DD'),
   date_before: moment().format('YYYY-MM-DD'),
};

export const NewAccountsDashboard = () => {
   const { setActiveTab } = useBizDetailsModal();
   const { search } = useLocation();
   const page = new URLSearchParams(search).get('page');
   const [searchTerm, setSearchTerm] = useState('');
   const [modalVisibility, setModalVisibility] = useState(false);
   const [filterData, setFilterData] = useState(initialFilter);
   const [filterPayload, setFilterPayload] = useState({});
   const [tableData, setTableData] = useState([]);
   const [loading, setLoading] = useState(false);
   const [currentPage, setCurrentPage] = useState(page || 1);
   const [paginationData, setPaginationData] = useState({});
   const [noCloseIcon, setNoCloseIcon] = useState(false);
   const [bizAccount, setBizAccount] = useState('');
   const [modalVisibilityFilter, setModalVisibilityFilter] = useState(false);

   const [anchorEl, setAnchorEl] = useState(null);
   const open = Boolean(anchorEl);

   const options = ['View Details', 'Call', 'Send Message'];

   const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
   };

   const handleClose = () => {
      setAnchorEl(null);
   };

   function handleSearch({ target }) {
      setSearchTerm(target.value);
   }

   const debounce = useDebouncedCallback(handleSearch, 600);

   function handleOpenModal() {
      setActiveTab('overview');
      setModalVisibility(true);
      setNoCloseIcon(false);
   }

   function callNumber(phone) {
      const link = document.createElement('a');
      const linkText = document.createTextNode('call us');
      link.appendChild(linkText);
      link.href = `tel:${phone}`;
      link.click();
   }

   function sendMessageModalSection() {
      setActiveTab('message');
      setModalVisibility(true);
   }

   function onRowClick({ row }) {
      setBizAccount(row.hbiz_qualifying_questions?.hbizaccount);
   }

   function onPageChange(e, pageNumber) {
      setCurrentPage(pageNumber);
   }

   const fetchNewAccounts = async () => {
      setLoading(true);
      try {
         const res = await getNewAccounts({
            page: currentPage,
            search: searchTerm,
            ...filterPayload,
         });
         setTableData(res.data);
         setPaginationData(res);
      } catch (err) {
         toast.error(err?.message);
      }
      setLoading(false);
   };

   useEffect(() => {
      fetchNewAccounts();
   }, [currentPage, searchTerm, filterPayload]);

   const columns = [
      {
         field: '',
         width: 0,
         renderCell: ({ row }) => (
            <div>
               <IconButton
                  aria-label="more"
                  aria-controls="long-menu"
                  aria-haspopup="true"
                  onClick={handleClick}
               >
                  <MoreHoriz />
               </IconButton>
               <MenuComponent
                  anchorEl={anchorEl}
                  handleClose={handleClose}
                  open={open}
                  options={options}
                  optionsFns={[
                     // place the callbacks in position corresponding to the options index
                     () => handleOpenModal(row.hbiz_qualifying_questions?.hbizaccount),
                     () => callNumber(row?.account_holder_details?.phone),
                     () => sendMessageModalSection(row.hbiz_qualifying_questions?.hbizaccount),
                  ]}
               />
            </div>
         ),
      },
      {
         field: 'biz_name',
         headerName: 'Business Name',
         renderCell: ({ row }) => (
            <TooltipComponent title={row.biz_name}>
               <span
                  style={{
                     cursor: 'pointer',
                  }}
                  className="text-primary"
                  onClick={() => handleOpenModal(row.hbiz_qualifying_questions?.hbizaccount)}
               >
                  {row.biz_name || 'N/A'}
               </span>
            </TooltipComponent>
         ),
      },
      {
         field: 'owner',
         headerName: 'Owner',
         renderCell: ({ row }) => (
            <span style={{ textTransform: 'capitalize' }}>
               {row?.account_holder_details?.first_name.toLowerCase()}{' '}
               {row?.account_holder_details?.last_name.toLowerCase()}
            </span>
         ),
      },
      {
         field: 'monthly_turnover',
         headerName: 'Monthly Turnover',
         renderCell: ({ row }) => (
            <span style={{ textTransform: 'capitalize' }}>
               {row?.hbiz_qualifying_questions?.monthly_turnover.replace('_', ' ') || 'N/A'}
            </span>
         ),
      },
      {
         field: 'biz_state',
         headerName: 'State',
         renderCell: ({ row }) => (
            <span style={{ textTransform: 'capitalize' }}>{row?.biz_state || 'N/A'}</span>
         ),
      },
      {
         field: 'biz_city',
         headerName: 'City',
         renderCell: ({ row }) => (
            <span style={{ textTransform: 'capitalize' }}>{row?.biz_city || 'N/A'}</span>
         ),
      },
      {
         field: 'sector',
         headerName: 'Sector',
         renderCell: ({ row }) => (
            <span style={{ textTransform: 'capitalize' }}>
               {row?.hbiz_qualifying_questions?.sector || 'N/A'}
            </span>
         ),
      },
      {
         field: 'assigned_manager_details',
         headerName: 'Manager',
         renderCell: ({ row }) => (
            <>
               {!row.assigned_manager_details ? (
                  'N/A'
               ) : (
                  <span>
                     {row.assigned_manager_details.first_name}
                     {row.assigned_manager_details.first_name}
                  </span>
               )}
            </>
         ),
      },
      {
         field: 'pub_date',
         headerName: 'Date Created',
         renderCell: ({ row }) => (
            <span>
               {moment(row?.pub_date).format('MMM DD, YYYY')} |{' '}
               {moment(row?.pub_date).format('h:mm a')}
            </span>
         ),
      },
      {
         field: 'phone_verification_status',
         headerName: 'Phone Number',
         renderCell: ({ row }) => (
            <Status
               status={
                  row?.account_holder_details?.phone_verification_status === 'accepted'
                     ? 'accepted'
                     : 'rejected'
               }
            >
               {row?.account_holder_details?.phone_verification_status === 'accepted'
                  ? 'Verified'
                  : 'Unverified'}
            </Status>
         ),
      },
      {
         field: 'call_request',
         headerName: 'Call Request',
         renderCell: ({ row }) => (
            <span>
               {row?.account_holder_details?.phone_verification_status === 'call_requested'
                  ? 'Yes'
                  : 'No'}
            </span>
         ),
      },
   ];
   return (
      <>
         <LoggedInDashboard pageTitle="">
            <TableContainer id="table">
               <TableContent>
                  {loading ? (
                     <Skeleton width="30%" height="20" />
                  ) : (
                     <div>
                        <h1> New Accounts</h1>
                        <span className="value">{paginationData?.count || 'N/A'}</span>
                     </div>
                  )}
                  <div className="d-flex justify-content-between">
                     <div className="me-3">
                        <Input
                           style={{ width: '300px' }}
                           placeholder="Search"
                           onChange={debounce}
                        />
                     </div>

                     <div className="d-flex align-items-center">
                        <FilterContainer className="me-3">
                           <>
                              {'Export Data'}
                              {''} <ImportExportIcon style={{ marginLeft: '10px' }} />
                           </>
                        </FilterContainer>
                        <FilterContainer onClick={() => setModalVisibilityFilter(true)}>
                           Filter By <FilterListIcon style={{ marginLeft: '10px' }} />
                        </FilterContainer>
                     </div>
                  </div>
               </TableContent>
               <TableComponent
                  columns={columns}
                  rows={tableData}
                  isLoading={loading}
                  onPageChange={onPageChange}
                  count={Math.round(paginationData.count / 15)}
                  page={currentPage}
                  pageSize={15}
                  onRowClick={onRowClick}
                  highlight
               />
               {modalVisibility && (
                  <BusinessDetails
                     businessAccountId={bizAccount}
                     open={modalVisibility}
                     onClose={() => setModalVisibility(false)}
                     noCloseIcon={noCloseIcon}
                  />
               )}
               {modalVisibilityFilter && (
                  <NewBusinessFilter
                     open={modalVisibilityFilter}
                     onClose={() => setModalVisibilityFilter(false)}
                     filterData={filterData}
                     setFilterData={setFilterData}
                     setFilterPayload={setFilterPayload}
                  />
               )}
            </TableContainer>
         </LoggedInDashboard>
      </>
   );
};
