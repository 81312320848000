import { getLoggedinManagerPermissions } from 'services/admin';
import {
   prospasuperadmin,
   prospabuildpartner,
   prospabizpartner,
   loan_admin,
   loan_officer,
   prospa_auditor,
   prospacsr,
   prospaapprover,
} from 'utils/user_types';

export const pageAccess = {
   [prospasuperadmin]: [],
   [loan_admin]: [
      '/approver',
      '/follow-up',
      '/activation',
      '/business-accounts',
      '/pre-approved-accounts',
      '/requires-tin',
      '/transactions',
      '/nibss-transactions',
      '/business-registrations',
      '/cac-business-search',
      '/loans',
      '/pos',
   ],
   [prospacsr]: [
      '/approver',
      '/follow-up',
      '/business-accounts',
      '/pre-approved-accounts',
      '/requires-tin',
      '/cac-business-search',
   ],
   [prospaapprover]: [
      '/approver',
      '/follow-up',
      '/business-accounts',
      '/pre-approved-accounts',
      '/requires-tin',
      '/cac-business-search',
   ],
   [prospabuildpartner]: ['/build-partner'],
   [prospabizpartner]: ['/activation', '/cac-business-search'],
   [loan_officer]: ['/loans'],
   [prospa_auditor]: ['/transactions', '/nibss-transactions'],
};

export const searchBarAccess = [
   prospacsr,
   prospaapprover,
   prospasuperadmin,
   loan_admin,
   prospa_auditor,
];

export const initializeUserPermissions = async (token) => {
   try {
      const response = await getLoggedinManagerPermissions({
         headers: {
            Authorization: `Token ${token}`,
         },
      });

      const access = {
         ...(response.permissions.filter((permission) => permission.action === 'manage_access')
            ?.length > 0
            ? {
                 permissions: 'all',
                 pageAccess: 'all',
              }
            : {
                 permissions: response.permissions
                    .filter((item) => item.category !== 'page_access')
                    .map((item) => item.action),
                 pageAccess: response.permissions
                    .filter((item) => item.category === 'page_access')
                    .map((item) => `/${item.action.slice(0, item.action.length - 4)}`),
              }),
      };

      return new Promise((resolve) => {
         return resolve(access);
      });
   } catch (e) {
      return new Promise((reject) => {
         return reject(e);
      });
   }
};

export const hasPermission = (permissions, action) => {
   if (permissions) {
      if (permissions === 'all') return true;
      if (Array.isArray(action)) return action?.some((item) => permissions?.includes(item));
      return permissions?.includes(action);
   }

   return false;
};
