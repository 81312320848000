import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import LoggedInDashboard from 'components/logged-in-dashboard';
import TabMenu from 'components/TabMenu';
import { TabWrapper } from 'pages/pending-documents/style';
import { useAuth } from 'hooks/auth';
import { hasPermission } from 'utils/user_access';
import Transactions from './features/transactions';
import DataExports from './features/data-exports';
import CrossReference from './features/cross-reference';
import NibssDebit from './features/nibss-debit';

function NibssTransaction() {
   const history = useHistory();
   const { pathname } = useLocation();
   const { currentUser } = useAuth();
   const { permissions } = currentUser?.access || {};
   const [activeTab, setActiveTab] = useState('transactions');

   const handleActiveTab = (value) => {
      if (value !== activeTab) {
         history.push(pathname);
      }
      setActiveTab(value);
   };

   const tabs = [
      {
         label: 'Transactions',
         value: 'transactions',
         permission: true,
      },
      {
         label: 'Data Exports',
         value: 'exports',
         permission: hasPermission(permissions, 'export_nibss_transactions'),
      },
      {
         label: 'Cross Reference',
         value: 'cross-reference',
         permission: hasPermission(permissions, 'export_nibss_transactions'),
      },
      {
         label: 'Debit Transactions',
         value: 'nibss-debit',
         permission: hasPermission(permissions, 'can_debit_nibss_member_wallet'),
      },
   ];

   return (
      <LoggedInDashboard pageTitle="NIBSS Transactions">
         <TabWrapper>
            <ul className="m-0 d-flex align-items-center">
               {tabs?.map(({ label, value, permission }) => {
                  if (permission) {
                     return (
                        <TabMenu
                           key={value}
                           text={label}
                           active={activeTab === value ? 'active' : ''}
                           setActiveTab={() => {
                              handleActiveTab(value);
                           }}
                        />
                     );
                  }
                  return null;
               })}
            </ul>
         </TabWrapper>
         {activeTab === 'transactions' && <Transactions />}
         {activeTab === 'exports' && <DataExports />}
         {activeTab === 'cross-reference' && <CrossReference />}
         {activeTab === 'nibss-debit' && <NibssDebit />}
      </LoggedInDashboard>
   );
}

export default NibssTransaction;
