/* eslint-disable react/forbid-prop-types */
import { numberWithCommas } from 'utils/others';
import { Status } from 'styles/utils';
import { Spinner } from 'components/spinner';
import PosIcon from 'assets/images/pos_device.png';
import { array } from 'prop-types';
import { BankAccountWrapper } from 'styles/layout';
// import './style';
export default function LoanOverview({ terminals }) {
   return terminals ? (
      <div>
         <div className="row">
            {terminals.map((item) => (
               <div className="col-md-6 mb-5" key={item.assigned_by_id}>
                  <BankAccountWrapper>
                     <div className="d-flex justify-content-between">
                        <div className="d-flex align-items-center mb-4">
                           <img src={PosIcon} alt="" className="wallet-icon" />
                           <div className="">
                              <h3 className="ms-4 me-3 mb-0 account-type">
                                 {item?.terminal_name || 'N/A'}
                                 <p
                                    className="font-weight-normal"
                                    style={{ fontSize: '12px', fontWeight: '400' }}
                                 >
                                    Pos ID: {item?.biz_pos_id || 'N/A'}
                                 </p>
                              </h3>
                           </div>
                        </div>
                        <div>
                           <Status status={item.terminal_status}>{item.terminal_status}</Status>
                        </div>
                     </div>

                     <div>
                        <div className="account_number d-flex mb-2">
                           <h3 className="mb-0">Serial No: </h3> {'    '}
                           {item.serial_number ? (
                              <span className="ms-2"> {item.serial_number}</span>
                           ) : (
                              <span className="ms-2 text-danger"> N/A</span>
                           )}
                        </div>
                        <div className="account_number d-flex mb-2">
                           <h3 className="mb-2">
                              Terminal Limit:{' '}
                              <span> ₦{numberWithCommas(item.transaction_limit)} </span>
                           </h3>
                        </div>
                     </div>
                  </BankAccountWrapper>
               </div>
            ))}
         </div>

         {terminals.length < 1 && <h3>Business Doesn&apos;t have a terminal</h3>}
      </div>
   ) : (
      <Spinner />
   );
}

LoanOverview.propTypes = {
   terminals: array.isRequired,
};
