import { Http } from 'services/http';
import { logActivity } from 'services/activity';
import axiosCustom from 'utils/axios';
import { paramsObjectToQueryString } from 'utils/others';
import {
   can_export_all_card_requests,
   can_view_card_stats,
   can_create_card_designs,
   can_view_card_transactions,
   can_create_cards,
   can_operate_settings,
} from 'utils/user_actions';

export const getCards = (page) =>
   Http(`/backmen/cards${paramsObjectToQueryString(page)}`, {
      action: can_create_cards,
   });

export const exportCardsData = async (params, payload) => {
   try {
      const response = await Http(
         `/backmen/card_request_exports${paramsObjectToQueryString(params)}`,
         {
            method: 'POST',
            body: payload,
            action: can_export_all_card_requests,
         },
      );
      await logActivity(`Cards data export done`);
      return response;
   } catch (e) {
      throw new Error(e.message);
   }
};

export const getCardLimit = (bizId) => Http(`/backmen/card_business_limits/${bizId}`);

export const getSingleCard = (cardId) => Http(`/backmen/cards/${cardId}`);

export const getCardStats = () =>
   Http(`/backmen/card_stats`, {
      action: can_view_card_stats,
   });

export const editCardDetails = async (payload, cardId) => {
   try {
      const response = await Http(`/backmen/cards/${cardId}`, {
         method: 'PATCH',
         body: payload,
      });
      await logActivity(`Card Details Updated`);
      return response;
   } catch (e) {
      throw new Error(e.message);
   }
};

export const getCardDesigns = () => {
   return Http(`/backmen/card_designs`);
};

export const createCardDesign = async (payload) => {
   try {
      const response = await axiosCustom().post(`/backmen/card_designs`, payload, {
         action: can_create_card_designs,
      });
      return response;
   } catch (e) {
      throw new Error(e.response?.data?.message || e.message);
   }
};

export const updateCardDesign = (id, payload) => {
   return Http(`/backmen/card_designs/${id}`, {
      method: 'PATCH',
      body: payload,
      action: can_create_card_designs,
   });
};

export const deleteCardDesign = (id) => {
   return Http(`/backmen/card_designs/${id}`, {
      method: 'DELETE',
      action: can_create_card_designs,
   });
};

export const getAllCardTransactions = (page) =>
   Http(`/backmen/card_transactions${paramsObjectToQueryString(page)}`, {
      action: can_view_card_transactions,
   });

export const getSingleCardTransactions = (cardId) => {
   return Http(`/backmen/card_transactions${paramsObjectToQueryString(cardId)}`, {
      action: can_view_card_transactions,
   });
};

export const getCardSettings = () => {
   return Http(`/settings/settings/`, {
      action: can_operate_settings,
   });
};

export const updateCardSettings = async (id, payload) => {
   try {
      const response = await Http(`/settings/settings/${id}/`, {
         method: 'PUT',
         body: payload,
         action: can_operate_settings,
      });
      await logActivity(`updated settings`);
      return response;
   } catch (e) {
      throw new Error(e.response?.data?.message || e.message);
   }
};

export const getCardBusinessSettings = (bizId) => {
   return Http(`/backmen/card_settings${paramsObjectToQueryString(bizId)}`);
};

export const createCardBusinessSettings = async (payload) => {
   try {
      const response = await Http(`/backmen/card_settings`, {
         method: 'POST',
         body: payload,
      });
      return response;
   } catch (e) {
      throw new Error(e.message);
   }
};

export const updateCardBusinessSettings = async (id, payload) => {
   try {
      const response = await Http(`/backmen/card_settings/${id}`, {
         method: 'PATCH',
         body: payload,
      });
      return response;
   } catch (e) {
      throw new Error(e.message);
   }
};
