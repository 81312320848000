/* eslint-disable react/prop-types */
import { useState } from 'react';
import moment from 'moment';
import toast from 'react-hot-toast';
import { func, bool } from 'prop-types';
import { Input } from 'components/input';
import Modal from 'components/modal';
import { InputWrapper } from 'styles/form';
import { Button } from 'components/button';
import { exportSavingsData } from 'services/savings';

const SavingsExportDataModal = ({ open, onClose }) => {
   const [isLoading, setIsLoading] = useState(false);
   const [exportState, setExport] = useState({
      start_date: moment().format('2019-01-01'),
      end_date: moment().format('YYYY-MM-DD'),
      allowIndividualExport: false,
   });

   async function onSubmit() {
      setIsLoading(true);
      try {
         const { start_date, end_date, allowIndividualExport, business_id } = exportState;
         const params = {
            url_now: 'True',
            start_date,
            end_date,
            ...(allowIndividualExport && { account: business_id }),
         };
         const result = await exportSavingsData(params);
         toast.success(result?.message || 'Export is currently available for download ');
         window.open(result?.file_url);
      } catch (err) {
         toast.error(err.message || err.custom_message);
      }
      setIsLoading(false);
   }

   return (
      <Modal open={open} onClose={onClose} title="Filters" center width="750px">
         <form>
            <InputWrapper className="grid-2-2">
               <Input
                  placeholder="Start Date"
                  label="Start Date"
                  name="start_date"
                  type="date"
                  value={exportState?.start_date}
                  onChange={(e) => {
                     setExport({ ...exportState, start_date: e.target.value });
                  }}
               />
               <Input
                  placeholder="End Date"
                  label="End Date"
                  name="end_date"
                  type="date"
                  value={exportState?.end_date}
                  onChange={(e) => {
                     setExport({ ...exportState, end_date: e.target.value });
                  }}
               />
            </InputWrapper>
            <InputWrapper className="grid-2-2">
               <label htmlFor="for-date-range">
                  <input
                     id="for-date-range"
                     type="checkbox"
                     name="for-date-range"
                     checked={exportState.allowIndividualExport}
                     style={{ cursor: 'pointer' }}
                     onChange={() =>
                        setExport({
                           ...exportState,
                           allowIndividualExport: !exportState.allowIndividualExport,
                        })
                     }
                  />
                  <span className="ms-3">Export for specific Business</span>
               </label>
            </InputWrapper>

            {exportState.allowIndividualExport && (
               <InputWrapper className="grid-2-2">
                  <div>
                     <Input
                        label="Enter Business Id"
                        name="business_id"
                        className={Input}
                        onChange={(e) => {
                           setExport({ ...exportState, business_id: e.target.value });
                        }}
                     />
                  </div>
               </InputWrapper>
            )}

            <InputWrapper
               style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
               }}
            >
               <div>
                  <Button
                     variant="primary"
                     type="button"
                     onClick={onSubmit}
                     isLoading={isLoading}
                     disabled={isLoading}
                     fullWidth
                  >
                     Export
                  </Button>
               </div>
            </InputWrapper>
         </form>
      </Modal>
   );
};

export default SavingsExportDataModal;

SavingsExportDataModal.propTypes = {
   open: bool.isRequired,
   onClose: func.isRequired,
};
