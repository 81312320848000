import { useEffect, useState } from 'react';
import moment from 'moment';
import { useDebouncedCallback } from 'use-debounce';

import { Input } from 'components/input';
import { Skeleton } from '@material-ui/lab';
import FilterListIcon from '@material-ui/icons/FilterList';
import { TableContainer, TableContent } from 'styles/table';
import { numberWithCommas } from 'utils/others';
import { renderTransactionStatus } from 'utils/business';
import { formatTag } from 'utils/tags';
import { TooltipComponent } from 'components/tooltip';
import { TableComponent } from 'components/table';
import TransactionModal from 'components/TransactionModal';
import { getAllCardTransactions } from 'services/cards';
import { FilterContainer } from 'pages/follow-up/follow-up.style';
import FilterCardTransactionModal from '../filter-transaction/filter-card-transactions';

const initialFilter = {
   start_date: moment().format('YYYY-MM-01'),
   end_date: moment().format('YYYY-MM-DD'),
};

function AllCardTransactions() {
   const [tableData, setTableData] = useState([]);
   const [isLoading, setIsLoading] = useState(false);
   const [paginationData, setPaginationData] = useState({});
   const [searchTerm, setSearchTerm] = useState('');
   const [currentPage, setCurrentPage] = useState(1);
   const [transactionDetail, setTransactionDetail] = useState('');
   const [transactionModal, setTransactionModal] = useState(false);
   const [modalVisibilityFilter, setModalVisibilityFilter] = useState(false);
   const [filterData, setFilterData] = useState(initialFilter);
   const [filterPayload, setFilterPayload] = useState({});

   const onTransactionClick = (data) => {
      setTransactionModal(true);
      setTransactionDetail(data);
   };

   const columns = [
      {
         field: 'transaction_id',
         headerName: 'Transaction ID',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.transaction_id}>
               <span
                  style={{ cursor: 'pointer' }}
                  className="text-primary"
                  onClick={() => onTransactionClick(row)}
               >
                  {row?.transaction_id}
               </span>
            </TooltipComponent>
         ),
      },
      {
         field: 'trans_amount',
         headerName: 'Amount',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.trans_amount}>
               ₦{numberWithCommas(row?.trans_amount)}
            </TooltipComponent>
         ),
      },
      {
         field: 'tx_action',
         headerName: 'Type',
         renderCell: ({ row }) => {
            let template = '';
            if (row?.tx_action.includes('credit')) {
               template = formatTag('accepted', 'Credit');
            } else if (row?.tx_action.includes('debit')) {
               template = formatTag('rejected', 'Debit');
            } else {
               template = formatTag(row?.tx_action, row?.tx_action);
            }
            return <TooltipComponent title={row?.tx_action}>{template}</TooltipComponent>;
         },
      },
      {
         field: 'account_number',
         headerName: 'Account',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.account_number || 'N/A'}>
               {row?.account_number || 'N/A'}
            </TooltipComponent>
         ),
      },
      {
         field: 'transfer_details?.transfer_status',
         headerName: 'Status',
         renderCell: ({ row }) => renderTransactionStatus(row),
      },
      {
         field: 'pub_date',
         headerName: 'Date',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.pub_date}>
               {moment(row?.pub_date).format('DD MMM, YYYY')} |{' '}
               <span style={{ opacity: '.5' }}>{moment(row?.pub_date).format('HH:mm A')}</span>
            </TooltipComponent>
         ),
      },
   ];

   const fetchTransactions = async (pageNumber) => {
      setIsLoading(true);
      Promise.all([
         getAllCardTransactions({
            page: pageNumber || currentPage,
            account_name: searchTerm,
            ...filterPayload,
         }),
      ])
         .then(([businessAccountsData]) => {
            setTableData(businessAccountsData?.results);
            setPaginationData(businessAccountsData);
         })
         .finally(() => {
            setIsLoading(false);
         });
   };

   useEffect(() => {
      fetchTransactions();
   }, [currentPage, filterPayload, searchTerm]);

   function onPageChange(e, pageNumber) {
      setCurrentPage(pageNumber);
   }

   function handleSearch({ target }) {
      setSearchTerm(target.value);
   }
   const debounce = useDebouncedCallback(handleSearch, 600);

   return (
      <>
         <TableContainer>
            <TableContent>
               {isLoading ? (
                  <Skeleton width="30%" height="20" />
               ) : (
                  <div>
                     <h1> All Transactions</h1>
                     <span className="value">{paginationData?.count || 'N/A'}</span>
                  </div>
               )}
               <div className="d-flex align-items-center">
                  <div className="me-3">
                     <Input
                        style={{ width: '300px' }}
                        placeholder="Search for Business name"
                        onChange={debounce}
                     />
                  </div>
                  <FilterContainer onClick={() => setModalVisibilityFilter(true)}>
                     Filter By <FilterListIcon style={{ marginLeft: '10px' }} />
                  </FilterContainer>
               </div>
            </TableContent>
            <TableComponent
               columns={columns}
               rows={tableData}
               isLoading={isLoading}
               onPageChange={onPageChange}
               count={Math.ceil(paginationData.count / 15)}
            />
         </TableContainer>
         <TransactionModal
            open={transactionModal}
            onClose={() => setTransactionModal(false)}
            data={transactionDetail}
         />
         {modalVisibilityFilter && (
            <FilterCardTransactionModal
               open={modalVisibilityFilter}
               onClose={() => setModalVisibilityFilter(false)}
               setCurrentPage={setCurrentPage}
               filterData={filterData}
               setFilterData={setFilterData}
               setFilterPayload={setFilterPayload}
            />
         )}
      </>
   );
}

export default AllCardTransactions;
