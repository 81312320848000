import { useEffect, useState } from 'react';
import moment from 'moment';
import Modal from 'components/modal';
import PropTypes from 'prop-types';
import BackArrowIcon from 'assets/images/back-arrow.svg';
import { listSavingsTransactions, getSingleSafe } from 'services/savings';
import { getAllFixedDepositsTransactions, getFixedDepositsAccount } from 'services/fixed-deposits';
import { Label, Value } from 'styles/layout';
import { Spinner } from 'components/spinner';
import { TableComponent } from 'components/table';
import { TableContainer, TableContent } from 'styles/table';
import { numberWithCommas } from 'utils/others';
import { TooltipComponent } from 'components/tooltip';
import { AlertMessage } from 'components/message';
import { OverviewWrapper } from './overview.style';

const Overview = ({ open, onClose, businessAccountId, onBack, modalVisibility }) => {
   const [businessTransactions, setBusinessTransactions] = useState([]);
   const [paginationData, setPaginationData] = useState({});
   const [currentPage, setCurrentPage] = useState(1);
   const [safeDetails, setSafeDetails] = useState({});
   const [fixedDetails, setFixedDetails] = useState({});
   const [loading, setLoading] = useState(true);
   const [error, setError] = useState('');

   const typeCheck = modalVisibility?.type;

   const fetchBusinessDetails = async () => {
      const params = {
         businessAccountId,
         page: currentPage,
      };
      try {
         const transaction = await listSavingsTransactions(params);

         const res = await getSingleSafe(businessAccountId);
         setSafeDetails(res);

         if (transaction.ok) {
            setBusinessTransactions(transaction.results);
            setPaginationData(transaction);
            setError('');
         }
         setLoading(false);
      } catch (err) {
         setError(err.message || err.custom_message);
         setLoading(false);
      }
   };

   const fetchFixedTransactions = async () => {
      const params = {
         businessAccountId,
         page: currentPage,
      };
      try {
         const transaction = await getAllFixedDepositsTransactions(params);

         const res = await getFixedDepositsAccount({ biz_account_id: businessAccountId });
         setFixedDetails(res.results[0]);

         if (transaction.ok) {
            setBusinessTransactions(transaction.results);
            setPaginationData(transaction);
            setError('');
         }
         setLoading(false);
      } catch (err) {
         setError(err.message || err.custom_message);
         setLoading(false);
      }
   };

   useEffect(() => {
      if (typeCheck === 'fixed-transactions') {
         fetchFixedTransactions();
      } else {
         fetchBusinessDetails();
      }
   }, [currentPage]);

   function onPageChange(e, pageNumber) {
      setCurrentPage(pageNumber);
   }

   const columns = [
      {
         field: 'amount',
         headerName: 'Amount',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.amount}>
               ₦{numberWithCommas(row?.amount)}
            </TooltipComponent>
         ),
      },
      {
         field: 'transaction_type',
         headerName: 'Transaction Type',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.transaction_type}>
               {row?.transaction_type}
            </TooltipComponent>
         ),
      },
      {
         field: 'created',
         headerName: 'Date Created',
         renderCell: ({ row }) => (
            <TooltipComponent title={row.created}>
               {moment(row.created).format('DD MMM, YYYY')} |{' '}
               <span style={{ opacity: '.5' }}>{moment(row.created).format('HH:mm A')}</span>
            </TooltipComponent>
         ),
      },
   ];

   return (
      <Modal
         open={open}
         onClose={onClose}
         center
         noCloseIcon
         maxWidth="1000px"
         modalStyle={{ minHeight: '70%' }}
      >
         <OverviewWrapper>
            <AlertMessage message={error} variant="error" />

            {loading ? (
               <Spinner />
            ) : (
               <div>
                  <div className="row mt-5 pt-5 savings-info-wrapper mx-1 pb-5 mb-5">
                     <div className="col-lg-6">
                        <div className="savings-info-group">
                           {typeCheck === 'fixed-transactions' && (
                              <>
                                 <Label className="title">Total Fixed balance</Label>
                                 <Value className="value">
                                    {`₦ ${parseFloat(
                                       fixedDetails?.lock_amount || 0,
                                    ).toLocaleString()}` || 'N/A'}
                                 </Value>
                              </>
                           )}
                           {typeCheck === 'transactions' && (
                              <>
                                 <Label className="title">Total Savings balance</Label>
                                 <Value className="value">
                                    {`₦ ${numberWithCommas(safeDetails?.total_balance || 0)}`}
                                 </Value>
                              </>
                           )}
                        </div>
                     </div>
                     <div className="col-lg-6  d-flex justify-content-end">
                        <div className="savings-info-group">
                           <Label className="title">Total interest earned</Label>
                           {typeCheck === 'fixed-transactions' && (
                              <Value className="value">
                                 ₦ {numberWithCommas(fixedDetails?.lifetime_interest || 0)}
                              </Value>
                           )}
                           {typeCheck === 'transactions' && (
                              <Value className="value">
                                 ₦ {numberWithCommas(safeDetails?.interest_earned || 0)}
                              </Value>
                           )}
                        </div>
                     </div>
                  </div>

                  <TableContainer>
                     <TableContent>
                        <div className="d-flex">
                           <img
                              src={BackArrowIcon}
                              alt="back"
                              onClick={onBack}
                              style={{ cursor: 'pointer', marginRight: '10px' }}
                           />
                           <div>
                              <h1> All Transactions</h1>
                              <span className="value"> {paginationData?.count}</span>
                           </div>
                        </div>
                     </TableContent>
                     <TableComponent
                        columns={columns}
                        rows={businessTransactions}
                        isLoading={loading}
                        onPageChange={onPageChange}
                        count={Math.ceil(paginationData.count / 15)}
                     />
                  </TableContainer>
               </div>
            )}
         </OverviewWrapper>
      </Modal>
   );
};

Overview.propTypes = {
   open: PropTypes.bool.isRequired,
   onClose: PropTypes.func.isRequired,
   businessAccountId: PropTypes.number.isRequired,
   onBack: PropTypes.func.isRequired,
   modalVisibility: PropTypes.string.isRequired,
};

export default Overview;
