import React, { useState, useEffect } from 'react';
import { arrayOf, func, string } from 'prop-types';
import Checkbox from 'components/checkbox';
import { Button } from 'components/button';
import { MultiSelectDropdownWrapper } from './style';

export default function MultiSelectDropdown({
   value,
   onSetValues,
   options,
   onChange,
   trigger,
   placeholder,
   label,
}) {
   const [state, setState] = useState([]);
   const [dropdownFilter, setDropdownFilter] = useState(false);

   useEffect(() => {
      setState(value);
   }, [value]);

   const displayValues = () => {
      let payload = '';
      const hasAll = state?.find((item) => item.value === null);

      if (hasAll) return 'All';

      const length = hasAll ? state.length - 1 : state.length;

      state
         ?.filter((item) => item.value !== null)
         .forEach((item, index) => {
            if (item.value) {
               payload += index + 1 === length ? `${item.label}` : `${item.label},`;
            }
         });

      return payload;
   };

   const handleFilterChange = (val) => {
      const exist = state?.find((item) => item.value === val.value);

      const setter = (result) => {
         setState(result);
         if (onSetValues && typeof onSetValues === 'function') {
            onSetValues(result);
         }
      };

      if (val.label === 'All') {
         if (exist) {
            return setter([]);
         }
         return setter(options);
      }

      if (exist) {
         setter(state?.filter((item) => item.value !== val.value));
      } else {
         setter([...state, val]);
      }
   };

   const onOpen = () => setDropdownFilter(!dropdownFilter);

   return (
      <MultiSelectDropdownWrapper>
         {trigger ? (
            <div onClick={onOpen}>{trigger}</div>
         ) : (
            <div>
               <label className="multi-select-label" htmlFor="multi-select">
                  {label}
               </label>
               <div
                  className="trigger-container d-flex align-items-center justify-content-between"
                  onClick={onOpen}
                  id="multi-select"
               >
                  <div className="values">
                     {state && state.length ? displayValues() : placeholder}
                  </div>
                  <svg
                     width="20"
                     height="10"
                     viewBox="0 0 20 10"
                     fill="none"
                     xmlns="http://www.w3.org/2000/svg"
                  >
                     <path
                        d="M1 1L9 9L17 1"
                        stroke="hsl(0, 0%, 80%)"
                        strokeWidth="4"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                     />
                  </svg>
               </div>
            </div>
         )}

         {dropdownFilter && (
            <div className="mulitselect-dropdown-options">
               {options?.map((option) => (
                  <div key={option.value} className="mb-2">
                     <Checkbox
                        label={option.label}
                        checked={!!state?.find((item) => item.label === option.label)}
                        onChange={() => handleFilterChange(option)}
                     />
                  </div>
               ))}

               <div className="d-flex justify-content-end mt-4">
                  <Button
                     size="small"
                     onClick={async () => {
                        await onChange(state);
                        setDropdownFilter(false);
                     }}
                  >
                     Done
                  </Button>
               </div>
            </div>
         )}
      </MultiSelectDropdownWrapper>
   );
}

MultiSelectDropdown.propTypes = {
   value: arrayOf(string).isRequired,
   options: arrayOf(string).isRequired,
   onChange: func.isRequired,
   onSetValues: func,
   trigger: string,
   placeholder: string,
   label: string,
};

MultiSelectDropdown.defaultProps = {
   trigger: null,
   onSetValues: null,
   placeholder: '',
   label: '',
};
