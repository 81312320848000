import React, { useState, useEffect } from 'react';
import { Skeleton } from '@material-ui/lab';
import toast from 'react-hot-toast';
import { numberWithCommas } from 'utils/others';
import { StatsCards } from 'components/stats-cards';
import { useAuth } from 'hooks/auth';
import { can_view_safe_stats } from 'utils/user_actions';
import { getAllFixedDeposits } from 'services/fixed-deposits';
import {
   getTotalSafesCount,
   getTotalSafeBalance,
   getTotalSafeInterestEarned,
} from 'services/savings';
import { TooltipComponent } from 'components/tooltip';
import { TableComponent } from 'components/table';
import { TableContainer, TableContent } from 'styles/table';
import BusinessDetails from 'containers/business-details';

export default function FixedDepositsTable() {
   const { currentUser } = useAuth();
   const { permissions } = currentUser?.access || {};
   const [modalVisibility, setModalVisibility] = useState(false);
   const [isLoading, setIsLoading] = useState(false);
   const [tableData, setTableData] = useState([]);
   const [paginationData, setPaginationData] = useState({});
   const [currentPage, setCurrentPage] = useState(1);
   const [bizAccount, setBizAccount] = useState('');
   const [stats, setStats] = useState({});

   useEffect(() => {
      (async () => {
         setIsLoading(true);
         const payload = {
            page: currentPage,
            status: 'on_going',
         };
         const params = { safe_type: 'fixed_deposits' };
         setTableData([]);
         try {
            Promise.all([
               getAllFixedDeposits(payload),
               getTotalSafesCount(params),
               getTotalSafeBalance(params),
               getTotalSafeInterestEarned(params),
            ]).then(([savings, safeCount, safeBalance, safeInterest]) => {
               setPaginationData(savings);
               setTableData(savings.results);

               const { active_safes } = safeCount;
               const { total_safe_balance } = safeBalance;
               const { total_interest } = safeInterest;

               setStats({
                  active_safes: numberWithCommas(active_safes),
                  total_fixed_balance: `₦${numberWithCommas(total_safe_balance)}`,
                  total_interest: `₦${numberWithCommas(total_interest)}`,
               });
            });
         } catch (e) {
            toast.error(e.message);
         }
         setIsLoading(false);
      })();
   }, [currentPage]);

   function onPageChange(e, pageNumber) {
      setCurrentPage(pageNumber);
   }

   function onRowClick({ row }) {
      setBizAccount(row);
   }

   function handleOpenModal() {
      setModalVisibility('details');
   }

   const columns = [
      {
         field: 'business_name',
         headerName: 'Business Name',
         renderCell: ({ row }) => (
            <TooltipComponent title={row.business_name}>
               <span
                  style={{
                     cursor: 'pointer',
                  }}
                  className="text-primary"
                  onClick={() => handleOpenModal(row.business_name)}
               >
                  {row.business_name || 'N/A'}
               </span>
            </TooltipComponent>
         ),
      },
      {
         field: 'lock_amount',
         headerName: 'Locked Amount',
         renderCell: ({ row }) => (
            <span style={{ textTransform: 'capitalize' }}>
               {`₦ ${parseFloat(row?.lock_amount).toLocaleString()}`}
            </span>
         ),
      },
      {
         field: 'interest_earned_for_month',
         width: 300,
         headerName: 'Interest earned for Month',
         renderCell: ({ row }) => (
            <span style={{ textTransform: 'capitalize' }}>{`₦ ${numberWithCommas(
               row.interest_earned_for_month,
            )}`}</span>
         ),
      },
      {
         field: 'lifetime_interest',
         headerName: 'Total interest earned',
         renderCell: ({ row }) => (
            <span style={{ textTransform: 'capitalize' }}>{`₦ ${numberWithCommas(
               row.lifetime_interest,
            )}`}</span>
         ),
      },
      {
         field: 'interest_rate',
         headerName: 'Interest rate',
         renderCell: ({ row }) => (
            <span style={{ textTransform: 'capitalize' }}>{`${row.interest_rate}%`}</span>
         ),
      },
   ];
   return (
      <div>
         {(permissions === 'all' || permissions?.includes(can_view_safe_stats)) && (
            <StatsCards data={stats} isLoading={isLoading} />
         )}
         <TableContainer>
            <TableContent>
               {isLoading ? (
                  <Skeleton width="30%" height="20" />
               ) : (
                  <div>
                     <h1> Fixed Accounts</h1>
                     <span className="value">{paginationData?.count || 'N/A'}</span>
                  </div>
               )}
            </TableContent>
            <TableComponent
               columns={columns}
               rows={tableData}
               isLoading={isLoading}
               onPageChange={onPageChange}
               count={Math.ceil(paginationData.count / 15)}
               onRowClick={onRowClick}
            />
         </TableContainer>

         {modalVisibility && (
            <BusinessDetails
               businessAccountId={bizAccount.biz_account_id}
               open={modalVisibility}
               onClose={() => setModalVisibility(false)}
            />
         )}
      </div>
   );
}
