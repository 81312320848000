export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const CLEAR_CURRENT_USER = 'CLEAR_CURRENT_USER';
export const RAAM_USER_ID = 85915;

export const accountStatus = [
   {
      value: 'approved',
      label: 'Approved',
   },
   {
      value: 'unapproved',
      label: 'Unapproved',
   },
   {
      value: 'pre_approved',
      label: 'Pre Approved',
   },
];

export const flags = [
   {
      label: 'OTP',
      value: 'multiple_failed_otp',
   },
   {
      label: 'PIN',
      value: 'multiple_failed_pin',
   },
   {
      label: 'LOGIN',
      value: 'multiple_failed_login',
   },
   {
      label: 'PASSWORD RESET',
      value: 'multiple_password_reset',
   },
];

export const tenure = [
   {
      value: 1,
      label: '1 Month',
   },
   {
      value: '2',
      label: '2 Months',
   },
   {
      value: '3',
      label: '3 Months',
   },
   {
      value: '4',
      label: '4 Months',
   },
   {
      value: '5',
      label: '5 Months',
   },
   {
      value: '6',
      label: '6 Months',
   },
   {
      value: '7',
      label: '7 Months',
   },
   {
      value: '8',
      label: '8 Months',
   },
   {
      value: '9',
      label: '9 Months',
   },
   {
      value: '10',
      label: '10 Months',
   },
   {
      value: '11',
      label: '11 Months',
   },
   {
      value: '12',
      label: '12 Months',
   },
];
