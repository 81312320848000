import { useState } from 'react';
import toast from 'react-hot-toast';
import { confirm } from 'components/confirm-box';
import {
   listTransactionSettings,
   updateTransactionSettings,
   getProspaTransactionLimit,
} from 'services/transactions';
import {
   getInternationalTransactionsSettings,
   globalToggleInternationalPayment,
} from 'services/international-transactions';
import { isArray } from 'underscore';

export default function useTransactionsSettings() {
   const [loading, setLoading] = useState(false);
   const [settings, setSettings] = useState([]);
   const [systemSettings, setSystemSettings] = useState([]);
   const [switching, setSwitching] = useState(false);
   const [allSettings, setAllSettings] = useState(null);
   const [whitelistedEmails, setWhitelistedEmails] = useState([]);
   const [globalSettings, setGlobalSettings] = useState({});
   const [prospaLimit, setProspaLimit] = useState({});

   const fetchGlobalPaymentSettings = async () => {
      setLoading(true);
      try {
         const res = await getInternationalTransactionsSettings();
         setGlobalSettings(res.data);
         setLoading(false);
      } catch (e) {
         toast.error(e?.message);
         setLoading(false);
      }
   };

   const fetchProspaTransferLimit = async () => {
      setLoading(true);
      try {
         const pLimit = await getProspaTransactionLimit({});
         setProspaLimit(pLimit);
      } catch (e) {
         toast.error(e?.message);
         setLoading(false);
      }
   };

   const updateGlobalPaymentToggle = (type) => {
      confirm({
         confirmText: 'Are you sure want to proceed?',
         onConfirm: async () => {
            const payload =
               type === 'on'
                  ? {
                       is_enabled: true,
                    }
                  : {
                       is_enabled: false,
                    };
            try {
               const res = await globalToggleInternationalPayment(payload);
               toast.success(res?.message || 'Successful');
               fetchGlobalPaymentSettings();
            } catch (e) {
               toast.error(e?.message);
            }
         },
      });
   };

   const getSelectedSettings = (results, keys) => {
      const all = [];
      if (keys && keys.length && isArray(keys)) {
         keys.forEach((key) => {
            const current = results.find((res) => res.name === key);
            if (current) {
               all.push(current);
            }
         });

         return all;
      }

      return results;
   };

   async function fetchSetting(keys) {
      setLoading(true);
      try {
         const { data } = await listTransactionSettings();
         const value = getSelectedSettings(data, keys);
         setSettings(value);
      } catch (e) {
         return e;
      }
      setLoading(false);
   }

   async function fetchAllSettings() {
      setLoading(true);
      try {
         const response = await listTransactionSettings();

         let transferSettingTemp = response?.data.find(
            (item) => item.name === 'BANKING__FUND_TRANSFER_SERVICE',
         );
         const complianceSettingsTemp = response?.data.find(
            (item) => item.name === 'COMPLIANCE__FLAG_IDENTICAL_TRANSACTIONS',
         );

         const limitSettingsTemp = response?.data.find(
            (item) => item.name === 'SYSTEM_TRANSACTION_LIMITS',
         );

         const otherOtpSettings = response?.data.find(
            (item) => item.name === 'OTHER__OTP_SETTINGS',
         );

         const transferChargesSettings = response?.data.find(
            (item) => item.name === 'BANKING__EXTERNAL_TRANSFER_CHARGES',
         );

         const interestDeductionSettings = response?.data.find(
            (item) => item.name === 'BANKING__INTEREST_DEDUCTION',
         );

         if (transferSettingTemp) {
            const active = Object.keys(transferSettingTemp.value)?.filter(
               (item) => transferSettingTemp.value[item],
            );
            transferSettingTemp = {
               ...transferSettingTemp,
               active: active[0],
            };
         }

         const settingList = {
            BANKING__FUND_TRANSFER_SERVICE: transferSettingTemp,
            COMPLIANCE__FLAG_IDENTICAL_TRANSACTIONS: complianceSettingsTemp,
            SYSTEM_TRANSACTION_LIMITS: limitSettingsTemp,
            OTHER__OTP_SETTINGS: otherOtpSettings,
            BANKING__EXTERNAL_TRANSFER_CHARGES: transferChargesSettings,
            BANKING__INTEREST_DEDUCTION: interestDeductionSettings,
         };
         setAllSettings(settingList);
         setSystemSettings(response.data);
         const easypay = settingList?.BANKING__FUND_TRANSFER_SERVICE.value.easypay_allowed_emails;
         setWhitelistedEmails(easypay);
      } catch (e) {
         toast.error(e?.message);
      }
      setLoading(false);
   }

   const confirmSetting = (setting) => {
      confirm({
         confirmText: 'Are you sure you want to update this setting?',
         onConfirm: async () => {
            setSwitching({
               [setting.name]: true,
            });
            try {
               const { value } = setting;
               const filteredWhitelist = whitelistedEmails.filter((el) => el !== '');
               const newWhitelistedEmails = [...new Set(filteredWhitelist)];
               value.easypay_allowed_emails = newWhitelistedEmails;
               const payload = {
                  id: setting.id,
                  value,
                  name: setting.name,
                  category: setting.category,
               };
               const response = await updateTransactionSettings(setting.id, payload);
               toast.success(response.message || 'Settings has been updated successfully');
               fetchAllSettings();
            } catch (e) {
               toast.error(e?.message);
            }
            setSwitching({
               [setting.name]: false,
            });
         },
      });
   };

   const updateSettings = (value, keys) => {
      confirm({
         confirmText: 'Are you sure you want to update this setting?',
         onConfirm: async () => {
            try {
               const response = await updateTransactionSettings(value.id, value);
               toast.success(response.message || 'Settings has been updated successfully');
               fetchSetting(keys);
            } catch (e) {
               toast.error(e?.message);
            }
         },
      });
   };

   const handleActiveTransfer = (setting, value) => {
      let newValue = {};
      if (setting.name === 'BANKING__FUND_TRANSFER_SERVICE') {
         Object.keys(setting.value).forEach((key) => {
            newValue = {
               ...newValue,
               [key]: key === value,
            };
         });
      } else if (setting.name === 'COMPLIANCE__FLAG_IDENTICAL_TRANSACTIONS') {
         newValue = { limit: value };
      }

      setAllSettings((prevSettings) => ({
         ...prevSettings,
         [setting.name]: {
            ...setting,
            value: newValue,
         },
      }));
   };

   return {
      loading,
      switching,
      allSettings,
      systemSettings,
      fetchAllSettings,
      confirmSetting,
      whitelistedEmails,
      setWhitelistedEmails,
      handleActiveTransfer,
      fetchSetting,
      settings,
      updateSettings,
      globalSettings,
      fetchGlobalPaymentSettings,
      fetchProspaTransferLimit,
      updateGlobalPaymentToggle,
      prospaLimit,
   };
}
