import React, { useEffect, useState } from 'react';
import Modal from 'components/modal';
import { toast } from 'react-hot-toast';
import { confirm } from 'components/confirm-box';
import PropTypes, { bool, func, string } from 'prop-types';
import { bulkAssignAccounts, bulkAssignCategories, getNewBizCategories } from 'services/business';
import { SelectInput } from 'components/select';
import { InputWrapper } from 'styles/form';
import useFetchManagers from 'hooks/fetchManagers';
import { prospabuildpartner } from 'utils/user_types';
import { Button } from 'components/button';

export const BulkAssignManagers = ({
   open,
   onClose,
   assignType,
   bulkAssignType,
   filterData,
   selectionModel,
   setBulkAssignActive,
   setSelectionModel,
   fetchApprovedAccounts,
}) => {
   const [loading, setLoading] = useState(false);
   const { managers } = useFetchManagers();
   const [newBizCategories, setNewBizCategories] = useState([]);
   const [buildPartner, setBuildPartner] = useState({});
   const [category, setCategory] = useState({});
   const [activationManager, setActivationManager] = useState({});

   const fetchNewCategories = async () => {
      setLoading(true);
      try {
         const res = await getNewBizCategories();
         setNewBizCategories(
            res.data.map((item) => ({
               value: item.category_id,
               label: item.name,
            })),
         );
         setLoading(false);
      } catch (e) {
         toast.error(e?.message);
         setLoading(false);
      }
   };

   useEffect(() => {
      fetchNewCategories();
   }, []);

   const handleAccountAssignment = async () => {
      setLoading(true);
      try {
         const payload = {
            prospa_manager_id: activationManager?.value,
            prospa_build_partner_id: buildPartner?.value,
            ...(assignType === 'selected-accounts' && { biz_account_ids: selectionModel }),
         };
         if (assignType === 'all-accounts') {
            const res = await bulkAssignAccounts(filterData, payload);
            toast.success(res?.message || 'Accounts have successfully assigned');
         }
         if (assignType === 'selected-accounts') {
            const res = await bulkAssignAccounts('', payload);
            toast.success(res?.message || 'Accounts have successfully assigned');
         }
         setBulkAssignActive(false);
         setSelectionModel([]);
         fetchApprovedAccounts();
      } catch (e) {
         toast.error(e?.message);
      }
      onClose();
      setLoading(false);
   };

   const handleCategoryAssignment = async () => {
      setLoading(true);
      try {
         const payload = {
            category_id: category?.value,
            ...(assignType === 'selected-accounts' && { biz_account_ids: selectionModel }),
         };
         if (assignType === 'all-accounts') {
            const res = await bulkAssignCategories(filterData, payload);
            toast.success(res?.message || 'Accounts have successfully assigned');
         }
         if (assignType === 'selected-accounts') {
            const res = await bulkAssignCategories('', payload);
            toast.success(res?.message || 'Accounts have successfully assigned');
         }
         setBulkAssignActive(false);
         setSelectionModel([]);
         fetchApprovedAccounts();
      } catch (e) {
         toast.error(e?.message);
      }
      onClose();
      setLoading(false);
   };

   const handleOnSubmit = () =>
      confirm({
         ...(assignType === 'all-accounts' && {
            confirmText:
               'Are you sure you want to assign all the accounts to these manager based on the current filter?',
         }),
         ...(assignType === 'selected-accounts' && {
            confirmText:
               'Are you sure you want to assign all the selected accounts to these manager?',
         }),
         onConfirm: handleAccountAssignment,
      });

   const handleOnSubmitCategories = () =>
      confirm({
         ...(assignType === 'all-accounts' && {
            confirmText:
               'Are you sure you want to assign all the accounts to this category based on the current filter?',
         }),
         ...(assignType === 'selected-accounts' && {
            confirmText:
               'Are you sure you want to assign all the selected accounts to this category?',
         }),
         onConfirm: handleCategoryAssignment,
      });

   return (
      <Modal open={open} onClose={onClose} title="Bulk Assign" center>
         {bulkAssignType === 'accounts' && (
            <InputWrapper className="grid-2-2">
               <SelectInput
                  onChange={(val) => setBuildPartner(val)}
                  label="Build Partner"
                  options={managers?.filter((manager) => manager.user_type === prospabuildpartner)}
                  value={managers.find((item) => item.value === buildPartner?.value)}
               />

               <SelectInput
                  onChange={(val) => setActivationManager(val)}
                  label="Activation Manager"
                  options={managers}
                  value={managers.find((item) => item.value === activationManager?.value)}
               />
            </InputWrapper>
         )}

         {bulkAssignType === 'categories' && (
            <InputWrapper className="grid-2-2">
               <SelectInput
                  onChange={(val) => setCategory(val)}
                  label="New Business Category"
                  options={newBizCategories}
                  value={newBizCategories.find((item) => item.value === category.value)}
               />
            </InputWrapper>
         )}
         <div className="d-flex justify-content-end">
            <Button
               type="button"
               isLoading={loading}
               onClick={bulkAssignType === 'accounts' ? handleOnSubmit : handleOnSubmitCategories}
            >
               {bulkAssignType === 'accounts' ? 'Assign Managers' : 'Assign Categories'}
            </Button>
         </div>
      </Modal>
   );
};

BulkAssignManagers.propTypes = {
   open: bool.isRequired,
   onClose: func.isRequired,
   assignType: string.isRequired,
   bulkAssignType: string.isRequired,
   filterData: PropTypes.objectOf().isRequired,
   selectionModel: PropTypes.arrayOf().isRequired,
   setBulkAssignActive: func.isRequired,
   setSelectionModel: func.isRequired,
   fetchApprovedAccounts: func.isRequired,
};
