import { Http } from 'services/http';
import { paramsObjectToQueryString } from 'utils/others';
import axiosCustom from 'utils/axios';
import { can_view_fixed_deposits, can_update_fixed_deposits } from 'utils/user_actions';

export const getAllFixedDeposits = (page) => {
   return Http(`/backmen/new_fixed_deposit/${paramsObjectToQueryString(page)}`, {
      action: can_view_fixed_deposits,
   });
};

export const getFixedDepositsAccount = (businessAccountId) => {
   return Http(`/backmen/new_fixed_deposit/${paramsObjectToQueryString(businessAccountId)}`, {
      action: can_view_fixed_deposits,
   });
};

export const createFixedDepositsAccount = async (payload) => {
   try {
      const response = await axiosCustom().post(`/backmen/new_fixed_deposit/`, payload);
      return response;
   } catch (e) {
      throw new Error(e?.response?.data?.message || e?.message);
   }
};
export const updateFixedDepositsAccount = (params) => {
   const { id, ...remainingParams } = params;
   return Http(`/backmen/new_fixed_deposit/${id}`, {
      method: 'PATCH',
      body: remainingParams,
      action: can_update_fixed_deposits,
   });
};

export const getAllFixedDepositsTerm = () => {
   return Http(`/backmen/fixed_deposit_interest_rate/`);
};

export const deleteFixedDepositsTerm = (fixedDepositId) => {
   Http(`/backmen/fixed_deposit_interest_rate/${fixedDepositId}`, {
      method: 'DELETE',
      action: can_update_fixed_deposits,
   });
};

export const createSavingsTerm = (payload) => {
   Http(`/backmen/fixed_deposit_interest_rate/`, {
      method: 'POST',
      body: payload,
      action: can_update_fixed_deposits,
   });
};

export const updateSavingsTerm = (params) => {
   const { id, ...remainingParams } = params;
   Http(`/backmen/fixed_deposit_interest_rate/${id}`, {
      method: 'PATCH',
      body: remainingParams,
      action: can_update_fixed_deposits,
   });
};

export const getAllFixedDepositsTransactions = (params) => {
   const { businessAccountId, ...rest } = params;
   return Http(
      `/express/savings/fixed_transactions/${businessAccountId}/${paramsObjectToQueryString(rest)}`,
   );
};
