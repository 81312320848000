import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import classNames from 'classnames';
import { Button } from 'components/button';
import { Spinner } from 'components/spinner';
import { confirm } from 'components/confirm-box';
import { NumberInput } from 'components/input';
import {
   getGlobalFees,
   getRateSpread,
   updateGlobalFees,
   updateRateSpread,
} from 'services/international-transactions';
import { UpdateFeesModal } from './features/update-fees-modal';

export const InternationalTransactionsSettings = () => {
   const [loading, setLoading] = useState(false);
   const [feesData, setFeesData] = useState({});
   const [rateSpreadData, setRateSpreadData] = useState('');
   const [selectedCurrency, setSelectedCurrency] = useState('');
   const [updateFeesModalVisibility, setUpdateFeesModalVisibility] = useState(false);

   const fetchGlobalFees = async () => {
      setLoading(true);
      try {
         const res = await getGlobalFees();
         setFeesData(res?.result);
         setLoading(false);
      } catch (e) {
         toast.error(e?.message || 'Something went wrong');
         setLoading(false);
      }
   };

   const fetchRateSpread = async () => {
      setLoading(true);
      try {
         const res = await getRateSpread();
         setRateSpreadData(res?.result?.spread);
         setLoading(false);
      } catch (e) {
         toast.error(e?.message || 'Something went wrong');
         setLoading(false);
      }
   };

   useEffect(() => {
      fetchGlobalFees();
      fetchRateSpread();
      setRateSpreadData();
   }, []);

   const handleUpdate = (data) => {
      setSelectedCurrency(data);
      setUpdateFeesModalVisibility(true);
   };

   const handleFeesUpdate = (data) => {
      confirm({
         confirmText: 'Are you sure want to update the fees?',
         onConfirm: async () => {
            setLoading(true);
            try {
               const res = await updateGlobalFees(data);
               toast.success(res?.message || 'Data Successfully updated');
               setLoading(false);
               fetchGlobalFees();
            } catch (e) {
               toast.error(e?.message || 'Something went wrong');
               setLoading(false);
            }
         },
      });
   };

   const handleRateSpreadUpdate = () => {
      confirm({
         confirmText: 'Are you sure want to update the rate spread?',
         onConfirm: async () => {
            setLoading(true);
            const payload = {
               spread: rateSpreadData,
            };
            try {
               const res = await updateRateSpread(payload);
               toast.success(res?.message || 'Data Successfully updated');
               setLoading(false);
               fetchRateSpread();
            } catch (e) {
               toast.error(e?.message || 'Something went wrong');
               setLoading(false);
            }
         },
      });
   };

   return (
      <div>
         {loading ? (
            <Spinner />
         ) : (
            <div>
               <h3 className="mb-3 pb-4">Inbound Transactions Fees</h3>
               <div className="border-top row pt-5">
                  {Object.keys(feesData).map((item, index) => (
                     <div
                        className={classNames('col-lg-4 mb-5', {
                           'pe-5': (index + 1) % 3 !== 0,
                        })}
                     >
                        <div className="border p-3 mb-4">
                           <h3 className="mb-3">{item}</h3>
                           <div className="d-flex gap-2 align-items-center mb-2">
                              <h4>Flat Fee:</h4>
                              {feesData[item].flat_fee}
                           </div>
                           <div className="d-flex gap-2 align-items-center mb-4">
                              <h4>Percentage Fee:</h4>
                              {feesData[item].percentage_fee}%
                           </div>
                           <Button className="w-100" onClick={() => handleUpdate(item)}>
                              Update
                           </Button>
                        </div>
                     </div>
                  ))}
               </div>
               <h3 className=" mb-3 pt-4 border-top ">Exchange Rate Spread </h3>
               <div className="col-lg-4 mb-5">
                  <div className="mb-4">
                     <NumberInput
                        label="Rate Spread"
                        placeholder="Enter the Rate Spread"
                        value={rateSpreadData}
                        prefix=" "
                        onChange={(val) => setRateSpreadData(val?.floatValue)}
                     />
                  </div>
                  <Button className="w-100" onClick={handleRateSpreadUpdate}>
                     Update
                  </Button>
               </div>
               {updateFeesModalVisibility && (
                  <UpdateFeesModal
                     open={updateFeesModalVisibility}
                     onClose={() => setUpdateFeesModalVisibility(false)}
                     feesData={feesData}
                     loading={loading}
                     currency={selectedCurrency}
                     onSubmit={handleFeesUpdate}
                  />
               )}
            </div>
         )}
      </div>
   );
};
