import { useEffect, useState } from 'react';
import { bool, func, objectOf, string } from 'prop-types';
import { StatsCards } from 'components/stats-cards';
import { Spinner } from 'components/spinner';
import moment from 'moment';
import { Input } from 'components/input';
import { Navigation, NavigationTab } from '../style';

const Overview = ({
   fetchFilteredStats,
   stats,
   activeStat,
   filteredDate,
   setFilteredDate,
   loading,
}) => {
   const [activeTab, setActiveTab] = useState('today');

   useEffect(() => {
      fetchFilteredStats();
   }, [filteredDate, activeStat]);

   useEffect(() => {
      setActiveTab('today');
   }, [activeStat]);

   const handleTabChange = (status) => {
      setActiveTab(status);
      switch (status) {
         case 'today':
            setFilteredDate({
               ...filteredDate,
               start_date: moment().subtract(1, 'day').format('YYYY-MM-DD'),
            });
            break;

         case 'past-7-days':
            setFilteredDate({
               ...filteredDate,
               start_date: moment().subtract(7, 'day').format('YYYY-MM-DD'),
            });
            break;

         case 'past-month':
            setFilteredDate({
               ...filteredDate,
               start_date: moment().subtract(30, 'day').format('YYYY-MM-DD'),
            });
            break;

         case 'all-time':
            setFilteredDate({
               ...filteredDate,
               start_date: '2019-01-01',
            });
            break;

         default:
            setFilteredDate({
               ...filteredDate,
               start_date: '2019-01-01',
            });
      }
   };

   return (
      <div>
         <Navigation className="mb-5 justify-content-between align-items-end">
            <div className="d-flex">
               <NavigationTab
                  className={activeTab === 'today' ? 'active' : ''}
                  onClick={() => handleTabChange('today')}
               >
                  Today
               </NavigationTab>

               <NavigationTab
                  className={activeTab === 'past-7-days' ? 'active' : ''}
                  onClick={() => handleTabChange('past-7-days')}
               >
                  Past 7 days
               </NavigationTab>

               <NavigationTab
                  className={activeTab === 'past-month' ? 'active' : ''}
                  onClick={() => handleTabChange('past-month')}
               >
                  Past Month
               </NavigationTab>

               <NavigationTab
                  className={activeTab === 'all-time' ? 'active' : ''}
                  onClick={() => handleTabChange('all-time')}
               >
                  All time
               </NavigationTab>
            </div>

            <div className="d-flex align-items-end">
               <Input
                  placeholder="Start Date"
                  className="me-4"
                  label="Start Date"
                  name="start_date"
                  type="date"
                  value={filteredDate.start_date}
                  onChange={(e) =>
                     setFilteredDate({
                        ...filteredDate,
                        start_date: e.target.value,
                     })
                  }
               />

               <Input
                  placeholder="End Date"
                  label="End Date"
                  name="end_date"
                  type="date"
                  className="me-4"
                  value={filteredDate.end_date}
                  onChange={(e) =>
                     setFilteredDate({
                        ...filteredDate,
                        end_date: e.target.value,
                     })
                  }
               />
            </div>
         </Navigation>
         {loading ? (
            <Spinner />
         ) : (
            <div>
               <StatsCards data={stats || []} />
            </div>
         )}
      </div>
   );
};

Overview.propTypes = {
   fetchFilteredStats: func.isRequired,
   stats: objectOf(string).isRequired,
   activeStat: string.isRequired,
   filteredDate: objectOf(string).isRequired,
   setFilteredDate: func.isRequired,
   loading: bool.isRequired,
};

export default Overview;
