import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { AlertMessage } from 'components/message';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-hot-toast';
import { FormContainer, InputWrapper } from 'styles/form';
import { saveToken, saveUserInfoToStorage } from 'utils/storage';
import { Input } from 'components/input';
import { login, extraStep2fa } from 'services/auth';
import { useAuth } from 'hooks/auth';
import { Button } from 'components/button';
// import { RAAM_USER_ID } from 'utils/constants';
import { initializeUserPermissions } from 'utils/user_access';
import { AuthContainer, AuthFooter, BrandLogoContainer } from './login.style';

const schema = yup.object().shape({
   email: yup.string().email().required(),
   password: yup.string().required(),
});

const schema2 = yup.object().shape({
   state_otp: yup.string().required(),
});

function Login() {
   const [isLoading, setIsLoading] = useState(false);
   const [error, setError] = useState('');
   const [requiresOtp, setRequiresOtp] = useState(false);
   const { setAuthenticatedUser } = useAuth();
   const history = useHistory();
   const {
      handleSubmit,
      register,
      formState: { errors },
      getValues,
      watch,
   } = useForm({ resolver: yupResolver(schema), mode: 'onChange' });

   const {
      handleSubmit: handleSubmit2,
      register: register2,
      formState: { errors: errors2 },
      getValues: getValues2,
      watch: watch2,
   } = useForm({
      defaultValues: {
         state_otp: '',
      },
      resolver: yupResolver(schema2),
      mode: 'onChange',
   });

   useEffect(() => {
      setError('');
   }, [JSON.stringify(watch()), JSON.stringify(watch2())]);

   const proceedToLogin = async ({ token, ...rest }) => {
      try {
         const access = await initializeUserPermissions(token, rest);
         saveToken(token);
         setAuthenticatedUser({ token, ...rest, access });
         saveUserInfoToStorage({ ...rest, access });
         // if (rest.prospa_user_id === RAAM_USER_ID || rest.prospa_manager_id === RAAM_USER_ID)
         //    return history.push('/loans');

         return history.push(access ? access.pageAccess[0]?.replaceAll('_', '-') : '/approver');
      } catch (e) {
         toast.error(e?.message || 'An error occurred');
      }
      setIsLoading(false);
   };
   async function onSubmit(values) {
      try {
         setIsLoading(true);
         const result = await login(values);
         if (result.status_code === 201) {
            setRequiresOtp(true);
            return setIsLoading(false);
         }
         proceedToLogin(result);
      } catch (err) {
         setError(err.message || err.custom_message);
         setIsLoading(false);
      }
   }

   const validate2fa = async ({ state_otp }) => {
      const params = {
         state_otp,
         otp_type: 'email',
         otp_email: watch('email'),
      };
      try {
         setIsLoading(true);
         const result = await extraStep2fa(params);
         setIsLoading(false);
         proceedToLogin(result);
      } catch (err) {
         setError(err.message || err.custom_message);
         setIsLoading(false);
         // setValue2('state_otp', '')
      }
   };

   return (
      <AuthContainer>
         <FormContainer>
            <BrandLogoContainer>
               <img
                  src="https://psp-zenvault.s3.eu-west-2.amazonaws.com/static/assets/img/prospa-logo3.svg"
                  alt="brand-icon"
               />
            </BrandLogoContainer>
            <AlertMessage message={error} variant="error" />
            {!requiresOtp ? (
               <form onSubmit={handleSubmit(onSubmit)}>
                  <InputWrapper>
                     <Input
                        placeholder="Email Address"
                        label="Email Address"
                        error={errors.email?.message}
                        {...register('email')}
                     />
                  </InputWrapper>
                  <InputWrapper>
                     <Input
                        type="password"
                        placeholder="Password"
                        label="Password"
                        endAdornment
                        error={errors.password?.message}
                        {...register('password')}
                     />
                  </InputWrapper>
                  <Button
                     disabled={
                        Object.keys(errors).length > 0 ||
                        Object.values(getValues()).length < 1 ||
                        isLoading
                     }
                     fullWidth
                     variant="primary"
                     isLoading={isLoading}
                     type="submit"
                  >
                     Submit
                  </Button>
               </form>
            ) : (
               <form onSubmit={handleSubmit2(validate2fa)}>
                  <InputWrapper>
                     <Input
                        placeholder="Please enter OTP"
                        label="OTP"
                        error={errors2.state_otp?.message}
                        {...register2('state_otp')}
                        type="number"
                     />
                  </InputWrapper>
                  <Button
                     disabled={
                        Object.keys(errors2).length > 0 ||
                        Object.values(getValues2()).length < 1 ||
                        isLoading
                     }
                     fullWidth
                     variant="primary"
                     isLoading={isLoading}
                     type="submit"
                  >
                     Submit
                  </Button>
               </form>
            )}
            <AuthFooter>2021 Prospa Technology Limited</AuthFooter>
         </FormContainer>
      </AuthContainer>
   );
}

export default Login;
