import MeterIcon from 'assets/images/speedometer.png';
import { objectOf, string } from 'prop-types';
import { Wrapper } from './style';

function Index({ bizDetails }) {
   const { progress_report_details } = bizDetails || {};

   const {
      biz_progress_customer,
      biz_progress_inflow,
      biz_progress_invoice,
      biz_progress_product,
      biz_progress_sub_account,
      biz_progress_team_member,
   } = progress_report_details || {};

   return (
      <Wrapper className="p-5">
         <div className="d-flex">
            <img src={MeterIcon} alt="" className="warning-icon me-5" />
            <div className="ps-3 w-100">
               <h2 className="mb-4">Progress Report</h2>
               <ul>
                  <li>
                     <span className="title">Business Inflow Progress</span>
                     <span className="number">{biz_progress_inflow} %</span>
                  </li>
                  <li className="">
                     <span className="title">Added a product to business Hub</span>
                     <span className="number">{biz_progress_product}</span>
                  </li>
                  <li>
                     <span className="title">Created a customer</span>
                     <span className="number">{biz_progress_customer}</span>
                  </li>

                  <li>
                     <span className="title">Created an Invoice</span>
                     <span className="number">{biz_progress_invoice}</span>
                  </li>
                  <li>
                     <span className="title">Created a sub account</span>
                     <span className="number">{biz_progress_sub_account}</span>
                  </li>
                  <li>
                     <span className="title">Added a team member</span>
                     <span className="number">{biz_progress_team_member}</span>
                  </li>
               </ul>
            </div>
         </div>
      </Wrapper>
   );
}

Index.propTypes = {
   bizDetails: objectOf(string).isRequired,
};

export default Index;
