import { string } from 'prop-types';
import { NoteContent, NoteHeading, NoteTimestamp, NoteWrapper } from './notes.style';

const Note = ({ date, time, author, note }) => (
   <div className="mb-5">
      <NoteTimestamp className="mb-2">
         {date} | <span className="note-time">{time}</span>
      </NoteTimestamp>
      <NoteWrapper>
         <NoteHeading className="py-2 px-3">{author}</NoteHeading>
         <NoteContent className="p-3">{note}</NoteContent>
      </NoteWrapper>
   </div>
);

Note.propTypes = {
   date: string.isRequired,
   time: string.isRequired,
   author: string.isRequired,
   note: string.isRequired,
};

export default Note;
