import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'components/input';
import { Button } from 'components/button';
import { validateEmail } from 'utils/validations';

export default function TransferChannels({
   settings,
   onChange,
   handleEmail,
   isLoading,
   viewWhiteList,
   handleCsv,
   whitelistedEmails,
}) {
   const excludeSettings = ['easypay_allowed_emails'];
   const [email, setEmail] = useState();
   const [setting, setSetting] = useState();
   const [error, setError] = useState(null);

   useEffect(() => {
      if (settings) {
         setSetting(settings);
      }
   }, [settings]);

   const handleChange = (value, oldSettings) => {
      let newValue = { ...oldSettings.value };
      Object.keys(oldSettings.value).forEach((val) => {
         if (!excludeSettings.includes(val)) {
            newValue = {
               ...newValue,
               [val]: value === val,
            };
         }
      });

      const newSettings = {
         ...oldSettings,
         value: newValue,
      };

      setSetting(newSettings);
   };

   const isExist = (value) =>
      whitelistedEmails?.some((item) => item?.toLowerCase() === value.toLowerCase());

   const changeEmail = ({ target }) => {
      const { value } = target;
      if (isExist(value)) {
         setError('Email already exist in the whitelist');
      } else {
         setError(null);
      }

      setEmail(value);
   };

   const isValid = !!email && validateEmail(email) && !error;

   return (
      <div className="row border-bottom pb-4">
         {setting && setting.value && (
            <div className="pb-3 d-flex flex-column justify-content-between">
               <div>
                  <div className="pb-5">
                     <h3 className="mb-3">Transfer Channel</h3>
                     <div className="mb-3">
                        {Object.keys(setting.value).map((settingItem) => {
                           if (settingItem !== 'easypay_allowed_emails') {
                              return (
                                 <div className="d-flex align-items-center mb-4">
                                    <input
                                       id={settingItem}
                                       type="radio"
                                       name="transfer-channel"
                                       checked={setting.value[settingItem]}
                                       onChange={() => handleChange(settingItem, setting)}
                                    />
                                    <label htmlFor={settingItem} className="mx-3">
                                       {settingItem?.replace('_', ' ')}
                                    </label>
                                 </div>
                              );
                           }
                           return null;
                        })}
                     </div>
                     <Button
                        className="col-lg-3 pe-5"
                        style={{ width: '100px' }}
                        onClick={() => onChange(setting)}
                     >
                        Update settings
                     </Button>
                  </div>

                  <div className="border-top pt-5">
                     <h3 className="mb-3">Easypay Whitelist</h3>
                     <div className="col-xl-4  d-flex">
                        <Input
                           placeholder="Enter emails to whitelist accounts"
                           value={email}
                           onChange={changeEmail}
                        />
                        <Button
                           className="mx-2"
                           onClick={() => handleEmail([...whitelistedEmails, email])}
                           isLoading={isLoading}
                           disabled={!isValid}
                        >
                           Add Email
                        </Button>
                     </div>
                     {error && <p className="input-error-message">{error}</p>}

                     <div className="d-flex align-items-center my-4 col-xl-8">
                        <Button
                           className="me-3"
                           style={{ backgroundColor: '#33DF59' }}
                           onClick={handleCsv}
                        >
                           Upload CSV
                        </Button>
                        <Button className="col-lg-3 pe-5" onClick={viewWhiteList}>
                           View whitelist
                        </Button>
                     </div>
                  </div>
               </div>
            </div>
         )}
      </div>
   );
}

TransferChannels.propTypes = {
   settings: PropTypes.objectOf().isRequired,
   onChange: PropTypes.func.isRequired,
   handleEmail: PropTypes.func.isRequired,
   isLoading: PropTypes.bool.isRequired,
   viewWhiteList: PropTypes.func.isRequired,
   handleCsv: PropTypes.func.isRequired,
   whitelistedEmails: PropTypes.arrayOf().isRequired,
};
