/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import { func, objectOf, bool, string } from 'prop-types';
import Modal from 'components/modal';
import { Input } from 'components/input';
import { SelectInput } from 'components/select';
import { toast } from 'react-hot-toast';
import { confirm } from 'components/confirm-box';
import { InputWrapper } from 'styles/form';
import { Button } from 'components/button';
import { createNewCampaign, updateCampaignDetails } from 'services/campaigns';

function AddCampaign({ isEdit = false, editDefault, fetchCampaigns, onClose, open }) {
   const [updatingData, setUpdatingData] = useState(false);
   const [loading, setLoading] = useState(false);
   const [imageSelected, setImageSelected] = useState([]);
   const [smallImageSelected, setSmallImageSelected] = useState([]);
   const [newData, setNewData] = useState({});
   const [allowButton, setAllowButton] = useState(false);

   const statusOptions = [
      {
         value: 'active',
         label: 'Active',
      },
      {
         value: 'expired',
         label: 'Expired',
      },
   ];

   const typeOptions = [
      {
         value: '',
         label: 'Internal',
      },
      {
         value: 'external',
         label: 'External',
      },
   ];

   const { cap_status, cap_title, cap_content, cap_location, cap_button, cap_type } = editDefault;

   const isValid =
      newData.cap_status && newData.cap_title && newData.cap_content && newData.cap_location;

   const isValid2 =
      newData.cap_title &&
      newData.cap_content &&
      imageSelected.length !== 0 &&
      smallImageSelected.length !== 0;

   const detailsUnchanged = () =>
      newData.cap_status === cap_status &&
      newData.cap_title === cap_title &&
      newData.cap_location === cap_location &&
      newData.cap_content === cap_content &&
      newData.cap_button === cap_button &&
      newData.cap_type === cap_type &&
      imageSelected.length === 0 &&
      smallImageSelected.length === 0;

   function onChange(name, data) {
      setNewData({
         ...newData,
         [name]: data.value,
      });
   }
   const addNewCampaign = async (e) => {
      e.preventDefault();
      confirm({
         confirmText: 'Are you sure you want to perform this action?',
         isLoading: false,
         onConfirm: async () => {
            setLoading(true);
            const formData = new FormData();
            formData.append('cap_title', newData?.cap_title);
            formData.append('cap_content', newData?.cap_content);
            if (allowButton) formData.append('cap_location', newData?.cap_location);
            if (allowButton) formData.append('cap_button', newData?.cap_button);
            if (allowButton) formData.append('cap_type', newData?.cap_type);
            if (!allowButton) formData.append('cap_location', 'n/a');
            if (imageSelected) formData.append('cap_image', imageSelected);
            if (smallImageSelected) formData.append('cap_small_image', smallImageSelected);
            try {
               const result = await createNewCampaign(formData);
               if (result.status === 201) {
                  fetchCampaigns();
                  toast.success(addNewCampaign?.message || 'Created Successfully', {
                     duration: 3000,
                  });
                  onClose();
               }
               setUpdatingData(false);
            } catch (err) {
               toast.error(err.message || 'An error has occurred');
               setUpdatingData(false);
            }
            setLoading(false);
         },
      });
   };

   const editCampaignDetails = async (e) => {
      e.preventDefault();
      confirm({
         confirmText: 'Are you sure you want to perform this action?',
         isLoading: false,
         onConfirm: async () => {
            const formData = new FormData();
            formData.append('cap_title', newData?.cap_title);
            formData.append('cap_content', newData?.cap_content);
            formData.append('cap_button', newData?.cap_button);
            formData.append('cap_type', newData?.cap_type);
            formData.append('cap_location', newData?.cap_location);
            formData.append('cap_status', newData?.cap_status);
            formData.append('campaign_id', editDefault.campaign_id);
            if (imageSelected) formData.append('cap_image', imageSelected);
            if (smallImageSelected) formData.append('cap_small_image', smallImageSelected);
            setUpdatingData(true);
            try {
               const result = await updateCampaignDetails(formData);
               if (result.status === 200) {
                  fetchCampaigns();
                  toast.success(editCampaignDetails?.message || 'Details Updated Successfully', {
                     duration: 3000,
                  });
                  onClose();
               }
               setUpdatingData(false);
            } catch (err) {
               toast.error(err.message || err.custom_message);
               setUpdatingData(false);
            }
         },
      });
   };

   useEffect(() => {
      setNewData(editDefault);
   }, [editDefault]);

   return (
      <Modal
         open={open}
         onClose={onClose}
         title={isEdit ? 'Edit Campaign Details' : 'Create New Campaign'}
      >
         <form onSubmit={isEdit ? editCampaignDetails : addNewCampaign}>
            <InputWrapper className="grid-2-2">
               <div>
                  <Input
                     label="Title"
                     name="cap_title"
                     value={newData.cap_title}
                     className={Input}
                     onChange={(e) => {
                        onChange('cap_title', e.target);
                     }}
                  />
               </div>
               <div>
                  <Input
                     label="Description"
                     name="cap_content"
                     value={newData.cap_content}
                     className={Input}
                     onChange={(e) => {
                        onChange('cap_content', e.target);
                     }}
                  />
               </div>
            </InputWrapper>

            {!isEdit && (
               <InputWrapper className="grid-2-2">
                  <label htmlFor="for-button-text">
                     <input
                        id="for-button-text"
                        type="checkbox"
                        checked={allowButton}
                        style={{ cursor: 'pointer' }}
                        onChange={() => setAllowButton(!allowButton)}
                     />
                     <span className="ms-3">Add Button & Location</span>
                  </label>
               </InputWrapper>
            )}

            {(allowButton || isEdit) && (
               <>
                  <InputWrapper className="grid-2-2">
                     <div>
                        <Input
                           label="Button Text"
                           name="cap_button"
                           value={newData.cap_button}
                           className={Input}
                           onChange={(e) => {
                              onChange('cap_button', e.target);
                           }}
                        />
                     </div>
                     <div>
                        <SelectInput
                           label="Campaign Type"
                           options={typeOptions}
                           value={typeOptions.filter((item) => item.value === newData.cap_type)}
                           onChange={(target) => onChange('cap_type', target)}
                        />
                     </div>
                  </InputWrapper>
                  <InputWrapper className="grid-2-2">
                     <div>
                        <Input
                           label="Location"
                           name="cap_location"
                           value={newData.cap_location}
                           className={Input}
                           onChange={(e) => {
                              onChange('cap_location', e.target);
                           }}
                        />
                     </div>
                  </InputWrapper>
               </>
            )}

            <InputWrapper className="grid-2-2">
               <div>
                  <h2>Upload Image</h2>
                  <div className="input-group mt-4 doc-file-input-group align-items-center">
                     <input
                        type="file"
                        accept="image"
                        className="form-control file-input"
                        aria-label="Upload"
                        onChange={(e) => setImageSelected(e.target.files[0])}
                     />
                  </div>
               </div>
               <div>
                  <h2>Upload Small Image</h2>
                  <div className="input-group mt-4 doc-file-input-group align-items-center">
                     <input
                        type="file"
                        accept="image"
                        className="form-control file-input"
                        aria-label="Upload"
                        onChange={(e) => setSmallImageSelected(e.target.files[0])}
                     />
                  </div>
               </div>
            </InputWrapper>

            {isEdit && (
               <InputWrapper className="grid-2-2">
                  <SelectInput
                     label="Campaign status"
                     options={statusOptions}
                     value={statusOptions.filter((item) => item.value === newData.cap_status)}
                     onChange={(target) => onChange('cap_status', target)}
                  />
               </InputWrapper>
            )}

            <div className="d-flex justify-content-end pt-5">
               {isEdit ? (
                  <Button
                     type="submit"
                     isLoading={updatingData}
                     disabled={!isValid || detailsUnchanged() || updatingData || loading}
                  >
                     Update Details
                  </Button>
               ) : (
                  <Button
                     type="submit"
                     isLoading={loading}
                     disabled={!isValid2 || updatingData || loading}
                  >
                     Add New Data
                  </Button>
               )}
            </div>
         </form>
      </Modal>
   );
}

AddCampaign.propTypes = {
   isEdit: bool.isRequired,
   onClose: func.isRequired,
   open: func.isRequired,
   editDefault: objectOf(string).isRequired,
   fetchCampaigns: func.isRequired,
};

export default AddCampaign;
