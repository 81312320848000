import { useEffect, useState } from 'react';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { Pagination } from '@material-ui/lab';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-hot-toast';
import { Button } from 'components/button';
import { Input } from 'components/input';
import { number } from 'prop-types';
import { Spinner } from 'components/spinner';
import { AlertMessage } from 'components/message';
import { usePaginationStyles } from 'components/table/table';
import { addNote, getNotes } from 'services/businessDetails';
import Empty from 'assets/images/empty.svg';
import Note from './note';
import { NoteForm, NotesContainer, Wrapper } from './notes.style';

const Notes = ({ businessAccountId }) => {
   const [notes, setNotes] = useState([]);
   const [error, setError] = useState('');
   const [loading, setLoading] = useState(true);
   const [page, setPage] = useState(1);
   const [paginationData, setPaginationData] = useState('');
   const [isSubmitting, setIsSubmitting] = useState(false);

   const paginationClasses = usePaginationStyles();

   const schema = yup.object().shape({
      drop_message: yup.string().required('This field is required'),
   });

   const {
      handleSubmit,
      register,
      formState: { errors },
      clearErrors,
      reset,
   } = useForm({ resolver: yupResolver(schema), mode: 'onSubmit' });

   const fetchNotes = async () => {
      try {
         const result = await getNotes(businessAccountId, { page });

         if (result.ok) {
            setNotes(result?.data);
            reset();
            setPaginationData(result);
            setError('');
         }
         setLoading(false);
      } catch (err) {
         setError(err.message || err.custom_message);
         setLoading(false);
      }
   };

   const onSubmit = async (data) => {
      const newNote = { ...data, biz_account_id: businessAccountId };

      try {
         setIsSubmitting(true);
         const result = await addNote(newNote);

         if (result.ok) {
            await fetchNotes();
         }
         setIsSubmitting(false);
      } catch (err) {
         toast.error(err.message || err.custom_message);
         setError(err.message || err.custom_message);
         setIsSubmitting(false);
      }
   };

   useEffect(() => {
      fetchNotes();
   }, [page]);

   return (
      <Wrapper>
         {loading ? (
            <Spinner />
         ) : (
            <>
               <AlertMessage message={error} variant="error" />
               <NotesContainer>
                  {notes?.length ? (
                     notes.map((n) => {
                        const { creator_first_name, creator_last_name } = n;
                        const author = `${creator_first_name} ${creator_last_name}`;
                        const { pub_date } = n;
                        const date = moment(pub_date).format('MMM DD, YYYY');
                        const time = moment(pub_date).format('h:mm a');
                        return (
                           <Note
                              key={n.biz_note_id}
                              date={date}
                              time={time}
                              author={author}
                              note={n.drop_message}
                           />
                        );
                     })
                  ) : (
                     <div className="d-flex justify-content-center align-items-center h-100">
                        <div className="text-center">
                           <img src={Empty} alt="empty" width="75" />
                           <p>No notes available</p>
                        </div>
                     </div>
                  )}
                  <div style={{ margin: '0 auto' }}>
                     <Pagination
                        classes={paginationClasses}
                        count={Math.ceil(paginationData.count / 15)}
                        page={page}
                        onChange={(e, num) => {
                           setPage(num);
                        }}
                        boundaryCount={4}
                        disabled={loading}
                     />
                  </div>
               </NotesContainer>
               <NoteForm onSubmit={handleSubmit(onSubmit)}>
                  <Input
                     placeholder="Type note here"
                     className="note-input"
                     id="drop_message"
                     error={errors.drop_message?.message}
                     {...register('drop_message')}
                     onBlur={() => clearErrors()}
                  />
                  <Button
                     disabled={isSubmitting}
                     type="submit"
                     className="note-btn"
                     isLoading={isSubmitting}
                  >
                     Submit
                  </Button>
               </NoteForm>{' '}
            </>
         )}
      </Wrapper>
   );
};

Notes.propTypes = {
   businessAccountId: number.isRequired,
};

export default Notes;
