import { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import { func, bool } from 'prop-types';
import { Input } from 'components/input';
import Modal from 'components/modal';
import { InputWrapper } from 'styles/form';
import { Button } from 'components/button';
import { SelectInput } from 'components/select';

const statusOptions = [
   {
      value: 'pending',
      label: 'Pending',
   },
   {
      value: 'expired',
      label: 'Expired',
   },
   {
      value: 'wait_confirmed',
      label: 'Wait Confirmed',
   },
   {
      value: 'unused_confirmed',
      label: 'Unused Confirmed',
   },
   {
      value: 'confirmed',
      label: 'Confirmed',
   },
];

const resetOptions = [
   {
      label: 'PIN',
      value: 'pin',
   },
   {
      label: 'PASSWORD',
      value: 'password',
   },
];

const ResetLogsFilter = ({ open, onClose, filterData, setFilterData, setFilterPayload }) => {
   const [isLoading, setIsLoading] = useState(false);
   const [isClearingFilter, setIsClearingFilter] = useState(false);
   const history = useHistory();

   useEffect(() => {
      return function unMount() {
         setIsLoading(false);
      };
   }, []);

   function onChange(name, data) {
      setFilterData({
         ...filterData,
         [name]: data.value,
      });
   }

   async function onSubmit() {
      setIsLoading(true);
      try {
         history.push(`${history.location.pathname}?page=1`);
         setFilterPayload(filterData);
         onClose();
      } catch (err) {
         toast.error(err.message || err.custom_message);
         setIsLoading(false);
         return err;
      } finally {
         setIsLoading(false);
      }
   }

   async function handleClearFilter() {
      setIsClearingFilter(true);
      try {
         history.push(`${history.location.pathname}?page=1`);
         setFilterData({});
         setFilterPayload({});
         onClose();
      } catch (err) {
         return err;
      }
      setIsClearingFilter(false);
   }

   return (
      <Modal
         open={open}
         onClose={onClose}
         title="Filters"
         className="biz-details-modal"
         center
         width="750px"
      >
         <form>
            <InputWrapper className="grid-2-2">
               <Input
                  placeholder="Start Date"
                  label="Start Date"
                  name="start_date"
                  type="date"
                  value={filterData?.start_date}
                  onChange={({ target }) => onChange('start_date', target)}
               />
               <Input
                  placeholder="End Date"
                  label="End Date"
                  name="end_date"
                  type="date"
                  value={filterData?.end_date}
                  onChange={({ target }) => onChange('end_date', target)}
               />
            </InputWrapper>
            <InputWrapper className="grid-2-2">
               <Input
                  placeholder="User"
                  label="Enter User ID"
                  name="user"
                  value={filterData?.user}
                  onChange={({ target }) => onChange('user', target)}
               />
               <Input
                  placeholder="Request IP"
                  label="Enter Request IP Address"
                  name="request_ip_address"
                  value={filterData?.request_ip_address}
                  onChange={({ target }) => onChange('request_ip_address', target)}
               />
            </InputWrapper>

            <InputWrapper className="grid-2-2">
               <SelectInput
                  label="Status"
                  options={statusOptions}
                  value={statusOptions.find((item) => item.value === filterData?.status)}
                  onChange={(target) => onChange('status', target)}
               />

               <SelectInput
                  label="Reset Type"
                  options={resetOptions}
                  value={resetOptions.find((item) => item.value === filterData?.reset_type)}
                  onChange={(target) => onChange('reset_type', target)}
               />
            </InputWrapper>

            <InputWrapper
               style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
            >
               <div style={{ marginRight: '20px' }}>
                  <Button
                     variant="secondary"
                     type="button"
                     onClick={handleClearFilter}
                     isLoading={isClearingFilter}
                     fullWidth
                  >
                     clear filter
                  </Button>
               </div>

               <div>
                  <Button
                     variant="primary"
                     type="button"
                     onClick={onSubmit}
                     isLoading={isLoading}
                     disabled={isLoading}
                     fullWidth
                  >
                     Apply
                  </Button>
               </div>
            </InputWrapper>
         </form>
      </Modal>
   );
};

export default ResetLogsFilter;

ResetLogsFilter.propTypes = {
   open: bool.isRequired,
   onClose: func.isRequired,
   filterData: func.isRequired,
   setFilterData: func.isRequired,
   setFilterPayload: func.isRequired,
};
