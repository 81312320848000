import styled from 'styled-components';

export const AuthContainer = styled.div`
   width: 100%;
   display: flex;
   justify-content: center;
   flex-direction: column;
   height: 100vh;
   margin: 0 auto;
   background-color: #f5f5f5;
`;

export const BrandLogoContainer = styled.div`
   margin: 0 auto;
   width: 170px;

   img {
      width: 100%;
   }
`;

export const AuthFooter = styled.div`
   margin-top: 4rem;
   font-size: 1.5rem;
   text-align: center;
   color: #a1a5b7;
`;
