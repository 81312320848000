import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { resendOTPApi } from 'services/businessDetails';
import { Button } from 'components/button';
import { confirm } from 'components/confirm-box';
import { Input } from 'components/input';
import { InputWrapper } from 'styles/form';
import { hasValue } from 'utils/validations';
import { SelectInput } from 'components/select';

export default function ResendOTP() {
   const [userId, setUserId] = useState('');
   const [resetType, setResetType] = useState('');
   const [businessId, setBusinessId] = useState('');
   const [loading, setLoading] = useState(false);

   const isValid = hasValue(userId) && hasValue(businessId) && hasValue(resetType);

   const resetOptions = [
      {
         label: 'PIN',
         value: 'pin',
      },
      {
         label: 'PASSWORD',
         value: 'password',
      },
   ];

   const resendOTP = async () => {
      setLoading(true);
      const payload = {
         reset_type: resetType.value,
         user_id: userId,
         biz_account_id: businessId,
      };

      try {
         const response = await resendOTPApi(payload);
         toast.success(response.message || 'OTP successfully sent!');
         setUserId('');
         setResetType('');
         setBusinessId('');
      } catch (e) {
         toast.error(e.message);
      }
      setLoading(false);
   };

   const confirmAction = () =>
      confirm({
         confirmText: 'Are you sure you want to continue ?',
         onConfirm: resendOTP,
      });

   return (
      <div className="row align-items-end">
         <InputWrapper className="col-lg-4">
            <SelectInput
               name="reset_type"
               label="Reset Type"
               options={resetOptions}
               value={resetType}
               onChange={(val) => setResetType(val)}
            />
         </InputWrapper>
         <InputWrapper className="col-lg-4">
            <Input
               placeholder="Enter User Id"
               label="User Id"
               value={userId}
               onChange={(e) => setUserId(e.target.value)}
            />
         </InputWrapper>
         <InputWrapper className="col-lg-4">
            <Input
               placeholder="Enter Business ID"
               label="Business ID"
               value={businessId}
               onChange={(e) => setBusinessId(e.target.value)}
            />
         </InputWrapper>
         <InputWrapper className="col-lg-4">
            <Button
               variant="primary"
               onClick={confirmAction}
               isLoading={loading}
               disabled={!isValid}
            >
               Resend
            </Button>
         </InputWrapper>
      </div>
   );
}
