/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { string } from 'prop-types';
import toast from 'react-hot-toast';
import moment from 'moment';
import { useDebouncedCallback } from 'use-debounce';
import { Skeleton } from '@material-ui/lab';
import FilterListIcon from '@material-ui/icons/FilterList';
import { numberWithCommas } from 'utils/others';
import { formatTag } from 'utils/tags';
import { renderTransactionStatus } from 'utils/business';
import { TableContainer, TableContent } from 'styles/table';
import { OverviewWrapper } from 'styles/layout';
import { Input } from 'components/input';
import { Spinner } from 'components/spinner';
import { TableComponent } from 'components/table';
import { TooltipComponent } from 'components/tooltip';
import { FilterContainer } from 'pages/follow-up/follow-up.style';
import { getSingleCardTransactions } from 'services/cards';
import FilterCardTransactionModal from 'pages/transactions/filter-transaction/filter-card-transactions';

const initialFilter = {
   start_date: '2019-01-01',
   end_date: moment().format('YYYY-MM-DD'),
};

const CardTransactions = ({ cardId }) => {
   const { search } = useLocation();
   const page = new URLSearchParams(search).get('page');
   const [currentPage, setCurrentPage] = useState(page);
   const [loading, setLoading] = useState(false);
   const [searchTerm, setSearchTerm] = useState('');
   const [tableData, setTableData] = useState([]);
   const [modalVisibilityFilter, setModalVisibilityFilter] = useState(false);
   const [paginationData, setPaginationData] = useState({});
   const [isLoadingData] = useState(false);
   const [filterData, setFilterData] = useState(initialFilter);
   const [filterPayload, setFilterPayload] = useState({});

   const fetchTransactions = async () => {
      setLoading(true);
      try {
         const res = await getSingleCardTransactions({
            card_id: cardId,
            page: currentPage,
            account_name: searchTerm,
            ...filterPayload,
         });
         setTableData(res.results);
         setPaginationData(res);
         setLoading(false);
      } catch (e) {
         toast.error(e?.message);
         setLoading(false);
      }
   };

   function handleSearch({ target }) {
      setSearchTerm(target.value);
   }
   const debounce = useDebouncedCallback(handleSearch, 600);

   useEffect(() => {
      fetchTransactions();
   }, [filterPayload, searchTerm, currentPage]);

   const columns = [
      {
         field: 'transaction_id',
         headerName: 'Transaction ID',
         renderCell: ({ row }) => <TooltipComponent>#{row?.transaction_id} </TooltipComponent>,
      },
      {
         field: 'trans_amount',
         headerName: 'Amount',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.trans_amount}>
               {numberWithCommas(row?.trans_amount)}
            </TooltipComponent>
         ),
      },
      {
         field: 'tx_action',
         headerName: 'Transaction Type',
         renderCell: ({ row }) => {
            let template = '';
            if (row?.tx_action.includes('credit')) {
               template = formatTag('accepted', 'Credit');
            } else if (row?.tx_action.includes('debit')) {
               template = formatTag('rejected', 'Debit');
            } else {
               template = formatTag(row?.tx_action, row?.tx_action);
            }
            return <TooltipComponent title={row?.tx_action}>{template}</TooltipComponent>;
         },
      },
      {
         field: 'account_number',
         headerName: 'Account',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.account_number || 'N/A'}>
               {row?.account_number || 'N/A'}
            </TooltipComponent>
         ),
      },
      {
         field: 'transfer_details?.transfer_status',
         headerName: 'Status',
         renderCell: ({ row }) => renderTransactionStatus(row),
      },
      {
         field: 'pub_date',
         headerName: 'Date',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.pub_date}>
               {moment(row?.pub_date).format('DD MMM, YYYY')} |{' '}
               <span style={{ opacity: '.5' }}>{moment(row?.pub_date).format('HH:mm A')}</span>
            </TooltipComponent>
         ),
      },
   ];

   function onPageChange(e, pageNumber) {
      setCurrentPage(pageNumber);
   }

   return (
      <>
         {loading ? (
            <Spinner />
         ) : (
            <OverviewWrapper>
               <TableContainer id="table">
                  <TableContent>
                     {isLoadingData ? (
                        <Skeleton width="30%" height="20" />
                     ) : (
                        <div>
                           <h1>Transactions</h1>
                           <span className="value">{paginationData?.count || 'N/A'}</span>
                        </div>
                     )}
                     <div className="d-flex justify-content-between">
                        <div>
                           <Input
                              style={{ width: '300px', height: '48px' }}
                              placeholder="Search for Transaction ID"
                              onChange={debounce}
                           />
                        </div>
                        <FilterContainer
                           style={{ margin: '0px 0px 0px 20px' }}
                           onClick={() => setModalVisibilityFilter(true)}
                        >
                           Filter By <FilterListIcon style={{ marginLeft: '10px' }} />
                        </FilterContainer>
                     </div>
                  </TableContent>
                  <TableComponent
                     columns={columns}
                     rows={tableData}
                     isLoading={isLoadingData}
                     onPageChange={onPageChange}
                     count={Math.round(paginationData.count / 15)}
                  />
                  {modalVisibilityFilter && (
                     <FilterCardTransactionModal
                        open={modalVisibilityFilter}
                        onClose={() => setModalVisibilityFilter(false)}
                        setCurrentPage={setCurrentPage}
                        filterData={filterData}
                        setFilterData={setFilterData}
                        setFilterPayload={setFilterPayload}
                     />
                  )}
               </TableContainer>
            </OverviewWrapper>
         )}
      </>
   );
};

CardTransactions.propTypes = {
   cardId: string.isRequired,
};
export default CardTransactions;
