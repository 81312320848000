import styled from 'styled-components';

export const MultiSelectDropdownWrapper = styled.div`
   width: 100%;
   position: sticky;

   .trigger-container {
      width: 100%;
      height: 48px;
      background-color: var(--color-white);
      border: 1px solid var(--color-grey);
      border-radius: 4px;
      padding: 0 8px;
      cursor: pointer;

      .values {
         color: #8fa7bc;
         font-size: 16px;
         line-height: 11px;
         white-space: nowrap;
         overflow-x: hidden;
         text-overflow: ellipsis;
         padding: 5px 0;
         margin-right: 15px;
      }

      svg {
         flex-shrink: 0;
         width: 13px;
      }
   }

   .multi-select-label {
      pointer-events: none;
      white-space: nowrap;
      margin-bottom: 0.3rem;
      font-size: 1.4rem;
      display: block;
      font-weight: 400;
      color: var(--color-black);
   }

   .mulitselect-dropdown-options {
      background: white;
      padding: 15px 20px 15px 10px;
      box-shadow: 0.5px 0.5px 5px rgba(0, 0, 0, 0.1);
      position: relative;
      z-index: 10;
      top: calc(100% + 10px);
      width: fit-content;
      max-width: 310px;
      min-width: 250px;
      max-height: 400px;
      overflow-y: auto;
   }
`;
