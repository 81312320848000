import Styled from 'styled-components';

export const HeaderWrapper = Styled.div`
    top:0;
    width:100%;
    z-index:1000;
    position:fixed;
    .nav-icons{
        .open-nav{
            width:25px;
        }
        .close-nav{
            width:20px;
        }
    }
    .search-term-container{
        position:relative;
    }
    .search-result{
        position:absolute;
        top:145%;
        box-shadow:1px 1px 25px rgba(0,0,0,.15);
        border-radius:4px;
        background:#fff;
        width:150%;
        transition:all ease .3s;
        max-height:calc(100vh - 100px);
        overflow-y:scroll;
        li{
            border-bottom:1px solid #e3e3e3;
            padding:10px 0;
        }
    }
    .search-result-title{
        cursor:pointer;
    }
    @media(max-width:767px){
        .logo-container{
            width:100%;
        }
    }
`;
