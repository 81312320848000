import Styled from 'styled-components';

export const TabWrapper = Styled.div`
    margin-bottom:30px;
    a:hover{
        text-decoration:none;
    }
     ul{
        border-bottom: 1px solid #D7DCE0;
        cursor:pointer;
        padding-left:10px;
    }
     li{
        padding:0 10px;
        padding-bottom:14px;
    }
     li.active{
        position:relative;
        font-weight:600;
    }
     li.active a{
        position:relative;
        color:#1354D3;
        
    }
     li.active:after{
        content:'';
        position:absolute;
        height:2px;
        width:100%;
        background:#1354D3;
        bottom:0;
        left:0;
    }

     li{
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: #81909D;
        margin-right:15px;
    }
`;

export const DepositFeedWrapper = Styled.div`
    .deposit-title{
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: 0.25px;
        color: #252733;
        margin-bottom:8px;
    }
    .moment{
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.4px;
        color: #81909D;
    }
    .date-picker{

    }
    .date-picker-group{
        span{
            font-size: 14px;
            line-height: 16px;
            color: #435A70;        
        }
        select{
            width:100px;
            background: #FFFFFF;
            border: 1px solid #D7E0E9;
            box-sizing: border-box;
            border-radius: 4px;
            width:120px;
            height:38px;
            font-size: 14px;
            line-height: 16px;
            color: #435A70;
            padding:0 10px;
            :placeholder{
                font-size: 14px;
                line-height: 16px;
                color: #435A70;
            }
        }
    }
    .feeds-btn{
        padding:10px 0;
        width:120px;
        border:1px solid #e3e3e3;
        border-radius:5px;
        text-align:center;
        cursor:pointer;
        color:#1354d3;
        transition:all ease .3s;
        p{
            margin:0;
        }
    }
    .feeds-btn.active,.feeds-btn:hover {
        background:#1354d3;
        color:#fff;
    }
`;

export const GraphWrapper = Styled.div`
    background: #FFFFFF;
    border: 1px solid #D7DCE0;
    box-sizing: border-box;
    border-radius: 8px;
    padding:40px 25px 25px 25px;
`;

export const FilterContainer = Styled.div`
   border: 1px solid #d7dce0;
   padding: 10px 16px;
   border-radius: 6px;
   margin: 0 20px;
   height: 40px;
   cursor: pointer;
   width: 200px;
   display: flex;
   align-items: center;
   justify-content: space-between;
`;
