import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes, { string } from 'prop-types';
import Modal from 'components/modal';
import toast from 'react-hot-toast';
import { InputWrapper } from 'styles/form';
import { Input, NumberInput } from 'components/input';
import { SelectInput } from 'components/select';
import { Button } from 'components/button';

const statusOptions = [
   {
      label: 'Fully Paid',
      value: 'full_paid',
   },
   {
      label: 'Pending',
      value: 'pending',
   },
];

export default function BillingDebtLogsFilterModal({
   open,
   onClose,
   filterData,
   setFilterData,
   setFilterPayload,
}) {
   const history = useHistory();
   const [isLoading, setIsLoading] = useState(false);
   const [isClearingFilter, setIsClearingFilter] = useState(false);

   useEffect(() => {
      return function unMount() {
         setIsLoading(false);
      };
   }, []);

   function onChange(name, data) {
      setFilterData({
         ...filterData,
         [name]: data.value,
      });
   }

   async function onSubmit() {
      setIsLoading(true);
      try {
         setFilterPayload(filterData);
         onClose();
         history.push(`${history.location.pathname}?page=1`);
      } catch (err) {
         toast.error(err.message || err.custom_message);
         return err;
      }
      setIsLoading(false);
   }

   async function handleClearFilter() {
      setIsClearingFilter(true);
      try {
         setFilterData({});
         setFilterPayload({});
         onClose();
         history.push(`${history.location.pathname}?page=1`);
      } catch (err) {
         return err;
      }
      setIsClearingFilter(false);
   }

   return (
      <Modal
         open={open}
         onClose={onClose}
         title="Filters"
         className="biz-details-modal"
         center
         width="750px"
      >
         <form>
            <InputWrapper className="grid-2-2">
               <Input
                  id="start_date_gt"
                  placeholder="Start Date"
                  label="Billing Start Date(greater than)"
                  name="start_date_gt"
                  type="date"
                  value={filterData?.start_date_gt}
                  onChange={({ target }) => onChange('start_date_gt', target)}
               />
               <Input
                  placeholder=" Start Date"
                  label="Billing Start Date(less than)"
                  name="start_date_lt"
                  type="date"
                  value={filterData?.start_date_lt}
                  onChange={({ target }) => onChange('start_date_lt', target)}
               />
            </InputWrapper>

            <InputWrapper className="grid-2-2">
               <Input
                  placeholder="End Date"
                  label="Billing End Date(greater than)"
                  name="end_date_gt"
                  type="date"
                  value={filterData?.end_date_gt}
                  onChange={({ target }) => onChange('end_date_gt', target)}
               />
               <Input
                  placeholder="End Date"
                  label="Billing End Date(less than)"
                  name="end_date_lt"
                  type="date"
                  value={filterData?.end_date_lt}
                  onChange={({ target }) => onChange('end_date_lt', target)}
               />
            </InputWrapper>

            <InputWrapper className="grid-2-2">
               <Input
                  placeholder="Exp. End Date"
                  label="Billing Expected End Date(greater than)"
                  name="expected_end_date_gt"
                  type="date"
                  value={filterData?.expected_end_date_gt}
                  onChange={({ target }) => onChange('expected_end_date_gt', target)}
               />
               <Input
                  placeholder="Exp. End Date"
                  label="Billing Expected End Date(less than)"
                  name="expected_end_date_lt"
                  type="date"
                  value={filterData?.expected_end_date_lt}
                  onChange={({ target }) => onChange('expected_end_date_lt', target)}
               />
            </InputWrapper>

            <InputWrapper className="grid-2-2">
               <SelectInput
                  label="Status"
                  options={statusOptions}
                  value={statusOptions.find((item) => item.value === filterData?.status)}
                  onChange={(target) => onChange('status', target)}
               />

               <NumberInput
                  placeholder="Business Account Id"
                  label="Business Account Id"
                  name="biz_account_id"
                  value={filterData?.biz_account_id}
                  prefix=" "
                  className={Input}
                  inputmode="numeric"
                  onChange={(valuesObject) => {
                     onChange('biz_account_id', { value: valuesObject.floatValue });
                  }}
               />
            </InputWrapper>

            <InputWrapper
               style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
            >
               <div style={{ marginRight: '20px' }}>
                  <Button
                     variant="secondary"
                     type="button"
                     onClick={handleClearFilter}
                     isLoading={isClearingFilter}
                     fullWidth
                  >
                     clear filter
                  </Button>
               </div>

               <div>
                  <Button
                     variant="primary"
                     type="button"
                     onClick={onSubmit}
                     isLoading={isLoading}
                     disabled={isLoading}
                     fullWidth
                  >
                     Apply
                  </Button>
               </div>
            </InputWrapper>
         </form>
      </Modal>
   );
}

BillingDebtLogsFilterModal.propTypes = {
   open: PropTypes.bool.isRequired,
   onClose: PropTypes.func.isRequired,
   filterData: PropTypes.objectOf(string).isRequired,
   setFilterData: PropTypes.func.isRequired,
   setFilterPayload: PropTypes.func.isRequired,
};
