import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
   SectionTitle,
   OverviewSection,
   InfoGroup,
   Label,
   Value,
   Name,
   AvatarWrapper,
} from 'styles/layout';
import Avatar from 'assets/images/user.svg';
import { Status } from 'styles/utils';

const PromotionsOverview = ({ campaignDetails }) => {
   const Image = campaignDetails?.cap_image || Avatar;
   const smallImage = campaignDetails?.cap_small_image || Avatar;
   const {
      campaign_id,
      cap_title,
      cap_content,
      cap_status,
      pub_date,
      cap_location,
      cap_button,
      cap_type,
   } = campaignDetails || {};

   return (
      <>
         <SectionTitle>Campaign Information</SectionTitle>
         <div>
            <OverviewSection>
               <InfoGroup>
                  <Label>Campaign ID</Label>
                  <Value>{campaign_id}</Value>
               </InfoGroup>
               <InfoGroup>
                  <Label>Title</Label>
                  <Value>{cap_title}</Value>
               </InfoGroup>
               <InfoGroup>
                  <Label>Description</Label>
                  <Value>{cap_content}</Value>
               </InfoGroup>
               <InfoGroup>
                  <Label>Status</Label>
                  <Value>
                     <Status status={cap_status}>{cap_status}</Status>
                  </Value>
               </InfoGroup>
               <InfoGroup>
                  <Label>Location</Label>
                  <Value>{cap_location}</Value>
               </InfoGroup>
               <InfoGroup>
                  <Label> Button</Label>
                  <Value>{cap_button || 'N/A'}</Value>
               </InfoGroup>
               <InfoGroup>
                  <Label>Type</Label>
                  <Value>{cap_type || 'N/A'}</Value>
               </InfoGroup>
               <InfoGroup>
                  <Label>Date Created</Label>
                  <Value>{moment(pub_date).format('DD MMM, YYYY')}</Value>
               </InfoGroup>
            </OverviewSection>
            <OverviewSection>
               <div>
                  <Name>Image</Name>
                  <div className="m-3">
                     <AvatarWrapper>
                        <img src={Image} alt="campaign-pic" />
                     </AvatarWrapper>
                  </div>
               </div>
               <div>
                  <Name>Small Image</Name>
                  <div className="m-3">
                     <AvatarWrapper>
                        <img src={smallImage} alt="campaign-small-pic" />
                     </AvatarWrapper>
                  </div>
               </div>
            </OverviewSection>
         </div>
      </>
   );
};

PromotionsOverview.propTypes = {
   campaignDetails: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default PromotionsOverview;
