export const prospaPlans = [
   {
      value: 'standard',
      label: 'Standard',
   },
   {
      value: 'growth',
      label: 'Growth',
   },
   {
      value: 'scale',
      label: 'Scale',
   },
];
