/* eslint-disable react/prop-types */
import { Button } from 'components/button';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { ConfirmModalWrapper } from './confirm-box.style';

export function confirm({
   confirmText,
   cancelBtnText,
   proceedBtnText,
   proceedBtnColor,
   cancelBtnColor,
   isLoading,
   onConfirm,
   multiple,
   hide,
}) {
   return confirmAlert({
      customUI: ({ onClose }) => {
         return (
            <ConfirmModalWrapper className="test">
               <div className="text">{confirmText}</div>
               <div className="btn-container d-flex justify-content-center">
                  {!hide?.proceed && (
                     <Button
                        className="confirm-btn-primary"
                        disabled={isLoading}
                        isLoading={isLoading}
                        key={isLoading}
                        variant={proceedBtnColor || 'primary'}
                        type="button"
                        size="small"
                        onClick={async () => {
                           if (!multiple) {
                              onClose();
                           }
                           return onConfirm();
                        }}
                     >
                        {proceedBtnText || 'Yes'}
                     </Button>
                  )}
                  {!hide?.cancel && (
                     <Button
                        className="confirm-btn-secondary"
                        disabled={isLoading}
                        variant={cancelBtnColor || 'secondary'}
                        type="button"
                        onClick={onClose}
                        size="small"
                     >
                        {cancelBtnText || 'No'}
                     </Button>
                  )}
               </div>
            </ConfirmModalWrapper>
         );
      },
      closeOnClickOutside: false,
   });
}
