import React, { useState } from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { Button } from 'components/button';
import { confirm } from 'components/confirm-box';
import { Tag } from 'styles/tags';
import { InputWrapper } from 'styles/form';
import RemoveIcon from 'assets/images/remove.svg';
import Textarea from 'components/textarea';
import { hasValue } from 'utils/validations';
import { Input } from 'components/input';
import { deleteTicketFaq, addTicketFaq, updateTicketFaq } from 'services/ticketing';
import { FaqWrapper } from '../../ticketing.style';

export default function FaqComponent({
   faq,
   isEdit,
   setIsEdit,
   fetchTicketFaq,
   activeCategory,
   allowManage,
}) {
   const editActive = isEdit === 'faq';
   // const [faqList, setFaqList] = useState([]);
   const [faqState, setFaq] = useState({
      question: '',
      answer: '',
   });

   const { answer, question } = faqState;

   const confirmConfig = {
      cancelBtnText: 'Cancel',
      proceedBtnText: 'Delete',
      proceedBtnColor: 'danger',
      cancelBtnColor: 'primary',
   };

   const handleAddFaq = async () => {
      if (hasValue(question) || hasValue(answer)) {
         // const allFaq = faqList;
         const exist = faq.find((item) => item.id === faqState.id);

         // if (exist) {
         //    exist.question = question;
         //    exist.answer = answer;
         //    setFaqList([...allFaq]);
         // } else {
         //    setFaqList([
         //       ...faqList,
         //       {
         //          id: uuidv4(),
         //          question,
         //          answer,
         //       },
         //    ]);
         // }

         // setFaq({
         //    answer: '',
         //    question: '',
         // });

         try {
            if (exist) {
               await updateTicketFaq(faqState.id, {
                  question: faqState.question,
                  answer: faqState.answer,
                  category: activeCategory,
               });
            } else {
               await addTicketFaq([
                  {
                     category: activeCategory,
                     question,
                     answer,
                  },
               ]);
            }

            setFaq({
               question: '',
               answer: '',
            });
            await fetchTicketFaq();
            // toast.success('Ticket FAQ has been deleted successfully');
         } catch (e) {
            toast.error(e?.message);
         }
      }
   };

   const deleteFaq = async (id) => {
      // setFaqList(faqList.filter((item) => item.id !== id));

      confirm({
         confirmText: 'Are you sure you want to delete this FAQ?',
         ...confirmConfig,
         onConfirm: async () => {
            try {
               await deleteTicketFaq(id);
               await fetchTicketFaq();
            } catch (e) {
               toast.error(e?.message || e);
            }
         },
      });
   };

   const triggerFaqUpdate = (data) => {
      setFaq({
         id: data.id,
         question: data.question,
         answer: data.answer,
      });
   };

   // const handleUpdateFaq = async () => {
   //    const payload = faqList;
   //    setIsLoading(true);
   //    console.log(payload);
   //    try {
   //       // const response = await updateTicketFaq(activeCategory, payload);
   //       // console.log(response);
   //       await fetchTicketCategories();
   //       setFaqList([]);
   //       setIsEdit(null);
   //       toast.success('Update was successful');
   //    } catch (e) {
   //       toast.error(e?.message || e);
   //    }
   //    setIsLoading(false);
   // };

   // useEffect(() => {
   //    if (editActive) {
   //       setFaqList(faq);
   //    }
   // }, [editActive]);

   const faqEdit = faq.find((item) => item.id === faqState.id);
   return (
      <FaqWrapper className="faq">
         <div>
            <div className="d-flex justify-content-between align-items-center">
               {!editActive && <h4>FAQ’s</h4>}

               {!editActive && allowManage && (
                  <div className="category-action">
                     <Button size="small" onClick={() => setIsEdit('faq')}>
                        Edit
                     </Button>
                  </div>
               )}
            </div>

            {!editActive ? (
               <div className="faq-list">
                  {faq?.map((item) => (
                     <div key={item.id} className="d-flex justify-content-between">
                        <div className="faq-item col-md-7">
                           <h5>{item.question}</h5>
                           <p>{item.answer}</p>
                        </div>
                     </div>
                  ))}
               </div>
            ) : (
               <div className="edit-faq">
                  <InputWrapper className="grid-2-2">
                     <Input
                        placeholder="Question"
                        label="Question"
                        name="question"
                        type="text"
                        className="mb-5"
                        value={question}
                        onChange={({ target: { value } }) =>
                           setFaq({ ...faqState, question: value })
                        }
                     />

                     <Textarea
                        label="Answer"
                        name="answer"
                        value={answer}
                        onChange={({ target: { value } }) => setFaq({ ...faqState, answer: value })}
                     />
                  </InputWrapper>

                  <Button
                     size="small"
                     onClick={handleAddFaq}
                     disabled={!hasValue(question) || !hasValue(answer)}
                  >
                     {faqEdit ? 'Edit' : 'Add'} FAQ
                  </Button>

                  {faq.length > 0 && (
                     <div className="faq-list-container mb-5 mt-5">
                        <div className="row m-0">
                           {faq?.map((item) => (
                              <div className="col-md-6" key={item.id}>
                                 <Tag className="faq-list-item mb-4" fullWidth>
                                    <div className="content" onClick={() => triggerFaqUpdate(item)}>
                                       <h6 className="faq-list-item-question tag-heading text">
                                          {item.question}
                                       </h6>
                                       <p className="faq-list-item-answer tag-body text">
                                          {item.answer}
                                       </p>
                                    </div>
                                    <img
                                       src={RemoveIcon}
                                       alt="remove"
                                       onClick={() => deleteFaq(item.id)}
                                    />
                                 </Tag>
                              </div>
                           ))}
                        </div>
                     </div>
                  )}

                  <div className="flex justify-content-end mt-4">
                     <Button onClick={() => setIsEdit(false)}>Update FAQ’s</Button>
                  </div>
               </div>
            )}
         </div>
      </FaqWrapper>
   );
}

FaqComponent.propTypes = {
   faq: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
   isEdit: PropTypes.string.isRequired,
   setIsEdit: PropTypes.func.isRequired,
   fetchTicketFaq: PropTypes.func.isRequired,
   activeCategory: PropTypes.number.isRequired,
   allowManage: PropTypes.bool.isRequired,
};
