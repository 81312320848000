import { useState, useEffect } from 'react';
import { useAuth } from 'hooks/auth';
import toast from 'react-hot-toast';
import { number } from 'prop-types';
import { Switch, Grid } from '@material-ui/core';
import moment from 'moment';
import { getBusinessDetails } from 'services/businessDetails';
import { getBusinessSafeSettings } from 'services/savings';
import {
   generateAccountStatementApi,
   reactivateTerminatedAccountApi,
   getMemberBusinessesList,
} from 'services/business';
import { AlertMessage } from 'components/message';
import { SelectInput } from 'components/select';
import { InputWrapper } from 'styles/form';
import { Spinner } from 'components/spinner';
import { Button } from 'components/button';
import { Input } from 'components/input';
import SubAccountCard from 'components/wallet-card';
import OtherBusinessAccountCard from 'components/other-business-account-card';
import Withdraw from './withdraw';
import Transactions from './transactions';
import { GenerateStatementWrapper } from './style';

function Index({ businessAccountId }) {
   const { currentUser } = useAuth();
   const { permissions } = currentUser?.access || {};
   const [bizDetails, setBizDetails] = useState({});
   const [safeSettings, setSafeSetting] = useState({});
   const [otherAccounts, setOtherAccounts] = useState([]);
   const [loading, setLoading] = useState(true);
   const [loadingOtherAccounts, setLoadingOtherAccounts] = useState(true);
   const [reactivatingAccount, setReactivatingAccount] = useState(null);
   const [generatingStatement, setGeneratingStatment] = useState(false);
   const [toggle, setToggle] = useState(false);
   const [walletId, setWalletId] = useState(null);
   const [modalVisibility, setModalVisibility] = useState(false);
   const [dateRange, setDateRange] = useState({
      start_date: moment().subtract('3', 'months').format('YYYY-MM-DD'),
      end_date: moment().format('YYYY-MM-DD'),
   });
   const EnableToggle = () => {
      setToggle(true);
   };
   const DisableToggle = () => {
      setToggle(false);
   };

   const action = 'read_sub_accounts';

   if (!permissions?.includes(action) && permissions !== 'all')
      return (
         <AlertMessage
            message={`You are not authorized to perform this action: ${action}`}
            variant="error"
         />
      );

   const fetchSafeSettings = async (params) => {
      try {
         const response = await getBusinessSafeSettings(params);
         return response;
      } catch (e) {
         toast.error(e?.message);
      }
   };

   const fetchBusinessDetails = async () => {
      try {
         const result = await getBusinessDetails(businessAccountId);
         const { data } = result;
         const safeInfo = await fetchSafeSettings({
            business_name: data.biz_friendly_name,
         });
         setSafeSetting(safeInfo);
         setLoading(false);
         const otherBusinesses = await getMemberBusinessesList(
            data?.account_holder_details?.account_holder_id,
         );
         if (result.ok) {
            setBizDetails(data);
         }
         setOtherAccounts(otherBusinesses.results);
         setLoadingOtherAccounts(false);
      } catch (err) {
         if (
            (err.message && !err.message.includes('business_search')) ||
            (err.custom_message && !err.message.includes('business_search'))
         ) {
            toast.error(err.message || err.custom_message);
         }
         setLoading(false);
      }
   };

   const generateAccountStatement = async () => {
      setGeneratingStatment(true);
      try {
         const payload = {
            biz_account_id: businessAccountId,
            hbizwallet_id: walletId.value,
            ...dateRange,
         };
         const response = await generateAccountStatementApi(payload);
         toast.success(response.message);
         window.open(response.statement_url, '_blank');
         await fetchBusinessDetails();
      } catch (err) {
         toast.error(err.message || err.custom_message);
      }
      setGeneratingStatment(false);
   };

   const reactivateTerminatedAccount = async (bizWalletId) => {
      setReactivatingAccount(bizWalletId);
      try {
         const payload = {
            biz_wallet_id: bizWalletId,
         };
         const response = await reactivateTerminatedAccountApi(payload);
         toast.success(response.message);
         await fetchBusinessDetails();
      } catch (err) {
         toast.error(err.message || err.custom_message);
      }
      setReactivatingAccount(null);
   };

   useEffect(() => {
      fetchBusinessDetails();
   }, []);

   const filterSingleSafeSettings = (safeItem) => {
      const safeSelected = safeSettings?.results.find(
         (safe) => safe.wallet_id === safeItem.biz_wallet_id,
      );

      if (safeSelected) {
         const activeSettings = safeSelected.safe_settings.map((item) => item.setting_type);
         return activeSettings.join(', ').replaceAll('_', ' ');
      }

      return [];
   };
   if (loading) return <Spinner />;

   const { biz_account_wallet_details } = bizDetails || {};

   return (
      <>
         <div>
            <h2 className="mb-4">Accounts</h2>
            <div>
               <Grid component="label" container alignItems="center" marginLeft="" spacing={1}>
                  <Grid item>
                     <b>Partner Bank</b>
                  </Grid>
                  <Grid item>
                     <Switch onClick={toggle ? DisableToggle : EnableToggle} />
                  </Grid>
                  <Grid item>
                     <b>GoodNews MFB</b>
                  </Grid>
               </Grid>
            </div>
            <div className="row mt-4">
               {biz_account_wallet_details?.map((item) => {
                  return (
                     <div className="col-md-6 mb-5" key={item.biz_wallet_id}>
                        <SubAccountCard
                           account={item}
                           toggle={toggle}
                           setModalVisibility={setModalVisibility}
                           reactivatingAccount={reactivatingAccount}
                           reactivateTerminatedAccount={reactivateTerminatedAccount}
                           filterSingleSafeSettings={filterSingleSafeSettings}
                        />
                     </div>
                  );
               })}
            </div>

            <h2 className="mb-4 pt-4">Generate Account Statement</h2>
            <GenerateStatementWrapper>
               <InputWrapper className="grid-2-2">
                  <Input
                     placeholder="Start Date"
                     label="Start Date"
                     name="start_date"
                     type="date"
                     value={dateRange.start_date}
                     onChange={(e) =>
                        setDateRange({
                           ...dateRange,
                           start_date: e.target.value,
                        })
                     }
                  />
                  <Input
                     placeholder="End Date"
                     label="End Date"
                     name="end_date"
                     type="date"
                     value={dateRange.end_date}
                     onChange={(e) =>
                        setDateRange({
                           ...dateRange,
                           end_date: e.target.value,
                        })
                     }
                  />

                  <SelectInput
                     label="Select Wallet"
                     value={walletId}
                     onChange={(val) => setWalletId(val)}
                     options={biz_account_wallet_details?.map(
                        ({
                           preferred_name,
                           biz_wallet_id,
                           goodnews_full_bank_name,
                           goodnews_account_number,
                           biz_account_number,
                           partner_bank_name,
                        }) => ({
                           value: biz_wallet_id,
                           label: `${preferred_name} - ${
                              goodnews_full_bank_name || partner_bank_name
                           } (${goodnews_account_number || biz_account_number})`,
                        }),
                     )}
                     className="w-100"
                  />
                  <Button
                     className="generate-statement-btn"
                     onClick={generateAccountStatement}
                     disabled={generatingStatement || !walletId}
                     isLoading={generatingStatement}
                  >
                     Generate
                  </Button>
               </InputWrapper>
            </GenerateStatementWrapper>

            <h2 className="mb-4 pt-4">Other Accounts</h2>
            <div className="row">
               {!loadingOtherAccounts ? (
                  otherAccounts?.map((otherBusinessDetails) => {
                     return (
                        <div className="col-lg-6 mb-4">
                           <OtherBusinessAccountCard
                              otherBusinessDetails={otherBusinessDetails}
                              fetchBusinessDetails={fetchBusinessDetails}
                           />
                        </div>
                     );
                  })
               ) : (
                  <h2>Loading...</h2>
               )}
            </div>
         </div>
         {modalVisibility.type === 'withdraw' && (
            <Withdraw
               businessAccountId={businessAccountId}
               open={modalVisibility.type === 'withdraw'}
               onClose={() => setModalVisibility(false)}
               onBack={() => setModalVisibility(false)}
               walletDetails={modalVisibility.data}
               onSuccess={() => fetchBusinessDetails()}
            />
         )}

         {modalVisibility.type === 'transactions' && (
            <Transactions
               businessAccountId={businessAccountId}
               open={modalVisibility.type === 'transactions'}
               onClose={() => setModalVisibility(false)}
               onBack={() => setModalVisibility(false)}
               modalVisibility={modalVisibility}
            />
         )}
         {modalVisibility.type === 'fixed-transactions' && (
            <Transactions
               businessAccountId={businessAccountId}
               open={modalVisibility.type === 'fixed-transactions'}
               onClose={() => setModalVisibility(false)}
               onBack={() => setModalVisibility(false)}
               modalVisibility={modalVisibility}
            />
         )}
      </>
   );
}

Index.propTypes = {
   businessAccountId: number.isRequired,
};

export default Index;
