import Styled from 'styled-components';

export const FilterContainer = Styled.div`
   border: 1px solid #d7dce0;
   padding: 10px 16px;
   border-radius: 6px;
   margin: 0 20px;
   height: 40px;
   cursor: pointer;
   width: 200px;
   display: flex;
   align-items: center;
   justify-content: space-between;
`;
